import axiosApiClient from "..";

import { handleError } from "../../utils/errors";

const BASE_URL = "business/marketplace";

class MarketplaceService {
  getOrders = async ({ page, sortBy, order }) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/order?page=${page}&sortBy=${sortBy}&order=${order}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, maxReached, total, perPage } = response.data;
      return {
        data,
        total,
        perPage,
        maxReached,
      };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching  marketplace orders."
      );
    }
  };

  getFavoriteMarketplaceItemsAll = async ({
    page,
    sortBy,
    order,
    search,
    category,
  }) => {
    try {
      let params = "";
      if (category) {
        params = `&categoryId=${category}`;
      }

      const response = await axiosApiClient.get(
        `${BASE_URL}/favorite?page=${page}&sortBy=${sortBy}&order=${order}&search=${search}` +
          params
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, maxReached, total, perPage } = response.data;
      return {
        data,
        total,
        perPage,
        maxReached,
        page,
      };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching favorite marketplace items."
      );
    }
  };

  getCountFavoriteMarketplaceItems = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/favorite/count`);
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching count of favorite marketplace items."
      );
    }
  };

  getOrder = async (id) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/order/${id}`);
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching marketplace order."
      );
    }
  };

  getAllMarketplaceItems = async ({
    search,
    page,
    sortBy,
    order,
    category,
  }) => {
    if (!search) {
      search = "";
    }

    if (!sortBy) {
      sortBy = "createdAt";
    }

    if (!order) {
      order = "ASC";
    }

    if (!category) {
      category = "";
    }

    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/items/all?page=${page}&search=${search}&sortBy=${sortBy}&order=${order}&category=${category}`
      );
      if (response.status !== 200) {
        throw new Error();
      }

      response.data.page = page;
      return response.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching marketplace items."
      );
    }
  };

  createOrder = async (order) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/order`, order);
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while creating order.");
    }
  };

  toggleFavoriteMarketplaceItem = async (requestData) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/favorite`,
        requestData
      );
      if (response.status !== 200) {
        throw new Error();
      }

      return requestData;
    } catch (err) {
      throw handleError(err, "Error occurred while toggle favorite item.");
    }
  };

  createItem = async (item) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/items`, item);
      if (response.status !== 201) {
        throw new Error();
      }

      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while creating item.");
    }
  };

  removeItem = async (itemId) => {
    try {
      const response = await axiosApiClient.delete(
        `${BASE_URL}/items/${itemId}`
      );
      if (response.status !== 201) {
        throw new Error();
      }

      return { itemId };
    } catch (err) {
      throw handleError(err, "Error occurred while deleting item.");
    }
  };

  getSubcategories = async ({ categories }) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/subcategories?categories=${categories}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching marketplace item subcategories."
      );
    }
  };

  createSubcategory = async ({ value, marketplaceCategoryId }) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/subcategories`, {
        value,
        marketplaceCategoryId,
      });
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while creating marketplace item subcategory."
      );
    }
  };

  getCategories = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/categories`);
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching marketplace item categories."
      );
    }
  };

  createCategory = async (name) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/categories`, {
        name,
      });
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while creating marketplace item category."
      );
    }
  };

  updateOrderState = async ({ id, state, paymentStatus }) => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/order/${id}`, {
        state,
        paymentStatus,
      });

      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while updating order state.");
    }
  };

  getMarketplaceItemById = async (itemId) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/items/${itemId}`);
      if (response.status !== 200) {
        throw new Error();
      }

      const { data, message } = response.data;

      return { data, message };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching item by id.");
    }
  };

  getSubcategoriesList = async () => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/subcategories-list`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data.data.result;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching marketplace item subcategories with categories."
      );
    }
  };

  deleteVariable = async (id) => {
    try {
      const response = await axiosApiClient.delete(
        `${BASE_URL}/subcategory-item/${id}`
      );
      if (response.status !== 200) {
        throw new Error();
      }

      return {
        subcategoryItemId: id,
      };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while deleting subcategory items."
      );
    }
  };

  deleteSubcategory = async (id) => {
    try {
      const response = await axiosApiClient.delete(
        `${BASE_URL}/subcategory/${id}`
      );
      if (response.status !== 200) {
        throw new Error();
      }

      return {
        subcategoryId: id,
      };
    } catch (err) {
      throw handleError(err, "Error occurred while deleting subcategory.");
    }
  };

  deleteCategory = async (id) => {
    try {
      const response = await axiosApiClient.delete(
        `${BASE_URL}/category/${id}`
      );
      if (response.status !== 200) {
        throw new Error();
      }

      return {
        categoryId: id,
      };
    } catch (err) {
      throw handleError(err, "Error occurred while deleting category.");
    }
  };

  createVariable = async ({ value, subcategoryId }) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/add-subcategory-item`,
        { value, subcategoryId }
      );
      if (response.status !== 201) {
        throw new Error();
      }
      return response.data.subcategoryItem;
    } catch (err) {
      throw handleError(err, "Error occurred while creating variable.");
    }
  };

  editVariable = async ({ value, subcategoryItemId }) => {
    try {
      const response = await axiosApiClient.put(
        `${BASE_URL}/subcategory-item/${subcategoryItemId}`,
        { value }
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data.data;
    } catch (err) {
      throw handleError(err, "Error occurred while updating variable.");
    }
  };

  editSubcategory = async ({ value, subcategoryId }) => {
    try {
      const response = await axiosApiClient.put(
        `${BASE_URL}/subcategory/${subcategoryId}`,
        { value }
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data.data;
    } catch (err) {
      throw handleError(err, "Error occurred while updating subcategory.");
    }
  };

  editCategory = async ({ name, categoryId }) => {
    try {
      const response = await axiosApiClient.put(
        `${BASE_URL}/category/${categoryId}`,
        { name }
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data.data;
    } catch (err) {
      throw handleError(err, "Error occurred while updating category.");
    }
  };

  updateCategoryPriority = async ({ newPriorities }) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/categories/priority`,
        { newPriorities }
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while updating categories priority."
      );
    }
  };

  getAttributesByCategory = async (categoryId) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/subcategories/${categoryId}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while updating categories priority."
      );
    }
  };

  editItem = async ({ itemId, item }) => {
    try {
      const response = await axiosApiClient.put(
        `${BASE_URL}/items/${itemId}`,
        item
      );
      if (response.status !== 201) {
        throw new Error();
      }
      return response.data.data;
    } catch (err) {
      throw handleError(err, "Error occurred while editing an item.");
    }
  };

  sendShareCartProductEmail = async ({ marketplaceItemId, email }) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/share-cart-product`,
        { marketplaceItemId, email }
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while sending an email for share cart product."
      );
    }
  };

  validatePayment = async ({ paymentChargeId }) => {
    try {
      const body = {
        paymentChargeId,
      };
      const response = await axiosApiClient.post(
        BASE_URL + "/validate-payment",
        body
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while choosing a plan");
    }
  };

  generateInvoice = async ({
    paymentChargeId,
    companyName,
    companyVat,
    companyCountry,
    companyAddress,
    companyPostalCode,
  }) => {
    try {
      const body = {
        paymentChargeId,
        companyName,
        companyVat,
        companyCountry,
        companyAddress,
        companyPostalCode,
      };
      const response = await axiosApiClient.post(
        BASE_URL + "/generate-invoice",
        body
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while generating an invoice");
    }
  };

  getMarketplaceOrdersSuperAdmin = async ({ page, sortBy, order }) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/order-admin?page=${page}&sortBy=${sortBy}&order=${order}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, maxReached, total, perPage } = response.data;
      return {
        data,
        total,
        perPage,
        maxReached,
      };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching super admin marketplace orders."
      );
    }
  };

  generateInvoiceMarketplaceOrder = async ({
    marketplaceOrderId,
    companyName,
    companyVat,
    companyCountry,
    companyAddress,
    companyPostalCode,
  }) => {
    try {
      const body = {
        companyName,
        companyVat,
        companyCountry,
        companyAddress,
        companyPostalCode,
      };
      const response = await axiosApiClient.post(
        BASE_URL + `/generate-invoice/${marketplaceOrderId}`,
        body
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while generating an invoice");
    }
  };
  getOrderPackages = async () => {
    try {
      const response = await axiosApiClient.get(BASE_URL + "/order-packages");
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while fetching order packages");
    }
  };
}

const marketplaceService = new MarketplaceService();

export default marketplaceService;
