import styled from "styled-components";

export const StyledRow = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;
export const StyledDiv = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.$column ? "column" : "row")};
  align-items: ${(props) => (props.$column ? "flex-start" : "center")};
  gap: ${(props) => (props.$column ? "10px" : "28px")};
  @media (max-width: 520px) {
    flex-direction: column;
    gap: 10px;
  }
`;
export const StyledText = styled.span`
  color: #8a4b5e;
  user-select: none;
  font-family: "Avenir-Medium";
  font-size: ${(props) => (props.$column ? "16px" : "22px")};
  letter-spacing: -0.178px;
`;
