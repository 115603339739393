import styled, { keyframes } from "styled-components";
import ProgressiveImg from "../../../../components/ProgressiveImg/ProgressiveImg";
import Draggable from "react-draggable";
import { ReactComponent as Xsvg } from "../../../../assets/icons/close.svg";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const MainContainer = styled.div`
  flex-direction: column;
  background: #f6f6f9;

  min-height: 100dvh;
  display: flex;
`;
export const HeaderWrapper = styled.div`
  background: #8a4b5e;

  position: fixed;
  z-index: 1000;

  width: 100%;
  justify-content: center;
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;

  width: ${(props) => (props.$loggedIn ? "100%" : "1440px")};

  padding: ${(props) =>
    props.$loggedIn && props.$ar
      ? "10rem 290px 80px 40px"
      : props.$loggedIn && !props.$ar
      ? "10rem 40px 80px 290px"
      : !props.$loggedIn && props.$ar
      ? "10rem 40px 80px 40px"
      : "10rem 40px 80px 40px"};

  margin-top: ${(props) => (props.$upgradePackage ? "6rem" : "0rem")};
  transition: all 0.2s ease-in-out;
  @media (max-width: 870px) {
    padding: 10rem 3rem 3rem 3rem;
  }
  @media (max-width: 520px) {
    margin-top: ${(props) => (props.$upgradePackage ? "16.2rem" : "10.2rem")};
    padding: 0rem 0rem 3rem 0rem;
  }
`;

export const SectionTitle = styled.h1`
  color: #8a4b5e;
  font-family: "Avenir-Medium";
  font-size: 1.8rem;
  user-select: none;
  letter-spacing: 0.067px;
`;
export const Layout = styled.div`
  width: 100%;
  min-height: 100dvh;
  height: 100%;
  display: flex;
  justify-content: center;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  @media (max-width: 1390px) {
    scale: 0.8;
    margin-top: -10rem;
  }
  @media (max-width: 1020px) {
    scale: 0.65;
    margin-top: -15rem;
  }
  @media (max-width: 870px) {
    scale: 0.8;
    margin-top: -10rem;
  }
  @media (max-width: 850px) {
    scale: 1;
    margin-top: 0;
    flex-direction: column;
  }
`;

export const StyledColumn = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  @media (max-width: 645px) {
    width: 100%;
  }
`;

export const Card = styled.div`
  display: flex;
  position: relative;
  width: 59rem;
  align-items: center;
  padding: ${(props) => (props.$bigGap ? "4rem" : "1.5rem")};
  justify-content: center;
  min-height: 82.23rem;
  height: 100%;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  @media (max-width: 1510px) {
    width: 49rem;
  }
  @media (max-width: 850px) {
    min-height: fit-content;
  }
  @media (max-width: 645px) {
    padding: ${(props) => (props.$bigGap ? "4rem 3rem" : "4rem 1.5rem")};

    width: 90%;
    min-height: fit-content;
  }

  @media (max-width: 520px) {
    padding: ${(props) => (props.$bigGap ? "3rem" : "1.5rem")};
  }
`;
export const StyledCard = styled(Card)`
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 30px;
  justify-content: flex-start;
`;
export const FooterCard = styled.div`
  display: flex;
  width: 59rem;
  align-items: center;
  padding: 12px;
  justify-content: center;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  min-height: 11.2rem;
  gap: 4rem;

  @media (max-width: 1510px) {
    width: 49rem;
  }
  @media (max-width: 645px) {
    padding: 4rem 2rem;
    width: 90%;
    height: fit-content;
  }
  @media (max-width: 610px) {
    flex-direction: column;
  }
`;
export const Img = styled(ProgressiveImg)`
  user-select: none;
  border-radius: 10px;
  width: 100%;
  /* height: 50rem; */
  object-fit: ${(props) => (props.$contain ? "contain" : "cover")};
  pointer-events: none;
`;

export const Name = styled.span`
  font-family: "FrankfurterEF-Medium";
  font-size: 5rem;
  letter-spacing: 0.067px;
  @media (max-width: 645px) {
    font-size: 4rem;
  }
`;
export const Title = styled.span`
  position: relative;
  color: rgba(138, 75, 94, 0.8);
  font-family: "FrankfurterEF-Medium";
  font-size: 3rem;
  line-height: 3.2rem; /* 106.667% */
  @media (max-width: 645px) {
    font-size: 2rem;
  }
`;
export const Description = styled.span`
  font-family: "Avenir-Medium";
  font-size: 1.4rem;
  letter-spacing: 0.067px;
  margin-bottom: 5rem;
  @media (max-width: 645px) {
    font-size: 1rem;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`;

export const Color = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    scale: 1.1;
    opacity: 1 !important;
  }
`;
export const ChooseFile = styled.input`
  background-color: red;
  position: absolute;
  left: 0;
  border-radius: 1rem;
  width: 100%;
  opacity: 0;
  height: 100%;

  top: 0;
`;
export const Size = styled(Color)`
  background-color: #8a4b5e;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GiftCardSub = styled(Color)`
  background-color: #8a4b5e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px;
  width: 130px;
  height: 120px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
export const SizeValue = styled.span`
  user-select: none;
  color: #fff;
  font-family: "Avenir-Medium";
  font-size: 1.2rem;
  margin-bottom: -2px;
`;
export const PriceValue = styled.span`
  user-select: none;
  color: #fff;
  margin-bottom: -2px;
  font-family: "FrankfurterEF-Medium";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.067px;
`;
export const Colors = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 2rem;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
`;

export const ButtonRow = styled.div`
  position: relative;
`;
export const ButtonsContainer = styled.div`
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 367px) {
    justify-content: center;
  }
`;
export const Relative = styled.div`
  position: relative;
`;
export const MinQuantityContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: flex-start;
`;
export const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const Button = styled.button`
  border-radius: 1rem;
  /* width: 100%; */
  height: 5.6rem;
  padding: 1.2rem;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8a4b5e;
  border: none;
  outline: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15), 0px 2.5px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  user-select: none;
  color: #fff;
  text-align: center;
  font-family: "FrankfurterEF-Medium";
  font-size: 2rem;

  transition: scale 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:active {
    scale: 0.97;
    box-shadow: 0px 5px 10px transparent, 0px 2.5px 5px transparent;
  }
  @media (max-width: 1510px) {
    width: ${(props) => (props.$upload ? "364px !important" : "unset")};
  }
  @media (max-width: 1200px) {
    width: ${(props) => (props.$upload ? "315px !important" : "unset")};
  }
  @media (max-width: 645px) {
    font-size: 1.5rem;
  }
  @media (max-width: 489px) {
    width: ${(props) => (props.$upload ? "255px !important" : "unset")};
  }
  @media (max-width: 425px) {
    width: ${(props) => (props.$upload ? "205px !important" : "unset")};
  }
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4rem;
`;

export const Text = styled.span`
  color: #000;
  font-family: "Avenir-Medium";
  font-size: 1.4rem;
  letter-spacing: 0.067px;
`;

export const Input = styled.input`
  font-family: "Avenir-Black";
  user-select: none;
  font-size: 3rem;
  text-align: center;
  color: #85a195;
  line-height: 3.2rem;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  max-width: 10rem;

  border-bottom: 2px solid #85a195;
  width: fit-content;
  &:focus {
    outline: none;
  }
  @media (max-width: 645px) {
    font-size: 2rem;
    line-height: 2.2rem;
  }
`;

export const StyledTitleContainer = styled(TitleContainer)`
  flex-direction: column;
  gap: 0px;
`;
export const StyledRow = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;
export const Error = styled.span`
  color: #f00;
  font-family: "Avenir-Medium";
  font-size: 1.4rem;
  letter-spacing: 0.067px;
`;
export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding-left: 4rem;
  padding-top: 2rem;
  margin-bottom: 3rem;
`;

export const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  @media (max-width: 425px) {
    flex-direction: column;
    justify-content: center;
    gap: 0;
  }
`;
export const StyledSectionTitle = styled(SectionTitle)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 425px) {
    width: 100%;
    gap: 1rem;
  }
`;
export const FooterText = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-family: "Avenir-Medium";
  font-size: 1.6rem;
  line-height: 3.2rem; /* 200% */
  @media (max-width: 425px) {
    line-height: normal;
  }
`;
export const FooterValues = styled.span`
  color: rgba(0, 0, 0, 0.8);
  font-family: "Avenir-Heavy";
  font-size: 1.8rem;

  line-height: 3.2rem; /* 177.778% */
  @media (max-width: 425px) {
    line-height: normal;
    text-align: center;
  }
`;
export const Green = styled(Text)`
  color: #ea435d;
  font-family: "Avenir-Black";
`;
export const Red = styled(Green)`
  font-family: "FrankfurterEF-Medium";
`;

export const Success = styled.img`
  user-select: none;
  background-color: transparent;
`;

export const ErrorDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: center;
  padding-top: 10%;
  padding-bottom: 10%;
  gap: 3rem;
`;

export const SubTitle = styled.span`
  color: #8a4b5e;
  font-family: "Avenir";
  font-size: 1.8rem;
  font-weight: 300;
  letter-spacing: -0.178px;
  cursor: pointer;
  text-decoration: underline;
  @media (max-width: 640px) {
    font-size: 1.4rem;
  }
`;

export const LoaderDiv = styled.div`
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
`;
export const SetPrintArea = styled.div`
  width: 100%;
  height: 100%;

  background-color: transparent;
  border: 1px solid transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;

  pointer-events: none;
  left: 0;
  z-index: 11;
  right: 0;
  bottom: 0;
  background-color: #00bfff40;
`;
export const Overflow = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
`;
export const SetHorizontalLine = styled.div`
  width: 115%;
  height: 1px;
  background-color: white;
  position: absolute;
`;

export const SetVerticalLine = styled.div`
  z-index: 1;
  height: 120%;
  width: 1px;
  background-color: white;
  position: absolute;
`;

export const HorizontalLine = styled.div`
  width: 120%;

  z-index: 1;
  height: 1px;
  background-color: white;
  position: absolute;
  opacity: ${(props) => (props.$hover ? 1 : 0)};
  transition: all 0.1s ease-in-out;
  @media (max-width: 768px) {
    opacity: ${(props) => (props.$drag ? 1 : 0)};
  }
`;

export const VerticalLine = styled.div`
  height: 115%;

  width: 1px;
  background-color: white;
  position: absolute;
  transition: all 0.1s ease-in-out;
  opacity: ${(props) => (props.$hover ? 1 : 0)};
  @media (max-width: 768px) {
    opacity: ${(props) => (props.$drag ? 1 : 0)};
  }
`;
export const PrintArea = styled.div`
  z-index: 2;
  background-color: transparent;
  border: 1px solid transparent;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.1s ease-in-out;
  border: ${(props) =>
    props.$hover ? "1px solid white" : "1px solid transparent"};
  @media (max-width: 768px) {
    border: 1px solid white;
  }
`;
export const PrintLogo = styled.img`
  width: 100%;
  height: 100%;
  border: ${(props) =>
    props.$hover ? "1px solid white" : "1px solid transparent"};
  border-style: dashed;
  user-select: none;
  z-index: 4;
  pointer-events: none;
  @media (max-width: 768px) {
    border: 1px solid white;
  }
`;

export const PhotoBorder = styled.div`
  width: 100%;
  height: 100%;
  border: ${(props) =>
    props.$hover ? "1px solid white" : "1px solid transparent"};
  border-style: dashed;
  user-select: none;
  z-index: 4;
  pointer-events: none;
  @media (max-width: 768px) {
    border: 1px solid white;
  }
`;

export const RemoveLogo = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  z-index: 6;
  border-radius: 50%;
  outline: 1px solid white;
  background-color: #ea435d;
  position: absolute;
  top: -1rem;
  right: -1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  transition: all 0.1s ease-in-out;
  opacity: ${(props) => (props.$hover ? 1 : 0)};
  @media (max-width: 768px) {
    opacity: 1;
  }
`;

export const ResizeLogo = styled(RemoveLogo)`
  background-color: #8a4b5e;
  top: unset;
  bottom: -1rem;
  padding: 0.3rem;
  cursor: nwse-resize;
  transition: all 0.1s ease-in-out;
  opacity: ${(props) => (props.$hover ? 1 : 0)};
  z-index: 10;
  @media (max-width: 768px) {
    opacity: 1;
  }
`;

export const RotateLogo = styled(ResizeLogo)`
  right: unset;
  left: -1rem;
  cursor: move;
`;

export const LogoContainer = styled.div`
  position: relative;
  cursor: move;
  z-index: 5;
  width: fit-content;

  height: fit-content;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const StyledDraggable = styled(Draggable)`
  z-index: 5;
`;

export const PrintAreTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -2.5rem;
  left: 0;
  right: 0;
  padding: 0.2rem;
  z-index: 11;
  background-color: #00bfff;
`;
export const PrintAreaText = styled.span`
  color: #fff;
  font-family: "Avenir-Medium";
  font-size: 1.2rem;
  user-select: none;
  letter-spacing: 0.067px;
`;

export const Div = styled.div`
  z-index: 2;
  background-color: transparent;
  border: 1px solid transparent;
  position: absolute;

  display: flex;

  transition: all 0.1s ease-in-out;
  border: ${(props) =>
    props.$hover ? "1px solid white" : "1px solid transparent"};
  @media (max-width: 768px) {
    border: 1px solid white;
  }
`;

export const PrintAreaBorder = styled.div`
  z-index: 2;
  background-color: transparent;
  border: 1px solid transparent;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.1s ease-in-out;
  border: ${(props) =>
    props.$hover ? "1px solid white" : "1px solid transparent"};
  @media (max-width: 768px) {
    border: ${(props) =>
      props.$drag ? "1px solid white" : "1px solid transparent"};
  }
`;

export const PrintAreaContainer = styled.div`
  background-color: transparent;
  position: absolute;
  display: flex;
  border: 1px solid transparent;
`;

export const PrintLogoInvisible = styled(PrintLogo)`
  opacity: 0;
  width: 100%;
  height: 100%;
`;

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #8a4b5e;
  padding: 1rem 2rem;
  max-width: 33rem;
  position: absolute;
  left: -2rem;
  z-index: 1000;
  top: -2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

  animation: ${(props) => (props.show ? fadeIn : fadeOut)} 0.3s ease-in-out;
`;

export const PopupTitle = styled.span`
  color: #fff;
  font-family: "Avenir-Heavy";
  font-size: 1.6rem;
  user-select: none;
  letter-spacing: 0.067px;
`;
export const PopupTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const PopupText = styled.span`
  color: #fff;
  font-family: "Avenir-Heavy";
  font-size: 1.6rem;
  user-select: none;
  letter-spacing: 0.067px;
  opacity: 0.8;
`;

export const Triangle = styled.div`
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 50px 37.5px 0;
  border-color: transparent #8a4b5e transparent transparent;
  transform: rotate(0deg);
  bottom: -2rem;
  right: 0;
`;

export const XIcon = styled(Xsvg)`
  cursor: pointer;
  position: absolute;
  width: 1.6rem;
  top: 0.5rem;
  right: 1rem;
  z-index: 10;

  fill: white;

  &:hover {
    fill: red;
  }
`;
