import React, { useEffect, useState } from "react";
import {
  Title,
  Subtitle,
  ButtonConfirm,
  ButtonsContainer,
  TitleContainer,
  ChevronBack,
  DeliverContainer,
  NewLocationButton,
  Container,
  AddIcon,
  Message,
  Address,
  LocationName,
  LocationContainer,
  Line,
  CheckIcon,
  Scroll,
  Gap,
} from "./PGMPages.styles";

import { ReactComponent as Gift } from "../../assets/icons/gift.svg";
import { toast } from "react-toastify";
import {
  getDeliveryAddressCompanyGiftingToken,
  setDeliveryAddressCompanyGiftingToken,
} from "../../store/slices/gift/asyncThunks";
import { useSelector } from "react-redux";
import {
  selectCompanyGiftingSetDeliveryAddressPending,
  selectDeliveryAddress,
  selectGetDeliveryAddressCompanyGiftingTokenPending,
} from "../../store/slices/gift/slice";
import { ReactComponent as Loading } from "../../assets/icons/loader-white.svg";
import MiniLoader from "../../components/MiniLoader/MiniLoader";

const ConfirmLocationComponent = ({ setTab, t, location, dispatch, token }) => {
  const [select, setSelect] = useState(false);
  const [newLocation, setNewLocation] = useState(false);
  const loading = useSelector(selectCompanyGiftingSetDeliveryAddressPending);
  const data = useSelector(selectDeliveryAddress);
  const getLoading = useSelector(
    selectGetDeliveryAddressCompanyGiftingTokenPending
  );

  useEffect(() => {
    dispatch(getDeliveryAddressCompanyGiftingToken({ token }))
      .unwrap()
      .then(() => {})
      .catch((err) => toast.error(t(err.message)));
  }, [dispatch, token]);

  const onConfirm = () => {
    dispatch(
      setDeliveryAddressCompanyGiftingToken({
        token,
        label: newLocation ? select.label : select.label,
        locationLat: newLocation
          ? select.locationLat
          : select.location.latitude,
        locationLng: newLocation
          ? select.locationLng
          : select.location.longitude,
        ...(!newLocation && { deliveryAddressId: select.id }),
      })
    )
      .unwrap()
      .then(() => {
        setTab("thank-you-to-setting");
      })
      .catch((err) => toast.error(t(err.message)));
  };

  useEffect(() => {
    if (location) {
      setSelect({
        label: location.address,
        locationLat: location.lat,
        locationLng: location.lng,
      });
      setNewLocation(true);
    }
  }, []);

  return (
    <Scroll>
      <Gap>
        <Container>
          <Gift width={178} height={222} />
          <Subtitle>{t("great-choice")}</Subtitle>
          <Message>{t("we-started-wrapping-your-gift")}</Message>
        </Container>

        <DeliverContainer>
          <Subtitle>{t("deliver-to")}</Subtitle>
          <NewLocationButton onClick={() => setTab("new-location")}>
            <AddIcon />
            {t("new-location")}
          </NewLocationButton>

          {getLoading ? (
            <MiniLoader />
          ) : (
            <div>
              {data?.length > 0 &&
                data?.map((address, index) => (
                  <LocationContainer
                    onClick={() => {
                      setSelect(address);
                      setNewLocation(false);
                    }}
                    key={address?.locationName}
                  >
                    {index === 0 && <Line $top />}
                    <LocationName>{t("recent-address")}</LocationName>
                    <Address>
                      {address?.locationName?.length > 65
                        ? address?.locationName.slice(0, 65) + "..."
                        : address?.locationName}
                    </Address>
                    <Line $bottom />
                    {select?.id === address?.id && <CheckIcon $24 />}
                  </LocationContainer>
                ))}

              {location && (
                <LocationContainer
                  onClick={() => {
                    setSelect({
                      label: location.address,
                      locationLat: location.lat,
                      locationLng: location.lng,
                    });
                    setNewLocation(true);
                  }}
                >
                  <LocationName>{t("new-address")}</LocationName>
                  <Address>
                    {location?.address?.length > 65
                      ? location?.address.slice(0, 65) + "..."
                      : location?.address}
                  </Address>
                  <Line $bottom />
                  {select?.label === location?.address && <CheckIcon $24 />}
                </LocationContainer>
              )}
            </div>
          )}
        </DeliverContainer>
      </Gap>

      <ButtonsContainer>
        {loading ? (
          <ButtonConfirm $center>
            <Loading height={25} />
          </ButtonConfirm>
        ) : (
          <ButtonConfirm
            onClick={!select ? () => {} : onConfirm}
            $center
            disabled={!select}
          >
            {t("confirm-location")}
          </ButtonConfirm>
        )}
      </ButtonsContainer>
    </Scroll>
  );
};

export default ConfirmLocationComponent;
