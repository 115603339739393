import React, { useEffect, useState } from "react";
import {
  MainContainer,
  Column,
  Graphic,
  Container,
  RightSide,
  LeftSide,
  EmojiContainer,
  CardEmployees,
  CardsEmploy,
  Title,
  Count,
  RightCard,
  WalletCard,
  CardColumn,
  SmallTitle,
  TitleSection,
  Value,
  Price,
  HeaderCard,
  MainColumn,
  StyledRow,
  OccasionRow,
  OccasionColumn,
  StyledPriceRow,
  ChartContainer,
  ChartWrapper,
  ChartLabelContainer,
  CharRow,
  ChartLabel,
  Basic,
  Essentials,
  OccasionDiv,
  LightText,
  HeaderWrapper,
} from "./Dashboard.styles";
import Sidebar from "../utils/Sidebar";
import { useTranslation } from "react-i18next";
import { ReactComponent as Gift } from "../../../assets/icons/gift.svg";
import AdminHeader from "../../../components/AdminHeader/AdminHeader";
import { useDispatch } from "react-redux";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import { getCurrencySymbol } from "../../../utils/constants";
import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";
import PieChart from "../../../components/PieChart/PieChart";
import { getRecipients } from "../../../store/slices/recipients/asyncThunks";
import { toast } from "react-toastify";
import Header from "../../../components/Header/Header";
import { selectedLocale } from "../../../store/slices/common/slice";

const Dashboard = () => {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const [secondCount, setSecondCount] = useState(0);
  const recipientsTotal = useSelector((state) => state.recipientInfo.total);
  const dispatch = useDispatch();
  const currentDate = new Date();
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < 423) {
        setCount(count + 1);
      } else {
        clearInterval(interval);
      }
    }, 0.1);

    return () => clearInterval(interval);
  }, [count]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondCount < 120) {
        setSecondCount(secondCount + 1);
      } else {
        clearInterval(interval);
      }
    }, 5);

    return () => clearInterval(interval);
  }, [secondCount]);

  useEffect(() => {
    // dispatch(getAdminDashboardData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRecipients({ page: 1, search: "" }))
      .unwrap()
      .then(() => {})
      .catch((error) => {
        toast.error(t(error.message));
      });
  }, [dispatch]);

  const data = [
    {
      id: 1,
      title: t("employees"),
      count: recipientsTotal,
      icon: "🧑‍💼👩‍💼",
      color: "rgba(236, 195, 113, 0.20)",
    },
    {
      id: 2,
      title: t("occasions"),
      count: 0,
      icon: "🥳",
      color: "rgba(133, 161, 172, 0.20)",
    },
    {
      id: 3,
      title: t("gifts.gifts"),
      count: 0,
      icon: <Gift width={36} height={36} />,
      color: "rgba(233, 67, 94, 0.20)",
    },
    {
      id: 4,
      title: t("events"),
      count: 0,
      icon: "🎉",
      color: "rgba(125, 100, 96, 0.20)",
    },
  ];

  const { company, state } = useSelector(selectBusinessUserInfo);

  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  const locale = useSelector(selectedLocale);
  const upgradePackage = showUpgradeBar && diff > 0 && state;
  const ar = locale === "ar";

  return (
    <MainContainer>
      <HeaderWrapper>
        <Header
          showLogin
          showSignUp
          showNav
          color
          showFavorite
          showCart
          showUser
        />
      </HeaderWrapper>
      <Sidebar />

      <MainColumn>
        <Column $upgradePackage={upgradePackage} $ar={ar}>
          <AdminHeader hideSearch />
          <Container>
            <LeftSide>
              <CardsEmploy>
                {data.map((e) => (
                  <CardEmployees key={e.id}>
                    <EmojiContainer style={{ background: e.color }}>
                      {e.icon}
                    </EmojiContainer>
                    <Title>{t(`${e.title}`)}</Title>
                    <Count>
                      <CountUp end={e.count} duration={1.5} />
                    </Count>
                  </CardEmployees>
                ))}
              </CardsEmploy>
              <CardColumn>
                <TitleSection>{t("gifts.gifts")}</TitleSection>
                <Graphic>{/* <BarChart /> */}</Graphic>
              </CardColumn>
              <CardColumn>
                <TitleSection>{t("wallet")}</TitleSection>
                <WalletCard>
                  <SmallTitle>{t("current-balance")}</SmallTitle>
                  <StyledPriceRow>
                    <div>
                      <Value>
                        <CountUp
                          end={company.walletBalance}
                          duration={1.5}
                          decimal=","
                          separator="."
                          decimals={2}
                        />
                      </Value>
                      <Price>{getCurrencySymbol("sar")}</Price>
                    </div>
                    {/* <ButtonCharge>{t("recharge")}</ButtonCharge> */}
                  </StyledPriceRow>
                </WalletCard>
              </CardColumn>
            </LeftSide>
            <RightSide>
              <RightCard>
                <HeaderCard>
                  <TitleSection>{t("budget")} in SR</TitleSection>
                  <StyledRow>
                    {/* <LightText>{t("show")}</LightText>
                    <Month>{t("this-month")}</Month> */}
                  </StyledRow>
                </HeaderCard>
                <ChartContainer>
                  {company.walletBalance && company.walletBalance > 0 ? (
                    <>
                      <ChartWrapper>
                        <PieChart />
                      </ChartWrapper>
                      <ChartLabelContainer>
                        <CharRow>
                          <Basic />
                          <ChartLabel>{t("remaining")}</ChartLabel>
                        </CharRow>
                        <CharRow>
                          <Essentials />
                          <ChartLabel>{t("in-progress")}</ChartLabel>
                        </CharRow>
                        {/* <CharRow>
                            <Enterprise />
                            <ChartLabel>{t("consumed")}</ChartLabel>
                          </CharRow> */}
                      </ChartLabelContainer>
                    </>
                  ) : (
                    <LightText>{t("wallet-is-empty")}</LightText>
                  )}
                </ChartContainer>
                <OccasionDiv>
                  <HeaderCard>
                    {/* <TitleSection>{t("upcoming-occasions")}</TitleSection> */}
                  </HeaderCard>
                  <OccasionColumn>
                    <OccasionRow>
                      {/* <StyledRow>
                        <Dot />
                        <StyledName>
                          {t("birthday")}:<Name> Mohammed Alghamdi</Name>
                        </StyledName>
                      </StyledRow>
                      <ButtonSeeMore>
                        <Chevron width={15} />
                      </ButtonSeeMore>
                    </OccasionRow>
                    <OccasionRow>
                      <StyledRow>
                        <Dot />
                        <StyledName>
                          {t("promotion")}:<Name> Mahmoud Fathi</Name>
                        </StyledName>
                      </StyledRow>
                      <ButtonSeeMore>
                        <Chevron width={15} />
                      </ButtonSeeMore> */}
                    </OccasionRow>
                  </OccasionColumn>
                </OccasionDiv>
              </RightCard>
            </RightSide>
          </Container>
        </Column>
      </MainColumn>
    </MainContainer>
  );
};

export default Dashboard;
