import {
  getCouponsDashboard,
  getCoupons,
  createCoupon,
  removeCoupon,
  editCoupon,
  getCouponById,
} from "./asyncThunks";

const findCouponInDashboardList = (dashboard, couponId) => {
  if (dashboard.active.some((coupon) => coupon.id === couponId)) {
    return "active";
  } else if (dashboard.deactivated.some((coupon) => coupon.id === couponId)) {
    return "deactivated";
  }
  return null;
};

export const getCouponsDashboardBuilder = (builder) => {
  builder
    .addCase(getCouponsDashboard.pending, (state) => {
      state.couponsDashboard.loading = true;
      state.couponsDashboard.error = null;
    })
    .addCase(getCouponsDashboard.fulfilled, (state, action) => {
      state.couponsDashboard.data = action.payload.data;
      state.couponsDashboard.maxReached = action.payload.maxReached;
      state.couponsDashboard.perPage = action.payload.perPage;
      state.couponsDashboard.total = action.payload.total;
      state.couponsDashboard.loading = false;
    })
    .addCase(getCouponsDashboard.rejected, (state, action) => {
      state.couponsDashboard.loading = false;
      state.couponsDashboard.error = action.error.message;
    });
};
export const getCouponsBuilder = (builder) => {
  builder
    .addCase(getCoupons.pending, (state) => {
      state.coupons.loading = true;
      state.coupons.error = null;
    })
    .addCase(getCoupons.fulfilled, (state, action) => {
      state.coupons.data = action.payload.data;
      state.coupons.maxReached = action.payload.maxReached;
      state.coupons.perPage = action.payload.perPage;
      state.coupons.total = action.payload.total;
      state.coupons.loading = false;
    })
    .addCase(getCoupons.rejected, (state, action) => {
      state.coupons.loading = false;
    });
};

export const createCouponBuilder = (builder) => {
  builder
    .addCase(createCoupon.pending, (state) => {
      state.createCouponLoading = true;
    })
    .addCase(createCoupon.fulfilled, (state, action) => {
      state.createCouponLoading = false;
    })
    .addCase(createCoupon.rejected, (state, action) => {
      state.createCouponLoading = false;
    });
};

export const removeCouponBuilder = (builder) => {
  builder
    .addCase(removeCoupon.pending, (state) => {
      state.removeCouponLoading = true;
      state.removeCouponError = null;
    })
    .addCase(removeCoupon.fulfilled, (state, action) => {
      const updatedData = [
        ...state.coupons.data.filter((c) => c.id !== action.payload),
      ];
      const listToUpdate = findCouponInDashboardList(
        state.couponsDashboard.data,
        action.payload
      );

      if (listToUpdate) {
        state.couponsDashboard.data[listToUpdate] = state.couponsDashboard.data[
          listToUpdate
        ].filter((coupon) => coupon.id !== action.payload);
        state.couponsDashboard.data[`${listToUpdate}Count`] =
          state.couponsDashboard.data[`${listToUpdate}Count`] - 1;
      }
      state.coupons.data = updatedData;
      state.coupons.total = state.coupons.total - 1;
      state.removeCouponError = null;
      state.removeCouponLoading = false;
    })

    .addCase(removeCoupon.rejected, (state, action) => {
      state.removeCouponLoading = false;
      state.removeCouponError = action.error.message;
    });
};

export const editCouponBuilder = (builder) => {
  builder.addCase(editCoupon.pending, (state) => {
    state.editCouponLoading = true;
  });
  builder.addCase(editCoupon.fulfilled, (state) => {
    state.editCouponLoading = false;
  });
  builder.addCase(editCoupon.rejected, (state) => {
    state.editCouponLoading = false;
  });
};

export const getCouponByIdBuilder = (builder) => {
  builder.addCase(getCouponById.pending, (state) => {
    state.getCouponByIdLoading = true;
  });
  builder.addCase(getCouponById.fulfilled, (state, action) => {
    state.getCouponByIdLoading = false;
    state.couponData = action.payload;
  });
  builder.addCase(getCouponById.rejected, (state) => {
    state.getCouponByIdLoading = false;
  });
};
