import React, { useEffect, useState } from "react";
import {
  Text,
  ButtonNext,
  ButtonBack,
  Buttons,
  RowInputBig,
  DropDown,
  DropDownValue,
  DropDownMenu,
  Relative,
  Attributes,
  Categories,
  CategoryText,
  Category,
  RowVariables,
  TextVariable,
  PhotosContainer,
  Photo,
  Title,
  ContentColumn,
  ContentRow,
  VariableName,
  PhotoContainer,
  XIcon,
  Loader,
} from "../Steps.styles";
import UseAnimations from "react-useanimations";
import trash2 from "react-useanimations/lib/trash2";
import { ReactComponent as Chevron } from "../../../assets/icons/chevronDown.svg";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAttributesByCategory } from "../../../store/slices/marketplace/asyncThunks";
import {
  addItemAttributes,
  addItemCategoryId,
  addItemPhotos,
  attributesByCategory,
  attributesByCategoryLoading,
  setAttributes,
  setVariablePhoto,
} from "../../../store/slices/marketplace/marketplaceSlice";
import Modal2 from "../../Modal/Modal2";
import AppLoader from "../../AppLoader";

const AddItemStepAttributes = ({ onNext, onBack, action, photos }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const attributes = useSelector(attributesByCategory);
  const loading = useSelector(attributesByCategoryLoading);
  const categoryId = useSelector(addItemCategoryId);
  const dispatch = useDispatch();
  const selectedAttributes = useSelector(addItemAttributes);
  const [modal, setModal] = useState(false);

  const [selectedAttributeId, setSelectedAttributeId] = useState(null);
  const [attributeValue, setAttributeValue] = useState({});
  const addItemPhotosArr = useSelector(addItemPhotos);

  useEffect(() => {
    if (categoryId) {
      dispatch(getAttributesByCategory(categoryId[0]));
    }
  }, [dispatch, categoryId, action]);

  const handleSubcategoryClick = (attribute) => {
    if (!selectedAttributes.find((c) => c.id === attribute.id)) {
      setAttributeValue([
        ...selectedAttributes,
        { ...attribute, variables: [] },
      ]);
      dispatch(
        setAttributes([...selectedAttributes, { ...attribute, variables: [] }])
      );
    }
    setOpen(false);
  };

  const handleVariableSelect = (c, id, value) => {
    const attributeIndex = selectedAttributes.findIndex(
      (attr) => attr.id === id
    );

    let updatedAttributes = [...selectedAttributes];
    let attribute = updatedAttributes[attributeIndex];

    if (
      c.value === "color" &&
      attribute.variables.some((variable) => variable.id === value.id)
    ) {
      setModal(false);
    } else {
      setSelectedAttributeId(value?.id);
      setModal(true);
    }

    if (attributeIndex !== -1) {
      let updatedAttributes = [...selectedAttributes];
      let attribute = updatedAttributes[attributeIndex];

      let newAttribute = { ...attribute };

      if (attribute.variables.some((variable) => variable.id === value.id)) {
        // Variable already selected, remove it
        newAttribute.variables = attribute.variables.filter(
          (variable) => variable.id !== value.id
        );
      } else {
        // Add the variable to the existing attribute
        newAttribute.variables = [
          ...attribute.variables,
          { id: value.id, value },
        ];
      }

      updatedAttributes[attributeIndex] = newAttribute;
      dispatch(setAttributes(updatedAttributes));
    }
  };

  const handlePhotoClick = (photoIndex, id, photo) => {
    dispatch(
      setVariablePhoto({
        variableId: id,
        photo,
        photoIndexes: [photoIndex],
        attributeValue,
      })
    );

    setModal(false);
  };

  const removeAttribute = (obj) => {
    const tmp = selectedAttributes.filter(
      (attribute) => attribute.id !== obj.id
    );
    setAttributeValue(tmp);
    dispatch(setAttributes(tmp));
  };

  return (
    <>
      <ContentRow>
        <ContentColumn>
          <RowInputBig>
            <Text>{t("add-attribute")}:</Text>
            <Relative>
              <DropDown $open={open} onClick={() => setOpen(!open)}>
                <DropDownValue>{t("select-attribute")}</DropDownValue>
                <Chevron />
              </DropDown>
              {open && (
                <DropDownMenu>
                  {loading ? (
                    <Loader>
                      <AppLoader size={20} />
                    </Loader>
                  ) : (
                    attributes?.map((attribute, index) => (
                      <Attributes
                        key={index}
                        onClick={() => handleSubcategoryClick(attribute)}
                      >
                        {attribute.value}
                      </Attributes>
                    ))
                  )}
                </DropDownMenu>
              )}
            </Relative>
          </RowInputBig>

          {selectedAttributes?.map((attribute, index) => (
            <RowVariables key={index}>
              <TextVariable>
                {t("attribute")} {index + 1}: {attribute.value}
              </TextVariable>
              <Categories>
                {attribute?.marketplaceSubcategoryItems?.map((value, index) => (
                  <Category
                    key={index}
                    $active={attribute.variables.some(
                      (variable) => variable.id === value.id
                    )}
                    onClick={() =>
                      handleVariableSelect(attribute, attribute.id, value)
                    }
                  >
                    <CategoryText
                      $active={attribute.variables.some(
                        (variable) => variable.id === value.id
                      )}
                    >
                      {value.value}
                    </CategoryText>
                  </Category>
                ))}
              </Categories>
              <UseAnimations
                onClick={() => removeAttribute(attribute)}
                className="pointer"
                animation={trash2}
                size={32}
                strokeColor="#8A4B5E"
              />
            </RowVariables>
          ))}
        </ContentColumn>
        <PhotosContainer style={{ marginTop: 133 }}>
          {selectedAttributes.map((atr) =>
            atr.variables.map(
              (v) =>
                v.value?.photo && (
                  <PhotoContainer key={v.id}>
                    <XIcon
                      onClick={() => {
                        handleVariableSelect(atr, atr.id, v);
                      }}
                    />
                    <Photo src={v.value?.photo} />
                    <VariableName>{t(v.value.value)}</VariableName>
                  </PhotoContainer>
                )
            )
          )}
        </PhotosContainer>
      </ContentRow>

      <Buttons>
        <ButtonBack onClick={onBack}>{t("back")}</ButtonBack>
        <ButtonNext onClick={onNext}>{t("next")}</ButtonNext>
      </Buttons>

      <Modal2
        hideScrollToTop
        border={"2px solid #8A4B5E"}
        bg={"linear-gradient(360deg, #a87283 -100%, #8A4B5E 100%)"}
        onClose={() => setModal(false)}
        open={modal}
        height={"fit-content"}
        width={"fit-content"}
      >
        <Title>{t("set-photo-for-this-color")}</Title>
        <PhotosContainer>
          {addItemPhotosArr.map((photo, index) => {
            return (
              <Photo
                key={index}
                src={photo.url}
                onClick={() =>
                  handlePhotoClick(index, selectedAttributeId, photo.url)
                }
              />
            );
          })}
          {photos.map((photo, index) => {
            const imageURL = URL.createObjectURL(photo);
            return (
              <Photo
                key={index}
                src={imageURL}
                onClick={() =>
                  handlePhotoClick(
                    index + addItemPhotosArr.length,
                    selectedAttributeId,
                    imageURL
                  )
                }
              />
            );
          })}
        </PhotosContainer>
      </Modal2>
    </>
  );
};
export default AddItemStepAttributes;
