import styled from "styled-components";

export const CardHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ImgContainer = styled.div`
  position: absolute;
  right: ${(props) => (props.$ar ? "unset" : "-45px")};
  left: ${(props) => (props.$ar ? "-45px" : "unset")};
  top: -30px;
  @media (max-width: 410px) {
    right: ${(props) => (props.$ar ? "unset" : "-65px")};
    left: ${(props) => (props.$ar ? "-65px" : "unset")};
    top: -40px;
  }
`;

export const DescBox = styled.div`
  padding-top: 20px;
  /* padding: 0px 30px 30px 30px; */
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ItemName = styled.span`
  font-family: "Avenir-Medium";
  font-size: 20px;
  letter-spacing: -0.178px;
  @media (max-width: 1290px) {
    font-size: 12px;
  }
`;

export const ItemPrice = styled.span`
  text-align: center;
  font-family: "Avenir";
  font-size: 18px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.178px;
  @media (max-width: 1290px) {
    font-size: 12px;
  }
`;

export const Row = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 24px;
  padding-top: 15px;
  justify-content: space-between;
  gap: 20px;
  width: 280px;
  height: 531px;
  border: ${(props) =>
    props.$selected ? "10px solid #85A195" : "1px solid rgb(1, 1, 1, 0.15)"};
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(1, 0, 1, 0.1);

  transition: all ease-in 0.4s;
  /* transform: ${(props) =>
    !props.$ar ? "translateX(-150%)" : "translateX(150%)"}; */
  @media (max-width: 1160px) {
    flex-wrap: wrap;
  }
`;
export const SelectedText = styled.span`
  color: #85a195;
  font-family: "Avenir-Heavy";
  font-size: 20px;
  letter-spacing: -0.178px;
  @media (max-width: 1290px) {
    font-size: 12px;
  }
`;

export const SelectContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SelectedImageContainer = styled.img`
  user-select: none;
  background-color: transparent;
  width: 65px;
  height: 65px;
`;

export const ItemPhoto = styled.img`
  object-fit: contain;
  user-select: none;
  width: 25rem;
  height: 15rem;
  border-radius: 2rem;
`;
