import React, { useEffect, useState } from "react";

const ProgressiveImg = ({
  placeholderSrc,
  imageRef,
  src,
  alt,
  onLoad,
  ...props
}) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  return (
    <img
      ref={imageRef}
      onLoad={onLoad}
      {...{ src: imgSrc, ...props }}
      alt={alt || ""}
    />
  );
};
export default ProgressiveImg;
