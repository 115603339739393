import React, { useEffect, useRef, useState } from "react";
import {
  MainContainer,
  Column,
  CardProduct,
  SortContainer,
  SortName,
  CardProductName,
  ProductInfo,
  Row,
  Price,
  Icons,
  ImageContainer,
  StyledInfinityScroll,
  HeaderWrapper,
  Layout,
  Text,
  StyledMenuContainer,
  IconContainer,
  ImageDiv,
  Img,
  DotsContainer,
} from "./MarketplacePage.styles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UseAnimations from "react-useanimations";
import heart from "react-useanimations/lib/heart";
import {
  getFavoriteMarketplaceItemsAll,
  toggleFavoriteMarketplaceItem,
} from "../../../store/slices/marketplace/asyncThunks";
import {
  resetAddItem,
  selectFavoriteItems,
  selectFavoriteItemsData,
} from "../../../store/slices/marketplace/marketplaceSlice";

import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";
import { priceFormatter } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer.js";
import Header from "../../../components/Header/Header";
import { getCurrencySymbol } from "../../../utils/constants";
import { t } from "i18next";
import Sidebar from "../utils/Sidebar";
import SkeletonMarketplaceSortLoader from "../../../components/SkeletonLoader/SkeletonMarketplaceSortLoader/SkeletonMarketplaceSortLoader.js";
import SkeletonMarketplaceLoader from "../../../components/SkeletonLoader/SkeletonMarketplaceLoader/SkeletonMarketplaceLoader.js";
import { useGetCategoriesQuery } from "../../../store/slices/marketplace/marketplaceApi.js";
import openToast from "../../../utils/toast.js";
import { selectedLocale } from "../../../store/slices/common/slice.js";
import Dots from "../../../components/Dots/Dots.js";
import { useSwipeable } from "react-swipeable";
import AppLoader from "../../../components/AppLoader.js";

function ProductCard({ product, itemDetails, reverse }) {
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";
  const [activeIndex, setActiveIndex] = useState(0);

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setActiveIndex((prevIndex) =>
        prevIndex === product?.photos.length - 1 ? 0 : prevIndex + 1
      );
    },
    onSwipedRight: () => {
      setActiveIndex((prevIndex) =>
        prevIndex === 0 ? product?.photos.length - 1 : prevIndex - 1
      );
    },
  });

  return (
    <CardProduct>
      <Icons>
        <IconContainer>
          <UseAnimations
            animation={heart}
            fillColor="#8A4B5E"
            reverse={reverse}
            strokeColor="#8A4B5E"
            size={32}
            onClick={() => {
              dispatch(
                toggleFavoriteMarketplaceItem({ marketplaceItemId: product.id })
              )
                .unwrap()
                .catch((err) =>
                  openToast(
                    toastId,
                    "marketplace-fav-item",
                    "error",
                    t(err.message)
                  )
                );
            }}
          />
        </IconContainer>
      </Icons>

      <ImageContainer onClick={itemDetails} {...handlers}>
        <>
          {product?.photos?.length > 1 ? (
            <ImageDiv>
              <Img
                $contain={!!product.productId}
                alt="PhotoProduct"
                effect="blur"
                wrapperProps={{
                  style: { transitionDelay: ".3s" },
                }}
                src={product?.photos[activeIndex]?.url}
              />

              <DotsContainer>
                <Dots
                  bg
                  rotate
                  total={product?.photos?.length}
                  activeIndex={activeIndex}
                  onDotClick={handleDotClick}
                />
              </DotsContainer>
            </ImageDiv>
          ) : (
            product?.photos && (
              <Img
                $contain={!!product.productId}
                alt="PhotoProduct"
                effect="blur"
                wrapperProps={{
                  style: { transitionDelay: ".3s" },
                }}
                src={product?.photos[0]?.url}
              />
            )
          )}
        </>
      </ImageContainer>

      <ProductInfo>
        <CardProductName
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {ar && product?.nameAr ? product?.nameAr : product?.name}
        </CardProductName>
        <Row>
          <Price>
            {t("from")} {priceFormatter(product?.price ?? product?.samplePrice)}{" "}
            {getCurrencySymbol(product?.currency)}
          </Price>
          {product.minQuantity && (
            <Price>
              Min Qty:{" "}
              <Price style={{ fontWeight: 800 }}>{product.minQuantity}</Price>
            </Price>
          )}
        </Row>
      </ProductInfo>
    </CardProduct>
  );
}

const FavoritePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const items = useSelector(selectFavoriteItemsData);
  const favData = useSelector(selectFavoriteItems);
  const businessUser = useSelector(selectBusinessUserInfo);
  const [selectedCategory, setSelectedCategory] = useState(-1);

  useEffect(() => {
    dispatch(resetAddItem());
  }, [dispatch]);

  const { data: categories, isFetching: categoriesPending } =
    useGetCategoriesQuery({ search: "" });

  useEffect(() => {
    dispatch(
      getFavoriteMarketplaceItemsAll({
        page: 1,
        sortBy: "createdAt",
        order: "DESC",
        search: "",
        category: (selectedCategory ?? "") !== -1 ? selectedCategory : "",
      })
    );
  }, [dispatch, selectedCategory]);

  const loadMoreItems = () => {
    dispatch(
      getFavoriteMarketplaceItemsAll({
        page: favData.page + 1,
        sortBy: "createdAt",
        order: "DESC",
        search: "",
        category: (selectedCategory ?? "") !== -1 ? selectedCategory : "",
      })
    );
  };
  const handleItemClick = (itemId) => {
    navigate(`/marketplace/${itemId}`);
  };

  const showUpgradeBar = businessUser.company.packageType === "Free";
  let diff =
    new Date(businessUser.company?.packageExpiration).getTime() -
    new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  const locale = useSelector(selectedLocale);
  const upgradePackage = showUpgradeBar && diff > 0 && businessUser.state;
  const ar = locale === "ar";
  return (
    <MainContainer>
      {businessUser.loggedIn && <Sidebar categoriesBar />}
      {businessUser.loggedIn && (
        <HeaderWrapper>
          <Header showLogin showNav color showUser showFavorite showCart />

          <StyledMenuContainer $loggedIn={businessUser.loggedIn}>
            {categoriesPending ? (
              <SortContainer>
                <SkeletonMarketplaceSortLoader />
              </SortContainer>
            ) : (
              categories?.data?.categories?.length && (
                <SortContainer>
                  {categoriesPending ? (
                    <SkeletonMarketplaceSortLoader />
                  ) : (
                    <SortName
                      selected={-1 === selectedCategory}
                      key={-1}
                      onClick={() => setSelectedCategory(-1)}
                    >
                      {t("all")}
                    </SortName>
                  )}
                  {categories?.data?.categories?.map((s) =>
                    categoriesPending ? (
                      <SkeletonMarketplaceSortLoader key={s.id} />
                    ) : (
                      <SortName
                        selected={s.id === selectedCategory}
                        key={s.id}
                        onClick={() => setSelectedCategory(s.id)}
                      >
                        {s.name}
                      </SortName>
                    )
                  )}
                </SortContainer>
              )
            )}
          </StyledMenuContainer>
        </HeaderWrapper>
      )}

      <Layout $ar={ar} $loggedIn={businessUser.loggedIn}>
        <Column $upgradePackage={upgradePackage}>
          {/* <SectionTitle>{t("favorites")}</SectionTitle>
            <BackRow onClick={() => navigate(-1)}>
              <Chevron />
              <BackText>{t("back")}</BackText> 
           </BackRow> */}

          {favData.loading ? (
            <SkeletonMarketplaceLoader loggedIn={businessUser.loggedIn} />
          ) : (
            <>
              {!favData.loading && items?.length <= 0 && (
                <Text>{t("no-items-in-favorites")}</Text>
              )}
              {items?.length > 0 && (
                <StyledInfinityScroll
                  dataLength={items.length}
                  next={loadMoreItems}
                  hasMore={!favData.maxReached}
                  loader={<AppLoader />}
                >
                  {items?.map((product) => (
                    <ProductCard
                      key={product.id}
                      itemDetails={() => {
                        handleItemClick(product.id);
                      }}
                      product={product}
                      reverse={true}
                    />
                  ))}
                </StyledInfinityScroll>
              )}
            </>
          )}
        </Column>
      </Layout>
      {!businessUser.loggedIn && <Footer />}
    </MainContainer>
  );
};

export default FavoritePage;
