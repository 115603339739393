import styled, { keyframes } from "styled-components";

export const Title = styled.span`
  font-family: "FrankfurterEF-Medium";
  font-size: 40px;
  font-weight: 500;
  line-height: 3.2rem; /* 64% */
  margin-bottom: 60px;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CloseContainer = styled.div`
  border-radius: 10px;
  background: #8a4b5e;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  left: -10px;
`;

export const Text = styled.span`
  color: rgba(0, 0, 0, 0.5);
  padding-top: 30px;
  font-family: "Avenir-Medium";
  font-size: 20px;
  line-height: 32px; /* 160% */
`;
export const Button = styled.button`
  border-radius: 10px;
  background: #8a4b5e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  border: none;
  outline: none;
  cursor: pointer;
  height: 55px;
  color: #fff;
  font-family: "FrankfurterEF-Medium";
  font-size: 30px;
  line-height: 32px; /* 106.667% */
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  justify-content: "center";
  align-items: "center";
  padding-top: 80px;
`;

export const Input = styled.input`
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  width: 100%;
  font-size: 24px;
  color: #000;
  font-family: "Avenir";
  font-weight: 500;
  line-height: 32px;
  ::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Loading = styled.div`
  border: 4px solid #b3b3b3;
  border-left: 4px solid #fff;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  animation: ${spin} 1s linear infinite;
  align-self: center;
  justify-content: center;
`;
