import { createAsyncThunk } from "@reduxjs/toolkit";
import authRecipientService from "../../../api/services/recipientUser";

export const signUpRecipient = createAsyncThunk(
  "auth/sign-up-recipient",
  async (requestData) => {
    return await authRecipientService.signUp(requestData);
  }
);

export const validateCode = createAsyncThunk(
  "auth/verify/code",
  async (requestData) => {
    return await authRecipientService.verifyUser(requestData);
  }
);

export const login = createAsyncThunk(
  "auth/login-recipient",
  async (requestData) => {
    return await authRecipientService.loginWithUsername(requestData);
  }
);

export const logoutRecipient = createAsyncThunk(
  "auth/logout-recipient",
  async (_, thunkAPI) => {
    thunkAPI.dispatch({ type: "logout" });
    thunkAPI.dispatch({ type: "reset" });

    return await authRecipientService.logout();
  }
);

export const postUserInfo = createAsyncThunk(
  "user/post-user-info",
  async (requestData) => {
    return await authRecipientService.postUsersInfo(requestData);
  }
);

export const fetchUserInfo = createAsyncThunk(
  "user/fetch-user-info",
  async (requestData) => {
    return await authRecipientService.fetchUserInfo(requestData);
  }
);
