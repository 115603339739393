import React, { useRef, useState } from "react";
import { ButtonBack, Buttons } from "../Categories/CategoriesPage.styles";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  CategoryName,
  Row2,
  Subtitle,
  SelectedSubcategoryContainer,
  Step2Content,
  AddCategoryContainer,
  Input,
  CategoryContent,
  IconsContainer,
  CategoryContentContainer,
  Row3,
} from "./AttributesPage.styles";
import UseAnimations from "react-useanimations";
import trash2 from "react-useanimations/lib/trash2";
import { ReactComponent as Add } from "../../../../assets/icons/add-filled.svg";
import { ReactComponent as EditPencil } from "../../../../assets/icons/edit-pencil.svg";
import {
  createVariable,
  deleteVariable,
  editVariable,
} from "../../../../store/slices/marketplace/asyncThunks";
import { toast } from "react-toastify";
import {
  selectCreateVariablePending,
  selectDeleteVariablePending,
  selectEditVariablePending,
} from "../../../../store/slices/marketplace/marketplaceSlice";
import { useSelector } from "react-redux";
import ButtonComponent from "../../../../components/ButtonAdd/ButtonAdd";
import openToast from "../../../../utils/toast";
import AppLoader from "../../../../components/AppLoader";

const AddSubcategoryItemValue = ({
  selectedSubcategory,
  setActiveStep,
  selectedCategory,
  attributes,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showAddInput, setShowAddInput] = useState(false);
  const [newVariableName, setNewVariableName] = useState("");
  const [editFormShow, setEditFormShow] = useState(false);
  const [editingVariableId, setEditingVariableId] = useState(null);
  const [variableValue, setVariableValue] = useState("");
  const toastId = useRef(null);

  const createVariablePending = useSelector(selectCreateVariablePending);
  const deleteVariablePending = useSelector(selectDeleteVariablePending);
  const editVariablePending = useSelector(selectEditVariablePending);
  const [deleteVariableId, setDeleteVariableId] = useState(null);

  const handleDeleteVariable = (id) => {
    setDeleteVariableId(id);
    dispatch(deleteVariable(id))
      .unwrap()
      .then(() => {
        toast.success(t("variable-delete-successfully"));
      })
      .catch((error) => {
        openToast(toastId, "delete-variable", "error", t(error.message));
      });
  };

  const handleCreateVariable = (subcategoryId) => {
    if (!newVariableName) {
      openToast(toastId, "create-variable", "error", t("fill-variable-value"));
      return;
    }
    dispatch(
      createVariable({ value: newVariableName, subcategoryId: subcategoryId })
    )
      .unwrap()
      .then(() => {
        toast.success(t("variable-added-successfully"));
        setNewVariableName("");
      })
      .catch((error) => {
        openToast(toastId, "create-variable", "error", t(error.message));
      });
  };

  const filteredAttributes = attributes
    .find((attribute) => attribute.id === selectedCategory)
    ?.marketplaceSubcategories.find(
      (subcategory) => subcategory.id === selectedSubcategory
    );

  const handleEditCancel = () => {
    setEditFormShow(false);
    setEditingVariableId(null);
  };

  const handleEditSubcategoryItem = (itemId) => {
    setEditingVariableId(null);
    const selectedVariable =
      filteredAttributes?.marketplaceSubcategoryItems?.find(
        (item) => item.id === itemId
      );

    if (selectedVariable) {
      setEditFormShow(true);
      setVariableValue(selectedVariable.value);
      setEditingVariableId(selectedVariable.id);
    }
  };

  const handleUpdateAction = () => {
    if (!variableValue) {
      openToast(toastId, "update-variable", "error", t("fill-variable-value"));
      return;
    }
    dispatch(
      editVariable({
        value: variableValue,
        subcategoryItemId: editingVariableId,
      })
    )
      .unwrap()
      .then(() => {
        toast.success(t("variable-updated-successfully"));
      })
      .catch((error) => {
        openToast(toastId, "update-variable", "error", t(error.message));
      });
  };

  return (
    <>
      <Step2Content>
        {selectedSubcategory && (
          <Row2>
            <SelectedSubcategoryContainer>
              <Subtitle>{t("selected-attribute")}</Subtitle>
              <CategoryName>{filteredAttributes?.value}</CategoryName>
            </SelectedSubcategoryContainer>
            {filteredAttributes?.marketplaceSubcategoryItems?.length > 0 && (
              <>
                <Subtitle style={{ marginRight: 40 }}>
                  {t("variables-subtitle")}
                </Subtitle>

                <CategoryContent>
                  {filteredAttributes?.marketplaceSubcategoryItems?.map(
                    (item) => (
                      <CategoryContentContainer key={item.id} style={{}}>
                        <CategoryName key={item.id} style={{ marginLeft: -40 }}>
                          {item.value}
                        </CategoryName>
                        <IconsContainer>
                          {deleteVariablePending &&
                          deleteVariableId === item.id ? (
                            <AppLoader />
                          ) : (
                            <UseAnimations
                              className="pointer"
                              animation={trash2}
                              size={32}
                              strokeColor="#8A4B5E"
                              style={{ width: 26, height: 26 }}
                              onClick={() => handleDeleteVariable(item.id)}
                            />
                          )}
                          <EditPencil
                            className="edit-icon"
                            fill="#8A4B5E"
                            style={{
                              marginTop: 6,
                              width: 26,
                              height: 26,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowAddInput(false);
                              setEditFormShow(true);
                              handleEditSubcategoryItem(item.id);
                            }}
                          />
                        </IconsContainer>
                      </CategoryContentContainer>
                    )
                  )}
                </CategoryContent>
              </>
            )}
            {filteredAttributes?.marketplaceSubcategoryItems?.length <= 0 && (
              <Subtitle>{t("no-variables")}</Subtitle>
            )}
            <Add
              onClick={() => {
                setShowAddInput(true);
                setEditFormShow(false);
              }}
              className="pointer"
              style={{ marginTop: 2, width: 26, height: 26 }}
            />
            {showAddInput && (
              <AddCategoryContainer>
                <Row3>
                  <Subtitle>{t("add-attribute-subtitle")}</Subtitle>
                  <Input
                    type="text"
                    placeholder={t("variable-name")}
                    value={newVariableName}
                    onChange={(e) => setNewVariableName(e.target.value)}
                  />
                  <ButtonComponent
                    title={t("add")}
                    loading={createVariablePending}
                    onClick={() => handleCreateVariable(selectedSubcategory)}
                  />
                  <ButtonComponent
                    title={t("cancel")}
                    onClick={() => setShowAddInput(false)}
                  />
                </Row3>
              </AddCategoryContainer>
            )}
            {editFormShow && (
              <AddCategoryContainer>
                <Row3>
                  <Subtitle>{t("edit-attribute")}</Subtitle>
                  <Input
                    type="text"
                    placeholder={t("edit-variable-value")}
                    value={variableValue}
                    onChange={(e) => setVariableValue(e.target.value)}
                  />

                  <ButtonComponent
                    title={t("edit")}
                    loading={editVariablePending}
                    onClick={() => handleUpdateAction()}
                  />
                  <ButtonComponent
                    title={t("cancel")}
                    onClick={() => handleEditCancel()}
                  />
                </Row3>
              </AddCategoryContainer>
            )}
          </Row2>
        )}
      </Step2Content>

      <Buttons>
        <ButtonBack onClick={() => setActiveStep(1)}>{t("back")}</ButtonBack>
      </Buttons>
    </>
  );
};

export default AddSubcategoryItemValue;
