import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  MainContainer,
  Title,
  Form,
  StyledText,
  InputWrapper,
  Input,
  ButtonContainer,
  SubmitButton,
  TextDiv,
  CreateText,
  ForgotPw,
  ForgotPwContainer,
  StyledMailIcon,
  StyledLockIcon,
  StyledVisibility,
  StyledVisibilityOff,
  OuterContainer,
  StyledTextDiv,
  HeaderWrapper,
} from "./LoginPage.style";
import { login } from "../../../store/slices/auth/asyncThunks";
import {
  isBusinessUserLoggedIn,
  setBusinessUserInfo,
} from "../../../store/slices/businessUser/slice";
import { selectLoggedIn } from "../../../store/slices/authRecipient/slice";
import { selectLoginPending } from "../../../store/slices/auth/slice";
import { selectedLocale } from "../../../store/slices/common/slice";
import Loader from "../../../components/Loader/Loader";
import { ReactComponent as Loading } from "../../../assets/icons/loader-white.svg";
import { getBusinessRulesVariables } from "../../../store/slices/common/asyncThunks";
import logService from "../../../api/services/log";
import openToast from "../../../utils/toast";
import Icon from "../../../components/Icon";
import { isValidEmail } from "../../../utils/utils";

const LoginPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visibility, setVisibility] = useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [error, setError] = useState(false);
  const loginLoading = useSelector(selectLoginPending);
  const loggedIn = useSelector(isBusinessUserLoggedIn);
  const loggedInRecipient = useSelector(selectLoggedIn);
  const selectedLanguage = useSelector(selectedLocale);
  const location = useLocation();
  const toastId = useRef();
  const redirectBack = location.state?.redirect;

  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {};
  }, []);

  const handleLogin = () => {
    setCheckValidation(true);
    if (!email || !password) {
      openToast(
        toastId,
        "login",
        "error",
        t("please-enter-correct-email-and-password")
      );
      return;
    }
    dispatch(login({ email, password }))
      .unwrap()
      .then((response) => {
        const { businessUser } = response;
        dispatch(setBusinessUserInfo(businessUser));
        dispatch(getBusinessRulesVariables());
        if (!businessUser.verified) {
          toast.warning(t("please-check-your-email-and-verify-your-account"));
        }
      })
      .catch(async (err) => {
        openToast(toastId, "login", "error", t(err.message));
        const excludeErrors = ["Wrong password", "Business user not found"];
        if (!excludeErrors.includes(err.message)) {
          await logService.createErrorLog({
            type: "bp_log_in_error",
            data: JSON.stringify({ error: err.message, email }),
          });
        }
      });
  };
  const [checkValidation, setCheckValidation] = useState(false);

  const [headerVisible, setHeaderVisible] = useState(true);
  const [lastScrollUpY, setLastScrollUpY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(null);
  const lastScrollTopRef = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      const lastScrollTop = lastScrollTopRef.current;

      if (currentScrollTop === 0) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
        setScrollDirection(null);
        lastScrollTopRef.current = currentScrollTop;
        return;
      }

      if (currentScrollTop > lastScrollTop) {
        setScrollDirection("down");
        setLastScrollUpY(currentScrollTop);
      } else {
        setScrollDirection("up");
      }

      if (scrollDirection === "up" && currentScrollTop < lastScrollUpY - 200) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
      }

      if (
        scrollDirection === "down" &&
        currentScrollTop > lastScrollUpY + 200
      ) {
        setHeaderVisible(false);
      }

      lastScrollTopRef.current = currentScrollTop;
    };

    const handleScrollDebounced = () => {
      requestAnimationFrame(handleScroll);
    };

    window.addEventListener("scroll", handleScrollDebounced);

    return () => {
      window.removeEventListener("scroll", handleScrollDebounced);
    };
  }, [scrollDirection]);

  const handleVisibilityToggle = () => {
    setVisibility(!visibility);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [handleKeyPress]);

  if (loggedIn) {
    if (redirectBack) {
      navigation(-1);
    } else {
      return <Navigate to="/admin-portal/dashboard" />;
    }
  }

  if (loggedInRecipient) {
    return <Navigate to="/user-portal/recipient/pick-a-gift" />;
  }

  return (
    <>
      <MainContainer>
        {!loggedIn ? (
          <HeaderWrapper
            style={{
              opacity: headerVisible ? 1 : 0,
              transition: "opacity 0.5s",
            }}
          >
            <Header
              showNav
              color
              hideSearch
              showLogin
              showSignUp
              display={headerVisible}
            />
          </HeaderWrapper>
        ) : (
          <HeaderWrapper>
            <Header showNav color hideSearch showLogin showSignUp />
          </HeaderWrapper>
        )}

        <OuterContainer>
          <div>
            <Title>{t("welcome-to-ygii")}</Title>
            {selectedLanguage === "en" ? (
              <TextDiv>
                <CreateText>{t("dont-have-an-account")}</CreateText>
                <Link to="/sign-up" className="signup">
                  {t("sign-up")}
                </Link>
              </TextDiv>
            ) : (
              <TextDiv>
                <Link to="/sign-up" className="signup">
                  {t("sign-up")}
                </Link>
                <CreateText>{t("dont-have-an-account")}</CreateText>
              </TextDiv>
            )}

            <Form>
              <InputWrapper>
                <Icon
                  icon={<StyledMailIcon className="login__icon" />}
                  selectedLocale={ar}
                />
                <Input
                  type="text"
                  $ar={ar}
                  placeholder={t("email-address")}
                  $error={checkValidation && (!isValidEmail(email) || !email)}
                  name="email"
                  autoComplete="on"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <Icon
                  icon={<StyledLockIcon className="login__icon" />}
                  selectedLocale={ar}
                />

                <Input
                  $ar={ar}
                  type={visibility ? "text" : "password"}
                  placeholder={t("password")}
                  name="password"
                  $error={checkValidation && !password}
                  autoComplete="off"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {visibility ? (
                  <Icon
                    icon={
                      <StyledVisibility
                        className="login__icon_right"
                        onClick={handleVisibilityToggle}
                      />
                    }
                    selectedLocale={ar}
                  />
                ) : (
                  <Icon
                    icon={
                      <StyledVisibilityOff
                        className="login__icon_right"
                        onClick={handleVisibilityToggle}
                      />
                    }
                    selectedLocale={ar}
                  />
                )}
              </InputWrapper>
              <div
                style={{
                  marginTop: -30,
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  display: "flex",
                  width: "100%",
                }}
              >
                {!error ? (
                  <ForgotPwContainer>
                    <ForgotPw onClick={() => navigation("/forgot-password")}>
                      {t("forgot-password")}
                    </ForgotPw>
                  </ForgotPwContainer>
                ) : (
                  <StyledTextDiv>
                    <StyledText>{t("there-is-no-linked")}</StyledText>
                    <Link to="/sign-up" className="signup">
                      {t("sign-up")}
                    </Link>
                  </StyledTextDiv>
                )}
              </div>
            </Form>
          </div>

          {!loginLoading ? (
            <ButtonContainer>
              <SubmitButton
                onClick={() => {
                  handleLogin();
                }}
              >
                {t("login")}
              </SubmitButton>
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <SubmitButton disabled>
                <Loading height={30} />
              </SubmitButton>
            </ButtonContainer>
          )}
        </OuterContainer>
        {loginLoading && <Loader />}
      </MainContainer>
      <Footer />
    </>
  );
};

export default LoginPage;
