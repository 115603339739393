import React, { useEffect, useState } from "react";

import {
  Button,
  CartContainer,
  CartCount,
  Dot,
  GiftCart,
  GiftCartContainer,
  ImgContainer,
  ItemName,
  Row,
  StyledHeader,
} from "./AdminHeader.styles";
import { useSelector } from "react-redux";
import { ReactComponent as Cart } from "../../assets/icons/cart.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import { ReactComponent as Gift } from "../../assets/icons/drawer/gift-sent-active.svg";
import LeftSideComponent from "./LeftSide";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { cartItemsData } from "../../store/slices/marketplace/marketplaceSlice";
import { removeGift, selectGifts } from "../../store/slices/sendGifts/slice";
import { useDispatch } from "react-redux";
import { getCountFavoriteMarketplaceItems } from "../../store/slices/marketplace/asyncThunks";
import { selectFavoriteItems } from "../../store/slices/marketplace/marketplaceSlice";
import { countClickAction } from "../../store/slices/common/asyncThunks";
import { selectBusinessUserInfo } from "../../store/slices/businessUser/slice";
import { ReactComponent as SendGiftIcon } from "../../assets/icons/send-gift-icon.svg";
import CartModal from "../Modal/CartModal";

const AdminHeader = ({
  showButtons,
  hideSendGifts,
  admin,
  showCart,
  showFavorite,
  showGiftCart,
  hideSearch,
  modal,
}) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const items = useSelector(cartItemsData);
  const businessUser = useSelector(selectBusinessUserInfo);
  const data = useSelector(selectGifts);

  const [cartModal, setCartModal] = useState(false);
  const favoriteData = useSelector(selectFavoriteItems);

  useEffect(() => {
    dispatch(getCountFavoriteMarketplaceItems());
  }, [dispatch]);

  const Notification = () => {
    if (items?.length > 0) {
      return (
        <Dot>
          <CartCount>{items?.length}</CartCount>
        </Dot>
      );
    } else {
      return null;
    }
  };

  const Notification2 = () => {
    if (data?.length > 0) {
      return (
        <Dot>
          <CartCount>{data?.length}</CartCount>
        </Dot>
      );
    } else {
      return null;
    }
  };

  const Notification3 = () => {
    if (favoriteData.count > 0) {
      return (
        <Dot>
          <CartCount>{favoriteData.count}</CartCount>
        </Dot>
      );
    } else {
      return null;
    }
  };

  const handleShowGiftsList = () => {
    setShow(!show);
  };

  const remove = (id) => {
    dispatch(removeGift(id));
  };

  const handleItemClick = (id) => {
    navigate(`/admin-portal/item-detail/${id}`);
  };
  return (
    <StyledHeader>
      <LeftSideComponent
        hideSearch={hideSearch}
        showButtons={showButtons}
        admin={admin}
      />

      <Row>
        {!hideSendGifts && (
          <Button
            onClick={() => {
              dispatch(countClickAction("startGifting"));
              navigate("/admin-portal/send-gifts/1");
            }}
          >
            <SendGiftIcon
              fill="#FFF"
              style={{
                marginBottom: 4,
                marginRight: 8,
              }}
            />
            {t("send-gifts")}
          </Button>
        )}
        {showFavorite && (
          <CartContainer
            onClick={() => {
              if (businessUser.loggedIn) {
                navigate("/admin-portal/favorite");
              } else {
                if (modal) {
                  modal();
                }
              }
            }}
          >
            {/* <Favorite /> */}
            <Notification3 />
          </CartContainer>
        )}
        {showCart && (
          <CartContainer
            onClick={() => {
              if (businessUser.loggedIn) {
                navigate("/admin-portal/cart");
                // setCartModal(true);
              } else {
                navigate("/cart");
                // setCartModal(true);
              }
            }}
          >
            <Cart />
            <Notification />
          </CartContainer>
        )}
        {showGiftCart && (
          <>
            <GiftCartContainer>
              <div onClick={handleShowGiftsList}>
                <Gift />
                <div style={{ position: "absolute", top: 2, right: 4 }}>
                  <Notification2 />
                </div>
              </div>
              {show && (
                <GiftCart>
                  {data?.length === 0 ? (
                    <div
                      style={{
                        width: 115,
                      }}
                    >
                      <ItemName>No gifts in cart.</ItemName>
                    </div>
                  ) : (
                    data.map((product) => (
                      <ImgContainer>
                        <img
                          onClick={() => {
                            handleItemClick(product.id);
                          }}
                          src={product.image}
                          alt="item-img"
                          style={{
                            height: 70,
                            borderRadius: 10,
                            background: "white",
                          }}
                          className="item-detail-img"
                        />

                        <Delete
                          onClick={() => remove(product.id)}
                          height={22}
                          fill={"white"}
                          className="pointer"
                        />
                      </ImgContainer>
                    ))
                  )}
                </GiftCart>
              )}
            </GiftCartContainer>
          </>
        )}
        {/* <Bell /> */}
        {/* {company?.companyLogo ? (
          <img
            style={{
              width: 42,
              borderRadius: 21,
              height: 42,
              background: "white",
              userSelect: "none",
            }}
            src={company?.companyLogo}
            alt="profilepicture"
          />
        ) : (
          <ProfilePic>
            <div style={{ marginBottom: -2 }}>
              <LogoGift height={26} />
            </div>
          </ProfilePic>
        )} */}
      </Row>
      <CartModal close={() => setCartModal(false)} open={cartModal} />
    </StyledHeader>
  );
};

export default AdminHeader;
