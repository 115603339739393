import styled from "styled-components";
import { ReactComponent as Xsvg } from "../../assets/icons/close.svg";
import { ReactComponent as Icon } from "../../assets/icons/date2.svg";
import DatePicker from "react-datepicker";
export const XIcon = styled(Xsvg)`
  cursor: pointer;
  position: absolute;
  width: 1.6rem;
  bottom: -0.1rem;
  right: 1rem;
  z-index: 10;

  fill: white;

  &:hover {
    fill: red;
  }
`;
export const Container = styled.div`
  display: flex;

  position: relative;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 870px) {
    justify-content: center;
  }
  @media (max-width: 295px) {
    margin-top: 68px;
  }
`;

export const LoaderContainer = styled.div`
  flex: 1;
  margin-left: -40px;
  background-color: transparent;
`;
export const QuestPriceBreak = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export const Circle = styled.div`
  transition: all 0.3s ease-out;

  z-index: 1;
  display: flex;

  background-color: ${(props) => (props.$active ? "#8A4B5E" : "#f3edef")};
  width: 65px;
  height: 65px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  @media (max-width: 870px) {
    display: ${(props) => (props.$active ? "flex" : "none")};
  }
`;
export const Row2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
`;

export const Number = styled.span`
  color: ${(props) => (props.$active ? "#fff" : "#8A4B5E")};
  font-family: "Avenir-Medium";
  letter-spacing: -0.18px;
  font-size: 20px;
  user-select: none;
`;

export const Name = styled.span`
  color: ${(props) => (props.$active === true ? "#8A4B5E" : "#8A4B5E")};
  font-family: "Avenir-Medium";
  user-select: none;
  letter-spacing: -0.18px;
  font-size: 20px;
  z-index: 1;
  @media (max-width: 870px) {
    display: ${(props) => (props.$active ? "flex" : "none")};
  }
`;

export const CircleContainer = styled.div`
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 870px) {
    display: none;
  }
`;

export const CircleContainer2 = styled.div`
  gap: 5px;
  display: none;
  align-items: center;
  flex-direction: column;
  @media (max-width: 870px) {
    display: flex;
  }
`;

export const Line = styled.div`
  width: 95%;
  margin-left: 2%;
  height: 2px;
  z-index: 0;
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  background-color: #ececf4;
  @media (max-width: 870px) {
    margin-left: 25%;
    width: 20%;
    display: none;
  }
`;

export const Text = styled.span`
  color: #8a4b5e;
  font-family: "Avenir";
  user-select: none;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.178px;
  min-width: fit-content;
`;

export const Categories = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 550px;

  flex-wrap: wrap;
`;

export const Category = styled.div`
  width: 164px;
  height: 48px;
  border-radius: 35px;
  user-select: none;
  background: ${(props) => (props.$active ? "#8A4B5E" : "#f3edef")};
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
`;
export const CategoryText = styled.span`
  color: ${(props) => (props.$active ? "#fff" : "#222239")};
  text-align: center;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.178px;
`;

export const ButtonNext = styled.button`
  padding: 12px 15px;
  background: #8a4b5e;
  border-radius: 15px;
  border: 0;
  user-select: none;
  color: #fff;
  font-family: "Avenir-Medium";

  font-size: 18px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: block;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &::after {
    width: 25px;
    height: 1px;
    background: white;
    transform: translateX(-3px);
    margin-top: 0px;
  }

  &::before {
    content: "";
    transform: rotate(-135deg) translateX(50%);
    width: 11px;
    height: 11px;
    background: transparent;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    margin-top: -1px;
  }

  &:hover {
    background: #4ab639;
    padding: 12px 60px 12px 15px;
  }

  &:hover::after,
  &:hover::before {
    opacity: 1;
    right: 15px;
  }
`;
export const ButtonBack = styled.button`
  padding: 12px 15px;
  background: #8a4b5e;
  border-radius: 15px;
  border: 0;
  color: #fff;
  font-family: "Avenir-Medium";
  user-select: none;

  font-size: 18px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: block;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &::after {
    width: 25px;
    height: 1px;
    background: white;
    transform: translateX(-4.5px);
    margin-top: 0px;
  }

  &::before {
    content: "";
    transform: rotate(-135deg) translateX(50%);
    width: 11px;
    height: 11px;
    background: transparent;
    border-right: 1px solid white;
    border-top: 1px solid white;
    margin-top: -1px;
  }

  &:hover {
    background: #d92525;
    padding: 12px 15px 12px 60px;
  }

  &:hover::after,
  &:hover::before {
    opacity: 1;
    right: 15px;
  }
`;
export const Background = styled.div`
  position: absolute;
  top: 0;

  pointer-events: none;
  left: 0;
  z-index: 11;
  right: 0;
  bottom: 0;
  background-color: #00bfff40;
`;
export const RowPrintArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  margin-left: ${(props) => (props.$margin ? "60px" : "0px")};

  margin-top: ${(props) => (props.$margin ? "60px" : "0px")};
  gap: ${(props) => (props.$margin ? "20px" : "0px")};
  padding: ${(props) => (props.$margin ? "80px 0px 80px 0px" : "0px")};
  flex-wrap: wrap;
  @media (max-width: 1158px) {
    flex-direction: column;
  }
  @media (max-width: 870px) {
    align-items: center;
    margin-top: 0px;
    margin-left: 0px;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
`;
export const RowInput = styled(Row)`
  justify-content: space-between;
  width: 700px;
  align-items: center;
`;

export const QuestRow = styled.div`
  width: 100%;
  min-height: 53px;
  justify-content: flex-start;
  gap: 20px;
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.$add ? "flex-start" : "center")};
`;
export const RowInputLast = styled(RowInput)`
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
`;
export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: auto;
  margin-bottom: 40px;
  @media (max-width: 1440px) {
    margin-bottom: 20px;
  }
  @media (max-width: 520px) {
    gap: 50px;
  }
`;
export const ContentContainer = styled.div`
  display: flex;

  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 60px;

  margin-top: 60px;
  gap: 20px;
  padding: 80px 0px 80px 0px;
  @media (max-width: 590px) {
    margin-left: 0px;
  }
`;

export const ContentRow = styled.div`
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 60px;

  margin-top: 60px;
  gap: 20px;
  padding: 80px 0px 80px 0px;
  @media (max-width: 590px) {
    margin-left: 0px;
  }
`;
export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const Input = styled.input`
  border-radius: 35px;
  border: 1px solid #8a4b5e;
  width: 250px;
  padding: 0px 20px;
  height: 48px;
  background: #fff;
  color: #000;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.178px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
    font-family: "Avenir";
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    letter-spacing: -0.178px;
  }
`;
export const DropDown = styled.div`
  border-radius: 15px;
  border: 1px solid #8a4b5e;
  border-bottom-right-radius: ${(props) => (props.$open ? 0 : 15)};
  border-bottom-left-radius: ${(props) => (props.$open ? 0 : 15)};
  background: #fff;
  z-index: 1000;
  width: 272px;
  height: 53px;
  transition: all 0.2s ease-in-out;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0px 5px 0px 20px;
  color: #000;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.178px;
`;

export const UploadButton = styled.label`
  display: flex;
  width: 192px;
  height: 53px;
  border-radius: 15px;
  background: linear-gradient(180deg, #a87283 0%, #8a4b5e 100%), #fff;
  flex-direction: row;
  border: none;
  cursor: pointer;
  gap: 8px;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-family: "Avenir";
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.178px;

  &:active {
    scale: 0.95;
    transition: all 0.15s ease-in-out;
  }
`;

export const UploadedPhotosContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  overflow-x: auto;
  align-items: center;
  width: 904px;
  padding-bottom: 10px;
`;
export const SmallText = styled.span`
  color: #8a4b5e;
  font-family: "Avenir";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  user-select: none;
  line-height: normal;
  letter-spacing: -0.178px;
`;

export const UploadCard = styled.div`
  border-radius: 10px;
  width: fit-content;
  padding: 10px;
  height: fit-content;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
`;

export const HeaderCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const Image = styled.img`
  width: 150px;
  height: 150px;
  object-fit: contain;
  border-radius: 10px;
`;
export const StyledImage = styled.img`
  user-select: none;
  border-radius: 10px;
  width: 50rem;
  /* max-height: 100rem; */
  object-fit: ${(props) => (props.$contain ? "contain" : "contain")};
  pointer-events: none;
  @media (max-width: 1510px) {
    width: 35rem;
    height: 35rem;
  }
  @media (max-width: 645px) {
    width: 25rem;
    height: 25rem;
  }
  @media (max-width: 450px) {
    width: 100%;
    height: 100%;
  }
`;

export const LeftIcons = styled(HeaderCard)`
  justify-content: unset;
  gap: 4px;
`;
export const RowInputBig = styled(RowInput)`
  width: 495px;
`;
export const RowVariables = styled(RowInput)`
  width: fit-content;
  align-items: flex-start;
  margin-top: 80px;
  justify-content: unset;
  gap: 0px;
  @media (max-width: 1600px) {
    width: 100%;
  }
`;
export const DropDownValue = styled.span`
  color: #bababa;
  font-family: "Avenir";
  font-size: 20px;
  font-style: normal;
  user-select: none;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.178px;
`;
export const Loader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const DropDownMenu = styled.div`
  border-radius: 15px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  position: absolute;
  border: 1px solid #8a4b5e;
  border-top: unset;
  background: #fff;

  width: 272px;
  max-height: 318px;
  padding: 10px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow-y: auto;
`;
export const Relative = styled.div`
  position: relative;
`;
export const Attributes = styled(DropDownValue)`
  color: black;
  cursor: pointer;
  padding-left: 10px;
  border-radius: 15px;
  &:hover {
    background: #8a4b5e22;
  }
`;
export const TextVariable = styled(Text)`
  width: 230px;
`;

export const QuestText = styled(Text)`
  width: 400px;
  user-select: none;
`;
export const QuestSmallGap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.$add ? "flex-start" : "center")};
  gap: 8px;
`;

export const QuestInput = styled(Input)`
  border-radius: 15px;
  border: 1px solid #8a4b5e;
  background: #fff;
  width: 272px;
  height: 53px;
  user-select: none;
`;
export const QuestSmallInput = styled(QuestInput)`
  width: 145px;
`;

export const Message = styled.span`
  color: #8a4b5e;
  font-family: "Avenir";
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.178px;
`;
export const Button = styled.button`
  border-radius: 30px;
  background: ${(props) => (props.disabled ? "grey" : "#8A4B5E")};
  box-shadow: ${(props) =>
    props.disabled ? "unset" : "0px 12px 24px -6px rgba(0, 0, 0, 0.25);"};

  display: flex;
  padding: 0.5rem 1.5rem;
  min-width: 233px;
  height: 45px;
  border: none;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: #fff;
  text-align: center;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
  transition: all 0.2s ease-in-out;

  &:active {
    scale: ${(props) => (props.disabled ? 1 : 0.95)};
  }
`;
export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
  flex: 1;
  margin-top: -250px;
  justify-content: center;
`;

export const RemoveButton = styled.button``;

export const StyledRowInput = styled(RowInput)`
  width: 470px;
  height: 53px;
  @media (max-width: 590px) {
    flex-direction: column;

    gap: 1.2rem;
    height: unset;
  }
  @media (max-width: 520px) {
    width: 300px;
  }
`;
export const StyledInput = styled(Input)`
  border-radius: 15px;
  width: 272px;
  height: 53px;
  color: #8a4b5e;
  font-family: "Avenir";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.178px;
  &::placeholder {
    font-family: "Avenir";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.178px;
  }
  @media (max-width: 350px) {
    width: 210px;
  }
`;
export const Value = styled.span`
  color: #8a4b5e;
  user-select: none;
  font-family: "Avenir";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.178px;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;
export const RowValue = styled(Column)`
  flex-direction: row;
  align-items: center;
  margin-top: -7px;
  width: 272px;

  justify-content: space-between;
`;
export const StyledRowInputValue = styled(StyledRowInput)`
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 45px;
  height: unset;
`;

export const StyledDatePicker = styled(DatePicker)`
  display: flex;
  width: 272px;
  height: 53px;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 15px;
  border: 1px solid #8c8c8c;
  background: #fff;
  color: #8c8c8c;
  font-family: "Avenir";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.178px;
`;

export const DatePickerContainer = styled.div`
  width: 272px;
  position: relative;
`;
export const DateIcon = styled(Icon)`
  position: absolute;
  right: 20px;
  top: 13px;
`;
export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  max-width: 272px;
`;
export const StyledInputLast = styled(StyledInput)`
  width: 100%;
`;
export const TableColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
export const TableColumnFirst = styled(TableColumn)`
  align-items: flex-start;
`;

export const TableRow = styled(TableColumn)`
  flex-direction: row;
  gap: 30px;
  @media (max-width: 1260px) {
    overflow-x: auto;
    width: 800px;
  }
  @media (max-width: 1050px) {
    width: 500px;
  }
  @media (max-width: 575px) {
    width: 250px;
  }
`;

export const TableHeader = styled.span`
  color: #8a4b5e;
  font-family: "Avenir";
  user-select: none;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.178px;
  @media (max-width: 1260px) {
    font-size: 12px;
  }
`;
export const TableValue = styled(TableHeader)`
  color: #000;
  font-size: 14px;
  @media (max-width: 1260px) {
    font-size: 12px;
  }
`;
export const TableSmallRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const ProceedContainer = styled.div`
  width: 443px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  height: 165px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 10px;
  margin-left: 70px;
  @media (max-width: 590px) {
    margin-left: 0px;
  }
  @media (max-width: 510px) {
    width: 343px;
    height: 125px;
  }
  @media (max-width: 375px) {
    width: 250px;
    height: 125px;
  }
`;
export const PopupTitle = styled.span`
  color: #8a4b5e;
  text-align: center;
  font-family: "Avenir";
  user-select: none;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.067px;
  @media (max-width: 375px) {
    font-size: 18px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  @media (max-width: 510px) {
    gap: 10px;
  }
`;

export const StyledButton = styled.button`
  width: 150px;
  height: 44px;
  border-radius: 10px;
  user-select: none;
  background: #8a4b5e;
  border: none;
  outline: none;
  color: #fff;
  font-family: "Avenir";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  letter-spacing: -0.178px;
  &:active {
    scale: 0.95;
    transition: all 0.2s ease-in-out;
  }
  &:focus {
    border: none;
    outline: none;
  }
  @media (max-width: 510px) {
    font-size: 16px;
    width: 75px;
    height: 34px;
  }
`;
export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const StyledContentContainer = styled(ContentContainer)`
  flex-direction: row;
  align-items: center;
`;

export const TextArea = styled.textarea`
  resize: none;
  height: 150px;
  width: 250px;
  border-radius: 35px;
  border: 1px solid #8a4b5e;
  padding: 16px 20px 16px 20px;
  background: #fff;
  color: #000;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  outline: none;
  letter-spacing: -0.178px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
    font-family: "Avenir";
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    letter-spacing: -0.178px;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const Success = styled.img`
  user-select: none;
  transition: all 0.3s ease-out;
  background-color: transparent;
  width: 70px;
  height: 70px;
`;

export const ColumnInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export const SetPrintArea = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
`;

export const PrintArea = styled.div`
  width: 17.5rem;
  height: 20rem;

  background-color: transparent;
  border: 1px solid transparent;
  position: absolute;
  top: 16rem;
  left: 18.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
  transition: all 0.1s ease-in-out;
  border: ${(props) =>
    props.$hover ? "1px solid white" : "1px solid transparent"};
`;

export const SetHorizontalLine = styled.div`
  width: 115%;
  height: 1px;
  background-color: white;
  position: absolute;
`;
export const MoveContainer = styled.div`
  position: absolute;
  z-index: 5;
  cursor: move;
`;

export const SetVerticalLine = styled.div`
  height: 115%;
  width: 1px;
  background-color: white;
  position: absolute;
`;

export const HorizontalLine = styled.div`
  width: 23rem;
  height: 1px;
  background-color: white;
  position: absolute;
  opacity: ${(props) => (props.$hover ? 1 : 0)};
  transition: all 0.1s ease-in-out;
`;

export const VerticalLine = styled.div`
  height: 25.5rem;
  width: 1px;
  background-color: white;
  position: absolute;
  transition: all 0.1s ease-in-out;
  opacity: ${(props) => (props.$hover ? 1 : 0)};
`;

export const PrintLogo = styled.img`
  width: 100%;
  height: 100%;
  border: ${(props) =>
    props.$hover ? "1px solid white" : "1px solid transparent"};
  border-style: dashed;
  user-select: none;
  z-index: 4;
  pointer-events: none;
`;

export const RemoveLogo = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  z-index: 6;

  border-radius: 50%;
  outline: 1px solid white;
  background-color: #ea435d;
  position: absolute;
  top: -1rem;
  right: -1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: ${(props) => (props.$hover ? 1 : 0)};
`;

export const ResizeLogo = styled(RemoveLogo)`
  background-color: #8a4b5e;
  top: unset;
  bottom: -1rem;
  padding: 0.3rem;
  cursor: nwse-resize;
  transition: all 0.1s ease-in-out;
  opacity: ${(props) => (props.$hover ? 1 : 0)};
  z-index: 10;
`;

export const RotateLogo = styled(ResizeLogo)`
  right: unset;
  left: -1rem;
  cursor: move;
`;

export const LogoContainer = styled.div`
  position: relative;
  cursor: move;
  z-index: 5;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  /* align-items: center;
  justify-content: center; */
`;

export const PhotosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  max-width: 32rem;
  height: fit-content;
  flex-wrap: wrap;
  @media (max-width: 870px) {
    justify-content: center;
  }
  @media (max-width: 365px) {
    max-width: 100%;
  }
`;

export const PhotoContainer = styled.div`
  position: relative;
  height: 10rem;
`;
export const Photo = styled.img`
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  border-radius: 1rem;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease-in-out;
  /* border: 2px solid transparent;
  outline: 2px solid transparent;
  &:hover {
    border: 2px solid #fff;
    outline: 2px solid #8A4B5E;
  } */
`;
export const Photos = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
`;
export const PrintAreaColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;

export const VariableName = styled(Name)`
  color: white;
  position: absolute;
  bottom: 0px;
  padding: 0.25rem 1rem;
  font-size: 1.4rem;
  left: 0px;
  right: 0px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);

  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
`;
export const Title = styled.span`
  color: white;
  text-align: center;
  margin-top: -1rem;
  margin-bottom: 2rem;
  font-size: 2.4rem;
  font-family: "Avenir-Medium";
  letter-spacing: -0.2px;
`;
