import React, { useEffect, useState } from "react";
import {
  Content,
  Text,
  TextContainer,
  TitleContainer,
  Message,
  MainContainer,
  Column,
  StyledAHref,
  SectionTitle,
  Button,
  InvoiceDiv,
  LoaderDiv,
  HeaderWrapper,
  TableBodyRow,
  FirstItem,
  LastTableItem,
} from "./BusinessOrderHistoryPage.styles";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { formatDate, priceFormatter } from "../../../utils/utils";
import { getCurrencySymbol } from "../../../utils/constants";
import { ReactComponent as Open } from "../../../assets/icons/open.svg";
import { getOrders } from "../../../store/slices/marketplace/asyncThunks";
import {
  marketplaceOrdersLoader,
  orders,
} from "../../../store/slices/marketplace/marketplaceSlice";
import Sidebar from "../utils/Sidebar";
import MiniLoader from "../../../components/MiniLoader/MiniLoader";
import { getAllCompanyGifting } from "../../../store/slices/gift/asyncThunks";
import { selectGiftsOrders } from "../../../store/slices/gift/slice";

import Header from "../../../components/Header/Header";
import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";
import TableItem from "../../../components/Table/TableItem";
import Table from "../../../components/Table/Table";
import { selectedLocale } from "../../../store/slices/common/slice";

const BusinessOrderHistoryPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ordersMarketplace = useSelector(orders);
  const ordersGifts = useSelector(selectGiftsOrders);
  const { company, state } = useSelector(selectBusinessUserInfo);
  const loading = useSelector(marketplaceOrdersLoader);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const marketplacePage = queryParams.get("marketplacePage");
  const giftsPage = queryParams.get("giftsPage");
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    dispatch(
      getOrders({
        page: marketplacePage,
        sortBy: "createdAt",
        order: "DESC",
      })
    )
      .unwrap()
      .then(() => {})
      .catch((err) => toast.error(t(err.message)));
  }, [dispatch, marketplacePage]);

  useEffect(() => {
    dispatch(
      getAllCompanyGifting({
        page: giftsPage,
        sortBy: "createdAt",
        order: "DESC",
      })
    )
      .unwrap()
      .catch((err) => toast.error(t(err.message)));
  }, [dispatch, giftsPage]);

  useEffect(() => {
    if (ordersGifts?.loading && loading) {
      setLoader(true);
    }
    if (!ordersGifts?.loading && !loading) {
      setLoader(false);
    }
    if (!ordersGifts?.loading && loading) {
      setLoader(false);
    }
    if (ordersGifts?.loading && !loading) {
      setLoader(false);
    }
  }, [loading, ordersGifts?.loading]);

  const handlePageChange = (pageNumber, paramName) => {
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set(paramName, pageNumber);

    const url = `/admin-portal/order-history?${currentParams.toString()}`;
    navigate(url);
  };

  const handleGiftOrderDetailsClick = (id) => {
    navigate(`/admin-portal/order-history/gift-order-details/${id}`);
  };

  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  const tableHeaderMarketplace = [
    {
      label: `${t("id")}`,
      sortable: true,
      key: "id",
    },
    {
      label: `${t("state")}`,
      sortable: true,
      key: "state",
    },
    {
      label: `${t("date-of-delivery")}`,
      sortable: true,
      key: "dateOfDelivery",
    },
    {
      label: `${t("total-price")}`,
      sortable: true,
      key: "totalPrice",
    },
    {
      label: `${t("receipt-url")}`,
      sortable: true,
      key: "recipientUrl",
    },
    {
      label: `${t("payment-type")}`,
      sortable: true,
      key: "paymentType",
    },
    {
      label: `${t("payment-status")}`,
      sortable: true,
      key: "paymentStatus",
    },
    {
      label: `${t("address-details")}`,
      sortable: true,
      key: "addressDetails",
    },
    {
      label: `${t("notes")}`,
      sortable: true,
      key: "notes",
    },
  ];
  const tableHeaderGifts = [
    {
      label: `${t("id")}`,
      sortable: true,
      key: "id",
    },
    {
      label: `${t("status")}`,
      sortable: true,
      key: "status",
    },
    {
      label: `${t("created")}`,
      sortable: true,
      key: "created",
    },
    {
      label: `${t("order-budget")}`,
      sortable: true,
      key: "orderBudget",
    },
    {
      label: `${t("occasions")}`,
      sortable: true,
      key: "occasions",
    },
    {
      label: `${t("delivery-location")}`,
      sortable: true,
      key: "deliveryLocation",
    },
    {
      label: `${t("address-details")}`,
      sortable: true,
      key: "addressDetails",
    },
  ];

  const locale = useSelector(selectedLocale);
  const upgradePackage = showUpgradeBar && diff > 0 && state;
  const ar = locale === "ar";

  return (
    <MainContainer>
      <HeaderWrapper>
        <Header
          showLogin
          showSignUp
          showNav
          color
          showUser
          showFavorite
          showCart
        />
      </HeaderWrapper>
      <Sidebar />

      <Column $upgradePackage={upgradePackage} $ar={ar}>
        <SectionTitle>{t("order-history")}</SectionTitle>
        {!loader ? (
          <>
            {!loading ? (
              <>
                {ordersMarketplace?.data.length <= 0 ? (
                  <Content>
                    <TextContainer>
                      <Message>
                        {t(
                          "you-currently-have-no-marketplace-orders-in-history"
                        )}
                      </Message>
                    </TextContainer>
                  </Content>
                ) : (
                  <>
                    <TitleContainer>
                      <Text>
                        {t("marketplace-order-history")} (
                        {ordersMarketplace.total})
                      </Text>
                    </TitleContainer>

                    <Table
                      marginTop={40}
                      headerInformation={tableHeaderMarketplace}
                      totalCount={ordersMarketplace.total}
                      currentPage={+marketplacePage}
                      setPage={handlePageChange}
                      perPage={ordersMarketplace.perPage}
                      noDataContent={t("no-data")}
                      dataLoading={false}
                      param={"marketplacePage"}
                      lastColumnAligned
                      centerText
                      orderHistory
                      orderHistoryMarketplace
                    >
                      {ordersMarketplace.data.map((val, key) => {
                        return (
                          <TableBodyRow
                            key={key}
                            $offClick
                            onClick={() =>
                              navigate(
                                `/admin-portal/order-history/marketplace-order-details/${val.id}`
                              )
                            }
                          >
                            <FirstItem data={val.id} />
                            <TableItem data={t(val.state)} center />
                            <TableItem
                              center
                              data={
                                val.dateOfDelivery
                                  ? formatDate(val.dateOfDelivery)
                                  : "/"
                              }
                            />
                            <TableItem
                              center
                              data={
                                priceFormatter(val.totalPrice, false, 0) +
                                " " +
                                getCurrencySymbol(val.currency)
                              }
                            />
                            <TableItem
                              center
                              data={
                                val.receiptUrl === null ? (
                                  <Open className="details-icon" />
                                ) : (
                                  <StyledAHref
                                    href={val.receiptUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Open className="details-icon" />
                                  </StyledAHref>
                                )
                              }
                            />
                            <TableItem
                              center
                              data={
                                <>
                                  {val.paymentStatus === "paid" && (
                                    <InvoiceDiv>
                                      <Button
                                        onClick={() =>
                                          navigate(
                                            "/admin-portal/checkout/final",
                                            {
                                              state: { order: val },
                                            }
                                          )
                                        }
                                      >
                                        {t("generate-invoice")}
                                      </Button>
                                    </InvoiceDiv>
                                  )}
                                  {val.invoiceUrl && (
                                    <StyledAHref
                                      href={val.invoiceUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <Open className="details-icon" />
                                    </StyledAHref>
                                  )}
                                  {!val.invoiceUrl &&
                                    val.paymentStatus === "pending" &&
                                    "/"}
                                </>
                              }
                            />
                            <TableItem data={t(val.paymentStatus)} center />
                            <TableItem
                              data={val.addressDetails || "/"}
                              center
                            />
                            <LastTableItem data={val.notes || "/"} $offClick />
                          </TableBodyRow>
                        );
                      })}
                    </Table>
                  </>
                )}
              </>
            ) : (
              <div
                style={{
                  height: 822,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <MiniLoader />
              </div>
            )}
            {!ordersGifts?.loading ? (
              <>
                {ordersGifts?.data?.length <= 0 ? (
                  <Content>
                    <TextContainer>
                      <Message>
                        {t("you-currently-have-no-gift-orders-in-history")}
                      </Message>
                    </TextContainer>
                  </Content>
                ) : (
                  <>
                    <TitleContainer style={{ marginTop: 40 }}>
                      <Text>
                        {t("gifts-order-history")} ({ordersGifts.total})
                      </Text>
                    </TitleContainer>

                    <Table
                      marginTop={40}
                      headerInformation={tableHeaderGifts}
                      totalCount={ordersGifts.total}
                      currentPage={+giftsPage}
                      setPage={handlePageChange}
                      perPage={ordersGifts.perPage}
                      noDataContent={t("no-data")}
                      dataLoading={false}
                      param={"giftsPage"}
                      lastColumnAligned
                      centerText
                      orderHistory
                    >
                      {ordersGifts?.data.map((val, key) => {
                        return (
                          <TableBodyRow
                            key={key}
                            $offClick
                            onClick={() => handleGiftOrderDetailsClick(val.id)}
                          >
                            <FirstItem data={val.id} />
                            <TableItem data={t(val.status)} center />
                            <TableItem
                              data={formatDate(val.createdAt)}
                              center
                            />
                            <TableItem
                              data={val.orderMaxBudget + " SR"}
                              center
                            />
                            <TableItem data={t(val.occasion)} center />
                            <TableItem data={t(val.deliveryLocation)} center />
                            <LastTableItem
                              data={val.addressDetails || "/"}
                              $offClick
                            />
                          </TableBodyRow>
                        );
                      })}
                    </Table>
                  </>
                )}
              </>
            ) : (
              <div
                style={{
                  height: 822,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <MiniLoader />
              </div>
            )}
          </>
        ) : (
          <LoaderDiv>
            <MiniLoader />
          </LoaderDiv>
        )}
      </Column>
    </MainContainer>
  );
};

export default BusinessOrderHistoryPage;
