import React, { useEffect, useRef, useState } from "react";
import {
  MainContainer,
  ContentContainer,
  ItemContainer,
} from "./PickGiftPage.styles";

import {
  CardProduct,
  CardProductName,
  ProductNameContainerColumn,
} from "../../AdminPortal/MarketplacePage/MarketplacePage.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  companyGiftingItemsData,
  companyGiftingItemsDataPending,
  getCreateCompanyGiftingRecipientOrderPending,
} from "../../../store/slices/gift/slice";

import {
  createCompanyGiftingRecipientOrder,
  getCompanyGiftingItems,
} from "../../../store/slices/gift/asyncThunks";
import { ButtonContinue } from "../../AdminPortal/AccountSettingsPage/AccountSettingsPage.styles";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import openToast from "../../../utils/toast";
import { selectedLocale } from "../../../store/slices/common/slice";
import AppLoader from "../../../components/AppLoader";

function ItemCard({ item, onClick, isActive, companyGiftingRecipientId }) {
  const { t } = useTranslation();
  const modalRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";
  const toastId = useRef(null);

  const handlePickGift = () => {
    modalRef.current.show();
  };
  const handleYes = (itemId, totalPrice, companyGiftingRecipientId) => {
    dispatch(
      createCompanyGiftingRecipientOrder({
        itemId,
        companyGiftingRecipientId,
        totalPrice,
      })
    )
      .unwrap()
      .then(() => {
        toast.success(t("you-have-successfully-picked-a-gift"));
        navigate(-1);
      })
      .catch((error) => {
        openToast(
          toastId,
          "create-company-gifting-order",
          "error",
          t(error.message)
        );
      });
  };

  return (
    <CardProduct
      onClick={onClick}
      style={{
        transition: "all 0.2s ease-out",
        opacity: isActive === null ? 1 : isActive ? 1 : 0.5,
        transform:
          isActive === null ? "none" : isActive ? "scale(1.1)" : "none",
      }}
    >
      <img
        style={{
          borderRadius: 20,
          width: "80%",
          height: 172,
          marginTop: 20,
          alignSelf: "center",
        }}
        src={item?.item.photos[0].url}
        alt="item-img"
      />

      <ProductNameContainerColumn>
        {!isActive ? (
          <>
            <CardProductName>
              {ar && item?.item?.nameAr ? item?.item?.nameAr : item?.item?.name}
            </CardProductName>
            {/* <ProductPrice>{`${getCurrencySymbol(
              item.item.currency
            )}${priceFormatter(item.item.price)}`}</ProductPrice> */}
          </>
        ) : (
          <ButtonContinue onClick={handlePickGift}>
            {t("pick-a-gift")}
          </ButtonContinue>
        )}
      </ProductNameContainerColumn>
      <ModalConfirm
        modalRef={modalRef}
        onClickNo={() => {
          modalRef.current.hide();
        }}
        onClickYes={() => {
          const itemId = item.item.id;
          const totalPrice = item.item.price;
          handleYes(itemId, totalPrice, companyGiftingRecipientId);
        }}
      />
    </CardProduct>
  );
}

const PickGiftPage = ({ id, companyGiftingRecipientId }) => {
  const dispatch = useDispatch();
  const data = useSelector(companyGiftingItemsData);
  const pending = useSelector(companyGiftingItemsDataPending);
  const [activeIndex, setActiveIndex] = useState(null);
  const createPending = useSelector(
    getCreateCompanyGiftingRecipientOrderPending
  );
  const handleDivClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    dispatch(
      getCompanyGiftingItems({
        id: id,
        page: 1,
        order: "DESC",
        sortBy: "CreatedAt",
      })
    );
  }, [dispatch, id]);

  return (
    <MainContainer>
      <ContentContainer style={{ marginTop: 40 }}>
        {!pending && !createPending ? (
          <ItemContainer>
            {data?.map((item, index) => (
              <ItemCard
                item={item}
                key={index}
                companyGiftingRecipientId={companyGiftingRecipientId}
                isActive={
                  activeIndex || activeIndex === 0
                    ? activeIndex === index
                    : null
                }
                onClick={() => handleDivClick(index)}
              />
            ))}
          </ItemContainer>
        ) : (
          <AppLoader />
        )}
      </ContentContainer>
    </MainContainer>
  );
};

export default PickGiftPage;
