import axiosApiClient from "..";
import { handleError } from "../../utils/errors";

const BASE_URL = "business/billing";

class BillingService {
  getBillingsInfo = async ({ page, sortBy, order }) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/?page=${page}&sortBy=${sortBy}&order=${order}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, maxReached, perPage } = response.data;
      return { data, total, maxReached, perPage };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching billing info.");
    }
  };

  addToWallet = async ({ value, cardTokenId, receipt, paymentMethodId }) => {
    let formData = new FormData();

    if (value) {
      formData.append("value", value);
    }

    if (cardTokenId) {
      formData.append("cardTokenId", cardTokenId);
    }

    if (cardTokenId || paymentMethodId) {
      formData.append("paymentType", "CARD_PAYMENT");
    } else {
      if (receipt) {
        formData.append("paymentType", "BANK_TRANSFER");
        formData.append("files", receipt);
      } else {
        formData.append("paymentType", "BANK_TRANSFER");
      }
    }

    if (paymentMethodId) {
      formData.append("paymentMethodId", paymentMethodId);
    }

    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/wallet`,
        formData
      );
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while processing payment.");
    }
  };

  validatePayment = async ({ paymentChargeId }) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/validate-wallet`,
        { paymentChargeId }
      );
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data.data;
    } catch (err) {
      throw handleError(err, "Error occurred while validate payment.");
    }
  };

  buyPackage = async ({ value, paymentChargeId }) => {
    try {
      const body = { value, paymentChargeId };
      const response = await axiosApiClient.post(`${BASE_URL}/package`, body);
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data.data;
    } catch (err) {
      throw handleError(err, "Error occurred while processing payment.");
    }
  };

  getBillingsWithBankTransferPayment = async ({ page, sortBy, order }) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/bank-transfer?page=${page}&sortBy=${sortBy}&order=${order}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, maxReached, perPage } = response.data;
      return { data, total, maxReached, perPage };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching bank transfers.");
    }
  };

  updateBillingPaymentStatus = async ({ id, paymentStatus }) => {
    try {
      const response = await axiosApiClient.put(
        `${BASE_URL}/payment-status/${id}`,
        {
          paymentStatus,
        }
      );

      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while updating payment status.");
    }
  };
}

const billingService = new BillingService();

export default billingService;
