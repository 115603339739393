import { useTranslation } from "react-i18next";
import Header from "../../../components/Header/Header";
import Sidebar from "../utils/Sidebar";
import {
  ButtonGenerateInvoice,
  Column,
  CompanyDetailsRow,
  CompanyDetailsText,
  Content,
  ContentSubscriptionHistory,
  FirstItem,
  HeaderWrapper,
  InvoiceDiv,
  LastTableItem,
  LoaderDiv,
  Message,
  RowSubscriptionHistory,
  SectionTitle,
  StyledAHref,
  StyledViewMoreContainer,
  TableBodyRow,
  Text,
  TextContainer,
  TitleContainer,
  ViewMoreText,
} from "./SubscriptionPage.styles";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getCompanySubscriptionHistory } from "../../../store/slices/subscriptions/asyncThunks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectGetCompanySubscriptionHistory } from "../../../store/slices/subscriptions/slice";
import { formatDate, priceFormatter } from "../../../utils/utils";
import MiniLoader from "../../../components/MiniLoader/MiniLoader";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron-left.svg";
import { ReactComponent as Open } from "../../../assets/icons/open.svg";
import { toast } from "react-toastify";
import Table from "../../../components/Table/Table";
import TableItem from "../../../components/Table/TableItem";

const CompanySubscriptionHistoryPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const companyId = params.companyId;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const { data, total, perPage, company, loading } = useSelector(
    selectGetCompanySubscriptionHistory
  );

  useEffect(() => {
    dispatch(
      getCompanySubscriptionHistory({ page: page, companyId: companyId })
    )
      .unwrap()
      .then(() => {})
      .catch((err) => {
        toast.error(t(err.message));
      });
  }, [dispatch, companyId, page]);

  const handlePageChange = (pageNumber, paramName) => {
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set(paramName, pageNumber);

    const url = `/admin-portal/subscriptions/company-history/${companyId}?${currentParams.toString()}`;
    navigate(url);
  };

  const tableHeader = [
    {
      label: `${t("type")}`,
      sortable: true,
      key: "type",
    },
    {
      label: `${t("period")}`,
      sortable: true,
      key: "period",
    },
    {
      label: `${t("subscription-valid")}`,
      sortable: true,
      key: "subValid",
    },
    {
      label: `${t("num-of-users")}`,
      sortable: true,
      key: "numOfUsers",
    },

    {
      label: `${t("price")}`,
      sortable: true,
      key: "price",
    },
    {
      label: `${t("invoice-url")}`,
      sortable: true,
      key: "invoiceUrl",
    },
    {
      label: `${t("recipient-url")}`,
      sortable: true,
      key: "recipientUrl",
    },
    {
      label: `${t("payment-type")}`,
      sortable: true,
      key: "paymentType",
    },
    {
      label: `${t("status")}`,
      sortable: true,
      key: "status",
    },
    {
      label: `${t("coupon")}`,
      sortable: true,
      key: "coupon",
    },
    {
      label: `${t("coupon-discount")}`,
      sortable: true,
      key: "couponDiscount",
    },
  ];
  return (
    <>
      <HeaderWrapper>
        <Header color showCart showFavorite showUser />
      </HeaderWrapper>
      <Sidebar />

      <Column style={{ marginTop: 0 }}>
        <RowSubscriptionHistory>
          <SectionTitle>{t("company-subscription-history")}</SectionTitle>
          <StyledViewMoreContainer onClick={() => navigate(-1)}>
            <Chevron height={23} fill="#55a1e8" />
            <ViewMoreText>{t("back")}</ViewMoreText>
          </StyledViewMoreContainer>
        </RowSubscriptionHistory>
        {!loading ? (
          <ContentSubscriptionHistory>
            <CompanyDetailsRow>
              <CompanyDetailsText $fontFamily>
                {t("company-name")}:
              </CompanyDetailsText>
              <CompanyDetailsText>{company?.name}</CompanyDetailsText>
            </CompanyDetailsRow>
            {company?.billingEmail && (
              <CompanyDetailsRow>
                <CompanyDetailsText $fontFamily>
                  {t("email")}:
                </CompanyDetailsText>
                <CompanyDetailsText>{company?.billingEmail}</CompanyDetailsText>
              </CompanyDetailsRow>
            )}
            {company?.address && (
              <CompanyDetailsRow>
                <CompanyDetailsText $fontFamily>
                  {t("address")}:
                </CompanyDetailsText>
                <CompanyDetailsText>{company?.address}</CompanyDetailsText>
              </CompanyDetailsRow>
            )}
            {company?.addressOptional && (
              <CompanyDetailsRow>
                <CompanyDetailsText $fontFamily>
                  {t("address-optional")}:
                </CompanyDetailsText>
                <CompanyDetailsText>
                  {company?.addressOptional}
                </CompanyDetailsText>
              </CompanyDetailsRow>
            )}
            {company?.city && (
              <CompanyDetailsRow>
                <CompanyDetailsText $fontFamily>
                  {t("city-country")}:
                </CompanyDetailsText>
                <CompanyDetailsText>
                  {company.city}
                  {company.country && `, ${company.country}`}
                </CompanyDetailsText>
              </CompanyDetailsRow>
            )}
            {company?.zipCode && (
              <CompanyDetailsRow>
                <CompanyDetailsText $fontFamily>
                  {t("zip-code")}:
                </CompanyDetailsText>
                <CompanyDetailsText>{company?.zipCode}</CompanyDetailsText>
              </CompanyDetailsRow>
            )}
            {company?.VAT && (
              <CompanyDetailsRow>
                <CompanyDetailsText $fontFamily>{t("VAT")}:</CompanyDetailsText>
                <CompanyDetailsText>{company?.VAT}</CompanyDetailsText>
              </CompanyDetailsRow>
            )}
            <CompanyDetailsRow>
              <CompanyDetailsText $fontFamily>
                {t("automatic-subscription-renewal")}:
              </CompanyDetailsText>
              <CompanyDetailsText>
                {company?.automaticSubscriptionRenewal ? t("yes") : t("no")}
              </CompanyDetailsText>
            </CompanyDetailsRow>
            <CompanyDetailsRow>
              <CompanyDetailsText $fontFamily>
                {t("max-number-of-users")}:
              </CompanyDetailsText>
              <CompanyDetailsText>
                {company?.maxNumberOfUsers}
              </CompanyDetailsText>
            </CompanyDetailsRow>

            <TitleContainer>
              <Text>{t("subscriptions")}</Text>
            </TitleContainer>
            {data?.length <= 0 ? (
              <Content>
                <TextContainer>
                  <Message>{t("no-subscriptions")}</Message>
                </TextContainer>
              </Content>
            ) : (
              <Table
                marginTop={40}
                headerInformation={tableHeader}
                totalCount={total}
                currentPage={+page}
                setPage={handlePageChange}
                perPage={perPage}
                noDataContent={t("no-data")}
                dataLoading={false}
                lastColumnAligned
                centerText
                bigHeaderWidth
              >
                {data &&
                  data?.map((val, key) => {
                    return (
                      <TableBodyRow key={key} $offClick>
                        <FirstItem data={val.type} />
                        <TableItem center data={t(val.period)} big />
                        <TableItem
                          center
                          big
                          data={`${t(formatDate(val.subscriptionFrom))} -  ${t(
                            formatDate(val.subscriptionTo)
                          )}`}
                        />
                        <TableItem
                          center
                          big
                          data={priceFormatter(val.price, false, 0) + " SR"}
                        />
                        <TableItem center data={val.numOfUsers} big />
                        <TableItem
                          center
                          big
                          data={
                            <>
                              {val.status === "PAID" && (
                                <InvoiceDiv>
                                  <ButtonGenerateInvoice
                                    onClick={() =>
                                      navigate(
                                        `/admin-portal/checkout/final?subscriptionId=${val.id}`,
                                        {
                                          state: {
                                            subscriptionCompany: company,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {t("generate-invoice")}
                                  </ButtonGenerateInvoice>
                                </InvoiceDiv>
                              )}
                              {val.invoiceUrl && val.status === "PAID" && (
                                <StyledAHref
                                  href={val.invoiceUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Open className="details-icon" />
                                </StyledAHref>
                              )}
                              {!val.invoiceUrl && val.status !== "PAID" && "/"}
                            </>
                          }
                        />

                        <TableItem
                          big
                          center
                          data={
                            !val.receiptUrl ? (
                              "/"
                            ) : (
                              <StyledAHref
                                href={val.receiptUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Open className="details-icon" />
                              </StyledAHref>
                            )
                          }
                        />
                        <TableItem center data={t(val.paymentType)} big />
                        <TableItem center data={t(val.status)} big />
                        <TableItem center data={t(val.coupon) || "/"} big />
                        <LastTableItem
                          data={t(val.couponDiscount) || "/"}
                          $offClick
                        />
                      </TableBodyRow>
                    );
                  })}
              </Table>
            )}
          </ContentSubscriptionHistory>
        ) : (
          <LoaderDiv>
            <MiniLoader />
          </LoaderDiv>
        )}
      </Column>
    </>
  );
};

export default CompanySubscriptionHistoryPage;
