import {
  getItemUnauthorized,
  getUsersWishlistItemsUnauthorized,
} from "./asyncThunks";

export const getUsersWishlistItemsUnauthorizedBuilder = (builder) => {
  builder.addCase(getUsersWishlistItemsUnauthorized.pending, (state) => {
    state.getUsersWishlistItemsUnauthorizedLoading = true;
  });
  builder.addCase(
    getUsersWishlistItemsUnauthorized.fulfilled,
    (state, action) => {
      state.getUsersWishlistItemsUnauthorizedLoading = false;
      state.itemInfo = action.payload;
    }
  );
  builder.addCase(getUsersWishlistItemsUnauthorized.rejected, (state) => {
    state.getUsersWishlistItemsUnauthorizedLoading = false;
  });
};

export const getItemUnauthorizedBuilder = (builder) => {
  builder.addCase(getItemUnauthorized.pending, (state) => {
    state.getItemUnauthorizedPending = true;
  });
  builder.addCase(getItemUnauthorized.fulfilled, (state, { payload }) => {
    const { item } = payload;
    state.getItemUnauthorizedPending = false;
    state.selectedItem = item;
  });
  builder.addCase(getItemUnauthorized.rejected, (state) => {
    state.getItemUnauthorizedPending = false;
  });
};
