import styled from "styled-components";

export const PhoneInputWrapper = styled.div`
  width: 100%;

  .react-tel-input {
    font-family: "Avenir-Medium", sans-serif;
  }

  .flag-dropdown {
    width: 5.2rem;
    &.open {
      width: 100%;
    }
  }

  .selected-flag {
    padding: ${(props) =>
      props.$ar
        ? "0px 11px 0px 0px !important"
        : "0px 0px 0px 11px !important"};

    &.open {
      width: 100%;
    }
  }

  .arrow {
    right: ${(props) => (props.$ar ? "29px !important" : "unset !important")};
    left: ${(props) => (props.$ar ? "unset !important" : "29px !important")};
  }

  .country-list {
    background-color: white;
    color: #8a4b5e;
    border: 2px solid #d1d1d4;
    width: unset;
    border-radius: 8px;
    box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  }

  .dial-code {
    font-weight: bold;
    color: #8a4b5e !important;
  }

  .country {
    &.highlight {
      background-color: #6a678e33 !important;
    }
  }

  .form-control {
    border: 1px solid #8c8c8c;
    border-radius: 15px;
    padding: 15px;
    background: none;
    width: 100%;
    padding-left: ${(props) => (props.$ar ? "unset" : "54px")};
    padding-right: ${(props) => (props.$ar ? "54px" : "unset")};
    text-align: ${(props) => (props.$ar ? "right" : "left")};

    height: 5rem;
    font-weight: 700;
    font-size: 14px;
    transition: 0.2s;
    &:active,
    &:focus {
      border: 2px solid #8a4b5e;
      box-shadow: none;
    }
    &:hover {
      border: 1px solid #8a4b5e;
      box-shadow: none;
    }
  }
`;
