import React, { useEffect } from "react";
import {
  MainContainer,
  Column,
  SectionTitle,
  HeaderWrapper,
} from "./SubscriptionPage.styles";
import Sidebar from "../utils/Sidebar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import AllSubs from "../../../components/Subscriptions/AllSubs";
import ViewMoreSubscribers from "../../../components/Subscriptions/ViewMoreSubscribers";
import { getAdminSubscriptions } from "../../../store/slices/subscriptions/asyncThunks";
import { selectedSubscriptions } from "../../../store/slices/subscriptions/slice";
import Header from "../../../components/Header/Header";
import { selectedLocale } from "../../../store/slices/common/slice";
import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";

const SubscriptionPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const expired = queryParams.get("expired");
  const subscriptions = useSelector(selectedSubscriptions);

  useEffect(() => {
    dispatch(getAdminSubscriptions())
      .unwrap()
      .catch((err) => toast.error(t(err.message)));
  }, [dispatch]);

  const onClickMoreChurned = () => {
    const query = { page: 1, search: "", expired: true };
    const queryString = new URLSearchParams(query).toString();
    const url = `/admin-portal/subscriptions?${queryString}`;

    navigate(url);
  };

  const onClickMoreSubscriptions = () => {
    const query = { page: 1, search: "", expired: false };
    const queryString = new URLSearchParams(query).toString();
    const url = `/admin-portal/subscriptions?${queryString}`;

    navigate(url);
  };
  const onClickMoreBankTransfers = () => {
    const query = { page: 1 };
    const queryString = new URLSearchParams(query).toString();
    const url = `/admin-portal/subscriptions/bank-transfers?${queryString}`;

    navigate(url);
  };

  const { company, state } = useSelector(selectBusinessUserInfo);

  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  const locale = useSelector(selectedLocale);
  const upgradePackage = showUpgradeBar && diff > 0 && state;
  const ar = locale === "ar";

  return (
    <MainContainer>
      <HeaderWrapper>
        <Header
          showLogin
          showSignUp
          showNav
          color
          showFavorite
          showCart
          showUser
        />
      </HeaderWrapper>
      <Sidebar />

      <Column $ar={ar} $upgradePackage={upgradePackage}>
        <SectionTitle>{t("subscriptions")}</SectionTitle>
        {expired === null && (
          <AllSubs
            data={subscriptions}
            ar={ar}
            onClickMoreBankTransfers={onClickMoreBankTransfers}
            onClickMoreChurned={onClickMoreChurned}
            onClickMoreSubscriptions={onClickMoreSubscriptions}
          />
        )}
        {expired && (
          <ViewMoreSubscribers
            ar={ar}
            onBack={() => navigate("/admin-portal/subscriptions")}
          />
        )}
      </Column>
    </MainContainer>
  );
};

export default SubscriptionPage;
