import { axiosRecipientAuthClient } from "..";

import { handleError } from "../../utils/errors";

const BASE_URL = "delete-account";

class DeleteService {
  deleteAccount = async (mobilePhone, username) => {
    try {
      const response = await axiosRecipientAuthClient.post(`${BASE_URL}/`, {
        mobilePhone,
        username,
      });
      if (response.status !== 200) {
        throw new Error();
      }

      return;
    } catch (err) {
      throw handleError(err, "Error occurred while deleting an account.");
    }
  };
}

const deleteService = new DeleteService();

export default deleteService;
