import React from "react";
import { ContentContainer } from "./PaymentModal.styles";
import ModalDownload from "../Modal/ModalDownload";
import PaymentsComponent from "../PaymentsComponent/PaymentsComponent";

const PaymentModal = ({
  modalRef,
  amount,
  onComplete,
  setLoading,
  loading,
}) => {
  return (
    <ModalDownload
      big
      ref={modalRef}
      onBack={() => {
        modalRef.current.hide();
      }}
    >
      <ContentContainer>
        <PaymentsComponent
          hide
          width
          priceToPay={amount}
          setLoading={setLoading}
          loading={loading}
          onBack={() => modalRef.current.hide()}
          onComplete={onComplete}
        />
      </ContentContainer>
    </ModalDownload>
  );
};

export default PaymentModal;
