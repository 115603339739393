import React, { useEffect, useRef, useState } from "react";
import {
  Title,
  CloseContainer,
  Price,
  Row,
  StyledButton,
  StyledRow,
  StyledPrice,
  Button,
  ButtonYes,
  Content,
  StyledInput,
} from "./PriceBreakModal.styles";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { ReactComponent as Minus } from "../../assets/icons/minus.svg";
import Modal from "./Modal2";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { postInstantQuotation } from "../../store/slices/instantQuotation/asyncThunks";
import { useSelector } from "react-redux";
import { selectedQuotationData } from "../../store/slices/instantQuotation/instantQuotationSlice";
import openToast from "../../../src/utils/toast";
import AppLoader from "../AppLoader";

const InstantQuoteModal = ({
  open,
  close,
  minQuantity,
  itemId,
  subcategoryIds,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [branding, setBranding] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const quoteData = useSelector(selectedQuotationData);
  const toastId = useRef(null);

  const idArray = subcategoryIds.map((obj) => +obj.id);

  useEffect(() => {
    if (minQuantity) {
      setQuantity(minQuantity);
    }
  }, [minQuantity, open]);

  const onClickPlus = ({ value, setValue }) => {
    setValue(+value + 1);
  };

  const onClickMinus = ({ value, setValue }) => {
    if (value > 1) {
      setValue(+value - 1);
    }
  };
  const handleClick = (isBranding) => {
    setBranding(isBranding);
  };

  const handleInputChange = (e, setValue) => {
    const inputValue = e.target.value;

    if (!isNaN(inputValue) && inputValue !== "") {
      setValue(parseInt(inputValue, 10));
    }
  };

  const onGetQuote = () => {
    if (!companyName) {
      openToast(toastId, "get-quote", "error", t("company-name-is-required"));
      return;
    }

    if (minQuantity > quantity) {
      openToast(
        toastId,
        "get-quote",
        "error",
        t("Minimum quantity for this item is:") + minQuantity
      );
      return;
    }

    dispatch(
      postInstantQuotation({
        itemId: +itemId,
        quantity: quantity,
        branding: branding,
        companyName: companyName,
        marketplaceSubcategoryIds: idArray,
      })
    )
      .unwrap()
      .then((res) => {
        window.open(res.data.url, "_blank");
      })
      .catch((err) =>
        openToast(toastId, "post-instant-quotation", "error", t(err.message))
      );
  };

  return (
    <Modal
      open={open}
      onClose={close}
      right
      width={"567px"}
      height={"100%"}
      padding={"50px 60px"}
      unset
    >
      <CloseContainer
        onClick={() => {
          if (!quoteData.loading) {
            close();
            setCompanyName("");
            setBranding(true);
            setQuantity(1);
          } else {
            openToast(
              toastId,
              "instant-quote-modal",
              "error",
              t("cannot-quit")
            );
          }
        }}
      >
        <CloseIcon fill="white" width={20} height={20} />
      </CloseContainer>
      <Title>{t("instant-quote")}</Title>
      <Content $smallGap>
        <Row>
          <Price>{t("quantity")}</Price>
          <StyledRow>
            <StyledButton
              onClick={() => {
                onClickMinus({
                  value: quantity,
                  setValue: setQuantity,
                });
              }}
            >
              <Minus />
            </StyledButton>
            <StyledPrice
              value={quantity}
              onChange={(e) => handleInputChange(e, setQuantity)}
              min={1}
            />
            <StyledButton
              onClick={() => {
                onClickPlus({
                  value: quantity,
                  setValue: setQuantity,
                });
              }}
            >
              <Plus />
            </StyledButton>
          </StyledRow>
        </Row>

        <Row>
          <Price>{t("branding")}</Price>
          <StyledRow style={{ gap: 10 }}>
            <ButtonYes
              onClick={() => handleClick(true)}
              $active={branding === true}
            >
              {t("yes")}
            </ButtonYes>

            <ButtonYes
              onClick={() => handleClick(false)}
              $active={branding === false}
            >
              {t("no")}
            </ButtonYes>
          </StyledRow>
        </Row>

        <Row>
          <Price>{t("company-name")}</Price>
          <StyledInput
            value={companyName}
            placeholder={t("name-on-quote")}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </Row>

        <Button onClick={onGetQuote}>
          {!quoteData?.loading ? t("get-quote") : <AppLoader size={24} light />}
        </Button>
      </Content>
    </Modal>
  );
};

export default InstantQuoteModal;
