import React from "react";
import { ReactComponent as Checked } from "../../assets/icons/checkbox-fill.svg";
import { ReactComponent as Unchecked } from "../../assets/icons/checkbox-blank-line.svg";

const Checkbox = ({ checked, onChange }) => {
  return (
    <div onClick={onChange}>
      {checked ? (
        <Checked width={38} height={38} className="pointer" />
      ) : (
        <Unchecked width={38} height={38} className="pointer" />
      )}
    </div>
  );
};

export default Checkbox;
