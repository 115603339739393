import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectCreateAccountPending } from "../../store/slices/auth/slice";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader/Loader";
import {
  Container,
  Screen,
  MainContainer,
  Wrapper,
  Title,
  Gap,
  HeaderWrapper,
  Description,
  TimelineContainer,
  Row,
  TextYear,
  YearTitle,
  TitleContainer,
  YearDescription,
  Background,
  CardContainer,
  PhotoContainer,
  Name,
  Position,
  NamePositionContainer,
  Word,
  PhotoNameContainer,
  SecondSectionContainer,
  LeftContainer,
  RightContainer,
  OurTextContainer,
  OurTitle,
  OurDescription,
  Lines,
  SectionThreeTitle,
  TeamPhotoContainer,
  SectionThreeDescription,
  SectionThreeColumns,
  SectionThreeRows,
  SectionThreeRowTitle,
  TeamPhotoContainerAbsolute,
  SectionFour,
  MapPhotoContainer,
  RightContainerSectionThree,
  TimelineCircle,
  TimelineCircleSecond,
  TimelineCircleThird,
  TimelineLine,
  TimelineLineSecond,
  TimelineLineThird,
  TimelineDiv,
  ThirdSectionContainer,
  RowTextContainer,
  SectionFourDescription,
  TextOnImageMap,
  InfoDiv,
  TextOnImageLeft,
  TextOnImageLeft2,
  TextOnImageMapRight,
  TextOnImageRight,
  TextOnImageRight2,
  MapLine,
  MailBoxIcon,
  MapPinIcon,
  TitleSecondContainer,
  SubtitleSecondContainer,
  PhotosContainer,
  DescriptionContainer,
  DescriptionContainerSectionThree,
  TitleDiv,
} from "./AboutUsPage.styles";
import GirlWorkingOnLaptop from "../../assets/images/Ayman_Photo.png";
import TeamPicture from "../../assets/images/team-Picture.png";
import TeamPictureOnDisplay from "../../assets/images/team-picture-on-display.png";
import YgiiAddressMap from "../../assets/images/ygii-address-map.png";
import Abdulkareem from "../../assets/images/Abdulkareem.png";
import Filip from "../../assets/images/Filip.png";
import { selectedLocale } from "../../store/slices/common/slice";

const AboutUsPage = () => {
  const { t } = useTranslation();
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";
  const createAccountPending = useSelector(selectCreateAccountPending);

  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isIntersecting2, setIsIntersecting2] = useState(false);
  const [isIntersecting3, setIsIntersecting3] = useState(false);
  const [isIntersecting4, setIsIntersecting4] = useState(false);
  const [isIntersecting5, setIsIntersecting5] = useState(false);
  const ref = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleIntersection = (setIntersecting, ref) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      { rootMargin: "-300px" }
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  };

  useEffect(() => handleIntersection(setIsIntersecting, ref, "slide-in"), []);
  useEffect(() => handleIntersection(setIsIntersecting2, ref2, "slide-in"), []);
  useEffect(() => handleIntersection(setIsIntersecting3, ref3, "slide-in"), []);
  useEffect(() => handleIntersection(setIsIntersecting4, ref4, "slide-in"), []);
  useEffect(() => handleIntersection(setIsIntersecting5, ref5, "slide-in"), []);

  useEffect(() => {
    const handleClassToggle = (isIntersecting, ref, className) => {
      ref.current.querySelectorAll("span").forEach((el) => {
        if (isIntersecting) {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      });
    };
    const handleClassToggleDiv = (isIntersecting, ref, className) => {
      ref.current.querySelectorAll("div").forEach((el) => {
        if (isIntersecting) {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      });
    };

    handleClassToggleDiv(isIntersecting, ref, "slide-in");
    handleClassToggle(isIntersecting2, ref2, "slide-in");
    handleClassToggleDiv(isIntersecting3, ref3, "slide-in");
    handleClassToggleDiv(isIntersecting4, ref4, "slide-in");
    handleClassToggleDiv(isIntersecting5, ref5, "slide-in");
  }, [
    isIntersecting,
    isIntersecting2,
    isIntersecting3,
    isIntersecting4,
    isIntersecting5,
  ]);

  const [headerVisible, setHeaderVisible] = useState(true);
  const [lastScrollUpY, setLastScrollUpY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(null);
  const lastScrollTopRef = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      const lastScrollTop = lastScrollTopRef.current;

      if (currentScrollTop === 0) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
        setScrollDirection(null);
        lastScrollTopRef.current = currentScrollTop;
        return;
      }

      if (currentScrollTop > lastScrollTop) {
        setScrollDirection("down");
        setLastScrollUpY(currentScrollTop);
      } else {
        setScrollDirection("up");
      }

      if (scrollDirection === "up" && currentScrollTop < lastScrollUpY - 350) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
      }

      if (
        scrollDirection === "down" &&
        currentScrollTop > lastScrollUpY + 350
      ) {
        setHeaderVisible(false);
      }

      lastScrollTopRef.current = currentScrollTop;
    };

    const handleScrollDebounced = () => {
      requestAnimationFrame(handleScroll);
    };

    window.addEventListener("scroll", handleScrollDebounced);

    return () => {
      window.removeEventListener("scroll", handleScrollDebounced);
    };
  }, [scrollDirection]);

  return (
    <>
      {createAccountPending && <Loader disableScroll />}

      <HeaderWrapper
        style={{
          opacity: headerVisible ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
        }}
      >
        <Header
          showLogin
          showNav
          color
          showUser
          showSignUp
          hideSearch
          display={headerVisible}
        />
      </HeaderWrapper>

      <MainContainer>
        <Container>
          <Screen>
            <Wrapper ref={ref}>
              <TitleDiv>
                <Title>{t("meet-ygii")}</Title>
              </TitleDiv>

              <DescriptionContainer $ar={ar}>
                <Description>{t("about-us-description")}</Description>
              </DescriptionContainer>

              <Gap $ar={ar}>
                <TimelineDiv>
                  <TimelineCircle />
                  <TimelineLine />
                  <TimelineCircleSecond />
                  <TimelineLineSecond />
                  <TimelineCircleThird />
                  <TimelineLineThird />
                </TimelineDiv>

                <TimelineContainer>
                  <Row>
                    <TitleContainer>
                      <Lines>
                        <TimelineCircle />
                        <TimelineLine />
                      </Lines>

                      <TextYear>{"2022"}</TextYear>
                      <YearTitle>{t("ygii-founded")}</YearTitle>
                      <YearDescription>
                        {t("ygii-founded-description")}
                      </YearDescription>
                    </TitleContainer>
                  </Row>
                  <Row>
                    <TitleContainer>
                      <Lines>
                        <TimelineCircle />
                        <TimelineLine />
                      </Lines>
                      <TextYear>{"2023"}</TextYear>
                      <YearTitle>{t("product-launched")}</YearTitle>
                      <YearDescription>
                        {t("product-launched-description")}
                      </YearDescription>
                    </TitleContainer>
                  </Row>
                  <Row>
                    <TitleContainer>
                      <Lines>
                        <TimelineCircle />
                        <TimelineLine />
                      </Lines>
                      <TextYear>{"2024"}</TextYear>
                      <YearTitle>{t("ygii-reaches-clients")}</YearTitle>
                      <YearDescription>
                        {t("ygii-reaches-clients-description")}
                      </YearDescription>
                    </TitleContainer>
                  </Row>
                </TimelineContainer>
              </Gap>
            </Wrapper>

            <Background ref={ref2}>
              <TitleSecondContainer $ar={ar}>
                {t("web-intelligence")}
              </TitleSecondContainer>
              <SubtitleSecondContainer $ar={ar}>
                {t("web-intelligence-description")}
              </SubtitleSecondContainer>

              <SecondSectionContainer ref={ref3}>
                <LeftContainer $ar={ar}>
                  <OurTextContainer>
                    <OurTitle>{t("our-vision")}</OurTitle>
                    <OurDescription>
                      {t("our-vision-description")}
                    </OurDescription>
                  </OurTextContainer>
                  <OurTextContainer>
                    <OurTitle>{t("our-mission")}</OurTitle>
                    <OurDescription>
                      {t("our-mission-description")}
                    </OurDescription>
                  </OurTextContainer>
                  <OurTextContainer>
                    <OurTitle>{t("our-values")}</OurTitle>
                    <OurDescription>
                      {t("our-values-description")}
                    </OurDescription>
                  </OurTextContainer>
                </LeftContainer>
                <RightContainer $ar={ar}>
                  <CardContainer>
                    <PhotoNameContainer>
                      <PhotoContainer src={Abdulkareem} alt="item-img" />
                      <NamePositionContainer>
                        <Name>{"Abdulkareem Aloumi"}</Name>
                        <Position>{t("co-founder-executive")}</Position>
                      </NamePositionContainer>
                    </PhotoNameContainer>
                    <Word>{t("co-founder-executive-description")}</Word>
                  </CardContainer>
                  <CardContainer>
                    <PhotoNameContainer>
                      <PhotoContainer
                        src={GirlWorkingOnLaptop}
                        alt="item-img"
                      />
                      <NamePositionContainer>
                        <Name>{"Ayman Alzamil"}</Name>
                        <Position>{t("co-founder-strategy")}</Position>
                      </NamePositionContainer>
                    </PhotoNameContainer>
                    <Word>{t("co-founder-strategy-description")}</Word>
                  </CardContainer>
                  <CardContainer>
                    <PhotoNameContainer>
                      <PhotoContainer src={Filip} alt="item-img" />
                      <NamePositionContainer>
                        <Name>{"Filip Vukelic"}</Name>
                        <Position>{t("co-founder-technology")}</Position>
                      </NamePositionContainer>
                    </PhotoNameContainer>
                    <Word>{t("co-founder-technology-description")}</Word>
                  </CardContainer>
                </RightContainer>
              </SecondSectionContainer>
            </Background>

            <Background ref={ref4}>
              <ThirdSectionContainer>
                <PhotosContainer $ar={ar}>
                  <TeamPhotoContainer src={TeamPicture} alt="item-img" />
                  <TeamPhotoContainerAbsolute
                    src={TeamPictureOnDisplay}
                    alt="item-img"
                  />
                </PhotosContainer>
                <RightContainerSectionThree>
                  <SectionThreeTitle>{t("building-team")}</SectionThreeTitle>
                  <SectionThreeDescription>
                    {t("building-team-description")}
                  </SectionThreeDescription>
                  <SectionThreeRows>
                    <SectionThreeColumns>
                      <RowTextContainer>
                        <SectionThreeRowTitle>
                          {t("we-foster-curiosity")}
                        </SectionThreeRowTitle>
                        <DescriptionContainerSectionThree>
                          {t("we-foster-curiosity-description")}
                        </DescriptionContainerSectionThree>
                      </RowTextContainer>
                      <RowTextContainer>
                        <SectionThreeRowTitle>
                          {t("we-celebrate-achievements")}
                        </SectionThreeRowTitle>
                        <DescriptionContainerSectionThree>
                          {t("we-celebrate-achievements-description")}
                        </DescriptionContainerSectionThree>
                      </RowTextContainer>
                    </SectionThreeColumns>
                    <SectionThreeColumns>
                      <RowTextContainer>
                        <SectionThreeRowTitle>
                          {t("we-create-comfort")}
                        </SectionThreeRowTitle>
                        <DescriptionContainerSectionThree>
                          {t("we-create-comfort-description")}
                        </DescriptionContainerSectionThree>
                      </RowTextContainer>
                      <RowTextContainer>
                        <SectionThreeRowTitle>
                          {t("we-grow-together")}
                        </SectionThreeRowTitle>
                        <DescriptionContainerSectionThree>
                          {t("we-grow-together-description")}
                        </DescriptionContainerSectionThree>
                      </RowTextContainer>
                    </SectionThreeColumns>
                  </SectionThreeRows>
                </RightContainerSectionThree>
              </ThirdSectionContainer>
            </Background>

            <SectionFour ref={ref5}>
              <TitleDiv>
                <SectionThreeTitle>{t("meet-the-ygii-team")}</SectionThreeTitle>
              </TitleDiv>
              <DescriptionContainer $ar={ar}>
                <SectionFourDescription>
                  {t("meet-the-ygii-team-description")}
                </SectionFourDescription>
              </DescriptionContainer>

              <InfoDiv $ar={ar}>
                <MapPhotoContainer src={YgiiAddressMap} alt="item-img" />
                <MailBoxIcon />
                <TextOnImageMap>{t("write-to-us")}</TextOnImageMap>
                <TextOnImageLeft>
                  {`${t("general")}: hello@ygii.app`}
                </TextOnImageLeft>
                <TextOnImageLeft2>
                  {`${t("support")}: partners@ygii.app`}
                </TextOnImageLeft2>
                <MapLine />
                <MapPinIcon />
                <TextOnImageMapRight>
                  {t("visit-HQ-office")}
                </TextOnImageMapRight>
                <TextOnImageRight>
                  {"8162 King Fahd Rd, Olaya Dist."}{" "}
                </TextOnImageRight>
                <TextOnImageRight2>{"Riyadh, Saudi Arabia"} </TextOnImageRight2>
              </InfoDiv>
            </SectionFour>
          </Screen>
        </Container>
      </MainContainer>
      <Footer />
    </>
  );
};

export default AboutUsPage;
