import { createAsyncThunk } from "@reduxjs/toolkit";
import giftService from "../../../api/services/gift";

export const getAllGiftItems = createAsyncThunk(
  "gifts/getAllGiftItems",
  async (requestData) => {
    return await giftService.getAllGiftItems(requestData);
  }
);
export const getAdminDashboardData = createAsyncThunk(
  "gifts/getAdminDashboardData",
  async (requestData) => {
    return await giftService.getAdminDashboardData(requestData);
  }
);

export const getItemsByCategory = createAsyncThunk(
  "gifts/getItemsByCategory",
  async (requestData) => {
    return await giftService.getItemsByCategory(requestData);
  }
);

export const getExploreItemsBySection = createAsyncThunk(
  "gifts/getExploreItemsBySection",
  async (requestData) => {
    return await giftService.getItemsBySection(requestData);
  }
);
export const getItemsBySection = createAsyncThunk(
  "gifts/getItemsBySection",
  async (requestData) => {
    return await giftService.getItemsBySection(requestData);
  }
);

export const getItemById = createAsyncThunk(
  "gifts/getItemById",
  async (requestData) => {
    return await giftService.getItemById(requestData);
  }
);

export const getCategories = createAsyncThunk(
  "gifts/getCategories",
  async () => {
    return await giftService.getCategories();
  }
);

export const getSections = createAsyncThunk("gifts/getSections", async () => {
  return await giftService.getSections();
});

export const getCategoriesWithSixteenOrMoreItems = createAsyncThunk(
  "gifts/getCategoriesWithSixteenOrMoreItems",
  async (requestData) => {
    return await giftService.getCategoriesWithSixteenOrMoreItems(requestData);
  }
);

export const getAllCompanyGifting = createAsyncThunk(
  "gifts/getAllCompanyGifting",
  async (requestData) => {
    return await giftService.getAllCompanyGifting(requestData);
  }
);

export const getCompanyGiftingById = createAsyncThunk(
  "gifts/getCompanyGiftingById",
  async (requestData) => {
    return await giftService.getCompanyGiftingById(requestData);
  }
);

export const rejectCompanyGiftingRecipientOrder = createAsyncThunk(
  "gifts/rejectOrder",
  async (requestData) => {
    return await giftService.rejectCompanyGiftingRecipientOrder(requestData);
  }
);

export const acceptCompanyGiftingRecipientOrder = createAsyncThunk(
  "gifts/acceptOrder",
  async (requestData) => {
    return await giftService.acceptCompanyGiftingRecipientOrder(requestData);
  }
);

export const getUserByPhone = createAsyncThunk(
  "gifts/getUserByPhone",
  async (requestData) => {
    return await giftService.getUserByPhone(requestData);
  }
);

export const getCompanyGiftingItems = createAsyncThunk(
  "company/gifting-items",
  async (requestData) => {
    return await giftService.getCompanyGiftingItems(requestData);
  }
);

export const getCompanyGiftingItemsForMe = createAsyncThunk(
  "company/gifting-items-for-me",
  async (requestData) => {
    return await giftService.getCompanyGiftingItemsForMe(requestData);
  }
);
export const createCompanyGiftingRecipientOrder = createAsyncThunk(
  "company/gifting-recipient-order",
  async (requestData) => {
    return await giftService.createCompanyGiftingRecipientOrder(requestData);
  }
);

export const getAllCompanyGiftingAdmin = createAsyncThunk(
  "company/getAllCompanyGiftingAdmin",
  async (requestData) => {
    return await giftService.getAllCompanyGiftingAdmin(requestData);
  }
);

export const updateRecipientOrderState = createAsyncThunk(
  "company/updateRecipientOrderState",
  async (requestData) => {
    return await giftService.updateCompanyGiftingRecipientOrderState(
      requestData
    );
  }
);

export const findRecipientsWishlists = createAsyncThunk(
  "gifts/findRecipientsWishlists",
  async (requestData) => {
    return await giftService.findRecipientsWishlists(requestData);
  }
);

export const updateCompanyGiftingDetailsStatus = createAsyncThunk(
  "marketplace/update-company-gifting-details-status",
  async (requestData) => {
    return await giftService.updateCompanyGiftingDetailsStatus(requestData);
  }
);

export const rejectCompanyGiftingToken = createAsyncThunk(
  "gifts/reject-company-gifting-token",
  async (requestData) => {
    return await giftService.rejectCompanyGiftingToken(requestData);
  }
);

export const setDeliveryAddressCompanyGiftingToken = createAsyncThunk(
  "gifts/set-delivery-address",
  async (requestData) => {
    return await giftService.setDeliveryAddressCompanyGiftingToken(requestData);
  }
);

export const getDeliveryAddressCompanyGiftingToken = createAsyncThunk(
  "gifts/get-delivery-address",
  async (requestData) => {
    return await giftService.getDeliveryAddressCompanyGiftingToken(requestData);
  }
);
