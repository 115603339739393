import React, { useEffect, useRef, useState } from "react";

import {
  CardRow,
  Container,
  Group,
  Number,
  Text,
  Title,
} from "./TabCreditCards.styles";

import { ReactComponent as Unchecked } from "../../../assets/icons/radio-check.svg";
import { ReactComponent as Checked } from "../../../assets/icons/radio-on.svg";
import { ReactComponent as Dot } from "../../../assets/icons/dot.svg";
import { ReactComponent as Visa } from "../../../assets/icons/visa.svg";
import { ReactComponent as MasterCard } from "../../../assets/icons/mastercard.svg";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  removePaymentMethodLoading,
  selectedPaymentMethodData,
  selectedPaymentMethodLoading,
  setDefaultPaymentMethodLoading,
} from "../../../store/slices/payment/paymentSlice";

import {
  getPaymentMethod,
  removePaymentMethod,
  setDefaultPaymentMethod,
} from "../../../store/slices/payment/asyncThunks";

import UseAnimations from "react-useanimations";
import trash2 from "react-useanimations/lib/trash2";
import { toast } from "react-toastify";
import ModalConfirm from "../../ModalConfirm/ModalConfirm";
import MiniLoader from "../../MiniLoader/MiniLoader";
import openToast from "../../../utils/toast";

const TabCreditCards = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const paymentMethodsData = useSelector(selectedPaymentMethodData);
  const deletePending = useSelector(removePaymentMethodLoading);
  const loading = useSelector(selectedPaymentMethodLoading);
  const editPending = useSelector(setDefaultPaymentMethodLoading);
  const modalRef = useRef();
  const [selectedPayment, setSelectedPayment] = useState("");
  const toastId = useRef(null);

  useEffect(() => {
    dispatch(getPaymentMethod());
  }, [dispatch]);

  const removePaymentAction = () => {
    dispatch(removePaymentMethod(selectedPayment))
      .unwrap()
      .then(() => {
        modalRef.current.hide();
        toast.success(t("card-deleted"));
      })
      .catch((err) => {
        openToast(toastId, "remove-payment-action", "error", t(err.message));
      });
  };

  const removePaymentOpenModal = (id) => {
    modalRef.current.show();
    setSelectedPayment(id);
  };

  return (
    <>
      {!editPending && !loading ? (
        <Container>
          {paymentMethodsData.length !== 0 ? (
            <Title>{t("saved-cards")}:</Title>
          ) : (
            <Title>{t("you-dont-have-card")}</Title>
          )}

          {paymentMethodsData.length !== 0 && (
            <>
              {paymentMethodsData.map((payment) => (
                <CardRow key={payment.id}>
                  {payment.default ? (
                    <Checked width={20} height={20} />
                  ) : (
                    <Unchecked width={20} height={20} />
                  )}

                  {payment.type === "VISA" ? <Visa /> : <MasterCard />}
                  <Group>
                    <Dot />
                    <Dot />
                    <Dot />
                  </Group>
                  <Group>
                    <Dot />
                    <Dot />
                    <Dot />
                  </Group>
                  <Group>
                    <Dot />
                    <Dot />
                    <Dot />
                  </Group>
                  <Number>{payment.lastFour}</Number>
                  {paymentMethodsData.length === 1 ? (
                    <div style={{ marginBottom: 8 }}>
                      <UseAnimations
                        animation={trash2}
                        size={32}
                        className="delete-icon"
                        strokeColor="#8A4B5E"
                        onClick={() => removePaymentOpenModal(payment.id)}
                      />
                    </div>
                  ) : (
                    <>
                      {!payment.default && (
                        <div style={{ marginBottom: 8 }}>
                          <UseAnimations
                            animation={trash2}
                            size={32}
                            className="delete-icon"
                            strokeColor="#8A4B5E"
                            onClick={() => removePaymentOpenModal(payment.id)}
                          />
                        </div>
                      )}
                    </>
                  )}

                  {!payment.default && (
                    <Text
                      onClick={() => {
                        dispatch(setDefaultPaymentMethod(payment.id))
                          .unwrap()
                          .then(() => toast.success(t("the-card-has-been")))
                          .catch((err) =>
                            openToast(
                              toastId,
                              "set-default-payment",
                              "error",
                              t(err.message)
                            )
                          );
                      }}
                    >
                      {t("set-card-as-default")}
                    </Text>
                  )}
                </CardRow>
              ))}
            </>
          )}
        </Container>
      ) : (
        <MiniLoader leftShift />
      )}

      <ModalConfirm
        title={"are-you-sure-card"}
        modalRef={modalRef}
        onClickNo={() => {
          modalRef.current.hide();
        }}
        onClickYes={() => {
          removePaymentAction();
        }}
        confirmLoading={deletePending}
      />
    </>
  );
};

export default TabCreditCards;
