import React, { useEffect, useState } from "react";
import { HoverText, TableBodyItem } from "./Table.styles";

const TableItem = ({
  data,
  className,
  onClickInfo,
  center,
  big,
  showHover,
  hoverText,
  length,
}) => {
  let lengthValue = length ? length : 25;
  const [hover, setHover] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timer;
    if (hover) {
      setIsVisible(true);
    } else {
      timer = setTimeout(() => setIsVisible(false), 50);
    }

    return () => clearTimeout(timer);
  }, [hover]);

  return showHover ? (
    <TableBodyItem
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={className}
      onClick={onClickInfo}
      $centerText={center}
      $big={big}
    >
      {isVisible && hoverText?.length > lengthValue && (
        <HoverText $hover={hover}>{hoverText}</HoverText>
      )}
      {data}
    </TableBodyItem>
  ) : (
    <TableBodyItem
      className={className}
      onClick={onClickInfo}
      $centerText={center}
      $big={big}
    >
      {data}
    </TableBodyItem>
  );
};

export default TableItem;
