import { styled } from "styled-components";
import { ReactComponent as MailBox } from "../../assets/icons/mailbox.svg";
import { ReactComponent as MapPin } from "../../assets/icons/map-pin.svg";
export const Wrapper = styled.div`
  padding: 0 2rem;
`;
export const MainContainer = styled.div`
  position: relative;
  z-index: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f5f6fa;
`;

export const HeaderWrapper = styled.div`
  background: #8a4b5e;
  position: fixed;
  z-index: 1000;
  width: 100%;
`;
export const ScreenContent = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;
  height: 93.9rem;
  border: 1px solid #b1b1b1;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(1, 0, 1, 0.1);
  border-radius: 3rem;
  padding: 3rem 0px;
  background: #fff;
  padding: 3rem;

  align-items: center;
  justify-content: center;
  gap: 10rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 1375px) {
    height: 100%;
  }
`;
export const Slider = styled.input`
  width: 1000px;
  @media (max-width: 1100px) {
    width: 90%;
  }
`;

export const HeaderText = styled.span`
  color: #8a4b5e;

  text-align: center;
  font-family: "Avenir-Roman";
  font-size: 5rem;
  font-weight: 800;
  letter-spacing: -0.178px;
`;
export const LargeText = styled(HeaderText)`
  font-size: 4rem;
  font-weight: 400;
  @media (max-width: 410px) {
    font-size: 3rem;
  }
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
`;

export const Screen = styled.div`
  /* padding: 2rem 3rem 1rem 3rem; */
  position: relative;
  flex-direction: column;
  height: fit-content;
  height: 100%;
  width: 100%;
  margin-top: 160px;
`;
export const TitleDiv = styled.div`
  transition: all ease-in 0.8s;

  opacity: 0;
  @media (max-width: 400px) {
    opacity: 1 !important;
  }
`;
export const Title = styled.span`
  justify-content: center;
  display: flex;
  text-align: center;
  user-select: none;
  flex-wrap: wrap;
  font-family: "FrankfurterEF-Medium", sans-serif;
  color: #130f35;
  font-size: 4.8rem;

  letter-spacing: -0.177777px;

  @media (max-width: 400px) {
    font-size: 3.4rem;
  }
  padding-bottom: 15px;
`;

export const Description = styled.span`
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  color: #130f35;
  font-family: "Avenir-Roman";
  font-size: 2.2rem;

  max-width: 840px;
`;

export const TitleSecondContainer = styled(Description)`
  font-family: "Avenir Heavy";
  font-size: 40px;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    !props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  @media (max-width: 400px) {
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
`;

export const SubtitleSecondContainer = styled(Description)`
  font-size: 18px;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  @media (max-width: 400px) {
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
`;

export const Background = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  flex-direction: column;
  padding: 80px;
  align-items: center;
  gap: 48px;
  @media (max-width: 1860px) {
  }
  @media (max-width: 1235px) {
    background: #fff;
  }
  @media (max-width: 800px) {
    padding: 30px;
    /* padding: 10px; */
  }
`;

export const SectionThree = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;

  flex-direction: column;
  padding: 80px;
  align-items: center;
  gap: 48px;
`;

export const TimelineContainer = styled.div`
  display: flex;
  right: 25px;
  flex-direction: row;
  padding-top: 130px;
  justify-content: center;
  /* position: absolute; */
  align-items: center;
  @media (max-width: 700px) {
    flex-direction: column;
    padding-top: 50px;
  }
`;

export const TimelineDiv = styled.div`
  position: absolute;
  top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d5d8e1;
  width: 700px;
  height: 2px;
  @media (max-width: 700px) {
    display: none;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 9px;
  width: 214px;
  height: 264px;
  flex-shrink: 0;
  flex-direction: column;
  @media (max-width: 565px) {
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 190px;
  height: 47px;
  flex-direction: column;
  flex-shrink: 0;
`;

export const TextYear = styled.span`
  text-align: left;
  display: flex;
  font-family: "Inter";
  font-size: 14.9px;
  color: #8a4b5e;
`;

export const YearTitle = styled.span`
  text-align: left;
  display: flex;
  font-family: "FrankfurterEF-Medium", sans-serif;
  color: #130f35;
  font-size: 14.6px;
  line-height: 24px;
`;

export const YearDescription = styled.span`
  text-align: left;
  display: flex;
  color: #130f35;
  font-family: "Avenir-Roman";
  font-size: 14.9px;
  line-height: 24px;
  padding-top: 20px;
`;
export const StyledRow = styled(Row)`
  right: 0px;
  left: 25px;
  top: 25px;
`;
export const Text = styled.span`
  color: #8a4b5e;
  text-align: center;
  font-family: "Avenir-Roman";
  font-size: 2.6rem;
  letter-spacing: -0.178px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const StyledText = styled(Text)`
  font-weight: 600;
`;

export const Row2 = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Gap = styled.div`
  display: flex;
  flex-direction: column;
  transition: all ease-in 0.8s;
  transform: ${(props) =>
    props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  align-items: center;
  gap: 40px;
  width: 100%;
  @media (max-width: 750px) {
    padding: 0px 20px;
  }
  @media (max-width: 400px) {
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
`;

export const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ErrorContainer = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SecondSectionContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 60px;
  width: 100%;
  justify-content: center;
  @media (max-width: 1235px) {
    flex-direction: column;
  }
`;

export const ThirdSectionContainer = styled(SecondSectionContainer)`
  gap: 220px;
  max-width: 1440px;
  align-items: flex-start;
  @media (max-width: 1235px) {
    gap: 120px;
    align-items: center;
  }
`;

export const LeftContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    !props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  @media (max-width: 1235px) {
    flex-direction: column;
  }
  @media (max-height: 700px) {
    /* flex-direction: row; */
    width: 100%;
  }
  @media (max-width: 500px) {
    gap: 80px;
  }

  @media (max-width: 400px) {
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
`;
export const RightContainer = styled.div`
  display: flex;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 400px) {
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
`;

export const OurTextContainer = styled.div`
  display: flex;
  width: 538px;
  height: 191px;
  padding: 32px;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 25px;

  flex-direction: column;
  @media (max-width: 1235px) {
    width: 418px;
    height: 191px;
  }
  @media (max-width: 500px) {
    width: 100%;
    padding: 20px;
    height: fit-content;
  }
`;

export const OurTitle = styled.span`
  color: #130f35;
  font-family: "FrankfurterEF-Medium", sans-serif;
  font-size: 21.2px;
  line-height: 31.2px;
`;

export const OurDescription = styled.span`
  color: #130f35;
  font-family: "Avenir-Roman";
  font-size: 14.9px;
  line-height: 24px;
  @media (max-width: 385px) {
    font-size: 15px;
    line-height: 20px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  width: 538px;
  height: 190.4px;
  padding: 32px;
  justify-content: center;

  flex-shrink: 0;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 20px 50px -10px rgba(19, 15, 53, 0.1);
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1235px) {
    width: 318px;
    height: 291px;
  }
  @media (max-width: 500px) {
    width: 100%;
    height: 291px;
  }
`;
export const PhotoContainer = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  user-select: none;
  align-self: center;
  justify-content: center;
  object-fit: cover;
`;

export const NamePositionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const PhotoNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14px;
`;

export const Name = styled.span`
  color: #130f35;
  font-family: "FrankfurterEF-Medium", sans-serif;
  font-size: 18.3px;
  line-height: 30px;
`;
export const Position = styled.span`
  color: #130f35;
  font-family: "Avenir-Roman";
  font-size: 14.9px;
  line-height: 24px;
`;
export const Word = styled.span`
  color: #130f35;
  font-family: "Avenir-Roman";
  font-size: 14.9px;
  line-height: 24px;
`;

export const RightContainerSectionThree = styled(RightContainer)`
  justify-content: space-between;
  gap: 108px;
  @media (max-width: 1235px) {
    justify-content: center;
    align-items: center;
  }
`;

export const SectionThreeTitle = styled.span`
  color: #130f35;
  font-family: "FrankfurterEF-Medium", sans-serif;
  font-size: 40px;
  line-height: 57.2px;
  @media (max-width: 1235px) {
    text-align: center;
    line-height: 40px;
  }
  @media (max-width: 385px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const SectionThreeDescription = styled.span`
  color: #130f35;
  font-family: "Avenir-Roman";
  font-size: 18.6px;
  line-height: 30px;
  flex-wrap: wrap;
  text-align: start;
  width: 60%;
  @media (max-width: 697px) {
    text-align: center;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
  @media (max-width: 385px) {
    font-size: 15px;
    line-height: 20px;
  }
`;
export const SectionFourDescription = styled(SectionThreeDescription)`
  text-align: center;
`;

export const SectionThreeColumns = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const SectionThreeRows = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  @media (max-width: 1230px) {
    gap: 50px;
  }
  @media (max-width: 697px) {
    flex-direction: column;
    gap: 50px;
  }
`;

export const RowTextContainer = styled.div`
  display: flex;
  width: 280px;
  height: 72px;
  justify-content: center;
  flex-direction: column;
`;

export const SectionThreeRowTitle = styled(SectionThreeTitle)`
  font-size: 20px;
  line-height: 24px;
`;

export const PhotosContainer = styled.div`
  position: relative;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    !props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  width: 100%;
  flex: 1;
  @media (max-width: 1235px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;
  }
  @media (max-width: 500px) {
    width: 100%;
  }

  @media (max-width: 400px) {
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
`;

export const TeamPhotoContainer = styled.img`
  width: 416px;
  height: 294px;
  border-radius: 30px;
  align-self: flex-start;

  user-select: none;
  position: relative;
  @media (max-width: 1235px) {
    align-self: center;
  }
  @media (max-width: 900px) {
    width: 216px;
    height: 144px;
  }
`;
export const TeamPhotoContainerAbsolute = styled(TeamPhotoContainer)`
  position: absolute;
  width: 382px;
  height: 433px;
  top: 165px;
  left: 195px;
  @media (max-width: 1235px) {
    position: relative;
    top: unset;
    left: unset;
  }
  @media (max-width: 900px) {
    width: 191px;
    height: 218px;
  }
`;

export const SectionFour = styled(Background)`
  padding-top: 250px;

  @media (max-width: 1235px) {
    padding-top: 120px;
  }
  @media (max-width: 900px) {
    padding-top: 70px;
  }
`;

export const MapPhotoContainer = styled.img`
  width: 1284px;
  height: 237px;
  flex-shrink: 0;
  border-radius: 10px;
  object-fit: cover;
  user-select: none;
  box-shadow: 0px 20px 50px -10px rgba(19, 15, 53, 0.1);
  @media (max-width: 1370px) {
    width: 600px;
  }
  @media (max-width: 1000px) {
    width: 250px;
    height: 550px;
  }
`;

export const TimelineCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50px;
  border: 2px solid #8a4b5e;
  position: absolute;
  left: 20px;
  z-index: 1;
`;

export const TimelineCircleSecond = styled(TimelineCircle)`
  left: 240px;
`;
export const TimelineCircleThird = styled(TimelineCircle)`
  left: 450px;
`;

export const TimelineLine = styled.div`
  width: 180px;
  height: 2px;
  background: linear-gradient(90deg, #8a4b5e 0%, #d5d8e1 60%);
  left: 27px;
  position: absolute;
`;

export const TimelineLineSecond = styled(TimelineLine)`
  left: 247px;
`;

export const TimelineLineThird = styled(TimelineLine)`
  left: 457px;
`;

export const TextOnImageMap = styled.span`
  color: #130f35;
  font-family: "FrankfurterEF-Medium", sans-serif;
  font-size: 18px;
  line-height: 30px; /* 163.934% */
  position: absolute;
  top: 30%;
  bottom: 0;
  left: 20%;
  right: 0;
  @media (max-width: 1000px) {
    top: 10%;
    right: 0;
    left: 25%;
  }
`;

export const TextOnImageLeft = styled.span`
  color: #130f35;
  font-family: "Avenir-Roman";
  font-size: 13px;
  line-height: 21px;
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 20%;
  @media (max-width: 1000px) {
    top: 20%;
    right: 0;
    left: 15%;
  }
`;

export const TextOnImageLeft2 = styled(TextOnImageLeft)`
  top: 60%;
  @media (max-width: 1000px) {
    top: 25%;
    right: 0;
    left: 15%;
  }
`;

export const TextOnImageMapRight = styled.span`
  color: #130f35;
  font-family: "FrankfurterEF-Medium", sans-serif;
  font-size: 18px;
  line-height: 30px;
  position: absolute;
  top: 30%;
  bottom: 0;
  right: 21%;
  @media (max-width: 1000px) {
    top: 70%;
    right: 0;
    left: 25%;
  }
`;

export const InfoDiv = styled.div`
  position: relative;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;

  @media (max-width: 400px) {
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
`;

export const TextOnImageRight = styled.span`
  color: #130f35;
  font-family: "Avenir-Roman";
  font-size: 13px;
  line-height: 21px;
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 15%;
  text-align: start;
  @media (max-width: 1000px) {
    top: 80%;
    right: 0;
    left: 15%;
  }
`;

export const TextOnImageRight2 = styled(TextOnImageRight)`
  top: 60%;
  right: 19.8%;
  @media (max-width: 1000px) {
    top: 85%;
    right: 0;
    left: 20%;
  }
`;

export const MapLine = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d5d8e1;
  width: 2px;
  height: 110px;
  top: 25%;
  right: 50%;

  @media (max-width: 1000px) {
    height: 2px;
    width: 110px;
    top: 50%;
    left: 25%;
  }
`;

export const MailBoxIcon = styled(MailBox)`
  position: absolute;
  top: 32%;
  bottom: 0;
  left: 17.5%;

  @media (max-width: 1370px) {
    left: 15%;
  }
  @media (max-width: 1000px) {
    top: 10.8%;
    right: 0;
    left: 12%;
  }
`;

export const MapPinIcon = styled(MapPin)`
  position: absolute;
  top: 31%;
  bottom: 0;
  right: 30.5%;
  @media (max-width: 1370px) {
    right: 40.5%;
  }
  @media (max-width: 1000px) {
    top: 70.5%;
    right: 0;
    left: 12%;
  }
`;
export const DescriptionContainer = styled.div`
  display: flex;
  transition: all ease-in 0.8s;
  transform: ${(props) =>
    !props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  justify-content: center;
  align-items: center;
  flex: 0% 30% 30%;
  @media (max-width: 400px) {
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
`;

export const DescriptionContainerSectionThree = styled(OurDescription)`
  @media (max-width: 1230px) {
    text-align: center;
  }
`;

export const Lines = styled.div`
  display: none;
  align-items: center;
  margin-left: -20px;
  margin-bottom: 20px;
  @media (max-width: 700px) {
    display: flex;
  }
`;
