import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as SearchIconWhite } from "../../assets/icons/whiteSearch.svg";
import { useTranslation } from "react-i18next";
import {
  IconContainer,
  Search,
  Search2,
  StyledInput,
  StyledInput2,
} from "./Search.styles";
import { selectedLocale } from "../../store/slices/common/slice";
import { useSelector } from "react-redux";

const SearchInput = ({
  value,
  onChange,
  placeholder,
  width,
  style,
  whiteIcon,
  searchStyle2,
  wrapperWidth,
  placeholderColor,
  inputRef,
  disabled,
}) => {
  const { t } = useTranslation();
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";
  return searchStyle2 ? (
    <StyledInput2 style={{ width: wrapperWidth }} $ar={ar}>
      <IconContainer $ar={ar}>
        {whiteIcon ? <SearchIconWhite /> : <SearchIcon />}
      </IconContainer>
      <form autoComplete="off">
        <Search2
          style={{ width: width }}
          ref={inputRef}
          placeholder={placeholder ? placeholder : t("search-anything")}
          value={value}
          onChange={onChange}
          $placeholderColor={placeholderColor}
          disabled={disabled}
        />
      </form>
    </StyledInput2>
  ) : (
    <StyledInput style={{ width: wrapperWidth }} $ar={ar}>
      <IconContainer $ar={ar}>
        {whiteIcon ? <SearchIconWhite /> : <SearchIcon />}
      </IconContainer>
      <form autoComplete="off">
        <Search
          ref={inputRef}
          style={style ? style : { width: width }}
          placeholder={placeholder ? placeholder : t("search-anything")}
          value={value}
          onChange={onChange}
        />
      </form>
    </StyledInput>
  );
};
export default SearchInput;
