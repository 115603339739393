import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Screen,
  ScreenContent,
  MainContainer,
  Title,
  Success,
  Input,
  Button,
  InputContainer,
  StyledTitle,
  Content,
  Text,
  Inputs,
  ButtonContainer,
  StyledButton,
  SubTitle,
  Link,
  HeaderWrapper,
  ErrorDiv,
} from "./CheckoutFinalPage.styles";
import Loader from "../../../components/Loader/Loader";
import SuccessImg from "../../../assets/images/success.png";
import FailedImg from "../../../assets/images/failed.png";
import { toast } from "react-toastify";
import { fetchBusinessUserInfo } from "../../../store/slices/businessUser/asyncThunks";
import Sidebar from "../utils/Sidebar";
import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer";
import marketplaceService from "../../../api/services/marketplace";
import { resetMarketplaceOrderData } from "../../../store/slices/marketplace/marketplaceSlice";
import subscriptionService from "../../../api/services/subscription";
import openToast from "../../../utils/toast";

const CheckoutFinalPage = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const businessUser = useSelector(selectBusinessUserInfo);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const subscriptionId = queryParams.get("subscriptionId");
  const toastId = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const paymentChargeId = searchParams.get("tap_id");
  const bankTransfer = searchParams.get("bank_transfer");

  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(resetMarketplaceOrderData());
    if (state) {
      if (state.order) {
        setOrder(state?.order);
        setCompanyName(state.order.company.name);
        setVatNumber(state.order.company.VAT);
        setCity(state.order.company.city);
        setAddress(state.order.company.address);
        setPostalCode(state.order.company.zipCode);
      } else if (state?.subscriptionCompany) {
        setCompanyName(state.subscriptionCompany.name);
        setVatNumber(state.subscriptionCompany.VAT);
        setCity(state.subscriptionCompany.city);
        setAddress(state.subscriptionCompany.address);
        setPostalCode(state.subscriptionCompany.zipCode);
      }
    }
    if (subscriptionId && !state?.subscriptionCompany) {
      setCompanyName(businessUser.company.name);
      setVatNumber(businessUser.company.VAT);
      setCity(businessUser.company.city);
      setAddress(businessUser.company.address);
      setPostalCode(businessUser.company.zipCode);
    }
    if (paymentChargeId) {
      setLoading(true);
      marketplaceService
        .validatePayment({ paymentChargeId })
        .then(() => {
          setLoading(false);
          setError(false);
        })
        .catch((err) => {
          toast.error(t("Payment validation failed"));
          setError(true);
          setLoading(false);
        });
    }
  }, [
    businessUser.company.VAT,
    businessUser.company.address,
    businessUser.company.city,
    businessUser.company.name,
    businessUser.company.zipCode,
    dispatch,
    paymentChargeId,
    state,
    subscriptionId,
  ]);

  const downloadInvoice = () => {
    if (order) {
      setLoading(true);
      marketplaceService
        .generateInvoiceMarketplaceOrder({
          marketplaceOrderId: order?.id,
          companyName,
          companyVat: vatNumber,
          companyCountry: city,
          companyAddress: address,
          companyPostalCode: postalCode,
        })
        .then((result) => {
          setLoading(false);
          if (result?.data?.url) {
            window.open(result.data.url, "_blank");
          }
        })
        .catch((err) => {
          console.log(err);
          openToast(
            toastId,
            "download-invoice",
            "error",
            t("Download invoice failed")
          );
          setLoading(false);
        });
    }
    if (subscriptionId) {
      setLoading(true);
      subscriptionService
        .generateInvoiceBySubscriptionId({
          subscriptionId: +subscriptionId,
          companyName: companyName,
          companyVat: vatNumber,
          companyCountry: city,
          companyAddress: address,
          companyPostalCode: postalCode,
        })
        .then((result) => {
          setLoading(false);
          if (result?.url) {
            window.open(result.url, "_blank");
          }
        })
        .catch((err) => {
          console.log(err);
          openToast(
            toastId,
            "download-invoice",
            "error",
            t("Download invoice failed")
          );
          setLoading(false);
        });
    }
    if (paymentChargeId) {
      setLoading(true);
      marketplaceService
        .generateInvoice({
          paymentChargeId,
          companyName,
          companyVat: vatNumber,
          companyCountry: city,
          companyAddress: address,
          companyPostalCode: postalCode,
        })
        .then((result) => {
          setLoading(false);
          if (result?.data?.url) {
            window.open(result.data.url, "_blank");
          }
        })
        .catch((err) => {
          console.log(err);
          openToast(
            toastId,
            "download-invoice",
            "error",
            t("Download invoice failed")
          );
          setLoading(false);
        });
    }
  };

  return (
    <>
      {loading && <Loader disableScroll></Loader>}
      <HeaderWrapper>
        <Header
          display
          showLogin
          showSignUp
          showNav
          color
          showCart
          showFavorite
          showUser
        />
      </HeaderWrapper>

      <MainContainer>
        {businessUser.loggedIn && <Sidebar />}

        <Screen $loggedIn={businessUser.loggedIn}>
          <ScreenContent>
            <StyledTitle>
              {!order ? t("checkout") : t("generate-invoice")}
            </StyledTitle>
            <Content>
              {!order ? (
                error ? (
                  <Success src={FailedImg} alt="success-img" />
                ) : (
                  <Success src={SuccessImg} alt="success-img" />
                )
              ) : null}
              {error ? (
                <ErrorDiv>
                  <Title>{t("payment-failed")}</Title>
                  <Text>
                    {t("contact-us-at")}{" "}
                    <Link href={"mailto:hello@ygii.app"}>hello@ygii.app</Link>
                  </Text>
                  {!businessUser.loggedIn && (
                    <SubTitle>
                      {t("check-your-email-and-verify-account")}
                    </SubTitle>
                  )}
                </ErrorDiv>
              ) : (
                <>
                  {bankTransfer ? (
                    <>
                      <Title>{t("our-admin-will-check-your-invoice")}</Title>
                      {!businessUser.loggedIn && (
                        <SubTitle>
                          {t("check-your-email-and-verify-account")}
                        </SubTitle>
                      )}
                    </>
                  ) : (
                    <>
                      {!order ? <Title>{t("payment-completed")}</Title> : null}
                      {!businessUser.loggedIn && (
                        <SubTitle>
                          {t("check-your-email-and-verify-account")}
                        </SubTitle>
                      )}
                      <Inputs>
                        <InputContainer>
                          <SubTitle>{t("company-name")}:</SubTitle>
                          <Input
                            placeholder={t("name-on-invoice")}
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <SubTitle>{t("vat-number")}:</SubTitle>
                          <Input
                            placeholder={t("vat-number")}
                            value={vatNumber}
                            onChange={(e) => setVatNumber(e.target.value)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <SubTitle>{t("city-country")}:</SubTitle>
                          <Input
                            placeholder={t("city-country")}
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <SubTitle>{t("address")}:</SubTitle>
                          <Input
                            placeholder={t("address")}
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <SubTitle>{t("postal-code")}:</SubTitle>
                          <Input
                            placeholder={t("postal-code")}
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                          />
                        </InputContainer>
                      </Inputs>
                    </>
                  )}
                </>
              )}
            </Content>
            <ButtonContainer>
              {!error && (
                <>
                  {!bankTransfer && (
                    <StyledButton
                      onClick={() => {
                        downloadInvoice();
                      }}
                    >
                      {t("download-invoice")}
                    </StyledButton>
                  )}
                </>
              )}
              {!businessUser.loggedIn ? (
                <Button
                  onClick={() => {
                    navigation("/marketplace");
                  }}
                >
                  {t("go-to-marketplace")}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    dispatch(fetchBusinessUserInfo());
                    if (!order && !subscriptionId) {
                      navigation(
                        "/admin-portal/order-history?marketplacePage=1&giftsPage=1"
                      );
                    } else if (
                      businessUser.role === "superAdmin" &&
                      !subscriptionId
                    ) {
                      navigation(
                        `/admin-portal/order-history/marketplace-order-details/${order?.id}`
                      );
                    } else if (
                      businessUser.role === "owner" &&
                      !subscriptionId
                    ) {
                      navigation(
                        "/admin-portal/order-history?marketplacePage=1&giftsPage=1"
                      );
                    } else if (
                      subscriptionId &&
                      !state?.subscriptionCompany &&
                      businessUser.role !== "superAdmin" &&
                      !order
                    ) {
                      navigation(`/admin-portal/business-subscriptions?page=1`);
                    } else if (
                      subscriptionId &&
                      state?.subscriptionCompany &&
                      businessUser.role === "superAdmin" &&
                      !order
                    ) {
                      navigation(
                        `/admin-portal/subscriptions/company-history/${state.subscriptionCompany.id}?page=1`
                      );
                    }
                  }}
                >
                  {order || (subscriptionId && !order)
                    ? t("back")
                    : t("go-to-dashboard")}
                </Button>
              )}
            </ButtonContainer>
          </ScreenContent>
        </Screen>
      </MainContainer>
      {!businessUser.loggedIn && <Footer />}
    </>
  );
};

export default CheckoutFinalPage;
