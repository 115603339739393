import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Text,
  ButtonNext,
  ButtonBack,
  Buttons,
  StyledImage,
  SetPrintArea,
  SetHorizontalLine,
  SetVerticalLine,
  ResizeLogo,
  ImageContainer,
  MoveContainer,
  RowPrintArea,
  Background,
  PhotosContainer,
  Photo,
  PrintAreaColumn,
  Button,
} from "../Steps.styles";

import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import {
  addItemDisplayPhoto,
  addItemPhotos,
  addItemPrintAreas,
  setPrintAreasToStore,
} from "../../../store/slices/marketplace/marketplaceSlice";
import { ReactComponent as ResizeIcon } from "../../../assets/icons/resizeLogo.svg";

const AddItemStepPrintArea = ({ onNext, onBack, photos }) => {
  const { t } = useTranslation();
  const [drag, setDrag] = useState(false);
  const [printAreas, setPrintAreas] = useState({});
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const addItemPhotosArr = useSelector(addItemPhotos);
  const photoIndex = useSelector(addItemDisplayPhoto);
  const dispatch = useDispatch();

  const printAreasStore = useSelector(addItemPrintAreas);

  const itemPhotoRef = useRef(null);
  const [scaleImage, setScaleImage] = useState({ x: 1, y: 1 });
  const [invertedScaleImage, setInvertedScaleImage] = useState({ x: 1, y: 1 });

  useEffect(() => {
    if (itemPhotoRef.current) {
      let scaleImage = { x: 1, y: 1 };
      let invertedScaleImage = { x: 1, y: 1 };

      scaleImage.x =
        itemPhotoRef.current?.naturalWidth / itemPhotoRef.current?.width;
      scaleImage.y =
        itemPhotoRef.current?.naturalHeight / itemPhotoRef.current?.height;

      invertedScaleImage.x =
        itemPhotoRef.current?.width / itemPhotoRef.current?.naturalWidth;
      invertedScaleImage.y =
        itemPhotoRef.current?.height / itemPhotoRef.current?.naturalHeight;

      setScaleImage(scaleImage);
      setInvertedScaleImage(invertedScaleImage);
    }
  }, [selectedPhotoIndex, setScaleImage]);

  useEffect(() => {
    if (!Object.keys(printAreas).length) {
      let printAreas = {};
      if (printAreasStore) {
        for (const [areaKey, areaValue] of Object.entries(printAreasStore)) {
          printAreas[areaKey] = {
            topScaled: areaValue.top,
            leftScaled: areaValue.left,
            widthScaled: areaValue.width,
            heightScaled: areaValue.height,
          };
        }
      }
      console.log(printAreas);
      setPrintAreas(printAreas);
    }
  }, [printAreasStore]);

  useEffect(() => {
    if (printAreasStore) {
      if (!printAreas[selectedPhotoIndex]?.top) {
        let invertedScaleImage = { x: 1, y: 1 };

        if (!printAreasStore[selectedPhotoIndex]?.scaled) {
          invertedScaleImage.x =
            itemPhotoRef.current?.width / itemPhotoRef.current?.naturalWidth;
          invertedScaleImage.y =
            itemPhotoRef.current?.height / itemPhotoRef.current?.naturalHeight;
        }
        const printArea = printAreasStore[selectedPhotoIndex];

        if (printArea) {
          setPrintAreas((prev) => ({
            ...prev,
            [selectedPhotoIndex]: {
              scaled: true,
              top: printArea.top * invertedScaleImage.y,
              left: printArea.left * invertedScaleImage.y,
              width: printArea.width * invertedScaleImage.y,
              height: printArea.height * invertedScaleImage.y,

              topScaled: printArea.top,
              leftScaled: printArea.left,
              widthScaled: printArea.width,
              heightScaled: printArea.height,
            },
          }));
        }
      }
    }
  }, [printAreasStore, selectedPhotoIndex]);

  useEffect(() => {
    if (photoIndex !== null) {
      let imageURL = null;
      if (photoIndex >= addItemPhotosArr.length) {
        imageURL = URL.createObjectURL(
          photos[photoIndex - addItemPhotosArr.length]
        );
      } else {
        imageURL = addItemPhotosArr[photoIndex].url;
      }

      console.log(imageURL);
      setImageSrc(imageURL);
      setSelectedPhotoIndex(photoIndex);
    }
  }, [addItemPhotosArr, photoIndex, photos]);

  const handleDrag = (e, data, index) => {
    setPrintAreas((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        top: data.y,
        left: data.x,
        topScaled: data.y * scaleImage.y,
        leftScaled: data.x * scaleImage.x,
      },
    }));
  };

  const handleResize = (e, data, index) => {
    setPrintAreas((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        width: data.size.width,
        height: data.size.height,

        heightScaled: data.size.height * scaleImage.y,
        widthScaled: data.size.width * scaleImage.x,
      },
    }));
  };

  useEffect(() => {
    if (printAreas[selectedPhotoIndex]?.top) {
      setPrintAreas((prev) => ({
        ...prev,
        [selectedPhotoIndex]: {
          ...prev[selectedPhotoIndex],
          topScaled: prev[selectedPhotoIndex].top * scaleImage.y,
          leftScaled: prev[selectedPhotoIndex].left * scaleImage.x,
          heightScaled: prev[selectedPhotoIndex].height * scaleImage.y,
          widthScaled: prev[selectedPhotoIndex].width * scaleImage.x,
        },
      }));
    }
  }, [scaleImage, selectedPhotoIndex]);

  const handleAddPrintArea = (index) => {
    let scaleImage = { x: 1, y: 1 };
    let invertedScaleImage = { x: 1, y: 1 };

    scaleImage.x =
      itemPhotoRef.current?.naturalWidth / itemPhotoRef.current?.width;
    scaleImage.y =
      itemPhotoRef.current?.naturalHeight / itemPhotoRef.current?.height;

    invertedScaleImage.x =
      itemPhotoRef.current?.width / itemPhotoRef.current?.naturalWidth;
    invertedScaleImage.y =
      itemPhotoRef.current?.height / itemPhotoRef.current?.naturalHeight;

    setScaleImage(scaleImage);
    setInvertedScaleImage(invertedScaleImage);

    setPrintAreas((prev) => ({
      ...prev,
      [index]: {
        top: 150,
        left: 160,
        width: 180,
        height: 200,
        topScaled: 150 * scaleImage.y,
        leftScaled: 160 * scaleImage.x,
        heightScaled: 200 * scaleImage.y,
        widthScaled: 180 * scaleImage.x,
      },
    }));
  };

  const handleRemovePrintArea = (index) => {
    setPrintAreas((prev) => {
      const { [index]: _, ...rest } = prev;
      return rest;
    });
  };

  return (
    <>
      <RowPrintArea $margin>
        <Text>{t("set-print-area")}:</Text>
        <ImageContainer>
          {selectedPhotoIndex !== null && printAreas[selectedPhotoIndex] && (
            <Draggable
              position={{
                x: printAreas[selectedPhotoIndex]?.left || 0,
                y: printAreas[selectedPhotoIndex]?.top || 0,
              }}
              onDrag={(e, data) => handleDrag(e, data, selectedPhotoIndex)}
              bounds="parent"
              onStart={() => setDrag(true)}
              onStop={() => setDrag(false)}
              disabled={drag}
            >
              <MoveContainer>
                <ResizableBox
                  width={printAreas[selectedPhotoIndex]?.width || 180}
                  height={printAreas[selectedPhotoIndex]?.height || 200}
                  onResize={(e, data) =>
                    handleResize(e, data, selectedPhotoIndex)
                  }
                  onResizeStart={(e) => e.stopPropagation()}
                  handle={
                    <ResizeLogo $hover>
                      <ResizeIcon />
                    </ResizeLogo>
                  }
                  //   lockAspectRatio
                >
                  <SetPrintArea>
                    <Background />
                    <SetHorizontalLine />
                    <SetVerticalLine />
                  </SetPrintArea>
                </ResizableBox>
              </MoveContainer>
            </Draggable>
          )}
          <StyledImage src={imageSrc} alt="ItemPhoto" ref={itemPhotoRef} />
        </ImageContainer>
        <PrintAreaColumn>
          <PhotosContainer>
            {addItemPhotosArr.map((photo, index) => {
              const imageURL = photo.url;
              return (
                <Photo
                  key={index}
                  src={imageURL}
                  onClick={() => {
                    setImageSrc(imageURL);
                    setSelectedPhotoIndex(index);
                  }}
                />
              );
            })}
            {photos.map((photo, index) => {
              const imageURL = URL.createObjectURL(photo);
              return (
                <Photo
                  key={index + addItemPhotosArr.length}
                  src={imageURL}
                  onClick={() => {
                    setImageSrc(imageURL);
                    setSelectedPhotoIndex(index + addItemPhotosArr.length);
                  }}
                />
              );
            })}
          </PhotosContainer>
          <Button
            onClick={() => handleAddPrintArea(selectedPhotoIndex)}
            style={{ alignSelf: "center" }}
            disabled={
              selectedPhotoIndex === null || printAreas[selectedPhotoIndex]
            }
          >
            {t("add-print-area")}
          </Button>
          {selectedPhotoIndex !== null && printAreas[selectedPhotoIndex] && (
            <Button
              onClick={() => handleRemovePrintArea(selectedPhotoIndex)}
              style={{
                alignSelf: "center",
                marginTop: "-30px",
              }}
            >
              {t("remove-print-area")}
            </Button>
          )}
        </PrintAreaColumn>
      </RowPrintArea>

      <Buttons>
        <ButtonBack
          onClick={() => {
            onBack();
            dispatch(setPrintAreasToStore(printAreas));
          }}
        >
          {t("back")}
        </ButtonBack>
        <ButtonNext
          onClick={() => {
            dispatch(setPrintAreasToStore(printAreas));
            onNext(printAreas);
          }}
        >
          {t("next")}
        </ButtonNext>
      </Buttons>
    </>
  );
};

export default AddItemStepPrintArea;
