import React, { useEffect, useState } from "react";
import {
  Content,
  Text,
  TextContainer,
  TitleContainer,
  Message,
  ViewMoreText,
  LoaderContainer,
  ViewMoreContainer,
  StyledAHref,
  LastTableItem,
  FirstItem,
  Chevron,
  TableBodyRow,
} from "../../screens/AdminPortal/CouponsPage/CouponsPage.styles";

import { useTranslation } from "react-i18next";

import { ReactComponent as Open } from "../../assets/icons/open.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate, priceFormatter } from "../../utils/utils";
import { getAdminSubscriptionsBankTransfers } from "../../store/slices/subscriptions/asyncThunks";
import {
  selectedBankTransfersLoading,
  selectedBankTransfersData,
} from "../../store/slices/subscriptions/slice";
import AcceptDeclineModal from "../AcceptDeclineModal/AcceptDeclineModal";
import TableItem from "../Table/TableItem";
import Table from "../Table/Table";
import MiniLoader from "../MiniLoader/MiniLoader";

const ViewMoreBankTransfers = ({ onBack, ar }) => {
  const { t } = useTranslation();
  const [subscriptionsId, setSubscriptionsId] = useState("");
  const bankTransfersData = useSelector(selectedBankTransfersData);
  const bankTransfersLoading = useSelector(selectedBankTransfersLoading);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const dispatch = useDispatch();

  const [openModalState, setOpenModalState] = useState(false);
  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    dispatch(
      getAdminSubscriptionsBankTransfers({
        page: pageNumber,
      })
    );
    const query = { page: pageNumber };
    const queryString = new URLSearchParams(query).toString();
    const url = `/admin-portal/subscriptions/bank-transfers?${queryString}`;

    navigate(url);
  };

  useEffect(() => {
    dispatch(getAdminSubscriptionsBankTransfers({ page }));
  }, [dispatch, page]);

  const tableHeaderTransfers = [
    {
      label: `${t("type")}`,
      sortable: true,
      key: "type",
    },
    {
      label: `${t("price-to-pay")}`,
      sortable: true,
      key: "priceToPay",
    },
    {
      label: `${t("period")}`,
      sortable: true,
      key: "period",
    },
    {
      label: `${t("subscription-from")}`,
      sortable: true,
      key: "subFrom",
    },
    {
      label: `${t("subscription-to")}`,
      sortable: true,
      key: "subTo",
    },
    {
      label: `${t("recipient-url")}`,
      sortable: true,
      key: "recipientUrl",
    },
    {
      label: `${t("num-of-users")}`,
      sortable: true,
      key: "numOfUsers",
    },
    {
      label: `${t("company")}`,
      sortable: true,
      key: "company",
    },
    {
      label: `${t("business-user")}`,
      sortable: true,
      key: "businessUser",
    },
    {
      label: `${t("coupon-name")}`,
      sortable: true,
      key: "couponName",
    },
  ];

  return (
    <>
      {!bankTransfersLoading ? (
        <>
          {bankTransfersData?.length <= 0 ? (
            <Content>
              <TextContainer>
                <Message>{t("you-currently-have-no-bank-transfers")}</Message>
              </TextContainer>
            </Content>
          ) : (
            <>
              <TitleContainer>
                <Text>
                  {t("bank-transfers")} ({bankTransfersData?.total})
                </Text>

                <ViewMoreContainer onClick={onBack}>
                  <Chevron $ar={!ar} />
                  <ViewMoreText>{t("back")}</ViewMoreText>
                </ViewMoreContainer>
              </TitleContainer>

              <Table
                marginTop={40}
                headerInformation={tableHeaderTransfers}
                totalCount={bankTransfersData?.total}
                currentPage={+page}
                setPage={handlePageChange}
                perPage={bankTransfersData?.perPage}
                noDataContent={t("no-data")}
                dataLoading={false}
                lastColumnAligned
                centerText
              >
                {bankTransfersData?.bankTransfersForReview?.map((val, key) => {
                  return (
                    <TableBodyRow
                      key={key}
                      onClick={() => {
                        setSubscriptionsId(val.id);

                        setOpenModalState(true);
                      }}
                    >
                      <FirstItem data={t(val.type.toLowerCase())} />

                      <TableItem
                        center
                        data={priceFormatter(val.priceToPay, false, 0) + " SR"}
                      />
                      <TableItem center data={t(val.period)} />
                      <TableItem
                        center
                        data={formatDate(val.subscriptionFrom)}
                      />
                      <TableItem center data={formatDate(val.subscriptionTo)} />
                      <TableItem
                        center
                        data={
                          <StyledAHref
                            href={val.receiptUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Open />
                          </StyledAHref>
                        }
                      />
                      <TableItem data={val.numOfUsers || "/"} center />
                      <TableItem data={val.company || "/"} center />
                      <TableItem data={val.businessUser || "/"} center />
                      <LastTableItem data={val.couponName || "/"} $offClick />
                    </TableBodyRow>
                  );
                })}
              </Table>

              <AcceptDeclineModal
                close={() => setOpenModalState(false)}
                open={openModalState}
                subscriptionsId={subscriptionsId}
              />
            </>
          )}
        </>
      ) : (
        <LoaderContainer>
          <MiniLoader />
        </LoaderContainer>
      )}
    </>
  );
};

export default ViewMoreBankTransfers;
