import { createAsyncThunk } from "@reduxjs/toolkit";
import billingService from "../../../api/services/billing";

export const getBillingsInfo = createAsyncThunk(
  "billing/getBillingsInfo",
  async (requestData) => {
    return await billingService.getBillingsInfo(requestData);
  }
);

export const addToWallet = createAsyncThunk(
  "billing/addToWallet",
  async (requestData) => {
    return await billingService.addToWallet(requestData);
  }
);

export const validatePayment = createAsyncThunk(
  "billing/validatePayment",
  async (requestData) => {
    return await billingService.validatePayment(requestData);
  }
);

export const buyPackage = createAsyncThunk(
  "billing/buyPackage",
  async (requestData) => {
    return await billingService.buyPackage(requestData);
  }
);

export const getBillingsWithBankTransferPayment = createAsyncThunk(
  "billing/getBillingsWithBankTransferPayment",
  async (requestData) => {
    return await billingService.getBillingsWithBankTransferPayment(requestData);
  }
);

export const updateBillingPaymentStatus = createAsyncThunk(
  "billing/updateBillingPaymentStatus",
  async (requestData) => {
    return await billingService.updateBillingPaymentStatus(requestData);
  }
);
