import React, { useState } from "react";
import { MainContainer, Column, SectionTitle } from "../AddItem/AddItem.styles";
import Sidebar from "../../utils/Sidebar";
import { useTranslation } from "react-i18next";
import Steps from "../../../../components/Steps/Steps";
import CategoryStep1 from "./CategoryStep1";
import Header from "../../../../components/Header/Header";
import { HeaderWrapper } from "../MarketplacePage.styles";
import { useSelector } from "react-redux";
import { selectedLocale } from "../../../../store/slices/common/slice";
import { selectBusinessUserInfo } from "../../../../store/slices/businessUser/slice";

const CategoriesPage = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(1);
  const stepName = [t("category")];

  const { company, state } = useSelector(selectBusinessUserInfo);

  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  const locale = useSelector(selectedLocale);
  const upgradePackage = showUpgradeBar && diff > 0 && state;
  const ar = locale === "ar";

  return (
    <>
      <MainContainer>
        <Sidebar />

        <HeaderWrapper>
          <Header showLogin showNav color showUser showCart showFavorite />
        </HeaderWrapper>
        <Column $ar={ar} $upgradePackage={upgradePackage}>
          <SectionTitle>{t("categories.categories")}</SectionTitle>
          <Steps
            steps={stepName}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
          {activeStep === 1 && <CategoryStep1 />}
        </Column>
      </MainContainer>
    </>
  );
};

export default CategoriesPage;
