import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../../../components/Header/Header";
import Footer from "../../../../components/Footer";
import {
  Screen,
  ScreenContent,
  MainContainer,
  Title,
  Success,
  Input,
  Button,
  InputContainer,
  StyledTitle,
  Content,
  Text,
  Inputs,
  ButtonContainer,
  StyledButton,
  SubTitle,
  Link,
} from "./CompletedSignup.styles";

import { selectCreateAccountPending } from "../../../../store/slices/auth/slice";
import Loader from "../../../../components/Loader/Loader";
import SuccessImg from "../../../../assets/images/success.png";
import FailedImg from "../../../../assets/images/failed.png";
import subscriptionService from "../../../../api/services/subscription";
import { toast } from "react-toastify";
import { fetchBusinessUserInfo } from "../../../../store/slices/businessUser/asyncThunks";
import openToast from "../../../../utils/toast";
import { validatePayment } from "../../../../store/slices/subscriptions/asyncThunks";

const SignupFinal = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const createAccountPending = useSelector(selectCreateAccountPending);
  const [loading, setLoading] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const paymentChargeId = searchParams.get("tap_id");
  const bankTransfer = searchParams.get("bank_transfer");
  const toastId = useRef(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (paymentChargeId) {
      setLoading(true);
      dispatch(validatePayment({ paymentChargeId }))
        .unwrap()
        .then(() => {
          setLoading(false);
          setError(false);
        })
        .catch((err) => {
          toast.error(t("Payment validation failed"));
          setError(true);
          setLoading(false);
        });
      // subscriptionService
      //   .validatePayment({ paymentChargeId })
      //   .then(() => {
      //     setLoading(false);
      //     setError(false);
      //   })
      //   .catch((err) => {
      //     toast.error(t("Payment validation failed"));
      //     setError(true);
      //     setLoading(false);
      //   });
    }
  }, []);

  const downloadInvoice = () => {
    if (paymentChargeId) {
      setLoading(true);
      subscriptionService
        .generateInvoice({
          paymentChargeId,
          companyName,
          companyVat: vatNumber,
          companyCountry: city,
          companyAddress: address,
          companyPostalCode: postalCode,
        })
        .then((result) => {
          setLoading(false);
          if (result?.data?.url) {
            window.open(result.data.url, "_blank").focus();
          }
        })
        .catch((err) => {
          openToast(
            toastId,
            "download-invoice",
            "error",
            t("Download invoice failed")
          );
          setLoading(false);
        });
    }
  };

  return (
    <>
      {loading && <Loader disableScroll></Loader>}
      <Header showNav color display />
      <MainContainer>
        <Screen>
          <ScreenContent>
            {!error && <StyledTitle>{t("welcome-to-ygii")}</StyledTitle>}
            <Content>
              {error ? (
                <Success src={FailedImg} alt="success-img" />
              ) : (
                <Success src={SuccessImg} alt="success-img" />
              )}
              {error ? (
                <>
                  <Title>{t("payment-failed")}</Title>
                  <Text>
                    {t("contact-us-at")}
                    <Link href={"mailto:hello@ygii.app"}>hello@ygii.app</Link>
                  </Text>
                  <Button
                    onClick={() => {
                      navigation("/admin-portal/dashboard");
                    }}
                  >
                    {t("go-to-dashboard")}
                  </Button>
                </>
              ) : (
                <>
                  {bankTransfer ? (
                    <Title>{t("our-admin-will-check-your-invoice")}</Title>
                  ) : (
                    <>
                      <Title>{t("payment-completed")}</Title>
                      <Inputs>
                        <InputContainer>
                          <SubTitle>{t("company-name")}:</SubTitle>
                          <Input
                            placeholder={t("name-on-invoice")}
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <SubTitle>{t("vat-number")}:</SubTitle>
                          <Input
                            placeholder={t("vat-number")}
                            value={vatNumber}
                            onChange={(e) => setVatNumber(e.target.value)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <SubTitle>{t("city-country")}:</SubTitle>
                          <Input
                            placeholder={t("city-country")}
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <SubTitle>{t("address")}:</SubTitle>
                          <Input
                            placeholder={t("address")}
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <SubTitle>{t("postal-code")}:</SubTitle>
                          <Input
                            placeholder={t("postal-code")}
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                          />
                        </InputContainer>
                      </Inputs>
                    </>
                  )}
                  <ButtonContainer>
                    {!bankTransfer && (
                      <StyledButton
                        onClick={() => {
                          downloadInvoice();
                        }}
                      >
                        {t("download-invoice")}
                      </StyledButton>
                    )}

                    <Button
                      onClick={() => {
                        dispatch(fetchBusinessUserInfo());
                        navigation("/admin-portal/dashboard");
                      }}
                    >
                      {t("go-to-dashboard")}
                    </Button>
                  </ButtonContainer>
                </>
              )}
            </Content>
          </ScreenContent>
        </Screen>
      </MainContainer>
      {createAccountPending && <Loader />}
      <Footer />
    </>
  );
};

export default SignupFinal;
