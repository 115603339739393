import {
  Circle,
  CircleContainer,
  Container,
  Name,
  Number,
  CircleContainer2,
  Success,
} from "./Steps.styles";
import SuccessImg from "../../assets/images/success.png";

import { useTranslation } from "react-i18next";
const Steps = ({ activeStep, steps }) => {
  const { t } = useTranslation();

  const number = activeStep;
  const title = steps[activeStep - 1];

  return (
    <Container>
      {steps.map((step, index) => {
        return (
          <CircleContainer key={step}>
            <Circle $active={activeStep === index + 1}>
              {activeStep > index + 1 && (
                <Success src={SuccessImg} alt="success-img" />
              )}
              {activeStep <= index + 1 && (
                <Number $active={activeStep === index + 1}>
                  {t(`${index + 1}`)}
                </Number>
              )}
            </Circle>
            <Name $active={activeStep >= index + 1}>{t(step)}</Name>
          </CircleContainer>
        );
      })}
      <CircleContainer2>
        <Circle $active>
          <Number $active>{number}</Number>
        </Circle>
        <Name $active>{t(title)}</Name>
      </CircleContainer2>
    </Container>
  );
};
export default Steps;
