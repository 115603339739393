import React, { useEffect, useRef } from "react";
import {
  ContentContainer,
  Text,
  Categories,
  Category,
  CategoryText,
  Row,
  ButtonNext,
  ButtonBack,
  Buttons,
} from "../Steps.styles";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getCategories } from "../../../store/slices/marketplace/asyncThunks";
import {
  addItemCategoryId,
  addItemState,
  categoriesData,
  resetAddItem,
  selectedMarketplaceItemLoader,
  setCategory,
} from "../../../store/slices/marketplace/marketplaceSlice";
import openToast from "../../../utils/toast";
import AppLoader from "../../AppLoader";

const AddItemStepCategory = ({ onNext }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeCategory = useSelector(addItemCategoryId);
  const categories = useSelector(categoriesData);
  const itemLoading = useSelector(selectedMarketplaceItemLoader);
  const addItem = useSelector(addItemState);
  const toastId = useRef(null);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const handleCategoryClick = (id) => {
    if (activeCategory.includes(id)) {
      dispatch(setCategory(activeCategory.filter((c) => c !== id)));
    } else {
      dispatch(setCategory([...activeCategory, id]));
    }
  };

  return (
    <>
      <ContentContainer>
        <Row>
          <Text>{t("select-category")}:</Text>
          <Categories>
            {!itemLoading && (
              <>
                {categories?.categories?.map((category) => (
                  <Category
                    key={category.id}
                    $active={addItem?.categoryId?.includes(category.id)}
                    onClick={() => {
                      handleCategoryClick(category.id);
                    }}
                  >
                    <CategoryText
                      $active={addItem?.categoryId?.includes(category.id)}
                    >
                      {category.name}
                    </CategoryText>
                  </Category>
                ))}
              </>
            )}
            {itemLoading && <AppLoader size={20} />}
          </Categories>
        </Row>
      </ContentContainer>
      {itemLoading === false && (
        <Buttons>
          <ButtonBack
            onClick={() => {
              navigate("/admin-portal/marketplace");
              dispatch(resetAddItem());
            }}
          >
            {t("back")}
          </ButtonBack>
          <ButtonNext
            onClick={
              !!activeCategory && activeCategory.length
                ? onNext
                : () => {
                    openToast(
                      toastId,
                      "select-category",
                      "warning",
                      t("please-select-a-category")
                    );
                  }
            }
          >
            {t("next")}
          </ButtonNext>
        </Buttons>
      )}
    </>
  );
};

export default AddItemStepCategory;
