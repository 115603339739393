import React, { useEffect, useRef, useState } from "react";

import {
  ButtonContainer,
  Container,
  ImageContainer,
  Input,
  ProfilePicEmpty,
  TextLight,
  ButtonConfirm,
  ButtonPlain,
  Row,
  ButtonVerify,
  VerifyEmailContainer,
} from "./TabGeneral.styles";
import { useTranslation } from "react-i18next";
import { uploadLogo } from "../../../store/slices/businessUser/slice";
import { ReactComponent as Edit } from "../../../assets/icons/editIcon.svg";
import { ReactComponent as LogoGift } from "../../../assets/icons/gift-empty.svg";
import { ReactComponent as Loading } from "../../../assets/icons/loader-white.svg";
import { useDispatch } from "react-redux";
import { sendVerificationEmailAgain } from "../../../store/slices/auth/asyncThunks";
import { useSelector } from "react-redux";
import { selectSendVerificationEmailAgainPending } from "../../../store/slices/auth/slice";
import { toast } from "react-toastify";

const TabGeneral = ({
  setIsEditable,
  isEditable,
  fullName,
  setFullName,
  businessUser,
  company,
  name,
  setName,
  companyLogo,
  handleImagePreview,
  saveLoading,
  handleEdit,
  editProfile,
  previewImage,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const imageInput = useRef();
  const emailVerificationLoading = useSelector(
    selectSendVerificationEmailAgainPending
  );
  const [timeRemaining, setTimeRemaining] = useState(-1);

  const onAddPhotoClick = () => {
    imageInput.current.click();
  };

  const resendCode = async () => {
    try {
      setTimeRemaining(59);
      dispatch(sendVerificationEmailAgain({ email: businessUser.email }))
        .unwrap()
        .then(() => {
          toast.success(t("email-successfully-sent"));
        })
        .catch((err) => {
          toast.error(t(err.message));
        });
    } catch (err) {
      setTimeRemaining(-1);
      toast.error(err);
    }
  };

  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setTimeout(() => {
        setTimeRemaining(timeRemaining - 1);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [timeRemaining]);

  return (
    <Container>
      {!businessUser.verified && (
        <VerifyEmailContainer>
          <TextLight
            style={{
              color: "red",
            }}
          >
            *{t("your-account-requires-verification")}
          </TextLight>

          <Row>
            <ButtonVerify
              disabled={timeRemaining > 0}
              onClick={resendCode}
              $color={timeRemaining > 0}
            >
              {!emailVerificationLoading ? (
                <>{t("resend-email")}</>
              ) : (
                <Loading height={20} />
              )}
            </ButtonVerify>
            {timeRemaining > 0 && <TextLight>00:{timeRemaining}</TextLight>}
          </Row>
        </VerifyEmailContainer>
      )}
      <TextLight>{t("full-name")}</TextLight>
      {isEditable ? (
        <Input
          id="fullName"
          name="fullName"
          placeholder={t("full-name")}
          onChange={(e) => setFullName(e.target.value)}
          autoCapitalize="none"
          value={fullName}
        />
      ) : (
        <Input
          value={businessUser.fullName}
          onClick={(e) => e.preventDefault()}
          style={{ cursor: "default" }}
          readOnly
        />
      )}
      <TextLight>{t("company-name")}</TextLight>
      {isEditable ? (
        <>
          {businessUser.role !== "owner" ? (
            <Input
              value={company.name}
              onClick={(e) => e.preventDefault()}
              style={{ cursor: "default" }}
              readOnly
            />
          ) : (
            <Input
              id="name"
              name="name"
              placeholder={t("name")}
              onChange={(e) => setName(e.target.value)}
              autoCapitalize="none"
              value={name}
            />
          )}
        </>
      ) : (
        <Input
          value={company.name}
          onClick={(e) => e.preventDefault()}
          style={{ cursor: "default" }}
          readOnly
        />
      )}
      <TextLight>{t("company-logo")}</TextLight>
      {isEditable &&
      (businessUser.role === "owner" || businessUser.role === "superAdmin") ? (
        <ImageContainer onClick={onAddPhotoClick}>
          {companyLogo ? (
            <>
              <img
                style={{
                  position: "relative",
                }}
                src={companyLogo}
                alt="profilePicture"
                className="pic"
              />
              <Edit
                fill="#8A4B5E"
                height={30}
                style={{ position: "absolute", top: -10, right: -35 }}
              />
            </>
          ) : (
            <>
              <ProfilePicEmpty
                style={{ border: "1px solid #545484", marginTop: 20 }}
              >
                <div>
                  <LogoGift height={150} />
                </div>
              </ProfilePicEmpty>
              <Edit
                fill="#8A4B5E"
                height={30}
                style={{ position: "absolute", top: 0, right: -20 }}
              />
            </>
          )}
        </ImageContainer>
      ) : companyLogo ? (
        <ImageContainer style={{ cursor: "default" }}>
          <img src={companyLogo} alt="profilePicture" className="pic" />
        </ImageContainer>
      ) : (
        <>
          <ProfilePicEmpty
            style={{ border: "1px solid #545484", marginTop: 40 }}
          >
            <div>
              <LogoGift height={100} />
            </div>
          </ProfilePicEmpty>
        </>
      )}

      <input
        style={{ display: "none" }}
        ref={imageInput}
        type="file"
        onChange={handleImagePreview}
        accept=".jpg, .png, .jpeg"
        disabled={!isEditable}
      />
      <ButtonContainer>
        {isEditable ? (
          <>
            <ButtonConfirm
              saveloading={"false"}
              onClick={() => {
                editProfile();
                if (previewImage !== null) {
                  dispatch(uploadLogo(previewImage));
                }
              }}
            >
              {!saveLoading ? <>{t("save")}</> : <Loading height={30} />}
            </ButtonConfirm>

            <ButtonConfirm
              onClick={() => {
                setIsEditable(false);
              }}
              $cancel
            >
              {t("cancel")}
            </ButtonConfirm>
          </>
        ) : (
          <ButtonPlain
            saveloading={saveLoading + ""}
            disabled={saveLoading}
            onClick={handleEdit}
          >
            {!saveLoading ? <>{t("edit")}</> : <Loading height={26} />}
          </ButtonPlain>
        )}
      </ButtonContainer>
    </Container>
  );
};

export default TabGeneral;
