import React, { useEffect, useState } from "react";
import {
  ModalBackground,
  ModalContainer,
  ModalContent,
  ModalContentFade,
} from "./ModalTemplate.styles.js";

const Modal = ({
  isOpen,
  children,
  width,
  maxWidth,
  height,
  hideScroll,
  fade,
  padding,
}) => {
  const [isVisible, setIsVisible] = useState(isOpen);

  useEffect(() => {
    let timer;
    if (isOpen) {
      setIsVisible(true);
    } else {
      timer = setTimeout(() => setIsVisible(false), 250);
    }

    return () => clearTimeout(timer);
  }, [isOpen]);

  useEffect(() => {
    if (isVisible) {
      document.documentElement.style.overflowY = "hidden";
      document.documentElement.style.paddingLeft = 0;
      !hideScroll && window.scrollTo(0, 0);
    } else {
      document.documentElement.style.overflowY = "auto";
    }
  }, [isVisible, hideScroll]);

  return (
    isVisible &&
    (fade ? (
      <ModalBackground $open={isOpen}>
        <ModalContainer $center>
          <ModalContentFade
            $open={isOpen}
            style={{
              height: height,
              width: width,
              padding: padding,
              maxWidth: maxWidth,
            }}
          >
            {children}
          </ModalContentFade>
        </ModalContainer>
      </ModalBackground>
    ) : (
      <ModalBackground $open={isOpen}>
        <ModalContainer>
          <ModalContent
            $open={isOpen}
            style={{ height: height, width: width, maxWidth: maxWidth }}
          >
            {children}
          </ModalContent>
        </ModalContainer>
      </ModalBackground>
    ))
  );
};

export default Modal;
