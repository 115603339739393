import { styled } from "styled-components";
import { ReactComponent as MailIcon } from "../../assets/icons/drawer/mail.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/drawer/profile-active.svg";

export const Error = styled.div`
  color: #dc143c;
  font-size: 13px;
  bottom: -50px;
  display: inline-block;
  position: absolute;
`;
export const MainContainer = styled.div`
  background: #f6f6f9;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 40px;
  gap: 30px;
  @media (max-width: 450px) {
    gap: 15px;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const ScreenContent = styled.div`
  z-index: 1;
  position: relative;
`;

export const Title = styled.span`
  text-transform: capitalize;
  justify-content: center;
  display: flex;
  user-select: none;
  flex-wrap: wrap;
  font-family: "FrankfurterEF-Medium";
  font-size: 48px;
  line-height: 55px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  @media (max-width: 450px) {
    font-size: 3.8rem;
    line-height: 4.8rem;
  }
`;
export const Subtitle = styled.span`
  justify-content: center;
  display: flex;
  color: #8a4b5e;
  user-select: none;
  opacity: 0.7;
  font-family: "Avenir-Heavy";
  font-size: 14px;
  text-transform: uppercase;
`;
export const Input = styled.input`
  border: 1px solid #8c8c8c;
  border-radius: 15px;
  height: 60px;
  user-select: none;
  background: none;
  width: 100%;
  font-weight: 700;
  transition: 0.2s;
  &:active,
  &:hover,
  &:focus {
    border: 1px solid #8a4b5e;
    box-shadow: none;
  }
  padding: 15px 15px 15px 56px;

  color: rgba(69, 69, 132, 1);
  font-family: "Avenir";
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.178px;
  @media (max-width: 450px) {
    font-size: 14px;
    border-radius: 7.5px;
    height: 30px;
  }
`;

export const SubmitButton = styled.button`
  font-family: "FrankfurterEF-Medium";

  letter-spacing: -0.178px;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  user-select: none;
  justify-content: center;
  font-size: 22px;
  font-weight: 500;
  color: white;
  background: linear-gradient(180deg, #a87283 0%, #8a4b5e 100%), #ffffff;
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 18px 35px;
  border: none;
  width: 100%;
  height: 70px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  &:hover {
    background: ${({ disabled }) => (disabled ? null : "#8A4B5E")};
  }

  &:active {
    ${({ disabled }) =>
      disabled
        ? null
        : `
    box-shadow: 0px 2px 8px -5px rgb(128, 128, 128);
    transition: all 0.1s ease-in;
    transform: scale(0.95);
  `};
  }
  @media (max-width: 450px) {
    font-size: 16px;
    border-radius: 7.5px;
    height: 35px;
  }
`;
export const StyledText = styled.span`
  color: rgba(255, 0, 0, 0.75);
  font-family: "Avenir-Medium";
  font-size: 16px;
  letter-spacing: -0.178px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  user-select: none;
  width: 100%;
  padding: 0px 40px;
`;
export const ForgotPwContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 15px;
  justify-content: flex-start;
`;
export const ForgotPw = styled.span`
  z-index: 50;
  cursor: pointer;
  color: #55a1e8;
  font-size: 16px;
  font-family: "Avenir";
  user-select: none;
  text-decoration: underline;
`;

export const TextDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
export const StyledTextDiv = styled(TextDiv)`
  justify-content: flex-start;
  margin-top: 15px;
`;

export const CreateText = styled.span`
  color: #8a4b5e;
  opacity: 0.7;
  font-family: "Avenir";
  user-select: none;
  font-size: 16px;
  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

export const TabTitle = styled.span`
  font-family: "Avenir-Heavy";
  font-size: 14px;
  letter-spacing: -0.41px;
`;

export const TabContainer = styled.div`
  padding: 0px 20px 8px 20px;
  user-select: none;
  border-radius: 2px;
  cursor: pointer;
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 25px;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border: 1;
`;

export const StyledMailIcon = styled(MailIcon)`
  width: 24px;
  height: 24px;
  left: 10px;
  top: 17.5px;
  @media (max-width: 450px) {
    top: 4.5px;
  }
`;

export const OuterContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-height: 520px;
  padding: 30px 0px 60px 0px;
  border: 1px solid #b1b1b1;
  width: 548px;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(1, 0, 1, 0.1);
  border-radius: 30px;

  @media (max-width: 560px) {
    width: 400px;
  }
  @media (max-width: 450px) {
    min-height: 444px;
    width: 90%;
  }
`;

export const StyledUserIcon = styled(UserIcon)`
  width: 24px;
  height: 24px;
  position: absolute;
  margin: 0px 4px;
  top: 17.5px;
  left: 10px;
  @media (max-width: 450px) {
    top: 4.5px;
  }
`;
