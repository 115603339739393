import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactComponent as Unchecked } from "../../assets/icons/radio-check.svg";
import { ReactComponent as Checked } from "../../assets/icons/radio-on.svg";
import { ReactComponent as Dot } from "../../assets/icons/dot.svg";
import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import { ReactComponent as Pdf } from "../../assets/icons/pdf.svg";
import { ReactComponent as Visa } from "../../assets/icons/visa.svg";
import { ReactComponent as MasterCard } from "../../assets/icons/mastercard.svg";
import { GoSellElements } from "@tap-payments/gosell";

import {
  Title,
  TabContainer,
  Tabs,
  Row,
  LastTabContainer,
  Input,
  Group,
  Number,
  SubTitle,
  Column,
  StyledContent,
  StyledColumn,
  InputContainer,
  ButtonCopy,
  Text,
  ContentColumn,
  Red,
  ButtonWhite,
  Button,
  ButtonContainer,
  StyledButton,
  Relative,
  Scale,
  CardRow,
} from "./PaymentsComponent.styles";
import { handleCopyClick, priceFormatter } from "../../utils/utils";
import { getPaymentMethod } from "../../store/slices/payment/asyncThunks";
import { selectedPaymentMethodData } from "../../store/slices/payment/paymentSlice";
import { selectBusinessUserInfo } from "../../store/slices/businessUser/slice";
import {
  selectCreateOrder,
  setCreateOrderNotes,
} from "../../store/slices/marketplace/marketplaceSlice";
import openToast from "../../utils/toast";
import Loader from "../Loader/Loader";

const PaymentsComponent = ({
  onBack,
  onComplete,
  width,
  hide,
  size,
  setLoading,
  loading,
  priceToPay,
  hideButtons,
  createOrderButton,
  hideAdminCreateOrder,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const paymentMethodsData = useSelector(selectedPaymentMethodData);
  const [savedPayment, setSavedPayment] = useState(false);
  const [creditCard, setCreditCard] = useState(false);
  const [bankTransfer, setBankTransfer] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const bankIbanRef = useRef(null);
  const bankHolderRef = useRef(null);
  const fileInputRef = useRef(null);
  const [errorTimeout, setErrorTimeout] = useState(null);
  const businessUser = useSelector(selectBusinessUserInfo);
  const [receipt, setReceipt] = useState(null);
  const createOrderData = useSelector(selectCreateOrder);
  const notes = createOrderData.notes;
  const toastId = useRef(null);

  useEffect(() => {
    if (businessUser.loggedIn) {
      dispatch(getPaymentMethod());
    }
  }, [businessUser, dispatch]);

  useEffect(() => {
    setLoading(true);
    if (paymentMethodsData && paymentMethodsData.length > 0) {
      setSelectedCardId(paymentMethodsData[0].id);
      setSavedPayment(true);
      setCreditCard(false);
    } else {
      setCreditCard(true);
      setSavedPayment(false);
    }
    setLoading(false);
  }, [dispatch, paymentMethodsData.length]);

  function callbackFunc(response) {
    clearTimeout(errorTimeout);
    onComplete({ tokenId: response.id });
  }

  const goToNextStep = () => {
    if (creditCard) {
      setLoading(true);
      GoSellElements.submit();
      setErrorTimeout(
        setTimeout(() => {
          setLoading(false);
          openToast(toastId, "payment", "error", t("invalid-credit-card"));
        }, 10000)
      );
    } else if (bankTransfer) {
      if (receipt) {
        setLoading(true);
        onComplete({ receipt });
      } else if (!receipt) {
        openToast(toastId, "payment", "error", t("please-upload-pdf"));
      }
    } else if (savedPayment) {
      setLoading(true);
      onComplete({ paymentMethodId: selectedCardId }, null);
    }
  };

  const createOrderWithoutPayment = () => {
    setLoading(true);
    onComplete({ createOrderWithoutPayment: true });
  };

  const handleSavedPayment = () => {
    setSavedPayment(!savedPayment);
    setCreditCard(false);
    setBankTransfer(false);
  };

  const handleCreditCard = () => {
    setCreditCard(!creditCard);
    setSavedPayment(false);
    setBankTransfer(false);
  };

  const handleBankTransfer = () => {
    setBankTransfer(!bankTransfer);
    setCreditCard(false);
    setSavedPayment(false);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setReceipt(selectedFile);
      toast.success(t("File uploaded successfully"));
    } else {
      setReceipt(null);
      openToast(
        toastId,
        "select-receipt-file",
        "error",
        t("Please select a receipt file")
      );
    }
  };

  return (
    <Tabs $width={width} style={{ width: size }}>
      <p id="msg" style={{ display: "none" }}></p>
      {!hide && (
        <TabContainer
          style={{
            opacity: paymentMethodsData.length === 0 ? 0.5 : 1,
            cursor: paymentMethodsData.length === 0 ? "default" : "pointer",
          }}
        >
          <Row
            onClick={
              paymentMethodsData.length === 0 ? () => {} : handleSavedPayment
            }
          >
            {savedPayment ? <Checked /> : <Unchecked />}
            <Title>{t("saved-payment-method")}</Title>
          </Row>
          {savedPayment && (
            <>
              {paymentMethodsData.map((payment) => (
                <CardRow
                  key={payment.id}
                  onClick={() => {
                    setSelectedCardId(payment.id);
                  }}
                >
                  {selectedCardId === payment.id ? (
                    <Checked width={20} height={20} />
                  ) : (
                    <Unchecked width={20} height={20} />
                  )}

                  {payment.type === "VISA" ? <Visa /> : <MasterCard />}
                  <Group>
                    <Dot />
                    <Dot />
                    <Dot />
                  </Group>
                  <Group>
                    <Dot />
                    <Dot />
                    <Dot />
                  </Group>
                  <Group>
                    <Dot />
                    <Dot />
                    <Dot />
                  </Group>
                  <Number>{payment.lastFour}</Number>
                </CardRow>
              ))}
            </>
          )}
        </TabContainer>
      )}
      <TabContainer $hide={hide}>
        {hide ? (
          <Row>
            <Checked />
            <Title>{t("credit-card")}</Title>
          </Row>
        ) : (
          <Row onClick={handleCreditCard}>
            {creditCard ? <Checked /> : <Unchecked />}
            <Title>{t("credit-card")}</Title>
          </Row>
        )}

        {creditCard && (
          <>
            <Scale $hide={hide}>
              <GoSellElements
                gateway={{
                  publicKey: "pk_live_LrXvcp9MIT3tn1Z6QkzVxFYC",
                  language: "en",
                  supportedCurrencies: "all",
                  supportedPaymentMethods: "all",
                  saveCardOption: true,
                  customerCards: true,
                  notifications: "msg",
                  callback: callbackFunc,
                  labels: {
                    cardNumber: "Card Number",
                    expirationDate: "MM/YY",
                    cvv: "CVV",
                    cardHolder: "Name on Card",
                    actionButton: "Pay",
                  },
                  style: {
                    base: {
                      color: "#535353",
                      lineHeight: "18px",
                      fontFamily: "sans-serif",
                      fontSmoothing: "antialiased",
                      fontSize: "16px",
                      "::placeholder": {
                        color: "rgba(0, 0, 0, 0.26)",
                        fontSize: "15px",
                      },
                    },
                    invalid: {
                      color: "red",
                      iconColor: "#fa755a ",
                    },
                  },
                }}
                transaction={{
                  mode: "charge",
                }}
              />
            </Scale>
          </>
        )}
      </TabContainer>
      {!hide && (
        <LastTabContainer>
          <Row onClick={handleBankTransfer}>
            {bankTransfer ? <Checked /> : <Unchecked />}
            <Title>{t("bank-transfer")}</Title>
          </Row>
          {bankTransfer && (
            <ContentColumn>
              <StyledContent>
                <StyledColumn>
                  <Column>
                    <SubTitle>{t("bank-name")}</SubTitle>
                    <Input
                      placeholder="Saudi National Bank"
                      disabled
                      value={"Saudi National Bank"}
                    />
                  </Column>
                  <Column>
                    <SubTitle>{t("bank-iban")}</SubTitle>

                    <InputContainer>
                      <Input
                        ref={bankIbanRef}
                        placeholder="SA3610000005500000423906"
                        value="SA3610000005500000423906"
                        disabled
                      />
                      <ButtonCopy
                        onClick={() => handleCopyClick(bankIbanRef.current, t)}
                      >
                        <Copy />
                        {t("copy")}
                      </ButtonCopy>
                    </InputContainer>
                  </Column>
                </StyledColumn>
                <StyledColumn>
                  <Column>
                    <SubTitle>{t("total-amount")}</SubTitle>
                    <Input
                      value={`${priceFormatter(+priceToPay)} SR`}
                      disabled
                    />
                  </Column>
                  <Column>
                    <SubTitle>{t("bank-holders-name")}</SubTitle>
                    <InputContainer>
                      <Input
                        ref={bankHolderRef}
                        placeholder="YGII APP INC"
                        value="YGII APP INC"
                        disabled
                      />

                      <ButtonCopy
                        onClick={() =>
                          handleCopyClick(bankHolderRef.current, t)
                        }
                      >
                        <Copy />
                        {t("copy")}
                      </ButtonCopy>
                    </InputContainer>
                  </Column>
                </StyledColumn>
              </StyledContent>
              <Text>{t("please-transfer")}</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Column>
                  <SubTitle>
                    {t("receipt-upload")}
                    <Red> * </Red>
                    {receipt && t("file-name")} {receipt && receipt.name}
                  </SubTitle>
                  <Relative>
                    <ButtonWhite>
                      <Pdf />
                      {t("upload")}
                    </ButtonWhite>
                    <input
                      type="file"
                      ref={fileInputRef}
                      accept=".pdf,image/*"
                      style={{
                        opacity: 0,
                        width: 264,
                        borderRadius: 15,
                        top: 0,
                        position: "absolute",
                      }}
                      onChange={handleFileChange}
                    />
                  </Relative>
                </Column>
                <Column>
                  {createOrderButton && (
                    <>
                      <SubTitle>{t("notes-optional")}</SubTitle>
                      <Input
                        style={{ backgroundColor: "#FFF" }}
                        placeholder={t("")}
                        value={notes}
                        onChange={(e) =>
                          dispatch(setCreateOrderNotes(e.target.value))
                        }
                      />
                    </>
                  )}
                </Column>
              </div>
            </ContentColumn>
          )}
        </LastTabContainer>
      )}
      {!hideButtons && (
        <ButtonContainer>
          <StyledButton onClick={onBack}>{t("back")}</StyledButton>
          {hide ? (
            <Button onClick={goToNextStep} $hide={hide}>
              {t("complete")}
            </Button>
          ) : (
            <Button onClick={goToNextStep} $hide={hide}>
              {t("complete-payment")}
            </Button>
          )}

          {createOrderButton &&
            !hideAdminCreateOrder &&
            businessUser.loggedIn &&
            businessUser.role === "superAdmin" && (
              <Button onClick={createOrderWithoutPayment}>
                {t("create-order")}
              </Button>
            )}
          {loading && <Loader />}
        </ButtonContainer>
      )}
    </Tabs>
  );
};

export default PaymentsComponent;
