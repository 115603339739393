import {
  editCompanySubscription,
  getAdminSubscriptions,
  getAdminSubscriptionsAll,
  getAdminSubscriptionsBankTransfers,
  getCompanySubscriptionHistory,
  getUserSubscriptions,
  reviewBankTransfer,
} from "./asyncThunks";

export const getAdminSubscriptionsBuilder = (builder) => {
  builder
    .addCase(getAdminSubscriptions.pending, (state) => {
      state.subscriptions.loading = true;
    })
    .addCase(getAdminSubscriptions.fulfilled, (state, action) => {
      state.subscriptions.data = action.payload.data;
      state.subscriptions.loading = false;
    })
    .addCase(getAdminSubscriptions.rejected, (state, action) => {
      state.subscriptions.loading = false;
      state.subscriptions.error = action.error.message;
    });
};
export const getAdminSubscriptionsAllBuilder = (builder) => {
  builder
    .addCase(getAdminSubscriptionsAll.pending, (state) => {
      state.allSubscriptions.loading = true;
    })
    .addCase(getAdminSubscriptionsAll.fulfilled, (state, action) => {
      state.allSubscriptions.data = action.payload;
      state.allSubscriptions.loading = false;
    })
    .addCase(getAdminSubscriptionsAll.rejected, (state, action) => {
      state.allSubscriptions.loading = false;
      state.allSubscriptions.error = action.error.message;
    });
};

export const getAdminSubscriptionsBankTransfersBuilder = (builder) => {
  builder
    .addCase(getAdminSubscriptionsBankTransfers.pending, (state) => {
      state.bankTransfers.loading = true;
    })
    .addCase(getAdminSubscriptionsBankTransfers.fulfilled, (state, action) => {
      state.bankTransfers.data = action.payload;
      state.bankTransfers.loading = false;
    })
    .addCase(getAdminSubscriptionsBankTransfers.rejected, (state, action) => {
      state.bankTransfers.loading = false;
      state.bankTransfers.error = action.error.message;
    });
};

export const reviewBankTransferBuilder = (builder) => {
  builder
    .addCase(reviewBankTransfer.pending, (state) => {
      state.reviewBankTransfer.loading = true;
    })
    .addCase(reviewBankTransfer.fulfilled, (state, action) => {
      const { subscriptionsId } = action.meta.arg;

      state.bankTransfers.data.bankTransfersForReview =
        state.bankTransfers.data.bankTransfersForReview.filter(
          (transfer) => transfer.id !== subscriptionsId
        );

      state.reviewBankTransfer.loading = false;
    })
    .addCase(reviewBankTransfer.rejected, (state, action) => {
      state.reviewBankTransfer.loading = false;
      state.reviewBankTransfer.error = action.error.message;
    });
};

export const editCompanySubscriptionBuilder = (builder) => {
  builder
    .addCase(editCompanySubscription.pending, (state) => {
      state.editCompanySubscription.loading = true;
    })
    .addCase(editCompanySubscription.fulfilled, (state, action) => {
      const { packageExpiration, packageType, companyId, maxNumberOfUsers } =
        action.meta.arg;

      state.allSubscriptions.data.companySubscriptions =
        state.allSubscriptions.data.companySubscriptions.map((sub) => {
          if (companyId === sub.id) {
            return {
              ...sub,
              packageExpiration,
              type: packageType,
              numOfUsers: maxNumberOfUsers,
            };
          } else {
            return sub;
          }
        });
      state.editCompanySubscription.loading = false;
    })
    .addCase(editCompanySubscription.rejected, (state, action) => {
      state.editCompanySubscription.loading = false;
      state.editCompanySubscription.error = action.error.message;
    });
};

export const getUserSubscriptionsBuilder = (builder) => {
  builder
    .addCase(getUserSubscriptions.pending, (state) => {
      state.getUserSubscriptions.loading = true;
    })
    .addCase(getUserSubscriptions.fulfilled, (state, action) => {
      const { subscriptions, maxReached, total, perPage } = action.payload.data;

      state.getUserSubscriptions.subscriptions = subscriptions;
      state.getUserSubscriptions.maxReached = maxReached;
      state.getUserSubscriptions.total = total;
      state.getUserSubscriptions.perPage = perPage;
      state.getUserSubscriptions.loading = false;
    })
    .addCase(getUserSubscriptions.rejected, (state, action) => {
      state.getUserSubscriptions.loading = false;
    });
};

export const getCompanySubscriptionHistoryBuilder = (builder) => {
  builder
    .addCase(getCompanySubscriptionHistory.pending, (state) => {
      state.getCompanySubscriptionHistory.loading = true;
    })
    .addCase(getCompanySubscriptionHistory.fulfilled, (state, action) => {
      state.getCompanySubscriptionHistory.data = action.payload.subscriptions;
      state.getCompanySubscriptionHistory.company = action.payload.company;
      state.getCompanySubscriptionHistory.maxReached =
        action.payload.maxReached;
      state.getCompanySubscriptionHistory.perPage = action.payload.perPage;
      state.getCompanySubscriptionHistory.total = action.payload.total;
      state.getCompanySubscriptionHistory.loading = false;
    })
    .addCase(getCompanySubscriptionHistory.rejected, (state, action) => {
      state.getCompanySubscriptionHistory.loading = false;
    });
};
