import axiosApiClient from "..";

import { handleError } from "../../utils/errors";

const BASE_URL = "coupon";

class CouponServices {
  getCouponsDashboard = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return {
        data,
      };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching coupons.");
    }
  };

  getCoupons = async ({ page, type }) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/all?page=${page}&type=${type}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, maxReached, total, perPage } = response.data;
      return {
        data,
        total,
        perPage,
        maxReached,
      };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching coupons.");
    }
  };

  getCouponById = async (couponId) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${couponId}`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return data;
    } catch (err) {
      throw handleError(err, "Error occurred while fetching coupon by id.");
    }
  };

  createCoupon = async (coupon) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/`, coupon);
      if (response.status !== 201) {
        throw new Error();
      }

      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while creating coupon.");
    }
  };

  removeCoupon = async (couponId) => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/${couponId}`);
      if (response.status !== 200) {
        throw new Error();
      }

      return couponId;
    } catch (err) {
      throw handleError(err, "Error occurred while deleting coupon.");
    }
  };

  editCoupon = async ({ newCoupon, couponId }) => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/${couponId}`, {
        ...newCoupon,
      });
      if (response.status !== 201) {
        throw new Error();
      }
      const { data } = response.data;
      return data;
    } catch (err) {
      throw handleError(err, "Error occurred while editing coupon.");
    }
  };

  isCouponValid = async ({ coupon, product }) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/valid-business`, {
        couponCode: coupon,
        product,
      });
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while fetching coupon.");
    }
  };
}

const couponServices = new CouponServices();

export default couponServices;
