import { createSlice } from "@reduxjs/toolkit";
import { getDashboardDataBuilder } from "./builder";

const initialState = {
  recipientCount: 0,
  marketplaceOrders: [],
  sendGiftOrders: [],
  topRecipients: [],
  topDepartments: [],
  getDashboardDataPending: true,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getDashboardDataBuilder(builder);
  },
});

export const selectDashboardData = (state) => state.dashboard;

export default dashboardSlice.reducer;
