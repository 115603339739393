import React from "react";
import Lottie from "react-lottie";
import { useTranslation } from "react-i18next";
import ModalWhite from "../Modal/ModalWhite";
import {
  ContentContainer,
  BigText,
  SmallText,
  Button,
  TextContainer,
} from "./SuccessModal.styles";
import Success from "../../assets/animations/success.json";

const UserInfoModal = ({ handleButtonPress, modalRef }) => {
  const { t } = useTranslation();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Success,
  };
  return (
    <ModalWhite
      ref={modalRef}
      onBack={() => {
        modalRef.current.hide();
      }}
    >
      <ContentContainer>
        <TextContainer>
          <div style={{ height: 100 }}>
            <Lottie options={defaultOptions} />
          </div>
          <BigText>{t("congratulation")}</BigText>
          <SmallText>{t("your-selection")}</SmallText>
          <Button onClick={handleButtonPress}>{t("done")}</Button>
        </TextContainer>
      </ContentContainer>
    </ModalWhite>
  );
};

export default UserInfoModal;
