import {
  getAllGiftItems,
  getItemsByCategory,
  getCategories,
  getSections,
  getExploreItemsBySection,
  getCategoriesWithSixteenOrMoreItems,
  getItemById,
  getItemsBySection,
  getAllCompanyGifting,
  getCompanyGiftingById,
  rejectCompanyGiftingRecipientOrder,
  acceptCompanyGiftingRecipientOrder,
  getCompanyGiftingItems,
  getCompanyGiftingItemsForMe,
  getUserByPhone,
  createCompanyGiftingRecipientOrder,
  getAllCompanyGiftingAdmin,
  updateRecipientOrderState,
  getAdminDashboardData,
  findRecipientsWishlists,
  updateCompanyGiftingDetailsStatus,
  rejectCompanyGiftingToken,
  setDeliveryAddressCompanyGiftingToken,
  getDeliveryAddressCompanyGiftingToken,
} from "./asyncThunks";

export const giftItemsBuilder = (builder) => {
  builder
    .addCase(getAllGiftItems.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getAllGiftItems.fulfilled, (state, action) => {
      state.items = action.payload;
      state.maxReached = action.payload.length === 0;
      state.loading = false;
    })
    .addCase(getAllGiftItems.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
};
export const getAdminDashboardDataBuilder = (builder) => {
  builder
    .addCase(getAdminDashboardData.pending, (state) => {
      state.adminDashboardData.loading = true;
      state.adminDashboardData.error = null;
    })
    .addCase(getAdminDashboardData.fulfilled, (state, action) => {
      state.adminDashboardData.data = action.payload.data;
      state.adminDashboardData.loading = false;
    })
    .addCase(getAdminDashboardData.rejected, (state, action) => {
      state.adminDashboardData.loading = false;
      state.adminDashboardData.error = action.error.message;
    });
};

export const getExploreItemsBySectionBuilder = (builder) => {
  builder
    .addCase(getExploreItemsBySection.pending, (state, { payload }) => {
      state.exploreItemsInfo.loading = true;
      state.exploreItemsInfo.error = null;
    })
    .addCase(getExploreItemsBySection.fulfilled, (state, action) => {
      const { sectionData } = state.exploreItemsInfo;
      const { items, maxReached, page, sectionId } = action.payload;
      if (page === 1) {
        sectionData[sectionId].data = [];
      }
      items.forEach((item) => {
        if (sectionData[item.sectionId]) {
          sectionData[item.sectionId].data.push(item);
          sectionData[item.sectionId].maxReached = maxReached;
          sectionData[item.sectionId].loading = false;
        }
      });
      state.exploreItemsInfo.maxReached = action.payload.maxReached;
      state.exploreItemsInfo.loading = false;
    })
    .addCase(getExploreItemsBySection.rejected, (state, action) => {
      state.exploreItemsInfo.loading = false;
      state.exploreItemsInfo.error = action.error.message;
    });
};

export const getCategoriesBuilder = (builder) => {
  builder
    .addCase(getCategories.pending, (state) => {
      state.categories.error = null;
      state.categories.loading = true;
    })
    .addCase(getCategories.fulfilled, (state, action) => {
      state.categories.data = action.payload;
      state.categories.loading = false;
    })
    .addCase(getCategories.rejected, (state, action) => {
      state.categories.error = action.error.message;
      state.categories.loading = false;
    });
};

export const getSectionsBuilder = (builder) => {
  builder
    .addCase(getSections.pending, (state) => {
      state.error = null;
    })
    .addCase(getSections.fulfilled, (state, action) => {
      state.sections = action.payload;
    })
    .addCase(getSections.rejected, (state, action) => {
      state.error = action.error.message;
    });
};

export const getCategoriesWithSixteenOrMoreItemsBuilder = (builder) => {
  builder
    .addCase(getCategoriesWithSixteenOrMoreItems.pending, (state) => {
      state.error = null;
    })
    .addCase(getCategoriesWithSixteenOrMoreItems.fulfilled, (state, action) => {
      state.categoriesWithSixteenOrMoreItems = action.payload;
    })
    .addCase(getCategoriesWithSixteenOrMoreItems.rejected, (state, action) => {
      state.error = action.error.message;
    });
};

export const getItemByIdBuilder = (builder) => {
  builder
    .addCase(getItemById.pending, (state) => {
      state.selectedItem.loading = true;
      state.selectedItem.error = null;
    })
    .addCase(getItemById.fulfilled, (state, action) => {
      state.selectedItem.data = action.payload;
      state.selectedItem.loading = false;
    })
    .addCase(getItemById.rejected, (state, action) => {
      state.selectedItem.loading = false;
      state.selectedItem.error = action.error.message;
    });
};
export const getItemsBySectionBuilder = (builder) => {
  builder
    .addCase(getItemsBySection.pending, (state) => {
      state.sectionItemsData.error = null;
      state.sectionItemsData.loading = true;
    })
    .addCase(getItemsBySection.fulfilled, (state, action) => {
      state.sectionItemsData.name = action.payload.name;
      state.sectionItemsData.data = action.payload.items;
      state.sectionItemsData.maxReached = action.payload.maxReached;
      state.sectionItemsData.loading = false;
    })
    .addCase(getItemsBySection.rejected, (state, action) => {
      state.sectionItemsData.loading = false;
      state.sectionItemsData.error = action.error.message;
    });
};

export const getItemsByCategoryBuilder = (builder) => {
  builder
    .addCase(getItemsByCategory.pending, (state) => {
      state.categoryItemsData.loading = true;
    })
    .addCase(getItemsByCategory.fulfilled, (state, action) => {
      state.categoryItemsData.name = action.payload.name;
      state.categoryItemsData.data = action.payload.items;
      state.categoryItemsData.maxReached = action.payload.maxReached;
      state.categoryItemsData.loading = false;
    })
    .addCase(getItemsByCategory.rejected, (state, action) => {
      state.categoryItemsData.error = action.error.message;
      state.categoryItemsData.loading = false;
    });
};

export const getAllCompanyGiftingBuilder = (builder) => {
  builder
    .addCase(getAllCompanyGifting.pending, (state) => {
      state.companyGiftingData.loading = true;
      state.companyGiftingData.error = null;
    })
    .addCase(getAllCompanyGifting.fulfilled, (state, action) => {
      state.companyGiftingData.data = action.payload.data;
      state.companyGiftingData.maxReached = action.payload.maxReached;
      state.companyGiftingData.total = action.payload.total;
      state.companyGiftingData.perPage = action.payload.perPage;
      state.companyGiftingData.loading = false;
    })
    .addCase(getAllCompanyGifting.rejected, (state, action) => {
      state.companyGiftingData.loading = false;
      state.companyGiftingData.error = action.error.message;
    });
};

export const getCompanyGiftingByIdBuilder = (builder) => {
  builder
    .addCase(getCompanyGiftingById.pending, (state) => {
      state.companyGiftById.loading = true;
      state.companyGiftById.error = null;
    })
    .addCase(getCompanyGiftingById.fulfilled, (state, action) => {
      state.companyGiftById.data = action.payload;
      state.companyGiftById.loading = false;
    })
    .addCase(getCompanyGiftingById.rejected, (state, action) => {
      state.companyGiftById.loading = false;
      state.companyGiftById.error = action.error.message;
    });
};

export const acceptCompanyGiftingRecipientOrderBuilder = (builder) => {
  builder
    .addCase(acceptCompanyGiftingRecipientOrder.pending, (state) => {
      state.companyGiftById.approveOrderPending = true;
      state.companyGiftById.approveOrderError = null;
    })
    .addCase(acceptCompanyGiftingRecipientOrder.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.companyGiftById.data.companyGiftingRecipients =
        state.companyGiftById.data.companyGiftingRecipients.map((item) => {
          if (item.id === id) {
            item.status = "approved";
          }
          return item;
        });
      state.companyGiftById.approveOrderPending = false;
    })
    .addCase(acceptCompanyGiftingRecipientOrder.rejected, (state, action) => {
      state.companyGiftById.approveOrderPending = false;
      state.companyGiftById.approveOrderError = action.error.message;
    });
};

export const rejectCompanyGiftingRecipientOrderBuilder = (builder) => {
  builder
    .addCase(rejectCompanyGiftingRecipientOrder.pending, (state) => {
      state.companyGiftById.rejectOrderPending = true;
      state.companyGiftById.rejectOrderError = null;
    })
    .addCase(rejectCompanyGiftingRecipientOrder.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.companyGiftById.data.companyGiftingRecipients =
        state.companyGiftById.data.companyGiftingRecipients.map((item) => {
          if (item.id === id) {
            item.status = "rejected";
          }
          return item;
        });
      state.companyGiftById.rejectOrderPending = false;
    })
    .addCase(rejectCompanyGiftingRecipientOrder.rejected, (state, action) => {
      state.companyGiftById.rejectOrderPending = false;
      state.companyGiftById.rejectOrderError = action.error.message;
    });
};

export const fetchCompanyGettingItemsBuilder = (builder) => {
  builder.addCase(getCompanyGiftingItems.pending, (state) => {
    state.companyGiftingItems.companyGiftingItemsPending = true;
  });
  builder.addCase(getCompanyGiftingItems.fulfilled, (state, action) => {
    state.companyGiftingItems.companyGiftingItemsPending = false;
    state.companyGiftingItems.data = action.payload;
  });
  builder.addCase(getCompanyGiftingItems.rejected, (state, action) => {
    state.companyGiftingItems.companyGiftingItemsPending = false;
    state.companyGiftingItems.companyGiftingItemsError = action.error.message;
  });
};

export const fetchCompanyGettingItemsForMeBuilder = (builder) => {
  builder.addCase(getCompanyGiftingItemsForMe.pending, (state) => {
    state.companyGiftingItems.giftingItemsForMeData.giftingItemsForMeDataPending = true;
  });
  builder.addCase(getCompanyGiftingItemsForMe.fulfilled, (state, action) => {
    state.companyGiftingItems.giftingItemsForMeData.giftingItemsForMeDataPending = false;
    state.companyGiftingItems.giftingItemsForMeData.data = action.payload.data;
    state.companyGiftingItems.giftingItemsForMeData.total =
      action.payload.total;
    state.companyGiftingItems.giftingItemsForMeData.maxReached =
      action.payload.maxReached;
    state.companyGiftingItems.giftingItemsForMeData.perPage =
      action.payload.perPage;
  });
  builder.addCase(getCompanyGiftingItemsForMe.rejected, (state, action) => {
    state.companyGiftingItems.giftingItemsForMeData.giftingItemsForMeDataPending = false;
    state.companyGiftingItems.giftingItemsForMeData.giftingItemsForMeDataError =
      action.error.message;
  });
};

export const getUserByPhoneBuilder = (builder) => {
  builder
    .addCase(getUserByPhone.pending, (state) => {
      state.fetchedUserByPhone.loading = true;
      state.fetchedUserByPhone.error = null;
    })
    .addCase(getUserByPhone.fulfilled, (state, action) => {
      state.fetchedUserByPhone.fetchedUsers = action.payload.fetchedUsers;
      state.fetchedUserByPhone.loading = false;
    })
    .addCase(getUserByPhone.rejected, (state, action) => {
      state.fetchedUserByPhone.loading = false;
      state.fetchedUserByPhone.error = action.error.message;
    });
};

export const createCompanyGiftingRecipientOrderBuilder = (builder) => {
  builder
    .addCase(createCompanyGiftingRecipientOrder.pending, (state) => {
      state.companyGiftingItems.giftingItemsForMeData.createCompanyGiftingRecipientOrderPending = true;
      state.companyGiftingItems.giftingItemsForMeData.createCompanyGiftingRecipientOrderError =
        null;
    })
    .addCase(createCompanyGiftingRecipientOrder.fulfilled, (state, action) => {
      state.companyGiftingItems.giftingItemsForMeData.createCompanyGiftingRecipientOrderPending = false;
      state.companyGiftingItems.giftingItemsForMeData.data =
        state.companyGiftingItems.giftingItemsForMeData.data.map((item) => {
          if (item.id === action.payload.companyGiftingRecipientId) {
            item.status = "approved";
          }
          return item;
        });
    })
    .addCase(createCompanyGiftingRecipientOrder.rejected, (state, action) => {
      state.companyGiftingItems.giftingItemsForMeData.createCompanyGiftingRecipientOrderPending = false;
      state.companyGiftingItems.giftingItemsForMeData.createCompanyGiftingRecipientOrderError =
        action.error.message;
    });
};

export const getAllCompanyGiftingAdminBuilder = (builder) => {
  builder
    .addCase(getAllCompanyGiftingAdmin.pending, (state) => {
      state.companyGiftingAdmin.loading = true;
      state.companyGiftingAdmin.error = null;
    })
    .addCase(getAllCompanyGiftingAdmin.fulfilled, (state, action) => {
      state.companyGiftingAdmin.data = action.payload.data;
      state.companyGiftingAdmin.maxReached = action.payload.maxReached;
      state.companyGiftingAdmin.total = action.payload.total;
      state.companyGiftingAdmin.perPage = action.payload.perPage;
      state.companyGiftingAdmin.loading = false;
    })
    .addCase(getAllCompanyGiftingAdmin.rejected, (state, action) => {
      state.companyGiftingAdmin.loading = false;
      state.companyGiftingAdmin.error = action.error.message;
    });
};

export const updateRecipientOrderStateBuilder = (builder) => {
  builder
    .addCase(updateRecipientOrderState.pending, (state) => {
      state.updateRecipientOrderState.loading = true;
      state.updateRecipientOrderState.error = null;
    })
    .addCase(updateRecipientOrderState.fulfilled, (state, action) => {
      const { companyGiftingRecipientId, orderState } = action.payload;
      state.companyGiftById.data.companyGiftingRecipients.map((recipient) => {
        if (
          recipient?.order?.state &&
          recipient.id === companyGiftingRecipientId
        ) {
          recipient.order.state = orderState;
        }
        return recipient;
      });

      state.updateRecipientOrderState.loading = false;
    })
    .addCase(updateRecipientOrderState.rejected, (state, action) => {
      state.updateRecipientOrderState.loading = false;
      state.updateRecipientOrderState.error = action.error.message;
    });
};

export const findRecipientsWishlistsBuilder = (builder) => {
  builder
    .addCase(findRecipientsWishlists.pending, (state) => {
      state.findRecipientsWishlists.loading = true;
    })
    .addCase(findRecipientsWishlists.fulfilled, (state, { payload }) => {
      const { data } = payload;
      state.findRecipientsWishlists.loading = false;
      state.findRecipientsWishlists.wishlists = data.recipients;
    })
    .addCase(findRecipientsWishlists.rejected, (state) => {
      state.findRecipientsWishlists.loading = false;
    });
};

export const updateCompanyGiftingDetailsStatusBuilder = (builder) => {
  builder
    .addCase(updateCompanyGiftingDetailsStatus.pending, (state) => {
      state.updateGiftingDetailsStatus.loading = true;
    })
    .addCase(updateCompanyGiftingDetailsStatus.fulfilled, (state, action) => {
      state.companyGiftById.data.status = action.payload.status;
      state.updateGiftingDetailsStatus.loading = false;
    })
    .addCase(updateCompanyGiftingDetailsStatus.rejected, (state) => {
      state.updateGiftingDetailsStatus.loading = false;
    });
};

export const rejectCompanyGiftingTokenBuilder = (builder) => {
  builder
    .addCase(rejectCompanyGiftingToken.pending, (state) => {
      state.rejectCompanyGiftingTokenPending = true;
    })
    .addCase(rejectCompanyGiftingToken.fulfilled, (state) => {
      state.rejectCompanyGiftingTokenPending = false;
    })
    .addCase(rejectCompanyGiftingToken.rejected, (state) => {
      state.rejectCompanyGiftingTokenPending = false;
    });
};

export const setDeliveryAddressCompanyGiftingTokenBuilder = (builder) => {
  builder
    .addCase(setDeliveryAddressCompanyGiftingToken.pending, (state) => {
      state.companyGiftingSetDeliveryAddressPending = true;
    })
    .addCase(setDeliveryAddressCompanyGiftingToken.fulfilled, (state) => {
      state.companyGiftingSetDeliveryAddressPending = false;
    })
    .addCase(setDeliveryAddressCompanyGiftingToken.rejected, (state) => {
      state.companyGiftingSetDeliveryAddressPending = false;
    });
};

export const getDeliveryAddressCompanyGiftingTokenBuilder = (builder) => {
  builder
    .addCase(getDeliveryAddressCompanyGiftingToken.pending, (state) => {
      state.getDeliveryAddressCompanyGiftingTokenPending = true;
    })
    .addCase(
      getDeliveryAddressCompanyGiftingToken.fulfilled,
      (state, { payload }) => {
        const { data } = payload;
        state.getDeliveryAddressCompanyGiftingTokenPending = false;
        state.deliveryAddresses = data;
      }
    )
    .addCase(getDeliveryAddressCompanyGiftingToken.rejected, (state) => {
      state.getDeliveryAddressCompanyGiftingTokenPending = false;
    });
};
