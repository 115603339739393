import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MainContainer,
  Column,
  Content,
  Container,
  Button,
  ButtonContainer,
  Name,
  OccasionContainer,
  OccasionTitle,
  GiftCardContainer,
  TextArea,
  LocationContainer,
  ButtonsContainer,
  ButtonBack,
  TextContainer,
  TextNone,
  TextAreaOccasion,
  MapContainer,
  CustomAddressInput,
  ImgLinearGradient,
  ImageWrapper,
  ImgText,
  SwitchWrapper,
  ImagesContainer,
  AddressContainer,
  StyledTextArea,
  AddOnsImg,
  DeliveryDateWrapper,
  HeaderWrapper,
  LastTableItem,
  FirstItem,
  TableBodyRow,
  Label,
  LabelRow,
  BalanceChargeButton,
  BalanceBox,
  ConfirmContainer,
  Details,
  Image,
  ImagesRow,
  PlacedOrderContainer,
  OrderText,
  OrderNumberStyled,
  OrderNumber,
  Title,
} from "./SendGiftsPage.styles";
import Sidebar from "../utils/Sidebar";
import Steps from "../../../components/Steps/Steps";
import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  MAP_LIBRARIES,
  MAP_ZOOM_LEVEL,
  getCurrencySymbol,
  googlePlacesKey,
} from "../../../utils/constants";
import { priceFormatter } from "../../../utils/utils";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BackContainer } from "../MarketplacePage/MarketplacePage.styles";
import { useTranslation } from "react-i18next";
import { getRecipients } from "../../../store/slices/recipients/asyncThunks";
import { ReactComponent as Loader } from "../../../assets/icons/loader-white.svg";
import CakeImage from "../../../assets/images/cake.png";
import FlowersImage from "../../../assets/images/flowers.png";
import ChocolateImage from "../../../assets/images/chocolate.png";
import { ReactComponent as Gift } from "../../../assets/icons/gift.svg";

import {
  addGiftCard,
  addRecipients,
  resetGifts,
  selectCompanyGiftingDetailsLoader,
  selectGiftsCart,
  selectRecipients,
  selectSendGifts,
  setSendGiftData,
  addLocation,
  addDeliveryDate,
  resetData,
  selectBiggestPrice,
  setAddOns,
} from "../../../store/slices/sendGifts/slice";
import {
  selectCompanyInfo,
  setWallet,
} from "../../../store/slices/businessUser/slice";
import { createCompanyGiftingDetails } from "../../../store/slices/sendGifts/asyncThunks";

import {
  recipientsInfo,
  recipientsLoader,
} from "../../../store/slices/recipients/slice";
import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import { format } from "date-fns";

import MiniLoader from "../../../components/MiniLoader/MiniLoader";
import SearchInput from "../../../components/Search/Search";
import Switch from "react-switch";
import {
  selectBusinessRulesVariables,
  selectedLocale,
} from "../../../store/slices/common/slice";
import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";
import Header from "../../../components/Header/Header";
import openToast from "../../../utils/toast";
import TableItem from "../../../components/Table/TableItem";
import Table from "../../../components/Table/Table";
import debounce from "lodash.debounce";
import ChargeWalletModal from "../../../components/ChargeWalletModal/ChargeWalletModal";
import { setChargeWalletAmount } from "../../../store/slices/billing/slice";

const occasions = [
  { name: "Birthday" },
  { name: "Anniversary" },
  { name: "Promotion" },
  { name: "Onboarding" },
  { name: "Farewell" },
];

const deliveryLocation = [
  { name: "Office" },
  {
    name: "Home",
  },
];

const SendGiftsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const businessUser = useSelector(selectBusinessUserInfo);
  const [activeStep, setActiveStep] = useState(1);
  const sendGiftsData = useSelector(selectSendGifts);

  const [budget, setBudget] = useState(sendGiftsData.budget);
  const toastId = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showOrderConfirmedScreen, setShowOrderConfirmedScreen] =
    useState(false);

  const [selectedOccasion, setSelectedOccasion] = useState(
    sendGiftsData?.giftCard?.occasion
  );

  const [textareaValue, setTextareaValue] = useState(
    sendGiftsData?.giftCard?.description
  );

  const [occasionValue, setOccasionValue] = useState(
    sendGiftsData?.giftCard?.occasion === "Other"
      ? sendGiftsData?.giftCard?.occasion
      : ""
  );

  const [selectedLocation, setSelectedLocation] = useState(
    sendGiftsData.packageLocation.type
  );

  const [customLocation, setCustomLocation] = useState(
    sendGiftsData.packageLocation.custom
  );

  const [startDate, setStartDate] = useState(
    sendGiftsData.deliveryDate
      ? new Date(sendGiftsData.deliveryDate)
      : new Date(new Date().setDate(new Date().getDate() + 5))
  );

  const selectRecipientsData = useSelector(selectRecipients);
  const selectGiftsCartData = useSelector(selectGiftsCart);
  const [addFlowers, setAddFlowers] = useState(sendGiftsData.addOns.flowers);
  const [addCake, setAddCake] = useState(sendGiftsData.addOns.cake);
  const [addChocolate, setAddChocolate] = useState(
    sendGiftsData.addOns.chocolate
  );

  const [addressDetails, setAddressDetails] = useState("");

  const selectCompany = useSelector(selectCompanyInfo);
  const [selectedRecipient, setSelectedRecipient] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const { step } = useParams();
  const recipientLoading = useSelector(recipientsLoader);
  const companyGiftingDetailsLoading = useSelector(
    selectCompanyGiftingDetailsLoader
  );

  const searchBoxRef = useRef(null);
  const [mapZoom, setMapZoom] = useState(MAP_ZOOM_LEVEL);
  const mapRef = useRef(null);

  const [loader, setLoader] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { itemPurchaseVAT, cakePrice, flowersPrice, chocolatePrice } =
    useSelector(selectBusinessRulesVariables);
  const biggestPrice = useSelector(selectBiggestPrice);
  const { total, perPage, maxReached, recipients, loading } =
    useSelector(recipientsInfo);
  const [queryData, setQueryData] = useState({
    page: 1,
  });
  const searchInputRef = useRef(null);
  const tableHeader = [
    {
      label: `${t("user")}`,
      sortable: true,
      key: "user",
    },
    {
      label: `${t("first-name")}`,
      sortable: true,
      key: "firstName",
    },
    {
      label: `${t("last-name")}`,
      sortable: true,
      key: "lastName",
    },
    {
      label: `${t("mobile-number")}`,
      sortable: true,
      key: "mobilePhone",
    },
    {
      label: `${t("department")}`,
      sortable: true,
      key: "department",
    },

    {
      label: "",
      sortable: false,
      key: "chevron",
    },
  ];

  useEffect(() => {
    dispatch(getRecipients({ page: 1, search: "" }))
      .unwrap()
      .then(() => {
        setQueryData((prev) => {
          const page = 2;
          return { ...prev, page };
        });
      })
      .catch((error) => {
        openToast(toastId, "get-recipients", "error", t(error.message));
      });
  }, [dispatch]);

  const loadMoreItems = () => {
    if (!maxReached && !loading) {
      dispatch(getRecipients({ page: queryData.page, search: searchTerm }))
        .unwrap()
        .then(() => {
          setQueryData((prev) => {
            const page = queryData.page + 1;
            return { ...prev, page };
          });
        })
        .catch((error) => {
          toast.error(t(error.message));
        });
    }
  };

  const changeHandler = useCallback(
    (text) => {
      setSearchTerm(text);
      dispatch(getRecipients({ page: 1, search: text }))
        .unwrap()
        .then(() => {
          searchInputRef.current.focus();
          setQueryData((prev) => {
            const page = 2;
            return { ...prev, page };
          });
        })
        .catch((error) => {
          toast.error(t(error.message));
        });
    },
    [dispatch, queryData.page]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    [changeHandler]
  );

  const handleChange = (event) => {
    const { value } = event.target;
    debouncedChangeHandler(value);
  };

  useEffect(() => {
    if (step === "1") {
      dispatch(resetGifts());
      dispatch(resetData());
    }
  }, [dispatch, step]);

  useEffect(() => {
    setBudget(biggestPrice);
  }, [biggestPrice]);

  const handleRecipientSelect = (recipient) => {
    if (selectedRecipient && selectedRecipient.id === recipient.id) {
      setSelectedRecipient(null);
    } else {
      dispatch(addRecipients(recipient));
      navigate("/admin-portal/select-gifts");
    }
  };

  const indexOfLastUser = currentPage * perPage;
  const indexOfFirstUser = 0;
  const currentUsers = recipients.slice(indexOfFirstUser, indexOfLastUser);

  // const handleChangeBudget = (event) => {
  //   setBudget(event.target.value);
  // };

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };
  const handleOccasionChange = (event) => {
    setOccasionValue(event.target.value);
  };
  // const handleAmountChange = (event) => {
  //   setAmount(event.target.value);
  // };

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      sessionStorage.setItem("token", token);
    }

    if (token || sessionStorage.getItem("token")) {
      setLoader(true);
    } else {
      setLoader(false);
    }

    if (showOrderConfirmedScreen) {
      sessionStorage.removeItem("sendGifts");
    }
  }, [searchParams, step, showOrderConfirmedScreen]);

  const formValid = () => {
    if (selectRecipientsData.length === 0) {
      openToast(
        toastId,
        "send-gifts",
        "warning",
        t("please-select-a-recipient")
      );
      navigate("/admin-portal/send-gifts/1");
      return false;
    }
    if (selectGiftsCartData.length === 0) {
      openToast(toastId, "send-gifts", "warning", t("please-select-a-gift"));
      navigate("/admin-portal/select-gifts");

      return false;
    }
    if (sendGiftsData.budget / selectRecipientsData.length < 0) {
      openToast(
        toastId,
        "send-gifts",
        "warning",
        t("please-add-a-appropriate-budget")
      );
      navigate("/admin-portal/select-gifts");

      return false;
    }
    if (selectedOccasion === "") {
      openToast(
        toastId,
        "send-gifts",
        "warning",
        t("please-select-an-occasion")
      );
      navigate("/admin-portal/send-gifts/3");

      return false;
    }
    if (selectedLocation === "") {
      openToast(
        toastId,
        "send-gifts",
        "warning",
        t("please-select-a-delivery-location")
      );
      return false;
    }
    return true;
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googlePlacesKey,
    libraries: MAP_LIBRARIES,
  });

  const center =
    customLocation?.lat && customLocation?.lng
      ? {
          lat: customLocation.lat,
          lng: customLocation.lng,
        }
      : {
          lat: DEFAULT_LATITUDE,
          lng: DEFAULT_LONGITUDE,
        };

  const handlePlacesChanged = (map) => {
    const places = searchBoxRef.current?.getPlaces();

    if (places && places.length > 0) {
      const place = places[0];
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const address = place.formatted_address;
      setCustomLocation({ lat, lng, address });
      dispatch(
        addLocation({
          type: "Office",
          custom: { lat, lng, address },
        })
      );

      if (mapRef.current) {
        mapRef.current.setCenter({ lat, lng });
        setMapZoom(14);
      }
    }
  };

  const handleMarkerDragEnd = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results?.length > 0) {
        const address = results[0].formatted_address;
        setCustomLocation({ lat, lng, address });
        dispatch(
          addLocation({
            type: "Office",
            custom: { lat, lng, address },
          })
        );
      }
    });
  };

  const handleMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results?.length > 0) {
        const address = results[0].formatted_address;
        setCustomLocation({ lat, lng, address });
        dispatch(
          addLocation({
            type: "Office",
            custom: { lat, lng, address },
          })
        );
      }
    });
  };

  const onLoad = useCallback(
    function callback(map) {
      mapRef.current = map;
      map.setCenter(center);
      map.setZoom(MAP_ZOOM_LEVEL);
    },
    [center]
  );

  // const handleSelectAll = () => {
  //   setSelectAll((prev) => !prev);

  //   if (!selectAll) {
  //     const updatedSelectedRecipients = [
  //       ...selectedRecipients,
  //       ...recipients.filter(
  //         (recipient) =>
  //           !selectedRecipients.some(
  //             (selectedRecipient) => selectedRecipient.id === recipient.id
  //           )
  //       ),
  //     ];
  //     setSelectedRecipients(updatedSelectedRecipients);
  //   } else {
  //     const updatedSelectedRecipients = selectedRecipients.filter(
  //       (r) => !recipients.map((recipient) => recipient.id).includes(r.id)
  //     );
  //     setSelectedRecipients(updatedSelectedRecipients);
  //   }
  // };

  let priceOfAddOns = 0;

  if (addFlowers === true) {
    priceOfAddOns += +priceFormatter(flowersPrice, 1, itemPurchaseVAT);
  }
  if (addCake === true) {
    priceOfAddOns += +priceFormatter(cakePrice, 1, itemPurchaseVAT);
  }
  if (addChocolate === true) {
    priceOfAddOns += +priceFormatter(chocolatePrice, 1, itemPurchaseVAT);
  }

  const totalOrderCost = priceOfAddOns + +biggestPrice;

  const showUpgradeBar = businessUser.company.packageType === "Free";
  let diff =
    new Date(businessUser.company?.packageExpiration).getTime() -
    new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  const [modal, setModal] = useState(false);

  const [amount, setAmount] = useState("");

  const onChargeWallet = () => {
    setModal(true);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const locale = useSelector(selectedLocale);
  const upgradePackage = showUpgradeBar && diff > 0 && businessUser.state;
  const ar = locale === "ar";

  const saveDataToSession = () => {
    sessionStorage.setItem("sendGifts", JSON.stringify(sendGiftsData));
  };

  useEffect(() => {
    const dataFromStore = JSON.parse(sessionStorage.getItem("sendGifts"));

    if (dataFromStore && +step !== 1) {
      dispatch(setSendGiftData(dataFromStore));
      setOccasionValue(
        sendGiftsData?.giftCard?.occasion === "Other"
          ? dataFromStore?.giftCard?.occasion
          : ""
      );
      setAddFlowers(dataFromStore.addOns.flowers);
      setAddCake(dataFromStore.addOns.cake);
      setAddChocolate(dataFromStore.addOns.chocolate);
      setSelectedOccasion(dataFromStore.giftCard.occasion);
      setTextareaValue(dataFromStore.giftCard.description);
      setSelectedLocation(dataFromStore.packageLocation.type);
      setCustomLocation(dataFromStore.packageLocation.custom);
      setStartDate(
        dataFromStore.deliveryDate
          ? new Date(dataFromStore.deliveryDate)
          : new Date(new Date().setDate(new Date().getDate() + 5))
      );
    }

    if (+step === 1) {
      sessionStorage.removeItem("sendGifts");
    }
  }, [dispatch, step]);

  return (
    <MainContainer>
      <HeaderWrapper>
        <Header
          showLogin
          showSignUp
          showNav
          color
          showUser
          showCart
          showFavorite
        />
      </HeaderWrapper>
      <Sidebar />

      <Column $upgradePackage={upgradePackage} $ar={ar}>
        <Content>
          <Steps
            activeStep={+step}
            setActiveStep={setActiveStep}
            steps={[
              "recipients",
              "select-gifts",
              "add-ons",
              "delivery",
              "confirmation",
            ]}
          />
          <Container>
            {+step === 1 && (
              <SearchInput
                inputRef={searchInputRef}
                placeholder={t("search-by-name-or-email")}
                value={searchTerm}
                onChange={(e) => {
                  handleChange(e);
                  setSearchTerm(e.target.value);
                }}
              />
            )}
            {+step === 1 &&
              (!recipientLoading ? (
                recipients?.length === 0 && !searchTerm ? (
                  <>
                    <TextContainer>
                      <TextNone>{t("no-recipients")}</TextNone>
                    </TextContainer>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        $ar={ar}
                        style={{ width: 200 }}
                        onClick={() => {
                          navigate("/admin-portal/recipients?page=1");
                        }}
                      >
                        {t("add-recipients")}
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <Table
                      infinityScroll
                      marginTop={20}
                      headerInformation={tableHeader}
                      totalCount={total}
                      currentPage={+currentPage}
                      setPage={() => {}}
                      perPage={perPage}
                      noDataContent={t("no-data")}
                      dataLoading={false}
                      lastColumnAligned
                      maxReached={maxReached}
                      dataLength={recipients.length}
                      loadMoreItems={loadMoreItems}
                    >
                      {recipients.map((recipient, key) => {
                        const isSelected =
                          selectedRecipient?.id === recipient.id;

                        return (
                          <TableBodyRow
                            key={recipient?.id}
                            $active={isSelected}
                            onClick={() => {
                              handleRecipientSelect(recipient);
                            }}
                          >
                            <FirstItem
                              data={
                                recipient.email && recipient.email.length > 20
                                  ? recipient.email.slice(0, 18) + "..."
                                  : recipient.email || "/"
                              }
                            />
                            <TableItem data={recipient.firstName || "/"} />
                            <TableItem data={recipient.lastName || "/"} />
                            <TableItem data={recipient.mobileNumber || "/"} />
                            <TableItem data={recipient.department || "/"} />

                            <LastTableItem
                              data={
                                <label className="checkbox-container">
                                  <input
                                    type="checkbox"
                                    checked={isSelected}
                                    onChange={() => {
                                      handleRecipientSelect(recipient);
                                    }}
                                  />
                                  <span className="checkbox-custom"></span>
                                </label>
                              }
                            />
                          </TableBodyRow>
                        );
                      })}
                    </Table>
                  </>
                )
              ) : (
                <MiniLoader />
              ))}

            {+step === 3 && (
              <>
                <OccasionContainer>
                  <OccasionTitle>{t("occasion")}: </OccasionTitle>
                  {occasions.map((item, index) => (
                    <div key={index} style={{ gap: 10, display: "flex" }}>
                      <label className="checkbox-container">
                        <input
                          type="radio"
                          name="selectedOccasion"
                          value={item.name}
                          checked={selectedOccasion === item.name}
                          onChange={(e) => {
                            setSelectedOccasion(e.target.value);
                          }}
                        />
                        <span className="checkbox-custom"></span>
                      </label>
                      <Name>{t(item.name)}</Name>
                    </div>
                  ))}
                </OccasionContainer>
                <OccasionContainer>
                  <OccasionTitle style={{ opacity: 0 }}>
                    {t("occasion")}:
                  </OccasionTitle>
                  <div style={{ gap: 10, display: "flex" }}>
                    <label className="checkbox-container">
                      <input
                        type="radio"
                        name="selectedOccasion"
                        value={"Other"}
                        checked={selectedOccasion === "Other"}
                        onChange={(e) => {
                          setSelectedOccasion(e.target.value);
                        }}
                      />
                      <span className="checkbox-custom"></span>
                    </label>
                    <Name>{t("other")}</Name>
                  </div>
                  <TextAreaOccasion
                    placeholder={t("type-your-occasion")}
                    value={occasionValue}
                    readOnly={selectedOccasion !== "Other"}
                    style={{
                      cursor:
                        selectedOccasion !== "Other" ? "default" : "pointer",
                    }}
                    onChange={handleOccasionChange}
                  />
                </OccasionContainer>
                <OccasionContainer
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginTop: 16,
                  }}
                >
                  <OccasionTitle>{t("add-ons")}:</OccasionTitle>
                  <ImagesContainer>
                    <ImageWrapper>
                      <AddOnsImg src={FlowersImage} alt="flowers" />
                      <ImgLinearGradient>
                        <ImgText $ar={ar}>{t("flowers")}</ImgText>
                        <ImgText $ar={ar}>
                          {" "}
                          {priceFormatter(flowersPrice, 1, itemPurchaseVAT)} SR
                        </ImgText>
                        <SwitchWrapper $ar={ar}>
                          <Switch
                            style={{
                              position: "absolute",
                            }}
                            activeBoxShadow={"0px 0px 2px 3px #8A4B5E"}
                            onColor="#8A4B5E"
                            offColor="#e6e6e6"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onChange={() => {
                              if (!addFlowers) {
                                setAddFlowers(true);
                                dispatch(setAddOns({ flowers: true }));
                              } else {
                                setAddFlowers(false);
                                dispatch(setAddOns({ flowers: false }));
                              }
                            }}
                            checked={addFlowers}
                          />
                        </SwitchWrapper>
                      </ImgLinearGradient>
                    </ImageWrapper>
                    <ImageWrapper>
                      <AddOnsImg src={CakeImage} alt="cake" />
                      <ImgLinearGradient>
                        <ImgText $ar={ar}>{t("cake")}</ImgText>
                        <ImgText $ar={ar}>
                          {priceFormatter(cakePrice, 1, itemPurchaseVAT)} SR
                        </ImgText>
                        <SwitchWrapper $ar={ar}>
                          <Switch
                            style={{
                              position: "absolute",
                            }}
                            activeBoxShadow={"0px 0px 2px 3px #8A4B5E"}
                            onColor="#8A4B5E"
                            offColor="#e6e6e6"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onChange={() => {
                              if (!addCake) {
                                setAddCake(true);
                                dispatch(setAddOns({ cake: true }));
                              } else {
                                setAddCake(false);
                                dispatch(setAddOns({ cake: false }));
                              }
                            }}
                            checked={addCake}
                          />
                        </SwitchWrapper>
                      </ImgLinearGradient>
                    </ImageWrapper>
                    <ImageWrapper>
                      <AddOnsImg src={ChocolateImage} alt="chocolate" />
                      <ImgLinearGradient>
                        <ImgText $ar={ar}>{t("chocolate")}</ImgText>
                        <ImgText $ar={ar}>
                          {priceFormatter(chocolatePrice, 1, itemPurchaseVAT)}
                          SR
                        </ImgText>
                        <SwitchWrapper $ar={ar}>
                          <Switch
                            style={{
                              position: "absolute",
                            }}
                            activeBoxShadow={"0px 0px 2px 3px #8A4B5E"}
                            onColor="#8A4B5E"
                            offColor="#e6e6e6"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onChange={() => {
                              if (!addChocolate) {
                                setAddChocolate(true);
                                dispatch(setAddOns({ chocolate: true }));
                              } else {
                                setAddChocolate(false);
                                dispatch(setAddOns({ chocolate: false }));
                              }
                            }}
                            checked={addChocolate}
                          />
                        </SwitchWrapper>
                      </ImgLinearGradient>
                    </ImageWrapper>

                    {/* <div
                        style={{
                          background:
                            "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.33) 31.25%, rgba(0, 0, 0, 0.45) 100%)",
                        }}
                      ></div> */}
                  </ImagesContainer>
                </OccasionContainer>

                <GiftCardContainer>
                  <OccasionTitle>{t("gift-card")}: </OccasionTitle>

                  <TextArea
                    placeholder={t("congratulations-on-your")}
                    value={textareaValue}
                    onChange={handleTextareaChange}
                  />
                </GiftCardContainer>
                <ButtonsContainer>
                  <BackContainer
                    onClick={() => {
                      navigate("/admin-portal/select-gifts");
                    }}
                  >
                    <ButtonBack $ar={ar}>{t("back")}</ButtonBack>
                  </BackContainer>
                  <ButtonContainer>
                    <Button
                      $ar={ar}
                      onClick={() => {
                        if (selectedOccasion) {
                          dispatch(
                            addGiftCard({
                              occasion:
                                selectedOccasion !== "Other"
                                  ? selectedOccasion
                                  : occasionValue,
                              description: textareaValue,
                            })
                          );
                          navigate("/admin-portal/send-gifts/4");
                        } else {
                          openToast(
                            toastId,
                            "send-gifts",
                            "warning",
                            t("please-select-an-occasion")
                          );
                          return;
                        }
                      }}
                    >
                      {t("delivery")}
                    </Button>
                  </ButtonContainer>
                </ButtonsContainer>
              </>
            )}
            {+step === 4 && (
              <div
                style={{ gap: 36, display: "flex", flexDirection: "column" }}
              >
                <LocationContainer>
                  <OccasionTitle>{t("delivery-location")}: </OccasionTitle>
                  {deliveryLocation.map((loc, index) => (
                    <div style={{ gap: 10, display: "flex" }} key={index}>
                      <label className="checkbox-container">
                        <input
                          type="radio"
                          name="selectedLocation"
                          value={loc.name}
                          checked={selectedLocation === loc.name}
                          onChange={(e) => {
                            setSelectedLocation(e.target.value);

                            if (selectedLocation === "Office") {
                              setAddressDetails("");
                              setCustomLocation("");
                            }

                            dispatch(
                              addLocation({
                                type: e.target.value,
                                custom: customLocation,
                              })
                            );
                          }}
                        />
                        <span className="checkbox-custom"></span>
                      </label>
                      <Name>{t(loc.name)}</Name>
                    </div>
                  ))}
                </LocationContainer>
                {selectedLocation && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 60,
                    }}
                  >
                    <OccasionTitle>{t("delivery-date")}: </OccasionTitle>
                    <DeliveryDateWrapper>
                      <DatePicker
                        dropdownMode="select"
                        className="data-picker"
                        showIcon={true}
                        selected={startDate}
                        minDate={
                          new Date(new Date().setDate(new Date().getDate() + 5))
                        }
                        onChange={(date) => {
                          setStartDate(date);
                          dispatch(
                            addDeliveryDate({
                              date: date.getTime(),
                            })
                          );
                        }}
                        popperPlacement="bottom"
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: "0, 10",
                          },
                        }}
                      />
                    </DeliveryDateWrapper>
                  </div>
                )}
                {selectedLocation === "Office" && (
                  <AddressContainer>
                    <MapContainer>
                      {isLoaded ? (
                        <>
                          <OccasionTitle>
                            {t("delivery-address")}:
                          </OccasionTitle>
                          <StandaloneSearchBox
                            onLoad={(ref) => {
                              searchBoxRef.current = ref;
                            }}
                            onPlacesChanged={handlePlacesChanged}
                          >
                            <CustomAddressInput
                              type="text"
                              placeholder={t("search-for-location")}
                              value={customLocation?.address}
                              onChange={(e) => {
                                setCustomLocation((prevLocation) => ({
                                  ...prevLocation,
                                  address: e.target.value,
                                }));
                                dispatch(
                                  addLocation({
                                    type: selectedLocation,
                                    custom: {
                                      ...customLocation,
                                      address: e.target.value,
                                    },
                                  })
                                );
                              }}
                            />
                          </StandaloneSearchBox>
                          <GoogleMap
                            mapContainerStyle={{
                              border: 1,
                              width: "100%",
                              maxWidth: "500px",
                              height: "400px",
                            }}
                            onLoad={(map) => {
                              mapRef.current = map;
                              onLoad(map);
                            }}
                            zoom={mapZoom}
                            onClick={handleMapClick}
                          >
                            <Marker
                              position={
                                customLocation?.lat && customLocation?.lng
                                  ? {
                                      lat: customLocation.lat,
                                      lng: customLocation.lng,
                                    }
                                  : {
                                      lat: DEFAULT_LATITUDE,
                                      lng: DEFAULT_LONGITUDE,
                                    }
                              }
                              draggable
                              onDragEnd={handleMarkerDragEnd}
                            />
                          </GoogleMap>
                        </>
                      ) : (
                        <div style={{ marginTop: 50 }}>
                          <MiniLoader />
                        </div>
                      )}
                    </MapContainer>
                    <MapContainer
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <OccasionTitle>{t("address-details")}:</OccasionTitle>
                      <StyledTextArea
                        placeholder={t("building-number-nearby-landmark")}
                        value={addressDetails}
                        onChange={(e) => setAddressDetails(e.target.value)}
                      />
                    </MapContainer>
                  </AddressContainer>
                )}
                <ButtonsContainer>
                  <BackContainer
                    onClick={() => {
                      navigate("/admin-portal/send-gifts/3");
                    }}
                  >
                    <ButtonBack $ar={ar}>{t("back")}</ButtonBack>
                  </BackContainer>
                  <ButtonContainer>
                    <Button
                      $ar={ar}
                      onClick={() => {
                        navigate("/admin-portal/send-gifts/5");
                        saveDataToSession();
                      }}
                    >
                      {t("confirmation")}
                    </Button>
                  </ButtonContainer>
                </ButtonsContainer>
              </div>
            )}
            {+step === 5 && (
              <>
                {!showOrderConfirmedScreen && (
                  <>
                    <ConfirmContainer>
                      <ImagesRow>
                        {sendGiftsData?.giftCart?.length > 0 &&
                          sendGiftsData?.giftCart?.map((gift) => (
                            <Image src={gift.image} key={gift.id} />
                          ))}
                      </ImagesRow>
                      <Label $bold>{t("your-wallet-ballance")}</Label>
                      <LabelRow>
                        <BalanceBox>
                          {selectCompany.walletBalance
                            ? `${priceFormatter(
                                selectCompany?.walletBalance
                              )} ${getCurrencySymbol(
                                selectCompany?.walletCurrency
                              )}`
                            : "0"}
                        </BalanceBox>
                        <BalanceChargeButton onClick={onChargeWallet}>
                          {t("charge-your-wallet")}
                        </BalanceChargeButton>
                      </LabelRow>
                      <Details>
                        <LabelRow>
                          <Label>{t("subtotal")}</Label>
                          <Label $bold>
                            {priceFormatter(sendGiftsData?.biggestPrice)}{" "}
                            {getCurrencySymbol(selectCompany?.walletCurrency)}
                          </Label>
                        </LabelRow>
                        {(addFlowers || addChocolate || addCake) && (
                          <LabelRow>
                            <Label>{t("add-ons")}</Label>
                            <Label $bold>
                              {priceOfAddOns.toFixed(2)}{" "}
                              {getCurrencySymbol(selectCompany?.walletCurrency)}
                            </Label>
                          </LabelRow>
                        )}
                        <LabelRow>
                          <Label>{t("shipping")}</Label>
                          <Label $bold>
                            {sendGiftsData.packageLocation.type}
                          </Label>
                        </LabelRow>
                        <LabelRow>
                          <Label $bold>{t("total")}</Label>
                          <Label $bold>
                            {totalOrderCost.toFixed(2)}{" "}
                            {getCurrencySymbol(selectCompany?.walletCurrency)}
                          </Label>
                        </LabelRow>
                      </Details>
                      {!companyGiftingDetailsLoading ? (
                        <>
                          <BalanceChargeButton
                            style={{ marginTop: 28 }}
                            $center
                            onClick={() => {
                              if (formValid()) {
                                if (
                                  typeof budget === "undefined" ||
                                  budget.toFixed(2) <
                                    +priceFormatter(biggestPrice)
                                ) {
                                  openToast(
                                    toastId,
                                    "select-gift-warning",
                                    "warning",
                                    `${t(
                                      "budget-must-be"
                                    )} ${biggestPrice.toFixed(2)}.`
                                  );
                                  return;
                                } else if (
                                  budget > selectCompany.walletBalance
                                ) {
                                  openToast(
                                    toastId,
                                    "select-gift-warning",
                                    "warning",
                                    t("please-charge-your-wallet-to-continue")
                                  );
                                  return;
                                }
                                dispatch(
                                  createCompanyGiftingDetails({
                                    ...(customLocation?.lat && {
                                      locationLat: customLocation.lat,
                                    }),
                                    ...(customLocation?.lng && {
                                      locationLng: customLocation.lng,
                                    }),
                                    ...(customLocation?.address && {
                                      locationName: customLocation.address,
                                    }),
                                    deliveryLocation: selectedLocation,
                                    ...(addressDetails && {
                                      addressDetails: addressDetails,
                                    }),
                                    dateOfDelivery: format(
                                      startDate,
                                      "yyyy-MM-dd"
                                    ),
                                    items: selectGiftsCartData.map(
                                      (item) => item.id
                                    ),
                                    recipients: selectRecipientsData,
                                    occasion: sendGiftsData?.giftCard?.occasion,
                                    ...(sendGiftsData?.giftCard
                                      ?.description && {
                                      giftCardText:
                                        sendGiftsData?.giftCard?.description,
                                      giftCard: true,
                                    }),
                                    budgetPerRecipient: biggestPrice,
                                    orderMaxBudget: totalOrderCost,
                                    ...(addFlowers === true && {
                                      flowers: addFlowers,
                                    }),
                                    ...(addCake === true && {
                                      cake: addCake,
                                    }),
                                    ...(addChocolate === true && {
                                      chocolate: addChocolate,
                                    }),
                                  })
                                )
                                  .unwrap()
                                  .then((result) => {
                                    dispatch(
                                      setWallet({
                                        newBalanceValue:
                                          selectCompany?.walletBalance -
                                          totalOrderCost,
                                        newReservedFundsValue:
                                          selectCompany?.walletBalance +
                                          totalOrderCost,
                                      })
                                    );
                                    sessionStorage.setItem(
                                      "companyGiftingDetailsId",
                                      result.data.id
                                    );
                                    setShowOrderConfirmedScreen(true);
                                  })
                                  .catch((err) => {
                                    openToast(
                                      toastId,
                                      "edit-company-subscription",
                                      "error",
                                      t(err.message)
                                    );
                                  });
                              }
                            }}
                          >
                            {t("confirm")}
                          </BalanceChargeButton>
                        </>
                      ) : (
                        <BalanceChargeButton
                          $center
                          style={{ cursor: "default", marginTop: 20 }}
                        >
                          <Loader
                            height={24}
                            width={24}
                            style={{ margin: 0 }}
                          />
                        </BalanceChargeButton>
                      )}
                    </ConfirmContainer>
                    <ButtonsContainer>
                      <BackContainer
                        onClick={() => {
                          navigate("/admin-portal/send-gifts/4");
                        }}
                      >
                        <ButtonBack $ar={ar}>{t("back")}</ButtonBack>
                      </BackContainer>
                    </ButtonsContainer>
                  </>
                )}
                <>
                  {showOrderConfirmedScreen && (
                    <PlacedOrderContainer>
                      <Title>
                        {t("your-order-has-been-placed-successfully")}!
                      </Title>
                      <Gift height={250} width={250} />
                      <OrderText>
                        <OrderNumberStyled>{t("order")} #</OrderNumberStyled>
                        <OrderNumber
                          onClick={() => {
                            navigate(
                              `/admin-portal/order-history/gift-order-details/${sessionStorage.getItem(
                                "companyGiftingDetailsId"
                              )}`
                            );
                            setShowOrderConfirmedScreen(false);
                          }}
                        >
                          {sessionStorage.getItem("companyGiftingDetailsId")}
                        </OrderNumber>
                      </OrderText>
                    </PlacedOrderContainer>
                  )}
                </>
              </>
            )}
          </Container>
        </Content>
      </Column>
      <ChargeWalletModal
        onChange={handleAmountChange}
        onClick={() => {
          dispatch(setChargeWalletAmount(amount));
          if (amount) {
            navigate("/admin-portal/wallet/checkout");
          } else {
            toast.error(t("please-add-amount"));
          }
        }}
        value={amount}
        open={modal}
        close={() => setModal(false)}
      />
    </MainContainer>
  );
};

export default SendGiftsPage;
