import styled from "styled-components";

export const MainContainer = styled.div`
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  min-height: 100vh;
  display: flex;
  color: white;
`;

export const BigText = styled.h1`
  font-family: "Avenir-Black";
  font-style: normal;
  font-size: 50px;
  line-height: 68px;
  text-align: center;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  @media (max-width: 600px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

export const FreeTrail = styled.img`
  user-select: none;
  background-color: transparent;
  scale: 0.35;
  @media (max-width: 490px) {
    scale: 0.25;
  }
`;
export const ImgContainer = styled.div`
  position: absolute;
  right: ${(props) => (props.$ar ? "unset" : "-45px")};
  left: ${(props) => (props.$ar ? "-45px" : "unset")};
  top: -30px;
  @media (max-width: 410px) {
    right: ${(props) => (props.$ar ? "unset" : "-65px")};
    left: ${(props) => (props.$ar ? "-65px" : "unset")};
    top: -40px;
  }
`;

export const MediumText = styled.span`
  font-family: "Avenir-Medium";
  font-style: normal;
  font-size: 25px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
`;

export const Container = styled.div`
  justify-content: center;

  width: 1440px;
  flex-direction: row;
  gap: 14px;

  flex-grow: 1;
  flex-direction: column;
  display: flex;
  @media (max-width: 1200px) {
    margin-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;
export const DescBox = styled.div`
  margin-top: 25px;
  padding: 0px 30px 30px 30px;
`;

export const Title = styled.span`
  padding: 20px 30px 0px 30px;
  font-family: "FrankfurterEF-Medium";
  font-size: 40px;
  user-select: none;

  color: #8a4b5e;
  @media (max-width: 1290px) {
    font-size: 30px;
  }
`;

export const Subtitle = styled.span`
  padding: 0px 30px;
  font-family: "Avenir-Medium";
  font-size: 20px;
  user-select: none;
  color: #8a4b5e;
  @media (max-width: 1290px) {
    font-size: 15px;
  }
`;

export const Desc = styled.span`
  font-family: "Avenir-Medium";
  font-size: 14px;
  user-select: none;

  @media (max-width: 1290px) {
    font-size: 12px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const HeaderTitle = styled.h1`
  font-family: "Avenir-Black";
  font-style: normal;
  font-size: 50px;
  line-height: 68px;
  text-align: center;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
`;

export const HeaderSubtitle = styled.h2`
  font-family: "Avenir-Medium";
  font-style: normal;
  font-size: 25px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
`;

export const Row = styled.div`
  flex-direction: row;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 20px;
  @media (max-width: 1160px) {
    flex-wrap: wrap;
  }
`;

export const Row2 = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  @media (max-width: 1200px) {
    margin-bottom: 20px;
  }
`;

export const RightCard = styled.div`
  border: 1px solid rgb(1, 1, 1, 0.15);
  max-width: 405px;
  max-height: 570px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  position: relative;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(1, 0, 1, 0.1);

  transition: all ease-in 0.4s;
  transform: ${(props) =>
    props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;

  @media (max-width: 1160px) {
    max-width: 366px;
  }
  @media (max-width: 500px) {
    max-width: 100%;
    max-height: fit-content;
  }
  @media (max-width: 400px) {
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
`;
export const MiddleCard = styled.div`
  border: 1px solid rgb(1, 1, 1, 0.15);
  max-width: 405px;
  max-height: 570px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  position: relative;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(1, 0, 1, 0.1);

  transition: all ease-in 0.4s;
  transform: translateY(150%);

  opacity: 0;
  @media (max-width: 1160px) {
    max-width: 366px;
  }
  @media (max-width: 500px) {
    max-width: 100%;
    max-height: fit-content;
  }
  @media (max-width: 400px) {
    transform: translateY(0) !important;
    opacity: 1 !important;
  }
`;
export const LeftCard = styled.div`
  border: 1px solid rgb(1, 1, 1, 0.15);
  max-width: 405px;
  max-height: 570px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  position: relative;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(1, 0, 1, 0.1);

  transition: all ease-in 0.4s;
  transform: ${(props) =>
    !props.$ar ? "translateX(-150%)" : "translateX(150%)"};

  opacity: 0;

  @media (max-width: 1160px) {
    max-width: 366px;
  }
  @media (max-width: 500px) {
    max-width: 100%;
    max-height: fit-content;
  }
  @media (max-width: 400px) {
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
`;

export const CardHeaderContainer = styled.div`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Date = styled.span`
  font-family: "Avenir-Black";
  font-style: normal;

  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
  gap: 20px;
  margin-bottom: -20px;
`;

export const Cards = styled.div`
  flex-direction: row;
  display: flex;
  gap: 20px;
`;

export const CardHeader = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;
export const RowPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: -5px;
  align-items: center;
`;

export const PriceContainer = styled.div`
  background-color: #e8dbdf;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  position: relative;
  flex-direction: column;
`;

export const Price = styled.span`
  font-family: "Avenir-Black";
  font-style: normal;
  font-size: 40px;
  line-height: 55px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;

  @media (max-width: 1290px) {
    font-size: 36px;
  }
`;

export const PriceInfo = styled.span`
  font-family: "Avenir-Medium";
  font-size: 14px;
  color: #343456;
  @media (max-width: 1290px) {
    font-size: 12px;
  }
`;

export const Benefits = styled.div`
  transition: all 0.2 ease-out;
`;

export const BenefitsFirst = styled.div`
  margin-top: 20px;
`;

export const ButtonPrice = styled.button`
  color: white;

  user-select: none;
  font-family: "FrankfurterEF-Medium";

  font-style: normal;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 0.5em 2em;
  border: transparent;
  background: ${(props) =>
    props.disabled
      ? "grey"
      : "linear-gradient(180deg, #a87283 0%, #8A4B5E 100%)"};
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border-radius: 30px;
  &:hover {
    transform: ${(props) => (props.disabled ? "scale(1)" : "scale(1.05)")};

    background: ${(props) => (props.disabled ? "grey" : "#8A4B5E")};
  }
  &:active {
    transform: ${(props) =>
      props.disabled ? "unset" : "translate(0em, 0.2em)"};
  }
  transition: transform 0.3s ease;
  @media (max-width: 1290px) {
    padding: 0.35em 1.85em;
    font-size: 16px;
  }
`;

export const ButtonSkip = styled.button`
  color: white;
  font-family: "Avenir-Black";
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 0.5em 1.5em;
  border: transparent;
  background: linear-gradient(180deg, #a87283 0%, #8a4b5e 100%);
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 30px;
  &:hover {
    transform: scale(1.05);
    background: rgb(2, 0, 36);
    background: #8a4b5e 100%;
  }
  &:active {
    transform: translate(0em, 0.2em);
  }
  transition: transform 0.3s ease;
`;

export const ButtonBox = styled.div`
  padding: 15px 0px;
  display: flex;
  width: 100%;
  margin-top: 15px;
  justify-content: center;
`;
export const StyledPriceContainer = styled.div`
  display: flex;
  padding: 0rem 3rem;
  flex-direction: column;
  gap: 2rem;
`;
export const PriceLabel = styled.span`
  font-family: "FrankfurterEF-Medium";
  font-size: 2.6rem;

  letter-spacing: -0.18px;
`;
export const PriceAmount = styled.span`
  font-family: "FrankfurterEF-Medium";
  font-size: 4rem;
  color: #8a4b5e;
  letter-spacing: -0.18px;
`;
export const ButtonBoxLast = styled.div`
  padding: 15px 0px;
  display: flex;
  width: 100%;
  margin-top: 65px;
  justify-content: center;
  margin-bottom: 25px;
`;

export const BenefitsBox = styled.div`
  display: flex;
  padding: 0px 30px;
  gap: 10px;
  flex-direction: row;
`;

export const BenefitsBoxFirst = styled.div`
  display: flex;
  padding: 0px 30px;
  margin-top: 10px;
  gap: 10px;
  flex-direction: row;
`;

export const BenefitsBoxLast = styled.div`
  margin-bottom: 25px;
  display: flex;
  padding: 0px 30px;
  gap: 10px;
  flex-direction: row;
`;

export const BenefitsText = styled.span`
  font-family: "Avenir-Light";
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.178px;

  @media (max-width: 1290px) {
    font-size: 12px;
    line-height: normal;
  }
`;

export const Discount = styled.span`
  font-family: "Avenir-Black";
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.177777px;
  color: #ffffff;
`;

export const InvisibleText = styled.span`
  user-select: none;
  font-family: "Avenir-Black";
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.177777px;
  color: transparent;
`;

export const DiscountContainer = styled.div`
  background: #8a4b5e;
  mix-blend-mode: normal;
  padding: 6px 13px;
`;

export const Invisible = styled.div`
  background: transparent;
  mix-blend-mode: normal;
  padding: 6px 13px;
`;

export const SkipContainer = styled.div`
  display: flex;
  padding-right: 90px;
  position: absolute;
  right: 0;
`;

export const PreviousPrice = styled.span`
  text-decoration: line-through;
  font-family: "Avenir-Black";
  font-style: normal;
  font-size: 20px;
  margin-bottom: -12px;
  line-height: 55px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;

  @media (max-width: 1290px) {
    font-size: 15px;
  }
`;

export const FreeTrailContainer = styled.div`
  position: absolute;
  background: #5bbe7d;
  border-radius: 30px;
  padding: 14px 14px;
  z-index: 15;
  left: 0;
  width: 830px;
  top: -10%;
  display: flex;
  justify-content: center;
`;

export const FreeTrailText = styled.p`
  user-select: none;
  display: flex;
  color: white;
  font-size: 18px;
  letter-spacing: 0.09px;
  font-family: "Avenir-Medium";
  line-height: 22px;
  text-align: center;
`;
