import React from "react";
import {
  Subtitle,
  ButtonConfirm,
  ButtonsContainer,
  SuccessContainer,
  Success,
  Scroll,
  Gap,
  StoreContainer,
  StoreContainerWrapper,
} from "./PGMPages.styles";
import AppStore from "../../assets/images/appstore.png";
import GooglePlay from "../../assets/images/googleplay.png";
import { useNavigate } from "react-router-dom";

const BackToComponent = ({ t }) => {
  const handleNavigation = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.ygii&hl=en_US&pli=1",
        "_blank"
      );
    } else if (/iPad|iPhone|mac|iPod/i.test(userAgent)) {
      window.open(
        "https://apps.apple.com/vn/app/ygii-gifts-wishlists/id1609129551",
        "_blank"
      );
    } else {
      window.open("https://www.ygii.app/", "_blank");
    }
  };

  return (
    <Scroll style={{ justifyContent: "start" }}>
      <SuccessContainer>
        <Subtitle style={{ textAlign: "center" }}>
          {t("thanks-for-your-feedback")}
        </Subtitle>
        <Success />
      </SuccessContainer>
      <StoreContainerWrapper>
        <Subtitle style={{ textAlign: "center" }}>
          {t("download-the-app-for-more-details")}!
        </Subtitle>

        <StoreContainer>
          <a
            href="https://apps.apple.com/vn/app/ygii-gifts-wishlists/id1609129551"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={AppStore}
              alt="App Store"
              className="store"
              style={{ userSelect: "none" }}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.ygii&hl=en_US&pli=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={GooglePlay}
              alt="img"
              className="store"
              style={{ userSelect: "none" }}
            />
          </a>
        </StoreContainer>
      </StoreContainerWrapper>
    </Scroll>
  );
};

export default BackToComponent;
