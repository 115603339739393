import React, { useEffect, useRef, useState } from "react";
import {
  MainContainer,
  Column,
  Content,
  Text,
  TextContainer,
  TabName,
  Select,
  Option,
  LoaderDiv,
  StyledAHref,
  HeaderWrapper,
  TableBodyRow,
  FirstItem,
  LastTableItem,
} from "./BillingPage.styles";
import Sidebar from "../utils/Sidebar";
import ContentHeader from "../utils/ContentHeader";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import {
  getBillingsInfo,
  getBillingsWithBankTransferPayment,
  updateBillingPaymentStatus,
} from "../../../store/slices/billing/asyncThunks";
import { toast } from "react-toastify";
import ChargeWalletModal from "../../../components/ChargeWalletModal/ChargeWalletModal";
import {
  selectBusinessUserInfo,
  setWalletBalance,
} from "../../../store/slices/businessUser/slice";
import { billingPaymentStatus } from "../../../utils/constants";
import { formatDate } from "../../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  selectBankTransfers,
  selectBankTransfersLoading,
  selectBillingInfo,
  selectBillingInfoLoading,
  setChargeWalletAmount,
} from "../../../store/slices/billing/slice";
import { fetchBusinessUserInfo } from "../../../store/slices/businessUser/asyncThunks";
import MiniLoader from "../../../components/MiniLoader/MiniLoader";
import { ReactComponent as Open } from "../../../assets/icons/open.svg";
import Header from "../../../components/Header/Header";
import TableItem from "../../../components/Table/TableItem";
import Table from "../../../components/Table/Table";
import { selectedLocale } from "../../../store/slices/common/slice";

const BillingPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const billingInfo = useSelector(selectBillingInfo);
  const [amount, setAmount] = useState("");
  const [modal, setModal] = useState(false);
  const businessInfo = useSelector(selectBusinessUserInfo);
  const bankTransfers = useSelector(selectBankTransfers);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const billingsPage = queryParams.get("billingsPage");
  const bankTransfersPage = queryParams.get("bankTransfersPage");
  const billingsLoading = useSelector(selectBillingInfoLoading);
  const bankTransfersLoading = useSelector(selectBankTransfersLoading);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    dispatch(fetchBusinessUserInfo());
    dispatch(
      getBillingsInfo({
        page: billingsPage,
        sortBy: "createdAt",
        order: "DESC",
      })
    )
      .unwrap()
      .catch((err) => toast.error(t(err.message)));
  }, [dispatch, billingsPage]);

  useEffect(() => {
    if (businessInfo.role === "superAdmin") {
      dispatch(
        getBillingsWithBankTransferPayment({
          page: bankTransfersPage,
          sortBy: "createdAt",
          order: "DESC",
        })
      )
        .unwrap()
        .catch((err) => toast.error(t(err.message)));
    }
  }, [dispatch, bankTransfersPage, businessInfo.role]);

  useEffect(() => {
    if (businessInfo.role === "superAdmin") {
      if (billingsLoading && bankTransfersLoading) {
        setLoader(true);
      }
      if (!billingsLoading && !bankTransfersLoading) {
        setLoader(false);
      }
      if (!billingsLoading && bankTransfersLoading) {
        setLoader(false);
      }
      if (billingsLoading && !bankTransfersLoading) {
        setLoader(false);
      }
    }
  }, [bankTransfersLoading, billingsLoading, businessInfo.role]);

  const handlePageChange = (pageNumber, paramName) => {
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set(paramName, pageNumber);

    const url = `/admin-portal/billing?${currentParams.toString()}`;
    navigate(url);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
    dispatch(fetchBusinessUserInfo());
  };

  const billingsData = billingInfo?.data;
  const billingsBankTransfersData = bankTransfers?.data;

  const onChargeWallet = () => {
    setModal(true);
  };

  const tableHeader = [
    {
      label: `${t("billing")} #`,
      sortable: true,
      key: "billing",
    },
    {
      label: `${t("value")}`,
      sortable: true,
      key: "value",
    },
    {
      label: `${t("created")}`,
      sortable: true,
      key: "createdAt",
    },
    {
      label: `${t("type-of-billing")}`,
      sortable: true,
      key: "typeOfBilling",
    },
    {
      label: `${t("status")}`,
      sortable: true,
      key: "status",
    },

    {
      label: `${t("payment-type")}`,
      sortable: false,
      key: "paymentType",
    },
  ];

  const tableHeaderTransfers = [
    {
      label: `${t("billing")} #`,
      sortable: true,
      key: "billing",
    },
    {
      label: `${t("value")}`,
      sortable: true,
      key: "value",
    },
    {
      label: `${t("company")}`,
      sortable: true,
      key: "company",
    },
    {
      label: `${t("recipient-url")}`,
      sortable: true,
      key: "recipientUrl",
    },
    {
      label: `${t("created")}`,
      sortable: true,
      key: "createdAt",
    },

    {
      label: `${t("type-of-billing")}`,
      sortable: true,
      key: "typeOfBilling",
    },
    {
      label: `${t("status")}`,
      sortable: true,
      key: "status",
    },
    {
      label: `${t("payment-type")}`,
      sortable: true,
      key: "paymentType",
    },
  ];

  const BillingComponent = () => {
    return !billingsLoading ? (
      <>
        {billingInfo?.data?.length <= 0 ? (
          <>
            <TabName>{t("billing")}</TabName>
            <Content>
              <TextContainer>
                <Text>{t("you-currently-have-no-billing-in-history")}.</Text>
              </TextContainer>
            </Content>
          </>
        ) : (
          <>
            <TabName>{t("billing")}</TabName>

            <Table
              marginTop={40}
              headerInformation={tableHeader}
              totalCount={billingInfo.total}
              currentPage={+billingsPage}
              setPage={handlePageChange}
              param={"billingsPage"}
              perPage={billingInfo.perPage}
              noDataContent={t("no-data")}
              dataLoading={false}
              lastColumnAligned
              centerText
            >
              {billingsData.map((billing, key) => {
                return (
                  <TableBodyRow key={key} onClick={() => {}} $offClick>
                    <FirstItem data={billing.id} />
                    <TableItem
                      center
                      data={parseFloat(billing.value).toFixed(2)}
                    />
                    <TableItem center data={formatDate(billing.createdAt)} />
                    <TableItem center data={t(billing.type.toLowerCase())} />
                    <TableItem center data={t(billing.paymentStatus)} />

                    <LastTableItem
                      $offClick
                      data={billing.paymentType || "/"}
                    />
                  </TableBodyRow>
                );
              })}
            </Table>
          </>
        )}
      </>
    ) : (
      <MiniLoader style={{ padding: "150px 0px 100px 0px" }} />
    );
  };

  const showUpgradeBar = businessInfo.company.packageType === "Free";
  let diff =
    new Date(businessInfo.company?.packageExpiration).getTime() -
    new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  const locale = useSelector(selectedLocale);
  const upgradePackage = showUpgradeBar && diff > 0 && businessInfo.state;
  const ar = locale === "ar";

  return (
    <MainContainer>
      <HeaderWrapper>
        <Header
          showLogin
          showSignUp
          showNav
          color
          showCart
          showFavorite
          showUser
        />
      </HeaderWrapper>
      <Sidebar />

      <Column $upgradePackage={upgradePackage} $ar={ar}>
        <ContentHeader
          showSendGifts
          hide
          showWallet
          showButtonCharge
          onChargeWallet={onChargeWallet}
        />
        {/* {selectedLanguage === "en" ? (
          <BalanceContainer>
            <BalanceValueContainer>
              <Balance>{t("reserved-funds")}:</Balance>
              <BalanceValue>
                {`${priceFormatter(
                  businessInfo.company?.walletReservedFunds
                )} ${getCurrencySymbol(businessInfo?.company?.walletCurrency)}`}
              </BalanceValue>
            </BalanceValueContainer>
          </BalanceContainer>
        ) : (
          <BalanceContainer>
            <BalanceValueContainer>
              <BalanceValue>
                {`${priceFormatter(
                  businessInfo.company?.walletReservedFunds
                )} ${getCurrencySymbol( businessInfo.company?.walletCurrency)}`}
              </BalanceValue>
              <Balance>:{t("wallet-balance")}</Balance>
            </BalanceValueContainer>
          </BalanceContainer>
        )} */}

        {businessInfo.role !== "superAdmin" && <BillingComponent />}

        {businessInfo.role === "superAdmin" && (
          <>
            {!loader ? (
              <>
                <BillingComponent />

                {!bankTransfersLoading ? (
                  <>
                    {bankTransfers?.data.length <= 0 ? (
                      <>
                        <TabName>{t("bank-transfers")}</TabName>
                        <Content>
                          <TextContainer>
                            <Text>
                              {t(
                                "you-currently-have-no-bank-transfers-in-history"
                              )}
                              .
                            </Text>
                          </TextContainer>
                        </Content>
                      </>
                    ) : (
                      <>
                        <TabName $isBankTransfers style={{ marginTop: 40 }}>
                          {t("bank-transfers")}
                        </TabName>

                        <Table
                          marginTop={40}
                          headerInformation={tableHeaderTransfers}
                          totalCount={bankTransfers.total}
                          currentPage={+bankTransfersPage}
                          setPage={handlePageChange}
                          param={"bankTransfersPage"}
                          perPage={bankTransfers.perPage}
                          noDataContent={t("no-data")}
                          dataLoading={false}
                          lastColumnAligned
                          centerText
                        >
                          {billingsBankTransfersData.map((billing, key) => {
                            return (
                              <TableBodyRow
                                key={key}
                                onClick={() => {}}
                                $offClick
                              >
                                <FirstItem data={billing.id} />
                                <TableItem
                                  center
                                  data={parseFloat(billing.value).toFixed(2)}
                                />
                                <TableItem
                                  center
                                  showHover
                                  hoverText={billing.businessUser.company.name}
                                  data={
                                    billing.businessUser.company.name &&
                                    billing.businessUser.company.name.length >
                                      25
                                      ? billing.businessUser.company.name.slice(
                                          0,
                                          25
                                        ) + "..."
                                      : billing.businessUser.company.name || "/"
                                  }
                                />
                                <TableItem
                                  center
                                  data={
                                    billing.receiptUrl === null ? (
                                      <Open className="details-icon" />
                                    ) : (
                                      <StyledAHref
                                        href={billing.receiptUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <Open className="details-icon" />
                                      </StyledAHref>
                                    )
                                  }
                                />
                                <TableItem
                                  center
                                  data={formatDate(billing.createdAt)}
                                />
                                <TableItem
                                  center
                                  data={t(billing.type.toLowerCase())}
                                />
                                <TableItem
                                  center
                                  data={
                                    billing.paymentStatus === "pending" ? (
                                      <Select
                                        id="userDropdown"
                                        onChange={(event) => {
                                          const selectedStatusId = parseInt(
                                            event.target.value
                                          );
                                          const selectedPaymentStatus =
                                            billingPaymentStatus.find(
                                              (status) =>
                                                status.id === selectedStatusId
                                            );
                                          const selectedStatusValue =
                                            selectedPaymentStatus?.value;
                                          dispatch(
                                            updateBillingPaymentStatus({
                                              id: billing.id,
                                              paymentStatus:
                                                selectedStatusValue,
                                            })
                                          )
                                            .unwrap()
                                            .then((res) => {
                                              dispatch(
                                                setWalletBalance({
                                                  newBalanceValue:
                                                    res.data.walletBalance,
                                                })
                                              );
                                            });
                                        }}
                                      >
                                        {billingPaymentStatus.map((status) => {
                                          return (
                                            <Option
                                              key={status.id}
                                              value={status.id}
                                              selected={
                                                billing.paymentStatus ===
                                                status.value
                                              }
                                            >
                                              {t(status.value)}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    ) : (
                                      <>{t(billing.paymentStatus)}</>
                                    )
                                  }
                                />

                                <LastTableItem
                                  $offClick
                                  data={t(billing.paymentType) || "/"}
                                />
                              </TableBodyRow>
                            );
                          })}
                        </Table>
                      </>
                    )}
                  </>
                ) : (
                  <MiniLoader style={{ padding: "120px 0px" }} />
                )}
              </>
            ) : (
              <LoaderDiv>
                <MiniLoader style={{ padding: "100px 0px" }} />
              </LoaderDiv>
            )}
          </>
        )}
      </Column>
      <ChargeWalletModal
        onChange={handleAmountChange}
        onClick={() => {
          dispatch(setChargeWalletAmount(amount));
          if (amount) {
            navigate("/admin-portal/wallet/checkout");
          } else {
            toast.error(t("please-add-amount"));
          }
        }}
        value={amount}
        open={modal}
        close={() => setModal(false)}
      />
    </MainContainer>
  );
};

export default BillingPage;
