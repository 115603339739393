import styled from "styled-components";
import { ReactComponent as Attachment } from "../../../assets/icons/attachment.svg";
import { ReactComponent as GallerySvg } from "../../../assets/icons/gallerySvg.svg";

export const MainContainer = styled.div`
  flex-direction: row;
  background: #f6f6f9;

  min-height: 100vh;
  display: flex;
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  margin-top: 108px;
  padding: ${(props) =>
    props.$ar ? "0px 290px 0px 40px" : "0px 40px 0px 290px"};
  @media (max-width: 870px) {
    margin-top: 50px;
    padding: 0px 40px 0px 40px;
  }
`;

export const GalleryIcon = styled(GallerySvg)`
  width: 4.2rem;
  height: auto;
  cursor: pointer;
`;
export const GalleryText = styled.span`
  font-size: 1.6rem;
  color: #8a4b5e;
  font-family: "Avenir-Heavy";
`;
export const GalleryRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
`;
export const GalleryColumn = styled(GalleryRow)`
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`;

export const HeaderWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
`;

export const Text = styled.span`
  font-family: "Avenir-Black";
  font-style: normal;

  font-size: 24px;
  line-height: 33px;

  letter-spacing: -0.177777px;

  color: #8a4b5e;
`;

export const Desc = styled.span`
  font-family: "Avenir-Light";
  font-style: normal;

  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.177777px;

  color: rgba(69, 69, 132, 0.6);
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  margin-right: 30px;
  align-items: right;
  justify-content: right;
  @media (max-width: 990px) {
    align-items: center;
    justify-content: center;
    margin-right: 0px;
  }
`;

export const Button = styled.div`
  border-radius: 12px;
  user-select: none;
  background: #8a4b5e;
  height: 30px;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  border: none;
  color: #fff;
  text-align: center;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
  cursor: pointer;

  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
    background: #8a4b5e;
  }

  @media (max-width: 1075px) {
    height: 35px;
    padding: 5px 20px;
    font-size: 12px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 40px;
  justify-content: space-between;
  align-items: center;
`;

export const DetailsDataValue = styled.span`
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 18px;
  line-height: 33px;
  letter-spacing: -0.177777px;
  color: #a87283;
  @media (max-width: 485px) {
    font-size: 14px;
  }
`;

export const StyledDetailsDataValue = styled(DetailsDataValue)`
  @media (max-width: 553px) {
    font-size: 12px;
  }
  @media (max-width: 420px) {
    font-size: 10px;
  }

  @media (max-width: 380px) {
    font-size: 7.5px;
  }
`;

export const DetailsContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
  flex-direction: row;
  // align-items: center;
  justify-content: space-between;
  display: flex;
  @media (max-width: 990px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const DetailsContainerColumn = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
`;

export const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

export const DetailsData = styled.div`
  margin-right: 5px;
  font-family: "Avenir-Heavy";
  font-size: 18px;
  line-height: 33px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  @media (max-width: 485px) {
    font-size: 14px;
  }
`;

export const InvoiceDiv = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const ButtonBackContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: left;
  justify-content: left;
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const ButtonDisabled = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 30px;
  align-items: center;
  font-family: "Avenir-Medium";
  font-style: normal;

  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.177777px;
  color: #ffffff;
  gap: 10px;
  justify-content: center;
  background: #8a4b5e 100%;
  border-radius: 15px;
`;

export const Select = styled.select`
  border: none;
  padding: 10px 5px;
  background: transparent;
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #a87283;
  display: flex;
  flex-direction: flex-end;
  text-align: right;

  &:focus {
    outline: none;
  }
`;

export const Option = styled.option`
  border: none;
  padding: 8px;
  background: transparent;
  width: 100%;
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
`;

export const DropDownContainer = styled.div`
  display: flex;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: white;
  align-items: center;
  margin-top: 10px;
  border: 1px solid #d1d1d4;
  border-radius: 4px;
  padding: 4px 8px 4px 10px;
  justify-content: space-between;
`;

export const StyledAttachment = styled(Attachment)`
  width: 24px;
  height: 24px;
`;

export const SelectForbidden = styled.div`
  border: none;
  padding: 10px 5px;
  background: transparent;
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #a87283;
  display: flex;
  flex-direction: flex-end;
  text-align: right;

  &:focus {
    outline: none;
  }
`;

export const ItemDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 550px) {
    width: 350px;
  }

  @media (max-width: 480px) {
    width: 290px;
  }

  @media (max-width: 420px) {
    width: 250px;
  }

  @media (max-width: 380px) {
    width: 150px;
  }
`;

export const VoucherCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 550px) {
    width: 350px;
  }

  @media (max-width: 480px) {
    width: 290px;
  }

  @media (max-width: 420px) {
    width: 250px;
  }

  @media (max-width: 380px) {
    width: 150px;
  }
`;

export const LoaderDiv = styled.div`
  margin-top: 300px;
  @media (max-height: 870px) {
    margin-top: 200px;
  }

  @media (max-height: 670px) {
    margin-top: 160px;
  }

  @media (max-height: 550px) {
    margin-top: 80px;
  }

  @media (max-height: 450px) {
    margin-top: 40px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ItemImage = styled.img`
  display: flex;
  height: 250;
  max-width: 750;
`;
