import {
  postInstantQuotation,
  postQuotationWithMultipleItems,
} from "./asyncThunks";

export const postInstantQuotationBuilder = (builder) => {
  builder.addCase(postInstantQuotation.pending, (state) => {
    state.postInstantQuotation.loading = true;
  });
  builder.addCase(postInstantQuotation.fulfilled, (state, action) => {
    const { url } = action.payload.data;
    state.postInstantQuotation.url = url;
    state.postInstantQuotation.loading = false;
  });
  builder.addCase(postInstantQuotation.rejected, (state) => {
    state.postInstantQuotation.loading = false;
  });
};

export const postQuotationWithMultipleItemsBuilder = (builder) => {
  builder.addCase(postQuotationWithMultipleItems.pending, (state) => {
    state.postQuotationWithMultipleItemsPending = true;
  });
  builder.addCase(postQuotationWithMultipleItems.fulfilled, (state, action) => {
    const { url } = action.payload.data;
    state.postQuotationWithMultipleItemsUrl = url;
    state.postQuotationWithMultipleItemsPending = false;
  });
  builder.addCase(postQuotationWithMultipleItems.rejected, (state) => {
    state.postQuotationWithMultipleItemsPending = false;
  });
};
