import {
  getPaymentMethod,
  removePaymentMethod,
  setDefaultPaymentMethod,
} from "./asyncThunks";

export const getPaymentMethodBuilder = (builder) => {
  builder
    .addCase(getPaymentMethod.pending, (state) => {
      state.paymentMethods.loading = true;
      state.paymentMethods.error = null;
    })
    .addCase(getPaymentMethod.fulfilled, (state, action) => {
      const { paymentMethods } = action.payload.data;
      state.paymentMethods.data = paymentMethods;
      state.paymentMethods.loading = false;
    })
    .addCase(getPaymentMethod.rejected, (state, action) => {
      state.paymentMethods.loading = false;
      state.paymentMethods.error = action.error.message;
    });
};

export const removePaymentMethodBuilder = (builder) => {
  builder
    .addCase(removePaymentMethod.pending, (state) => {
      state.removePaymentMethodLoading = true;
      state.removePaymentMethodError = null;
    })
    .addCase(removePaymentMethod.fulfilled, (state, action) => {
      const updatedData = [
        ...state.paymentMethods.data.filter((c) => c.id !== action.payload),
      ];
      state.paymentMethods.data = updatedData;
      state.removePaymentMethodError = null;
      state.removePaymentMethodLoading = false;
    })

    .addCase(removePaymentMethod.rejected, (state, action) => {
      state.removePaymentMethodLoading = false;
      state.removePaymentMethodError = action.error.message;
    });
};

export const setDefaultPaymentMethodBuilder = (builder) => {
  builder.addCase(setDefaultPaymentMethod.pending, (state) => {
    state.setDefaultPaymentMethodLoading = true;
    state.setDefaultPaymentMethodError = null;
  });
  builder.addCase(setDefaultPaymentMethod.fulfilled, (state, action) => {
    state.paymentMethods.data = state.paymentMethods.data.map((payment) => {
      if (payment.id === action.payload) {
        return { ...payment, default: true };
      } else {
        return { ...payment, default: false };
      }
    });
    state.setDefaultPaymentMethodLoading = false;
    state.setDefaultPaymentMethodError = null;
  });
  builder.addCase(setDefaultPaymentMethod.rejected, (state, action) => {
    state.setDefaultPaymentMethodLoading = false;
    state.setDefaultPaymentMethodError = action.error.message;
  });
};
