import { FRONT_URL } from "../api";

export const seoDefaultData = {
  title: {
    en: "Ygii Gifting Services",
    ar: "خدمات الإهداء من يجي",
  },
  description: {
    en: "Transform your company's gifting experience with Ygii. Empower employees to choose their own gifts and customize event giveaways for enjoyable celebrations.",
    ar: "غيّر تجربة الإهداء في شركتك مع يجي. امنح للموظفين اختيار هداياهم الخاصة وتخصيص الهدايا الترويجية للاحتفالات الممتعة.",
  },
  image: "https://portal.ygii.app/ygii.png",
};

export const seoData = {
  [FRONT_URL + "/login"]: {
    title: { en: "Ygii Login", ar: "يجي تسجيل دخول" },
    description: {
      en: "Discover the power of Ygii. Access a wide range of customizable gifts and giveaways.",
      ar: "اكتشف قوة يجي. احصل على تشكيلة واسعة من الهدايا والهدايا الترويجية القابلة للتخصيص.",
    },
  },

  [FRONT_URL + "/sign-up"]: {
    title: { en: "Gifting as a Service", ar: "الإهداء كخدمة" },
    description: {
      en: "Empower employees with our GaaS.Streamline gift-giving by letting them choose, for a seamless experience.",
      ar: "مكّن الموظفين مع خدمات الاهداء لدينا. عزز عملية إعطاء الهدايا بجعلهم يختارون هداياهم بتجربة سلسة.",
    },
  },

  [FRONT_URL + "/marketplace"]: {
    title: { en: "Marketplace", ar: "المتجر" },
    description: {
      en: "Elevate events with Ygii's customizable giveaways. Add fun and personalization to your celebrations.",
      ar: "ارفع من شأن الفعاليات مع الهدايا الترويجية القابلة للتخصيص من يجي. أضف المرح والتخصيص لاحتفالاتك.",
    },
  },
  [FRONT_URL + "/admin-portal/marketplace"]: {
    title: { en: "Marketplace", ar: "المتجر" },
    description: {
      en: "Elevate events with Ygii's customizable giveaways. Add fun and personalization to your celebrations.",
      ar: "ارفع من شأن الفعاليات مع الهدايا الترويجية القابلة للتخصيص من يجي. أضف المرح والتخصيص لاحتفالاتك.",
    },
  },

  [FRONT_URL + "/marketplace?category=16"]: {
    title: { en: "Foundation Day", ar: "يوم التأسيس" },
    description: {
      en: "Get ready with Ygii. Explore our exclusive giveaways and prepare your company for a memorable day.",
      ar: "استعد مع يجي. استكشف هدايانا الحصرية وأعد شركتك ليوم لا يُنسى.",
    },
  },
};
