import { styled } from "styled-components";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import { ReactComponent as Chevron } from "../../assets/icons/chevron-left.svg";
import { ReactComponent as Add } from "../../assets/icons/add-filled.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as GiftIcon } from "../../assets/icons/gift.svg";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100dvh;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const MobileScreenContainer = styled.div`
  background-color: white;
  position: relative;
  width: 600px;
  height: 100dvh;

  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 575px) {
    width: 100%;
  }
`;

export const Gap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 100%;
  padding: 32px;
  overflow-x: hidden;
`;

export const Scroll = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  height: 100%;
  justify-content: space-between;
  overflow-y: auto;
`;
export const Subtitle = styled.span`
  user-select: none;

  font-size: 22px;
  font-family: "FrankfurterEF-Medium";
`;

export const Title = styled.span`
  font-size: 22px;
  text-align: center;

  letter-spacing: -0.24px;
  user-select: none;
  font-family: "Avenir-Heavy";
`;

export const Message = styled.span`
  font-size: 16px;
  font-family: "Avenir-Medium";
`;

export const MessageContainer = styled.div`
  display: flex;
  align-self: ${(props) => (props.$center ? "center" : "flex-start")};
  flex-direction: column;
  gap: 5px;
`;
export const Gifts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const Gift = styled.div`
  transition: all 0.2s ease-in-out;
  box-shadow: ${(props) =>
    props.$selected ? "rgb(38, 57, 77) 0px 20px 30px -10px;" : "unset"};
  z-index: ${(props) => (props.$selected ? 20 : 1)};
  display: flex;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  position: relative;
  height: 200px;
  width: 100%;
  border-radius: 12px;
  background-color: white;
  background-image: url(${(props) => props.$imageUrl});
  background-size: cover;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: ${(props) => (props.$active ? 1 : 0.65)};

  &:hover {
    opacity: 1;
  }

  @media (max-width: 575px) {
    height: 150px;
  }
`;

export const GiftName = styled.span`
  font-size: 14px;
  letter-spacing: -0.24px;
  font-family: "Avenir-Heavy";
  color: #fff;
  text-shadow: 0px 0px 3.5px rgba(0, 0, 0, 1);
`;

export const GiftPrice = styled(GiftName)`
  font-size: 12px;
  font-family: "Avenir-Medium";
`;

export const InfoContainer = styled.div`
  display: flex;
  z-index: 2;
  flex-direction: column;
  position: absolute;
  bottom: 12px;
  left: 12px;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 32px 32px 32px;
  gap: 20px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  @media (max-width: 305px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const ButtonReject = styled.button`
  background-color: white;
  min-width: 107px;
  outline: none;
  user-select: none;
  min-height: 49px;
  max-height: 49px;
  color: #e9435e;
  border-radius: 30px;
  border: 2px solid #e9435e;
  padding: 10px 5px;

  font-size: 18px;
  letter-spacing: -0.24px;
  font-family: "Avenir-Heavy";
  width: 20%;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  @media (max-width: 405px) {
    font-size: 14px;
    width: 50%;
    padding: 0px;
    min-height: 40px;
  }
  @media (max-width: 325px) {
    font-size: 12px;
    min-height: 36px;
  }
  @media (max-width: 305px) {
    font-size: 14px;
    width: 80%;
  }
`;

export const ButtonConfirm = styled.button`
  min-height: 49px;
  max-height: 49px;
  background-color: ${(props) =>
    props.disabled ? "rgba(0,0,0,0.10)" : "#6868a7"};
  outline: none;
  user-select: none;
  color: ${(props) => (props.disabled ? "#000" : "#fff")};
  border-radius: 30px;
  border: ${(props) =>
    props.disabled ? "2px solid transparent" : "2px solid #6868a7"};
  padding: 10px 5px;
  width: 80%;
  font-size: 18px;
  letter-spacing: -0.24px;
  font-family: "Avenir-Heavy";
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  align-self: ${(props) => (props.$center ? "center" : "flex-start")};
  @media (max-width: 405px) {
    padding: 0px;
    font-size: 14px;
    min-height: 40px;
  }
  @media (max-width: 325px) {
    font-size: 12px;
    min-height: 36px;
  }
  @media (max-width: 305px) {
    font-size: 14px;
  }
`;

export const SmallText = styled.span`
  font-size: 14px;
  text-align: center;
  font-family: "Avenir-Light";
  cursor: pointer;
  user-select: none;

  color: #8a4b5e;
  opacity: 0.75;
  text-decoration: underline;
`;

export const UncheckedIcon = styled.div`
  background-color: #8a4b5e;
  z-index: 2;

  border-radius: 50%;
  width: 28px;
  height: 28px;
  padding: 2px;
  border: 2px solid white;

  position: absolute;
  right: ${(props) => (props.$24 ? "24px" : "12px")};
  bottom: 12px;
`;

export const CheckIcon = styled(Check)`
  background-color: #8a4b5e;
  z-index: 2;

  border-radius: 50%;
  width: 28px;
  height: 28px;
  padding: 2px;
  border: 2px solid white;

  position: absolute;
  right: ${(props) => (props.$24 ? "24px" : "12px")};
  bottom: 12px;
`;

export const DeliverContainer = styled.div`
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-self: flex-start;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  align-self: flex-start;
`;

export const SearchContainer = styled.div`
  padding: 0px 32px;
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  justify-content: center;

  border-radius: 2rem;

  box-shadow: rgba(60, 64, 67, 0.125) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
`;
export const ChevronBack = styled(Chevron)`
  cursor: pointer;
`;

export const Input = styled.input`
  border: none;
  outline: none;

  color: #8a4b5e;
  padding: 2.4rem 1rem;

  font-size: 1.6rem;
  text-transform: capitalize;
  letter-spacing: -0.178px;
  font-family: "Avenir-Medium";

  @media (max-width: 480px) {
    width: 34rem;
  }
  @media (max-width: 405px) {
    width: 28rem;
  }
  @media (max-width: 335px) {
    width: 24rem;
  }
`;

export const NewLocationButton = styled.button`
  width: 100%;
  border-radius: 12px;
  border: 1px solid #8a4b5e;
  background-color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  outline: none;
  user-select: none;
  font-family: "Avenir-Heavy";
  font-size: 18px;
  letter-spacing: -0.24px;
  cursor: pointer;
`;

export const AddIcon = styled(Add)`
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
`;

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 12px 24px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: calc(100% + 64px);
  left: -32px;
  /* width: 100%; */
  &:hover {
    background-color: #8a4b5e15;
    padding: 12px 12px 12px 32px;
  }
`;

export const LocationName = styled.span`
  font-family: "Avenir-Heavy";
  font-size: 16px;
  letter-spacing: -0.24px;
`;

export const Address = styled.span`
  max-width: 80%;
  font-family: "Avenir-Medium";
  font-size: 14px;
  letter-spacing: -0.24px;
  color: #8a4b5e;
`;

export const Line = styled.div`
  position: absolute;
  background-color: #8a4b5e;
  opacity: 0.25;
  height: 1px;
  left: -32px;
  width: calc(100% + 64px);
  top: ${(props) => (props.$top ? 0 : "unset")};
  bottom: ${(props) => (props.$bottom ? 0 : "unset")};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  z-index: 1;
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(65, 64, 64, 0.5) 100%);
`;

export const FeedBackContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  width: 100%;
`;

export const RadioButtonsContainer = styled.div`
  padding: 12px 32px;
  gap: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const TextArea = styled.textarea`
  border-radius: 12px;
  border: 1px solid #8a4b5e;
  padding: 18px;
  outline: none;
  font-size: 16px;
  font-family: "Avenir-Medium";
  color: #8a4b5e;
  width: 100%;
`;

export const Success = styled(Check)`
  width: 150px;
  height: 150px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b9e0bd;
  border-radius: 50%;
  border: 5px solid #5e9c76;

  @media (max-width: 500px) {
    width: 125px;
    height: 125px;
  }

  @media (max-width: 400px) {
    width: 100px;
    height: 100px;
  }
`;

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 80px;
  justify-content: center;
  margin-top: 100px;

  @media (max-width: 500px) {
    margin-top: 70px;
  }

  @media (max-width: 400px) {
    margin-top: 50px;
  }
`;

export const Relative = styled.div`
  position: relative;
  width: calc(100% + 64px);
  height: 100%;

  display: flex;
  justify-content: center;
  margin-top: -20px;
`;

export const Absolute = styled.div`
  position: absolute;
  z-index: 3;
  top: 64px;
  min-width: 32rem;
  width: 100%;
  max-width: 41rem;
  justify-content: center;
  display: flex;
  border-radius: 2rem;
  background: #fff;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  @media (max-width: 400px) {
    width: 50%;
  }
`;

export const SearchIcon = styled(Search)`
  position: absolute;
  left: 1rem;
  top: 50%;

  transform: translateY(-50%);
`;
export const LoaderContainer = styled.div`
  margin-top: 50px;
`;
export const NewLocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  padding-bottom: 32px;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  box-shadow: rgba(50, 50, 93, 0.125) 0px -6px 12px -2px,
    rgba(0, 0, 0, 0.15) 0px -3px 7px -3px;
`;

export const LocationDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 32px;
`;

export const PickGiftHeader = styled.div`
  width: 100%;
  background-color: #8a4b5e;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 82px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  position: relevant;
`;

export const ArcStyle = styled.div`
  width: 100%;
  height: 80px;
  margin-top: -56px;
  border-radius: 25px;
  transform: rotate(2.5deg);
  box-shadow: 0px 0px 0px -1px #000; /* elevation: -1 */
  z-index: 5000;
  background-color: #8a4b5e;
`;

export const StoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 20px;
  justify-content: center;
  margin-top: 50px;

  @media (max-width: 400px) {
    margin-top: 30px;
  }

  @media (max-height: 750px) {
    margin-top: 30px;
  }

  @media (max-height: 750px) {
    margin-top: 30px;
  }
`;

export const StoreContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 150px;

  @media (max-width: 500px) {
    margin-top: 100px;
  }

  @media (max-width: 400px) {
    margin-top: 80px;
  }

  @media (max-width: 375px) {
    margin-top: 60px;
  }

  @media (max-height: 850px) {
    margin-top: 70px;
  }

  @media (max-height: 750px) {
    margin-top: 60px;
  }
`;

export const GiftIconStyled = styled(GiftIcon)`
  width: 250px;
  height: 250px;
  align-self: center;

  @media (max-width: 400px) {
    width: 225px;
    height: 225px;
  }

  @media (max-width: 375px) {
    width: 150px;
    height: 150px;
  }
`;

export const LocaleWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 8px;
`;
