import { createSlice } from "@reduxjs/toolkit";
import { createCompanyGiftingDetailsBuilder } from "./builder";

const sendGiftsSlice = createSlice({
  name: "sendGifts",
  initialState: {
    giftCart: {},
    recipients: [],
    budget: 0,
    giftCard: {
      occasion: "",
      description: "",
    },
    packageLocation: {
      type: "",
      custom: {
        lat: null,
        lng: null,
        address: "",
      },
    },
    deliveryDate: "",
    gift: [],
    companyGiftingDetailsData: {
      data: {},
      loading: false,
      error: null,
    },
    detailsId: null,
    biggestPrice: 0,
    loading: false,
    error: null,
    addOns: {
      chocolate: false,
      cake: false,
      flowers: false,
    },
  },
  reducers: {
    addRecipients: (state, action) => {
      state.recipients = [action.payload];
    },
    addGifts: (state, action) => {
      const { user } = action.payload;
      if (user) {
        state.gift = [action.payload];
      } else {
        state.gift = [...state.gift, action.payload];
      }
    },
    addBudget: (state, action) => {
      state.budget = +action.payload.budget;
    },
    addGiftCard: (state, action) => {
      state.giftCard.occasion = action.payload.occasion;
      state.giftCard.description = action.payload.description;
    },
    addLocation: (state, action) => {
      state.packageLocation.type = action.payload.type;
      state.packageLocation.custom = action.payload.custom;
    },
    addDeliveryDate: (state, action) => {
      state.deliveryDate = action.payload.date;
    },
    giftCart: (state) => {
      state.giftCart = [...state.gift];
    },
    removeGift: (state, action) => {
      state.gift = state.gift.filter((item) => item.id !== action.payload);
    },
    resetGifts: (state) => {
      state.gift = [];
    },
    resetGiftsAfterBudget: (state) => {
      state.gift = [state.gift[0]];
    },
    setSendGiftData: (state, action) => {
      return action.payload;
    },
    setBiggestPrice: (state, action) => {
      state.biggestPrice = action.payload;
    },
    setAddOns: (state, action) => {
      if (action.payload.cake) {
        state.addOns.cake = true;
      } else if (action.payload.chocolate) {
        state.addOns.chocolate = true;
      } else if (action.payload.flowers) {
        state.addOns.flowers = true;
      }
    },
    resetData: (state) => {
      state.gift = [];
      state.giftCard = {
        occasion: "",
        description: "",
      };
      state.packageLocation = {
        type: "",
        custom: {
          lat: null,
          lng: null,
          address: "",
        },
      };
      state.deliveryDate = "";
      state.addOns = {
        chocolate: false,
        cake: false,
        flowers: false,
      };
    },
  },

  extraReducers: (builder) => {
    createCompanyGiftingDetailsBuilder(builder);
  },
});

export const {
  addRecipients,
  addGifts,
  addBudget,
  addDeliveryDate,
  addLocation,
  addGiftCard,
  giftCart,
  removeGift,
  resetGifts,
  setSendGiftData,
  resetData,
  setBiggestPrice,
  resetGiftsAfterBudget,
  setAddOns,
} = sendGiftsSlice.actions;

export const selectSendGifts = (state) => state.sendGifts;
export const selectRecipients = (state) => state.sendGifts.recipients;
export const selectGifts = (state) => state.sendGifts.gift;
export const selectGiftsCart = (state) => state.sendGifts.giftCart;
export const selectCompanyGiftingDetailsData = (state) =>
  state.sendGifts.companyGiftingDetailsData.data;
export const selectCompanyGiftingDetailsLoader = (state) =>
  state.sendGifts.companyGiftingDetailsData.loading;
export const selectDetailsId = (state) => state.sendGifts.detailsId;
export const selectBiggestPrice = (state) => state.sendGifts.biggestPrice;

export default sendGiftsSlice.reducer;
