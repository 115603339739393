import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerLight = styled.div`
  display: inline-block;
  width: 36px;
  height: 36px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  border-top: 4px solid white;
  animation: ${spin} 1s linear infinite;
`;

const Spinner = styled.div`
  display: inline-block;
  width: 36px;
  height: 36px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #8a4b5e;
  animation: ${spin} 1s linear infinite;
`;

const AppLoader = ({ size, light }) => {
  return light ? (
    <SpinnerLight
      style={{
        height: size,
        width: size,
      }}
    />
  ) : (
    <Spinner
      style={{
        height: size,
        width: size,
      }}
    />
  );
};

export default AppLoader;
