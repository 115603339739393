import React, { useRef } from "react";

import {
  ContentContainer,
  Title,
  TitleContainer,
  CloseContainer,
  Column,
  StyledButton,
  ButtonDiv,
} from "../InviteUserModal/InviteUserModal.style";

import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactComponent as Loader } from "../../assets/icons/loaderWhite.svg";
import { selectedBankTransfersReviewLoading } from "../../store/slices/subscriptions/slice";
import { useDispatch } from "react-redux";
import { reviewBankTransfer } from "../../store/slices/subscriptions/asyncThunks";
import openToast from "../../utils/toast";
import Modal2 from "../Modal/Modal2";

const AcceptDeclineModal = ({ modalRef, subscriptionsId, open, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(selectedBankTransfersReviewLoading);
  const toastId = useRef(null);

  const onClickAccept = () => {
    dispatch(reviewBankTransfer({ subscriptionsId, action: "ACCEPT" }))
      .unwrap()
      .then(() => {
        close();
      })
      .catch((err) =>
        openToast(toastId, "accept-decline-modal", "error", t(err.message))
      );
  };
  const onClickDecline = () => {
    dispatch(reviewBankTransfer({ subscriptionsId, action: "DECLINE" }))
      .unwrap()
      .then(() => {
        close();
      })
      .catch((err) =>
        openToast(toastId, "accept-decline-modal", "error", t(err.message))
      );
  };

  return (
    <Modal2
      border={"2px solid #8A4B5E"}
      bg={"linear-gradient(360deg, #a87283 -100%, #8A4B5E 100%)"}
      onClose={close}
      open={open}
      height={"fit-content"}
      width={"fit-content"}
    >
      <ContentContainer>
        <CloseContainer onClick={!loading ? () => close() : () => {}}>
          <Close className="close" fill="#fff" />
        </CloseContainer>

        <Column>
          <TitleContainer>
            <Title>{t("do-you-want-to-accept-or-decline-bank-transfer")}</Title>
          </TitleContainer>
          <ButtonDiv>
            {loading ? (
              <StyledButton $disabled={!loading} onClick={() => {}}>
                <Loader height={24} width={24} />
              </StyledButton>
            ) : (
              <>
                <StyledButton
                  $disabled={!loading}
                  onClick={!loading ? onClickDecline : () => {}}
                >
                  {!loading ? t("decline") : <Loader height={24} width={24} />}
                </StyledButton>

                <StyledButton
                  $disabled={!loading}
                  onClick={!loading ? onClickAccept : () => {}}
                >
                  {!loading ? t("accept") : <Loader height={24} width={24} />}
                </StyledButton>
              </>
            )}
          </ButtonDiv>
        </Column>
      </ContentContainer>
    </Modal2>
  );
};

export default AcceptDeclineModal;
