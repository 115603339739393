import React, { useCallback, useRef, useState } from "react";
import {
  Title,
  ButtonConfirm,
  TitleContainer,
  ChevronBack,
  Address,
  LocationName,
  Input,
  SearchIcon,
  NewLocationContainer,
  LocationDiv,
  LoaderContainer,
  Scroll,
  SearchContainer,
} from "./PGMPages.styles";
import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  MAP_LIBRARIES,
  MAP_ZOOM_LEVEL,
  googlePlacesKey,
} from "../../utils/constants";
import MiniLoader from "../../components/MiniLoader/MiniLoader";

const NewLocationComponent = ({ setTab, t, location, setLocation }) => {
  const mapRef = useRef();
  const searchBoxRef = useRef();
  const [mapZoom, setMapZoom] = useState(MAP_ZOOM_LEVEL);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googlePlacesKey,
    libraries: MAP_LIBRARIES,
  });

  const handlePlacesChanged = (map) => {
    const places = searchBoxRef.current?.getPlaces();

    if (places && places.length > 0) {
      const place = places[0];
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const address = place.formatted_address;
      setLocation({ lat, lng, address });

      if (mapRef.current) {
        mapRef.current.setCenter({ lat, lng });
        setMapZoom(14);
      }
    }
  };

  const handleMarkerDragEnd = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results?.length > 0) {
        const address = results[0].formatted_address;
        setLocation({ lat, lng, address });
      }
    });
  };

  const handleMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results?.length > 0) {
        const address = results[0].formatted_address;
        setLocation({ lat, lng, address });
      }
    });
  };

  const center =
    location?.lat && location?.lng
      ? {
          lat: location.lat,
          lng: location.lng,
        }
      : {
          lat: DEFAULT_LATITUDE,
          lng: DEFAULT_LONGITUDE,
        };

  const onLoad = useCallback(
    function callback(map) {
      mapRef.current = map;
      map.setCenter(center);
      map.setZoom(MAP_ZOOM_LEVEL);
    },
    [center]
  );

  return (
    <Scroll>
      <TitleContainer style={{ padding: "32px 32px 0px 32px" }}>
        <ChevronBack onClick={() => setTab("confirm-location")} />
        <Title>{t("select-a-delivery-location")}</Title>
      </TitleContainer>
      {isLoaded ? (
        <>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              marginTop: -10,
            }}
          >
            <StandaloneSearchBox
              onLoad={(ref) => {
                searchBoxRef.current = ref;
              }}
              onPlacesChanged={handlePlacesChanged}
            >
              <SearchContainer>
                <SearchIcon />
                <Input
                  type="text"
                  placeholder={t("search-address")}
                  value={location?.address}
                  onChange={(e) => {
                    setLocation((prevLocation) => ({
                      ...prevLocation,
                      address: e.target.value,
                    }));
                  }}
                />
              </SearchContainer>
            </StandaloneSearchBox>
          </div>

          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "100%",
            }}
            onLoad={(map) => {
              mapRef.current = map;
              onLoad(map);
            }}
            zoom={mapZoom}
            onClick={handleMapClick}
          >
            <Marker
              position={
                location?.lat && location?.lng
                  ? {
                      lat: location.lat,
                      lng: location.lng,
                    }
                  : {
                      lat: DEFAULT_LATITUDE,
                      lng: DEFAULT_LONGITUDE,
                    }
              }
              draggable
              onDragEnd={handleMarkerDragEnd}
            />
          </GoogleMap>
        </>
      ) : (
        <LoaderContainer>
          <MiniLoader />
        </LoaderContainer>
      )}

      <NewLocationContainer>
        <LocationDiv>
          <LocationName>{t("delivery-location")}</LocationName>
          {!location?.address ? (
            <Address>{t("select-a-delivery-location")}</Address>
          ) : (
            <Address>{location?.address}</Address>
          )}
        </LocationDiv>

        <ButtonConfirm
          onClick={() => setTab("confirm-location")}
          $center
          disabled={!location?.address}
        >
          {t("confirm-location")}
        </ButtonConfirm>
      </NewLocationContainer>
    </Scroll>
  );
};

export default NewLocationComponent;
