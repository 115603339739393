import styled, { keyframes } from "styled-components";

const skeletonLoading = keyframes`
  from {
    background-color: #dcc9cf;
  }
  to {

    background-color: #f3edef;

  }
`;
export const MainContainer = styled.div`
  background: rgb(255, 255, 255);
  min-height: 100vh;
  display: flex;
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  transition: all 0.2s ease-in-out;
  margin-top: ${(props) => (props.$upgradePackage ? "170px" : "110px")};
`;
export const Button = styled.button`
  border-radius: 5px;
  padding: 7px 15px 8.5px 15px;
  cursor: pointer;
  border: transparent;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  background: #8a4b5e;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  font-family: "Avenir-Medium";
  &:active {
    opacity: 0.75;
  }
`;
export const EmptyDataText = styled.span`
  font-family: "Avenir-Black";
  font-style: normal;

  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: #8a4b5e;
  cursor: text;
`;

export const EmptyDataContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SectionTitle = styled.h1`
  font-family: "Avenir-Black", sans-serif;
  font-size: 35px;
  line-height: 48px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  margin-top: 15px;
  @media (max-width: 550px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

export const CardProduct = styled.div`
  position: relative;
  gap: 10px;
  outline: 1px solid rgb(1, 1, 1, 0.15);
  width: 215px;
  height: 335px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  cursor: pointer;

  &:active {
    transition: all 0.2s ease-in;
    opacity: 0.8;
    transform: scale(0.98);
  }
`;

export const CardProductName = styled.span`
  font-family: "Avenir-Black";

  font-size: 18px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const ProductNameContainer = styled.div`
  display: flex;
  height: 120px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 15px 15px 20px 15px;
  margin-top: auto;
`;
export const ProductPrice = styled.span`
  font-family: "Avenir-Heavy";
  font-size: 26px;
  letter-spacing: -0.41px;
`;

export const Category = styled.span`
  // background-color: #545484;
  font-family: "Avenir-Medium";
  font-size: 18px;
  letter-spacing: -0.41px;
  color: #fff;
  padding: 5px 15px;
  width: fit-content;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    background-color: #e8dbdf33;
  }
`;

export const SeeAllText = styled.span`
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  color: #55a1e8;
  font-family: "Avenir-Medium";
  letter-spacing: -0.41px;
  &:hover {
    opacity: 0.6;
  }
`;

export const CategoryDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 35px;
  margin-left: 290px;
  @media (max-width: 870px) {
    margin-left: 40px;
    margin-top: 50px;
  }
`;
export const SectionDiv = styled.div`
  display: flex;
  padding-left: ${(props) => (props.$ar ? "0px" : "293px")};
  padding-right: ${(props) => (!props.$ar ? "0px" : "293px")};
  flex-direction: column;
  @media (max-width: 870px) {
    padding-left: ${(props) => (props.$ar ? "0px" : "40px")};
    padding-right: ${(props) => (!props.$ar ? "0px" : "40px")};
  }
`;
export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Title = styled.span`
  font-family: "Avenir-Black", sans-serif;
  font-size: 35px;
  letter-spacing: -0.177777px;

  color: #fff;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 20px 10px;
`;

export const CategoryBar = styled.div`
  position: fixed;

  margin-top: 75px;
  padding: 20px 10px 10px 20px;
  display: flex;
  align-items: flex-start;
  background: linear-gradient(360deg, #a87283 -100%, #8a4b5e 100%);
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.4);
  flex-direction: column;

  min-width: 250px;
  gap: 10px;
  z-index: 10;
  user-select: none;
  top: 0;
  bottom: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
  padding: ${(props) =>
    props.$upgradePackage ? "80px 10px 10px 20px" : "20px 10px 10px 20px"};
  @media (max-height: 1027px) {
    padding: ${(props) =>
      props.$upgradePackage ? "82px 22px 22px 22px" : "22px"};
  }
  @media (max-width: 870px) {
    display: none;
  }
`;
export const LoaderContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const CategoryList = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;
  padding: 10px 0px;
`;
export const Search = styled.input`
  padding: 8px;
  margin-bottom: 20px;
  border: unset;
  border-bottom: 1px solid #fff;
  padding-left: 30px;
  font-family: "Avenir-Medium";
  font-style: normal;

  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #fff;
  background: transparent;
  &:focus {
    outline: unset;
  }
  &::placeholder {
    font-family: "Avenir-Medium";
    font-style: normal;

    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.177777px;
    color: #c99ba9;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  &:hover {
    opacity: 0.6;
  }
`;
export const ButtonBack = styled.button`
  cursor: pointer;
  border: transparent;
  background: transparent;
  color: #8a4b5e;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Avenir-Medium";
  &:active {
    opacity: 0.75;
  }
`;
export const StepsContainer = styled.div`
  padding: ${(props) =>
    props.$ar ? "0px 290px 0px 40px" : "0px 40px 0px 290px"};
  margin-bottom: 4rem;
  @media (max-width: 870px) {
    padding: 0;
    margin-top: 4rem;
  }
`;

export const BackContainer = styled.button`
  padding: 12px 15px;
  background: #8a4b5e;
  border-radius: 15px;
  border: 0;
  color: #fff;
  font-family: "Avenir-Medium";
  font-size: 18px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: block;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: ${(props) => (props.$ar ? "unset" : "20px")};
    right: ${(props) => (!props.$ar ? "unset" : "20px")};
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &::after {
    width: 25px;
    height: 1px;
    background: white;
    transform: translateX(-4.5px);
    margin-top: 0px;
  }

  &::before {
    content: "";
    transform: rotate(-135deg) translateX(50%);
    width: 11px;
    height: 11px;
    background: transparent;
    border-left: ${(props) => (!props.$ar ? "none" : "1px solid white")};
    border-right: ${(props) => (props.$ar ? "none" : "1px solid white")};
    border-top: ${(props) => (props.$ar ? "unset" : "1px solid white")};
    border-bottom: ${(props) => (!props.$ar ? "unset" : "1px solid white")};
    margin-top: -1px;
  }

  &:hover {
    background: #d92525;
    padding: ${(props) =>
      !props.$ar ? "12px 15px 12px 60px" : "12px 60px 12px 15px"};
  }

  &:hover::after,
  &:hover::before {
    opacity: 1;
    right: 15px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  justify-content: flex-end;
  @media (max-width: 1205px) {
    gap: 10px;
  }
`;
export const FooterRow = styled.div`
  justify-content: space-between;
  display: flex;
  padding: ${(props) =>
    props.$ar ? "0rem 29rem 2rem 2rem" : "0rem 2rem 2rem 29rem"};
  align-items: center;
  flex-direction: row;
`;
export const StyledButton = styled.div`
  user-select: none;
  background: #8a4b5e;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: none;
  color: #fff;
  text-align: center;
  gap: 1rem;

  letter-spacing: 0.08px;
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);

  padding: 12px 15px;
  background: #8a4b5e;
  border-radius: 15px;
  border: 0;
  color: #fff;
  font-family: "Avenir-Medium";
  font-size: 18px;

  cursor: pointer;

  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
  }
  max-height: 48.5px;
`;
export const IconRotate = styled.div`
  transform: rotate(90deg);
  margin-right: -10px;
`;

export const ButtonContinue = styled.button`
  padding: 12px 15px;
  background: #8a4b5e;
  border-radius: 15px;
  border: 0;
  color: #fff;
  font-family: "Avenir-Medium";
  font-size: 18px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: block;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: ${(props) => (props.$ar ? "unset" : "20px")};
    left: ${(props) => (!props.$ar ? "unset" : "20px")};
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &::after {
    width: 25px;
    height: 1px;
    background: white;
    transform: translateX(-3px);
    margin-top: 0px;
  }

  &::before {
    content: "";
    transform: rotate(-135deg) translateX(50%);
    width: 11px;
    height: 11px;
    background: transparent;
    border-right: ${(props) => (!props.$ar ? "unset" : "1px solid white")};
    border-top: ${(props) => (!props.$ar ? "unset" : "1px solid white")};
    border-left: ${(props) => (props.$ar ? "unset" : "1px solid white")};
    border-bottom: ${(props) => (props.$ar ? "unset" : "1px solid white")};
    margin-top: -1px;
  }

  &:hover {
    background: #4ab639;
    padding: ${(props) =>
      props.$ar ? "12px 15px 12px 60px" : "12px 60px 12px 15px"};
  }

  &:hover::after,
  &:hover::before {
    opacity: 1;
    right: ${(props) => (props.$ar ? "unset" : "15px")};
    left: ${(props) => (props.$ar ? "15px" : "unset")};
  }
`;

export const BudgetContainer = styled(SectionDiv)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 36px;
`;

export const BudgetTitle = styled.span`
  font-family: "Avenir-Black", sans-serif;
  font-size: 28px;
  line-height: normal;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

export const BudgetInput = styled.input`
  display: flex;
  width: 240px;
  height: 50px;
  padding: 5px 7px;
  border: 1px solid #8c8c8c;
  border-radius: 15px;
  align-items: center;
  font-family: "Avenir-Medium", sans-serif;
  font-size: 18px;
  line-height: normal;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  margin-right: 10px;
  &:placeholder {
    color: rgba(69, 69, 132, 0.5);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  @media (max-width: 550px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

export const LoadingText = styled.div`
  width: 500px;
  border-radius: 10px;
  height: 16px;
  animation: ${skeletonLoading} 0.6s linear infinite alternate;
`;

export const HeaderWrapper = styled.div`
  padding: 0 60px 0px 40px;
  background: #8a4b5e;
  position: fixed;
  z-index: 1000;
  width: 100%;
`;
