import { createSlice } from "@reduxjs/toolkit";
import {
  addRecipientBuilder,
  deleteRecipientBuilder,
  editRecipientBuilder,
  getRecipientsBuilder,
  uploadRecipientsBuilder,
} from "./builder";

const recipientSlice = createSlice({
  name: "recipientInfo",
  initialState: {
    recipients: [],
    maxReached: false,
    perPage: "",
    total: "",
    loading: true,
    editLoading: false,
    addLoading: false,
    uploadLoading: false,
    deleteLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    getRecipientsBuilder(builder);
    addRecipientBuilder(builder);
    uploadRecipientsBuilder(builder);
    deleteRecipientBuilder(builder);
    editRecipientBuilder(builder);
  },
});

export const recipientsData = (state) => state.recipientInfo.recipients;
export const recipientsInfo = (state) => state.recipientInfo;
export const recipientsTotal = (state) => state.recipientInfo.total;
export const recipientsLoader = (state) => state.recipientInfo.loading;
export const deleteRecipientLoading = (state) =>
  state.recipientInfo.deleteLoading;
export const addRecipientLoading = (state) => state.recipientInfo.addLoading;
export const editRecipientLoading = (state) => state.recipientInfo.editLoading;
export const uploadRecipientLoading = (state) =>
  state.recipientInfo.uploadLoading;

export default recipientSlice.reducer;
