import React from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonRequest,
  ButtonUpgrade,
  Buttons,
  CloseIcon,
  WarningContainer,
  WarningMessage,
} from "./UpgradePackage.styles";
import { useNavigate } from "react-router-dom";
import { countClickAction } from "../../store/slices/common/asyncThunks";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  selectBusinessUserInfo,
  setState,
} from "../../store/slices/businessUser/slice";
import { selectedLocale } from "../../store/slices/common/slice";

const UpgradePackage = ({ show, expirationDate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useSelector(selectBusinessUserInfo);
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";
  if (show && expirationDate > 0 && state) {
    return (
      <WarningContainer>
        <Buttons>
          <WarningMessage>
            {t("your-free-package-has")} {expirationDate} {t("days-remaining")}
          </WarningMessage>
          <ButtonUpgrade
            onClick={() => {
              navigate("/sign-up/steps/upgrade");
            }}
          >
            {t("upgrade-package")}
          </ButtonUpgrade>
          <ButtonRequest
            onClick={() => {
              dispatch(countClickAction("demoRequest"));

              window.open("https://calendly.com/abdulkareem-2/demo", "_blank");
            }}
          >
            {t("request-demo")}
          </ButtonRequest>
        </Buttons>
        <CloseIcon onClick={() => dispatch(setState(false))} $ar={ar} />
      </WarningContainer>
    );
  }

  return <></>;
};

export default UpgradePackage;
