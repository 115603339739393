import { createAsyncThunk } from "@reduxjs/toolkit";
import companyService from "../../../api/services/company";

export const getCompanyUsers = createAsyncThunk(
  "company/users",
  async (requestData) => {
    return await companyService.getCompanyUsers(requestData);
  }
);

export const deleteCompanyBusinessUser = createAsyncThunk(
  "company/deleteCompanyBusinessUser",
  async (requestData) => {
    return await companyService.deleteCompanyBusinessUser(requestData);
  }
);
