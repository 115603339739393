import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../assets/icons/logo_purple.svg";
import { ReactComponent as LogoWhite } from "../../assets/icons/Logo2.svg";

import {
  HeaderContainer,
  Business,
  DropDiv,
  Row,
  Button,
  Text2,
  MenuContainer,
  CartCount,
  Dot,
  CartContainer,
  StyledNavLink,
  TestEnvContainer,
  CartIcon,
  FavoriteIcon,
  LogoIcon,
  Buttons,
  DotContainer,
  DotsIcon,
  CloseIcon,
  SearchContainer,
  SearchDropdownInput,
  SearchIcon,
  Test,
  StyledFullNameText,
  Div,
} from "./Header.style";

import { useSelector } from "react-redux";
import { selectBusinessUserInfo } from "../../store/slices/businessUser/slice";
import { useDispatch } from "react-redux";
import LocaleSelection from "../LocaleSelection/LocaleSelection";
import { ReactComponent as Menu } from "../../assets/icons/aaaa.svg";

import { countClickAction } from "../../store/slices/common/asyncThunks";
import {
  cartItemsData,
  categoriesData,
  categoriesLoading,
  selectFavoriteItems,
} from "../../store/slices/marketplace/marketplaceSlice";
import { FRONT_URL } from "../../api";
import { getCountFavoriteMarketplaceItems } from "../../store/slices/marketplace/asyncThunks";
import SmallScreensSideBarModal from "../Modal/SmallScreensSideBarModal";
import SkeletonMarketplaceSortLoader from "../SkeletonLoader/SkeletonMarketplaceSortLoader/SkeletonMarketplaceSortLoader";
import {
  SortContainer,
  SortName,
  StyledMenuContainer,
} from "../../screens/AdminPortal/MarketplacePage/MarketplacePage.styles";
import SmallScreensLeftSideBarModal from "../Modal/SmallScreensLeftSideBarModal";
import UpgradePackage from "../UpgradePackage/UpgradePackage";
import { selectedLocale } from "../../store/slices/common/slice";
import Icon from "../Icon";
import AppLoader from "../AppLoader";

const Header = ({
  showLogin,
  showSignUp,
  showNav,
  color,
  showCart,
  showFavorite,
  setModal,
  modal,
  hideSearch,
  showUser,
  onSearchPage,
  display,
}) => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { loggedIn, fullName, state, company } = useSelector(
    selectBusinessUserInfo
  );
  const favoriteData = useSelector(selectFavoriteItems);
  const items = useSelector(cartItemsData);
  const [show, setShow] = useState(false);
  const [openSidebarModal, setOpenSidebarModal] = useState(false);
  const [openSearchInput, setOpenSearchInput] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const path = location.pathname;
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("category");
  const searchTermQuery = queryParams.get("searchTerm");
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";
  const categories = useSelector(categoriesData);
  const categoriesPending = useSelector(categoriesLoading);

  const LogoComponent = color ? (
    <LogoWhite className="logo" height={40} />
  ) : (
    <Logo className="logo" height={40} />
  );

  const navigateTo = (route) => {
    navigation(route);
    setOpenSidebarModal(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && searchTerm !== "") {
      if (loggedIn) {
        navigation(`/admin-portal/search?searchTerm=${searchTerm}`);
      } else {
        navigation(`/search?searchTerm=${searchTerm}`);
      }
    }
  };

  useEffect(() => {
    if (openSearchInput) {
      document.addEventListener("keypress", handleKeyPress);
    }
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (!openSearchInput && onSearchPage) {
      setOpenSearchInput(true);
      setSearchTerm(searchTermQuery);
    }
  }, []);

  useEffect(() => {
    dispatch(getCountFavoriteMarketplaceItems());
  }, [dispatch]);

  const Notification1 = () => {
    if (items?.length > 0) {
      return (
        <Dot $ar={ar}>
          <CartCount>{items?.length}</CartCount>
        </Dot>
      );
    } else {
      return null;
    }
  };

  const Notification3 = () => {
    if (favoriteData.count > 0) {
      return (
        <Dot $ar={ar}>
          <CartCount>{favoriteData.count}</CartCount>
        </Dot>
      );
    } else {
      return null;
    }
  };

  const [x, setX] = useState(false);

  useEffect(() => {
    if (path.endsWith("/marketplace") || path.endsWith("/favorite")) {
      setX(true);
    } else {
      setX(false);
    }
  }, [path]);

  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  let pointerEvents =
    path.includes("admin-portal") && !display ? true : display;

  return (
    <>
      <HeaderContainer
        className="tab"
        $color={color}
        $upgradePackage={showUpgradeBar && diff > 0 && state}
        $loggedIn={loggedIn}
      >
        <UpgradePackage show={showUpgradeBar} expirationDate={diff} />

        {loggedIn && (
          <DotContainer $ar={ar}>
            <DotsIcon
              style={{ pointerEvents: pointerEvents ? "unset" : "none" }}
              onClick={() => {
                setShow(!show);
              }}
              fill="#fff"
              className="pointer"
            />
          </DotContainer>
        )}
        <Business>
          {FRONT_URL !== "https://portal.ygii.app" ? (
            <TestEnvContainer
              $loggedIn={loggedIn}
              className="maxWidth"
              $ar={ar}
            >
              <Test>TEST</Test>
              <div
                style={{ pointerEvents: pointerEvents ? "unset" : "none" }}
                className="divHome"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigateTo("/");
                }}
              >
                {LogoComponent}
              </div>
            </TestEnvContainer>
          ) : (
            <LogoIcon
              $loggedIn={loggedIn}
              className="divHome"
              style={{ pointerEvents: pointerEvents ? "unset" : "none" }}
              onClick={() => {
                window.scrollTo(0, 0);
                navigateTo("/");
              }}
            >
              {LogoComponent}
            </LogoIcon>
          )}
          {showNav && (
            <DropDiv>
              <StyledNavLink
                style={{ pointerEvents: pointerEvents ? "unset" : "none" }}
                $path={
                  path.endsWith("/upgrade") ||
                  path.endsWith("/upgrade/") ||
                  path.endsWith("/pricing")
                }
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(countClickAction("giftingAsAService"));
                }}
                className="navLink"
                to={loggedIn ? "/sign-up/steps/upgrade" : "/pricing"}
              >
                {t("gifting-as-a-service")}
              </StyledNavLink>
              <StyledNavLink
                $path={
                  path.endsWith("/marketplace") ||
                  path.includes("/marketplace/item-detail") ||
                  path.includes("/marketplace/")
                }
                className="navLink"
                style={{ pointerEvents: pointerEvents ? "unset" : "none" }}
                to="/marketplace"
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(countClickAction("marketplace"));
                }}
              >
                {t("marketplace")}
              </StyledNavLink>
            </DropDiv>
          )}
        </Business>

        {!loggedIn ? (
          <Row>
            {!hideSearch && (
              <Icon
                icon={
                  <SearchIcon
                    style={{ pointerEvents: pointerEvents ? "unset" : "none" }}
                    onClick={() => setOpenSearchInput(true)}
                  />
                }
                selectedLocale={ar}
              />
            )}
            {showCart && (
              <CartContainer
                style={{ pointerEvents: pointerEvents ? "unset" : "none" }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  if (loggedIn) {
                    navigation("/admin-portal/cart");
                  } else {
                    navigation("/cart");
                  }
                }}
              >
                <Icon icon={<CartIcon />} selectedLocale={ar} />
                <Notification1 />
              </CartContainer>
            )}
            <LocaleSelection color />
            {showLogin && showSignUp && (
              <Buttons $ar={ar}>
                {showLogin && (
                  <>
                    <Text2
                      $ar={ar}
                      $color={color}
                      style={{
                        marginLeft: "-1rem",
                        marginBottom: -5,
                        cursor: "default",
                      }}
                    >
                      |
                    </Text2>
                    <Text2
                      style={{
                        pointerEvents: pointerEvents ? "unset" : "none",
                      }}
                      $ar={ar}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigateTo("/login");
                      }}
                      $color={color}
                    >
                      {t("login")}
                    </Text2>
                  </>
                )}
                {showSignUp && (
                  <Button
                    style={{ pointerEvents: pointerEvents ? "unset" : "none" }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigateTo("/sign-up");
                    }}
                    $ar={ar}
                  >
                    {t("sign-up")}
                  </Button>
                )}
              </Buttons>
            )}
          </Row>
        ) : (
          <Row>
            {showUser && (
              <>
                <StyledFullNameText
                  style={{ pointerEvents: pointerEvents ? "unset" : "none" }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigateTo("/dashboard");
                  }}
                  $color
                >
                  {t("hello")}, {t(`${fullName}`)}
                </StyledFullNameText>
                <Text2
                  $color={color}
                  style={{
                    marginLeft: "-1rem",
                    marginBottom: -5,
                    cursor: "default",
                    marginRight: "-1rem",
                  }}
                >
                  |
                </Text2>
              </>
            )}
            {!hideSearch && (
              <CartContainer>
                <Icon
                  icon={
                    <SearchIcon
                      style={{
                        pointerEvents: pointerEvents ? "unset" : "none",
                      }}
                      onClick={() => setOpenSearchInput(true)}
                    />
                  }
                  selectedLocale={ar}
                />
              </CartContainer>
            )}
            {showFavorite && (
              <CartContainer
                style={{ pointerEvents: pointerEvents ? "unset" : "none" }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  if (loggedIn) {
                    navigation("/admin-portal/favorite");
                  } else {
                    if (modal) {
                      setModal(true);
                    }
                  }
                }}
              >
                <Icon icon={<FavoriteIcon />} selectedLocale={ar} />

                <Notification3 />
              </CartContainer>
            )}
            {showCart && (
              <CartContainer
                style={{ pointerEvents: pointerEvents ? "unset" : "none" }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  if (loggedIn) {
                    navigation("/admin-portal/cart");
                  } else {
                    navigation("/cart");
                  }
                }}
              >
                <Icon icon={<CartIcon />} selectedLocale={ar} />

                <Notification1 />
              </CartContainer>
            )}

            <LocaleSelection color />
          </Row>
        )}

        <MenuContainer $ar={ar}>
          <Menu
            style={{ pointerEvents: pointerEvents ? "unset" : "none" }}
            onClick={() => {
              setOpenSidebarModal(!openSidebarModal);
            }}
            className="pointer"
          />
        </MenuContainer>

        <SmallScreensSideBarModal
          ar={ar}
          open={openSidebarModal}
          close={() => {
            setOpenSidebarModal(false);
          }}
          setOpenSidebarModal={setOpenSidebarModal}
          openSidebarModal={openSidebarModal}
          Notification1={Notification1}
          Notification3={Notification3}
          loggedIn={loggedIn}
          hideSearch={hideSearch}
          showCart={showCart}
          showFavorite={showFavorite}
        />

        <SmallScreensLeftSideBarModal
          ar={ar}
          open={show}
          close={() => {
            setShow(false);
          }}
        />
      </HeaderContainer>

      <StyledMenuContainer
        $ar={ar}
        $loggedIn={loggedIn}
        $open={x}
        $upgradePackage={showUpgradeBar && diff > 0 && state}
      >
        {categoriesPending ? (
          <AppLoader size={24} light />
        ) : (
          categories?.categories?.length && (
            <SortContainer $open={x}>
              <SortName
                selected={null === category}
                style={{ pointerEvents: pointerEvents ? "unset" : "none" }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  if (loggedIn) {
                    navigation(`/admin-portal/marketplace`);
                  } else {
                    navigation(`/marketplace`);
                  }
                }}
              >
                {t("all")}
              </SortName>

              {categories?.categories.map((s) =>
                categoriesPending ? (
                  <SkeletonMarketplaceSortLoader key={s.id} />
                ) : (
                  <SortName
                    selected={s.id === +category}
                    key={s.id}
                    style={{ pointerEvents: pointerEvents ? "unset" : "none" }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      if (loggedIn) {
                        navigation(
                          `/admin-portal/marketplace?category=${s.id}`
                        );
                      } else {
                        navigation(`/marketplace?category=${s.id}`);
                      }
                    }}
                  >
                    {t(s.name)}
                  </SortName>
                )
              )}
            </SortContainer>
          )
        )}
      </StyledMenuContainer>
      <SearchContainer
        $ar={ar}
        $loggedIn={loggedIn}
        $open={openSearchInput}
        $upgradePackage={showUpgradeBar && diff > 0 && state}
      >
        <Div>
          <Icon
            icon={
              <SearchIcon
                style={{ pointerEvents: display ? "unset" : "none" }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  if (searchTerm) {
                    if (loggedIn) {
                      navigation(
                        `/admin-portal/search?searchTerm=${searchTerm}`
                      );
                    } else {
                      navigation(`/search?searchTerm=${searchTerm}`);
                    }
                  }
                }}
              />
            }
            selectedLocale={ar}
          />
          <SearchDropdownInput
            value={searchTerm}
            placeholder={t("search-anything")}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Div>
        <CloseIcon
          style={{ pointerEvents: display ? "unset" : "none" }}
          onClick={() => setOpenSearchInput(false)}
          $ar={ar}
        />
      </SearchContainer>
    </>
  );
};

export default Header;
