import styled from "styled-components";

export const MainContainer = styled.div`
  flex-direction: row;
  background: rgb(255, 255, 255);
  min-height: 100vh;
  display: flex;
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  margin-top: 110px;
  padding: ${(props) =>
    props.$ar ? "0px 280px 0px 40px" : "0px 40px 0px 280px"};

  @media (max-width: 870px) {
    padding: 0px 40px 0px 40px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin-top: 100px;
  @media (max-width: 690px) {
    flex-direction: column;
  }
`;

export const InfoTitle = styled.span`
  font-family: "Avenir-Heavy";
  font-size: 22px;
  letter-spacing: -0.41px;
  color: #8a4b5e;
`;

export const InfoText = styled.span`
  font-family: "Avenir-Medium";
  font-size: 18px;
  letter-spacing: -0.41px;
`;

export const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 45%;
  @media (max-width: 690px) {
    width: unset;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`;
export const PriceContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  @media (max-width: 1220px) {
    align-items: flex-start;

    flex-direction: column;
  }
`;

export const Button = styled.button`
  margin-left: 130px;
  color: white;
  text-transform: uppercase;
  font-family: "Avenir-Black";
  max-width: 200px;
  font-size: 14px;
  line-height: 30px;
  display: flex;
  align-items: center;

  justify-content: center;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 0.5em 1.5em;
  border: 1.5px solid #fff8;
  background: #8a4b5e;

  cursor: pointer;
  border-radius: 30px;
  &:hover {
    transition: all 0.2s ease-out;
    transform: scale(1.02);
  }
  &:active {
    transition: all 0.1s ease-in;
    transform: scale(0.97);
  }
  @media (max-width: 1220px) {
    margin-left: 0px;
  }
`;

export const ButtonDisabled = styled.button`
  margin-left: 130px;

  color: white;
  text-transform: uppercase;
  font-family: "Avenir-Black";

  font-size: 14px;
  line-height: 30px;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 0.5em 1.5em;
  border: 1.5px solid #fff8;
  background: "#e8dbdf";

  border-radius: 30px;
  cursor: not-allowed;
`;

export const LoaderDiv = styled.div`
  margin-top: 150px;
  @media (max-width: 870px) {
    margin-top: 150px;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
