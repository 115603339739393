import React, { useRef, useState } from "react";
import {
  Title,
  CloseContainer,
  Row,
  InputWrapper,
  Button,
  ButtonWrapper,
  Input,
  Text,
  Loading,
} from "./ShareCartProductModal.styles";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import Modal from "./Modal2";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { sendShareCartProductEmail } from "../../store/slices/marketplace/asyncThunks";
import { useSelector } from "react-redux";
import { loadingSendCartProductEmail } from "../../store/slices/marketplace/marketplaceSlice";
import openToast from "../../utils/toast";

const ShareCartProductModal = ({ isOpen, close, productId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const loading = useSelector(loadingSendCartProductEmail);
  const emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const toastId = useRef(null);

  const handleSend = () => {
    if (!email) {
      openToast(toastId, "share-cart-product", "error", t("email-is-required"));
      return;
    }
    if (!emailValidation.test(email)) {
      openToast(
        toastId,
        "share-cart-product",
        "error",
        t("invalid-email-format")
      );
      return;
    }
    dispatch(
      sendShareCartProductEmail({ marketplaceItemId: productId, email: email })
    )
      .unwrap()
      .then(() => {
        close();
        setEmail("");
        toast.success(t("email-successfully-sent"));
      });
  };

  return (
    <Modal
      open={isOpen}
      right
      width={"567px"}
      height={"100%"}
      padding={"50px 60px"}
      unset
    >
      <CloseContainer
        onClick={() => {
          close();
          setEmail("");
        }}
      >
        <CloseIcon fill="white" width={20} height={20} />
      </CloseContainer>
      <Title>{t("share-your-product")}</Title>
      <Row>
        <Text>{t("send-link-to-inbox")}</Text>
      </Row>
      <InputWrapper>
        <Input
          placeholder={t("your-email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </InputWrapper>
      <Button onClick={handleSend}>{!loading ? t("send") : <Loading />}</Button>
    </Modal>
  );
};

export default ShareCartProductModal;
