import React, { useEffect, useState } from "react";

import {
  MainContainer,
  Column,
  Graphic,
  Container,
  RightSide,
  LeftSide,
  EmojiContainer,
  CardEmployees,
  CardsEmploy,
  Title,
  Count,
  RightCard,
  WalletCard,
  RowCards,
  CardColumn,
  SmallTitle,
  TitleSection,
  Value,
  RowPrice,
  Price,
  HeaderCard,
  Number,
  MainColumn,
  HeaderCardLast,
  StyledRowPrice,
  StyledRow,
  ChartLabel,
  ChartLabelContainer,
  ChartContainer,
  ChartWrapper,
  Basic,
  CharRow,
  Essentials,
  Enterprise,
  HeaderWrapper,
} from "./AdminDashboardPage.styles";
import Sidebar from "../utils/Sidebar";
import { useTranslation } from "react-i18next";
import { ReactComponent as Gift } from "../../../assets/icons/gift.svg";
import AdminHeader from "../../../components/AdminHeader/AdminHeader";
import { useDispatch } from "react-redux";
import CountUp from "react-countup";
import { getAdminDashboardData } from "../../../store/slices/gift/asyncThunks";
import { useSelector } from "react-redux";
import {
  selectAdminDashboard,
  selectAdminDashboardLoading,
} from "../../../store/slices/gift/slice";
import { getCurrencySymbol } from "../../../utils/constants";
import BarChart from "../../../components/BarChart/BarChart";
import PieChart from "../../../components/PieChart/PieChart";

import Header from "../../../components/Header/Header";
import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";
import { selectedLocale } from "../../../store/slices/common/slice";
import AppLoader from "../../../components/AppLoader";

const AdminDashboardPage = () => {
  const { t } = useTranslation();
  const adminDashboard = useSelector(selectAdminDashboard);
  const [count, setCount] = useState(0);
  const [secondCount, setSecondCount] = useState(0);
  const dispatch = useDispatch();
  const adminDashboardLoading = useSelector(selectAdminDashboardLoading);

  const [marketplaceClicks, setMarketplaceClicks] = useState(0);
  const [iosDownloadClicks, setIosDownloadClicks] = useState(0);
  const [androidDownloadClicks, setAndroidDownloadClicks] = useState(0);
  const [startGiftingClicks, setStartGiftingClicks] = useState(0);
  const [demoRequestClicks, setDemoRequestClicks] = useState(0);
  const [giftingAsAServiceClicks, setGiftingAsAServiceClicks] = useState(0);

  const currentDate = new Date();
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < 423) {
        setCount(count + 1);
      } else {
        clearInterval(interval);
      }
    }, 0.1);

    return () => clearInterval(interval);
  }, [count]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondCount < 120) {
        setSecondCount(secondCount + 1);
      } else {
        clearInterval(interval);
      }
    }, 5);

    return () => clearInterval(interval);
  }, [secondCount]);

  useEffect(() => {
    dispatch(getAdminDashboardData());
  }, [dispatch]);

  const data = [
    {
      id: 1,
      title: t("employees"),
      count: adminDashboard.recipientCount,
      icon: "🧑‍💼👩‍💼",
      color: "rgba(236, 195, 113, 0.20)",
    },
    {
      id: 2,
      title: t("occasions"),
      count: 0,
      icon: "🥳",
      color: "rgba(133, 161, 172, 0.20)",
    },
    {
      id: 3,
      title: t("gifts.gifts"),
      count: adminDashboard.sendGiftOrders,
      icon: <Gift width={36} height={36} />,
      color: "rgba(233, 67, 94, 0.20)",
    },
    {
      id: 4,
      title: t("events"),
      count: 0,
      icon: "🎉",
      color: "rgba(125, 100, 96, 0.20)",
    },
  ];

  useEffect(() => {
    const marketplaceValue = Object.values(adminDashboard.clickCounts)
      .filter((click) => click.key === "marketplace")
      .map((click) => click.value)[0];
    const iosDownloadValue = Object.values(adminDashboard.clickCounts)
      .filter((click) => click.key === "iosDownload")
      .map((click) => click.value)[0];

    const androidDownloadValue = Object.values(adminDashboard.clickCounts)
      .filter((click) => click.key === "androidDownload")
      .map((click) => click.value)[0];

    const startGiftingValue = Object.values(adminDashboard.clickCounts)
      .filter((click) => click.key === "startGifting")
      .map((click) => click.value)[0];

    const demoRequestValue = Object.values(adminDashboard.clickCounts)
      .filter((click) => click.key === "demoRequest")
      .map((click) => click.value)[0];

    const giftingAsAServiceValue = Object.values(adminDashboard.clickCounts)
      .filter((click) => click.key === "giftingAsAService")
      .map((click) => click.value)[0];

    setMarketplaceClicks(marketplaceValue);
    setIosDownloadClicks(iosDownloadValue);
    setAndroidDownloadClicks(androidDownloadValue);
    setStartGiftingClicks(startGiftingValue);
    setDemoRequestClicks(demoRequestValue);
    setGiftingAsAServiceClicks(giftingAsAServiceValue);
  }, [adminDashboard]);

  const { company, state } = useSelector(selectBusinessUserInfo);

  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  const locale = useSelector(selectedLocale);
  const upgradePackage = showUpgradeBar && diff > 0 && state;
  const ar = locale === "ar";

  return (
    <MainContainer>
      <Sidebar />

      <HeaderWrapper>
        <Header
          showLogin
          showSignUp
          showNav
          color
          showFavorite={true}
          showCart
          showUser
        />
      </HeaderWrapper>
      <MainColumn>
        <Column $upgradePackage={upgradePackage} $ar={ar}>
          <AdminHeader hideSearch />
          <Container>
            <LeftSide>
              <CardsEmploy>
                {data.map((e) => (
                  <CardEmployees key={e.id}>
                    <EmojiContainer style={{ background: e.color }}>
                      {e.icon}
                    </EmojiContainer>
                    <Title>{t(`${e.title}`)}</Title>
                    <Count>
                      <CountUp end={e.count} duration={1.5} />
                    </Count>
                  </CardEmployees>
                ))}
              </CardsEmploy>
              <CardColumn>
                <TitleSection>{t("subscriptions")}</TitleSection>
                <Graphic>
                  {!adminDashboardLoading ? (
                    adminDashboard &&
                    adminDashboard.subscriptions && (
                      <BarChart adminDashboard={adminDashboard} />
                    )
                  ) : (
                    <AppLoader />
                  )}
                </Graphic>
              </CardColumn>
              <RowCards>
                <CardColumn>
                  <TitleSection>{t("wallets")}</TitleSection>
                  <WalletCard>
                    <SmallTitle>{t("current-balance")}</SmallTitle>
                    <StyledRowPrice>
                      <Value>
                        <CountUp
                          end={adminDashboard.totalWalletsBalance}
                          duration={1.5}
                          decimal=","
                          separator="."
                          decimals={2}
                        />
                      </Value>
                      <Price>{getCurrencySymbol("sar")}</Price>
                    </StyledRowPrice>
                  </WalletCard>
                </CardColumn>
                <CardColumn>
                  <TitleSection>{t("marketplace-items")}</TitleSection>
                  <WalletCard>
                    <SmallTitle>{t("#-of-items")}</SmallTitle>
                    <RowPrice>
                      <Value>
                        <CountUp
                          end={adminDashboard.marketplaceItemCount}
                          duration={1.5}
                        />
                      </Value>
                    </RowPrice>
                  </WalletCard>
                </CardColumn>
              </RowCards>
            </LeftSide>
            <RightSide>
              <RightCard>
                <HeaderCard>
                  <TitleSection>{t("subscriptions-packages")}</TitleSection>
                  <StyledRow>
                    {/* <LightText>{t("show")}</LightText>
                    <Month>{t("this-month")}</Month> */}
                  </StyledRow>
                </HeaderCard>
                <ChartContainer>
                  {!adminDashboardLoading ? (
                    <>
                      <ChartWrapper>
                        {adminDashboard && adminDashboard.subscriptions && (
                          <PieChart adminDashboard={adminDashboard} />
                        )}
                      </ChartWrapper>
                      <ChartLabelContainer>
                        <CharRow>
                          <Basic />
                          <ChartLabel>{t("basic")}</ChartLabel>
                        </CharRow>
                        <CharRow>
                          <Essentials />
                          <ChartLabel>{t("essentials")}</ChartLabel>
                        </CharRow>
                        <CharRow>
                          <Enterprise />
                          <ChartLabel>{t("enterprise")}</ChartLabel>
                        </CharRow>
                      </ChartLabelContainer>
                    </>
                  ) : (
                    <AppLoader />
                  )}
                </ChartContainer>
                <div>
                  <HeaderCard>
                    <TitleSection>
                      {t("gifting-as-a-service-clicks")}
                    </TitleSection>
                    <Number>
                      <CountUp end={giftingAsAServiceClicks} duration={1.5} />
                    </Number>
                  </HeaderCard>
                  <HeaderCard>
                    <TitleSection>{t("marketplace-clicks")}</TitleSection>
                    <Number>
                      <CountUp end={marketplaceClicks} duration={1.5} />
                    </Number>
                  </HeaderCard>
                  <HeaderCard>
                    <TitleSection>{t("demo-requests-clicks")}</TitleSection>
                    <Number>
                      <CountUp end={demoRequestClicks} duration={1.5} />
                    </Number>
                  </HeaderCard>
                  <HeaderCard>
                    <TitleSection>{t("start-gifting-clicks")}</TitleSection>
                    <Number>
                      <CountUp end={startGiftingClicks} duration={1.5} />
                    </Number>
                  </HeaderCard>
                  <HeaderCard>
                    <TitleSection>{t("ios-download-clicks")}</TitleSection>
                    <Number>
                      <CountUp end={iosDownloadClicks} duration={1.5} />
                    </Number>
                  </HeaderCard>
                  <HeaderCardLast>
                    <TitleSection>{t("android-download-clicks")}</TitleSection>
                    <Number>
                      <CountUp end={androidDownloadClicks} duration={1.5} />
                    </Number>
                  </HeaderCardLast>
                </div>
              </RightCard>
            </RightSide>
          </Container>
        </Column>
      </MainColumn>
    </MainContainer>
  );
};

export default AdminDashboardPage;
