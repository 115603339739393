import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  MainContainer,
  Title,
  ScreenContent,
  Form,
  InputWrapper,
  Input,
  ButtonContainer,
  SubmitButton,
  Subtitle,
  StyledMailIcon,
  StyledTextDiv,
  Message,
  SuccessImg,
} from "./ForgotPasswordPage.styles";
import {
  selectSendingResetPending,
  setEmailInput,
} from "../../../store/slices/auth/slice";
import { sendCodeForNewPassword } from "../../../store/slices/auth/asyncThunks";
import { toast } from "react-toastify";
import Success from "../../../assets/images/success.png";
import { ReactComponent as Loading } from "../../../assets/icons/loader-white.svg";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import openToast from "../../../utils/toast";
import { selectedLocale } from "../../../store/slices/common/slice";

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(selectSendingResetPending);
  const [tab, setTab] = useState(0);
  const toastId = useRef(null);

  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";

  const handleSendEmailWithCode = () => {
    dispatch(sendCodeForNewPassword(email))
      .unwrap()
      .then((response) => {
        toast.success(t("email-successfully-sent"));
        navigate("/login");
      })
      .catch((error) => {
        openToast(
          toastId,
          "forgot-password-email-send",
          "error",
          t(error.message)
        );
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendEmailWithCode();
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      {loading && <Loader></Loader>}
      <MainContainer>
        <Header showNav color hideSearch showSignUp showLogin display />

        <ScreenContent>
          <div>
            <Title>{t("welcome-to-ygii")}</Title>
            <StyledTextDiv>
              <Subtitle>{t("dont-have-an-account")}</Subtitle>

              <Link to="/sign-up" className="signup">
                {t("sign-up")}
              </Link>
            </StyledTextDiv>
            {tab === 0 && (
              <Form>
                <InputWrapper>
                  <StyledMailIcon className="login__icon" $ar={ar} />
                  <Input
                    $ar={ar}
                    type="text"
                    placeholder={t("email-address")}
                    name="email"
                    autoComplete="on"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputWrapper>
              </Form>
            )}
            {tab === 1 && (
              <Form>
                <Message>{t("an-email-sent-new-password")}</Message>
                <SuccessImg src={Success} alt="success-img" />
              </Form>
            )}
          </div>

          {tab === 0 &&
            (!loading ? (
              <ButtonContainer>
                <SubmitButton
                  onClick={() => {
                    handleSendEmailWithCode();
                    dispatch(setEmailInput(email));
                  }}
                >
                  {t("set-up-new-password")}
                </SubmitButton>
              </ButtonContainer>
            ) : (
              <ButtonContainer>
                <SubmitButton disabled>
                  <Loading height={30} />
                </SubmitButton>
              </ButtonContainer>
            ))}
          {tab === 1 && (
            <ButtonContainer>
              <SubmitButton
                onClick={() => {
                  setTab(0);
                }}
              >
                {t("log-in")}
              </SubmitButton>
            </ButtonContainer>
          )}
        </ScreenContent>
      </MainContainer>
      <Footer />
    </>
  );
};

export default ForgotPasswordPage;
