import React from "react";

import { TabContainer, Text } from "./AccountSettingTabs.styles";
import { useTranslation } from "react-i18next";

const TAB_GENERAL = "general";
const TAB_BILLING = "billing";
const TAB_MANAGE = "manage";
const TAB_CREDIT_CARDS = "creditCards";

const AccountSettingTabs = ({ tab, handleTab, setIsEditable }) => {
  const { t } = useTranslation();

  return (
    <TabContainer>
      <Text
        onClick={() => {
          handleTab(TAB_GENERAL);
          setIsEditable(false);
        }}
        style={{
          fontWeight: tab === TAB_GENERAL ? "800" : "500",
          textDecoration: tab === TAB_GENERAL ? "underline" : "none",
        }}
      >
        {t("general")}
      </Text>
      <Text
        onClick={() => {
          handleTab(TAB_BILLING);
          setIsEditable(false);
        }}
        style={{
          fontWeight: tab === TAB_BILLING ? "800" : "500",
          textDecoration: tab === TAB_BILLING ? "underline" : "none",
        }}
      >
        {t("billing-info")}
      </Text>
      <Text
        onClick={() => {
          handleTab(TAB_MANAGE);
          setIsEditable(false);
        }}
        style={{
          fontWeight: tab === TAB_MANAGE ? "800" : "500",
          textDecoration: tab === TAB_MANAGE ? "underline" : "none",
        }}
      >
        {t("manage-users")}
      </Text>
      <Text
        onClick={() => {
          handleTab(TAB_CREDIT_CARDS);
          setIsEditable(false);
        }}
        style={{
          fontWeight: tab === TAB_CREDIT_CARDS ? "800" : "500",
          textDecoration: tab === TAB_CREDIT_CARDS ? "underline" : "none",
        }}
      >
        {t("credit-cards")}
      </Text>
    </TabContainer>
  );
};

export default AccountSettingTabs;
