import { LOCAL_STORAGE_CART_ITEMS } from "../../../utils/constants";
import { checkEnvironment } from "../../../utils/utils";
import { generatePhotosWithLogo } from "../photoProcessing/asyncThunks";
import {
  getOrders,
  getOrder,
  getAllMarketplaceItems,
  getSubcategories,
  updateOrderState,
  getCategories,
  createItem,
  getMarketplaceItemById,
  getSubcategoriesList,
  deleteVariable,
  deleteSubcategory,
  deleteCategory,
  createVariable,
  createSubcategory,
  createCategory,
  editCategory,
  editSubcategory,
  editVariable,
  updateCategoryPriority,
  getAttributesByCategory,
  removeItem,
  editItem,
  getFavoriteMarketplaceItemsAll,
  toggleFavoriteMarketplaceItem,
  getCountFavoriteMarketplaceItems,
  sendShareCartProductEmail,
  getSuperAdminOrders,
  getOrderPackages,
} from "./asyncThunks";

export const getOrdersBuilder = (builder) => {
  builder
    .addCase(getOrders.pending, (state) => {
      state.getOrders.loading = true;
      state.getOrders.error = null;
    })
    .addCase(getOrders.fulfilled, (state, action) => {
      state.getOrders.data = action.payload.data;
      state.getOrders.maxReached = action.payload.maxReached;
      state.getOrders.perPage = action.payload.perPage;
      state.getOrders.total = action.payload.total;
      state.getOrders.loading = false;
    })
    .addCase(getOrders.rejected, (state, action) => {
      state.getOrders.loading = false;
      state.getOrders.error = action.error.message;
    });
};
export const getFavoriteMarketplaceItemsAllBuilder = (builder) => {
  builder
    .addCase(getFavoriteMarketplaceItemsAll.pending, (state) => {
      state.getFavoriteItems.loading = true;
      state.getFavoriteItems.error = null;
    })
    .addCase(getFavoriteMarketplaceItemsAll.fulfilled, (state, action) => {
      if (action.payload.page === 1) {
        state.getFavoriteItems.data = action.payload.data;
      } else {
        state.getFavoriteItems.data = [
          ...state.getFavoriteItems.data,
          ...action.payload.data,
        ];
      }

      state.getFavoriteItems.maxReached = action.payload.maxReached;
      state.getFavoriteItems.perPage = action.payload.perPage;
      state.getFavoriteItems.total = action.payload.total;
      state.getFavoriteItems.loading = false;
      state.getFavoriteItems.page = action.payload.page;
    })
    .addCase(getFavoriteMarketplaceItemsAll.rejected, (state, action) => {
      state.getFavoriteItems.loading = false;
      state.getFavoriteItems.error = action.error.message;
    });
};

export const getOrderBuilder = (builder) => {
  builder
    .addCase(getOrder.pending, (state) => {
      state.getOrder.loading = true;
      state.getOrder.error = null;
    })
    .addCase(getOrder.fulfilled, (state, action) => {
      state.getOrder.data = action.payload;
      state.getOrder.loading = false;
    })
    .addCase(getOrder.rejected, (state, action) => {
      state.getOrder.loading = false;
      state.getOrder.error = action.error.message;
    });
};

export const fetchAllMarketplaceItems = (builder) => {
  builder.addCase(getAllMarketplaceItems.pending, (state) => {
    state.marketplaceItems.loading = true;
  });
  builder.addCase(getAllMarketplaceItems.fulfilled, (state, action) => {
    state.marketplaceItems.loading = false;
    const { data, page, maxReached, total } = action.payload;
    state.marketplaceItems.data =
      page === 1 ? [...data] : [...state.marketplaceItems.data, ...data];

    state.marketplaceItems.maxReached = maxReached;
    state.marketplaceItems.page = page;
    state.marketplaceItems.total = total;
  });
  builder.addCase(getAllMarketplaceItems.rejected, (state) => {
    state.marketplaceItems.loading = false;
  });
};

export const getSubcategoriesBuilder = (builder) => {
  builder
    .addCase(getSubcategories.pending, (state) => {
      state.subcategories.loading = true;
      state.subcategories.error = null;
    })
    .addCase(getSubcategories.fulfilled, (state, action) => {
      state.subcategories.data = action.payload.data;
      state.subcategories.error = null;
      state.subcategories.loading = false;
    })
    .addCase(getSubcategories.rejected, (state, action) => {
      state.subcategories.loading = false;
      state.subcategories.error = action.error.message;
    });
};

export const getCategoriesBuilder = (builder) => {
  builder
    .addCase(getCategories.pending, (state) => {
      state.categories.loading = true;
      state.categories.error = null;
    })
    .addCase(getCategories.fulfilled, (state, action) => {
      state.categories.data = action.payload.data;
      state.categories.error = null;
      state.categories.loading = false;
    })
    .addCase(getCategories.rejected, (state, action) => {
      state.categories.loading = false;
      state.categories.error = action.error.message;
    });
};

export const updateOrderStateBuilder = (builder) => {
  builder
    .addCase(updateOrderState.pending, (state) => {
      state.getOrder.loading = true;
      state.getOrder.error = null;
    })
    .addCase(updateOrderState.fulfilled, (state, action) => {
      const { newState, paymentStatus, marketplaceOrderItems } =
        action.payload.data;

      state.getOrder.data.state = newState;
      state.getOrder.data.paymentStatus = paymentStatus;

      if (marketplaceOrderItems?.length > 0) {
        state.getOrder.data.marketplaceOrderItems.forEach((item) => {
          for (const marketplaceOrderItem of marketplaceOrderItems) {
            if (marketplaceOrderItem.id === item.id) {
              item.referenceId = marketplaceOrderItem.referenceId;
              item.voucherCode = marketplaceOrderItem.voucherCode;
              item.validTo = marketplaceOrderItem.validTo;
              item.pinCode = marketplaceOrderItem.pinCode;
            }
          }
        });
      }

      state.getOrder.loading = false;
    })
    .addCase(updateOrderState.rejected, (state, action) => {
      state.getOrder.loading = false;
      state.getOrder.error = action.error.message;
    });
};
export const createItemBuilder = (builder) => {
  builder
    .addCase(createItem.pending, (state) => {
      state.item.loading = true;
      state.item.error = null;
    })
    .addCase(createItem.fulfilled, (state, action) => {
      state.item.data = action.payload;
      state.item.loading = false;
    })
    .addCase(createItem.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });
};
export const toggleFavoriteMarketplaceItemBuilder = (builder) => {
  builder
    .addCase(toggleFavoriteMarketplaceItem.pending, (state, { meta }) => {
      const { like, marketplaceItemId } = meta.arg;

      state.getFavoriteItems.selectedItem.loading = true;
      state.getFavoriteItems.selectedItem.error = null;

      const updatedData = state.marketplaceItems.data.map((item) => {
        if (item.id === marketplaceItemId) {
          return { ...item, favorite: like };
        }
        return item;
      });

      const updatedCartData = state.cartItems.map((item) => {
        if (item.id === marketplaceItemId) {
          return { ...item, favorite: like };
        }
        return item;
      });

      state.getFavoriteItems.count += like ? 1 : -1;
      state.marketplaceItems.data = updatedData;
      state.cartItems = updatedCartData;
    })

    .addCase(toggleFavoriteMarketplaceItem.fulfilled, (state, action) => {
      const { marketplaceItemId } = action.payload;
      state.getFavoriteItems.selectedItem.data = action.payload;
      state.getFavoriteItems.data = state.getFavoriteItems.data.filter(
        (item) => item.id !== marketplaceItemId
      );

      state.getFavoriteItems.selectedItem.loading = false;
    })
    .addCase(toggleFavoriteMarketplaceItem.rejected, (state, action) => {
      state.getFavoriteItems.selectedItem.loading = false;
      state.getFavoriteItems.selectedItem.error = action.error.message;
    });
};

export const getCountFavoriteMarketplaceItemsBuilder = (builder) => {
  builder
    .addCase(getCountFavoriteMarketplaceItems.pending, (state) => {
      state.getFavoriteItems.countLoading = true;
      state.getFavoriteItems.countError = null;
    })
    .addCase(getCountFavoriteMarketplaceItems.fulfilled, (state, action) => {
      state.getFavoriteItems.count = action.payload.count;
      state.getFavoriteItems.countLoading = false;
    })
    .addCase(getCountFavoriteMarketplaceItems.rejected, (state, action) => {
      state.getFavoriteItems.countLoading = false;
      state.getFavoriteItems.countError = action.error.message;
    });
};

export const deleteItemBuilder = (builder) => {
  builder.addCase(removeItem.pending, (state) => {
    state.deleteMarketplaceItemPending = true;
  });
  builder.addCase(removeItem.fulfilled, (state, action) => {
    const { itemId } = action.payload;
    state.marketplaceItems.data = state.marketplaceItems?.data?.filter(
      (item) => item.id !== itemId
    );
    state.deleteMarketplaceItemPending = false;
  });
  builder.addCase(removeItem.rejected, (state) => {
    state.deleteMarketplaceItemPending = false;
  });
};

export const getMarketplaceItemByIdBuilder = (builder) => {
  builder
    .addCase(getMarketplaceItemById.pending, (state) => {
      state.selectedItem.loading = true;
    })
    .addCase(getMarketplaceItemById.fulfilled, (state, action) => {
      const item = action.payload.data;
      state.selectedItem.loading = false;
      state.selectedItem.data = item;
      state.addItem.description = item.description;
      state.addItem.descriptionAr = item.descriptionAr;
      state.addItem.favorite = item.favorite;
      state.addItem.price = item.price;
      state.addItem.name = item.name;
      state.addItem.nameAr = item.nameAr;
      state.addItem.photos = item.photos;
      state.addItem.categoryId = item?.category.map(
        (el) => el.marketplaceCategory?.id
      );
      state.addItem.displayPhoto = 0;

      state.addItem.printedPrice = item.printedPrice;
      state.addItem.minQuantity = item.minQuantity;
      state.addItem.uploadDesign = item.uploadDesign;
      state.addItem.designWithoutColor = item.designWithoutColor;
      state.addItem.priceBreaks = item.priceBreaks;
      state.addItem.editItemSubcategories = item.subcategories;

      state.addItem.userCanUploadFile = !!item.uploadDesign;
      state.addItem.productionTime = item.productionTime ?? 15;
      state.addItem.isMinOrderQty = !!item.minQuantity;
      state.addItem.minOrderQty = item.minQuantity;
      state.addItem.buySample = !!item.samplePrice;
      state.addItem.blankPrice = item.samplePrice;
      state.addItem.buyPrintedSample = !!item.printedPrice;
      state.addItem.printPrice = item.printedPrice;
      state.addItem.priceBrake = !!item.priceBreaks?.length;
      state.addItem.additionalPrices = item.priceBreaks?.length
        ? item.priceBreaks
        : [{ minQuantity: "", discount: "" }];

      let tmpPrintAreas = {};

      item.photos.forEach((photo, index) => {
        const printArea = photo.itemLogoPrintAreas[0];
        if (printArea) {
          tmpPrintAreas[index] = {
            top: printArea.top,
            left: printArea.left,
            width: printArea.width,
            height: printArea.height,
          };
        }
      });

      state.addItem.printAreas = tmpPrintAreas;
    })
    .addCase(getMarketplaceItemById.rejected, (state, action) => {
      state.selectedItem.loading = false;
    });
};

export const getSubcategoriesListBuilder = (builder) => {
  builder
    .addCase(getSubcategoriesList.pending, (state) => {
      state.subcategoriesList.loading = true;
      state.subcategoriesList.error = null;
    })
    .addCase(getSubcategoriesList.fulfilled, (state, action) => {
      state.subcategoriesList.data = action.payload;
      state.subcategoriesList.error = null;
      state.subcategoriesList.loading = false;
    })
    .addCase(getSubcategoriesList.rejected, (state, action) => {
      state.subcategoriesList.loading = false;
      state.subcategoriesList.error = action.error.message;
    });
};

export const deleteVariableBuilder = (builder) => {
  builder
    .addCase(deleteVariable.pending, (state) => {
      state.deleteVariable.loading = true;
      state.deleteVariable.error = null;
    })
    .addCase(deleteVariable.fulfilled, (state, action) => {
      const { subcategoryItemId } = action.payload;

      const updatedData = state.subcategoriesList.data.map((category) => {
        category.marketplaceSubcategories =
          category.marketplaceSubcategories.map((subcat) => {
            subcat.marketplaceSubcategoryItems =
              subcat.marketplaceSubcategoryItems.filter(
                (item) => item.id !== subcategoryItemId
              );
            return subcat;
          });
        return category;
      });

      state.subcategoriesList.data = updatedData;
      state.deleteVariable.error = null;
      state.deleteVariable.loading = false;
    })
    .addCase(deleteVariable.rejected, (state, action) => {
      state.deleteVariable.loading = false;
      state.deleteVariable.error = action.error.message;
    });
};

export const deleteSubcategoryBuilder = (builder) => {
  builder
    .addCase(deleteSubcategory.pending, (state) => {
      state.deleteSubcategory.loading = true;
      state.deleteSubcategory.error = null;
    })
    .addCase(deleteSubcategory.fulfilled, (state, action) => {
      const { subcategoryId } = action.payload;

      const updatedData = state.subcategoriesList.data.map((category) => {
        category.marketplaceSubcategories =
          category.marketplaceSubcategories.filter(
            (subcategory) => subcategory.id !== subcategoryId
          );
        return category;
      });

      state.subcategoriesList.data = updatedData;
      state.deleteSubcategory.loading = false;
    })
    .addCase(deleteSubcategory.rejected, (state, action) => {
      state.deleteSubcategory.loading = false;
      state.deleteSubcategory.error = action.error.message;
    });
};

export const deleteCategoryBuilder = (builder) => {
  builder
    .addCase(deleteCategory.pending, (state) => {
      state.deleteCategory.loading = true;
      state.deleteCategory.error = null;
    })
    .addCase(deleteCategory.fulfilled, (state, action) => {
      const { categoryId } = action.payload;
      const updatedData = {
        ...state.categories.data,
        categories: state.categories?.data?.categories?.filter(
          (category) => category.id !== categoryId
        ),
      };
      state.categories.data = updatedData;
      state.deleteCategory.error = null;
      state.deleteCategory.loading = false;
    })
    .addCase(deleteCategory.rejected, (state, action) => {
      state.deleteCategory.loading = false;
      state.deleteCategory.error = action.error.message;
    });
};

export const createVariableBuilder = (builder) => {
  builder
    .addCase(createVariable.pending, (state) => {
      state.createVariable.loading = true;
      state.createVariable.error = null;
    })
    .addCase(createVariable.fulfilled, (state, action) => {
      const subcategoryItem = action.payload;

      const updatedData = state.subcategoriesList.data.map((category) => {
        return {
          ...category,
          marketplaceSubcategories: category.marketplaceSubcategories.map(
            (subcat) => {
              if (subcat.id === subcategoryItem.marketplaceSubcategoryId) {
                if (
                  !subcat.marketplaceSubcategoryItems.some(
                    (item) => item.id === subcategoryItem.id
                  )
                ) {
                  return {
                    ...subcat,
                    marketplaceSubcategoryItems: [
                      ...subcat.marketplaceSubcategoryItems,
                      subcategoryItem,
                    ],
                  };
                }
              }
              return subcat;
            }
          ),
        };
      });

      state.subcategoriesList.data = updatedData;
      state.createVariable.error = null;
      state.createVariable.loading = false;
    })
    .addCase(createVariable.rejected, (state, action) => {
      state.createVariable.loading = false;
      state.createVariable.error = action.error.message;
    });
};

export const createSubcategoryBuilder = (builder) => {
  builder
    .addCase(createSubcategory.pending, (state) => {
      state.createSubcategory.loading = true;
      state.createSubcategory.error = null;
    })
    .addCase(createSubcategory.fulfilled, (state, action) => {
      const subcategory = action.payload.data;

      const updatedData = state.subcategoriesList.data.map((category) => {
        if (category.id === subcategory.marketplaceCategoryId) {
          category.marketplaceSubcategories.push({
            ...subcategory,
            marketplaceSubcategoryItems: [],
          });
        }
        return category;
      });

      state.subcategoriesList.data = updatedData;
      state.createSubcategory.error = null;
      state.createSubcategory.loading = false;
    })
    .addCase(createSubcategory.rejected, (state, action) => {
      state.createSubcategory.loading = false;
      state.createSubcategory.error = action.error.message;
    });
};

export const createCategoryBuilder = (builder) => {
  builder
    .addCase(createCategory.pending, (state) => {
      state.createCategory.loading = true;
      state.createCategory.error = null;
    })
    .addCase(createCategory.fulfilled, (state, action) => {
      const cat = action.payload.data;
      state.categories.data.categories.push(cat);
      state.createCategory.error = null;
      state.createCategory.loading = false;
    })
    .addCase(createCategory.rejected, (state, action) => {
      state.createCategory.loading = false;
      state.createCategory.error = action.error.message;
    });
};

export const editCategoryBuilder = (builder) => {
  builder
    .addCase(editCategory.pending, (state) => {
      state.editCategory.loading = true;
      state.editCategory.error = null;
    })
    .addCase(editCategory.fulfilled, (state, action) => {
      const { id, name } = action.payload;
      const updatedCategories = state.categories.data.categories.map(
        (category) => {
          if (category.id === id) {
            return { ...category, name };
          }
          return category;
        }
      );

      state.categories.data.categories = updatedCategories;
      state.editCategory.error = null;
      state.editCategory.loading = false;
    })
    .addCase(editCategory.rejected, (state, action) => {
      state.editCategory.loading = false;
      state.editCategory.error = action.error.message;
    });
};

export const editSubcategoryBuilder = (builder) => {
  builder
    .addCase(editSubcategory.pending, (state) => {
      state.editSubcategory.loading = true;
      state.editSubcategory.error = null;
    })
    .addCase(editSubcategory.fulfilled, (state, action) => {
      const { id, value } = action.payload;

      const updatedData = state.subcategoriesList.data.map((category) => {
        category.marketplaceSubcategories =
          category.marketplaceSubcategories.map((subcategory) => {
            if (subcategory.id === id) {
              return { ...subcategory, value: value };
            }
            return subcategory;
          });
        return category;
      });

      state.subcategoriesList.data = updatedData;
      state.editSubcategory.error = null;
      state.editSubcategory.loading = false;
    })
    .addCase(editSubcategory.rejected, (state, action) => {
      state.editSubcategory.loading = false;
      state.editSubcategory.error = action.error.message;
    });
};

export const editVariableBuilder = (builder) => {
  builder
    .addCase(editVariable.pending, (state) => {
      state.editVariable.loading = true;
      state.editVariable.error = null;
    })
    .addCase(editVariable.fulfilled, (state, action) => {
      const { id, value } = action.payload;

      const updatedData = state.subcategoriesList.data.map((category) => {
        return {
          ...category,
          marketplaceSubcategories: category.marketplaceSubcategories.map(
            (subcat) => {
              return {
                ...subcat,
                marketplaceSubcategoryItems:
                  subcat.marketplaceSubcategoryItems.map((item) => {
                    if (item.id === id) {
                      return {
                        ...item,
                        value: value,
                      };
                    }
                    return item;
                  }),
              };
            }
          ),
        };
      });

      state.subcategoriesList.data = updatedData;
      state.editVariable.error = null;
      state.editVariable.loading = false;
    })
    .addCase(editVariable.rejected, (state, action) => {
      state.editVariable.loading = false;
      state.editVariable.error = action.error.message;
    });
};

export const updateCategoryPriorityBuilder = (builder) => {
  builder
    .addCase(updateCategoryPriority.pending, (state) => {
      state.updateCategoryPriority.loading = true;
      state.updateCategoryPriority.error = null;
    })
    .addCase(updateCategoryPriority.fulfilled, (state, action) => {
      const updatedCategories = action.payload;

      state.categories.data.categories = updatedCategories;
      state.updateCategoryPriority.error = null;
      state.updateCategoryPriority.loading = false;
    })
    .addCase(updateCategoryPriority.rejected, (state, action) => {
      state.updateCategoryPriority.loading = false;
      state.updateCategoryPriority.error = action.error.message;
    });
};

export const getAttributesByCategoryBuilder = (builder) => {
  builder
    .addCase(getAttributesByCategory.pending, (state) => {
      state.getAttributesByCategory.loading = true;
    })
    .addCase(getAttributesByCategory.fulfilled, (state, action) => {
      state.getAttributesByCategory.data = action.payload;
      state.getAttributesByCategory.loading = false;

      //   for (const attributeKey of Object.keys(
      //     state.addItem.editItemSubcategories
      //   )) {
      //     const attribute = state.addItem.editItemSubcategories[attributeKey];
      //     for (const subcategory of action.payload) {
      //       if (subcategory.value === attribute[0].subcategoryName) {
      //         const variableName = attribute.map((el) => el.value);

      //         let variables = [];
      //         for (const subcategoryItem of subcategory.marketplaceSubcategoryItems) {
      //           if (variableName.includes(subcategoryItem.value)) {
      //             const att = attribute.filter(
      //               (el) => el.value === subcategoryItem.value
      //             );
      //             let photo = null;
      //             console.log(JSON.stringify(att));
      //             if (!!att[0].photos.length) {
      //               photo = att[0].photos[0].url;
      //             }

      //             if (photo) {
      //               variables.push({
      //                 id: subcategoryItem.id,
      //                 value: {
      //                   photo,
      //                   value: subcategoryItem.value,
      //                   photoIndex: state.addItem.photos.findIndex(
      //                     (el) => el.id === att[0].photos[0].id
      //                   ),
      //                 },
      //               });
      //             } else {
      //               variables.push({
      //                 id: subcategoryItem.id,
      //               });
      //             }
      //           }
      //         }

      //         state.addItem.attributes = [
      //           ...state.addItem.attributes,
      //           { ...subcategory, variables },
      //         ];
      //       }
      //     }
      //   }

      state.addItem.editItemSubcategories = {};
    })
    .addCase(getAttributesByCategory.rejected, (state, action) => {
      state.getAttributesByCategory.loading = false;
    });
};

export const editItemBuilder = (builder) => {
  builder
    .addCase(editItem.pending, (state) => {
      state.editItem.loading = true;
    })
    .addCase(editItem.fulfilled, (state, action) => {
      state.editItem.loading = false;
    })
    .addCase(editItem.rejected, (state, action) => {
      state.editItem.loading = false;
    });
};

export const shareCartProductBuilder = (builder) => {
  builder
    .addCase(sendShareCartProductEmail.pending, (state) => {
      state.sendCartProductEmail.loading = true;
    })
    .addCase(sendShareCartProductEmail.fulfilled, (state) => {
      state.sendCartProductEmail.loading = false;
    })
    .addCase(sendShareCartProductEmail.rejected, (state) => {
      state.sendCartProductEmail.loading = false;
    });
};

export const getSuperAdminOrdersBuilder = (builder) => {
  builder
    .addCase(getSuperAdminOrders.pending, (state) => {
      state.getSuperAdminOrders.loading = true;
      state.getSuperAdminOrders.error = null;
    })
    .addCase(getSuperAdminOrders.fulfilled, (state, action) => {
      state.getSuperAdminOrders.data = action.payload.data;
      state.getSuperAdminOrders.maxReached = action.payload.maxReached;
      state.getSuperAdminOrders.perPage = action.payload.perPage;
      state.getSuperAdminOrders.total = action.payload.total;
      state.getSuperAdminOrders.loading = false;
    })
    .addCase(getSuperAdminOrders.rejected, (state, action) => {
      state.getSuperAdminOrders.loading = false;
      state.getSuperAdminOrders.error = action.error.message;
    });
};

export const generatePhotosWithLogoBuilder = (builder) => {
  builder.addCase(generatePhotosWithLogo.pending, (state) => {
    state.generatePhotosWithLogoPending = true;
  });
  builder.addCase(generatePhotosWithLogo.fulfilled, (state, action) => {
    const { data, cartItemId } = action.payload;

    const isFirstPhoto = data.find((p) => p.indexPhoto === 0);

    state.cartItems = state.cartItems.map((item) => {
      if (item.cartItemId === cartItemId) {
        if (isFirstPhoto) {
          return { ...item, photo: isFirstPhoto, photosWithLogo: data };
        } else {
          return { ...item, photosWithLogo: data };
        }
      }
      return item;
    });

    const environment = checkEnvironment();
    const formattedData = {
      // businessUserId: businessUserId,
      cartItems: JSON.stringify(state.cartItems),
      environment,
    };

    localStorage.setItem(
      LOCAL_STORAGE_CART_ITEMS,
      JSON.stringify(formattedData)
    );

    state.generatePhotosWithLogoPending = false;
  });
  builder.addCase(generatePhotosWithLogo.rejected, (state, action) => {
    state.generatePhotosWithLogoPending = false;
  });
};

export const getOrderPackagesBuilder = (builder) => {
  builder.addCase(getOrderPackages.pending, (state) => {
    state.orderPackages.loading = true;
  });
  builder.addCase(getOrderPackages.fulfilled, (state, action) => {
    state.orderPackages.data = action.payload.data;
    state.orderPackages.loading = false;
  });
  builder.addCase(getOrderPackages.rejected, (state, action) => {
    state.orderPackages.loading = false;
  });
};
