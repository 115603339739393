import styled from "styled-components";

export const ButtonStyle = styled.button`
  border-radius: 30px;
  user-select: none;
  outline: none;
  background: #8a4b5e;
  padding: 10px 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: none;
  color: #fff;
  text-align: center;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
  }
  @media (max-width: 990px) {
    font-size: 16px;
  }
  @media (max-width: 952px) {
    font-size: 14px;
  }
  @media (max-width: 915px) {
    font-size: 12px;
  }
  @media (max-width: 795px) {
    font-size: 11px;
  }
`;
