import { styled } from "styled-components";
export const TitleTotal = styled.span`
  color: #7f7f7f;
  text-align: center;
  user-select: none;

  font-family: "Avenir-Medium";
  font-size: 2rem;
`;
export const Title = styled.span`
  color: #8a4b5e;
  font-family: "FrankfurterEF-Medium";
  font-size: 4.5rem;
  font-weight: 400;
  user-select: none;
  align-self: center;
  letter-spacing: -0.178px;

  text-align: center;
`;
export const Text = styled(Title)`
  font-family: "Avenir";
  font-size: 20px;
  margin-top: -30px;
`;

export const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
`;
export const Link = styled(Text)`
  color: #55a1e8;
  user-select: text;
  margin-top: 0;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 90%;
  padding: 0px 20rem;
`;

export const Button = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  height: 4rem;
  border-radius: 15px;
  padding: 0.5rem 2rem;
  background: #8a4b5e;
  user-select: none;
  color: #fff;
  text-align: center;
  font-family: "FrankfurterEF-Medium";
  font-size: 2.2rem;
  letter-spacing: -0.178px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
export const StyledButton = styled(Button)`
  border: 1px solid #8a4b5e;
  background: #fff;
  color: #8a4b5e;
`;
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  width: 100%;
  justify-content: center;
  @media (max-width: 412px) {
    flex-direction: column;
  }
`;

export const Success = styled.img`
  user-select: none;
  background-color: transparent;
`;
export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 660px) {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  align-content: center;
  gap: 24px;
`;

export const SubTitle = styled.span`
  color: #8a4b5e;
  font-family: "Avenir-Light";
  font-size: 2.6rem;

  letter-spacing: -0.178px;
`;

export const Input = styled.input`
  border-radius: 1.5rem;
  border: 1px solid #0008;
  background: #fff;
  padding: 22px 1rem;
  width: 41rem;
  font-size: 1.6rem;
  text-transform: capitalize;
  cursor: text;

  &:focus {
    background-color: #fff;
    border: 1px solid #8a4b5e;
  }
  font-family: "Avenir-Medium";
  &::placeholder {
    font-family: "Avenir-Medium";
    letter-spacing: -0.178px;
    color: #666;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const MainContainer = styled.div`
  position: relative;
  z-index: 0;
  min-height: ${(props) => (props.$height ? "100vh" : "100vh")};
  padding-bottom: 4rem;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #f6f6f9;
`;
export const PhoneInputWrapper = styled.div`
  width: 41rem;
`;
export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10rem;

  justify-content: center;
  @media (max-width: 1442px) {
    flex-direction: column;
    width: 90%;
    align-items: center;
  }
`;
export const PaymentDiv = styled.div`
  flex-direction: row;
  gap: 10px;
  display: flex;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -70px;
  }
`;
export const ColumnDiv = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  width: 100%;
  @media (max-width: 500px) {
    width: 90%;
  }
`;
export const ScreenContent = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;
  border: 1px solid #b1b1b1;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(1, 0, 1, 0.1);
  border-radius: 3rem;
  padding: 3rem;
  background: #fff;
  /* align-items: center; */
  /* justify-content: space-between; */

  gap: 10rem;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  height: fit-content;

  @media (max-width: 1442px) {
    height: fit-content;
  }
`;
export const HeaderWrapper = styled.div`
  z-index: 501;
  background: #8a4b5e;
  width: 100%;
  position: fixed;
`;

export const Screen = styled.div`
  padding: ${(props) =>
    props.$loggedIn && props.$ar
      ? "4rem 29rem 4rem 4rem"
      : props.$loggedIn && !props.$ar
      ? "4rem 4rem 4rem 29rem"
      : "4rem"};

  position: relative;
  flex-direction: column;
  height: fit-content;
  height: 100%;
  width: ${(props) => (props.$loggedIn ? "100%" : "1440px")};
  margin-top: ${(props) => (props.$upgradePackage ? "3rem" : "0rem")};
  @media (max-width: 1440px) {
    width: 100%;
  }
  @media (max-width: 1200px) {
    padding: ${(props) =>
      props.$loggedIn && props.$ar
        ? "4rem 32rem 4rem 4rem"
        : props.$loggedIn && !props.$ar
        ? "4rem 4rem 4rem 32rem"
        : "4rem"};
  }
  @media (max-width: 870px) {
    padding: 4rem;
    margin-top: 5rem;
  }
`;

export const StyledTitle = styled.span`
  justify-content: center;
  display: flex;
  user-select: none;
  flex-wrap: wrap;
  font-family: "FrankfurterEF-Medium", sans-serif;
  font-size: 4.8rem;
  line-height: 5.5rem;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
`;

export const Separator = styled.div`
  justify-content: center;
  display: flex;
  height: 50rem;
  width: 1px;
  background-color: #f0f0f7;
  @media (max-width: 1442px) {
    display: none;
  }
`;
export const StyledSeparator = styled.div`
  height: 2px;
  display: flex;
  background-color: #e8e8e8;
`;
export const MapContainer = styled.div`
  margin-left: 40px;
`;

export const SearchBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 870px) {
    justify-content: center;
  }
`;

export const TextArea = styled.textarea`
  resize: none;
  height: 150px;
  width: 100%;
  border-radius: 1.5rem;
  border: 1px solid #0008;
  padding: 12px 8px 12px 8px;
  background: #fff;
  color: #000;
  font-family: "Avenir-Medium";
  font-size: 1.6rem;
  line-height: normal;
  letter-spacing: -0.178px;

  &:focus {
    background-color: #fff;
    border: 1px solid #8a4b5e;
    outline: none;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
    font-family: "Avenir-Medium";
    font-size: 1.6rem;
    line-height: normal;
    letter-spacing: -0.178px;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const LoginText = styled.span`
  color: #8a4b5e;
  text-align: center;
  opacity: 0.7;
  font-size: 16px;
  font-family: "Avenir-Medium";
  user-select: none;
  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

export const LoginTextDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;
