import React, { Fragment } from "react";
import {
  ExpandableTableHeaderItem,
  PaginationContainer,
  StyledTable,
  TableBody,
  TableBodyItemEmpty,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderItem,
  TableHeaderRow,
  TableWrapper,
} from "./Table.styles";
import Pagination from "./Pagination/Pagination";
import { ORDER } from "../../utils/constants";
import MiniLoader from "../MiniLoader/MiniLoader";
import InfiniteScroll from "react-infinite-scroll-component";

import { useSelector } from "react-redux";
import { selectedLocale } from "../../store/slices/common/slice";
import AppLoader from "../AppLoader";

const Table = ({
  currentPage,
  totalCount,
  perPage,
  bigHeaderWidth,
  setPage,
  children,
  marginTop,
  headerInformation,
  sortOptions,
  setSortOptions,
  noDataContent,
  lastColumnAligned,
  expandable,
  height,
  dataLoading,
  width,
  minWidth,
  maxHeight,
  scroll,
  centerText,
  flex,
  infinityScroll,
  loadMoreItems,
  dataLength,
  maxReached,
  param,
}) => {
  // const onSort = key => {
  //   if (sortOptions.order === ORDER.ASC && !sortOptions.sortBy) {
  //     setSortOptions({ ...sortOptions, sortBy: key });
  //   } else if (key !== sortOptions.sortBy) {
  //     setSortOptions({ sortBy: key, order: ORDER.ASC });
  //   } else if (sortOptions.order === ORDER.ASC && sortOptions.sortBy) {
  //     setSortOptions({ sortBy: key, order: ORDER.DESC });
  //   } else {
  //     setSortOptions({ sortBy: '', order: ORDER.ASC });
  //   }
  // };
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";

  return infinityScroll ? (
    <TableContainer
      style={{
        marginTop: marginTop,
      }}
    >
      <TableWrapper
        $empty={!children?.length}
        style={{
          width: width,
          minHeight: height,
          maxHeight: maxHeight,
          overflow: scroll,
          minWidth: minWidth,
          flex: flex,
        }}
      >
        <InfiniteScroll
          style={{
            width: "100%",
            overflow: !children?.length ? "hidden" : "auto",
          }}
          dataLength={dataLength ?? 0}
          hasMore={!maxReached}
          next={loadMoreItems}
          loader={<AppLoader />}
        >
          <StyledTable>
            {headerInformation && (
              <TableHeader>
                <TableHeaderRow>
                  {expandable && <ExpandableTableHeaderItem />}
                  {headerInformation.map((item, index) => {
                    return (
                      <TableHeaderItem
                        $big={bigHeaderWidth}
                        key={index}
                        $centerText={centerText}
                        $lastColumnAligned={lastColumnAligned}
                        // sortable={item.key}
                        // sorted={item.key && item.key === sortOptions?.sortBy}
                        // onClick={() => {
                        //   item.key && item.sortable && onSort(item.key);
                        // }}
                      >
                        {item.label}
                        {/* {item.key && item.sortable && (
                  <>
                    {sortOptions.order === ORDER.DESC &&
                    item.key === sortOptions.sortBy ? (
                      <Arrow style={{ transform: "rotate(90deg)" }} />
                    ) : (
                      <Arrow style={{ transform: "rotate(-90deg)" }} />
                    )}
                  </>
                )} */}
                      </TableHeaderItem>
                    );
                  })}
                </TableHeaderRow>
              </TableHeader>
            )}
            <TableBody>
              {!dataLoading ? (
                children?.length ? (
                  children?.map((child, index) => {
                    return expandable ? (
                      <Fragment key={index}>{child}</Fragment>
                    ) : (
                      <Fragment key={index}>{child}</Fragment>
                    );
                  })
                ) : (
                  <TableBodyRow $empty>
                    <TableBodyItemEmpty $textcenter>
                      {noDataContent}
                    </TableBodyItemEmpty>
                  </TableBodyRow>
                )
              ) : (
                <TableBodyRow>
                  <TableBodyItemEmpty $textcenter>
                    <MiniLoader />
                  </TableBodyItemEmpty>
                </TableBodyRow>
              )}
            </TableBody>
          </StyledTable>
        </InfiniteScroll>
      </TableWrapper>
    </TableContainer>
  ) : (
    <TableContainer
      style={{
        marginTop: marginTop,
      }}
    >
      <TableWrapper
        style={{
          width: width,
          minHeight: height,
          maxHeight: maxHeight,
          overflow: scroll,
          minWidth: minWidth,
          flex: flex,
        }}
      >
        {/* <AnimationLoader margin={80} /> */}
        <StyledTable>
          {headerInformation && (
            <TableHeader>
              <TableHeaderRow>
                {expandable && <ExpandableTableHeaderItem />}
                {headerInformation.map((item, index) => {
                  return (
                    <TableHeaderItem
                      $big={bigHeaderWidth}
                      key={index}
                      $centerText={centerText}
                      $lastColumnAligned={lastColumnAligned}
                      // sortable={item.key}
                      // sorted={item.key && item.key === sortOptions?.sortBy}
                      // onClick={() => {
                      //   item.key && item.sortable && onSort(item.key);
                      // }}
                    >
                      {item.label}
                      {/* {item.key && item.sortable && (
                  <>
                    {sortOptions.order === ORDER.DESC &&
                    item.key === sortOptions.sortBy ? (
                      <Arrow style={{ transform: "rotate(90deg)" }} />
                    ) : (
                      <Arrow style={{ transform: "rotate(-90deg)" }} />
                    )}
                  </>
                )} */}
                    </TableHeaderItem>
                  );
                })}
              </TableHeaderRow>
            </TableHeader>
          )}
          <TableBody>
            {!dataLoading ? (
              children?.length ? (
                children?.map((child, index) => {
                  return expandable ? (
                    <Fragment key={index}>{child}</Fragment>
                  ) : (
                    <Fragment key={index}>{child}</Fragment>
                  );
                })
              ) : (
                <TableBodyRow $empty>
                  <TableBodyItemEmpty $textcenter>
                    {noDataContent}
                  </TableBodyItemEmpty>
                </TableBodyRow>
              )
            ) : (
              <TableBodyRow>
                <TableBodyItemEmpty $textcenter>
                  <MiniLoader />
                </TableBodyItemEmpty>
              </TableBodyRow>
            )}
          </TableBody>
        </StyledTable>
        {totalCount > perPage && (
          <PaginationContainer $ar={ar}>
            <Pagination
              ar={ar}
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={perPage}
              onPageChange={(page) => {
                setPage(page, param);
              }}
            />
          </PaginationContainer>
        )}
      </TableWrapper>
    </TableContainer>
  );
};

export default Table;
