import { generatePhotosWithLogo, processLogoBackground } from "./asyncThunks";

export const removeBackgroundFromLogoBuilder = (builder) => {
  builder.addCase(processLogoBackground.pending, (state) => {
    state.processLogoBackgroundPending = true;
  });
  builder.addCase(processLogoBackground.fulfilled, (state, action) => {
    state.processLogoBackgroundPending = false;
    state.logo = action.payload.data;
  });
  builder.addCase(processLogoBackground.rejected, (state, action) => {
    state.processLogoBackgroundPending = false;
  });
};
