import { ButtonStyle } from "../Button/Button.styles";

const Button = ({ onClick, title, leftIcon, rightIcon, style }) => {
  return (
    <>
      <ButtonStyle onClick={onClick} style={style}>
        {leftIcon}
        {title}
        {rightIcon}
      </ButtonStyle>
    </>
  );
};

export default Button;
