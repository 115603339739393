import { keyframes, styled } from "styled-components";

const skeletonLoading = keyframes`
  from {
    background-color: #52528b;
  }
  to {

    background-color: #b2b2cb;

  }
`;

export const Text = styled.div`
  width: 75px;
  border-radius: 10px;
  height: 10px;

  animation: ${skeletonLoading} 0.6s linear infinite alternate;
`;
