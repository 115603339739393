import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../Modal/Modal";
import {
  ContentContainer,
  Title,
  Text,
  TitleContainer,
  Row,
  CloseContainer,
  InfoContainer,
  Button,
  ButtonsContainer,
  LogoLogoutContainer,
} from "./UserInfoModal.styles";
import { ReactComponent as UserIcon } from "../../assets/icons/drawer/profile.svg";
import { ReactComponent as CompanyIcon } from "../../assets/icons/drawer/group.svg";
import { ReactComponent as PhoneNumberIcon } from "../../assets/icons/phone.svg";
import { ReactComponent as Email } from "../../assets/icons/drawer/mail.svg";
import { ReactComponent as Role } from "../../assets/icons/role.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as Logo } from "../../assets/icons/Logo2.svg";
import { logoutRecipient } from "../../store/slices/authRecipient/asyncThunks";
import { useNavigate } from "react-router-dom";
import { selectBusinessUserInfo } from "../../store/slices/businessUser/slice";
import { selectUserInfo } from "../../store/slices/authRecipient/slice";
import { toast } from "react-toastify";
import { logout } from "../../store/slices/businessUser/asyncThunks";
import { useTranslation } from "react-i18next";
import { countClickAction } from "../../store/slices/common/asyncThunks";
import openToast from "../../utils/toast";

const UserInfoModal = ({
  handleButtonPress,
  modalRef,
  selectedLocale,
  showButtonsModal,
}) => {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const userInfo = useSelector(selectUserInfo);
  const { fullName, email, mobileNumber, role, company } = useSelector(
    selectBusinessUserInfo
  );
  const businessUserInfo = useSelector(selectBusinessUserInfo);
  const toastId = useRef(null);

  const handleLogout = () => {
    if (businessUserInfo.id > 0) {
      dispatch(logout())
        .unwrap()
        .then(() => {
          navigation("/login");
        })
        .catch((error) => {
          openToast(toastId, "logout-user-info", "error", t(error.message));
        });
    } else if (userInfo.id > 0) {
      dispatch(logoutRecipient())
        .unwrap()
        .then(() => {
          navigation("/login/user");
        })
        .catch((error) => {
          openToast(toastId, "logout-user-info", "error", t(error.message));
        });
    }
  };

  return (
    <Modal
      ref={modalRef}
      onBack={() => {
        modalRef.current.hide();
      }}
    >
      <ContentContainer>
        <CloseContainer
          onClick={() => {
            modalRef.current.hide();
          }}
        >
          <Close className="close" fill="#fff" />
        </CloseContainer>

        <InfoContainer>
          <TitleContainer>
            <UserIcon />
            <Title>{t("full-name")}:</Title>
          </TitleContainer>
          <Text>{fullName || userInfo.fullName}</Text>
        </InfoContainer>

        <InfoContainer>
          <TitleContainer>
            <Email fill="#fff" />
            <Title>{t("email")}:</Title>
          </TitleContainer>
          <Text>{email || userInfo.email}</Text>
        </InfoContainer>

        <InfoContainer>
          <TitleContainer>
            <PhoneNumberIcon fill="#fff" />
            <Title>{t("phone-number")}:</Title>
          </TitleContainer>
          <Text>{mobileNumber || userInfo.phone}</Text>
        </InfoContainer>

        {company.name && (
          <InfoContainer>
            <TitleContainer>
              <CompanyIcon />
              <Title>{t("company-name")}:</Title>
            </TitleContainer>
            <Text>{company?.name}</Text>
          </InfoContainer>
        )}

        {role && (
          <InfoContainer>
            <TitleContainer>
              <Role fill="#fff" />
              <Title>{t("role")}:</Title>
            </TitleContainer>
            <Text style={{ marginBottom: 0 }}>{role}</Text>
          </InfoContainer>
        )}
        {!showButtonsModal && (
          <ButtonsContainer>
            <Button
              onClick={() => {
                navigation("/admin-portal/pricing");
              }}
            >
              {t("upgrade-plan")}
            </Button>

            <Button
              onClick={() => {
                dispatch(countClickAction("startGifting"));
                navigation("/admin-portal/send-gifts/1");
              }}
            >
              {t("send-gifts")}
            </Button>
          </ButtonsContainer>
        )}

        <LogoLogoutContainer>
          <Row style={{ gap: 5 }}>
            <Logo />
            <Text style={{ fontFamily: "Avenir-Medium", userSelect: "none" }}>
              {t("business")}
            </Text>
          </Row>
          <Row
            onClick={handleLogout}
            className="hover"
            style={{
              cursor: "pointer",
              justifyContent: "flex-end",
            }}
          >
            <Logout />
            <Text
              style={{
                userSelect: "none",
              }}
            >
              {t("logout")}
            </Text>
          </Row>
        </LogoLogoutContainer>
      </ContentContainer>
    </Modal>
  );
};

export default UserInfoModal;
