import { createAsyncThunk } from "@reduxjs/toolkit";
import ItemServices from "../../../api/services/item";

export const getUsersWishlistItemsUnauthorized = createAsyncThunk(
  "coupon/get-wishlist-items-unauthorized",
  async (requestData) => {
    return await ItemServices.getUsersWishlistItemsUnauthorized(requestData);
  }
);

export const getItemUnauthorized = createAsyncThunk(
  "items/get-item-unauthorized",
  async (requestData) => {
    return await ItemServices.getItemUnauthorized(requestData);
  }
);
