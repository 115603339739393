import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  MainContainer,
  Column,
  Content,
  Text,
  ButtonContainer,
  Button,
  TitleContainer,
  DetailsData,
  DetailsDataValue,
  DetailsContainer,
  DetailsRow,
  InvoiceDiv,
  ButtonBackContainer,
  ButtonDisabled,
  DetailsContainerColumn,
  Select,
  Option,
  DetailsDataWrapper,
  DetailsDataValueColumn,
  DropDownContainer,
  LoaderDiv,
  HeaderWrapper,
  ImgGiftCard,
  RightSideRecipientDetails,
} from "./GiftOrderDetailsPage.styles";
import Sidebar from "../utils/Sidebar";
import ContentHeader from "../utils/ContentHeader";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptCompanyGiftingRecipientOrder,
  getCompanyGiftingById,
  rejectCompanyGiftingRecipientOrder,
  updateCompanyGiftingDetailsStatus,
  updateRecipientOrderState,
} from "../../../store/slices/gift/asyncThunks";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as InvoiceIcon } from "../../../assets/icons/invoice.svg";
import { ReactComponent as HourglassDownIcon } from "../../../assets/icons/hourglass-down.svg";
import { ReactComponent as HourglassUpIcon } from "../../../assets/icons/hourglass-up.svg";
import {
  MAP_LIBRARIES,
  getCurrencySymbol,
  giftingStatusObj,
  googlePlacesKey,
  marketplaceOrderStates,
} from "../../../utils/constants";
import { formatDate, priceFormatter } from "../../../utils/utils";
import { ButtonBack } from "../SendGiftsPage/SendGiftsPage.styles";
import {
  approveLoader,
  orderDetailLoader,
  ordersData,
  rejectLoader,
  selectOrderStatus,
  selectUpdateGiftingDetailsStatusLoading,
} from "../../../store/slices/gift/slice";
import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";
import { toast } from "react-toastify";
import { format, isValid } from "date-fns";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { selectDetailsId } from "../../../store/slices/sendGifts/slice";
import MiniLoader from "../../../components/MiniLoader/MiniLoader";
import Header from "../../../components/Header/Header";
import openToast from "../../../utils/toast";
import {
  selectBusinessRulesVariables,
  selectedLocale,
} from "../../../store/slices/common/slice";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy.svg";
import AppLoader from "../../../components/AppLoader";

const GiftOrderDetailsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const params = useParams();
  const id = params.orderId;
  const order = useSelector(ordersData);
  const orderDetailsLoading = useSelector(orderDetailLoader);
  const [icon, setIcon] = useState(<HourglassUpIcon />);
  const approvedLoading = useSelector(approveLoader);
  const rejectedLoading = useSelector(rejectLoader);
  const businessUser = useSelector(selectBusinessUserInfo);
  const mapRef = useRef(null);
  const detailsId = useSelector(selectDetailsId);
  const orderStatus = useSelector(selectOrderStatus);
  const orderStatusLoading = useSelector(
    selectUpdateGiftingDetailsStatusLoading
  );
  const toastId = useRef(null);
  const { itemPurchaseVAT } = useSelector(selectBusinessRulesVariables);

  useEffect(() => {
    dispatch(getCompanyGiftingById(id))
      .unwrap()
      .catch((error) => {
        toast.error(t(error.message));
      });
  }, [dispatch, id]);

  const [acceptedRecipients, setAcceptedRecipients] = useState([]);
  const [rejectedRecipients, setRejectedRecipients] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIcon((currentIcon) => {
        if (currentIcon.type === HourglassUpIcon) {
          return <HourglassDownIcon />;
        } else {
          return <HourglassUpIcon />;
        }
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  let formattedDateOfDelivery = "";
  if (isValid(new Date(order?.dateOfDelivery))) {
    const date = new Date(order?.dateOfDelivery);
    formattedDateOfDelivery = format(date, "dd/MM/yyyy");
  } else {
    formattedDateOfDelivery = "Invalid date";
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googlePlacesKey,
    libraries: MAP_LIBRARIES,
  });

  const center = {
    lat: order?.location?.point.coordinates[1],
    lng: order?.location?.point.coordinates[0],
  };
  const onLoad = useCallback(function callback(map) {
    mapRef.current = map;
    map.setCenter(center);
    map.setZoom(14);
  }, []);

  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";
  return (
    <MainContainer>
      <HeaderWrapper>
        <Header
          showLogin
          showSignUp
          showNav
          color
          showFavorite
          showCart
          showUser
        />
      </HeaderWrapper>
      <Sidebar />

      <Column $ar={ar}>
        <ContentHeader title={t("order-details")} showTitle={true} />
        {!orderDetailsLoading ? (
          <>
            <TitleContainer>
              <Text>
                {t("order-#")}
                {order?.id}
              </Text>
            </TitleContainer>
            <Content>
              {businessUser?.role === "superAdmin" ? (
                <DropDownContainer>
                  <DetailsData> {t("status")}:</DetailsData>
                  {!orderStatusLoading ? (
                    <Select
                      id="userDropdown"
                      onChange={(event) => {
                        const selectedStatusId = parseInt(event.target.value);
                        const selectedStatus = giftingStatusObj.find(
                          (status) => status.id === selectedStatusId
                        );
                        const selectedStatusValue = selectedStatus?.value;
                        dispatch(
                          updateCompanyGiftingDetailsStatus({
                            id: id,
                            status: selectedStatusValue,
                          })
                        );
                      }}
                    >
                      {giftingStatusObj.map((status) => (
                        <Option
                          key={status.id}
                          value={status.id}
                          selected={orderStatus === status.value}
                        >
                          {status.value}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <AppLoader />
                  )}
                </DropDownContainer>
              ) : (
                <DetailsContainer>
                  <DetailsData>{t("status")}: </DetailsData>
                  {order?.companyGiftingRecipients?.length ? (
                    <DetailsDataValue>
                      {t(`${order?.companyGiftingRecipients[0]?.status}`)}
                    </DetailsDataValue>
                  ) : (
                    <DetailsDataValue>/</DetailsDataValue>
                  )}
                </DetailsContainer>
              )}
              <DetailsContainer>
                <DetailsData>{t("number-of-recipients")}: </DetailsData>
                {order?.companyGiftingRecipients?.length ? (
                  <DetailsDataValue>
                    {order?.companyGiftingRecipients?.length}
                  </DetailsDataValue>
                ) : (
                  <DetailsDataValue>/</DetailsDataValue>
                )}
              </DetailsContainer>

              {order?.giftCardCategory && (
                <DetailsContainer>
                  <DetailsData>{t("gift-card-category")}: </DetailsData>
                  <DetailsDataValue>{order?.giftCardCategory}</DetailsDataValue>
                </DetailsContainer>
              )}
              {order?.giftCardText && (
                <DetailsContainer>
                  <DetailsData>{t("gift-card-text")}: </DetailsData>
                  <DetailsDataValue>{order?.giftCardText}</DetailsDataValue>
                </DetailsContainer>
              )}
              <DetailsContainer>
                <DetailsData>{t("occasion")}: </DetailsData>
                {order?.occasion ? (
                  <DetailsDataValue>{t(order?.occasion)}</DetailsDataValue>
                ) : (
                  <DetailsDataValue>/</DetailsDataValue>
                )}
              </DetailsContainer>
              <DetailsContainer>
                <DetailsDataWrapper>
                  <DetailsData>{t("delivery-location")}: </DetailsData>
                </DetailsDataWrapper>
                {order?.deliveryLocation === "Office" ||
                order?.deliveryLocation === "Custom" ? (
                  <>
                    <DetailsDataWrapper
                      style={{
                        width: "100%",
                        maxWidth: "500px",
                        height: "300px",
                        flexDirection: "column",
                        display: "flex",
                      }}
                    >
                      <DetailsDataValue>
                        {t(order?.deliveryLocation)}
                      </DetailsDataValue>
                      {isLoaded ? (
                        <GoogleMap
                          mapContainerStyle={{
                            border: 1,
                            width: "100%",
                            maxWidth: "500px",
                            height: "330px",
                            marginTop: "10px",
                          }}
                          onLoad={(map) => {
                            mapRef.current = map;
                            onLoad(map);
                          }}
                          zoom={14}
                          center={center}
                        >
                          <Marker position={center} />
                        </GoogleMap>
                      ) : (
                        <div style={{ marginTop: 50 }}>
                          <AppLoader />
                        </div>
                      )}
                    </DetailsDataWrapper>
                  </>
                ) : (
                  <DetailsDataValue>
                    {t(order?.deliveryLocation)}
                  </DetailsDataValue>
                )}
              </DetailsContainer>
              <DetailsContainer>
                <DetailsData>{t("date-of-delivery")}: </DetailsData>
                {order?.dateOfDelivery ? (
                  <DetailsDataValue>
                    {t(`${formattedDateOfDelivery}`)}
                  </DetailsDataValue>
                ) : (
                  <DetailsDataValue>/</DetailsDataValue>
                )}
              </DetailsContainer>
              {order?.addressDetails && (
                <DetailsContainer>
                  <DetailsData>{t("address-details")}: </DetailsData>
                  <DetailsDataValue>{order?.addressDetails}</DetailsDataValue>
                </DetailsContainer>
              )}
              {(order?.flowers || order?.cake || order?.chocolate) && (
                <DetailsContainer>
                  <DetailsData>{t("add-ons")}: </DetailsData>
                  <DetailsDataValueColumn>
                    {order?.flowers && (
                      <DetailsDataValue>
                        {t("flowers")} - {getCurrencySymbol("sar")}
                        {priceFormatter(
                          order.flowersPrice,
                          true,
                          itemPurchaseVAT
                        )}
                      </DetailsDataValue>
                    )}
                    {order?.cake && (
                      <DetailsDataValue>
                        {t("cake")} - {getCurrencySymbol("sar")}
                        {priceFormatter(order.cakePrice, true, itemPurchaseVAT)}
                      </DetailsDataValue>
                    )}
                    {order?.chocolate && (
                      <DetailsDataValue>
                        {t("chocolate")} - {getCurrencySymbol("sar")}
                        {priceFormatter(
                          order.chocolatePrice,
                          true,
                          itemPurchaseVAT
                        )}
                      </DetailsDataValue>
                    )}
                  </DetailsDataValueColumn>
                </DetailsContainer>
              )}
              {order?.budgetPerRecipient && (
                <DetailsContainer>
                  <DetailsData>{t("budget-per-recipient")}:</DetailsData>
                  <DetailsDataValue>
                    {`${priceFormatter(
                      order?.budgetPerRecipient
                    )} ${getCurrencySymbol("sar")} `}
                  </DetailsDataValue>
                </DetailsContainer>
              )}
              {order?.orderMaxBudget && (
                <DetailsContainer>
                  <DetailsData>{t("order-max-budget")}:</DetailsData>
                  <DetailsDataValue>
                    {`${priceFormatter(
                      order?.orderMaxBudget
                    )} ${getCurrencySymbol("sar")} `}
                  </DetailsDataValue>
                </DetailsContainer>
              )}
              <DetailsContainerColumn>
                <DetailsData>{t("recipients-details")}: </DetailsData>
                {order?.companyGiftingRecipients?.map((recipient) => {
                  const handleInvoiceGiftClick = () => {
                    if (recipient.gaasOrder?.invoiceUrl) {
                      window.open(recipient.gaasOrder.invoiceUrl, "_blank");
                    }
                  };
                  const handleAccept = () => {
                    dispatch(acceptCompanyGiftingRecipientOrder(recipient?.id))
                      .unwrap()
                      .then(() => {
                        setAcceptedRecipients((prevAcceptedRecipients) => [
                          ...prevAcceptedRecipients,
                          recipient.id,
                        ]);
                      })
                      .catch((error) => {
                        openToast(
                          toastId,
                          "accept-company-gifting-order",
                          "error",
                          t(error.message)
                        );
                      });
                  };
                  const handleReject = () => {
                    dispatch(rejectCompanyGiftingRecipientOrder(recipient?.id));
                    setRejectedRecipients((prevRejectedRecipients) => [
                      ...prevRejectedRecipients,
                      recipient.id,
                    ]);
                  };
                  const isAccepted = acceptedRecipients.includes(recipient.id);
                  const isRejected = rejectedRecipients.includes(recipient.id);
                  return (
                    <React.Fragment key={recipient.recipientId}>
                      <DetailsContainer
                        style={{
                          borderTop: "5px solid #8A4B5E88",
                        }}
                      >
                        <div>
                          <DetailsRow>
                            <DetailsData>{t("recipient-name")}: </DetailsData>
                            <DetailsDataValue>
                              {recipient.recipient?.firstName}{" "}
                              {recipient.recipient?.lastName}
                            </DetailsDataValue>
                          </DetailsRow>
                          <DetailsRow>
                            <DetailsData>
                              {t("recipient-phone-number")}:{" "}
                            </DetailsData>
                            <DetailsDataValue>
                              {recipient.recipient?.mobileNumber}
                            </DetailsDataValue>
                          </DetailsRow>
                          <DetailsRow>
                            <DetailsData>{t("recipient-email")}: </DetailsData>
                            <DetailsDataValue>
                              {recipient.recipient?.email}
                            </DetailsDataValue>
                          </DetailsRow>
                          <DetailsRow>
                            <DetailsData>
                              {t("recipient-department")}:{" "}
                            </DetailsData>
                            <DetailsDataValue>
                              {recipient.recipient?.department}
                            </DetailsDataValue>
                          </DetailsRow>
                          {!!recipient.gaasOrder?.item?.price && (
                            <DetailsRow>
                              <DetailsData>{t("price")}: </DetailsData>
                              <DetailsDataValue>
                                {`${priceFormatter(
                                  recipient.gaasOrder?.item?.price,
                                  1,
                                  15
                                )} ${getCurrencySymbol("sar")} `}
                              </DetailsDataValue>
                            </DetailsRow>
                          )}
                          {recipient.gaasOrder?.invoiceUrl ? (
                            <DetailsRow>
                              <DetailsData>{t("invoice")}: </DetailsData>
                              <DetailsDataValue>
                                <InvoiceDiv>
                                  <InvoiceIcon
                                    className="invoice-icon"
                                    onClick={handleInvoiceGiftClick}
                                  />
                                </InvoiceDiv>
                              </DetailsDataValue>
                            </DetailsRow>
                          ) : null}
                          {recipient.gaasOrder?.validTo && (
                            <DetailsRow>
                              <DetailsData>{t("valid-to")}: </DetailsData>
                              <DetailsDataValue>
                                {formatDate(recipient.gaasOrder?.validTo)}
                              </DetailsDataValue>
                            </DetailsRow>
                          )}
                          {recipient.gaasOrder?.voucherCode && (
                            <DetailsRow>
                              <DetailsData>{t("voucher-code")}: </DetailsData>
                              {recipient.gaasOrder?.voucherCode
                                .split(",")
                                .map((code, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      display: "flex",
                                    }}
                                  >
                                    <DetailsDataValue>
                                      {code.trim()}
                                    </DetailsDataValue>
                                    <CopyIcon
                                      fill="#8A4B5E"
                                      width={20}
                                      height={20}
                                      style={{
                                        cursor: "pointer",
                                        marginTop: 3,
                                        marginLeft: 6,
                                      }}
                                      onClick={() =>
                                        navigator.clipboard.writeText(
                                          code.trim()
                                        )
                                      }
                                    />{" "}
                                  </div>
                                ))}
                            </DetailsRow>
                          )}
                          {recipient.gaasOrder?.state &&
                          businessUser.role === "superAdmin" ? (
                            <DetailsRow>
                              <DetailsData>
                                {t("order-status")}:
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Select
                                    id="userDropdown"
                                    onChange={(event) => {
                                      const selectedStateId = parseInt(
                                        event.target.value
                                      );
                                      const selectedState =
                                        marketplaceOrderStates.find(
                                          (state) =>
                                            state.id === selectedStateId
                                        );
                                      const selectedStateValue =
                                        selectedState?.value;
                                      dispatch(
                                        updateRecipientOrderState({
                                          id: recipient.id,
                                          state: selectedStateValue,
                                        })
                                      );
                                    }}
                                  >
                                    {marketplaceOrderStates.map((state) => (
                                      <Option
                                        key={state.id}
                                        value={state.id}
                                        selected={
                                          recipient.gaasOrder?.state ===
                                          state.value
                                        }
                                      >
                                        {state.value}
                                      </Option>
                                    ))}
                                  </Select>
                                </div>
                              </DetailsData>
                            </DetailsRow>
                          ) : (
                            <DetailsRow>
                              <DetailsData>
                                {recipient.gaasOrder?.state && (
                                  <>
                                    {t("order-status")}:{" "}
                                    <DetailsDataValue>
                                      {recipient.gaasOrder?.state}
                                    </DetailsDataValue>
                                  </>
                                )}
                              </DetailsData>
                            </DetailsRow>
                          )}
                        </div>

                        <RightSideRecipientDetails>
                          {isRejected ||
                          recipient?.status === "rejected" ? null : (
                            <>
                              {recipient.gaasOrder?.item?.photos[0] ? (
                                recipient.gaasOrder.voucherCode ? (
                                  <ImgGiftCard
                                    alt="item-img"
                                    src={
                                      recipient.gaasOrder?.item?.photos[0].url
                                    }
                                  />
                                ) : (
                                  <img
                                    src={
                                      recipient.gaasOrder?.item?.photos[0].url
                                    }
                                    style={{
                                      width: 200,
                                      borderRadius: 20,
                                      marginBottom: 10,
                                    }}
                                    alt="item-img"
                                  />
                                )
                              ) : (
                                <div
                                  className="icon-div"
                                  style={{
                                    paddingBottom: 20,
                                    marginLeft: 80,
                                  }}
                                >
                                  {icon}
                                </div>
                              )}
                            </>
                          )}

                          {!isAccepted &&
                            !isRejected &&
                            recipient?.status === "orderCreated" &&
                            businessUser.role !== "superAdmin" && (
                              <ButtonContainer>
                                <Button onClick={handleAccept}>
                                  {t("accept")}
                                </Button>
                                <>
                                  <Button onClick={handleReject}>
                                    {t("reject")}
                                  </Button>
                                </>
                              </ButtonContainer>
                            )}
                          {(isAccepted || recipient?.status === "approved") && (
                            <ButtonContainer>
                              <ButtonDisabled
                                saveloading={approvedLoading + ""}
                                disabled
                                style={{
                                  cursor: "default",
                                  justifyContent: "center",
                                }}
                              >
                                {!approvedLoading ? (
                                  <>{t("accepted")}</>
                                ) : (
                                  <>{icon}</>
                                )}
                              </ButtonDisabled>
                            </ButtonContainer>
                          )}
                          {(isRejected || recipient?.status === "rejected") && (
                            <ButtonContainer style={{ marginTop: 100 }}>
                              <ButtonDisabled
                                saveloading={rejectedLoading + ""}
                                disabled
                                style={{ cursor: "default" }}
                              >
                                {!rejectedLoading ? (
                                  <>{t("rejected")}</>
                                ) : (
                                  <>{icon}</>
                                )}
                              </ButtonDisabled>
                            </ButtonContainer>
                          )}
                        </RightSideRecipientDetails>
                      </DetailsContainer>
                    </React.Fragment>
                  );
                })}
              </DetailsContainerColumn>
              <ButtonBackContainer>
                <ButtonBack
                  onClick={() =>
                    navigation(
                      "/admin-portal/order-history?marketplacePage=1&giftsPage=1"
                    )
                  }
                >
                  {t("back")}
                </ButtonBack>
              </ButtonBackContainer>
            </Content>
          </>
        ) : (
          <LoaderDiv>
            <MiniLoader />
          </LoaderDiv>
        )}
      </Column>
    </MainContainer>
  );
};
export default GiftOrderDetailsPage;
