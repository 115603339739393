import axiosApiClient, { axiosRecipientUserClient } from "..";
import { handleError } from "../../utils/errors";

const BASE_URL = "business/gift";

class GiftService {
  getAllGiftItems = async ({ search, page, sortBy, order }) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/items?page=${page}&search=${search}&sortBy=${sortBy}&order=${order}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { items } = response.data.data;

      return items;
    } catch (err) {
      throw handleError(err, "Error occurred while fetching gift items.");
    }
  };
  getAdminDashboardData = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/dashboardAdmin`);
      if (response.status !== 200) {
        throw new Error();
      }
      const adminDashboardData = response.data;

      return adminDashboardData;
    } catch (err) {
      throw handleError(err, "Error occurred while fetching dashboard data.");
    }
  };

  getItemsBySection = async ({ sectionId, page, search, sortBy, order }) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/section/${sectionId}?page=${page}&search=${search}&sortBy=${sortBy}&order=${order}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { items, maxReached, name, perPage, total } = response.data.data;

      return { items, maxReached, name, page, sectionId, total, perPage };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching items by section.");
    }
  };

  getItemsByCategory = async ({ categoryId, page, search, sortBy, order }) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/category/${categoryId}?page=${page}&search=${search}&sortBy=${sortBy}&order=${order}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { items, maxReached, name, perPage, total } = response.data.data;

      return { items, maxReached, name, page, categoryId, perPage, total };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching items by category."
      );
    }
  };

  getItemById = async (id) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/item/${id}`);
      if (response.status !== 200) {
        throw new Error();
      }

      const { item } = response.data.data;

      return item;
    } catch (err) {
      throw handleError(err, "Error occurred while fetching item by id.");
    }
  };

  getCategories = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/categories`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { categories } = response.data.data;

      return categories;
    } catch (err) {
      throw handleError(err, "Error occurred while fetching categories.");
    }
  };
  getSections = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/sections`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { sections } = response.data.data;

      return sections;
    } catch (err) {
      throw handleError(err, "Error occurred while fetching categories.");
    }
  };
  getCategoriesWithSixteenOrMoreItems = async () => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/categories/explore`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { categoryItems } = response.data.data;
      return categoryItems;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching categories with sixteen or more items."
      );
    }
  };
  getRecipients = async ({ page, search }) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/recipients?page=${page}&search=${search}`
      );
      if (response.status !== 200) {
        throw new Error();
      }

      const { recipients, maxReached, perPage, total } = response.data.data;
      return {
        recipients,
        maxReached,
        perPage,
        total,
      };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching recipients.");
    }
  };

  addRecipient = async ({
    email,
    firstName,
    lastName,
    mobileNumber,
    department,
    userId,
  }) => {
    try {
      const body = {
        email,
        firstName,
        lastName,
        mobileNumber,
        department,
        userId,
      };
      const response = await axiosApiClient.post(
        `${BASE_URL}/add-recipient`,
        body
      );

      if (response.status !== 200) {
        throw new Error();
      }
      const { recipient } = response.data.data;

      return recipient;
    } catch (err) {
      throw handleError(err, "Error occurred while creating a new recipient.");
    }
  };

  createCompanyGiftingDetails = async (giftCardObject) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/company-gifting-details`,
        giftCardObject
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { message, data } = response.data;

      return { message, data };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while creating a new gifting details."
      );
    }
  };

  paidCompanyGiftingDetails = async (requestData) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/company-gifting-details/${requestData.id}/paid`,
        requestData
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { message, data } = response.data;

      return { message, data };
    } catch (err) {
      throw handleError(err, "Error occurred while paying gifting details.");
    }
  };

  deleteRecipient = async (recipientId) => {
    try {
      const response = await axiosApiClient.delete(
        `${BASE_URL}/recipient/${recipientId}`
      );
      if (response.status !== 200) {
        throw new Error();
      }

      return recipientId;
    } catch (err) {
      throw handleError(err, "Error occurred while deleting a recipient.");
    }
  };

  getAllCompanyGifting = async ({ page, sortBy, order }) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/company-gifting?page=${page}&sortBy=${sortBy}&order=${order}`
      );
      const { data, total, maxReached, perPage } = response.data;
      return { data, total, maxReached, perPage };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching company gifting orders."
      );
    }
  };

  getCompanyGiftingById = async (id) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/company-gifting/${id}`
      );
      return response.data.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching company gifting order."
      );
    }
  };

  acceptCompanyGiftingRecipientOrder = async (id) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/company-gifting-recipient-order/accept/${id}`
      );

      return { response, id };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while accepting company gifting order."
      );
    }
  };

  rejectCompanyGiftingRecipientOrder = async (id) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/company-gifting-recipient-order/reject/${id}`
      );
      return { response, id };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while rejecting company gifting order."
      );
    }
  };

  getUserByPhone = async (phone) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/recipientUser/phone/${phone}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data.data;
    } catch (err) {
      throw handleError(err, "Error occurred while getting users by phone");
    }
  };

  getCompanyGiftingItems = async ({ id, page, sortBy, order }) => {
    try {
      const response = await axiosRecipientUserClient.get(
        `${BASE_URL}/company-gifting-items/${id}?page=${page}&sortBy=${sortBy}&order=${order}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, maxReached, perPage, message } = response.data;
      return { data, total, maxReached, perPage, message };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching company gifting items."
      );
    }
  };

  getCompanyGiftingItemsForMe = async ({ page, sortBy, order }) => {
    try {
      const response = await axiosRecipientUserClient.get(
        `${BASE_URL}/company-gifting-for-me?page=${page}&sortBy=${sortBy}&order=${order}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, maxReached, perPage, message } = response.data;

      return { data, total, maxReached, perPage, message };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching company gifting items."
      );
    }
  };

  createCompanyGiftingRecipientOrder = async ({
    itemId,
    companyGiftingRecipientId,
    totalPrice,
  }) => {
    try {
      const response = await axiosRecipientUserClient.post(
        `${BASE_URL}/company-gifting-recipient-order`,
        { itemId, companyGiftingRecipientId, totalPrice }
      );
      const { message, data } = response.data;
      return { message, data, companyGiftingRecipientId };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while posting company gifting order."
      );
    }
  };

  getAllCompanyGiftingAdmin = async ({ page, sortBy, order }) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/company-gifting-admin?page=${page}&sortBy=${sortBy}&order=${order}`
      );
      return response.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching company gifting orders."
      );
    }
  };

  getDashboardData = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/dashboard`);
      if (response.status !== 200) {
        throw new Error();
      }
      const {
        recipientCount,
        marketplaceOrders,
        sendGiftOrders,
        topRecipients,
        topDepartments,
      } = response.data.data;
      return {
        recipientCount,
        marketplaceOrders,
        sendGiftOrders,
        topRecipients,
        topDepartments,
      };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching company gifting items."
      );
    }
  };

  updateCompanyGiftingRecipientOrderState = async ({ id, state }) => {
    try {
      const response = await axiosApiClient.put(
        `${BASE_URL}/company-gifting-recipient-order/state/${id}`,
        { state }
      );
      const { companyGiftingRecipientId, orderState } = response.data;
      return { companyGiftingRecipientId, orderState };
    } catch (err) {
      throw handleError(err, "Error occurred while updating order state.");
    }
  };

  getCompanyGiftingItemsWithToken = async (token) => {
    try {
      const response = await axiosRecipientUserClient.get(
        `${BASE_URL}/company-gifting-items-with-token/${token}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching company gifting items."
      );
    }
  };

  createCompanyGiftingRecipientOrderWithToken = async ({
    itemId,
    token,
    totalPrice,
  }) => {
    try {
      const response = await axiosRecipientUserClient.post(
        `${BASE_URL}/company-gifting-recipient-order-with-token`,
        { itemId, token, totalPrice }
      );
      return response.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while posting company gifting order."
      );
    }
  };

  rejectCompanyGiftingToken = async ({ token, feedback }) => {
    try {
      const response = await axiosRecipientUserClient.post(
        `/gift/company-gifting-recipient/${token}/reject-token`,
        { feedback }
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while rejecting company gifting order."
      );
    }
  };

  setDeliveryAddressCompanyGiftingToken = async (data) => {
    try {
      const response = await axiosRecipientUserClient.post(
        `/gift/company-gifting-recipient/${data.token}/set-delivery-address`,
        data
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while setting delivery address company gifting."
      );
    }
  };

  getDeliveryAddressCompanyGiftingToken = async ({ token }) => {
    try {
      const response = await axiosRecipientUserClient.get(
        `/gift/company-gifting-recipient/${token}/delivery-address`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while getting delivery address company gifting."
      );
    }
  };

  addRecipientsWithFile = async (file) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/upload-recipients`,
        file,
        {
          headers: {
            "Content-Type": file.type,
          },
        }
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while adding recipients.");
    }
  };

  editRecipient = async ({
    recipientId,
    email,
    firstName,
    lastName,
    mobileNumber,
    department,
    userId,
  }) => {
    try {
      const body = {
        email,
        firstName,
        lastName,
        mobileNumber,
        department,
        userId,
      };
      const response = await axiosApiClient.put(
        `${BASE_URL}/edit-recipient/${recipientId}`,
        body
      );

      if (response.status !== 200) {
        throw new Error();
      }
      const { recipient } = response.data.data;

      return recipient;
    } catch (err) {
      throw handleError(err, "Error occurred while editing recipient details.");
    }
  };

  findRecipientsWishlists = async ({ recipients, searchTerm }) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/find-recipients-wishlist`,
        { recipients, searchTerm }
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while finding recipients wishlists."
      );
    }
  };

  updateCompanyGiftingDetailsStatus = async ({ id, status }) => {
    try {
      const response = await axiosApiClient.put(
        `${BASE_URL}/company-gifting-details/status/${id}`,
        { status: status }
      );
      return response.data.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while updating company gifting details status."
      );
    }
  };
}
const giftService = new GiftService();

export default giftService;
