import React, { useEffect, useRef, useState } from "react";
import {
  Title,
  StyledCloseContainer,
  Button,
  Input,
  Gap,
  StyledText,
  StyledText2,
  StyledVisibilityOff,
  StyledVisibility,
  StyledLockIcon,
  StyledMailIcon,
  InputWrapper,
} from "./PriceBreakModal.styles";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import Modal from "./Modal2";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Loading } from "../../assets/icons/loader-white.svg";
import { useSelector } from "react-redux";
import { selectLoginPending } from "../../store/slices/auth/slice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  isBusinessUserLoggedIn,
  setBusinessUserInfo,
} from "../../store/slices/businessUser/slice";
import { login } from "../../store/slices/auth/asyncThunks";
import openToast from "../../utils/toast";

const LoginModal = ({ open, close, title }) => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const loginLoading = useSelector(selectLoginPending);
  const [visibility, setVisibility] = useState(false);
  const loggedIn = useSelector(isBusinessUserLoggedIn);
  const toastId = useRef(null);

  const handleLogin = () => {
    if (!email || !password) {
      openToast(
        toastId,
        "login-modal",
        "error",
        t("please-enter-correct-email-and-password")
      );
      return;
    }
    dispatch(login({ email, password }))
      .unwrap()
      .then((response) => {
        close();
        const { businessUser } = response;
        dispatch(setBusinessUserInfo(businessUser));
      })
      .catch((err) =>
        openToast(toastId, "login-modal", "error", t(err.message))
      );
  };

  const handleKeyPress = (e) => {
    if (!loggedIn & open) {
      if (e.key === "Enter") {
        handleLogin();
      }
    }
  };

  useEffect(() => {
    if (!loggedIn && open) {
      document.addEventListener("keypress", handleKeyPress);
      return () => {
        document.removeEventListener("keypress", handleKeyPress);
      };
    }
  }, [handleKeyPress, loggedIn, open]);

  const handleVisibilityToggle = () => {
    setVisibility(!visibility);
  };

  return (
    <Modal
      onClose={close}
      open={open}
      width={"877px"}
      height={"fit-content"}
      padding={"50px 60px"}
      unset
      className="modal-login"
    >
      <StyledCloseContainer onClick={close}>
        <CloseIcon fill="white" width={20} height={20} />
      </StyledCloseContainer>
      <Gap>
        {!title ? (
          <Title>{t("you-need-to-login")}</Title>
        ) : (
          <Title>{t("you-need-to-login-to-get-instant-quotes")}</Title>
        )}

        <InputWrapper>
          <StyledMailIcon className="login__icon" />
          <Input
            type="text"
            placeholder={t("email-address")}
            name="email"
            autoComplete="on"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper>
          <StyledLockIcon className="login__icon" />
          <Input
            type={visibility ? "text" : "password"}
            placeholder={t("password")}
            name="password"
            autoComplete="off"
            value={password}
            onKeyPress={handleKeyPress}
            onChange={(e) => setPassword(e.target.value)}
          />
          {visibility ? (
            <StyledVisibility
              onClick={handleVisibilityToggle}
              className="login__icon_right"
            />
          ) : (
            <StyledVisibilityOff
              onClick={handleVisibilityToggle}
              className="login__icon_right"
            />
          )}
        </InputWrapper>

        {!loginLoading ? (
          <Button
            onClick={() => {
              handleLogin();
            }}
          >
            {t("log-in")}
          </Button>
        ) : (
          <Button disabled>
            <Loading height={30} />
          </Button>
        )}
        <StyledText>
          {t("dont-have-an-account")}
          <StyledText2 onClick={() => navigation("/sign-up")}>
            {t("get-started")}
          </StyledText2>
        </StyledText>
      </Gap>
    </Modal>
  );
};

export default LoginModal;
