import styled from "styled-components";

export const MainContainer = styled.div`
  background: rgb(255, 255, 255);
  min-height: 100vh;
  padding: 0px 40px;
  display: flex;
`;

export const HeaderWrapper = styled.div`
  background: #8a4b5e;
  position: fixed;
  z-index: 1000;
  width: 100%;
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  padding: ${(props) =>
    props.$upgradePackage ? "110px 40px 80px 290px" : "80px 40px 80px 290px"};

  @media (max-width: 870px) {
    margin-top: 10rem;
    padding: ${(props) =>
      props.$upgradePackage ? "60px 40px 40px 40px" : "0px 40px 40px 40px"};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  @media (max-width: 530px) {
    justify-content: center;
  }
`;

export const Text = styled.span`
  font-family: "Avenir-Black";
  font-style: normal;
  user-select: none;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-top: 20;
  width: 100%;
`;
