import axiosApiClient from "..";
import { handleError } from "../../utils/errors";

const BASE_URL = "business/company";

class CompanyService {
  editCompanyInfo = async ({
    companyLogo,
    billingEmail,
    address,
    addressOptional,
    city,
    zipCode,
    country,
    VAT
  }) => {
    const body = {
      companyLogo,
      billingEmail,
      address,
      addressOptional,
      city,
      zipCode,
      country,
      VAT
    };
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/info`, body);
      if (response.status !== 200) {
        throw new Error();
      }
      const { company } = response.data.data;
      return company;
    } catch (err) {
      throw handleError(err, "Error occurred while fetching user's info.");
    }
  };

  editCompanyProfile = async (formData) => {
    try {
      const response = await axiosApiClient.put(
        `${BASE_URL}/profile`,
        formData
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { businessUser } = response.data.data;
      return businessUser;
    } catch (err) {
      throw handleError(err, "Error occurred while fetching user's info.");
    }
  };

  getCompanyUsers = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/users`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { companyUsers } = response.data.data;
      return companyUsers;
    } catch (err) {
      throw handleError(err, "Error occurred while fetching company users.");
    }
  };

  deleteCompanyBusinessUser = async (businessUserId) => {
    try {
      const response = await axiosApiClient.delete(
        `${BASE_URL}/remove-user/${businessUserId}`
      );
      if (response.status !== 200) {
        throw new Error();
      }

      return businessUserId;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while deleting company business user."
      );
    }
  };
}

const companyService = new CompanyService();

export default companyService;
