import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../../../components/Header/Header";
import Footer from "../../../../components/Footer";
import {
  Container,
  Screen,
  ScreenContent,
  MainContainer,
  Title,
  Success,
  TitleContainer,
  StepContainer,
  Step,
  StepCircle,
  Skip,
  Subtitle,
  Text,
  Row,
  HeaderText,
  SkipContainer,
  Slider,
  TextContainer,
  StyledText,
  Payments,
  StyledDate,
  Row2,
  DiscountContainer,
  Discount,
  Invisible,
  Gap,
  StyledTextSmall,
  InvisibleText,
  PaymentsContainer,
  Input,
  Button,
  InputContainer,
  GreenText,
  LargeText,
  Link,
  TextColumn,
  Linked,
  Chevron,
  StyledRow,
  DiscountText,
  ErrorContainer,
  HeaderWrapper,
  Form,
  Message,
  TitleWrapper,
  FeaturesContainer,
  FeaturesTitles,
  Feature,
  FeatureTitle,
  Card,
  Name,
  Value,
  Wrapper,
  Image,
  StyledContainer,
  StyledTitle,
} from "./StepBilling.styles";

import { selectCreateAccountPending } from "../../../../store/slices/auth/slice";
import Loader from "../../../../components/Loader/Loader";
import SuccessImg from "../../../../assets/images/success.png";
import { ReactComponent as Yes } from "../../../../assets/icons/green.svg";
import No from "../../../../assets/images/failed.png";

import Switch from "react-switch";
import PaymentsComponent from "../../../../components/PaymentsComponent/PaymentsComponent";
import PricePlan from "../../../../components/PricePlan/PricePlan";
import { selectedPlans } from "../../../../store/slices/plan/slice";
import { getCurrencySymbol } from "../../../../utils/constants";
import { priceFormatter } from "../../../../utils/utils";
import authService from "../../../../api/services/auth";
import { fetchBusinessUserInfo } from "../../../../store/slices/businessUser/asyncThunks";
import subscriptionService from "../../../../api/services/subscription";
import couponServices from "../../../../api/services/coupon";
import {
  isBusinessUserLoggedIn,
  selectBusinessUserInfo,
} from "../../../../store/slices/businessUser/slice";
import openToast from "../../../../utils/toast";
import { ButtonContainer } from "../SignupPage.style";
import { ReactComponent as Loading } from "../../../../assets/icons/loader-white.svg";
import { selectedLocale } from "../../../../store/slices/common/slice";
import ModalConfirm from "../../../../components/ModalConfirm/ModalConfirm";
import {
  assignPlanWithoutPayment,
  assignSubscriptionPlan,
} from "../../../../store/slices/subscriptions/asyncThunks";

const StepBilling = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const createAccountPending = useSelector(selectCreateAccountPending);
  const plans = useSelector(selectedPlans);
  const [tab, setTab] = useState(0);

  const [users, setUsers] = useState(1);
  const [priceToPay, setPriceToPay] = useState(1);
  const [discountCode, setDiscountCode] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [packageType, setPackageType] = useState("");
  const toastId = useRef(null);
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";
  // const filteredTypePlans = plans.filter((plan) => plan.type === packageType);
  // const selectedPlan = filteredTypePlans.find((plan) => users < plan.maxUsers);
  const selectedPlan = plans.find((plan) => plan.type === packageType);

  const datePlusMonth = new Date();
  datePlusMonth.setMonth(datePlusMonth.getMonth() + 1);
  const datePlusYear = new Date();
  datePlusYear.setFullYear(datePlusYear.getFullYear() + 1);

  const options = { day: "2-digit", month: "short", year: "numeric" };
  const formattedDate = datePlusMonth.toLocaleDateString("en-GB", options);
  const formattedDatePlusYear = datePlusYear.toLocaleDateString(
    "en-GB",
    options
  );

  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);

  const [showError, setShowError] = useState(false);

  const loggedInBusinessUser = useSelector(isBusinessUserLoggedIn);
  const { company, state } = useSelector(selectBusinessUserInfo);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (token && token !== "upgrade") {
      setLoading(true);
      authService
        .validateCode({ code: token })
        .then((result) => {
          toast.success(t("successfully-verified-account"));
          setLoading(false);
          dispatch(fetchBusinessUserInfo());
        })
        .catch((err) => {
          console.log(err);
          setShowError(true);
          setLoading(false);
          toast.error(t("user-verification-error"));
          //   navigate("/sign-up");
        });
    } else {
      if (!loggedInBusinessUser) {
        navigation("/login", { state: { redirect: true } });
      }
    }
  }, [token]);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };
  const handleSliderChange = (value) => {
    setUsers(value);
  };
  const handleTab = () => {
    setPriceToPay(
      isChecked || discountPercentage
        ? selectedPlan.price * (isChecked ? 12 : 1) * users -
            (selectedPlan.price *
              users *
              (isChecked ? 12 : 1) *
              (isChecked ? 10 + discountPercentage : discountPercentage)) /
              100
        : selectedPlan.price * users
    );
    setTab(2);
  };
  const submitPayment = async (tokenId, paymentMethodId) => {
    try {
      setLoading(true);
      // const result = await subscriptionService.assignPlan({
      //   planId: selectedPlan.id,
      //   // maxNumberOfUsers: users,
      //   ...(tokenId && { cardTokenId: tokenId }),
      //   ...(paymentMethodId && { paymentMethodId }),
      //   // planPeriod: isChecked ? "YEARLY" : "MONTHLY",
      //   couponCode: isCouponValid ? discountCode : null,
      // });

      dispatch(
        assignSubscriptionPlan({
          planId: selectedPlan.id,
          ...(tokenId && { cardTokenId: tokenId }),
          ...(paymentMethodId && { paymentMethodId }),
          couponCode: isCouponValid ? discountCode : null,
        })
      )
        .unwrap()
        .then((data) => {
          if (data.data.url) {
            window.open(data.data.url, "_self");
          } else if (data.data.tapId) {
            navigation("/sign-up/final?tap_id=" + data.data.tapId);
          } else {
            openToast(toastId, "info", t("changed-subscription-plan"));
            setLoading(false);
          }
          setLoading(false);
        })
        .catch((err) => {
          openToast(
            toastId,
            "delivery-details",
            "error",
            t("error-occurred-while-changing-plan")
          );
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      openToast(
        toastId,
        "delivery-details",
        "error",
        t("error-occurred-while-changing-plan")
      );
      setLoading(false);
      console.log(err);
    }
  };

  const submitSubscriptionWithoutPayment = async (receipt) => {
    try {
      const result = await subscriptionService.assignPlanWithoutPayment({
        planId: selectedPlan.id,
        // maxNumberOfUsers: users,
        receipt: receipt,
        // planPeriod: isChecked ? "YEARLY" : "MONTHLY",
        couponCode: isCouponValid ? discountCode : null,
      });
      navigation("/sign-up/final?bank_transfer=true");
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const validateCoupon = async () => {
    setLoading(true);
    try {
      const result = await couponServices.isCouponValid({
        coupon: discountCode,
        product: "SUBSCRIPTION",
      });
      if (result.isValid) {
        toast.success(t(result.message));
        setIsCouponValid(true);
        setDiscountPercentage(result.data.discount);
      } else {
        toast.info(t(result.message));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      openToast(toastId, "validate-coupon", "error", t(err.message));
    }
  };

  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  /* SCROLL ANIMATION */

  const [isIntersecting, setIsIntersecting] = useState(false);

  const ref = useRef(null);

  const handleIntersection = (setIntersecting, ref) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      { rootMargin: "-300px" }
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  };

  useEffect(() => handleIntersection(setIsIntersecting, ref, "slide-in"), []);

  useEffect(() => {
    const handleClassToggleDiv = (isIntersecting, ref, className) => {
      ref.current.querySelectorAll("div").forEach((el) => {
        if (isIntersecting) {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      });
    };

    handleClassToggleDiv(isIntersecting, ref, "slide-in");
  }, [isIntersecting]);

  const [headerVisible, setHeaderVisible] = useState(true);
  const [lastScrollUpY, setLastScrollUpY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(null);
  const lastScrollTopRef = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      const lastScrollTop = lastScrollTopRef.current;

      if (currentScrollTop === 0) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
        setScrollDirection(null);
        lastScrollTopRef.current = currentScrollTop;
        return;
      }

      if (currentScrollTop > lastScrollTop) {
        setScrollDirection("down");
        setLastScrollUpY(currentScrollTop);
      } else {
        setScrollDirection("up");
      }

      if (scrollDirection === "up" && currentScrollTop < lastScrollUpY - 350) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
      }

      if (
        scrollDirection === "down" &&
        currentScrollTop > lastScrollUpY + 350
      ) {
        setHeaderVisible(false);
      }

      lastScrollTopRef.current = currentScrollTop;
    };

    const handleScrollDebounced = () => {
      requestAnimationFrame(handleScroll);
    };

    window.addEventListener("scroll", handleScrollDebounced);

    return () => {
      window.removeEventListener("scroll", handleScrollDebounced);
    };
  }, [scrollDirection]);

  return (
    <>
      {(createAccountPending || loading) && <Loader disableScroll />}

      <HeaderWrapper
        style={{
          opacity: headerVisible ? 1 : 0,
          transition: "opacity 0.5s",
        }}
      >
        <Header
          showLogin
          showNav
          color
          showUser
          showCart
          showFavorite
          showSignUp
          display={headerVisible}
        />
      </HeaderWrapper>

      <MainContainer $upgradePackage={showUpgradeBar && diff > 0 && state}>
        <Container>
          {company?.packageType !== "Free" &&
            token &&
            token !== "upgrade" &&
            !showError && (
              <div style={{ width: "100%", padding: "20px" }}>
                <ScreenContent>
                  <ErrorContainer>
                    <Title>{t("choose-the-plan")}</Title>

                    <Form>
                      <Message>{t("successfully-verified-account")}</Message>
                      <Success
                        style={{ width: 100, height: 100 }}
                        src={SuccessImg}
                        alt="success"
                      />
                    </Form>
                    <ButtonContainer>
                      <Button
                        onClick={() => {
                          dispatch(fetchBusinessUserInfo());
                          navigation("/admin-portal/dashboard");
                        }}
                      >
                        {loading ? (
                          <Loading height={30} />
                        ) : (
                          t("go-to-dashboard")
                        )}
                      </Button>
                    </ButtonContainer>
                  </ErrorContainer>
                </ScreenContent>
              </div>
            )}

          {showError && (
            <div style={{ width: "100%", padding: "20px" }}>
              <ScreenContent>
                <ErrorContainer>
                  <Title>{t("link-not-valid")}</Title>
                  <Text>
                    {t("for-more-information-contact-us-on")}{" "}
                    <Link>hello@ygii.app</Link>
                  </Text>
                </ErrorContainer>
              </ScreenContent>
            </div>
          )}

          {!showError &&
            ((token && token === "upgrade") ||
              (token !== "upgrade" && company?.packageType === "Free")) && (
              <Screen>
                <ScreenContent>
                  <TitleContainer>
                    <Row
                      $ar={ar}
                      style={{ opacity: 0, cursor: "default", width: "1rem" }}
                    >
                      <Skip>{t("skip")}</Skip>
                      <Chevron $ar={ar} />
                    </Row>
                    <SkipContainer>
                      <Title>{t("choose-the-plan")}</Title>
                    </SkipContainer>
                    <Row
                      $ar={ar}
                      onClick={() => navigation("/admin-portal/dashboard")}
                    >
                      <Skip>{t("skip")}</Skip>
                      <Chevron $ar={ar} />
                    </Row>
                  </TitleContainer>
                  <Gap>
                    {tab === 0 && (
                      <PricePlan
                        startFree={() => {
                          if (loggedInBusinessUser) {
                            setPackageType("Free");
                            setModalOpen(true);
                          } else {
                            navigation("/sign-up");
                          }
                        }}
                        startPremium={() => {
                          if (loggedInBusinessUser) {
                            setPackageType("Premium");
                            setTab(2);
                          } else {
                            navigation("/sign-up");
                          }
                        }}
                        startEnterprise={() => {
                          if (loggedInBusinessUser) {
                            setPackageType("Enterprise");
                            setTab(2);
                          } else {
                            navigation("/sign-up");
                          }
                        }}
                      />
                    )}
                    {/* {tab === 1 && (
                      <>
                        {users !== "500" ? (
                          <HeaderText>
                            {users} {t("users")}
                          </HeaderText>
                        ) : (
                          <HeaderText>
                            {t("over")} 500 {t("users")}
                          </HeaderText>
                        )}
                        <Slider
                          type="range"
                          min="1"
                          max="500"
                          value={users}
                          onChange={(e) => handleSliderChange(e.target.value)}
                          step="1"
                        />
                        {users !== "500" ? (
                          <>
                            <TextContainer>
                              <Text>
                                {`${priceFormatter(
                                  selectedPlan.price * users
                                )} ${getCurrencySymbol("sar")}`}{" "}
                                {t("month")},{" "}
                                <StyledText>
                                  {t("billed")}{" "}
                                  {isChecked
                                    ? t("annually")
                                    : t("monthly-small")}
                                </StyledText>
                              </Text>
                              <Text>
                                {`${priceFormatter(
                                  selectedPlan.price * users * 12
                                )} ${getCurrencySymbol("sar")}`}{" "}
                                {t("annually-for")} {users} {t("users-small")},{" "}
                                {selectedPlan.type} {t("package")}, {t("at")}{" "}
                                {`${priceFormatter(
                                  selectedPlan.price
                                )} ${getCurrencySymbol("sar")}`}{" "}
                                {t("per-user-month")}
                              </Text>
                            </TextContainer>
                            <PaymentsContainer>
                              <Payments>{t("payments")}:</Payments>
                              <Row2>
                                <StyledDate>{t("monthly")}</StyledDate>
                                <Switch
                                  activeBoxShadow={"0px 0px 2px 3px #8A4B5E"}
                                  onColor="#8A4B5E"
                                  checkedIcon={false}
                                  uncheckedIcon={false}
                                  onChange={handleChange}
                                  checked={isChecked}
                                />
                                <StyledDate>{t("yearly")}</StyledDate>
                                {!isChecked && (
                                  <Invisible>
                                    <InvisibleText>
                                      {t("10%-discount")}
                                    </InvisibleText>
                                  </Invisible>
                                )}
                                {isChecked && (
                                  <DiscountContainer>
                                    <Discount>{t("10%-discount")}</Discount>
                                  </DiscountContainer>
                                )}
                              </Row2>
                            </PaymentsContainer>
                            <InputContainer>
                              <Input
                                placeholder={t("insert-code")}
                                value={discountCode}
                                onChange={(e) =>
                                  setDiscountCode(e.target.value)
                                }
                              />
                              <Button onClick={validateCoupon}>
                                {t("apply")}
                              </Button>
                              {isCouponValid && (
                                <DiscountText>
                                  {t("DISCOUNT")}: {discountPercentage}%
                                </DiscountText>
                              )}
                            </InputContainer>
                            {isChecked || discountPercentage ? (
                              <Text>
                                {t("pay-now")}:{" "}
                                <StyledText
                                  style={{
                                    textDecoration: isChecked
                                      ? "line-through"
                                      : "none",
                                  }}
                                >
                                  {`${priceFormatter(
                                    selectedPlan.price *
                                      (isChecked ? 12 : 1) *
                                      users
                                  )} ${getCurrencySymbol("sar")}`}
                                </StyledText>{" "}
                                {(isChecked || discountPercentage) && (
                                  <GreenText>
                                    {`${priceFormatter(
                                      selectedPlan.price *
                                        (isChecked ? 12 : 1) *
                                        users -
                                        (selectedPlan.price *
                                          users *
                                          (isChecked ? 12 : 1) *
                                          (isChecked
                                            ? 10 + discountPercentage
                                            : discountPercentage)) /
                                          100
                                    )} ${getCurrencySymbol("sar")}`}
                                  </GreenText>
                                )}{" "}
                                <StyledTextSmall>
                                  ({t("valid-to")} {formattedDatePlusYear})
                                </StyledTextSmall>
                              </Text>
                            ) : (
                              <Text>
                                {t("monthly-payments")}:{" "}
                                <StyledText
                                  style={{
                                    textDecoration: isChecked
                                      ? "line-through"
                                      : "none",
                                  }}
                                >
                                  {`${priceFormatter(
                                    selectedPlan.price * users
                                  )} ${getCurrencySymbol("sar")}`}
                                </StyledText>{" "}
                                <StyledTextSmall>
                                  ({t("valid-to")} {formattedDate})
                                </StyledTextSmall>
                              </Text>
                            )}
                            <Button onClick={handleTab}>
                              {t("subscribe")}
                            </Button>
                          </>
                        ) : (
                          <>
                            <TextColumn>
                              <LargeText>
                                {t("contact-us-at")} <Link>hello@ygii.app</Link>
                              </LargeText>
                              <LargeText>
                                {t("or-schedule-a-call-with-us")}{" "}
                                <Linked
                                  onClick={() => {
                                    window.open(
                                      "https://calendly.com/abdulkareem-2",
                                      "_blank"
                                    );
                                  }}
                                >
                                  {t("here")}
                                </Linked>
                              </LargeText>
                            </TextColumn>
                            <InputContainer>
                              <Input
                                placeholder={t("insert-code")}
                                value={discountCode}
                                onChange={(e) =>
                                  setDiscountCode(e.target.value)
                                }
                              />
                              <Button>{t("apply")}</Button>
                            </InputContainer>
                            <Button onClick={handleTab}>
                              {t("subscribe")}
                            </Button>
                          </>
                        )}
                      </>
                    )} */}
                    {tab === 2 && (
                      <PaymentsComponent
                        priceToPay={priceToPay}
                        onBack={() => setTab(0)}
                        onComplete={(data) => {
                          if (data.tokenId) {
                            submitPayment(data.tokenId);
                          }
                          if (data.paymentMethodId) {
                            submitPayment(null, data.paymentMethodId);
                          }

                          if (data.receipt) {
                            submitSubscriptionWithoutPayment(data.receipt);
                          }
                        }}
                        setLoading={setLoading}
                      />
                    )}
                  </Gap>
                </ScreenContent>
              </Screen>
            )}
        </Container>
        <StyledContainer ref={ref}>
          <TitleWrapper>
            <StyledTitle>{t("ultimate-gifting-platform")}</StyledTitle>
            <Title>
              {t("innovative-features-to-transform-your-gifting-experience")}
            </Title>
          </TitleWrapper>
          <FeaturesContainer>
            <FeaturesTitles $ar={ar}>
              <Feature>{t("features")}</Feature>
              <FeatureTitle>{t("recipients")}</FeatureTitle>
              <FeatureTitle>{t("company-users")}</FeatureTitle>
              <FeatureTitle>{t("delivery-fees")}</FeatureTitle>
              <FeatureTitle>{t("procurement-discounts")}</FeatureTitle>
              <FeatureTitle>{t("branded-gifts")}</FeatureTitle>
              <FeatureTitle>{t("account-manager")}</FeatureTitle>
            </FeaturesTitles>
            <Card $ar={ar}>
              <Name>{t("free")}</Name>
              <Value>100</Value>
              <Value>1</Value>
              <Value>35 SAR</Value>
              <Wrapper>
                <Image src={No} />
              </Wrapper>
              <Wrapper>
                <Yes height={42} width={42} />
              </Wrapper>
              <Wrapper>
                <Image src={No} />
              </Wrapper>
            </Card>
            <Card $ar={ar}>
              <Name>{t("premium")}</Name>
              <Value>{t("unlimited")}</Value>
              <Value>{t("unlimited")}</Value>
              <Value>{t("free")}</Value>
              <Value>{ar ? t("up-to-5") + " %5" : t("up-to-5")}</Value>
              <Wrapper>
                <Yes height={42} width={42} />
              </Wrapper>
              <Wrapper>
                <Image src={No} />
              </Wrapper>
            </Card>
            <Card $ar={ar}>
              <Name>{t("platinum")}</Name>
              <Value>{t("unlimited")}</Value>
              <Value>{t("unlimited")}</Value>
              <Value>{t("free")}</Value>
              <Value>{ar ? t("up-to-20") + " %20" : t("up-to-20")}</Value>
              <Wrapper>
                <Yes height={42} width={42} />
              </Wrapper>
              <Wrapper>
                <Yes height={42} width={42} />
              </Wrapper>
            </Card>
          </FeaturesContainer>
        </StyledContainer>

        <ModalConfirm
          title={"Are you sure you want to change subscription plan?"}
          open={modalOpen}
          onClickNo={() => {
            setModalOpen(false);
          }}
          close={() => {
            setModalOpen(false);
          }}
          onClickYes={() => {
            setModalOpen(false);
            submitPayment();
          }}
        />
      </MainContainer>
      <Footer />
    </>
  );
};

export default StepBilling;
