import React, { useEffect } from "react";
import {
  Input,
  ButtonConfirm,
  InputContainer,
  Select,
  ContentContainer,
  Option,
  StyledButtonContainer,
} from "../../screens/AdminPortal/RecipientsPage/RecipientsPage.styles";
import PhoneNumberInput from "../PhoneNumberInput/PhoneNumberInput";
import { departments } from "../../utils/constants";
import { useSelector } from "react-redux";
import {
  addRecipientLoading,
  editRecipientLoading,
} from "../../store/slices/recipients/slice";
import { ReactComponent as Loading } from "../../assets/icons/loader-white.svg";

const RecipientForm = ({
  t,
  email,
  setEmail,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  mobileNumber,
  setMobileNumber,
  setDepartment,
  handleConfirmClick,
  fetchedUsers,
  setSelectedUser,
  resetFormData,
  isEdit,
  recipient,
}) => {
  useEffect(() => {
    if (isEdit) {
      setEmail(recipient?.email);
      setFirstName(recipient?.firstName);
      setLastName(recipient?.lastName);
      setMobileNumber(recipient?.mobileNumber);
      setDepartment(recipient?.department);
      if (recipient.user) {
        setSelectedUser(recipient.user);
      }
    } else {
      setDepartment(departments[0].label);
    }
  }, [isEdit, recipient]);

  useEffect(() => {
    if (fetchedUsers?.length > 0 && !isEdit) {
      setSelectedUser(fetchedUsers[0]);
    }
  }, [fetchedUsers, isEdit, setSelectedUser]);

  const loadingAddRecipient = useSelector(addRecipientLoading);
  const loadingEditRecipient = useSelector(editRecipientLoading);

  return (
    <ContentContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <InputContainer>
          <Input
            id="firstName"
            name="firstName"
            placeholder={t("first-name")}
            value={firstName}
            onChange={(e) => {
              const { value } = e.target;
              setFirstName(value);
            }}
            autoCapitalize="none"
          />
        </InputContainer>
        <InputContainer>
          <Input
            id="lastName"
            name="lastName"
            placeholder={t("last-name")}
            value={lastName}
            onChange={(e) => {
              const { value } = e.target;
              setLastName(value);
            }}
            autoCapitalize="none"
          />
        </InputContainer>
        <InputContainer>
          <Input
            id="email"
            name="email"
            placeholder={t("email")}
            value={email}
            onChange={(e) => {
              const { value } = e.target;
              setEmail(value);
            }}
            autoCapitalize="none"
          />
        </InputContainer>

        <div style={{ position: "relative", width: "100%" }}>
          <InputContainer>
            <div>
              <div>
                <Select
                  id="userDropdown"
                  onChange={(event) => {
                    const selectedId = parseInt(event.target.value);

                    const department = departments?.find(
                      (value) => value.id === selectedId
                    );

                    setDepartment(department.label);
                  }}
                >
                  {recipient?.department && (
                    <Option>{recipient.department}</Option>
                  )}
                  {departments.map(
                    (dept) =>
                      recipient?.department !== dept.label && (
                        <Option key={dept.id} value={dept.id}>
                          {dept.label}
                        </Option>
                      )
                  )}
                </Select>
              </div>
            </div>
          </InputContainer>
        </div>
        <InputContainer>
          <PhoneNumberInput
            phone={mobileNumber ?? ""}
            setPhone={setMobileNumber}
            containerStyle={{
              backgroundColor: "#FFF",
              color: "#8A4B5E",
              borderRadius: 15,
            }}
          />
        </InputContainer>
        {fetchedUsers?.length >= 1 && (
          <div>
            <Select
              id="userDropdown"
              onChange={(event) => {
                const selectedUserId = parseInt(event.target.value);
                const user = fetchedUsers.find(
                  (user) => user.id === selectedUserId
                );
                setSelectedUser(user);
              }}
            >
              <Option value="">
                {isEdit && recipient.user
                  ? `${recipient.user.fullName} - @${recipient.user.username}`
                  : `${fetchedUsers[0].fullName} - @${fetchedUsers[0].username}`}
              </Option>
              {fetchedUsers
                ?.filter((user) => {
                  if (!isEdit && fetchedUsers[0]?.id === user.id) {
                    return false;
                  }
                  if (
                    isEdit &&
                    recipient.user &&
                    user.id === recipient.user.id
                  ) {
                    return false;
                  }
                  return true;
                })
                .map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.fullName} - @{user.username}
                  </Option>
                ))}
            </Select>
          </div>
        )}
      </div>
      <StyledButtonContainer>
        <ButtonConfirm
          $cancel
          onClick={() => {
            resetFormData();
          }}
        >
          {t("cancel")}
        </ButtonConfirm>
        <ButtonConfirm
          onClick={() => {
            handleConfirmClick();
          }}
        >
          {loadingAddRecipient || loadingEditRecipient ? (
            <Loading height={20} />
          ) : (
            <>{!isEdit ? t("confirm") : t("edit")}</>
          )}
        </ButtonConfirm>
      </StyledButtonContainer>
    </ContentContainer>
  );
};

export default RecipientForm;
