import {
  LOCAL_STORAGE_RECIPIENT_ACCESS_TOKEN,
  LOCAL_STORAGE_RECIPIENT_REFRESH_TOKEN,
} from "../../../utils/constants";
import {
  logoutRecipient,
  validateCode,
  signUpRecipient,
  postUserInfo,
  fetchUserInfo,
  login,
} from "./asyncThunks";

export const signUpRecipientBuilder = (builder) => {
  builder.addCase(signUpRecipient.pending, (state) => {
    state.createAccountPending = true;
  });
  builder.addCase(signUpRecipient.fulfilled, (state, action) => {
    const { accessToken } = action.payload;
    state.createAccountPending = false;
    state.loggedIn = true;
    state.token = accessToken;
  });
  builder.addCase(signUpRecipient.rejected, (state) => {
    state.createAccountPending = false;
  });
};

export const validateCodeBuilder = (builder) => {
  builder.addCase(validateCode.pending, (state) => {
    state.validateCodePending = true;
  });
  builder.addCase(validateCode.fulfilled, (state, action) => {
    const { accessToken, refreshToken, user } = action.payload;
    localStorage.setItem(LOCAL_STORAGE_RECIPIENT_ACCESS_TOKEN, accessToken);
    localStorage.setItem(LOCAL_STORAGE_RECIPIENT_REFRESH_TOKEN, refreshToken);
    state.userInfo = user;
    state.validateCodePending = false;
  });
  builder.addCase(validateCode.rejected, (state) => {
    state.validateCodePending = false;
  });
};

export const userInfoUpdateBuilder = (builder) => {
  builder.addCase(postUserInfo.pending, (state) => {
    state.userInfoUpdatePending = true;
  });
  builder.addCase(postUserInfo.fulfilled, (state) => {
    state.userInfoUpdatePending = false;
  });
  builder.addCase(postUserInfo.rejected, (state) => {
    state.userInfoUpdatePending = false;
  });
};

export const loginRecipientBuilder = (builder) => {
  builder.addCase(login.pending, (state) => {
    state.loginPending = true;
  });
  builder.addCase(login.fulfilled, (state, action) => {
    const { accessToken, refreshToken } = action.payload;
    state.loginPending = false;
    localStorage.setItem(LOCAL_STORAGE_RECIPIENT_ACCESS_TOKEN, accessToken);
    localStorage.setItem(LOCAL_STORAGE_RECIPIENT_REFRESH_TOKEN, refreshToken);
    state.loggedIn = true;
  });
  builder.addCase(login.rejected, (state) => {
    state.loginPending = false;
  });
};

export const logoutRecipientBuilder = (builder) => {
  builder.addCase(logoutRecipient.pending, (state) => {
    state.loggedIn = false;
  });
  builder.addCase(logoutRecipient.fulfilled, () => {
    localStorage.removeItem(LOCAL_STORAGE_RECIPIENT_ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_RECIPIENT_REFRESH_TOKEN);
  });
  builder.addCase(logoutRecipient.rejected, (state) => {
    localStorage.removeItem(LOCAL_STORAGE_RECIPIENT_ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_RECIPIENT_REFRESH_TOKEN);
  });
};

export const userInfoBuilder = (builder) => {
  builder.addCase(fetchUserInfo.pending, (state) => {
    state.gettingUserInfo = true;
  });
  builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
    state.gettingUserInfo = false;
    const data = action.payload;
    const { id, fullName, email, phone, dateOfBirth, gender, profilePhoto } =
      data.user;

    state.userInfo.id = id;
    state.userInfo.fullName = fullName;
    state.userInfo.email = email;
    state.userInfo.phone = phone;
    state.userInfo.dateOfBirth = dateOfBirth;
    state.userInfo.gender = gender;
    state.userInfo.profilePhoto = profilePhoto;
    state.loggedIn = true;
  });
  builder.addCase(fetchUserInfo.rejected, (state) => {
    state.gettingUserInfo = false;
  });
};
