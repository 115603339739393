import React from "react";
import { useTranslation } from "react-i18next";
import AppStore from "../assets/images/appstore.png";
import GooglePlay from "../assets/images/googleplay.png";
import Logo from "../assets/images/logo.png";
import Instagram from "../assets/images/instagram.png";
import Twitter from "../assets/images/twitter.png";
import TikTok from "../assets/images/linkedin.png";
import Linkedin from "../assets/images/tiktok.png";
import Copy from "../assets/images/copytext.png";

import {
  Footer,
  TitleDownload,
  TextDownload,
  Download,
  FooterContainer,
  TitleContainer,
  StoreContainer,
  TextFooter,
  FooterLeft,
  FooterColumn,
  ColumTitle,
  ColumnLink,
  FooterRow,
  Media,
  MediaContainer,
  CopyContainer,
  CopyText,
  Bg,
  Row,
  ColumnLinkHref,
  StyledDiv,
  Image,
} from "./Footer.styles";
import { useDispatch } from "react-redux";
import { countClickAction } from "../store/slices/common/asyncThunks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectBusinessUserInfo } from "../store/slices/businessUser/slice";

const FooterComponent = ({ ar }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const businessUser = useSelector(selectBusinessUserInfo);

  return (
    <FooterContainer>
      <Bg>
        <Download>
          <TitleContainer>
            <TitleDownload>{t("download")}</TitleDownload>
            <TextDownload>{t("add-wishes")}</TextDownload>
          </TitleContainer>
          <StoreContainer>
            <a
              href="https://apps.apple.com/vn/app/ygii-gifts-wishlists/id1609129551"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src={AppStore} alt="App Store" className="store" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.ygii&hl=en_US&pli=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src={GooglePlay} alt="img" className="store" />
            </a>
          </StoreContainer>
        </Download>
      </Bg>
      <StyledDiv>
        <Footer $ar={ar}>
          <Row>
            <FooterLeft>
              <Image src={Logo} alt="logo" width={100} height={73} />
              <TextFooter>{t("unlock-power")}</TextFooter>
            </FooterLeft>
            <FooterRow>
              <FooterColumn>
                <ColumTitle>{t("company")}</ColumTitle>

                <ColumnLink
                  onClick={() => {
                    navigation("/about-us");
                    // window.open(
                    //   "https://ygii-s3-bucket.s3.me-south-1.amazonaws.com/AboutUs.pdf",
                    //   "_blank"
                    // );
                  }}
                >
                  {t("about-us")}
                </ColumnLink>

                <ColumnLink
                  onClick={() => {
                    navigation("/pricing");
                  }}
                >
                  {t("pricing")}
                </ColumnLink>

                {/* <ColumnLink
                  onClick={() => {
                    window.open(
                      "https://ygii-s3-bucket.s3.me-south-1.amazonaws.com/Help.pdf",
                      "_blank"
                    );
                  }}
                >
                  {t("help-support")}
                </ColumnLink> */}
              </FooterColumn>
              <FooterColumn>
                <ColumTitle>{t("products")}</ColumTitle>
                <ColumnLink
                  onClick={() => {
                    if (businessUser.loggedIn) {
                      navigation("/admin-portal/marketplace");
                    } else {
                      navigation("/marketplace");
                    }
                    dispatch(countClickAction("marketplace"));
                  }}
                >
                  {t("marketplace")}
                </ColumnLink>
                <ColumnLink
                  onClick={() => {
                    dispatch(countClickAction("giftingAsAService"));
                    navigation("/pricing");
                  }}
                >
                  {t("gifting-as")}
                </ColumnLink>
                <ColumnLink
                  onClick={() => {
                    window.open("https://www.ygii.app/", "_blank");
                  }}
                >
                  {t("user-app")}
                </ColumnLink>
              </FooterColumn>
              <FooterColumn>
                <ColumTitle>{t("contact")}</ColumTitle>
                <ColumnLink
                  onClick={() => {
                    dispatch(countClickAction("demoRequest"));

                    window.open(
                      "https://calendly.com/abdulkareem-2/demo",
                      "_blank"
                    );
                  }}
                >
                  {t("request-demo")}
                </ColumnLink>

                <ColumnLinkHref href={"mailto:hello@ygii.app"}>
                  {t("contact-us")}
                </ColumnLinkHref>
                <ColumnLink
                  onClick={
                    () => navigation("/privacy-policy")
                    // window.open("https://www.ygii.app/privacy-policy", "_blank")
                  }
                >
                  {t("privacy-policy")}
                </ColumnLink>
              </FooterColumn>
            </FooterRow>
          </Row>
          <div>
            <MediaContainer>
              <Media>
                <a
                  href="https://www.instagram.com/ygiiapp/?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={Instagram} alt="instagram" />
                </a>
                <a
                  href="https://twitter.com/ygiiapp?lang=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={Twitter} alt="twitter" />
                </a>
                <a
                  href="https://www.tiktok.com/@ygiiapp?lang=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={TikTok} alt="tiktok" />
                </a>
                <a
                  href="https://www.linkedin.com/company/ygiiapp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={Linkedin} alt="linkedin" />
                </a>
              </Media>
            </MediaContainer>
            <CopyContainer>
              <Image src={Copy} alt="copy" />
              <CopyText>{t("copy-text")}</CopyText>
            </CopyContainer>
          </div>
        </Footer>
      </StyledDiv>
    </FooterContainer>
  );
};

export default FooterComponent;
