import { toast } from "react-toastify";
import { api } from "../../api";

api.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getMarketplaceItems: build.query({
      query: ({ page, search, sortBy, category }) =>
        `business/marketplace/items/all?page=${page}&search=${
          search ?? ""
        }&sortBy=createdAt&order=ASC&category=${category ?? ""}`,

      providesTags: ["marketplaceItems"],
      serializeQueryArgs: ({ queryArgs }) => {
        const newQueryArgs = { ...queryArgs };
        if (newQueryArgs.page) {
          delete newQueryArgs.page;
        }
        return newQueryArgs;
      },
      merge: (currentCache, newItems) => {
        let tmp = { ...currentCache };
        if (newItems.page === 1) {
          tmp.data = newItems.data;
        } else {
          tmp.data = [...tmp.data, ...newItems.data];
        }
        tmp.maxReached = newItems.maxReached;
        return tmp;
      },
      forceRefetch({ currentArg, previousArg }) {
        if (!previousArg) {
          return true;
        }

        if (currentArg?.page && previousArg?.page) {
          return currentArg.page > previousArg.page;
        } else {
          return currentArg !== previousArg;
        }
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          toast.error(err.error?.data?.message);
        }
      },
    }),
    favoriteItem: build.mutation({
      query: ({ id }) => ({
        url: `business/marketplace/favorite`,
        method: "POST",
        body: { marketplaceItemId: id },
      }),
      onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
        const entries = api.util.selectInvalidatedBy(getState(), [
          "marketplaceItems",
        ]);
        for (const { originalArgs } of entries) {
          const patchResult = dispatch(
            api.util.updateQueryData(
              "getMarketplaceItems",
              originalArgs,
              (draft) => {
                const updatedData = draft.data.map((item) => {
                  if (item.id === id) {
                    return { ...item, favorite: !item.favorite };
                  }
                  return item;
                });

                Object.assign(draft, { ...draft, data: updatedData });
              }
            )
          );
          queryFulfilled.catch(patchResult.undo);
        }
      },
    }),

    getCategories: build.query({
      query: ({ search }) =>
        `business/marketplace/categories?search=${search ?? ""}`,
      providesTags: ["categories"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          toast.error(err.error?.data?.message);
        }
      },
    }),
  }),
});

export const {
  useGetMarketplaceItemsQuery,
  useFavoriteItemMutation,
  useGetCategoriesQuery,
} = api;
