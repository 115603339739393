import React from "react";
import Modal from "../Modal/Modal";
import {
  ContentContainer,
  Title,
  TitleContainer,
  CloseContainer,
  Column,
  StyledButton,
  ButtonDiv,
} from "../InviteUserModal/InviteUserModal.style";

import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectedRemoveCouponLoading } from "../../store/slices/coupon/couponSlice";
import { ReactComponent as Loader } from "../../assets/icons/loaderWhite.svg";

const AreYouSureModal = ({ modalRef, onClick, title }) => {
  const { t } = useTranslation();
  const loading = useSelector(selectedRemoveCouponLoading);

  return (
    <Modal
      ref={modalRef}
      onBack={() => {
        modalRef.current.hide();
      }}
    >
      <ContentContainer>
        <CloseContainer
          onClick={!loading ? () => modalRef.current.hide() : () => {}}
        >
          <Close className="close" fill="#fff" />
        </CloseContainer>

        <Column>
          <TitleContainer>
            <Title>{title}</Title>
          </TitleContainer>
          <ButtonDiv>
            {!loading && (
              <StyledButton onClick={() => modalRef.current.hide()}>
                {t("no")}
              </StyledButton>
            )}
            <StyledButton
              $disabled={!loading}
              onClick={!loading ? onClick : () => {}}
            >
              {!loading ? t("yes") : <Loader height={24} width={24} />}
            </StyledButton>
          </ButtonDiv>
        </Column>
      </ContentContainer>
    </Modal>
  );
};

export default AreYouSureModal;
