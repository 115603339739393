import React, { useEffect, useState } from "react";
import {
  Content,
  Text,
  TextContainer,
  TitleContainer,
  Message,
  ViewMoreText,
  ViewMoreContainer,
  FirstItem,
  LastTableItem,
  TableBodyRow,
  Chevron,
} from "../../screens/AdminPortal/CouponsPage/CouponsPage.styles";

import { useTranslation } from "react-i18next";
import { ReactComponent as HistoryIcon } from "../../assets/icons/history.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate, priceFormatter } from "../../utils/utils";
import { getAdminSubscriptionsAll } from "../../store/slices/subscriptions/asyncThunks";
import {
  selectedAllSubscriptionsData,
  selectedAllSubscriptionsLoading,
} from "../../store/slices/subscriptions/slice";
import EditModal from "../EditModal/EditModal";
import MiniLoader from "../MiniLoader/MiniLoader";
import Table from "../Table/Table";
import TableItem from "../Table/TableItem";

const ViewMoreSubscribers = ({ onBack, ar }) => {
  const { t } = useTranslation();
  const [data, setData] = useState("");
  const allSubscriptionsData = useSelector(selectedAllSubscriptionsData);
  const allSubscriptionsLoading = useSelector(selectedAllSubscriptionsLoading);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const search = queryParams.get("search");
  const expired = queryParams.get("expired");
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    dispatch(
      getAdminSubscriptionsAll({
        page: pageNumber,
        search: "",
        expired: expired,
      })
    );
    const query = { page: pageNumber, search: search, expired: expired };
    const queryString = new URLSearchParams(query).toString();
    const url = `/admin-portal/subscriptions?${queryString}`;

    navigate(url);
  };

  useEffect(() => {
    dispatch(
      getAdminSubscriptionsAll({ page, search: search, expired: expired })
    );
  }, [dispatch, page, search, expired]);

  const tableHeaderSubscriptions = [
    {
      label: `${t("company-name")}`,
      sortable: true,
      key: "companyName",
    },
    {
      label: `${t("employees")}`,
      sortable: true,
      key: "employees",
    },
    {
      label: `${t("wallet")}`,
      sortable: true,
      key: "wallet",
    },
    {
      label: `${t("expiry-date")}`,
      sortable: true,
      key: "expiryDate",
    },
    {
      label: `${t("type")}`,
      sortable: true,
      key: "type",
    },
    {
      label: `${t("orders")}`,
      sortable: true,
      key: "orders",
    },
    {
      label: `${t("num-of-users")}`,
      sortable: true,
      key: "numOfUsers",
    },
    {
      label: `${t("subscription-history")}`,
      sortable: true,
      key: "subsHistory",
    },
  ];

  return (
    <>
      {!allSubscriptionsLoading ? (
        <>
          {allSubscriptionsData?.length <= 0 ? (
            <Content>
              <TextContainer>
                {expired === "false" ? (
                  <Message>{t("you-currently-have-no-subscribers")}</Message>
                ) : (
                  <Message>{t("you-currently-have-no-churned-users")}</Message>
                )}
              </TextContainer>
            </Content>
          ) : (
            <>
              <TitleContainer>
                {expired === "false" ? (
                  <Text>
                    {t("subscribers")} ({allSubscriptionsData?.total})
                  </Text>
                ) : (
                  <Text>
                    {t("churned")} ({allSubscriptionsData?.total})
                  </Text>
                )}
                <ViewMoreContainer onClick={onBack}>
                  <Chevron $ar={!ar} />
                  <ViewMoreText>{t("back")}</ViewMoreText>
                </ViewMoreContainer>
              </TitleContainer>

              <Table
                marginTop={40}
                headerInformation={tableHeaderSubscriptions}
                totalCount={allSubscriptionsData?.total}
                currentPage={+page}
                setPage={handlePageChange}
                perPage={allSubscriptionsData?.perPage}
                param={"page"}
                noDataContent={t("no-data")}
                dataLoading={false}
                lastColumnAligned
                centerText
              >
                {allSubscriptionsData?.companySubscriptions?.map((val, key) => {
                  return (
                    <TableBodyRow
                      key={key}
                      onClick={() => {
                        setData(val);
                        setOpenModal(true);
                      }}
                    >
                      <FirstItem
                        hoverText={val?.company}
                        showHover
                        data={
                          val?.company.length > 25
                            ? val?.company.slice(0, 25) + "..."
                            : val?.company
                        }
                        center
                      />
                      <TableItem center data={val.employees} />
                      <TableItem
                        center
                        data={priceFormatter(val.wallet, false, 0) + " SR"}
                      />
                      <TableItem
                        center
                        data={formatDate(val.packageExpiration)}
                      />
                      <TableItem center data={t(val.type.toLowerCase())} />
                      <TableItem center data={val.orders} />

                      <TableItem data={val.numOfUsers || "/"} center />
                      <LastTableItem
                        onClickInfo={() =>
                          navigate(
                            `/admin-portal/subscriptions/company-history/${val.id}?page=1`
                          )
                        }
                        data={
                          <HistoryIcon
                            className="details-icon"
                            fill="#8A4B5E"
                            height={30}
                            width={30}
                            style={{ cursor: "pointer" }}
                          />
                        }
                        $offClick
                      />
                    </TableBodyRow>
                  );
                })}
              </Table>

              <EditModal
                open={openModal}
                close={() => setOpenModal(false)}
                data={data}
                resetData={() => {
                  setData(null);
                }}
              />
            </>
          )}
        </>
      ) : (
        <MiniLoader leftShift />
      )}
    </>
  );
};

export default ViewMoreSubscribers;
