import React, { useEffect, useRef, useState } from "react";
import {
  Title,
  CloseContainer,
  Price,
  Row,
  StyledButton,
  StyledRow,
  StyledPrice,
  Button,
  ButtonYes,
  Content,
  StyledInput,
  GenerateButton,
} from "./PriceBreakModal.styles";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { ReactComponent as Minus } from "../../assets/icons/minus.svg";
import Modal from "./Modal2";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { postQuotationWithMultipleItems } from "../../store/slices/instantQuotation/asyncThunks";
import { useSelector } from "react-redux";
import { selectedPostQuotationWithMultipleItemsPending } from "../../store/slices/instantQuotation/instantQuotationSlice";
import openToast from "../../utils/toast";
import AppLoader from "../AppLoader";
import { blobUrlToFile } from "../../utils/utils";

const GenerateQuotationModal = ({ open, close, minQuantity, items }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [paymentTerms, setPaymentTerms] = useState(1);
  const [deliveryCycle, setDeliveryCycle] = useState(1);
  const [quotationDays, setQuotationDays] = useState(1);
  const [delivery, setDelivery] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const toastId = useRef(null);

  const postQuotationWithMultipleItemsPending = useSelector(
    selectedPostQuotationWithMultipleItemsPending
  );

  useEffect(() => {
    if (minQuantity) {
      setQuotationDays(minQuantity);
    }
  }, [minQuantity, open]);

  const onClickPlus = ({ value, setValue }) => {
    setValue(+value + 1);
  };

  const onClickMinus = ({ value, setValue }) => {
    if (value > 1) {
      setValue(+value - 1);
    }
  };
  const handleClick = (isDelivery) => {
    setDelivery(isDelivery);
  };

  const handleInputChange = (e, setValue) => {
    const inputValue = e.target.value;

    if (!isNaN(inputValue) && inputValue !== "") {
      setValue(parseInt(inputValue, 10));
    }
  };

  const formatItemsForQuotation = () => {
    let cartItems = [];
    let logo;
    let logoName;
    for (const cartItem of items) {
      if (cartItem.isPackaging) {
        if (cartItem.packageLogo) {
          logo = cartItem.packageLogo;
          logoName = cartItem.packageLogoName;
        }
        cartItems.push({
          id: cartItem.id,
          isPackaging: true,
          photoUrl: cartItem.photo?.url,
          quantity: cartItem.quantity,
          itemPrice: cartItem.itemPrice,
          ...(cartItem.packageColor && { packageColor: cartItem.packageColor }),
        });
      } else {
        let marketplaceSubcategoryIds = [];
        if (cartItem.color && cartItem.color.id) {
          marketplaceSubcategoryIds.push(cartItem.color.id);
        }
        if (cartItem.size && cartItem.size.id) {
          marketplaceSubcategoryIds.push(cartItem.size.id);
        }
        if (cartItem.giftCard && cartItem.giftCard.id) {
          marketplaceSubcategoryIds.push(cartItem.giftCard.id);
        }
        cartItems.push({
          id: cartItem.id,
          photoUrl: cartItem.photo?.url,
          quantity: cartItem.quantity,
          marketplaceSubcategoryIds,
          branding: cartItem.uploadLogoUrl ? true : false,
        });
      }
    }

    return { quotationItems: cartItems, logo, logoName };
  };

  const onGetQuote = async () => {
    if (!companyName) {
      openToast(toastId, "get-quote", "error", t("company-name-is-required"));
      return;
    }

    if (minQuantity > quotationDays) {
      openToast(
        toastId,
        "get-quote",
        "error",
        t("Minimum quantity for this item is:") + minQuantity
      );
      return;
    }
    const { quotationItems, logo, logoName } = formatItemsForQuotation();
    let formData = new FormData();

    formData.append("items[]", JSON.stringify(quotationItems));
    formData.append("validityDays", quotationDays);
    formData.append("paymentTerms", paymentTerms);
    formData.append("delivery", delivery);
    formData.append("companyName", companyName);
    formData.append("deliveryDays", deliveryCycle);

    if (logo) {
      formData.append("file", await blobUrlToFile(logo, logoName));
    }

    dispatch(postQuotationWithMultipleItems(formData))
      .unwrap()
      .then((res) => {
        window.open(res.data.url, "_blank");
        close();
        setCompanyName("");
        setDelivery(true);
        setQuotationDays(1);
        setDeliveryCycle(1);
        setPaymentTerms(1);
      })
      .catch((err) =>
        openToast(toastId, "post-instant-quotation", "error", t(err.message))
      );
  };

  return (
    <Modal
      open={open}
      onClose={close}
      right
      width={"567px"}
      height={"100%"}
      padding={"50px 60px"}
      unset
    >
      <CloseContainer
        onClick={() => {
          if (!postQuotationWithMultipleItemsPending) {
            close();
            setCompanyName("");
            setDelivery(true);
            setQuotationDays(1);
            setDeliveryCycle(1);
            setPaymentTerms(1);
          } else {
            openToast(
              toastId,
              "instant-quote-modal",
              "error",
              t("cannot-quit")
            );
          }
        }}
      >
        <CloseIcon fill="white" width={20} height={20} />
      </CloseContainer>
      <Title>{t("instant-quote")}</Title>
      <Content $smallGap>
        <Row>
          <Price>{t("quotation-validity-days")}</Price>
          <StyledRow>
            <StyledButton
              onClick={() => {
                onClickMinus({
                  value: quotationDays,
                  setValue: setQuotationDays,
                });
              }}
            >
              <Minus />
            </StyledButton>
            <StyledPrice
              value={quotationDays}
              onChange={(e) => handleInputChange(e, setQuotationDays)}
              min={1}
            />
            <StyledButton
              onClick={() => {
                onClickPlus({
                  value: quotationDays,
                  setValue: setQuotationDays,
                });
              }}
            >
              <Plus />
            </StyledButton>
          </StyledRow>
        </Row>

        <Row>
          <Price>{t("delivery")}</Price>
          <StyledRow style={{ gap: 10 }}>
            <ButtonYes
              onClick={() => handleClick(true)}
              $active={delivery === true}
            >
              {t("yes")}
            </ButtonYes>

            <ButtonYes
              onClick={() => handleClick(false)}
              $active={delivery === false}
            >
              {t("no")}
            </ButtonYes>
          </StyledRow>
        </Row>

        <Row>
          <Price>{t("company-name")}</Price>
          <StyledInput
            value={companyName}
            placeholder={t("name-on-quote")}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </Row>

        <Row>
          <Price>{t("payment-terms")}</Price>
          <StyledRow>
            <StyledButton
              onClick={() => {
                onClickMinus({
                  value: paymentTerms,
                  setValue: setPaymentTerms,
                });
              }}
            >
              <Minus />
            </StyledButton>
            <StyledPrice
              value={paymentTerms}
              onChange={(e) => {
                handleInputChange(e, setPaymentTerms);
              }}
              min={1}
            />
            <StyledButton
              onClick={() => {
                onClickPlus({ value: paymentTerms, setValue: setPaymentTerms });
              }}
            >
              <Plus />
            </StyledButton>
          </StyledRow>
        </Row>

        <Row>
          <Price>{t("delivery-cycle")}</Price>
          <StyledRow>
            <StyledButton
              onClick={() => {
                onClickMinus({
                  value: deliveryCycle,
                  setValue: setDeliveryCycle,
                });
              }}
            >
              <Minus />
            </StyledButton>
            <StyledPrice
              value={deliveryCycle}
              onChange={(e) => {
                handleInputChange(e, setDeliveryCycle);
              }}
              min={1}
            />
            <StyledButton
              onClick={() => {
                onClickPlus({
                  value: deliveryCycle,
                  setValue: setDeliveryCycle,
                });
              }}
            >
              <Plus />
            </StyledButton>
          </StyledRow>
        </Row>

        <GenerateButton
          onClick={() => {
            if (!postQuotationWithMultipleItemsPending) {
              onGetQuote();
            }
          }}
          disabled={postQuotationWithMultipleItemsPending}
        >
          {!postQuotationWithMultipleItemsPending ? (
            t("get-quote")
          ) : (
            <AppLoader size={24} light />
          )}
        </GenerateButton>
      </Content>
    </Modal>
  );
};

export default GenerateQuotationModal;
