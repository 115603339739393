export const APPLICATION_NAME = "YGIIBUSINESS";
export const APPLICATION_NAME_NORMAL = "Ygii - Business";
export const APP_VERSION = "2.0.0";

export const LOCAL_STORAGE_ACCESS_TOKEN = `${APPLICATION_NAME}_ACCESS_TOKEN`;
export const LOCAL_STORAGE_REFRESH_TOKEN = `${APPLICATION_NAME}_REFRESH_TOKEN`;
export const LOCAL_STORAGE_RECIPIENT_ACCESS_TOKEN =
  "YGII_RECIPIENT_ACCESS_TOKEN";
export const LOCAL_STORAGE_RECIPIENT_REFRESH_TOKEN =
  "YGII_RECIPIENT_REFRESH_TOKEN";

export const LOCAL_STORAGE_PHONE_COUNTRY = `${APPLICATION_NAME}_PHONE_COUNTRY`;
export const LOCAL_STORAGE_SELECTED_LOCALE = `${APPLICATION_NAME}_SELECTED_LOCALE`;
export const SESSION_STORAGE_SELECTED_LOCALE = `${APPLICATION_NAME}_SELECTED_LOCALE`;
export const LOCAL_STORAGE_CART_ITEMS = `${APPLICATION_NAME}_CART_ITEMS`;

export const googlePlacesKey = "AIzaSyCR388mWBleCqk4xOU2ZBjoBM_Aiu6VNt4";

export const SPLASH_SCREEN_DURATION_IN_MILLIS = 400;

export const keyboardTypeEnum = {
  default: "default",
  email: "email-address",
  numeric: "number-pad",
  decimal: "decimal-pad",
  phone: "phone-pad",
};

export const MAX_FILE_SIZE_IN_BYTES = 5000;

export const UPLOAD_ALLOWED_EXTENSIONS = ["png", "jpg", "jpeg"];
export const genders = [
  { text: "Male", value: "male" },
  { text: "Female", value: "female" },
  { text: "Occasion", value: "occasion" },
];

export const currencies = [
  { value: "sar", text: "SAR", symbol: "SR" },
  { value: "usd", text: "USD", symbol: "$" },
  { value: "eur", text: "EUR", symbol: "€" },
  { value: "kwd", text: "KWD", symbol: "ك" },
  { value: "bhd", text: "BHD", symbol: "BD" },
  { value: "aed", text: "AED", symbol: "د.إ" },
  { value: "qar", text: "QAR", symbol: "﷼" },
  { value: "omr", text: "OMR", symbol: "﷼" },
  { value: "egp", text: "EGP", symbol: "£" },
  { value: "jod", text: "JOD", symbol: "Dinar" },
  { value: "gbp", text: "GBP", symbol: "£" },
  { value: "cny", text: "CNY", symbol: "¥" },
  { value: "rub", text: "RUB", symbol: "₽" },
  { value: "aud", text: "AUD", symbol: "$" },
  { value: "inr", text: "INR", symbol: "₹" },
];

export const getCurrencySymbol = (currencyValue) => {
  switch (currencyValue) {
    case currencies[0].value:
      return currencies[0].symbol;
    case currencies[1].value:
      return currencies[1].symbol;
    case currencies[2].value:
      return currencies[2].symbol;
    case currencies[3].value:
      return currencies[3].symbol;
    case currencies[4].value:
      return currencies[4].symbol;
    case currencies[5].value:
      return currencies[5].symbol;
    case currencies[6].value:
      return currencies[6].symbol;
    case currencies[7].value:
      return currencies[7].symbol;
    case currencies[8].value:
      return currencies[8].symbol;
    case currencies[9].value:
      return currencies[9].symbol;
    case currencies[10].value:
      return currencies[10].symbol;
    case currencies[11].value:
      return currencies[11].symbol;
    case currencies[12].value:
      return currencies[12].symbol;
    case currencies[13].value:
      return currencies[13].symbol;
    case currencies[14].value:
      return currencies[14].symbol;
    default:
      return "N/A";
  }
};

export const codeSourceType = {
  phone: "phone",
  email: "email",
};

export const locales = [
  { text: "English", value: "en" },
  { text: "العربية", value: "ar" },
];

export const seeAllItemsCategory = {
  category: "category",
  section: "section",
};

export const sectionNames = {
  ads: {
    id: 1,
    name: "ADS",
    label: "explore-the-most-popular-gifts",
  },
  hot: {
    id: 2,
    name: "HOT",
    label: "hot-right-now",
  },
  new: {
    id: 3,
    name: "NEW",
    label: "new-on-ygii",
  },
  browseMore: {
    id: 4,
    name: "BROWSE_MORE",
    label: "browse-more",
  },
};

export const PROFILE_VISIBILITY = {
  PRIVATE: "private",
  FRIENDS: "friends",
  PUBLIC: "public",
};

export const GIFT_CARD_CATEGORY = {
  GRADUATION: "graduation",
  BIRTHDAY: "birthday",
  OTHER: "other",
};
export const DYNAMIC_ISLAND_PADDING = 15;

export const marketplaceOrderStates = [
  { id: 1, value: "created" },
  { id: 2, value: "wrapping" },
  { id: 3, value: "sent" },
  { id: 4, value: "received" },
];

export const orderStates = {
  created: "created",
  wrapping: "wrapping",
  sent: "sent",
  received: "received",
};

export const marketplacePaymentStatus = [
  { id: 1, value: "pending" },
  { id: 2, value: "paid" },
  { id: 3, value: "paidFromWallet" },
  { id: 4, value: "declined" },
];

export const paymentStatus = {
  pending: "pending",
  paid: "paid",
  paidFromWallet: "paidFromWallet",
  declined: "declined",
};

export const billingPaymentStatus = [
  { id: 1, value: "pending" },
  { id: 2, value: "paid" },
  { id: 3, value: "declined" },
];

export const DEFAULT_LATITUDE = 24.774265;
export const DEFAULT_LONGITUDE = 46.738586;
export const MAP_ZOOM_LEVEL = 11;
export const MAP_LIBRARIES = ["places"];

export const giftingStatusObj = [
  { id: 1, value: "created" },
  { id: 2, value: "completed" },
  { id: 3, value: "paid" },
];

export const giftingStatus = {
  created: "created",
  completed: "completed",
  paid: "paid",
};

// exports.bookingStatus = {
//   active: "ACTIVE",
//   giftReceived: "GIFT_RECEIVED",
//   giftNotReceived: "GIFT_NOT_RECEIVED",
//   bookingCanceled: "BOOKING_CANCELED",
//   itemRemoved: "ITEM_REMOVED",
// };

// exports.ENV_TYPE = {
//   PRODUCTION: "production",
//   DEVELOPMENT: "development",
// };

// exports.GIFT_TYPES = {
//   ORDER: "order",
//   BOOKING: "booking",
// };

// exports.VIDEO = "video";
// exports.PHOTO = "photo";

export const departments = [
  { id: 1, value: "developer", label: "Developer" },
  { id: 2, value: "engineer", label: "Engineer" },
  { id: 3, value: "marketing", label: "Marketing" },
  { id: 4, value: "designer", label: "Designer" },
  { id: 5, value: "owner", label: "Owner" },
  { id: 6, value: "employee", label: "Employee" },
  { id: 7, value: "operations", label: "Operations" },
  { id: 8, value: "humanResources", label: "Human Resources" },
  { id: 9, value: "finance", label: "Finance" },
];
