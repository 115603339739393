import { createAsyncThunk } from "@reduxjs/toolkit";
import businessUserService from "../../../api/services/businessUser";
import companyService from "../../../api/services/company";
import authService from "../../../api/services/auth";

export const fetchBusinessUserInfo = createAsyncThunk(
  "businessUser/fetchBusinessUserInfo",
  async (requestData) => {
    return await businessUserService.fetchBusinessUserInfo(requestData);
  }
);

export const getInvitationLink = createAsyncThunk(
  "businessUser/getInvitationLink",
  async (requestData) => {
    return await businessUserService.getInvitationLink(requestData);
  }
);

export const editCompanyInfo = createAsyncThunk(
  "company/editCompanyInfo",
  async (requestData) => {
    return await companyService.editCompanyInfo(requestData);
  }
);

export const editCompanyProfile = createAsyncThunk(
  "company/editCompanyProfile",
  async (requestData) => {
    return await companyService.editCompanyProfile(requestData);
  }
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  thunkAPI.dispatch({ type: "logout" });
  thunkAPI.dispatch({ type: "reset" });
  return await authService.logout();
});
