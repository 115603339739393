import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as FavoriteSvg } from "../../assets/icons/heartUnSelected.svg";
import { ReactComponent as CartSvg } from "../../assets/icons/cart.svg";
import { ReactComponent as Dots } from "../../assets/icons/dots-y.svg";
import { ReactComponent as CloseSvg } from "../../assets/icons/close.svg";
import { ReactComponent as SearchSvg } from "../../assets/icons/whiteSearch.svg";

export const DotContainer = styled.div`
  display: none;
  z-index: 1000;
  margin-top: 20px;

  @media (max-width: 870px) {
    justify-content: center;
    align-items: center;

    display: flex;
    height: fit-content;
    padding: 9px;
    bottom: 15px;
    left: ${(props) => (props.$ar ? "unset" : "25px")};
    right: ${(props) => (props.$ar ? "25px" : "unset")};
    position: absolute;
  }
`;

export const HeaderContainer = styled.div`
  background: ${(props) => (props.$color ? "#8A4B5E" : "#fff")};
  display: flex;

  top: 0;
  flex-direction: row;
  z-index: 1000;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* width: 1440px; */
  min-height: 7.5rem;
  padding: ${(props) =>
    props.$upgradePackage ? "7.2rem 16rem 1.2rem 16rem" : "1.2rem 16rem"};
  color: #8a4b5e;
  gap: 3rem;
  transition: all 0.25s ease-in-out;
  @media (max-width: 1320px) {
    padding: ${(props) =>
      props.$upgradePackage ? "7.2rem 8rem 1.2rem 8rem" : "1.2rem 8rem"};
  }
  @media (max-width: 1125px) {
    padding: ${(props) =>
      props.$upgradePackage ? "7.2rem 1.2rem 1.2rem 1.2rem" : "1.2rem"};
  }
  @media (max-width: 890px) {
    gap: 1rem;
  }
  @media (max-width: 870px) {
    padding: 0px 35px 0px 35px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-right: ${(props) => (props.$ar ? "-2.5rem" : "0rem")};
`;
export const Text2 = styled.span`
  user-select: none;
  color: ${(props) => (props.$color ? "#fff" : "#8A4B5E")};
  font-family: "Avenir";
  font-weight: 400;
  font-size: 22px;
  letter-spacing: -0.18px;
  height: 5rem;

  margin-right: ${(props) => (props.$ar ? "2rem" : "1rem")};
  display: flex;
  align-items: center;
  justify-content: center;
  &:active {
    opacity: 0.5;
  }
  transition: all 0.2s ease-in-out;
  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }
  cursor: pointer;
  /* @media (max-width: 1635px) {
    height: 4.5rem;
    font-size: 18px !important;
  }
  @media (max-width: 1425px) {
    height: 4rem;
    font-size: 14px !important;
  } */
  @media (max-width: 890px) {
    font-size: 18px;
  }
  @media (max-width: 870px) {
    display: none;
  }
`;
export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  margin-top: 3rem;
`;
export const CartContainer = styled.div`
  // display: none;
  cursor: pointer;
  position: relative;
  @media (max-width: 870px) {
    display: flex;
  }
`;

export const Text22 = styled.span`
  user-select: none;
  color: ${(props) => (props.$color ? "#fff" : "#8A4B5E")};
  font-family: "Avenir";
  font-size: 22px;
  font-weight: 400;
  letter-spacing: -0.18px;
  &:active {
    opacity: 0.5;
  }
`;
export const Button = styled.button`
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0px 1.2rem 24rem -0.6rem rgba(0, 0, 0, 0.25);
  user-select: none;
  border-radius: 3rem;
  letter-spacing: -0.18px;

  font-family: "Avenir";
  font-size: 22px;
  font-weight: 400;

  border: none;
  outline: none;
  transition: all 0.2s ease-in-out;
  height: 5rem;
  width: ${(props) => (props.$ar ? "15rem" : "13rem")};
  align-items: center;
  justify-content: center;
  display: flex;

  &:active {
    scale: 0.95;
  }

  /* @media (max-width: 1635px) {
    font-size: 18px !important;
    height: 4.5rem;
    width: 10rem;
  }
  @media (max-width: 1425px) {
    font-size: 14px !important;
    height: 4rem;
    width: 8rem;
  } */
  @media (max-width: 890px) {
    width: ${(props) => (props.$ar ? "13rem" : "11rem")};
    font-size: 18px;
    height: 4rem;
  }
  @media (max-width: 870px) {
    display: none;
  }
`;
export const Button2 = styled.button`
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0px 1.2rem 24rem -0.6rem rgba(0, 0, 0, 0.25);
  user-select: none;
  border-radius: 3rem;
  padding: 1rem 3rem;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 0px;
  transition: transform 0.3s ease;
  font-family: "Avenir-Heavy", sans-serif;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.5%;
  color: #8a4b5e;
  &:hover {
    box-shadow: 0px 1.2rem 2.4rem -0.6rem rgba(0, 0, 0, 0.25);
    transform: scale(1.05);
  }
  &:active {
    transform: translate(0em, 0.2em);
  }
`;
export const BusinessRow = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  // margin-right: 2rem;
`;
export const Business = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const LogoText = styled.span`
  color: ${(props) => (props.$color ? "#fff" : "#8A4B5E")};
  font-family: "Avenir-Book";
  font-size: 20px;
  letter-spacing: -0.178px;

  margin-top: 15px;
  letter-spacing: -0.018rem;
`;
export const Row2 = styled.div`
  display: flex;
  gap: 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Row = styled.div`
  display: flex;
  gap: 3rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 870px) {
    display: none;
  }
`;
export const DropDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  // margin-left: 8rem;
  gap: 25px;
  @media (max-width: 1425px) {
    gap: 15px;
  }
  @media (max-width: 870px) {
    display: none;
  }
`;

export const LogoIcon = styled.div`
  @media (max-width: 870px) {
    margin-left: ${(props) => (props.$loggedIn ? "24px" : "0px")};
  }
`;

export const DropDiv2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 6rem;
`;

export const DropRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DropdownContainer = styled.div`
  position: absolute;
  top: 5%;
  left: 220px;
  z-index: 1501;
  padding: 2.4rem 2rem;
  border: 0.2rem solid #f1f1f1;
  background-color: white;
  box-shadow: 0px 1rem 1.5rem rgba (255, 250, 250, 0.2);
  border-radius: 3rem;
`;

export const DropdownContent = styled.div`
  display: flex;
  gap: 5.4rem;
  flex-direction: column;
`;

export const TitleDropdown = styled.span`
  font-family: "Avenir-Medium";
  font-style: normal;
  user-select: none;
  font-size: 2.2rem;
  line-height: 3rem;
  letter-spacing: -0.177777px;
  color: #a7a7a7;
`;

export const DropdownOptions = styled.div`
  display: flex;
  gap: 13.6rem;
  flex-direction: row;
`;

export const ColumnDropdown = styled.div`
  gap: 2.4rem;
  display: flex;
  flex-direction: column;
`;

export const Option = styled.div`
  cursor: pointer;
`;

export const OptionRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  flex-direction: row;
`;

export const OptionColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Elipse = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  background: rgba(104, 104, 172, 0.17);
`;

export const OptionName = styled.span`
  font-family: "Avenir-Heavy";
  user-select: none;
  font-style: normal;
  font-weight: 800;
  font-size: 2.2rem;
  line-height: 3rem;
  letter-spacing: -0.177777px;

  color: #8a4b5e;
`;

export const Elipse2 = styled.div`
  width: 4.5rem;
  background: transparent;
`;

export const OptionInfo = styled.span`
  font-family: "Avenir-Heavy";
  font-style: normal;
  user-select: none;
  font-weight: 300;
  color: #696969;
  font-size: 1.8rem;
  line-height: 2.5rem;
  letter-spacing: -0.177777px;
`;

export const LogoutButton = styled.div`
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  font-family: "Avenir";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.067px;
  @media (max-width: 870px) {
    display: none;
  }
`;
export const LogoutButton2 = styled.div`
  color: #fff;
  display: none;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  font-family: "Avenir";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.067px;
  @media (max-width: 870px) {
    display: flex;
  }
`;
export const MenuContainer = styled.div`
  margin-left: ${(props) => (props.$ar ? "unset" : "auto")};
  margin-right: ${(props) => (props.$ar ? "auto" : "unset")};

  display: none;
  width: 42px;
  height: 42px;
  align-items: center;
  z-index: 502;
  position: relative;
  justify-content: center;
  border-radius: 10px;
  background-color: #fff;
  @media (max-width: 870px) {
    display: flex;
    cursor: pointer;
  }
`;

export const Sidebar = styled.div`
  box-shadow: 0px 1.2rem 2.4rem -0.6rem rgba (0, 0, 0, 0.25);
  background: linear-gradient(180deg, #a87283 -100%, #8a4b5e 100%), #fff;
  margin-top: 50px;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  padding: 1.2rem 3rem;
  min-width: 250px;
`;

export const Dot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #fff;
  top: -5px;
  right: ${(props) => (props.$ar ? "unset" : "-5px")};
  left: ${(props) => (props.$ar ? "-5px" : "unset")};
  padding: 5px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 1px solid #8a4b5e;
`;

export const CartCount = styled.span`
  color: #8a4b5e;
  font-size: 1rem;
  font-family: "Avenir-Heavy";
`;
export const StyledMenuContainer = styled.div`
  background-color: #8a4b5e;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 501;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledNavLink = styled(NavLink)`
  display: flex;
  user-select: none;
  text-decoration: none;
  font-weight: 400;
  color: #fff;
  font-family: ${(props) => (props.$path ? "Avenir-Heavy" : "Avenir")};
  font-size: 22px;
  letter-spacing: -0.18px;

  transition: all 0.2s ease-in-out;
  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }

  text-shadow: ${(props) =>
    props.$path ? "0px 0px 10px rgba(255, 255, 255, 0.75)" : "unset"};
  /* transition: all 0.2s ease-in-out;
  position: relative;

  &:hover {
    &:before {
      border-radius: 20px;
      width: 100%;
      left: 0;
      right: unset;
    }
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 2px;
    left: unset;
    right: 0;
    width: 0;
    height: 1.65px;
    background-color: #fff;
    transition: width 0.3s ease, left 0.3s ease;
  } */

  /* @media (max-width: 1635px) {
    font-size: 18px;
  }
  @media (max-width: 1425px) {
    font-size: 14px;
  } */

  @media (max-width: 890px) {
    font-size: 18px;
  }
`;
export const TestEnvContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  margin-right: ${(props) => (!props.$ar ? "24px" : "0px")};

  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
  }

  @media (max-width: 870px) {
    margin-left: ${(props) => (props.$loggedIn ? "24px" : "0px")};
  }
`;

export const GiftCartContainer = styled.div`
  position: relative;
  cursor: pointer;
`;
export const GiftCart = styled.div`
  position: absolute;
  padding: 20px;
  background: #8a4b5e;
  width: fit-content;
  height: fit-content;
  right: 0;
  border: 2px solid #fff;
  top: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
`;

export const ItemName = styled.span`
  font-size: 16px;
  color: white;
  text-align: center;
  font-family: "Avenir-Medium";
`;

export const ImgContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const FavoriteIcon = styled(FavoriteSvg)`
  path {
    fill: #fff;
  }
`;
export const CartIcon = styled(CartSvg)`
  fill: #fff;
`;

export const Test = styled.span`
  font-size: 11px;
  user-select: none;
  color: #fff;
  text-align: center;
  position: absolute;
  bottom: -5px;
  right: -5px;
  transition: all 0.2s ease-in-out;
  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }
`;

export const FullNameText = styled.span`
  user-select: none;
  color: ${(props) => (props.$color ? "#fff" : "#8A4B5E")};
  font-family: "Avenir";
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.18px;

  transition: all 0.2s ease-in-out;
  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }
  cursor: default;

  @media (max-width: 870px) {
    display: none;
  }
`;

export const StyledFullNameText = styled.span`
  user-select: none;
  color: ${(props) => (props.$color ? "#fff" : "#8A4B5E")};
  font-family: "Avenir";
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.18px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }
  cursor: default;
  /* @media (max-width: 1635px) {
    font-size: 15px !important;
  }
  @media (max-width: 1425px) {
    font-size: 12px !important;
  } */
  @media (max-width: 890px) {
    font-size: 16px;
  }
  @media (max-width: 870px) {
    display: none;
  }
`;
export const DotsIcon = styled(Dots)`
  z-index: -1;

  g {
    fill: #fff;
  }
`;

export const CloseIcon = styled(CloseSvg)`
  cursor: pointer;
  position: absolute;
  left: ${(props) => (props.$ar ? "190px" : "unset")};
  right: ${(props) => (props.$ar ? "unset" : "190px")};

  fill: #fff;

  &&:hover {
    fill: red;
  }
  @media (max-width: 1440px) {
    left: ${(props) => (props.$ar ? "40px" : "unset")};
    right: ${(props) => (props.$ar ? "unset" : "40px")};
  }
`;

export const Div = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
export const SearchContainer = styled.div`
  background-color: rgba(138, 75, 94, 0.65);

  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);

  transition: all 0.2s ease-out;
  height: ${(props) => (props.$open ? "46px" : "0px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: -1;
  top: ${(props) => (props.$upgradePackage ? "calc(6.9rem + 5.5rem)" : "75px")};
  left: 0;
  right: 0;
  opacity: ${(props) => (props.$open ? 1 : 0)};
  padding-left: ${(props) =>
    props.$loggedIn && !props.$ar
      ? "290px"
      : props.$loggedIn && props.$ar
      ? "220px"
      : "220px"};
  padding-right: ${(props) =>
    props.$loggedIn && !props.$ar
      ? "220px"
      : props.$loggedIn && props.$ar
      ? "290px"
      : "220px"};

  @media (max-width: 1440px) {
    padding-left: ${(props) =>
      props.$loggedIn && !props.$ar
        ? "40px"
        : !props.$loggedIn && props.$ar
        ? "70px"
        : props.$loggedIn && props.$ar
        ? "70px"
        : "40px"};

    padding-right: ${(props) =>
      props.$loggedIn && !props.$ar
        ? "70px"
        : !props.$loggedIn && props.$ar
        ? "40px"
        : props.$loggedIn && props.$ar
        ? "40px"
        : "70px"};
  }
  @media (max-width: 1200px) {
    top: ${(props) => (props.$upgradePackage ? "120px" : "67px")};
  }
`;

export const SearchDropdownInput = styled.input`
  color: #fff;
  font-size: 16px;
  font-family: "Avenir-Medium";
  display: flex;
  width: 100%;

  &::placeholder {
    color: #fff;
    opacity: 1;
  }
`;

export const SearchIcon = styled(SearchSvg)`
  cursor: pointer;

  g {
    stroke: #fff;
  }
`;
