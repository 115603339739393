import React, { useEffect, useRef, useState } from "react";
import {
  ContentContainer,
  Text,
  ButtonNext,
  ButtonBack,
  Buttons,
  StyledDiv,
  StyledRowInput,
  Relative,
  DropDown,
  DropDownValue,
  DropDownMenu,
  Attributes,
  StyledInput,
  DatePickerContainer,
  Column,
  Value,
  RowValue,
  StyledInputLast,
  StyledDatePicker,
  StyledRowInputValue,
  DateIcon,
} from "../Steps.styles";

import { useTranslation } from "react-i18next";
import { ReactComponent as Chevron } from "../../../assets/icons/chevronDown.svg";
import { useDispatch } from "react-redux";
import {
  selectedNewCoupon,
  setExpirationDate,
  setMaxUsage,
  setOnetime,
  setProduct,
  setStatus,
  setType,
  setValue,
  setFrequency,
} from "../../../store/slices/coupon/couponSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Checkbox from "../../Checkbox/Checkbox";
import { useLocation } from "react-router-dom";
import RadioButtonGroup from "../../RadioButtons/RadioButtons";
import openToast from "../../../utils/toast";

const CreateEditStep2 = ({ onNext, onBack }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const isEditCoupon = location.pathname.includes("edit-coupon");
  const data = useSelector(selectedNewCoupon);
  const [open, setOpen] = useState(false);
  const currentDate = new Date();
  const minDate = new Date(currentDate);
  minDate.setMonth(currentDate.getMonth() + 1);
  const [date, setDate] = useState(minDate);
  const toastId = useRef(null);

  useEffect(() => {
    if (!isEditCoupon) {
      dispatch(
        setExpirationDate(typeof date === "number" ? date : date.getTime())
      );
      dispatch(
        setProduct([
          { id: 1, name: "MOBILE" },
          { id: 2, name: "WEB_APP" },
        ])
      );
      dispatch(setType("DISCOUNT"));
      dispatch(setFrequency("SINGLE"));
    }
  }, [dispatch, isEditCoupon, date]);

  useEffect(() => {
    if (data.maxUsage === 1) {
      dispatch(setOnetime(true));
    }
  }, []);

  const types = [
    { id: 1, name: "DISCOUNT" },
    { id: 2, name: "FREE_DELIVERY" },
  ];

  const handleSubcategoryClick = (type) => {
    dispatch(setType(type.name));
    if (type.name === "FREE_DELIVERY") {
      dispatch(setValue(""));
    }
    setOpen(false);
  };

  const handleStatusClick = (status) => {
    dispatch(setStatus(status));
  };

  const handleProductClick = (product) => {
    const updatedProducts = [...data.products];
    const mobileIndex = updatedProducts.findIndex((p) => p.name === "MOBILE");
    const webAppIndex = updatedProducts.findIndex((p) => p.name === "WEB_APP");
    const subscriptionIndex = updatedProducts.findIndex(
      (p) => p.name === "SUBSCRIPTION"
    );

    if (product === "MOBILE") {
      if (mobileIndex === -1) {
        updatedProducts.push({ id: 1, name: "MOBILE" });
      } else {
        updatedProducts.splice(mobileIndex, 1);
      }
    } else if (product === "WEB_APP") {
      if (webAppIndex === -1) {
        updatedProducts.push({ id: 2, name: "WEB_APP" });
      } else {
        updatedProducts.splice(webAppIndex, 1);
      }
    } else if (product === "SUBSCRIPTION") {
      if (subscriptionIndex === -1) {
        updatedProducts.push({ id: 3, name: "SUBSCRIPTION" });
      } else {
        updatedProducts.splice(subscriptionIndex, 1);
      }
    }

    dispatch(setProduct(updatedProducts));
  };

  const handleFrequencyClick = (frequency) => {
    dispatch(setFrequency(frequency));
  };

  const validation = () => {
    if (data.expirationDate <= currentDate) {
      openToast(
        toastId,
        "edit-coupon-warning",
        "warning",
        t("expiry-date-must-be-selected")
      );
      return;
    }

    if (data.products.length === 0) {
      openToast(
        toastId,
        "select-gift-warning",
        "warning",
        t("select-at-least-one-product")
      );
      return;
    }

    if (!data.type) {
      openToast(
        toastId,
        "select-gift-warning",
        "warning",
        t("select-coupon-type")
      );
      return;
    }

    if (
      data.type === "DISCOUNT" &&
      (isNaN(data.value) || data.value < 1 || data.value > 99)
    ) {
      openToast(
        toastId,
        "select-gift-warning",
        "warning",
        t("invalid-discount-value")
      );
      return;
    }

    if (isEditCoupon) {
      if (!data.status) {
        openToast(
          toastId,
          "select-gift-warning",
          "warning",
          t("select-coupon-status")
        );
        return;
      }
    }

    onNext();
  };

  return (
    <>
      <ContentContainer>
        <StyledRowInput>
          <Text>{t("expiry-date")}:</Text>
          <DatePickerContainer>
            <StyledDatePicker
              placeholderText={t("select-a-date")}
              selected={new Date(data.expirationDate)}
              onChange={(value) => {
                const selectedDate = new Date(value);
                const newDate = selectedDate.getTime();
                dispatch(setExpirationDate(isEditCoupon ? value : newDate));
                setDate(newDate);
              }}
              minDate={minDate}
            />

            <DateIcon />
          </DatePickerContainer>
        </StyledRowInput>

        <StyledRowInput>
          <Text>{t("frequency")}:</Text>
          <RadioButtonGroup
            label1={t("single-use")}
            label2={t("multiple")}
            checked={data.frequency === "SINGLE"}
            checked2={data.frequency === "MULTI"}
            onChange={() => {
              handleFrequencyClick("SINGLE");
            }}
            onChange2={() => {
              handleFrequencyClick("MULTI");
            }}
          />
        </StyledRowInput>

        <StyledRowInputValue>
          <Text>{t("product")}:</Text>
          <Column>
            <RowValue>
              <Value>{t("userApp")}</Value>
              <Checkbox
                checked={data.products.some((p) => p.name === "MOBILE")}
                onChange={() => {
                  handleProductClick("MOBILE");
                }}
              />
            </RowValue>
            <RowValue>
              <Value>{t("b2b-web-app")}</Value>
              <Checkbox
                checked={data.products.some((p) => p.name === "WEB_APP")}
                onChange={() => {
                  handleProductClick("WEB_APP");
                }}
              />
            </RowValue>
            <RowValue>
              <Value>{t("Subscriptions")}</Value>
              <Checkbox
                checked={data.products.some((p) => p.name === "SUBSCRIPTION")}
                onChange={() => {
                  handleProductClick("SUBSCRIPTION");
                }}
              />
            </RowValue>
          </Column>
        </StyledRowInputValue>
        <StyledRowInput>
          <Text>{t("coupon-type")}:</Text>
          <Relative>
            <DropDown $open={open} onClick={() => setOpen(!open)}>
              <DropDownValue>
                {!data.type ? t("type") : t(data.type)}
              </DropDownValue>
              <Chevron />
            </DropDown>
            {open && (
              <DropDownMenu>
                {types.map((type, index) => (
                  <Attributes
                    key={index}
                    onClick={() => handleSubcategoryClick(type)}
                  >
                    {t(type.name)}
                  </Attributes>
                ))}
              </DropDownMenu>
            )}
          </Relative>
        </StyledRowInput>

        {data.type === "DISCOUNT" && (
          <StyledRowInput>
            <Text>{t("value")}:</Text>
            <StyledInput
              min="1"
              type="number"
              value={data.value || ""}
              onChange={(e) => {
                dispatch(setValue(e.target.value));
              }}
            />
          </StyledRowInput>
        )}

        <StyledRowInput>
          <Text>{t("one-time")}:</Text>
          <StyledDiv>
            {!data.onetime && (
              <StyledInputLast
                min="2"
                type="number"
                value={data.maxUsage}
                onChange={(e) => {
                  dispatch(setMaxUsage(e.target.value));
                }}
              />
            )}
            <Checkbox
              checked={data.onetime}
              onChange={() => {
                dispatch(setOnetime(!data.onetime));
              }}
            />
          </StyledDiv>
        </StyledRowInput>
        {isEditCoupon && (
          <StyledRowInput>
            <Text>{t("status")}:</Text>
            <RadioButtonGroup
              label1={t("active")}
              label2={t("deactivated")}
              checked={data.status === "ACTIVE"}
              checked2={data.status === "DEACTIVATED"}
              onChange={() => {
                handleStatusClick("ACTIVE");
              }}
              onChange2={() => {
                handleStatusClick("DEACTIVATED");
              }}
            />
          </StyledRowInput>
        )}
      </ContentContainer>
      <Buttons>
        <ButtonBack onClick={onBack}>{t("back")}</ButtonBack>
        <ButtonNext onClick={validation}>{t("next")}</ButtonNext>
      </Buttons>
    </>
  );
};

export default CreateEditStep2;
