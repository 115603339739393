import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { ReactComponent as LogoGift } from "../../../assets/icons/gift-empty.svg";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow.svg";
import { ReactComponent as Logout } from "../../../assets/icons/logout2.svg";
import { ReactComponent as Dots } from "../../../assets/icons/dots-y.svg";

export const ProfilePhoto = styled.img`
  min-width: 101px;

  max-width: 170px;
  max-height: 101px;
  margin-bottom: 10px;
  border-radius: 50%;

  @media (max-height: 1200px) {
    max-height: 80px;
    min-width: auto;
  }
`;
export const DotsIcon = styled(Dots)`
  g {
    fill: #fff;
  }
`;
export const SideBar = styled.div`
  margin-top: 7.5rem;
  position: fixed;
  padding: 72px 22px 22px 22px;
  display: flex;
  align-items: flex-start;
  background: #fff;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
  flex-direction: column;
  justify-content: space-between;
  min-width: 250px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: none;
  @media (max-width: 870px) {
    display: flex;
  }
`;

export const LogoGiftIcon = styled(LogoGift)`
  height: auto;
  @media (max-height: 860px) {
    width: 40px;
  }
`;

export const LogoutIcon = styled(Logout)`
  height: auto;
  width: 27px;
  @media (max-height: 860px) {
    width: 25px;
  }
`;
export const ArrowIcon = styled(Arrow)`
  height: auto;
  @media (max-height: 860px) {
    width: 8px;
    path {
      stroke-width: 1.35px;
    }
  }
`;

export const StyledSideBar = styled(SideBar)`
  right: 0;
  margin-top: 30px;
  left: unset;
  padding: 22px;
  align-items: center;
`;
export const DotsContainer = styled.div`
  display: none;

  background-color: unset;
  @media (max-width: 870px) {
    display: flex;
  }
`;
export const NavDiv = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  display: flex;
  gap: 50px;
  flex-direction: column;
  @media (max-height: 860px) {
    gap: 25px;
  }
`;
export const IconContainer = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-height: 860px) {
    width: 25px;
    height: 25px;
  }
`;

export const Group = styled.div`
  display: flex;
  user-select: none;
  cursor: pointer;
  align-items: center;
`;
export const GiftCartContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

export const GiftCart = styled.div`
  position: absolute;
  padding: 20px;
  background: #8a4b5e;
  width: fit-content;
  height: fit-content;
  right: -130px;
  border: 2px solid #fff;
  top: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
`;
export const ItemName = styled.span`
  font-size: 16px;
  color: white;
  text-align: center;
  font-family: "Avenir-Medium";
`;
export const ImgContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
export const Shape = styled.div`
  width: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 50px;
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: -0.177777px;

  color: #8a4b5e;

  background-color: #ececf4;
  border-radius: 50%;
`;
export const LogoText = styled.span`
  font-family: "Avenir-Light";
  font-style: normal;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.177777px;
  margin-left: -12px;
  color: #8a4b5e;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AdminInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 100px;

  @media (max-height: 1027px) {
    margin-bottom: 50px;
  }
  @media (max-height: 860px) {
    margin-bottom: 25px;
  }
`;

export const PhotoElipse = styled.div`
  width: 101px;
  height: 101px;
  display: none;
  border-radius: 50%;
`;

export const AdminName = styled.span`
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.266666px;
  text-transform: uppercase;
  color: #fff;
  max-width: 20rem;
  white-space: pre-wrap;
  word-wrap: break-word;
  @media (max-width: 870px) {
    max-width: none;
  }
  @media (max-height: 985px) {
    font-size: 18px;
  }
`;
export const AdminAccount = styled.span`
  user-select: none;
  font-family: "Avenir-Light";
  font-style: normal;

  font-size: 16px;

  letter-spacing: -0.177777px;

  color: #fff;

  opacity: 0.6;
  @media (max-height: 860px) {
    font-size: 14px;
  }
`;
export const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media (max-height: 717px) {
    overflow-x: auto;
    max-height: 55dvh;
    padding-right: 4px;

    white-space: nowrap;

    &&::-webkit-scrollbar {
      width: 5px;
    }

    &&::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 10px;
    }

    &&::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
  @media (max-height: 545px) {
    max-height: 50dvh;
  }
  @media (max-height: 500px) {
    max-height: 45dvh;
  }
  @media (max-height: 430px) {
    max-height: 40dvh;
  }
  @media (max-height: 410px) {
    max-height: 30dvh;
  }
  @media (max-height: 350px) {
    max-height: 20dvh;
  }
`;
export const Row = styled.div`
  cursor: pointer;
  width: fit-content;
  align-items: center;
  gap: 15px;
  display: flex;
  flex-direction: row;
`;
export const NavTitle = styled.span`
  font-family: "Avenir-Medium", sans-serif;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.18px;
  color: #fff;
  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;
export const ProfilePicEmpty = styled.div`
  width: 101px;
  height: 101px;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  @media (max-height: 860px) {
    width: 80px;
    height: 80px;
  }
`;

export const MenuContainer = styled.div`
  display: none;
  z-index: 1001;
  margin-top: 20px;

  @media (max-width: 870px) {
    justify-content: space-between;
    align-items: center;
    position: fixed;
    display: flex;
    height: fit-content;
    padding: 9px;
    margin-left: 25px;
    top: -8px;
    left: 0px;
  }
`;

export const Dot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #8a4b5e;
  top: -5px;
  right: -5px;
  padding: 5px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
`;
export const CartCount = styled.span`
  color: white;
  font-size: 12px;
  font-family: "Avenir-Medium", sans-serif;
`;

export const Button = styled.button`
  color: white;
  user-select: none;
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 0.5em 2em;
  border: transparent;
  background: rgba(255, 255, 255, 0.15);
  width: fit-content;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.15);
  cursor: pointer;
  border-radius: 30px;
  &:hover {
    outline: 1px solid rgba(255, 255, 255, 0.15);
    background: transparent;
  }
  &:active {
    -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    outline: 1px solid rgba(255, 255, 255, 0.15);
    transition: all 0.1s ease-in;
    transform: scale(0.97);
  }
  @media (max-width: 870px) {
    font-size: 12px;
  }
  @media (max-width: 570px) {
    display: none;
  }
`;

export const CartContainer = styled.div`
  cursor: pointer;
  position: relative;
`;
export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: right;
  width: 100%;
`;

export const CategoryList = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;
  padding: 10px 0px;
`;
export const Category = styled.span`
  // background-color: #545484;
  font-family: "Avenir-Medium";
  font-size: 18px;
  letter-spacing: -0.41px;
  color: #fff;
  padding: 5px 15px;
  width: fit-content;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    background-color: #e8dbdf33;
  }
`;
export const Search = styled.input`
  padding: 8px;
  margin-bottom: 20px;
  border: unset;
  border-bottom: 1px solid #fff;
  padding-left: 30px;
  font-family: "Avenir-Medium";
  font-style: normal;

  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #fff;
  background: transparent;
  &:focus {
    outline: unset;
  }
  &::placeholder {
    font-family: "Avenir-Medium";
    font-style: normal;

    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.177777px;
    color: #c99ba9;
  }
`;
export const Title = styled.span`
  font-family: "Avenir-Black", sans-serif;
  font-size: 35px;
  letter-spacing: -0.177777px;

  color: #fff;
`;

export const StyledNavLink = styled(NavLink)`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 0.6;
  text-decoration: none;
  color: #8a4b5e;
  font-family: "Avenir-Medium";
  gap: 15px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.0666667px;
  transition: all 0.2s ease-in-out;
  padding: 10px;
  width: 100%;
  &.active {
    opacity: 1;
  }
  &:hover {
    padding-left: 20px;
    border-radius: 10px;
    opacity: 1;
    background-color: #8a4b5e22;
  }
  @media (max-height: 545px) {
    padding: 5px;
    padding-left: 10px;
  }
`;

export const StyledBar = styled.div`
  display: flex;

  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* width: 200.56px; */
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 75px;
  @media (max-height: 1027px) {
    margin-top: 35px;
  }
  @media (max-height: 860px) {
    margin-top: 15px;
  }
`;
export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 100%;
`;

export const ButtonsContainer2 = styled.div`
  display: none;
  flex-direction: column;
  gap: 20px;
  margin-top: 120px;
  @media (max-height: 700px) {
    display: flex;
    margin-top: 30px;
  }
`;

export const GiftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  color: #000;
  font-family: "Avenir";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.067px;
`;

export const UpgradeButton = styled.button`
  border-radius: 5px;
  cursor: pointer;
  background: #fff;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  color: #8a4b5e;
  font-family: "Avenir";
  gap: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.067px;
  border: none;
  @media (max-width: 717px) {
    font-size: 13px;
  }
`;

export const LogoutButton = styled(GiftContainer)`
  color: #c66b66;
  transition: all 0.2s ease-in-out;

  &:hover {
    padding-left: 10px;
  }
`;
export const StyledLogoutButton = styled(GiftContainer)`
  color: #c66b66;
  transition: all 0.2s ease-in-out;
  margin-left: 15;
  &:hover {
    padding-left: 10px;
  }
`;

export const StyledButton = styled.button`
  border-radius: 30px;
  user-select: none;
  background: #8a4b5e;
  height: 35px;
  padding: 15px 30px;

  border: none;
  color: #fff;
  text-align: center;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
  }
  @media (max-width: 1075px) {
    padding: 5px 20px;
    font-size: 12px;
  }
  @media (max-width: 870px) {
    display: none;
  }
`;
export const StyledButton1 = styled.button`
  border-radius: 30px;
  user-select: none;
  background: #8a4b5e;
  height: 35px;
  padding: 15px 30px;
  width: 150px;
  border: none;
  color: #fff;
  text-align: center;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
  }
  @media (max-width: 1075px) {
    padding: 5px 20px;
    font-size: 12px;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;
export const StyledLeftSide = styled(LeftSide)`
  flex-direction: column;
`;

export const Search1 = styled.input`
  border-radius: 10px;
  width: 443px;
  user-select: none;
  height: 40px;
  border: 2px solid #8a4b5e22;
  background: #fff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.05);
  padding-left: 46px;
  &:active {
    outline: none;
  }
  &::placeholder {
    color: rgba(72, 72, 136, 0.5);
    font-family: "Avenir";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.067px;
  }
  @media (max-width: 1040px) {
    width: 340px;
  }
  @media (max-width: 930px) {
    width: 240px;
  }
  @media (max-width: 820px) {
    width: 200px;
  }
`;
export const StyledInput = styled.div`
  position: relative;
  @media (max-width: 870px) {
    display: none;
  }
`;
export const StyledInput2 = styled.div`
  position: relative;
  margin-top: 40px;
`;
export const IconContainer1 = styled.div`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
`;
export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 870px) {
    display: none;
  }
`;

export const Row1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-left: 20px;
`;
export const ProfilePic = styled.div`
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #454588;
  border-radius: 42px;
  cursor: pointer;
  box-shadow: 0px 3px 25px -8px rgba(0, 0, 0, 0.24);
`;
export const ButtonsContainer1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  @media (max-width: 870px) {
    display: none;
  }
`;

export const SectionTitle = styled.h1`
  color: #8a4b5e;
  margin-top: -40px;
  font-family: "Avenir";
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.178px;
`;

export const StyledDiv = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (max-height: 428px) {
    overflow-y: auto;
    max-height: 200px;
    padding-right: 4px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c8c8db;
      border-radius: 10px;
    }
    @media (max-height: 428px) {
      max-height: 100px;
    }
  }
`;
