import styled from "styled-components";

export const MainContainer = styled.div`
  flex-direction: row;
  background: #f6f6f9;
  min-height: 100vh;
  display: flex;
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  margin-top: 75px;

  transition: all 0.2s ease-in-out;
  padding: ${(props) =>
    props.$upgradePackage && props.$ar
      ? "100px 290px 80px 40px"
      : props.$upgradePackage && !props.$ar
      ? "100px 40px 80px 290px"
      : !props.$upgradePackage && props.$ar
      ? "40px 290px 80px 40px"
      : "40px 40px 80px 290px"};

  @media (max-width: 870px) {
    margin-top: 10rem;
    padding: ${(props) =>
      props.$upgradePackage ? "60px 40px 40px 40px" : "0px 40px 40px 40px"};
  }
`;
export const EmptySpace = styled.div`
  height: 42rem;
`;

export const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
`;
export const ButtonRequest = styled.button`
  color: #fff;
  text-align: center;
  font-family: "FrankfurterEF-Medium";

  user-select: none;
  font-size: 1.6rem;

  letter-spacing: 0.067px;
  padding: 0.4rem 1rem;
  height: 3.2rem;
  border-radius: 1rem;
  background: #85a1ac;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  border: none;
  outline: none;
  cursor: pointer;
`;
export const OccasionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const OccasionColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
`;
export const ButtonUpgrade = styled.button`
  color: #fff;
  user-select: none;
  padding: 0.4rem 1rem;
  border: none;
  height: 3rem;
  outline: none;
  cursor: pointer;
  text-align: center;
  font-family: "FrankfurterEF-Medium";

  font-size: 16px;

  letter-spacing: 0.067px;
  border-radius: 10px;
  background: #85a195;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
`;
export const CardColumn = styled.div`
  flex-direction: column;
  display: flex;
  gap: 24px;
`;

export const BigCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #52b868;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;

  width: 175px;
  height: 175px;
`;

export const BigCirclePurple = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  mix-blend-mode: multiply;
  border: 11px solid #5d5c89;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;

  width: 17.5rem;
  height: 17.5rem;

  font-family: "Avenir-Black";
  font-style: normal;

  font-size: 4rem;
  line-height: 5.5rem;
  letter-spacing: -0.177777px;

  color: #8a4b5e;
  position: relative;
`;

export const CardMediumText = styled.span`
  font-family: "Avenir-Black";
  font-style: italic;

  font-size: 2.5rem;
  line-height: 3.4rem;
  letter-spacing: -0.177777px;

  color: #8a4b5e;
`;

export const Circle = styled.div`
  border: 0;
  position: relative;

  &::before,
  &::after {
    box-sizing: inherit;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

export const Graphic = styled.div`
  height: 30rem;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 1rem;
  border-radius: 1rem;

  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  @media (max-width: 870px) {
    width: 35.5rem;
  }
  @media (max-width: 360px) {
    width: 30rem;
  }
`;

export const RightSide = styled.div`
  width: 39.5rem;
  @media (max-width: 870px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const LeftSide = styled.div`
  width: 69.3rem;
  gap: 4rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: 870px) {
    align-items: center;
  }
`;

export const HeaderWrapper = styled.div`
  background: #8a4b5e;
  position: fixed;
  z-index: 1000;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: wrap;

  @media (max-width: 1700px) {
    margin-top: 0px;
  }
  @media (max-width: 870px) {
    align-items: center;
    justify-content: center;
  }
`;

export const CardEmployees = styled.div`
  width: 15.9rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1.6rem;
  border-radius: 1.5rem;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
`;

export const EmojiContainer = styled.div`
  width: 5.9rem;
  height: 5.9rem;
  user-select: none;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
`;
export const CardsEmploy = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  @media (max-width: 962px) {
    flex-wrap: wrap;
  }
  @media (max-width: 870px) {
    justify-content: center;
    max-width: 35.2rem;
  }
`;
export const Title = styled.span`
  user-select: none;

  font-family: "Avenir-Medium";
  font-size: 1.2rem;
  letter-spacing: 0.067px;
`;

export const Count = styled(Title)`
  font-size: 3rem;
  font-weight: 800;
`;

export const RightCard = styled.div`
  width: 39.5rem;
  height: 83rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.6rem;
  border-radius: 1.5rem;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  @media (max-width: 870px) {
    width: 35.5rem;
  }
`;
export const WalletCard = styled.div`
  width: 33.7rem;
  height: 16.2rem;
  border-radius: 1rem;
  padding: 1rem 2rem;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  @media (max-width: 870px) {
    width: 35.5rem;
  }
  @media (max-width: 360px) {
    width: 30rem;
  }
`;
export const RowCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  @media (max-width: 945px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 870px) {
    align-items: center;
  }
`;

export const TitleSection = styled.span`
  user-select: none;

  font-family: "Avenir-Black";
  font-size: 1.6rem;

  letter-spacing: 0.067px;
`;

export const SmallTitle = styled.span`
  user-select: none;
  font-family: "Avenir-Medium";
  font-size: 1.2rem;
  letter-spacing: 0.067px;
`;

export const RowPrice = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;

  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const StyledRowPrice = styled(RowPrice)`
  justify-content: center;
`;

export const Value = styled.span`
  color: rgba(138, 75, 94, 0.8);
  text-align: center;
  font-family: "Avenir-Light";
  user-select: none;
  font-size: 3rem;
  letter-spacing: 0.067px;
`;

export const Price = styled(Value)`
  user-select: none;
  font-size: 1.5rem;
`;

export const LightText = styled.span`
  color: #b1b1b1;
  font-family: "Avenir-Medium";
  font-size: 1.2rem;
  user-select: none;
  letter-spacing: 0.067px;
`;
export const Month = styled(LightText)`
  color: #55a1e8;
  cursor: pointer;
`;

export const HeaderCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 1.6rem;
  padding-top: 1.6rem;
  padding-right: 1.6rem;
`;

export const HeaderCardLast = styled(HeaderCard)`
  border-bottom: none;
  padding-bottom: 0px;
`;

export const Number = styled.span`
  font-family: "Avenir-Heavy";
  font-size: 3rem;

  user-select: none;
  letter-spacing: 0.067px;
`;

export const Search = styled.input`
  border-radius: 1rem;
  width: 44.3rem;
  height: 4.1rem;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  border: none;
  padding-left: 4.6rem;
  &:active {
    outline: none;
  }
  &::placeholder {
    color: rgba(72, 72, 136, 0.5);
    font-family: "Avenir-Medium";
    font-size: 1.2rem;
    letter-spacing: 0.067px;
  }
`;
export const StyledInput = styled.div`
  position: relative;
`;
export const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
`;
export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;
  align-items: center;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
`;
export const ProfilePic = styled.div`
  height: 4.2rem;
  width: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #454588;
  border-radius: 4.2rem;
  cursor: pointer;
  box-shadow: 0px 3px 25px -8px rgba(0, 0, 0, 0.24);
`;

export const WarningContainer = styled.div`
  z-index: 100;
  /* position: absolute; */
  top: 0;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border: 2px solid #ea435d;
  background: #eadadd;
  width: 100%;
`;
export const WarningMessage = styled.span`
  font-family: "FrankfurterEF-Medium";
  font-size: 1.6rem;
  letter-spacing: 0.067px;
`;
export const MainColumn = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: 0;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
`;
export const ButtonCharge = styled.button`
  border-radius: 3rem;
  user-select: none;
  border: none;
  cursor: pointer;
  outline: none;
  background: #5bbe7d;
  min-height: 2.8rem;
  min-width: 10.8rem;
  color: #fff;
  text-align: center;
  font-family: "Avenir-Medium";
  font-size: 1rem;
  letter-spacing: 0.05px;
  &:hover {
    transition: all 0.2s ease-in-out;
    scale: 1.1;
  }
`;
export const Name = styled.span`
  color: rgba(138, 75, 94, 0.8);
  font-family: "Avenir-Medium";
  font-size: 1.2rem;

  letter-spacing: 0.067px;
`;
export const StyledName = styled(Name)`
  font-family: "Avenir-Black";
`;
export const ButtonSeeMore = styled.button`
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChartLabel = styled.span`
  font-size: 1.6rem;
  user-select: none;
  font-family: "Avenir-Medium";
`;

export const ChartLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const ChartContainer = styled.div`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
`;

export const ChartWrapper = styled.div`
  width: 60%;
`;

export const Basic = styled.div`
  border: 1px solid rgba(255, 99, 132, 1);
  width: 1rem;
  border-radius: 50%;
  background-color: transparent;
  height: 1rem;
`;
export const Essentials = styled.div`
  border: 1px solid rgba(54, 162, 235, 1);
  width: 1rem;
  border-radius: 50%;
  background-color: transparent;
  height: 1rem;
`;
export const Enterprise = styled.div`
  border: 1px solid rgba(255, 206, 86, 1);
  width: 1rem;
  border-radius: 50%;
  background-color: transparent;
  height: 1rem;
`;

export const CharRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;
