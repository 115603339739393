import styled from "styled-components";
import TableItem from "../../../components/Table/TableItem";

export const TableBodyRow = styled.tr`
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  justify-content: space-between;
  display: flex;
  width: 100%;
  background-color: ${(props) => props.$active && "#8A4B5E15"};

  cursor: ${(props) => (props.$offClick ? "default" : "pointer")};

  min-height: 6.4rem;
  gap: 0rem;

  &:last-of-type {
    border-bottom: none;
  }
  &:hover {
    background-color: #8a4b5e15;
    transition: all 0.2s ease-in-out;
  }
  &:nth-child(even) {
    background-color: #8a4b5e10;
  }
`;

export const FirstItem = styled(TableItem)`
  padding-left: 1.6rem;
  width: 250px;
  max-width: 250px;
`;

export const LastTableItem = styled(TableItem)`
  padding-right: 1.6rem;
  justify-content: flex-end;
  cursor: ${(props) => (props.$offClick ? "default" : "pointer")};
  &:active {
    opacity: ${(props) => (props.$offClick ? 1 : 0)};
  }
`;
export const MainContainer = styled.div`
  flex-direction: row;
  background: #f6f6f9;

  min-height: 100vh;
  height: 100%;
  display: flex;
`;

export const SectionTitle = styled.h1`
  color: #8a4b5e;
  font-family: "Avenir";
  user-select: none;
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.178px;
  margin-bottom: 10px;

  @media (max-width: 870px) {
    font-size: 32px;
  }
  @media (max-width: 580px) {
    font-size: 28px;
  }
  @media (max-width: 375px) {
    font-size: 22px;
  }
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  margin-top: 72px;
  padding: ${(props) =>
    props.$upgradePackage && props.$ar
      ? "100px 290px 80px 40px"
      : props.$upgradePackage && !props.$ar
      ? "100px 40px 80px 290px"
      : !props.$upgradePackage && props.$ar
      ? "40px 290px 80px 40px"
      : "40px 40px 80px 290px"};

  @media (max-width: 870px) {
    margin-top: 80px;
    padding: ${(props) =>
      props.$upgradePackage ? "60px 40px 40px 40px" : "0px 40px 40px 40px"};
  }
`;

export const Content = styled.div`
  max-width: 100%;
  border: 1px solid #8a4b5e77;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 25px 10px 15px 0px;
`;

export const StyledTh = styled.th`
  text-align: center;
  font-family: "Avenir";
`;

export const TableContainer = styled.div`
  overflow-x: auto;
  margin-top: 20px;
  margin-bottom: 80px;
  width: 1800px;
  @media (max-width: 2125px) {
    width: 100%;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
`;

export const StyledTd = styled.td`
  text-align: center;
  min-width: 150px !important;
  font-family: "Avenir-Medium";
`;

export const StyledTdAll = styled(StyledTd)`
  padding-bottom: 10px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  margin-top: 10px;
  //   align-items: center;
  gap: 10px;
  justify-content: space-between;
  flex-direction: column;
`;
export const DescriptionValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
export const Description = styled.span`
  font-family: "Avenir-Medium";

  font-size: 26px;
  letter-spacing: -0.18px;
  color: #8a4b5e;
  @media (max-width: 870px) {
    font-size: 24px;
  }
  @media (max-width: 580px) {
    font-size: 18px;
  }
  @media (max-width: 375px) {
    font-size: 14px;
  }
`;
export const DescriptionValue = styled.span`
  font-family: "Avenir-Heavy";

  font-size: 26px;
  letter-spacing: -0.18px;
  color: #8a4b5e;
  @media (max-width: 870px) {
    font-size: 24px;
  }
  @media (max-width: 580px) {
    font-size: 18px;
  }
  @media (max-width: 375px) {
    font-size: 14px;
  }
`;

export const PageButton = styled.button`
  padding: 2px 7px;
  border: none;
  background: transparent;
  font-family: "Avenir-Medium";
  font-size: 14px;
  user-select: none;
  color: #8a4b5e;
  cursor: pointer;
  &:active {
    opacity: 0.6;
  }
`;

export const PageCountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;
  gap: 5px;
  width: 1800px;

  margin-top: -70px;
  @media (max-width: 2125px) {
    width: 100%;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 5px;
  background-color: #8a4b5e;
  border: 1.5px solid rgba(255, 255, 255, 0.3);
  padding: 6px 12px;
  border-radius: 20px;
  font-family: "Avenir-Heavy", sans-serif;
  font-size: 16px;
  letter-spacing: -0.177777px;
  color: #fff;
  text-transform: uppercase;

  &:hover {
    background-color: #8a4b5e;
    transition: all 0.2s ease-out;
  }

  &:active {
    transition: scale 0.3s ease-in;
    transform: ${(props) => (props.$disabled ? "scale(0.95)" : "unset")};
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
  }

  @media (max-width: 580px) {
    height: 35px;
    padding: 4px 8px;
    font-size: 14px;
  }
`;

export const SuccessImg = styled.img`
  background-color: transparent;
  max-width: 30px;
  align-self: center;
  max-height: 30px;
`;

export const FailedImg = styled.img`
  background-color: transparent;
  max-width: 30px;
  align-self: center;
  max-height: 30px;
`;

export const StyledAHref = styled.a`
  cursor: pointer;
  margin-top: 4px;
`;

export const InvoiceDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const ButtonGenerateInvoice = styled.div`
  border-radius: 12px;
  user-select: none;
  background: #8a4b5e;
  height: 30px;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  border: none;
  color: #fff;
  text-align: center;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
  cursor: pointer;

  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
    background: #8a4b5e;
  }

  @media (max-width: 1965px) {
    height: 35px;
    padding: 4px 8px;
    font-size: 14px;
  }
`;

export const LoaderDiv = styled.div`
  margin-top: 150px;
  display: flex;
  justify-content: center;
  @media (max-height: 870px) {
    margin-top: 100px;
  }

  @media (max-height: 550px) {
    margin-top: 40px;
  }

  @media (max-height: 450px) {
    margin-top: 20px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 10%;
`;

export const Text = styled.span`
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 24px;
  letter-spacing: -0.177777px;
  text-align: center;
  padding: 5px;
  color: #8a4b5e;

  @media (max-width: 990px) {
    font-size: 20px;
  }
  @media (max-width: 850px) {
    font-size: 17px;
  }
  @media (max-width: 870px) {
    font-size: 22px;
  }
  @media (max-width: 650px) {
    font-size: 18px;
  }
  @media (max-width: 540px) {
    font-size: 14px;
  }
`;

export const HeaderWrapper = styled.div`
  background: #8a4b5e;
  width: 100%;
  z-index: 1000;
  position: fixed;
`;
