import { createAsyncThunk } from "@reduxjs/toolkit";
import marketplaceService from "../../../api/services/marketplace";

export const getOrders = createAsyncThunk(
  "marketplace/orders",
  async (requestData) => {
    return await marketplaceService.getOrders(requestData);
  }
);

export const getFavoriteMarketplaceItemsAll = createAsyncThunk(
  "marketplace/getFavoriteMarketplaceItemsAll",
  async (requestData) => {
    return await marketplaceService.getFavoriteMarketplaceItemsAll(requestData);
  }
);

export const toggleFavoriteMarketplaceItem = createAsyncThunk(
  "marketplace/toggleFavoriteMarketplaceItem",
  async (requestData) => {
    return await marketplaceService.toggleFavoriteMarketplaceItem(requestData);
  }
);

export const getCountFavoriteMarketplaceItems = createAsyncThunk(
  "marketplace/getCountFavoriteMarketplaceItems",
  async (requestData) => {
    return await marketplaceService.getCountFavoriteMarketplaceItems(
      requestData
    );
  }
);

export const getOrder = createAsyncThunk(
  "marketplace/order",
  async (requestData) => {
    return await marketplaceService.getOrder(requestData);
  }
);

export const getAllMarketplaceItems = createAsyncThunk(
  "marketplace/all-items",
  async (requestData) => {
    return await marketplaceService.getAllMarketplaceItems(requestData);
  }
);

export const createItem = createAsyncThunk(
  "marketplace/create-item",
  async (requestData) => {
    return await marketplaceService.createItem(requestData);
  }
);

export const removeItem = createAsyncThunk(
  "marketplace/remove-item",
  async (requestData) => {
    return await marketplaceService.removeItem(requestData);
  }
);

export const editItem = createAsyncThunk(
  "marketplace/edit-item",
  async (requestData) => {
    return await marketplaceService.editItem(requestData);
  }
);

export const getSubcategories = createAsyncThunk(
  "marketplace/item-subcategories",
  async (requestData) => {
    return await marketplaceService.getSubcategories(requestData);
  }
);
export const getCategories = createAsyncThunk(
  "marketplace/item-categories",
  async (requestData) => {
    return await marketplaceService.getCategories(requestData);
  }
);

export const updateOrderState = createAsyncThunk(
  "marketplace/update-order-state",
  async (requestData) => {
    return await marketplaceService.updateOrderState(requestData);
  }
);

export const getMarketplaceItemById = createAsyncThunk(
  "gifts/get-marketplace-item-by-id",
  async (requestData) => {
    return await marketplaceService.getMarketplaceItemById(requestData);
  }
);

export const getSubcategoriesList = createAsyncThunk(
  "marketplace/subcategories-list",
  async (requestData) => {
    return await marketplaceService.getSubcategoriesList(requestData);
  }
);

export const deleteVariable = createAsyncThunk(
  "marketplace/delete-variable",
  async (requestData) => {
    return await marketplaceService.deleteVariable(requestData);
  }
);

export const deleteSubcategory = createAsyncThunk(
  "marketplace/delete-subcategory",
  async (requestData) => {
    return await marketplaceService.deleteSubcategory(requestData);
  }
);

export const deleteCategory = createAsyncThunk(
  "marketplace/delete-category",
  async (requestData) => {
    return await marketplaceService.deleteCategory(requestData);
  }
);

export const createVariable = createAsyncThunk(
  "marketplace/create-variable",
  async (requestData) => {
    return await marketplaceService.createVariable(requestData);
  }
);

export const createSubcategory = createAsyncThunk(
  "marketplace/create-subcategory",
  async (requestData) => {
    return await marketplaceService.createSubcategory(requestData);
  }
);

export const createCategory = createAsyncThunk(
  "marketplace/create-category",
  async (requestData) => {
    return await marketplaceService.createCategory(requestData);
  }
);

export const editCategory = createAsyncThunk(
  "marketplace/edit-category",
  async (requestData) => {
    return await marketplaceService.editCategory(requestData);
  }
);

export const editVariable = createAsyncThunk(
  "marketplace/edit-variable",
  async (requestData) => {
    return await marketplaceService.editVariable(requestData);
  }
);

export const editSubcategory = createAsyncThunk(
  "marketplace/edit-subcategory",
  async (requestData) => {
    return await marketplaceService.editSubcategory(requestData);
  }
);

export const updateCategoryPriority = createAsyncThunk(
  "marketplace/update-category-priority",
  async (requestData) => {
    return await marketplaceService.updateCategoryPriority(requestData);
  }
);

export const getAttributesByCategory = createAsyncThunk(
  "marketplace/get-attributes-by-category",
  async (requestData) => {
    return await marketplaceService.getAttributesByCategory(requestData);
  }
);

export const sendShareCartProductEmail = createAsyncThunk(
  "marketplace/send-share-cart-product-email",
  async (requestData) => {
    return await marketplaceService.sendShareCartProductEmail(requestData);
  }
);

export const addFavoriteMarketplaceItemFromCart = createAsyncThunk(
  "marketplace/add-favorite-marketplace-item-from-cart",
  async (requestData) => {
    return await marketplaceService.toggleFavoriteMarketplaceItem(requestData);
  }
);

export const getSuperAdminOrders = createAsyncThunk(
  "marketplace/super-admin-orders",
  async (requestData) => {
    return await marketplaceService.getMarketplaceOrdersSuperAdmin(requestData);
  }
);

export const getOrderPackages = createAsyncThunk(
  "marketplace/getOrderPackages",
  async () => {
    return await marketplaceService.getOrderPackages();
  }
);
