import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectedLocale } from "../../store/slices/common/slice";
import {
  IconContainer,
  LeftIcon,
  Image,
  MediaSliderContainer,
  Loader,
  RightIcon,
} from "./MediaSlider.styles";
import AppLoader from "../AppLoader";

const MediaSlider = ({
  pictures,
  style,
  activeIndex,
  mobileApp,
  onSlideChange,
  hideArrows,
  imageRef,
  onLoadPicture,
}) => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const previousSlide = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      const newIndex =
        activeIndex === 0 ? pictures.length - 1 : activeIndex - 1;
      setTimeout(() => {
        onSlideChange(newIndex);
        setIsTransitioning(false);
        setIsLoading(true);
      }, 200);
    }
  };

  const nextSlide = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      const newIndex =
        activeIndex === pictures.length - 1 ? 0 : activeIndex + 1;
      setTimeout(() => {
        onSlideChange(newIndex);
        setIsTransitioning(false);
        setIsLoading(true);
      }, 200);
    }
  };

  const handleImageLoad = (event) => {
    setIsLoading(false);
    if (onLoadPicture) {
      onLoadPicture(event);
    }
  };

  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";

  return (
    <MediaSliderContainer>
      {!hideArrows && (
        <IconContainer onClick={previousSlide} $right={ar}>
          {ar ? <RightIcon /> : <LeftIcon />}
        </IconContainer>
      )}
      {isLoading && (
        <Loader>
          <AppLoader />
        </Loader>
      )}

      {mobileApp ? (
        <Image
          className={`img-transition ${isTransitioning ? "fade-out" : ""}`}
          style={style}
          src={pictures[activeIndex].url}
          alt="slider"
          ref={imageRef}
          onLoad={handleImageLoad}
        />
      ) : (
        <img
          className={`img-transition ${
            isTransitioning ? "fade-out" : ""
          } && itemPhoto`}
          style={style}
          src={pictures[activeIndex].url}
          alt="slider"
          ref={imageRef}
          onLoad={handleImageLoad}
        />
      )}
      {!hideArrows && (
        <IconContainer onClick={nextSlide} $right={!ar}>
          {ar ? <LeftIcon /> : <RightIcon />}
        </IconContainer>
      )}
    </MediaSliderContainer>
  );
};

export default MediaSlider;
