import React, { useRef, useState } from "react";

import {
  Container,
  FirstItem,
  HeadRow,
  LastTableItem,
} from "./TabManage.styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteCompanyBusinessUser } from "../../../store/slices/company/asyncThunks";

import { ReactComponent as InviteUser } from "../../../assets/icons/person_add.svg";

import Button from "../../Button/Button";
import ModalConfirm from "../../ModalConfirm/ModalConfirm";
import InviteUserModal from "../../InviteUserModal/InviteUserModal";
import SearchInput from "../../Search/Search";
import MiniLoader from "../../MiniLoader/MiniLoader";
import openToast from "../../../utils/toast";
import Table from "../../Table/Table";
import { TableBodyRow } from "../../Table/Table.styles";
import TableItem from "../../Table/TableItem";

const TabManage = ({
  businessUser,
  searchQuery,
  setSearchQuery,
  handleGetInvitationLink,
  companyLoading,
  currentUsers,
  currentPage,
  handlePageChange,
  selectedUser,
  totalNumOfUsers,
  perPage,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modalRef = useRef();
  const [inviteUserModal, setInviteUserModal] = useState(false);
  const toastId = useRef(null);

  const tableHeader = [
    {
      label: `${t("email")}`,
      sortable: true,
      key: "email",
    },
    {
      label: `${t("full-name")}`,
      sortable: true,
      key: "fullName",
    },
    {
      label: `${t("role")}`,
      sortable: true,
      key: "role",
    },
  ];

  return (
    <>
      <Container>
        <HeadRow>
          <SearchInput
            placeholder={t("search-by-name-or-email")}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />

          {businessUser.role === "owner" && (
            <Button
              onClick={() => {
                setInviteUserModal(true);
                handleGetInvitationLink();
              }}
              title={t("invite-users")}
              leftIcon={<InviteUser style={{ marginRight: 10 }} />}
            />
          )}
        </HeadRow>

        {!companyLoading ? (
          <>
            <Table
              marginTop={40}
              headerInformation={tableHeader}
              totalCount={totalNumOfUsers}
              currentPage={+currentPage}
              setPage={handlePageChange}
              param={"page"}
              perPage={perPage}
              noDataContent={t("no-users")}
              dataLoading={false}
              lastColumnAligned
              centerText
            >
              {currentUsers.map((user, key) => {
                return (
                  <TableBodyRow key={key} onClick={() => {}} $offClick>
                    <FirstItem
                      hoverText={user?.email}
                      showHover
                      data={
                        user.email && user.email.length > 25
                          ? user.email.slice(0, 25) + "..."
                          : user.email || "/"
                      }
                    />
                    <TableItem center data={t(`${user.fullName}`)} />
                    <LastTableItem $offClick data={user.role || "/"} />
                  </TableBodyRow>
                );
              })}
            </Table>
          </>
        ) : (
          <div style={{ marginTop: 100 }}>
            <MiniLoader />
          </div>
        )}
      </Container>
      <InviteUserModal
        open={inviteUserModal}
        close={() => setInviteUserModal(false)}
      />
      <ModalConfirm
        modalRef={modalRef}
        onClickNo={() => {
          modalRef.current.hide();
        }}
        onClickYes={() => {
          dispatch(deleteCompanyBusinessUser(selectedUser))
            .unwrap()
            .then(() => toast.success(t("User deleted successfully.")))
            .catch((error) => {
              openToast(
                toastId,
                "delete-company-user",
                "error",
                t(error.message)
              );
            });
          modalRef.current.hide();
        }}
      />
    </>
  );
};

export default TabManage;
