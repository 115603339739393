import { createSlice } from "@reduxjs/toolkit";
import { getUserInfoByIdUnauthorizedBuilder } from "./builder";

const userSlice = createSlice({
  name: "userUnauthorized",
  initialState: {
    userInfo: {},
    getUserInfoByIdUnauthorizedLoading: false,
  },
  reducers: {},

  extraReducers: (builder) => {
    getUserInfoByIdUnauthorizedBuilder(builder);
  },
});

// export const {} = itemSlice.actions;

export const selectedUserInfo = (state) => state.userUnauthorized.userInfo;
export const selectedUserLoading = (state) =>
  state.userUnauthorized.getUserInfoByIdUnauthorizedLoading;

export default userSlice.reducer;
