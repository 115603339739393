import styled, { keyframes } from "styled-components";

const slideIn = keyframes`
  from {
    opacity: 0;
    left: -100%;

  }
  to {
    opacity: 1;
    left: 0;

  }
`;

const slideOut = keyframes`
  from {
    opacity: 1;
    left: 0;

  }
  to {
    opacity: 0;
    left: -100%;

  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const ModalBackground = styled.div`
  z-index: 5000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1.3px);
  display: flex;

  animation: ${(props) => (props.$open ? fadeIn : fadeOut)} 0.3s ease-in-out;
`;

export const ModalContainer = styled.div`
  z-index: 10000;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 870px) {
    padding: 0px;
  }
`;

export const ModalContent = styled.div`
  z-index: 10001;
  position: relative;
  background-color: white;
  border-radius: 10px;
  display: flex;
  width: 280px;
  height: 100%;
  padding: 32px;
  flex-direction: column;
  justify-content: ${(props) =>
    props.$unset ? props.$unset : "space-between"};
  flex-shrink: 0;
  animation: ${(props) => (props.$open ? slideIn : slideOut)} 0.3s ease-in-out;

  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
`;
