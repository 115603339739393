import styled from "styled-components";

export const CategoryBarContainer = styled.div`
  position: fixed;
  margin-top: 70px;
  display: flex;
  align-items: flex-start;
  background: linear-gradient(360deg, #a87283 -100%, #8a4b5e 100%);
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.4);
  flex-direction: column;
  min-width: 260px;
  gap: 10px;
  z-index: 10;
  user-select: none;
  top: 0;
  bottom: 0;
  left: ${(props) => (props.$ar ? "unset" : 0)};
  right: ${(props) => (!props.$ar ? "unset" : 0)};
  transition: all 0.2s ease-in-out;
  padding: ${(props) =>
    props.$upgradePackage ? "80px 10px 10px 20px" : "20px 10px 10px 20px"};

  @media (max-height: 1200px) {
    margin-top: ${(props) => (props.$upgradePackage ? "80px" : "67px")};
  }

  @media (max-width: 870px) {
    display: none;
  }
`;
export const LoaderContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const CategoryList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  gap: 5px;
`;

export const Category = styled.span`
  font-family: ${(props) => (props.$active ? "Avenir-Heavy" : "Avenir-Medium")};
  font-size: 18px;
  letter-spacing: -0.41px;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 10px;
  width: 100%;
  background-color: ${(props) => (props.$active ? "#e8dbdf33" : "transparent")};
  border-radius: 10px;
  padding-left: ${(props) => (props.$active ? "20px" : "10px")};

  &:hover {
    padding-left: 20px;
    opacity: 1;
    background-color: #e8dbdf33;
  }
`;

export const Title = styled.span`
  font-family: "Avenir-Black", sans-serif;
  font-size: 35px;
  letter-spacing: -0.177777px;

  color: #fff;
`;
