import React from "react";
import {
  Content,
  Text,
  TextContainer,
  TitleContainer,
  Message,
  ViewMoreText,
  ViewMoreContainer,
  FirstItem,
  LastTableItem,
  TableBodyRow,
  Chevron,
} from "../../screens/AdminPortal/CouponsPage/CouponsPage.styles";

import { useTranslation } from "react-i18next";

import { priceFormatter } from "../../utils/utils";
import MiniLoader from "../MiniLoader/MiniLoader";
import Table from "../Table/Table";
import TableItem from "../Table/TableItem";

const AllCoupons = ({
  data,
  onClickMoreActive,
  onClickMoreDeactivated,
  ar,
}) => {
  const { t } = useTranslation();

  const tableHeader = [
    {
      label: `${t("coupon")}`,
      sortable: true,
      key: "coupon",
    },
    {
      label: `${t("usage")}`,
      sortable: true,
      key: "usage",
    },
    {
      label: `${t("frequency")}`,
      sortable: true,
      key: "frequency",
    },
    {
      label: `${t("gmv")}`,
      sortable: true,
      key: "gmv",
    },

    {
      label: `${t("createdAt")}`,
      sortable: true,
      key: "createdAt",
    },
    {
      label: `${t("expiry-date")}`,
      sortable: true,
      key: "expiryDate",
    },
    {
      label: `${t("type")}`,
      sortable: true,
      key: "type",
    },
    {
      label: `${t("value")}`,
      sortable: true,
      key: "value",
    },
    {
      label: `${t("maxUsage")}`,
      sortable: true,
      key: "maxUsage",
    },
    {
      label: `${t("product")}`,
      sortable: true,
      key: "product",
    },
  ];

  return (
    <>
      {!data.loading ? (
        <>
          {data.data.active?.length <= 0 ? (
            <Content>
              <TextContainer>
                <Message>{t("you-currently-have-no-active-coupons")}</Message>
              </TextContainer>
            </Content>
          ) : (
            <>
              <TitleContainer>
                <Text>
                  {t("active-coupons")} ({data.data.activeCount})
                </Text>
                <ViewMoreContainer onClick={onClickMoreActive}>
                  <ViewMoreText>{t("view-more")}</ViewMoreText>
                  <Chevron $ar={ar} />
                </ViewMoreContainer>
              </TitleContainer>

              <Table
                marginTop={40}
                headerInformation={tableHeader}
                totalCount={data?.data?.active?.length}
                currentPage={1}
                setPage={() => {}}
                perPage={100}
                noDataContent={t("no-data")}
                dataLoading={false}
                lastColumnAligned
                centerText
              >
                {data?.data?.active?.map((val, key) => {
                  const originalDate = val.createdAt;
                  const newDate = new Date(originalDate)
                    .toLocaleDateString()
                    .replaceAll("/", "-");
                  const parts = newDate.split("-");
                  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

                  const productNames = val.products.map(
                    (product) => product.name
                  );
                  return (
                    <TableBodyRow key={key} $offClick>
                      <FirstItem data={val.name} />
                      <TableItem center data={val.usage} />
                      <TableItem center data={t(val.frequency)} />
                      <TableItem
                        center
                        data={priceFormatter(val.gmv, false, 0)}
                      />
                      <TableItem center data={formattedDate} />
                      <TableItem center data={val.expirationDate} />
                      <TableItem center data={val.type} />
                      <TableItem
                        center
                        data={!val.value ? "/" : `${val.value}%`}
                      />

                      <TableItem center data={val.maxUsage} />
                      <LastTableItem data={productNames.join(", ")} $offClick />
                    </TableBodyRow>
                  );
                })}
              </Table>
            </>
          )}

          {data.data.deactivated?.length <= 0 ? (
            <Content>
              <TextContainer>
                <Message>{t("you-currently-have-no-inactive-coupons")}</Message>
              </TextContainer>
            </Content>
          ) : (
            <>
              <TitleContainer>
                <Text>
                  {t("inactive-coupons")} ({data.data.deactivatedCount})
                </Text>
                <ViewMoreContainer onClick={onClickMoreDeactivated}>
                  <ViewMoreText>{t("view-more")}</ViewMoreText>
                  <Chevron $ar={ar} />
                </ViewMoreContainer>
              </TitleContainer>

              <Table
                marginTop={40}
                headerInformation={tableHeader}
                totalCount={data?.data?.active?.length}
                currentPage={1}
                setPage={() => {}}
                perPage={100}
                noDataContent={t("no-data")}
                dataLoading={false}
                lastColumnAligned
                centerText
              >
                {data?.data?.deactivated?.map((val, key) => {
                  const originalDate = val.createdAt;
                  const newDate = new Date(originalDate)
                    .toLocaleDateString()
                    .replaceAll("/", "-");
                  const parts = newDate.split("-");
                  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

                  const productNames = val.products.map(
                    (product) => product.name
                  );
                  return (
                    <TableBodyRow key={key} $offClick>
                      <FirstItem data={val.name} />
                      <TableItem center data={val.usage} />
                      <TableItem center data={t(val.frequency)} />
                      <TableItem
                        center
                        data={priceFormatter(val.gmv, false, 0)}
                      />
                      <TableItem center data={formattedDate} />
                      <TableItem center data={val.expirationDate} />
                      <TableItem center data={val.type} />
                      <TableItem
                        center
                        data={!val.value ? "/" : `${val.value}%`}
                      />

                      <TableItem center data={val.maxUsage} />
                      <LastTableItem data={productNames.join(", ")} $offClick />
                    </TableBodyRow>
                  );
                })}
              </Table>
            </>
          )}
        </>
      ) : (
        <MiniLoader />
      )}
    </>
  );
};

export default AllCoupons;
