import React, { useEffect, useState } from "react";
import {
  MainContainer,
  Column,
  Content,
  TextContainer,
  Text,
  TableBodyRow,
  FirstItem,
  LastTableItem,
} from "./RecipientDashboardPage.styles";
import Sidebar from "../../AdminPortal/utils/Sidebar";
import ContentHeader from "../../AdminPortal/utils/ContentHeader";
import { useTranslation } from "react-i18next";
import Table from "../../../components/Table/Table";
import TableItem from "../../../components/Table/TableItem";
import { ReactComponent as HourglassDownIcon } from "../../../assets/icons/hourglass-down.svg";
import { ReactComponent as HourglassUpIcon } from "../../../assets/icons/hourglass-up.svg";

import PickGiftPage from "../PickGiftPage/PickGiftPage";
import { getCompanyGiftingItemsForMe } from "../../../store/slices/gift/asyncThunks";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { companyGiftingItemsDataForMe } from "../../../store/slices/gift/slice";
import {
  recipientsData,
  recipientsInfo,
} from "../../../store/slices/recipients/slice";
import { getRecipients } from "../../../store/slices/recipients/asyncThunks";
import { toast } from "react-toastify";

const RecipientDashboardPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(companyGiftingItemsDataForMe);
  const [companyGiftingRecipientId, setCompanyGiftingRecipientId] = useState(0);

  const { tab } = useParams();

  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };
  // const handlePickGift = (id, companyGiftingRecipientId) => {
  //   setCompanyGiftingRecipientId(companyGiftingRecipientId);
  //   navigate(`/user-portal/recipient/${id}`);
  // };

  useEffect(() => {
    dispatch(
      getCompanyGiftingItemsForMe({
        page: 1,
        order: "DESC",
        sortBy: "CreatedAt",
      })
    );
  }, [dispatch]);

  const tableHeader = [
    {
      label: `${t("email")}`,
      sortable: true,
      key: "email",
    },
    {
      label: `${t("first-name")}`,
      sortable: true,
      key: "firstName",
    },
    {
      label: `${t("last-name")}`,
      sortable: true,
      key: "lastName",
    },
    {
      label: `${t("mobile-number")}`,
      sortable: true,
      key: "mobilePhone",
    },
    {
      label: `${t("department")}`,
      sortable: true,
      key: "department",
    },
  ];
  const recipients = useSelector(recipientsData);

  const { total, perPage, maxReached, loading } = useSelector(recipientsInfo);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const [loadingTable, setLoadingTable] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [queryData, setQueryData] = useState({
    page: 1,
  });

  useEffect(() => {
    dispatch(getRecipients({ page: 1, search: "" }))
      .unwrap()
      .then(() => {
        setLoadingTable(false);
        setQueryData((prev) => {
          const page = 2;
          return { ...prev, page };
        });
      })
      .catch((error) => {
        toast.error(t(error.message));
      });
  }, [dispatch]);

  const loadMoreItems = () => {
    if (!maxReached && !loading) {
      dispatch(getRecipients({ page: queryData.page, search: searchTerm }))
        .unwrap()
        .then(() => {
          setQueryData((prev) => {
            const page = queryData.page + 1;
            return { ...prev, page };
          });
        })
        .catch((error) => {
          toast.error(t(error.message));
        });
    }
  };

  return (
    <MainContainer>
      <Sidebar />

      <Column>
        <ContentHeader
          showTitle
          title={
            tab === "pick-a-gift" ? t("received-gifts") : t("choose-your-gift")
          }
          showBack={tab === "pick-a-gift" ? false : true}
        />
        {data?.length <= 0 ? (
          <Content>
            <TextContainer>
              <Text>{t("you-currently-have-no-billing-in-history")}</Text>
            </TextContainer>
          </Content>
        ) : (
          <>
            {tab === "pick-a-gift" && (
              <>
                <Table
                  infinityScroll
                  marginTop={40}
                  headerInformation={tableHeader}
                  totalCount={total}
                  currentPage={+page}
                  setPage={() => {}}
                  perPage={perPage}
                  noDataContent={t("no-data")}
                  dataLoading={false}
                  lastColumnAligned
                  maxReached={maxReached}
                  dataLength={recipients.length}
                  loadMoreItems={loadMoreItems}
                >
                  {recipients.map((recipient, key) => {
                    const isSelected = selectedRecipient?.id === recipient.id;

                    return (
                      <TableBodyRow key={key} $active={isSelected} $offClick>
                        <FirstItem
                          data={
                            recipient.email && recipient.email.length > 20
                              ? recipient.email.slice(0, 18) + "..."
                              : recipient.email || "/"
                          }
                        />
                        <TableItem data={recipient.firstName || "/"} />
                        <TableItem data={recipient.lastName || "/"} />
                        <TableItem data={recipient.mobileNumber || "/"} />
                        <LastTableItem
                          $offClick
                          data={recipient.department || "/"}
                        />
                      </TableBodyRow>
                    );
                  })}
                </Table>
              </>
            )}
            {tab !== "pick-a-gift" && (
              <PickGiftPage
                id={+tab}
                companyGiftingRecipientId={companyGiftingRecipientId}
              />
            )}
          </>
        )}
      </Column>
    </MainContainer>
  );
};

export default RecipientDashboardPage;
