import styled from "styled-components";
import FirstTableItem from "../../../components/Table/FirstTableItem";
import TableItem from "../../../components/Table/TableItem";

export const MainContainer = styled.div`
  flex-direction: row;
  background: #f6f6f9;
  min-height: 100vh;
  display: flex;
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  padding: 0px 40px 0px 290px;
  @media (max-width: 870px) {
    margin-top: 50px;
    padding: 0px 40px 0px 40px;
  }
`;

export const TableBodyRow = styled.tr`
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  justify-content: space-between;
  display: flex;
  width: 100%;
  background-color: ${(props) => props.$active && "#8A4B5E15"};

  cursor: ${(props) => (props.$offClick ? "default" : "pointer")};

  min-height: 6.4rem;
  gap: 4rem;

  &:last-of-type {
    border-bottom: none;
  }
  &:hover {
    background-color: #8a4b5e15;
    transition: all 0.2s ease-in-out;
  }
  &:nth-child(even) {
    background-color: #8a4b5e10;
  }
`;

export const FirstItem = styled(FirstTableItem)`
  padding-left: 18px;
`;

export const LastTableItem = styled(TableItem)`
  padding-right: 16px;
  justify-content: flex-end;
  /* background-color: red; */
  z-index: 10;
  cursor: pointer;
  &:active {
    opacity: ${(props) => (props.$offClick ? 1 : 0)};
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.span`
  font-family: "Avenir-Black";
  font-style: normal;

  font-size: 24px;
  line-height: 33px;

  letter-spacing: -0.177777px;

  color: #8a4b5e;
`;

export const Desc = styled.span`
  font-family: "Avenir-Light";
  font-style: normal;

  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.177777px;

  color: rgba(69, 69, 132, 0.6);
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
`;

export const Buttons = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 15px;
  align-items: center;
  font-family: "Avenir-Medium";
  font-style: normal;

  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.177777px;
  color: #ffffff;
  gap: 10px;
  justify-content: center;
  background: linear-gradient(180deg, #a87283 0%, #8a4b5e 100%), #ffffff;
  border-radius: 15px;
  &:hover {
    background: #8a4b5e;
  }
  &:active {
    transition: all 0.2s ease;
    transform: scale(0.95);
  }
`;

export const TableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
  margin-top: 80px;
`;

export const DetailsTd = styled.td`
  text-align: center;
`;

export const InvoiceTd = styled.td`
  text-align: center;
`;
