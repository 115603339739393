import React from "react";
import { ContentContainer, Title, Button, Row } from "./OTPModal.styles";
import ModalDownload from "../Modal/ModalDownload";
import OtpInput from "react-otp-input";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import AppLoader from "../AppLoader";

const OTPModal = ({
  modalRef,
  otp,
  setOtp,
  t,

  validateOtp,
  loading,
}) => {
  return (
    <ModalDownload
      big
      ref={modalRef}
      onBack={() => {
        modalRef.current.hide();
        setOtp("");
      }}
    >
      <ContentContainer>
        <Row>
          <Title>{t("verification-code")}</Title>
          <Close
            onClick={() => {
              modalRef.current.hide();
              setOtp("");
            }}
            style={{ cursor: "pointer" }}
          />
        </Row>
        <OtpInput
          containerStyle={{
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
          }}
          inputStyle={{
            width: 55,
            userSelect: "none",
            height: 55,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 12,
            fontSize: 18,
            fontFamily: "Avenir-Medium",
            letterSpacing: -0.2,
            color: "#000",
            borderRadius: 27.5,
            border: "1px solid #7F7F98",
            outline: "none",
            backgroundColor: "#fff",
            boxShadow: "0px 1px 5px 1px #74519133",
          }}
          shouldAutoFocus
          value={otp}
          placeholder={"----"}
          onChange={setOtp}
          numInputs={4}
          renderSeparator={
            <span style={{ userSelect: "none", color: "transparent" }}>-</span>
          }
          renderInput={(props, i) => <input {...props} />}
        />
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 5,
          }}
        >
          <Text>{t("did-not-receive-the-code")}</Text>
          <Resend>{t("resend")}</Resend>
        </div> */}
        <Button
          style={{ width: "100%" }}
          onClick={async () => {
            await validateOtp();
          }}
          $disabled={loading}
        >
          {loading ? <AppLoader /> : t("continue")}
        </Button>
      </ContentContainer>
    </ModalDownload>
  );
};

export default OTPModal;
