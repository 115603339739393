import styled from "styled-components";
import { ReactComponent as Edit } from "../../../assets/icons/edit-3.svg";
import TableItem from "../../../components/Table/TableItem";
import FirstTableItem from "../../../components/Table/FirstTableItem";

export const TableBodyRow = styled.tr`
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  justify-content: space-between;
  display: flex;
  width: 100%;
  background-color: ${(props) => props.$active && "#8A4B5E15"};

  cursor: ${(props) => (props.$offClick ? "default" : "pointer")};

  min-height: 6.4rem;
  gap: 0rem;

  &:last-of-type {
    border-bottom: none;
  }
  &:hover {
    background-color: #8a4b5e15;
    transition: all 0.2s ease-in-out;
  }
  &:nth-child(even) {
    background-color: #8a4b5e10;
  }
`;

export const FirstItem = styled(FirstTableItem)`
  padding-left: 1.6rem;
  max-width: 250px;
  width: 250px;
`;

export const LastTableItem = styled(TableItem)`
  padding-right: 1.6rem;
  justify-content: flex-end;
  cursor: ${(props) => (props.$offClick ? "default" : "pointer")};
  max-width: 250px;
  width: 250px;
  &:active {
    opacity: ${(props) => (props.$offClick ? 1 : 0)};
  }
`;
export const MainContainer = styled.div`
  flex-direction: row;
  background: #f6f6f9;

  min-height: 100vh;
  display: flex;
  height: 100%;
`;
export const SectionTitle = styled.h1`
  color: #8a4b5e;
  font-family: "Avenir";
  user-select: none;
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.178px;
  margin-bottom: 80px;

  @media (max-width: 870px) {
    display: none;
  }
`;

export const HeaderWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  justify-content: center;
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: 75px;

  width: 100%;
  background: #f6f6f9;
  min-height: 100dvh;
  height: 100%;
  padding: ${(props) =>
    props.$upgradePackage && props.$ar
      ? "100px 290px 80px 40px"
      : props.$upgradePackage && !props.$ar
      ? "100px 40px 80px 290px"
      : !props.$upgradePackage && props.$ar
      ? "40px 290px 80px 40px"
      : "40px 40px 80px 290px"};

  @media (max-width: 870px) {
    margin-top: 80px;
    padding: ${(props) =>
      props.$upgradePackage ? "60px 40px 40px 40px" : "0px 40px 40px 40px"};
  }
`;
export const Message = styled.span`
  font-family: "Avenir-Heavy";
  font-style: normal;

  font-size: 24px;

  letter-spacing: -0.177777px;
  text-align: center;

  color: #8a4b5e;
  @media (max-width: 990px) {
    font-size: 20px;
  }
  @media (max-width: 850px) {
    font-size: 17px;
  }
  @media (max-width: 870px) {
    font-size: 22px;
  }
  @media (max-width: 650px) {
    font-size: 18px;
  }
  @media (max-width: 540px) {
    font-size: 14px;
  }
`;

export const Content = styled.div`
  max-width: 100%;
  border: 1px solid #8a4b5e77;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 25px 0px 15px 0px;
`;

export const Text = styled.span`
  font-family: "Avenir-Black";
  font-style: normal;
  user-select: none;
  font-size: 24px;
  line-height: 33px;

  letter-spacing: -0.177777px;

  color: #8a4b5e;
`;

export const Desc = styled.span`
  font-family: "Avenir-Light";
  font-style: normal;

  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.177777px;

  color: rgba(69, 69, 132, 0.6);
`;

export const TextContainer = styled.div`
  display: flex;
  margin-top: 10%;
  margin-bottom: 10%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
`;

export const Buttons = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 15px;
  align-items: center;
  font-family: "Avenir-Medium";
  font-style: normal;

  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.177777px;
  color: #ffffff;
  gap: 10px;
  justify-content: center;
  background: linear-gradient(180deg, #a87283 0%, #8a4b5e 100%), #ffffff;
  border-radius: 15px;
  &:hover {
    background: #8a4b5e;
  }
  &:active {
    transition: all 0.2s ease;
    transform: scale(0.95);
  }
`;

export const TableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 80px;
  /* min-height: 493px; */
  @media (max-width: 1890px) {
    width: 1300px;
  }
  @media (max-width: 1680px) {
    width: 1100px;
  }

  @media (max-width: 1450px) {
    width: 1000px;
  }

  @media (max-width: 1400px) {
    left: 0%;
  }
  @media (max-width: 1300px) {
    width: 800px;
  }
`;

export const DetailsTd = styled.td`
  text-align: center;
`;

export const InvoiceTd = styled.td`
  text-align: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 1500px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
  @media (max-width: 1890px) {
    width: 1300px;
  }
  @media (max-width: 1680px) {
    width: 1100px;
  }

  @media (max-width: 1450px) {
    width: 1000px;
  }

  @media (max-width: 1400px) {
    left: 0%;
  }
  @media (max-width: 1300px) {
    width: 800px;
  }
`;

export const PageButton = styled.button`
  padding: 2px 7px;
  border: none;
  background: transparent;
  font-family: "Avenir-Medium";
  font-size: 14px;
  color: #8a4b5e;
  cursor: pointer;
  &:active {
    opacity: 0.6;
  }
`;

export const PageCountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;
  gap: 5px;
  width: 1500px;
  margin-top: -70px;
  @media (max-width: 1890px) {
    width: 1300px;
  }
  @media (max-width: 1680px) {
    width: 1100px;
  }

  @media (max-width: 1450px) {
    width: 1000px;
  }

  @media (max-width: 1400px) {
    left: 0%;
  }
  @media (max-width: 1300px) {
    width: 800px;
  }
`;

export const StyledTd = styled.td`
  text-align: center;
  min-width: 150px !important;
`;
export const StyledTdEdit = styled.td`
  text-align: left;
  min-width: 40px !important;
`;

export const StyledTdFirst = styled(StyledTd)`
  text-align: left;
`;
export const StyledTh = styled.th`
  text-align: center;
`;

export const EditIcon = styled(Edit)`
  cursor: pointer;
  margin-left: 20px;
`;
export const LoaderContainer = styled.div`
  padding: 120px 0px;
  background-color: transparent;
`;
export const ViewMoreText = styled.span`
  color: #55a1e8;
  user-select: none;
  font-family: "Avenir";
  text-transform: uppercase;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.067px;
`;
export const ViewMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const StyledTable = styled.table`
  /* min-height: 491px; */
`;

export const TableInput = styled.input`
  border: none;
  outline: none;
  background-color: transparent;
  font-family: "Avenir-Light";
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  text-align: center;
  padding: 3px;

  max-width: 140px !important;
  &:focus {
    outline: none;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #8a4b5e;
  }
`;
export const TableInputFirst = styled(TableInput)`
  text-align: left;
`;
export const StyledTdAll = styled(StyledTd)`
  padding-bottom: 10px;
`;

export const StyledAHref = styled.a`
  cursor: pointer;
  margin-top: 4px;
`;

export const InvoiceDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const ButtonGenerateInvoice = styled.div`
  border-radius: 12px;
  user-select: none;
  background: #8a4b5e;
  height: 30px;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  border: none;
  color: #fff;
  text-align: center;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
  cursor: pointer;

  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
    background: #8a4b5e;
  }

  @media (max-width: 1965px) {
    height: 35px;
    padding: 4px 8px;
    font-size: 14px;
  }
`;

export const CompanyDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 4px;
`;

export const LoaderDiv = styled.div`
  margin-top: 150px;
  display: flex;
  justify-content: center;
  @media (max-height: 870px) {
    margin-top: 100px;
  }

  @media (max-height: 550px) {
    margin-top: 40px;
  }

  @media (max-height: 450px) {
    margin-top: 20px;
  }
`;

export const StyledPageCountContainer = styled.div`
  width: 1800px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;
  gap: 5px;
  margin-top: -70px;

  @media (max-width: 2125px) {
    width: 1700px;
  }

  @media (max-width: 2010px) {
    width: 1600px;
  }

  @media (max-width: 1890px) {
    width: 1300px;
  }
  @media (max-width: 1680px) {
    width: 1100px;
  }

  @media (max-width: 1450px) {
    width: 1000px;
  }

  @media (max-width: 1400px) {
    left: 0%;
  }
  @media (max-width: 1300px) {
    width: 800px;
  }
`;

export const ContentSubscriptionHistory = styled.div`
  padding-top: 10px;
`;

export const RowSubscriptionHistory = styled.div`
  padding-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: -40px;
  @media (max-width: 870px) {
    margin-bottom: 0px;
  }
`;

export const StyledViewMoreContainer = styled(ViewMoreContainer)`
  align-items: start;
  margin-left: -10px;
  margin-top: 16px;
`;

export const CompanyDetailsText = styled.span`
  font-family: ${(props) =>
    props.$fontFamily ? "Avenir-Medium" : "Avenir-Black"};
  font-style: normal;
  user-select: none;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;

  @media (max-width: 1000px) {
    font-size: 22px;
  }

  @media (max-width: 700px) {
    font-size: 20px;
  }

  @media (max-width: 410px) {
    font-size: 16px;
  }

  @media (max-width: 380px) {
    font-size: 14px;
  }
`;
