import React from "react";
import {
  Title,
  CloseContainer,
  SmallTitle,
  BlankText,
  BlankPrice,
  Button,
  Row,
  SampleColumn,
  Content,
  StyledButton,
  StyledPrice,
  Error,
  Price,
  ButtonContainer,
} from "./PriceBreakModal.styles";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import Modal from "./Modal2";
import { useTranslation } from "react-i18next";
import { ReactComponent as Minus } from "../../assets/icons/minus.svg";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";

const BuySampleModal = ({
  open,
  onBuyBlank,
  close,
  item,
  errorBranded,
  errorBlank,
  onBuyPrinted,
  quantityBlank,
  setQuantityBlank,
  quantityBranded,
  setQuantityBranded,
}) => {
  const { t } = useTranslation();

  const onClickPlus = (type) => {
    if (type === "blank") {
      setQuantityBlank(quantityBlank + 1);
    } else if (type === "branded") {
      setQuantityBranded(quantityBranded + 1);
    }
  };

  const onClickMinus = (type) => {
    if (type === "blank" && quantityBlank > 0) {
      setQuantityBlank(quantityBlank - 1);
    } else if (type === "branded" && quantityBranded > 0) {
      setQuantityBranded(quantityBranded - 1);
    }
  };

  const handleInputChange = (type, e) => {
    const inputValue = e.target.value;

    if (!isNaN(inputValue) && inputValue !== "") {
      const quantityValue = parseInt(inputValue, 10);

      if (type === "blank") {
        setQuantityBlank(quantityValue);
      } else if (type === "branded") {
        setQuantityBranded(quantityValue);
      }
    }
  };
  return (
    <Modal
      onClose={close}
      open={open}
      right
      width={"567px"}
      height={"100%"}
      padding={"50px 60px"}
      unset
    >
      <CloseContainer
        onClick={() => {
          close();
          setQuantityBlank(0);
          setQuantityBranded(0);
        }}
      >
        <CloseIcon fill="white" width={20} height={20} />
      </CloseContainer>
      <Title>{t("buy-sample")}</Title>
      <Content style={{ gap: "1rem" }}>
        <SampleColumn>
          <Row>
            <SmallTitle>{t("blank")}</SmallTitle>
            <BlankPrice>{item.samplePrice} SR</BlankPrice>
          </Row>
          <BlankText>{t("blank-text")}</BlankText>
          <Row>
            <Price>{t("quantity")}</Price>
            <Row>
              <StyledButton onClick={() => onClickMinus("blank")}>
                <Minus />
              </StyledButton>
              <StyledPrice
                value={quantityBlank}
                onChange={(e) => handleInputChange("blank", e)}
              />
              <StyledButton onClick={() => onClickPlus("blank")}>
                <Plus />
              </StyledButton>
            </Row>
          </Row>
          <ButtonContainer>
            <Button onClick={onBuyBlank}>{t("add-to-cart")}</Button>
            {errorBlank && <Error>*{t("you-must-add-quantity-min-1")}</Error>}
          </ButtonContainer>
        </SampleColumn>
        <SampleColumn>
          <Row>
            <SmallTitle>{t("printed")}</SmallTitle>
            <BlankPrice>{item.printedPrice} SR</BlankPrice>
          </Row>
          <BlankText>
            {t("printed-text")} {item.printedPrice} SR{" "}
            {t("from-your-regular-order-price")}
          </BlankText>
          <Row>
            <Price>{t("quantity")}</Price>
            <Row>
              <StyledButton onClick={() => onClickMinus("branded")}>
                <Minus />
              </StyledButton>
              <StyledPrice
                value={quantityBranded}
                onChange={(e) => handleInputChange("branded", e)}
              />
              <StyledButton onClick={() => onClickPlus("branded")}>
                <Plus />
              </StyledButton>
            </Row>
          </Row>
          <ButtonContainer>
            <Button onClick={onBuyPrinted}>{t("add-to-cart")}</Button>
            {errorBranded && <Error>*{t("you-must-add-quantity-min-1")}</Error>}
          </ButtonContainer>
        </SampleColumn>
      </Content>
    </Modal>
  );
};

export default BuySampleModal;
