import styled from "styled-components";
import TableItem from "../../../components/Table/TableItem";
import FirstTableItem from "../../../components/Table/FirstTableItem";

export const MainContainer = styled.div`
  flex-direction: row;
  background: #f6f6f9;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  position: relative;
`;

export const HeaderWrapper = styled.div`
  padding: 0 60px 0px 40px;
  background: #8a4b5e;
  position: fixed;
  z-index: 1000;
  width: 100%;
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  margin-top: 75px;

  transition: all 0.2s ease-in-out;
  padding: ${(props) =>
    props.$upgradePackage && props.$ar
      ? "100px 290px 80px 40px"
      : props.$upgradePackage && !props.$ar
      ? "100px 40px 80px 290px"
      : !props.$upgradePackage && props.$ar
      ? "40px 290px 80px 40px"
      : "40px 40px 80px 290px"};

  @media (max-width: 870px) {
    margin-top: 50px;
    padding: ${(props) =>
      props.$upgradePackage ? "60px 40px 40px 40px" : "0px 40px 40px 40px"};
  }
`;
export const StyledTd = styled.td`
  min-width: 120px !important;
  text-align: center;
  border: none;
`;

export const TableBodyRow = styled.tr`
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  justify-content: space-between;
  display: flex;
  width: 100%;
  background-color: ${(props) => props.$active && "#8A4B5E15"};

  cursor: pointer;

  min-height: 6.4rem;
  gap: 4rem;

  &:last-of-type {
    border-bottom: none;
  }
  &:hover {
    background-color: #8a4b5e15;
    transition: all 0.2s ease-in-out;
  }
  &:nth-child(even) {
    background-color: #8a4b5e10;
  }
`;
export const StyledTh = styled.th`
  text-align: center;
`;
export const FirstItem = styled(FirstTableItem)`
  padding-left: 18px;
`;
export const StyledTr = styled.tr`
  cursor: pointer;
  gap: 0px;
  background-color: ${(props) =>
    props.$selected ? "#8A4B5E25" : "transparent"};
`;
export const StyledTdAll = styled(StyledTd)`
  padding: 5px;
`;
export const SectionTitle = styled.h1`
  color: #8a4b5e;
  margin-top: -40px;
  font-family: "Avenir-Heavy";
  user-select: none;
  font-size: 3.5rem;
  letter-spacing: -0.178px;
  text-align: center;
  @media (max-width: 870px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 38px;
  margin-top: 20px;
  @media (max-width: 870px) {
    margin-top: 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export const Search = styled.input`
  padding: 8px;
  border: unset;
  border-bottom: 2px solid #e6e6e6;
  width: 240px;
  font-family: "Avenir-Medium";
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  background: transparent;
  &:focus {
    outline: unset;
  }
  &::placeholder {
    font-family: "Avenir-Medium";
    font-style: normal;

    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.177777px;
    color: #c99ba9;
  }
`;

export const HeadRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
`;
export const SearchBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 870px) {
    justify-content: center;
  }
`;
export const ChargeWalletContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 870px) {
    justify-content: center;
    margin-bottom: 40px;
  }
`;
export const TableContainer = styled.div`
  overflow-x: auto;
  margin-top: 20px;
  margin-bottom: 80px;
  width: 1800px;
  @media (max-width: 2125px) {
    width: 100%;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
`;

export const SearchIconContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 3px;
  background: #fff;
`;
export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;
export const Button = styled.button`
  padding: 12px 15px;
  background: #8a4b5e;
  border-radius: 15px;
  border: 0;
  color: #fff;
  font-family: "Avenir-Medium";
  font-size: 18px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: block;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: ${(props) => (props.$ar ? "unset" : "20px")};
    left: ${(props) => (!props.$ar ? "unset" : "20px")};
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &::after {
    width: 25px;
    height: 1px;
    background: white;
    transform: translateX(-3px);
    margin-top: 0px;
  }

  &::before {
    content: "";
    transform: rotate(-135deg) translateX(50%);
    width: 11px;
    height: 11px;
    background: transparent;
    border-right: ${(props) => (!props.$ar ? "unset" : "1px solid white")};
    border-top: ${(props) => (!props.$ar ? "unset" : "1px solid white")};
    border-left: ${(props) => (props.$ar ? "unset" : "1px solid white")};
    border-bottom: ${(props) => (props.$ar ? "unset" : "1px solid white")};
    margin-top: -1px;
  }

  &:hover {
    background: #4ab639;
    padding: ${(props) =>
      props.$ar ? "12px 15px 12px 60px" : "12px 60px 12px 15px"};
  }

  &:hover::after,
  &:hover::before {
    opacity: 1;
    right: ${(props) => (props.$ar ? "unset" : "15px")};
    left: ${(props) => (props.$ar ? "15px" : "unset")};
  }
`;
export const ButtonLoading = styled.button`
  padding: 11px 55px 6px 55px;
  background: #8a4b5e;
  border-radius: 15px;
  border: 0;
  color: #fff;
  font-family: "Avenir-Medium";
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 18px;
  position: relative;
  display: block;
`;
export const ButtonBack = styled.button`
  padding: 12px 15px;
  background: #8a4b5e;
  border-radius: 15px;
  border: 0;
  color: #fff;
  font-family: "Avenir-Medium";
  font-size: 18px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: block;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: ${(props) => (props.$ar ? "unset" : "20px")};
    right: ${(props) => (!props.$ar ? "unset" : "20px")};
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &::after {
    width: 25px;
    height: 1px;
    background: white;
    transform: translateX(-4.5px);
    margin-top: 0px;
  }

  &::before {
    content: "";
    transform: rotate(-135deg) translateX(50%);
    width: 11px;
    height: 11px;
    background: transparent;
    border-left: ${(props) => (!props.$ar ? "none" : "1px solid white")};
    border-right: ${(props) => (props.$ar ? "none" : "1px solid white")};
    border-top: ${(props) => (props.$ar ? "unset" : "1px solid white")};
    border-bottom: ${(props) => (!props.$ar ? "unset" : "1px solid white")};
    margin-top: -1px;
  }

  &:hover {
    background: #d92525;
    padding: ${(props) =>
      !props.$ar ? "12px 15px 12px 60px" : "12px 60px 12px 15px"};
  }

  &:hover::after,
  &:hover::before {
    opacity: 1;
    right: 15px;
  }
`;
export const ButtonContainer = styled.div`
  justify-content: right;
  margin: 40px 0px;
  box-shadow: ${(props) =>
    props.$shadow
      ? "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;"
      : "none"};
  display: flex;
`;

export const BalanceContainer = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;
export const Balance = styled.span`
  font-family: "Avenir-Medium";

  font-size: 35px;
  letter-spacing: -0.18px;
  color: #8a4b5e;
  @media (max-width: 330px) {
    font-size: 25px;
  }
`;
export const BalanceValue = styled.span`
  font-family: "Avenir-Heavy";

  font-size: 35px;
  letter-spacing: -0.18px;
  color: #8a4b5e;
  @media (max-width: 330px) {
    font-size: 25px;
  }
`;
export const Text = styled.span`
  font-family: "Avenir-Medium";
  margin-top: 50px;
  margin-bottom: 34px;

  font-size: 16px;
  letter-spacing: -0.18px;
  color: #8a4b5e;
`;
export const Bold = styled.span`
  font-family: "Avenir-Black";

  font-size: 16px;
  letter-spacing: -0.18px;
  color: #8a4b5e;
`;

export const NameContainer = styled.div`
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
`;

export const NameRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 200px;
`;

export const Name = styled.span`
  font-family: "Avenir-Medium";
  font-style: normal;

  font-size: 18px;
  letter-spacing: -0.177777px;

  color: #8a4b5e;
`;

export const Email = styled.span`
  font-family: "Avenir-Heavy";
  font-style: normal;

  font-size: 18px;
  letter-spacing: -0.177777px;
  text-decoration-line: underline;

  color: #8a4b5e;
`;
export const BudgetContentContainer = styled.div`
  @media (max-width: 870px) {
  }
`;
export const BudgetContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-top: 50px;
  border-bottom: 1px solid #e6e6e6;
  @media (max-width: 510px) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
`;
export const BudgetConfirmContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  @media (max-width: 620px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const BudgetTitle = styled.span`
  display: flex;
  font-family: "Avenir-Black";
  font-style: normal;

  font-size: 18px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
`;
export const BudgetInput = styled.input`
  padding: 10px 20px;
  border-radius: 15px;
  border: 1px solid #8c8c8c;
  font-family: "Avenir-Medium";
  font-style: normal;
  font-size: 1.8rem;
  height: 5rem;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  &::placeholder {
    color: #8a4b5e60;
  }
`;
export const NewBalanceContainer = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;
`;

export const Star = styled.span`
  margin-top: -5px;
  font-family: "Avenir-Black";
  font-style: normal;

  font-size: 20px;
  letter-spacing: -0.177777px;
  color: red;
`;

export const OccasionContainer = styled.div`
  display: flex;
  margin-top: 40px;
  flex-direction: row;
  gap: 50px;
  align-items: center;
  // justify-content: space-between;
  @media (max-width: 1200px) {
    gap: 10px;
  }
  @media (max-width: 1050px) {
    flex-direction: column;
    margin-top: 0px;
    align-items: flex-start;
  }
`;

export const OccasionTitle = styled.span`
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 22px;
  letter-spacing: -0.177777px;

  color: #8a4b5e;
  &:hover {
    cursor: default;
  }
`;

export const GiftCardContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const DeliveryDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 300px;
  position: relative;
  align-items: center;

  @media (max-width: 540px) {
    flex-direction: row;
    gap: 10px;
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    width: 350px;
  }

  @media (max-width: 420px) {
    width: 310px;
  }
  @media (max-width: 380px) {
    width: 270px;
  }
`;

export const TextArea = styled.textarea`
  padding: 10px 20px;
  width: 100%;
  height: 150px;

  font-size: 16px;
  border: 1px solid #8c8c8c;
  border-radius: 15px;

  font-family: "Avenir-Medium";
  font-style: normal;

  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: -0.177777px;

  color: #8a4b5e;
  &::placeholder {
    color: #8a4b5e60;
  }
  &:focus {
    outline: none;
  }
`;
export const TextAreaOccasion = styled.input`
  z-index: 4;
  padding: 10px 20px;
  width: 50%;
  height: 5rem;
  font-size: 1.6rem;
  background-color: white;
  border: 1px solid #8c8c8c;
  border-radius: 15px;
  font-family: "Avenir-Medium";
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: -0.177777px;

  color: #8a4b5e;
  &::placeholder {
    color: #8a4b5e60;
  }
  &:focus {
    outline: none;
  }
`;
export const Title = styled.span`
  font-family: "Avenir-Black";
  font-style: normal;

  font-size: 50px;
  letter-spacing: -0.177777px;

  color: #8a4b5e;

  @media (max-width: 1500px) {
    text-align: center;
  }
`;
export const PlacedOrderContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 30px;
  border: 1px solid #b1b1b1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 100px 100px 150px 100px;
`;

export const OrderText = styled.span`
  font-family: "Avenir-Heavy";
  font-style: normal;

  font-size: 22px;
  letter-spacing: -0.177777px;

  color: #8a4b5e;
`;
export const OrderNumber = styled.span`
  cursor: pointer;
  font-family: "Avenir-Heavy";
  font-style: normal;

  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.177777px;
  text-decoration: underline;
  color: #8a4b5e;
`;

export const OrderNumberStyled = styled(OrderNumber)`
  cursor: text;
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.177777px;
  text-decoration: none;
  color: #8a4b5e;
`;
export const BalanceValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const LocationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  gap: 50px;
  @media (max-width: 540px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const LastTableItem = styled(TableItem)`
  padding-right: 16px;
  justify-content: flex-end;
  cursor: pointer;
  &:active {
    opacity: 0.6;
  }
`;
export const EmptyTable = styled.div`
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.04);
  overflow: auto;
  min-height: 875px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;

  // height: 100vh; /* Set the height of the container to occupy the full viewport height */
`;

export const TextNone = styled.span`
  font-family: "Avenir-Black";
  font-style: normal;
  user-select: none;
  font-size: 24px;

  line-height: 33px;
  padding-bottom: 30px;
  margin-top: 60px;

  letter-spacing: -0.177777px;

  color: #8a4b5e;
`;

export const PageButton = styled.button`
  padding: 6px 10px;
  border: none;
  background: transparent;
  font-family: "Avenir-Medium";
  font-size: 12px;
  color: #8a4b5e;
  cursor: pointer;
`;

export const PageCountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;
  gap: 5px;
  width: 1800px;

  margin-top: -70px;
  @media (max-width: 2125px) {
    width: 100%;
  }
`;

export const MapContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 540px) {
    align-items: center;
  }
`;

export const CustomAddressInput = styled.input`
  padding: 10px 20px;
  border-radius: 15px;
  border: 1px solid #8c8c8c;
  height: 5rem;
  background-color: white;

  font-family: "Avenir-Medium";
  font-style: normal;

  font-size: 18px;
  line-height: 25px;

  width: 500px;
  align-items: center;
  letter-spacing: -0.177777px;

  color: #8a4b5e;
  &::placeholder {
    color: #8a4b5e60;
  }
  margin-bottom: 20px;

  @media (max-width: 150px) {
    width: 450px;
  }

  @media (max-width: 570px) {
    width: 400px;
  }

  @media (max-width: 480px) {
    width: 350px;
  }

  @media (max-width: 420px) {
    width: 310px;
  }
  @media (max-width: 380px) {
    width: 270px;
  }
`;

export const ImgLinearGradient = styled.div`
  position: absolute;
  flex-direction: column;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
`;

export const ImageWrapper = styled.div`
  position: relative;
  user-select: none;
  width: 270px;
  height: 180px;
  border-radius: 16px;
  box-shadow: 0px 5px 12px 0px rgba(45, 45, 67, 0.11);
  // overflow: hidden;

  @media (max-width: 1515px) {
    width: 300px;
  }

  @media (max-width: 1515px) {
    width: 270px;
  }

  @media (max-width: 1255px) {
    width: 230px;
  }
`;

export const ImgText = styled.span`
  display: absolute;
  z-index: 2;
  color: #fff;
  bottom: 10px;
  left: 0;
  right: 0;
  user-select: none;
  font-family: "FrankfurterEF-Medium";
  font-size: 16px;
  letter-spacing: -0.178px;
  line-height: 24px;
  padding-left: ${(props) => (props.$ar ? "unset" : "16px")};
  padding-right: ${(props) => (!props.$ar ? "unset" : "16px")};
`;

export const AddOnsImg = styled.img`
  user-select: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
`;

export const SwitchWrapper = styled.div`
  position: absolute;
  right: ${(props) => (props.$ar ? "unset" : "16px")};
  left: ${(props) => (!props.$ar ? "unset" : "16px")};
  top: 16px;
  z-index: 3;
`;

export const ImagesContainer = styled.div`
  display: flex;
  gap: 50px;

  @media (max-width: 1100px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
  }

  @media (max-width: 870px) {
    align-self: flex-start;
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 120px;

  @media (max-width: 1300px) {
    flex-direction: column;
    gap: 36px;
  }

  @media (max-width: 870px) {
    align-self: flex-start;
  }

  @media (max-width: 570px) {
    width: 400px;
    justify-content: center;
  }

  @media (max-width: 540px) {
    align-self: center;
  }

  @media (max-width: 480px) {
    width: 350px;
  }

  @media (max-width: 420px) {
    width: 310px;
  }
  @media (max-width: 380px) {
    width: 270px;
  }
`;

export const DeliveryDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 120px;

  @media (max-width: 1300px) {
    flex-direction: column;
    gap: 36px;
  }

  @media (max-width: 870px) {
    align-self: flex-start;
  }

  @media (max-width: 570px) {
    width: 400px;
    justify-content: center;
  }

  @media (max-width: 540px) {
    align-self: center;
  }

  @media (max-width: 480px) {
    width: 350px;
  }

  @media (max-width: 420px) {
    width: 310px;
  }
  @media (max-width: 380px) {
    width: 270px;
  }
`;

export const StyledTextArea = styled(TextArea)`
  resize: none;
  width: 500px;

  @media (max-width: 1500px) {
    width: 400px;
  }

  @media (max-width: 1400px) {
    width: 360px;
  }

  @media (max-width: 1350px) {
    width: 320px;
  }

  @media (max-width: 1300px) {
    width: 500px;
  }

  @media (max-width: 570px) {
    width: 400px;
  }

  @media (max-width: 540px) {
    align-self: center;
  }

  @media (max-width: 480px) {
    width: 350px;
  }

  @media (max-width: 420px) {
    width: 310px;
  }
  @media (max-width: 380px) {
    width: 270px;
  }
`;

export const Label = styled.span`
  font-family: ${(props) => (props.$bold ? "Avenir-Heavy" : "Avenir-Medium")};
  font-size: 18px;
  color: #8a4b5e;
`;
export const LabelRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
`;

export const BalanceChargeButton = styled.button`
  background-color: #8a4b5e;
  font-family: "Avenir-Medium";
  font-size: 16px;
  color: #fff;
  padding: 6px;
  height: 3.8rem;
  width: 17.8rem;
  align-items: center;
  border-radius: 2rem;
  justify-content: center;
  display: flex;
  border: none;
  outline: none;
  cursor: pointer;
  align-self: ${(props) => (props.$center ? "center" : "flex-start")};
`;

export const BalanceBox = styled.span`
  font-family: "Avenir-Medium";
  font-size: 16px;
  color: #8a4b5e;
  padding: 12px;
  height: 3.8rem;
  width: 20.8rem;
  border-radius: 2rem;
  border: 1px solid #8a4b5e66;
  background-color: #8a4b5e22;
  color: #8a4b5e88;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const ConfirmContainer = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  justify-content: center;
  background-color: white;
  border-radius: 30px;
  border: 1px solid #b1b1b1;
  padding: 8rem;
  align-self: center;
  width: fit-content;
`;

export const Details = styled.div`
  padding: 0px 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 12px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
export const ImagesRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
`;
