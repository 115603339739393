import React, { useEffect, useRef, useState } from "react";
import {
  Background,
  BackgroundRotated,
  MainContainer,
  MobileScreenContainer,
  BackgroundContainer,
  HeaderContainer,
  Menu,
  Logo,
  Share,
  NameRow,
  NameContainer,
  Username,
  Name,
  ProfileInfoContainer,
  ProfilePhoto,
  InfoBar,
  Count,
  BarName,
  BarRow,
  ButtonAdd,
  ContentContainer,
  Add,
  Card,
  CardColumn,
  CardName,
  CardDesc,
  CardPrice,
  CardImg,
  CardContainer,
  Number,
  CountContainer,
  Tab,
  TabContainer,
  LoaderContainer,
  TabName,
  StyledLoaderContainer,
  ProfilePicEmpty,
  Scroll,
  Gap,
  LocaleWrapper,
  LocaleWrapperAr,
} from "./MobileAppPage.styles";
import Img1 from "../../assets/images/img-1.png";
import Img2 from "../../assets/images/img-2.png";
import Img3 from "../../assets/images/img-3.png";
import Img4 from "../../assets/images/img-4.png";
import GiftImg from "../../assets/images/gift.png";
import { ReactComponent as WishesIcon } from "../../assets/icons/wishes-icon.svg";
import { ReactComponent as FriendsIcon } from "../../assets/icons/friends-icon.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/location-icon.svg";
import { ReactComponent as Explore } from "../../assets/icons/explore-icon.svg";
import { ReactComponent as Wishlist } from "../../assets/icons/wishlist-icon.svg";
import { ReactComponent as LogoGift } from "../../assets/icons/gift-empty-icon.svg";
import { ReactComponent as LoaderWhite } from "../../assets/icons/loader-white.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getUsersWishlistItemsUnauthorized } from "../../store/slices/item/asyncThunks";
import { getUserInfoByIdUnauthorized } from "../../store/slices/user/asyncThunks";
import { useSelector } from "react-redux";
import { selectedItemLoading } from "../../store/slices/item/itemSlice";
import {
  selectedUserInfo,
  selectedUserLoading,
} from "../../store/slices/user/userSlice";
import { getCurrencySymbol } from "../../utils/constants";
import { priceFormatter } from "../../utils/utils";
import DownloadAppModal from "../../components/DownloadAppModal/DownloadAppModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import LocaleSelection from "../../components/LocaleSelection/LocaleSelection";
import { selectedLocale } from "../../store/slices/common/slice";
import AppLoader from "../../components/AppLoader";

const MobileAppPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const openModal = useRef();
  const itemLoading = useSelector(selectedItemLoading);
  const userInfo = useSelector(selectedUserInfo);
  const userLoading = useSelector(selectedUserLoading);
  const [data, setData] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("userId");

  const locale = useSelector(selectedLocale);

  useEffect(() => {
    if (userId) {
      dispatch(getUserInfoByIdUnauthorized(userId))
        .unwrap()
        .then((data) => {
          if (data.user.wishlistVisibility === "public") {
            dispatch(getUsersWishlistItemsUnauthorized(userId))
              .unwrap()
              .then((items) => {
                setData(items);
              })
              .catch((err) => console.log(err.message));
          }
        })
        .catch((err) => console.log(err.message));
    }
  }, [dispatch, userId]);

  return (
    <MainContainer>
      <MobileScreenContainer onClick={() => openModal.current.show()}>
        <div>
          <BackgroundContainer>
            <Background />
            <BackgroundRotated />
          </BackgroundContainer>
          <HeaderContainer>
            <Menu />
            <Logo />
            <Share />
            {locale === "en" ? (
              <LocaleWrapper>
                <LocaleSelection color textOnly />
              </LocaleWrapper>
            ) : (
              <LocaleWrapperAr>
                <LocaleSelection color textOnly />
              </LocaleWrapperAr>
            )}
          </HeaderContainer>
          {!userLoading ? (
            <>
              <ProfileInfoContainer>
                {userInfo.user?.profilePhoto ? (
                  <ProfilePhoto
                    src={userInfo.user?.profilePhoto}
                    alt="Profile-img"
                  />
                ) : (
                  <ProfilePicEmpty>
                    <div>
                      <LogoGift />
                    </div>
                  </ProfilePicEmpty>
                )}
                <NameContainer>
                  <NameRow>
                    <Name>{userInfo.user?.fullName}</Name>
                    <img
                      src={GiftImg}
                      alt="Gift-img"
                      style={{ userSelect: "none" }}
                    />
                  </NameRow>
                  <Username>@{userInfo.user?.username}</Username>
                </NameContainer>
              </ProfileInfoContainer>
              <InfoBar>
                <BarRow>
                  <WishesIcon />
                  <Count>{userInfo.user?.numOfWishes}</Count>
                  <BarName>{t("wishes")}</BarName>
                </BarRow>
                <BarRow>
                  <FriendsIcon />
                  <Count>{userInfo.user?.numOfFriends}</Count>
                  <BarName>{t("friends")}</BarName>
                </BarRow>
                {userInfo.user?.locationVisibility === "public" && (
                  <BarRow>
                    <LocationIcon />
                    <BarName>{userInfo.user?.location.name}</BarName>
                  </BarRow>
                )}
              </InfoBar>
            </>
          ) : (
            <LoaderContainer>
              <LoaderWhite width={54} />
            </LoaderContainer>
          )}
        </div>
        <ContentContainer>
          <Gap>
            {!itemLoading ? (
              <>
                <ButtonAdd>
                  <Add />
                  {t("add-a-gift")}
                </ButtonAdd>
                <Scroll>
                  {data.map((item, index) => (
                    <CardContainer
                      key={item.id}
                      onClick={() => {
                        navigate(`/mobile-app/item-detail/${item.id}`);
                      }}
                    >
                      <CountContainer>
                        <Number>{index + 1}</Number>
                      </CountContainer>
                      <Card>
                        <CardImg
                          src={item.photos[0]?.url}
                          alt={`Item-photo-${index}`}
                        />
                        <CardColumn>
                          <CardName>{item.name}</CardName>
                          {/* <CardDesc>{item.description}</CardDesc> */}

                          {item.isBought ? (
                            <div
                              style={{
                                flexDirection: "row",
                                gap: 10,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <CardPrice $isBought>
                                {priceFormatter(item.price, true, 15)}{" "}
                                {getCurrencySymbol(item.currency)}{" "}
                              </CardPrice>
                              <CardPrice
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                {t("bought")}
                              </CardPrice>
                            </div>
                          ) : (
                            <CardPrice>
                              {priceFormatter(item.price, true, 15)}{" "}
                              {getCurrencySymbol(item.currency)}
                            </CardPrice>
                          )}
                        </CardColumn>
                        {item.buyable && !item.isBought && (
                          <div
                            style={{
                              position: "absolute",
                              bottom: "10px",
                              ...(locale === "en" && { right: "5px" }),
                              ...(locale === "ar" && { left: "5px" }),
                            }}
                          >
                            <div>
                              <img
                                src={GiftImg}
                                alt="Gift-img"
                                style={{ userSelect: "none" }}
                              />
                            </div>
                          </div>
                        )}
                      </Card>
                    </CardContainer>
                  ))}
                </Scroll>
              </>
            ) : (
              <StyledLoaderContainer>
                <AppLoader />
              </StyledLoaderContainer>
            )}
          </Gap>
          <TabContainer>
            <Tab>
              <Explore />
              <TabName>{t("explore")}</TabName>
            </Tab>
            <Tab $active>
              <Wishlist />
              <TabName $active>{t("wishlist")}</TabName>
            </Tab>
          </TabContainer>
        </ContentContainer>
      </MobileScreenContainer>
      <DownloadAppModal modalRef={openModal} />
    </MainContainer>
  );
};

export default MobileAppPage;
