import axiosApiClient from "..";

import { handleError } from "../../utils/errors";

const BASE_URL = "log";

class LogService {
  createErrorLog = async (data) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/error`, {
        ...data,
        source: "business_portal",
      });
      if (response.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      console.log(err, "Error occurred while creating log.");
    }
  };

  createAuditLog = async (data) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/audit`, {
        ...data,
        source: "business_portal",
      });
      if (response.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      console.log(err, "Error occurred while creating log.");
    }
  };
}

const logService = new LogService();

export default logService;
