import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  MainContainer,
  Title,
  ScreenContent,
  Form,
  InputWrapper,
  Input,
  ButtonContainer,
  SubmitButton,
  Subtitle,
  StyledTextDiv,
  StyledLockIcon,
  StyledVisibility,
  StyledVisibilityOff,
} from "./SetUpNewPasswordPage.styles";

import { toast } from "react-toastify";
import { resetPassword } from "../../../store/slices/auth/asyncThunks";
import { ReactComponent as Loading } from "../../../assets/icons/loader-white.svg";
import { useSelector } from "react-redux";
import { selectResetPasswordPending } from "../../../store/slices/auth/slice";
import { isValidPassword } from "../../../utils/utils";
import Loader from "../../../components/Loader/Loader";
import openToast from "../../../utils/toast";
import Icon from "../../../components/Icon";
import { selectedLocale } from "../../../store/slices/common/slice";

const SetUpNewPasswordPage = () => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState(false);
  const [visibility2, setVisibility2] = useState(false);
  const navigate = useNavigate();
  const loading = useSelector(selectResetPasswordPending);
  const { token } = useParams();
  const toastId = useRef(null);

  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";

  const handleVisibilityToggle = () => {
    setVisibility(!visibility);
  };
  const handleVisibilityToggle2 = () => {
    setVisibility2(!visibility2);
  };

  const handleSubmit = () => {
    if (validateForm()) {
      dispatch(resetPassword({ newPassword, code: token }))
        .unwrap()
        .then(() => {
          toast.success(t("password-successfully-reset"));
          navigate("/login");
        })
        .catch((error) => {
          openToast(toastId, "new-password", "error", t(error.message));
        });
    }
  };

  const validateForm = () => {
    let formIsValid = true;

    if (!newPassword) {
      openToast(toastId, "new-password", "error", t("password-is-required"));
      formIsValid = false;
    } else if (!isValidPassword(newPassword)) {
      toast.info(t("password-contain"));
      formIsValid = false;
    }

    if (!confirmPassword) {
      openToast(
        toastId,
        "new-password",
        "error",
        t("confirm-password-is-required")
      );
      formIsValid = false;
    } else if (confirmPassword !== newPassword) {
      openToast(toastId, "new-password", "error", t("password-dont-match"));
      formIsValid = false;
    }

    return formIsValid;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      {loading && <Loader></Loader>}
      <Header showNav={true} color={true} display />
      <MainContainer>
        <ScreenContent>
          <div>
            <Title>{t("welcome-to-ygii")}</Title>
            <StyledTextDiv>
              <Subtitle>{t("dont-have-an-account")}</Subtitle>

              <Link to="/sign-up" className="signup">
                {t("sign-up")}
              </Link>
            </StyledTextDiv>
            <Form>
              <InputWrapper>
                <Icon
                  icon={<StyledLockIcon className="login__icon" />}
                  selectedLocale={ar}
                />
                <Input
                  type={visibility ? "text" : "password"}
                  placeholder={t("new-password")}
                  name="password"
                  autoComplete="off"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                {visibility ? (
                  <Icon
                    icon={
                      <StyledVisibility
                        onClick={handleVisibilityToggle}
                        className="login__icon_right"
                      />
                    }
                    selectedLocale={ar}
                  />
                ) : (
                  <Icon
                    icon={
                      <StyledVisibilityOff
                        onClick={handleVisibilityToggle}
                        className="login__icon_right"
                      />
                    }
                    selectedLocale={ar}
                  />
                )}
              </InputWrapper>
              <InputWrapper>
                <Icon
                  icon={<StyledLockIcon className="login__icon" />}
                  selectedLocale={ar}
                />

                <Input
                  type={visibility2 ? "text" : "password"}
                  placeholder={t("confirm-password")}
                  name="password"
                  autoComplete="off"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {visibility2 ? (
                  <Icon
                    icon={
                      <StyledVisibility
                        onClick={handleVisibilityToggle2}
                        className="login__icon_right"
                      />
                    }
                    selectedLocale={ar}
                  />
                ) : (
                  <Icon
                    icon={
                      <StyledVisibilityOff
                        onClick={handleVisibilityToggle2}
                        className="login__icon_right"
                      />
                    }
                    selectedLocale={ar}
                  />
                )}
              </InputWrapper>
            </Form>
          </div>

          {!loading ? (
            <ButtonContainer>
              <SubmitButton
                onClick={() => {
                  handleSubmit();
                }}
              >
                {t("set-up-new-password")}
              </SubmitButton>
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <SubmitButton disabled>
                <Loading height={30} />
              </SubmitButton>
            </ButtonContainer>
          )}
        </ScreenContent>
      </MainContainer>
      <Footer />
    </>
  );
};

export default SetUpNewPasswordPage;
