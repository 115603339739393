import { axiosRecipientAuthClient, axiosRecipientUserClient } from "..";
import { handleError } from "../../utils/errors";
import { LOCAL_STORAGE_RECIPIENT_REFRESH_TOKEN } from "../../utils/constants";

const BASE_URL = "/auth";

class AuthService {
  signUp = async ({ email, password, fullName, inviteToken }) => {
    try {
      const body = {
        email,
        password,
        fullName,
      };
      const response = await axiosRecipientAuthClient.post(
        `business${BASE_URL}/sign-up-employee?inviteToken=${inviteToken}`,
        body,
        {
          withCredentials: true,
        }
      );
      if (response.status !== 201) {
        throw new Error();
      }
      const { accessToken, refreshToken, businessUser } = response.data.data;
      return { accessToken, refreshToken, businessUser };
    } catch (err) {
      throw handleError(err, "Error occurred while signing up new user");
    }
  };

  loginWithUsername = async ({ username, password }) => {
    try {
      const response = await axiosRecipientAuthClient.post(
        `${BASE_URL}/login`,
        { username, password }
      );
      if (response.status !== 201) {
        throw new Error();
      }
      const { accessToken, refreshToken, user } = response.data.data;
      return { accessToken, refreshToken, user };
    } catch (err) {
      throw handleError(err, "Error occurred while logging in");
    }
  };

  logout = async () => {
    try {
      const refreshToken = localStorage.getItem(
        LOCAL_STORAGE_RECIPIENT_REFRESH_TOKEN
      );
      const response = await axiosRecipientAuthClient.post(
        `${BASE_URL}/logout`,
        {
          refreshToken,
        }
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return;
    } catch (err) {
      throw handleError(err, "Error occurred while logging out");
    }
  };

  verifyUser = async ({ username, code, phone, password }) => {
    try {
      const response = await axiosRecipientAuthClient.post(
        `${BASE_URL}/verify/phone`,
        {
          username,
          code,
          phone,
          password,
        }
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { accessToken, refreshToken, user } = response.data.data;
      return { accessToken, refreshToken, user };
    } catch (err) {
      throw handleError(err, "Error occurred while verifying user");
    }
  };

  postUsersInfo = async (userInfo) => {
    try {
      const response = await axiosRecipientUserClient.post(`/users/info`, {
        ...userInfo,
        wishlistPurposes: [1],
        wishlistVisibility: "private",
        friendsVisibility: "private",
        locationVisibility: "private",
        locationLat: 0,
        locationLng: 0,
      });
      if (response.status !== 201) {
        throw new Error();
      }
      const { user } = response.data.data;
      return user;
    } catch (err) {
      throw handleError(err, "Error occurred while posting user's info");
    }
  };

  fetchUserInfo = async () => {
    try {
      const response = await axiosRecipientUserClient.get(`/users/me`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { user, linkedAccounts, receivedGifts } = response.data.data;
      return { user, linkedAccounts, receivedGifts };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching user's info.");
    }
  };
}

const authRecipientService = new AuthService();

export default authRecipientService;
