import { createSlice } from "@reduxjs/toolkit";
import { removeBackgroundFromLogoBuilder } from "./builder";

const initialState = {
  logo: "",
  processLogoBackgroundPending: false,
};

const photoProcessingSlice = createSlice({
  name: "photoProcessingInfo",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    removeBackgroundFromLogoBuilder(builder);
  },
});

export const {} = photoProcessingSlice.actions;

export const selectLogo = (state) => state.photoProcessingInfo.logo;
export const selectProcessLogoBackgroundPending = (state) =>
  state.photoProcessingInfo.processLogoBackgroundPending;

export default photoProcessingSlice.reducer;
