import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import LoginPage from "./screens/OnboardingPages/LoginPage/LoginPage";
import ForgotPasswordPage from "./screens/OnboardingPages/ForgotPasswordPage/ForgotPasswordPage";
import ChooseGiftPage from "./screens/RecipientPortal/ChooseGiftPage/ChooseGiftPage";
import SignUpPage from "./screens/OnboardingPages/SignupPage/SignupPage";
import HomePage from "./screens/HomePage/HomePage";
import AdminDashboardPage from "./screens/AdminPortal/AdminDashboardPage/AdminDashboardPage";
import CouponsPage from "./screens/AdminPortal/CouponsPage/CouponsPage";
import SubscriptionPage from "./screens/AdminPortal/SubscriptionPage/SubscriptionPage";
import CartPage from "./screens/AdminPortal/CartPage/CartPage";
import GiftsPage from "./screens/AdminPortal/GiftsPage/GiftsPage";
import AllCategoryItemsPage from "./screens/AdminPortal/AllCategoryItemsPage/AllCategoryItemsPage";
import AllSectionItemsPage from "./screens/AdminPortal/AllSectionItemsPage/AllSectionItemsPage";
import ItemDetailPage from "./screens/AdminPortal/ItemDetailPage/ItemDetailPage";
import RecipientsPage from "./screens/AdminPortal/RecipientsPage/RecipientsPage";
import OrderHistoryPage from "./screens/AdminPortal/OrderHistoryPage/OrderHistoryPage";
import BillingPage from "./screens/AdminPortal/BillingPage/BillingPage";
import AddItem from "./screens/AdminPortal/MarketplacePage/AddItem/AddItem";
import CategoriesPage from "./screens/AdminPortal/MarketplacePage/Categories/CategoriesPage";
import AttributesPage from "./screens/AdminPortal/MarketplacePage/Attributes/AttributesPage";
import AccountSettingsPage from "./screens/AdminPortal/AccountSettingsPage/AccountSettingsPage";
import SendGiftsPage from "./screens/AdminPortal/SendGiftsPage/SendGiftsPage";
import RecipientDashboardPage from "./screens/RecipientPortal/DashboardPage/RecipientDashboardPage";
import MobileAppPage from "./screens/MobileAppPage/MobileAppPage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles/responsive.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { fetchBusinessUserInfo } from "./store/slices/businessUser/asyncThunks";
import GiftOrderDetailsPage from "./screens/AdminPortal/GiftOrderDetailsPage/GiftOrderDetailsPage";
import MarketplaceOrderDetailsPage from "./screens/AdminPortal/MarketplaceOrderDetailsPage/MarketplaceOrderDetailsPage";
import { selectLoggedIn } from "./store/slices/authRecipient/slice";
import {
  isBusinessUserLoggedIn,
  selectBusinessUserInfo,
  setLoggedIn,
} from "./store/slices/businessUser/slice";
import AdminPricingPage from "./screens/AdminPortal/PricingPage/AdminPricingPage";
import BusinessUserLayout from "./layout/BusinessUserLayout";
import UserLayout from "./layout/UserLayout";
import Splash from "./components/Splash/Splash";
import BusinessOrderHistoryPage from "./screens/AdminPortal/BusinessOrderHistoryPage/BusinessOrderHistoryPage";
import CreateEditCoupon from "./screens/AdminPortal/CouponsPage/CreateEditCoupon/CreateEditCoupon";
import { refresh } from "./store/slices/auth/asyncThunks";
import MarketplacePage from "./screens/AdminPortal/MarketplacePage/MarketplacePage";
import FavoritePage from "./screens/AdminPortal/MarketplacePage/FavoritePage";
import MarketplaceItemDetailPage from "./screens/AdminPortal/MarketplacePage/MarketplaceItemDetailPage/MarketplaceItemDetailPage";
import SetUpNewPasswordPage from "./screens/OnboardingPages/SetUpNewPasswordPage/SetUpNewPasswordPage";
import StepBilling from "./screens/OnboardingPages/SignupPage/SignupSteps/StepBilling";
import SignupFinal from "./screens/OnboardingPages/SignupPage/SignupSteps/CompletedSignup";
import DashboardPage from "./screens/AdminPortal/DashboardPage/Dashboard";
import BankTransfersPage from "./screens/AdminPortal/SubscriptionPage/BankTransfersPage";
import CheckoutFinalPage from "./screens/AdminPortal/MarketplacePage/CheckoutFinalPage";
import DeliveryDetailsPage from "./screens/AdminPortal/MarketplaceOrderDeliveryDetails/DeliveryDetailsPage";
import DeleteAccountPage from "./screens/DeleteAccount/DeleteAccountPage";
import VerificationSuccessPage from "./screens/OnboardingPages/VerificationSuccessPage/VerificationSuccessPage";
import { getBusinessRulesVariables } from "./store/slices/common/asyncThunks";
import {
  refreshCart,
  selectedMarketplaceItem,
} from "./store/slices/marketplace/marketplaceSlice";
import WalletCheckoutPage from "./screens/AdminPortal/WalletCheckoutPage/WalletCheckoutPage";
import WalletCheckoutFinalPage from "./screens/AdminPortal/WalletCheckoutPage/WalletCheckoutFinalPage";
import { Helmet } from "react-helmet-async";
import BusinessSubscriptionsPage from "./screens/AdminPortal/BusinessSubscriptionsPage/BusinessSubscriptionsPage";
import { seoData, seoDefaultData } from "./utils/seo";
import {
  Close,
  CloseContainer,
  IconContainer,
  LogoIcon,
  StyledToastContainer,
} from "./App.styles";
import { selectedLocale } from "./store/slices/common/slice";
import { ReactComponent as SuccessIcon } from "./assets/icons/success.svg";
import { ReactComponent as ErrorIcon } from "./assets/icons/error.svg";
import { ReactComponent as InfoIcon } from "./assets/icons/info-icon.svg";
import { ReactComponent as WarningIcon } from "./assets/icons/warning.svg";
import PricingPage from "./screens/PricingPage/PricingPage";
import SearchPage from "./screens/AdminPortal/SearchPage/SearchPage";
import CompanySubscriptionHistoryPage from "./screens/AdminPortal/SubscriptionPage/CompanySubscriptionHistoryPage";
import PGMPages from "./screens/PGMPages/PGMPages";
import GamePage from "./screens/GamePage/GamePage";
import AboutUsPage from "./screens/AboutUsPage/AboutUsPage";
import PrivacyPolicyPage from "./screens/PrivacyPolicyPage/PrivacyPolicyPage";
import MobileAppItemDetailPage from "./screens/MobileAppPage/MobileAppItemDetailPage";
import MobileAppCheckout from "./screens/MobileAppPage/MobileAppCheckout";
import MobileAppCheckoutFinal from "./screens/MobileAppPage/MobileAppCheckoutFinal";
import { LOCAL_STORAGE_CART_ITEMS } from "./utils/constants";
import { FRONT_URL } from "./api";
import { checkEnvironment } from "./utils/utils";
import PackagingPage from "./screens/PackagingPage/PackagingPage";

function App() {
  const dispatch = useDispatch();
  const businessUser = useSelector(selectBusinessUserInfo);
  const [minimumDurationPassed, setMinimumDurationPassed] = useState(false);
  const [isInitialFetchCompleted, setIsInitialFetchCompleted] = useState(false);
  const loggedInUser = useSelector(selectLoggedIn);
  const loggedInBusinessUser = useSelector(isBusinessUserLoggedIn);
  const locale = useSelector(selectedLocale);
  const location = useLocation();
  const marketplaceItem = useSelector(selectedMarketplaceItem);
  const ar = locale === "ar";

  useEffect(() => {
    const timer = setTimeout(() => setMinimumDurationPassed(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const cartItems = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_CART_ITEMS)
    );
    const currentEnvironment = checkEnvironment();

    if (
      cartItems &&
      Object.keys(cartItems).length !== 0 &&
      JSON.parse(cartItems.cartItems).length &&
      currentEnvironment === cartItems.environment
    ) {
      dispatch(refreshCart({ cartItems: JSON.parse(cartItems.cartItems) }));
    }
  }, []);

  useEffect(() => {
    dispatch(refresh())
      .unwrap()
      .then(() => {
        dispatch(fetchBusinessUserInfo())
          .unwrap()
          .then(() => {
            setIsInitialFetchCompleted(true);
          })
          .catch((err) => {
            setIsInitialFetchCompleted(true);
          });
        dispatch(getBusinessRulesVariables());
      })
      .catch(() => {
        dispatch(setLoggedIn({ loggedIn: false }));
        setIsInitialFetchCompleted(true);
      });
  }, [dispatch]);

  //   useEffect(() => {
  //     const token = localStorage.getItem(LOCAL_STORAGE_RECIPIENT_ACCESS_TOKEN);
  //     const refreshToken = localStorage.getItem(
  //       LOCAL_STORAGE_RECIPIENT_REFRESH_TOKEN
  //     );
  //     if (token || refreshToken) {
  //       dispatch(fetchUserInfo());
  //     } else {
  //       dispatch(setLoggedIn({ loggedIn: false }));
  //     }
  //   }, [dispatch]);

  const fullPath = window.location.href;

  const isMarketplaceItemPage = /\/marketplace\/\d+$/.test(
    window.location.href
  );

  const seoPageData = seoData[fullPath.replace(/\/(en|ar)\//, "/")] ?? {};
  document.documentElement.lang = locale;
  useEffect(() => {
    if (locale === "ar") {
      document.body.style.direction = "rtl";
    } else {
      document.body.style.direction = "ltr";
    }
  }, [locale]);

  return (
    <div className="App">
      <Helmet>
        {isMarketplaceItemPage ? (
          <>
            <title>Ygii Marketplace</title>
            <meta
              name="description"
              content={
                locale === "ar"
                  ? "ارفع من شأن الفعاليات مع الهدايا الترويجية القابلة للتخصيص من يجي. أضف المرح والتخصيص لاحتفالاتك."
                  : "Elevate events with Ygii's customizable giveaways. Add fun and personalization to your celebrations."
              }
            />
            <meta
              property="og:title"
              content={marketplaceItem.name ?? "Item"}
            />
            <meta
              property="og:description"
              content={marketplaceItem.description ?? "Description"}
            />
            <meta
              property="og:url"
              content={fullPath.replace(/\/(en|ar)\//, "/")}
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:image"
              content={
                marketplaceItem?.photos?.length
                  ? marketplaceItem?.photos[0].url
                  : ""
              }
            />
          </>
        ) : seoPageData ? (
          <>
            <title>
              {seoPageData?.title
                ? locale === "ar"
                  ? seoPageData.title.ar
                  : seoPageData.title.en
                : locale === "ar"
                ? seoDefaultData.title.ar
                : seoDefaultData.title.en}
            </title>
            <meta
              name="description"
              content={
                seoPageData?.description
                  ? locale === "ar"
                    ? seoPageData.description.ar
                    : seoPageData.description.en
                  : locale === "ar"
                  ? seoDefaultData.description.ar
                  : seoDefaultData.description.en
              }
            />
            <meta
              property="og:title"
              content={
                seoPageData?.title
                  ? locale === "ar"
                    ? seoPageData.title.ar
                    : seoPageData.title.en
                  : locale === "ar"
                  ? seoDefaultData.title.ar
                  : seoDefaultData.title.en
              }
            />
            <meta
              property="og:description"
              content={
                seoPageData?.description
                  ? locale === "ar"
                    ? seoPageData.description.ar
                    : seoPageData.description.en
                  : locale === "ar"
                  ? seoDefaultData.description.ar
                  : seoDefaultData.description.en
              }
            />
            <meta
              property="og:url"
              content={fullPath.replace(/\/(en|ar)\//, "/")}
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:image"
              content={seoPageData?.image ?? seoDefaultData.image}
            />
          </>
        ) : (
          <>
            <title>
              {locale === "ar"
                ? seoDefaultData.title.ar
                : seoDefaultData.title.en}
            </title>
            <meta
              name="description"
              content={
                locale === "ar"
                  ? seoDefaultData.description.ar
                  : seoDefaultData.description.en
              }
            />
            <meta
              property="og:title"
              content={
                locale === "ar"
                  ? seoDefaultData.title.ar
                  : seoDefaultData.title.ar
              }
            />
            <meta
              property="og:description"
              content={
                locale === "ar"
                  ? seoDefaultData.description.ar
                  : seoDefaultData.description.en
              }
            />
            <meta
              property="og:url"
              content={fullPath.replace(/\/(en|ar)\//, "/")}
            />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={seoDefaultData.image} />
          </>
        )}
      </Helmet>
      {(minimumDurationPassed && isInitialFetchCompleted) ||
      location.pathname.includes("mobile-app") ? (
        <Routes>
          <Route path="/mobile-app" element={<MobileAppPage />} />
          <Route
            path="/mobile-app/item-detail/:id"
            element={<MobileAppItemDetailPage />}
          />
          <Route
            path="/mobile-app/checkout/:id"
            element={<MobileAppCheckout />}
          />
          <Route
            path="/mobile-app/checkout/final"
            element={<MobileAppCheckoutFinal />}
          />

          <Route path="/pgm/:token" element={<PGMPages />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/sign-up/steps/:token" element={<StepBilling />} />
          <Route path="/sign-up/final" element={<SignupFinal />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route
            path="/reset-password/:token"
            element={<SetUpNewPasswordPage />}
          />
          <Route path="/" element={<HomePage />} />
          <Route path="/game" element={<GamePage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          {/* <Route path="/blog" element={<BlogPage />} /> */}
          {/* <Route path="/contact" element={<ContactPage />} /> */}
          {/* <Route path="/how-it-work" element={<HowItWorkPage />} /> */}
          <Route path="/marketplace" element={<MarketplacePage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route
            path="/marketplace/:itemId"
            element={<MarketplaceItemDetailPage />}
          />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/checkout/final" element={<CheckoutFinalPage />} />
          <Route path="/delivery-details" element={<DeliveryDetailsPage />} />
          <Route
            path="/marketplace/:itemId"
            element={<MarketplaceItemDetailPage />}
          />
          <Route path="/packaging" element={<PackagingPage />} />
          <Route path="/choose-gift/:token" element={<ChooseGiftPage />} />
          <Route path="/delete-account" element={<DeleteAccountPage />} />
          <Route
            path="/verification-success/:token"
            element={<VerificationSuccessPage />}
          />

          {loggedInBusinessUser !== false &&
            minimumDurationPassed &&
            isInitialFetchCompleted && (
              <>
                <Route element={<BusinessUserLayout />}>
                  <Route
                    path="/admin-portal/select-gifts"
                    element={<GiftsPage />}
                  />
                  <Route
                    path="/admin-portal/dashboard"
                    element={
                      businessUser.role === "superAdmin" ? (
                        <AdminDashboardPage />
                      ) : (
                        <DashboardPage />
                      )
                    }
                  />
                  <Route
                    path="/admin-portal/marketplace"
                    element={<MarketplacePage />}
                  />
                  <Route
                    path="/admin-portal/favorite"
                    element={<FavoritePage />}
                  />
                  <Route
                    path="/admin-portal/marketplace/item-detail/:itemId"
                    element={<MarketplaceItemDetailPage />}
                  />
                  <Route path="/admin-portal/cart" element={<CartPage />} />
                  <Route
                    path="/admin-portal/packaging"
                    element={<PackagingPage />}
                  />
                  <Route
                    path="/admin-portal/checkout/final"
                    element={<CheckoutFinalPage />}
                  />
                  <Route
                    path="/admin-portal/wallet/checkout"
                    element={<WalletCheckoutPage />}
                  />
                  <Route
                    path="/admin-portal/wallet/final"
                    element={<WalletCheckoutFinalPage />}
                  />
                  <Route
                    path="/admin-portal/category-items/:categoryId"
                    element={<AllCategoryItemsPage />}
                  />
                  <Route
                    path="/admin-portal/section-items/:sectionId"
                    element={<AllSectionItemsPage />}
                  />
                  <Route
                    path="/admin-portal/item-detail/:itemId"
                    element={<ItemDetailPage />}
                  />
                  <Route
                    path="/admin-portal/recipients"
                    element={<RecipientsPage />}
                  />
                  <Route
                    path="/admin-portal/order-history"
                    element={
                      businessUser.role === "superAdmin" ? (
                        <OrderHistoryPage />
                      ) : (
                        <BusinessOrderHistoryPage />
                      )
                    }
                  />
                  <Route
                    path="/admin-portal/billing"
                    element={<BillingPage />}
                  />
                  <Route
                    path="/admin-portal/account-settings"
                    element={<AccountSettingsPage />}
                  />
                  <Route
                    path="/admin-portal/send-gifts/:step"
                    element={<SendGiftsPage />}
                  />
                  <Route
                    path="/admin-portal/order-history/gift-order-details/:orderId"
                    element={<GiftOrderDetailsPage />}
                  />
                  <Route
                    path="/admin-portal/order-history/marketplace-order-details/:orderId"
                    element={<MarketplaceOrderDetailsPage />}
                  />
                  <Route
                    path="/admin-portal/pricing"
                    element={<AdminPricingPage />}
                  />
                  <Route
                    path="/admin-portal/marketplace/add-an-item/:action"
                    element={<AddItem />}
                  />
                  <Route
                    path="/admin-portal/marketplace/categories"
                    element={<CategoriesPage />}
                  />
                  <Route
                    path="/admin-portal/marketplace/attributes"
                    element={<AttributesPage />}
                  />

                  <Route
                    path="/admin-portal/coupons"
                    element={<CouponsPage />}
                  />
                  <Route
                    path="/admin-portal/coupons/new-coupon"
                    element={<CreateEditCoupon />}
                  />
                  <Route
                    path="/admin-portal/coupons/edit-coupon/:couponId"
                    element={<CreateEditCoupon />}
                  />
                  <Route
                    path="/admin-portal/subscriptions"
                    element={<SubscriptionPage />}
                  />
                  <Route
                    path="/admin-portal/subscriptions/company-history/:companyId"
                    element={<CompanySubscriptionHistoryPage />}
                  />
                  <Route
                    path="/admin-portal/business-subscriptions"
                    element={<BusinessSubscriptionsPage />}
                  />
                  <Route
                    path="/admin-portal/subscriptions/bank-transfers"
                    element={<BankTransfersPage />}
                  />
                  <Route
                    path="/admin-portal/delivery-details"
                    element={<DeliveryDetailsPage />}
                  />
                  <Route path="/admin-portal/search" element={<SearchPage />} />
                </Route>
              </>
            )}

          {loggedInUser !== null &&
            minimumDurationPassed &&
            isInitialFetchCompleted && (
              <>
                <Route element={<UserLayout />}>
                  <Route
                    path="/user-portal/recipient/:tab"
                    element={<RecipientDashboardPage />}
                  />
                </Route>
              </>
            )}

          <Route path="*" element={<LoginPage />} />
        </Routes>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
          }}
        >
          <Splash />
        </div>
      )}

      <StyledToastContainer
        // position={locale !== "en" ? "top-left" : "top-right"}
        position={"top-right"}
        autoClose={6000}
        hideProgressBar={false}
        rtl={locale !== "en"}
        newestOnTop
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={({ closeToast }) => (
          <CloseContainer>
            <Close $ar={ar} />
            <LogoIcon onClick={closeToast} />
          </CloseContainer>
        )}
        icon={({ closeToast, type }) => (
          <IconContainer type={type} $ar={ar}>
            {type === "success" && <SuccessIcon onClick={closeToast} />}
            {type === "error" && <ErrorIcon onClick={closeToast} />}
            {type === "warning" && <WarningIcon onClick={closeToast} />}
            {type === "info" && <InfoIcon onClick={closeToast} />}
          </IconContainer>
        )}
      />
    </div>
  );
}

export default App;
