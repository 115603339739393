import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import {
  Container,
  Screen,
  ScreenContent,
  MainContainer,
  Title,
  Gap,
  HeaderWrapper,
  Subtitle,
  TitleWrapper,
  FeaturesTitles,
  Feature,
  FeatureTitle,
  FeaturesContainer,
  Card,
  Name,
  Value,
  Image,
  Wrapper,
  StyledContainer,
} from "./PricingPage.styles";

import { selectCreateAccountPending } from "../../store/slices/auth/slice";
import Loader from "../../components/Loader/Loader";
import { ReactComponent as Yes } from "../../assets/icons/green.svg";
import No from "../../assets/images/failed.png";
import PricePlan from "../../components/PricePlan/PricePlan";
import { selectedLocale } from "../../store/slices/common/slice";

const PricingPage = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const createAccountPending = useSelector(selectCreateAccountPending);
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";

  /* SCROLL ANIMATION */

  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isIntersecting3, setIsIntersecting3] = useState(false);

  const ref = useRef(null);
  const ref3 = useRef(null);

  const handleIntersection = (setIntersecting, ref) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      { rootMargin: "-300px" }
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  };

  useEffect(() => handleIntersection(setIsIntersecting, ref, "slide-in"), []);
  useEffect(() => handleIntersection(setIsIntersecting3, ref3, "slide-in"), []);

  useEffect(() => {
    const handleClassToggle = (isIntersecting, ref, className) => {
      ref.current.querySelectorAll("span").forEach((el) => {
        if (isIntersecting) {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      });
    };

    const handleClassToggleDiv = (isIntersecting, ref, className) => {
      ref.current.querySelectorAll("div").forEach((el) => {
        if (isIntersecting) {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      });
    };

    handleClassToggle(isIntersecting, ref, "slide-in");

    handleClassToggleDiv(isIntersecting3, ref3, "slide-in");
  }, [isIntersecting, isIntersecting3]);

  const [headerVisible, setHeaderVisible] = useState(true);
  const [lastScrollUpY, setLastScrollUpY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(null);
  const lastScrollTopRef = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      const lastScrollTop = lastScrollTopRef.current;

      if (currentScrollTop === 0) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
        setScrollDirection(null);
        lastScrollTopRef.current = currentScrollTop;
        return;
      }

      if (currentScrollTop > lastScrollTop) {
        setScrollDirection("down");
        setLastScrollUpY(currentScrollTop);
      } else {
        setScrollDirection("up");
      }

      if (scrollDirection === "up" && currentScrollTop < lastScrollUpY - 350) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
      }

      if (
        scrollDirection === "down" &&
        currentScrollTop > lastScrollUpY + 350
      ) {
        setHeaderVisible(false);
      }

      lastScrollTopRef.current = currentScrollTop;
    };

    const handleScrollDebounced = () => {
      requestAnimationFrame(handleScroll);
    };

    window.addEventListener("scroll", handleScrollDebounced);

    return () => {
      window.removeEventListener("scroll", handleScrollDebounced);
    };
  }, [scrollDirection]);

  return (
    <>
      {createAccountPending && <Loader disableScroll />}

      <HeaderWrapper
        style={{
          opacity: headerVisible ? 1 : 0,
          transition: "opacity 0.5s",
        }}
      >
        <Header
          showLogin
          showNav
          color
          showUser
          hideSearch
          showSignUp
          display={headerVisible}
        />
      </HeaderWrapper>

      <MainContainer>
        <Container>
          <Screen>
            <ScreenContent ref={ref}>
              <Title>{t("choose-the-plan")}</Title>

              <Gap>
                <PricePlan
                  animated
                  startFree={() => {
                    navigation("/sign-up");
                  }}
                  startPremium={() => {
                    navigation("/sign-up");
                  }}
                  startEnterprise={() => {
                    navigation("/sign-up");
                  }}
                />
              </Gap>
            </ScreenContent>
          </Screen>
        </Container>
        <StyledContainer ref={ref3}>
          <TitleWrapper>
            <Subtitle>{t("ultimate-gifting-platform")}</Subtitle>
            <Title>
              {t("innovative-features-to-transform-your-gifting-experience")}
            </Title>
          </TitleWrapper>
          <FeaturesContainer>
            <FeaturesTitles>
              <Feature>{t("features")}</Feature>
              <FeatureTitle>{t("recipients2")}</FeatureTitle>
              <FeatureTitle>{t("company-users")}</FeatureTitle>
              <FeatureTitle>{t("delivery-fees")}</FeatureTitle>
              <FeatureTitle>{t("procurement-discounts")}</FeatureTitle>
              <FeatureTitle>{t("branded-gifts")}</FeatureTitle>
              <FeatureTitle>{t("account-manager")}</FeatureTitle>
            </FeaturesTitles>
            <Card>
              <Name>{t("free")}</Name>
              <Value>100</Value>
              <Value>1</Value>
              <Value>35 SAR</Value>
              <Wrapper>
                <Image src={No} />
              </Wrapper>
              <Wrapper>
                <Yes height={42} width={42} />
              </Wrapper>
              <Wrapper>
                <Image src={No} />
              </Wrapper>
            </Card>
            <Card>
              <Name>{t("premium")}</Name>
              <Value>{t("unlimited")}</Value>
              <Value>{t("unlimited")}</Value>
              <Value>{t("free2")}</Value>
              <Value>{ar ? t("up-to-5") + " %5" : t("up-to-5")}</Value>
              <Wrapper>
                <Yes height={42} width={42} />
              </Wrapper>
              <Wrapper>
                <Image src={No} />
              </Wrapper>
            </Card>
            <Card>
              <Name>{t("platinum")}</Name>
              <Value>{t("unlimited")}</Value>
              <Value>{t("unlimited")}</Value>
              <Value>{t("free2")}</Value>
              <Value>{ar ? t("up-to-20") + " %20" : t("up-to-20")}</Value>
              <Wrapper>
                <Yes height={42} width={42} />
              </Wrapper>
              <Wrapper>
                <Yes height={42} width={42} />
              </Wrapper>
            </Card>
          </FeaturesContainer>
        </StyledContainer>
      </MainContainer>
      <Footer />
    </>
  );
};

export default PricingPage;
