import React from "react";

import Lottie from "lottie-react";
import animationData from "../../assets/animations/animation.json";
import { Loading, LoadingSize } from "./MiniLoader.styles";

const MiniLoader = ({ leftShift, small, style }) => {
  return (
    <Loading>
      <LoadingSize $leftshift={leftShift} $small={small} style={style}>
        <Lottie animationData={animationData} autoplay loop />
      </LoadingSize>
    </Loading>
  );
};

export default MiniLoader;
