import styled from "styled-components";

export const ModalStyle = styled.div`
  width: ${(props) => (props.$big ? "fit-content" : "310px")};
  height: ${(props) => (props.$big ? "fit-content" : "110px")};
  display: flex;
  padding: ${(props) => (props.$big ? "20px" : "0px")};
  outline: none;
  flex-direction: column;
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.2);
  background: white;
  border-radius: 5px;
`;

export const OverlayStyle = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3500;
  background: rgba(0, 0, 0, 0.6);
`;
