import styled, { keyframes } from "styled-components";
import { ReactComponent as LockIcon } from "../../assets/icons/drawer/lock.svg";
import { ReactComponent as VisibilityOff } from "../../assets/icons/visibility_off.svg";
import { ReactComponent as Visibility } from "../../assets/icons/visibility.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/drawer/mail.svg";

export const Title = styled.span`
  font-family: "FrankfurterEF-Medium";
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  @media (max-width: 600px) {
    font-size: 2rem;
  }
`;

export const Error = styled.span`
  font-family: "Avenir-Medium";
  font-size: 16px;
  letter-spacing: -0.41;
  position: absolute;
  color: #ea435d;
  bottom: -10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const CloseContainer = styled.div`
  border-radius: 1rem;
  background: #8a4b5e;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  width: 5.5rem;
  height: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1rem;
  left: -1rem;
  @media (max-width: 870px) {
    top: 8rem;
    right: 1rem;
    left: unset;
  }
`;

export const StyledCloseContainer = styled(CloseContainer)`
  right: -2.5rem;
  left: unset;
  top: -2.5rem;
`;
export const Subtitle = styled.span`
  font-family: "Avenir-Medium";
  font-size: 2.5rem;

  line-height: 3.2rem; /* 128% */
  margin-top: 5rem;
`;
export const StyledSubtitle = styled.span`
  font-family: "Avenir-Medium";
  font-size: 2.5rem;
  line-height: 3.2rem; /* 128% */
`;

export const Description = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-family: "Avenir-Medium";
  font-size: 2.5rem;
  line-height: 3.2rem; /* 128% */
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
`;

export const PriceBreaksContainer = styled.div`
  background: rgba(72, 72, 136, 0.1);
  width: 56.7rem;
  margin-left: -6rem;
  padding: 2rem;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  height: fit-content;
`;

export const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 5px;
`;
export const Text = styled.span`
  font-family: "Avenir-Medium";
  font-size: 1.6rem;
  line-height: 2.2rem;
`;
export const RightText = styled(Text)`
  position: absolute;
  right: 6rem;
`;
export const StyledRightText = styled(Text)`
  position: absolute;
  color: red;
  right: 6rem;
`;
export const LeftText = styled(Text)`
  left: 6rem;
  position: absolute;
`;
export const Price = styled.span`
  font-family: "Avenir-Medium";
  font-size: 2.2rem;
  line-height: 2.5rem; /* 160% */
`;
export const Button = styled.button`
  border-radius: 1rem;
  z-index: 5;
  background: #8a4b5e;
  width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 3.5px;

  height: 5rem;
  color: #fff;
  font-family: "FrankfurterEF-Medium";
  font-size: 2.2rem;
  line-height: 2.5rem; /* 106.667% */
  margin-top: 1rem;
  margin-bottom: 2rem;

  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
  }
`;

export const GenerateButton = styled.button`
  border-radius: 1rem;
  z-index: 5;
  width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 3.5px;
  height: 5rem;
  color: #fff;
  font-family: "FrankfurterEF-Medium";
  font-size: 2.2rem;
  line-height: 2.5rem; /* 106.667% */
  margin-top: 1rem;
  margin-bottom: 2rem;

  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
  }
  background: ${(props) => (props.disabled ? "grey" : "#8A4B5E")};

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  /* &:hover {
    transform: ${(props) => (props.disabled ? "scale(1)" : "scale(1.05)")};

    background: ${(props) => (props.disabled ? "grey" : "#8A4B5E")};
  } */
`;

export const StyledButton = styled.button`
  border-radius: 5px;
  z-index: 5;
  background: #8a4b5e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 3.5px;
`;

// export const Input = styled.input`
//   border-bottom: 1px solid rgba(0, 0, 0, 0.4);
//   border-left: none;
//   border-top: none;
//   border-right: none;
//   width: 100%;
//   height: 55px;

//   font-size: 20px;
//   font-family: "Avenir-Medium";
// `;

export const Gap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
export const StyledPrice = styled.input`
  width: 6rem;
  background-color: #f4f1f1;
  display: flex;
  align-items: center;
  color: #000;
  text-align: center;
  font-family: "Avenir-Medium";
  font-size: 2rem;

  line-height: 3.2rem;
  padding: 2px 0px;
  justify-content: center;
`;
export const StyledText = styled.span`
  color: rgba(0, 0, 0, 0.7);
  font-family: "Avenir-Medium";
  text-align: center;
  font-size: 2rem;
  line-height: 3.2rem; /* 160% */
`;
export const StyledText2 = styled.span`
  line-height: 3.2rem; /* 160% */
  color: #55a1e8;
  font-family: "Avenir-Medium";
  margin-left: 0.5rem;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
`;
export const StyledMailIcon = styled(MailIcon)`
  width: 2.4rem;
  height: 2.4rem;
  left: 1rem;
  top: 1.75rem;
`;

export const StyledLockIcon = styled(LockIcon)`
  width: 2.4rem;
  height: 2.4rem;
  left: 1rem;
  top: 1.75rem;
`;

export const StyledVisibilityOff = styled(VisibilityOff)`
  width: 2.4rem;
  height: 2.4rem;
  top: 1.75rem;
  background-color: white;
  position: absolute;
  right: 2rem;
`;

export const StyledVisibility = styled(Visibility)`
  width: 2.4rem;
  height: 2.4rem;
  top: 1.75rem;
  background-color: white;
  position: absolute;
  right: 2rem;
`;
export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const Input = styled.input`
  border: 1px solid #8c8c8c;
  border-radius: 1.5rem;
  height: 6rem;
  user-select: none;
  background: none;
  width: 100%;
  font-weight: 700;
  transition: 0.2s;
  &:active,
  &:hover,
  &:focus {
    border: 1px solid #8a4b5e;
    box-shadow: none;
  }
  padding: 15px 15px 15px 38px;

  color: rgba(69, 69, 132, 1);
  font-family: "Avenir-Roman";
  font-size: 1.8rem;
  letter-spacing: -0.178px;
`;

export const ButtonYes = styled.button`
  border-radius: 0.5rem;
  z-index: 5;
  background: ${(props) => (props.$active ? "#8A4B5E" : "#F4F1F1")};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 2.1rem;
  color: ${(props) => (props.$active ? "#fff" : "#000")};
  font-family: "Avenir-Medium";
  font-size: 1.6rem;
`;

export const SmallTitle = styled(Title)`
  font-size: 2.2rem;
  margin-bottom: 0;
`;
export const BlankText = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-family: "Avenir-Roman";

  font-size: 1.5rem;
  line-height: 2rem; /* 128% */
`;

export const BlankPrice = styled.span`
  color: #ea435d;
  text-align: right;
  font-family: "FrankfurterEF-Medium";
  font-size: 2.2rem;
  line-height: 2.5rem; /* 123.077% */
`;

export const SampleColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 5px;
  max-height: 1000px;
  height: 1000px;
  gap: ${(props) => (props.$smallGap ? "2rem" : "12rem")};
`;
export const StyledInput = styled(Input)`
  height: 4rem;
  padding-left: 1.5rem;
  width: 60%;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Loading = styled.div`
  border: 2px solid #b3b3b3;
  border-left: 4px solid #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${spin} 1s linear infinite;
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 0;
  font-size: 4.2rem;
  text-align: center;
`;
