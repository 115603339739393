import { getUserInfoByIdUnauthorized } from "./asyncThunks";

export const getUserInfoByIdUnauthorizedBuilder = (builder) => {
  builder.addCase(getUserInfoByIdUnauthorized.pending, (state) => {
    state.getUserInfoByIdUnauthorizedLoading = true;
  });
  builder.addCase(getUserInfoByIdUnauthorized.fulfilled, (state, action) => {
    state.getUserInfoByIdUnauthorizedLoading = false;
    state.userInfo = action.payload;
  });
  builder.addCase(getUserInfoByIdUnauthorized.rejected, (state) => {
    state.getUserInfoByIdUnauthorizedLoading = false;
  });
};
