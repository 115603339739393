import { useTranslation } from "react-i18next";
import {
  Form,
  Input,
  LastNameInput,
  Row,
  SignupField,
  StyledCompanyIcon,
  StyledInput,
  StyledLockIcon,
  StyledMailIcon,
  StyledUserIcon,
  StyledVisibility,
  StyledVisibilityOff,
} from "./SignupPage.style";
import { isValidEmail, isValidPassword } from "../../../utils/utils";
import Icon from "../../../components/Icon";

const SignUpFormComponent = ({
  companyName,
  ar,
  setCompanyName,
  setFirstName,
  firstName,
  lastName,
  setLastName,
  checkValidation,
  email,
  setEmail,
  visibility,
  visibility2,
  confirmPassword,
  password,
  setPassword,
  setConfirmPassword,
  setVisibility,
  setVisibility2,
  inviteToken,
}) => {
  const { t } = useTranslation();

  const handleVisibilityToggle = () => {
    setVisibility(!visibility);
  };

  const handleVisibilityToggle2 = () => {
    setVisibility2(!visibility2);
  };
  return (
    <Form>
      {!inviteToken && (
        <SignupField>
          <Icon
            icon={<StyledCompanyIcon className="login__icon" />}
            selectedLocale={ar}
          />

          <Input
            $ar={ar}
            type="text"
            $error={checkValidation && !companyName}
            placeholder={t("company-name")}
            value={companyName}
            onChange={(e) => {
              const inputValue = e.target.value;
              const transformedValue =
                inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
              setCompanyName(transformedValue);
            }}
          />
        </SignupField>
      )}
      <Row>
        <SignupField>
          <Icon
            icon={<StyledUserIcon className="login__icon" />}
            selectedLocale={ar}
          />

          <StyledInput
            $ar={ar}
            $error={checkValidation && !firstName}
            type="text"
            placeholder={t("first-name")}
            value={firstName}
            onChange={(e) => {
              const inputValue = e.target.value;
              const transformedValue =
                inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
              setFirstName(transformedValue);
            }}
          />
        </SignupField>
        <SignupField>
          <LastNameInput
            type="text"
            $error={checkValidation && !lastName}
            placeholder={t("last-name")}
            value={lastName}
            onChange={(e) => {
              const inputValue = e.target.value;
              const transformedValue =
                inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
              setLastName(transformedValue);
            }}
          />
        </SignupField>
      </Row>

      <SignupField>
        <Icon
          icon={<StyledMailIcon className="login__icon" />}
          selectedLocale={ar}
        />

        <Input
          $ar={ar}
          $error={checkValidation && (!isValidEmail(email) || !email)}
          type="text"
          placeholder={t("email-address")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </SignupField>
      <SignupField>
        <Icon
          icon={<StyledLockIcon className="login__icon" />}
          selectedLocale={ar}
        />
        <Input
          $ar={ar}
          type={visibility ? "text" : "password"}
          placeholder={t("password")}
          $error={checkValidation && (!isValidPassword(password) || !password)}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {visibility ? (
          <Icon
            icon={
              <StyledVisibility
                className="login__icon_right"
                onClick={handleVisibilityToggle}
              />
            }
            selectedLocale={ar}
          />
        ) : (
          <Icon
            icon={
              <StyledVisibilityOff
                className="login__icon_right"
                onClick={handleVisibilityToggle}
              />
            }
            selectedLocale={ar}
          />
        )}
      </SignupField>
      <SignupField>
        <Icon
          icon={<StyledLockIcon className="login__icon" />}
          selectedLocale={ar}
        />
        <Input
          $ar={ar}
          type={visibility2 ? "text" : "password"}
          placeholder={t("confirm-password")}
          $error={
            checkValidation &&
            (!confirmPassword || password !== confirmPassword)
          }
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {visibility2 ? (
          <Icon
            icon={
              <StyledVisibility
                className="login__icon_right"
                onClick={handleVisibilityToggle2}
              />
            }
            selectedLocale={ar}
          />
        ) : (
          <Icon
            icon={
              <StyledVisibilityOff
                className="login__icon_right"
                onClick={handleVisibilityToggle2}
              />
            }
            selectedLocale={ar}
          />
        )}
      </SignupField>
    </Form>
  );
};

export default SignUpFormComponent;
