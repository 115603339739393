import React from "react";
import {
  ButtonDiv,
  ContentContainer,
  StyledButton,
  Title,
  TitleContainer,
  StyledAHref,
} from "./DownloadAppModal.styles";

import { useTranslation } from "react-i18next";
import ModalDownload from "../Modal/ModalDownload";
import { isAppleDevice } from "../../utils/utils";

const DownloadAppModal = ({ modalRef }) => {
  const { t } = useTranslation();

  return (
    <ModalDownload
      ref={modalRef}
      onBack={() => {
        modalRef.current.hide();
      }}
    >
      <ContentContainer>
        <TitleContainer>
          <Title>{t("to-take-actions")}</Title>
        </TitleContainer>
        <ButtonDiv>
          <StyledButton onClick={() => modalRef.current.hide()}>
            {t("cancel")}
          </StyledButton>
          <StyledAHref
            href={
              isAppleDevice()
                ? "https://apps.apple.com/vn/app/ygii-gifts-wishlists/id1609129551"
                : "https://play.google.com/store/apps/details?id=com.ygii&hl=en_US&pli=1"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("sure")}
          </StyledAHref>
        </ButtonDiv>
      </ContentContainer>
    </ModalDownload>
  );
};

export default DownloadAppModal;
