import { priceFormatter } from "../../utils/utils";
import {
  CardProduct,
  CardProductName,
  IconContainer,
  ProductNameContainer,
  ProductPrice,
  GiftCardImgContainer,
} from "./GiftItemCard.style";
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";
import { getCurrencySymbol } from "../../utils/constants";
import { useSelector } from "react-redux";
import { selectBusinessRulesVariables } from "../../store/slices/common/slice";

const GiftItemCard = ({ item, itemDetails, isSelected, addToItemCart }) => {
  const businessRulesVariables = useSelector(selectBusinessRulesVariables);

  return (
    <CardProduct>
      <IconContainer onClick={itemDetails}>
        <InfoIcon />
      </IconContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 24,
        }}
        onClick={() => addToItemCart(item)}
      >
        {item.productType === "like_card" ? (
          <GiftCardImgContainer>
            <img
              style={{
                marginTop: 20,
                alignSelf: "center",
                fitObject: "contain",
                overflow: "hidden",
                width: 180,
              }}
              src={item?.photoUrl ? item.photoUrl : item.photos[0]?.url}
              alt="item-img"
            />
          </GiftCardImgContainer>
        ) : (
          <img
            style={{
              borderRadius: 20,
              width: "80%",
              height: 172,
              marginTop: 20,
              alignSelf: "center",
            }}
            src={item?.photoUrl ? item.photoUrl : item.photos[0]?.url}
            alt="item-img"
          />
        )}

        <ProductNameContainer
          style={{
            background: !isSelected
              ? "#e8dbdf"
              : "linear-gradient(360deg, #a87283 -100%, #8A4B5E 100%)",
          }}
        >
          <CardProductName
            style={{
              color: !isSelected ? "rgba(138, 75, 94, .8)" : "#fff9",
            }}
          >
            {item.name}
          </CardProductName>
          <ProductPrice
            style={{
              color: !isSelected ? "rgba(138, 75, 94, 1)" : "#fff",
            }}
          >
            {`${priceFormatter(
              item.price,
              true,
              businessRulesVariables.itemPurchaseVAT
            )} ${getCurrencySymbol(item.currency)}`}
          </ProductPrice>
        </ProductNameContainer>
      </div>
    </CardProduct>
  );
};

export default GiftItemCard;
