import styled from "styled-components";

export const Category = styled.h3`
  //   border-radius: 35px;
  //   background: #f3edef;
  //   width: 164px;
  //   height: 48px;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CategoryName = styled.span`
  border-radius: 35px;
  background: #f3edef;
  width: 164px;
  height: 48px;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: center;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.178px;
`;

export const CategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
`;

export const Subtitle = styled.span`
  font-size: 16px;
  color: #8a4b5e;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.178px;
  text-align: center;
`;

export const Subtitle2 = styled.span`
  font-size: 16px;
  color: #8a4b5e;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.178px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 64px;
  gap: 20px;
  @media (max-width: 1650px) {
    flex-direction: column;
    margin-left: 0px;
    align-items: center;
  }
`;

export const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 64px;
  gap: 20px;
  @media (max-width: 1650px) {
    gap: 20px;
    flex-direction: column;
    align-items: center;
  }
`;

export const Gap = styled.div`
  padding-right: 10px;
`;

export const SelectedSubcategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  @media (max-width: 1650px) {
    gap: 20px;
    flex-direction: column;
  }
`;

export const VariableContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Step2Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 20px;
  @media (max-width: 1650px) {
    flex-direction: column;
    margin-left: 0px;
  }
`;

export const AttributesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
`;

export const AddCategoryContainer = styled.div``;
export const Row3 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  @media (max-width: 1650px) {
    flex-direction: column;
    gap: 20px;
  }
`;
export const ButtonAdd = styled.button`
  border-radius: 35px;
  border: none;
  padding: 5px 15px;
  width: 80px;
  color: #fff;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
  }
  height: 30px;
  line-height: normal;
  letter-spacing: -0.178px;
  background: #8a4b5e;
`;
export const ButtonCancel = styled.button`
  border-radius: 35px;
  border: none;
  padding: 5px 15px;
  color: #fff;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: 30px;
  box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
  }
  line-height: normal;
  letter-spacing: -0.178px;
  background: #8a4b5e;
`;
export const Input = styled.input`
  border-radius: 35px;
  border: 0.5px solid #8a4b5e;
  width: 230px;
  padding: 0px 20px;
  height: 30px;
  background: #fff;
  color: #000;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.178px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
    font-family: "Avenir";
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    letter-spacing: -0.178px;
  }
`;

export const TextAdd = styled.span`
  color: #8a4b5e;
  font-family: "Avenir";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.178px;
`;

export const CategoryContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

export const CategoryContentContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  padding-bottom: 6px;
`;
