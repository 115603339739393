import React, { useEffect, useRef, useState } from "react";
import { ModalBackground, ModalContainer, ModalContent } from "./Modal3.styles";

const Modal2 = ({
  open,
  children,
  width,
  height,
  left,
  padding,
  unset,
  bg,
  borderRadius,
  border,
  className,
  paddingOuter,
  onClose,
  bgColor,
  paddingTop,
  blur,
}) => {
  const [isVisible, setIsVisible] = useState(open);
  const modalContentRef = useRef(null);

  const handleBackgroundClick = (e) => {
    if (!modalContentRef.current.contains(e.target)) {
      onClose();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape" && onClose) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    let timer;
    if (open) {
      setIsVisible(true);
    } else {
      timer = setTimeout(() => setIsVisible(false), 250);
    }

    return () => clearTimeout(timer);
  }, [open]);

  useEffect(() => {
    if (isVisible) {
      document.documentElement.style.overflowY = "hidden";

      window.scrollTo(0, 0);
    } else {
      document.documentElement.style.overflowY = "auto";
    }
  }, [isVisible]);

  return isVisible ? (
    <ModalBackground
      $open={open}
      onClick={handleBackgroundClick}
      style={{ backgroundColor: bgColor, backdropFilter: `blur(${blur})` }}
    >
      <ModalContainer $left={left} style={{ padding: paddingOuter }}>
        <ModalContent
          style={{
            background: bg,
            border: border,
            borderRadius: borderRadius,
            paddingTop: paddingTop,
            padding: padding,
          }}
          $open={open}
          $left={left}
          className={className}
          width={width}
          height={height}
          $unset={unset}
          ref={modalContentRef}
        >
          {children}
        </ModalContent>
      </ModalContainer>
    </ModalBackground>
  ) : (
    <></>
  );
};

export default Modal2;
