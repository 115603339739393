import { useTranslation } from "react-i18next";
import Header from "../../../components/Header/Header";
import {
  MainContainer,
  Message,
  ScreenContent,
  SuccessImg,
  Form,
  Title,
  ButtonContainer,
  Button,
  HeaderWrapper,
} from "./VerificationSuccessPage.styles";
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "../../../components/Footer.styles";
import Success from "../../../assets/images/success.png";
import FailedImg from "../../../assets/images/failed.png";
import { useEffect, useState } from "react";
import authService from "../../../api/services/auth";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchBusinessUserInfo } from "../../../store/slices/businessUser/asyncThunks";
import { ReactComponent as Loading } from "../../../assets/icons/loader-white.svg";
import AppLoader from "../../../components/AppLoader";

const VerificationSuccessPage = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [invalidLink, setInvalidLink] = useState(false);

  useEffect(() => {
    if (token) {
      setLoading(true);
      authService
        .validateCode({ code: token })
        .then((result) => {
          toast.success(t("successfully-verified-account"));
          setLoading(false);
          dispatch(fetchBusinessUserInfo());
        })
        .catch(() => {
          setLoading(false);
          setInvalidLink(true);
        });
    }
  }, [token]);

  return (
    <>
      <HeaderWrapper>
        <Header
          showLogin
          showSignUp
          showNav
          color
          showCart
          showFavorite
          showUser
          display
        />
      </HeaderWrapper>
      <MainContainer>
        <ScreenContent>
          {loading && <AppLoader />}
          {!invalidLink && !loading ? (
            <>
              <Title>{t("welcome-to-ygii")}</Title>
              <Form>
                <Message>{t("successfully-verified-account")}</Message>
                <SuccessImg src={Success} alt="success-img" />
              </Form>
              <ButtonContainer>
                <Button
                  onClick={() => {
                    dispatch(fetchBusinessUserInfo());
                    navigation("/admin-portal/dashboard");
                  }}
                >
                  {loading ? <Loading height={30} /> : t("go-to-dashboard")}
                </Button>
              </ButtonContainer>
            </>
          ) : (
            <Form style={{ marginTop: 80 }}>
              <Title>{t("invalid-link")}</Title>
              <SuccessImg src={FailedImg} alt="success-img" />
            </Form>
          )}
        </ScreenContent>
      </MainContainer>
      <Footer />
    </>
  );
};

export default VerificationSuccessPage;
