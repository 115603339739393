import { styled } from "styled-components";

export const Title = styled.span`
  color: #8a4b5e;
  font-family: "Avenir-Roman";
  font-size: 4.5rem;
  text-align: center;
  user-select: none;
  letter-spacing: -0.178px;
  @media (max-width: 720px) {
    text-align: center;
  }
  @media (max-width: 442px) {
    font-size: 3.5rem;
  }
`;
export const Text = styled(Title)`
  font-size: 3.5rem;
  margin-top: -30px;
`;
export const Link = styled.a`
  color: #55a1e8;
  font-size: 3.5rem;

  user-select: text;

  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  margin-top: -5rem;
  width: 90%;
`;
export const ErrorDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
`;

export const Button = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  height: 40px;
  border-radius: 15px;
  padding: 0.5rem 2rem;
  background: #8a4b5e;
  user-select: none;
  color: #fff;
  text-align: center;
  font-family: "FrankfurterEF-Medium";

  font-size: 2.2rem;
  font-weight: 500;
  letter-spacing: -0.178px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media (max-width: 482px) {
    font-size: 1.8rem;
  }
  @media (max-width: 442px) {
    font-size: 1.4rem;
  }
`;
export const StyledButton = styled(Button)`
  border: 1px solid #8a4b5e;
  background: #fff;
  color: #8a4b5e;
`;
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  width: 100%;
  justify-content: center;
  @media (max-width: 390px) {
    flex-direction: column;
  }
`;

export const Success = styled.img`
  user-select: none;
  max-width: 100px;
  background-color: transparent;
`;
export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: 660px) {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  @media (max-width: 612px) {
    flex-direction: column;
    gap: 0.8rem;
    width: 100%;
  }
`;

export const SubTitle = styled.span`
  color: #8a4b5e;
  font-family: "Avenir";
  font-size: 1.8rem;
  font-weight: 300;
  letter-spacing: -0.178px;
  @media (max-width: 640px) {
    font-size: 1.4rem;
  }
`;

export const Input = styled.input`
  border-radius: 1.5rem;
  border: 1px solid #d9dfe3;
  background: #f2f2f2;
  padding: 2rem 1rem;
  color: #666;
  font-size: 1.6rem;

  &:focus {
    background-color: #fff;
  }
  font-family: "Avenir-Light";
  &::placeholder {
    font-family: "Avenir-Medium";
    letter-spacing: -0.178px;
  }
  @media (max-width: 640px) {
    font-size: 1.4rem;
    width: 100%;
  }
`;

export const MainContainer = styled.div`
  position: relative;
  z-index: 0;
  min-height: 100vh;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */

  background: #f6f6f9;
`;

export const HeaderWrapper = styled.div`
  background: #8a4b5e;
  width: 100%;
  z-index: 1000;
  position: fixed;
`;
export const ScreenContent = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;
  height: 91.3rem;

  border: 1px solid #b1b1b1;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(1, 0, 1, 0.1);
  border-radius: 3rem;
  padding: 3rem 0px;
  background: #fff;

  align-items: center;
  justify-content: ${(props) => (props.$center ? "center" : "space-between")};
  gap: 10rem;
  display: flex;
  flex-direction: column;
  @media (max-height: 1200px) {
    height: fit-content;
  }
  @media (max-width: 675px) {
    height: fit-content;
  }
`;

export const Screen = styled.div`
  padding: ${(props) => (props.$loggedIn ? "4rem 4rem 2.3rem 29rem" : "4rem")};

  position: relative;
  flex-direction: column;
  width: ${(props) => (props.$loggedIn ? "100%" : "1440px")};

  height: 100%;

  @media (max-width: 1440px) {
    width: 100%;
  }
  @media (max-width: 1200px) {
    padding: ${(props) => (props.$loggedIn ? "4rem 4rem 4rem 32rem" : "4rem")};
  }
  @media (max-width: 870px) {
    padding: 4rem;
    margin-top: 5rem;
  }
`;

export const StyledTitle = styled.span`
  justify-content: center;
  display: flex;
  user-select: none;
  flex-wrap: wrap;
  font-family: "FrankfurterEF-Medium", sans-serif;
  font-size: 4.8rem;
  line-height: 5.5rem;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  @media (max-width: 442px) {
    font-size: 3.8rem;
  }
`;
