import { styled } from "styled-components";

export const Title = styled.span`
  font-family: "Avenir";
  font-size: 2rem;
  font-weight: 800;
  user-select: none;
  letter-spacing: -0.178px;
`;
export const Relative = styled.div`
  position: relative;
  margin-top: 8px;
`;
export const TabContainer = styled.div`
  display: flex;
  cursor: pointer;
  padding-bottom: 1.5rem;
  flex-direction: column;
  gap: 1.2rem;
  border-bottom: ${(props) =>
    props.$hide ? "1px solid transparent" : "1px solid rgba(72, 72, 136, 0.3)"};
`;
export const LastTabContainer = styled(TabContainer)`
  border-bottom: none;
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: ${(props) => (props.$width ? "unset" : "90rem")};
  @media (max-width: 1280px) {
    width: 90%;
  }
`;
export const Row = styled.div`
  gap: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Content = styled.div`
  margin-left: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;

export const CardRow = styled.div`
  margin-left: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;
export const Scale = styled(Content)`
  margin-left: ${(props) => (props.$hide ? "0rem" : "2rem")};

  @media (max-width: 435px) {
    scale: ${(props) => (props.$hide ? 1 : 0.9)};
  }
  @media (max-width: 380px) {
    scale: ${(props) => (props.$hide ? 1 : 0.8)};
  }
  @media (max-width: 345px) {
    scale: ${(props) => (props.$hide ? 1 : 0.7)};
  }
  @media (max-width: 320px) {
    scale: ${(props) => (props.$hide ? 1 : 0)};
  }
`;

export const VisaImg = styled.img`
  width: 35px;
  height: 35px;
  background-color: transparent;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
`;
export const Number = styled.span`
  color: #8a4b5e;
  font-family: "Avenir-Light";
  font-size: 1.8rem;
  user-select: "none";
  letter-spacing: -0.178px;
`;
export const SubTitle = styled.span`
  color: #8a4b5e;
  font-family: "Avenir";
  font-size: 1.6rem;
  cursor: default;
  user-select: none;
  font-weight: 800;
  letter-spacing: -0.178px;
`;
export const Input = styled.input`
  border-radius: 1.5rem;
  border: 1px solid #d9dfe3;
  background-color: #f2f2f2;
  padding: 2rem 7px;
  color: #666;

  font-size: 1.6rem;
  cursor: default;
  user-select: none;
  font-family: "Avenir-Light";
  &::placeholder {
    font-family: "Avenir-Medium";
    letter-spacing: -0.178px;
  }
  &:focus {
    background-color: #fff;
  }
  @media (max-width: 565px) {
    width: 100%;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 565px) {
    width: 100%;
  }
`;
export const StyledColumn = styled(Column)`
  gap: 4rem;
  @media (max-width: 1280px) {
    gap: 1rem;
  }
`;
export const StyledContent = styled(Content)`
  justify-content: space-between;
  margin-left: 0;
`;
export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const ButtonCopy = styled.button`
  border-radius: 10px;
  background: #8a4b5e;
  border: none;
  user-select: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: "Avenir";
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: -0.178px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0.4rem 0.8rem;
  position: absolute;
  right: 5px;
  top: 5px;
  &:active {
    scale: 0.95;
    transition: all 0.2s ease-in-out;
  }
  @media (max-width: 1200px) {
    top: 3px;
  }
`;

export const Text = styled.span`
  color: #8a4b5e;
  font-family: "Avenir";
  font-size: 1.4rem;
  cursor: default;

  font-weight: 400;
  letter-spacing: -0.178px;
  padding: 1.2rem 2.5rem;
  background: rgba(72, 72, 136, 0.1);
`;
export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  margin-left: 2rem;
`;
export const Red = styled.span`
  color: #ff2e00;
  font-family: "Avenir";
  font-size: 1.6rem;
  font-weight: 800;
  letter-spacing: -0.178px;
`;
export const ButtonWhite = styled.button`
  border-radius: 15px;
  border: 1px solid #8a4b5e;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0.25rem 0.5rem;
  width: 25rem;
  align-items: center;
  display: flex;
  flex-direction: row;
  outline: none;
  gap: 1rem;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  color: #8a4b5e;
  text-align: center;
  font-family: "Avenir";
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: -0.178px;
  @media (max-width: 630px) {
    width: 170px;
  }
`;
export const Button = styled.button`
  border: none;
  user-select: none;
  outline: none;
  cursor: pointer;
  border-radius: 15px;
  height: 4rem;
  width: fit-content;
  padding: 0.5rem 2rem;
  background: #8a4b5e;
  color: #fff;
  text-align: center;
  font-family: "FrankfurterEF-Medium";
  height: 4rem;
  font-size: ${(props) => (props.$hide ? "2rem" : "2.2rem")};
  font-weight: 500;
  letter-spacing: -0.178px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: 700px) {
    /* width: 50%; */
    font-size: 1.5rem;
    height: fit-content;
  }
  @media (max-width: 455px) {
    font-size: 1.5rem;
  }
`;
export const StyledButton = styled(Button)`
  border: 1px solid #8a4b5e;
  background: #fff;
  color: #8a4b5e;
  @media (max-width: 700px) {
    /* width: 50%; */
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  width: 100%;
  justify-content: center;
  @media (max-width: 460px) {
    width: 90%;
  }
`;
