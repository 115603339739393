import styled from "styled-components";

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 25px;
  align-items: flex-start;
  justify-content: center;
`;

export const CloseContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: -25px;
  right: -25px;
`;

export const BigText = styled.span`
  font-family: "Avenir-Medium";
  font-size: 24px;

  letter-spacing: -0.27px;
  line-height: 29.05px;
`;
export const SmallText = styled.span`
  font-family: "Avenir-Medium";
  font-size: 14px;

  letter-spacing: -0.27px;
  line-height: 16.94px;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
`;
export const Button = styled.button`
  width: 100%;
  background-color: #8a4b5e;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.27px;
  cursor: pointer;
  &:active {
    transform: scale(0.97);
    opacity: 0.8;
    transition: all 0.2s ease-in;
  }
`;
