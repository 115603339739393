import React, { useEffect, useState } from "react";
import {
  MainContainer,
  Column,
  SectionTitle,
  LoaderContainer,
  HeaderWrapper,
} from "./AddItem.styles";
import Sidebar from "../../utils/Sidebar";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Steps from "../../../../components/Steps/Steps";
import AddItemStepCategory from "../../../../components/Steps/Items/AddItemStepCategory";
import AddItemStepItemsInfo from "../../../../components/Steps/Items/AddItemStepItemsInfo";
import AddItemStepPrintArea from "../../../../components/Steps/Items/AddItemStepPrintArea";
import AddItemStepAttributes from "../../../../components/Steps/Items/AddItemStepAttributes";
import AddItemStepPricing from "../../../../components/Steps/Items/AddItemStepPricing";
import AddItemStepComplete from "../../../../components/Steps/Items/AddItemStepComplete";
import { selectedMarketplaceItem } from "../../../../store/slices/marketplace/marketplaceSlice";

import { useLocation } from "react-router-dom";
import { getMarketplaceItemById } from "../../../../store/slices/marketplace/asyncThunks";
import { useSelector } from "react-redux";
import Header from "../../../../components/Header/Header";
import { selectedLocale } from "../../../../store/slices/common/slice";
import { selectBusinessUserInfo } from "../../../../store/slices/businessUser/slice";
import AppLoader from "../../../../components/AppLoader";

const AddItem = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(1);
  const { state } = useLocation();
  const item = useSelector(selectedMarketplaceItem);
  const action = state?.action;
  const itemId = state?.productId;
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (action === "edit") {
      dispatch(getMarketplaceItemById(itemId));
    }
  }, [state, dispatch, action]);

  const stepName = [
    t("category"),
    t("items-info"),
    t("print-area"),
    t("attributes"),
    t("pricing"),
  ];

  const businessUserInfo = useSelector(selectBusinessUserInfo);

  const showUpgradeBar = businessUserInfo.company.packageType === "Free";
  let diff =
    new Date(businessUserInfo.company?.packageExpiration).getTime() -
    new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  const locale = useSelector(selectedLocale);
  const upgradePackage = showUpgradeBar && diff > 0 && businessUserInfo.state;
  const ar = locale === "ar";

  return (
    <>
      <MainContainer>
        <HeaderWrapper>
          <Header showLogin showNav color showUser showCart showFavorite />
        </HeaderWrapper>
        <Sidebar />

        <Column $ar={ar} $upgradePackage={upgradePackage}>
          <SectionTitle>
            {action === "create" ? t("add-an-item") : t("edit-an-item")}
          </SectionTitle>
          {activeStep !== 5 && (
            <Steps
              steps={stepName}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          )}
          {loading && (
            <LoaderContainer>
              <AppLoader />
            </LoaderContainer>
          )}

          {!loading && (
            <>
              {activeStep === 1 && (
                <AddItemStepCategory
                  action={action}
                  item={item}
                  onNext={() => {
                    setActiveStep(2);
                  }}
                />
              )}
              {activeStep === 2 && (
                <AddItemStepItemsInfo
                  onBack={() => {
                    setActiveStep(1);
                  }}
                  onNext={() => {
                    setActiveStep(3);
                  }}
                  item={item}
                  action={action}
                  setPhotos={setPhotos}
                  photos={photos}
                />
              )}
              {activeStep === 3 && (
                <AddItemStepPrintArea
                  photos={photos}
                  action={action}
                  onBack={() => {
                    setActiveStep(2);
                  }}
                  onNext={() => {
                    setActiveStep(4);
                  }}
                />
              )}
              {activeStep === 4 && (
                <AddItemStepAttributes
                  photos={photos}
                  action={action}
                  onBack={() => {
                    setActiveStep(3);
                  }}
                  onNext={() => {
                    setActiveStep(5);
                  }}
                />
              )}
              {activeStep === 5 && (
                <AddItemStepPricing
                  onBack={() => {
                    setActiveStep(4);
                  }}
                  onNext={() => {
                    setActiveStep(6);
                  }}
                  photos={photos}
                  setLoading={setLoading}
                  itemId={itemId}
                  action={action}
                />
              )}
            </>
          )}
          {activeStep === 6 && <AddItemStepComplete action={action} />}
        </Column>
      </MainContainer>
    </>
  );
};

export default AddItem;
