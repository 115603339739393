import styled from "styled-components";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 100px;
  gap: 80px;
  padding: 80px 0px 80px 0px;
  @media (max-width: 1650px) {
    margin-left: 0px;
  }
`;
export const Text = styled.span`
  color: #8a4b5e;
  font-family: "Avenir";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.178px;
`;
export const ButtonNext = styled.button`
  padding: 12px 15px;
  background: #8a4b5e;
  border-radius: 15px;
  border: 0;
  user-select: none;
  color: #fff;
  font-family: "Avenir-Medium";

  font-size: 18px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: block;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &::after {
    width: 25px;
    height: 1px;
    background: white;
    transform: translateX(-3px);
    margin-top: 0px;
  }

  &::before {
    content: "";
    transform: rotate(-135deg) translateX(50%);
    width: 11px;
    height: 11px;
    background: transparent;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    margin-top: -1px;
  }

  &:hover {
    background: #4ab639;
    padding: 12px 60px 12px 15px;
  }

  &:hover::after,
  &:hover::before {
    opacity: 1;
    right: 15px;
  }
`;
export const ButtonBack = styled.button`
  padding: 12px 15px;
  background: #8a4b5e;
  border-radius: 15px;
  border: 0;
  color: #fff;
  font-family: "Avenir-Medium";
  user-select: none;

  font-size: 18px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: block;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &::after {
    width: 25px;
    height: 1px;
    background: white;
    transform: translateX(-4.5px);
    margin-top: 0px;
  }

  &::before {
    content: "";
    transform: rotate(-135deg) translateX(50%);
    width: 11px;
    height: 11px;
    background: transparent;
    border-right: 1px solid white;
    border-top: 1px solid white;
    margin-top: -1px;
  }

  &:hover {
    background: #d92525;
    padding: 12px 15px 12px 60px;
  }

  &:hover::after,
  &:hover::before {
    opacity: 1;
    right: 15px;
  }
`;
export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: auto;
  margin-bottom: 40px;
  @media (max-width: 1440px) {
    margin-bottom: 20px;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding-bottom: 80px;
  align-items: flex-start;
  @media (max-width: 1650px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const Category = styled.div`
  border-radius: 35px;
  background: #f3edef;
  width: 164px;
  height: 48px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Value = styled.span`
  color: #222239;
  text-align: center;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.178px;
`;
export const RowCategory = styled.div`
  display: Flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;
export const Categories = styled(RowCategory)`
  flex-direction: column;
  gap: 12px;
  max-height: 588px;
`;

export const AddCategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  @media (max-width: 1650px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const ButtonAdd = styled.button`
  border-radius: 35px;
  border: none;
  padding: 5px 15px;
  width: 80px;
  color: #fff;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
  }
  height: 30px;
  line-height: normal;
  letter-spacing: -0.178px;
  background: #8a4b5e;
`;
export const ButtonCancel = styled.button`
  border-radius: 35px;
  border: none;
  padding: 5px 15px;
  color: #fff;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: 30px;
  box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
  }
  line-height: normal;
  letter-spacing: -0.178px;
  background: #8a4b5e;
`;
export const Input = styled.input`
  border-radius: 35px;
  border: 0.5px solid #8a4b5e;
  width: 230px;
  padding: 0px 20px;
  height: 30px;
  background: #fff;
  color: #000;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.178px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
    font-family: "Avenir";
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    letter-spacing: -0.178px;
  }
`;
export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 40px;
  align-items: center;
  justify-content: space-between;
  width: 443px;
  height: 265px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
`;

export const Message = styled.span`
  color: #8a4b5e;
  text-align: center;
  font-family: "Avenir";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.067px;
`;

export const RowButtons = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 24px;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonSubmit = styled(ButtonAdd)`
  width: 150px;
  height: 44px;
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

export const HeaderWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  justify-content: center;
`;
