import InfiniteScroll from "react-infinite-scroll-component";
import styled, { keyframes } from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";

const jumpUp = keyframes`
  0% , 100% {
    transform: translateY(0rem);
  }
  50% {
    transform: translateY(-5rem);
  }
`;

export const MainContainer = styled.div`
  flex-direction: column;
  background: #f6f6f9;
  min-height: 100vh;
  display: flex;
`;
export const HeaderWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  justify-content: center;
`;
export const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 75px;
  justify-content: center;
  padding: 80px 0px;
`;
export const Column = styled.div`
  flex-direction: column;
  display: flex;
  transition: all 0.2s ease-in-out;
  width: ${(props) => (props.$loggedIn ? "1800px" : "1440px")};
  padding: ${(props) =>
    props.$loggedIn && props.$ar
      ? "0px 290px 0px 40px"
      : props.$loggedIn && !props.$ar
      ? "0px 40px 0px 290px"
      : !props.$loggedIn && props.$ar
      ? "0px 40px"
      : "0px 40px"};
  @media (max-width: 1440px) {
    width: 100%;
  }
  @media (max-width: 870px) {
    padding: 0px;
  }
`;

export const CardProductMockup = styled.div`
  gap: 10px;
  cursor: pointer;
  border: 1px solid rgb(1, 1, 1, 0.15);
  width: 215px;
  height: 350px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  &:hover {
    transition: transform 0.2s ease-out;
    transform: scale(1.1);
    -webkit-box-shadow: 0px 0px 30px 5px rgba(1, 1, 1, 0.1);
    -moz-box-shadow: 0px 0px 30px 5px rgba(1, 1, 1, 0.1);
    box-shadow: 0px 0px 30px 5px rgba(1, 1, 1, 0.1);
  }
`;

export const ProductNameContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #e8dbdf;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 15px 15px 20px 15px;
  margin-top: auto;
  gap: 10px;
  width: 100%;
`;
export const ProductPrice = styled.span`
  font-family: "Avenir-Heavy";
  font-size: 24px;
  cursor: default;
  letter-spacing: -0.41px;
  color: #343456;
`;
export const ColorRow = styled.div`
  padding: 0px 15px;
  display: flex;
  gap: 6px;
`;

export const Button = styled.button`
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  border: transparent;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  background: #8a4b5e;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  font-family: "Avenir-Medium";
`;

export const ButtonBack = styled.button`
  cursor: pointer;
  user-select: none;
  border: transparent;
  background: transparent;
  color: #8a4b5e;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Avenir-Medium";
  &:active {
    opacity: 0.75;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 80px 0px 80px 0px;
  @media (max-width: 925px) {
    justify-content: center;
  }
`;

export const BackContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

export const IconRotate = styled.div`
  transform: rotate(90deg);
  margin-right: -10px;
`;

export const ImagePadding = styled.div`
  height: 100%;
  padding-top: 30px;
`;

export const Image = styled.img`
  height: 150px;
  user-select: none;
  max-width: 100%;
`;

export const Sizes = styled.span`
  font-family: "Avenir-Heavy";
  font-size: 14px;
`;
export const Circle = styled.div`
  border-radius: 50%;
  cursor: pointer;
  border: 1.5px solid #fff;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  width: 32px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
  border-radius: 25%;
  /* box-shadow: 0px 0px 5px 2.5px rgba(0, 0, 0, 0.15); */
`;
export const Icons = styled.div`
  display: flex;
  position: absolute;

  cursor: pointer;
  justify-content: center;
  padding: 5px;
  flex-direction: row;
  align-items: center;
  right: 2px;
  top: 2px;
  gap: 6px;
  z-index: 20;
`;
export const SectionTitle = styled.h1`
  color: #8a4b5e;
  font-family: "Avenir";
  margin-top: 40px;
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  z-index: 1000;
  letter-spacing: -0.178px;
  @media (max-width: 870px) {
    display: none;
  }
`;
export const DotsContainer = styled.div`
  position: absolute;
  z-index: 20;
  bottom: 85px;
`;
export const ImageContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: -10px;
  @media (max-width: 335px) {
    width: 100%;
  }
`;
export const ImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  @media (max-width: 335px) {
    width: 100%;
  }
`;
export const Img = styled(LazyLoadImage)`
  width: 300px;
  height: 291px;
  object-fit: ${(props) => (props.$contain ? "contain" : "cover")};
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
  user-select: none;
  pointer-events: none;
  @media (max-width: 335px) {
    width: 100%;
  }
`;
export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto !important;

  &&::-webkit-scrollbar {
    height: 3.8px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
  }

  &&::-webkit-scrollbar-track {
    background-color: transparent;
  }

  gap: 10px;
  max-width: 80%;

  overflow: visible;
  opacity: ${(props) => (props.$open ? 1 : 0)};
  padding-inline: 5px;
`;

export const SortName = styled.span`
  color: ${(props) => (props.selected ? "#fff" : "rgba(255, 250, 250, 0.85)")};
  user-select: none;
  text-align: center;
  font-family: "Avenir-Medium";
  font-size: 13.8px;
  letter-spacing: 0.067px;
  display: flex;
  font-weight: ${(props) => (props.selected ? "600" : "400")};
  cursor: pointer;

  padding: 5px 1.6rem;
  border-radius: 10px;

  transition: all 0.2s ease-in-out;

  text-shadow: ${(props) =>
    props.selected ? "0px 0px 10px rgba(255, 255, 255, 0.75)" : "none"};
  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }
`;
export const CardProduct = styled.div`
  border-radius: 2rem;
  position: relative;

  background: #fff;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  height: 354px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
  z-index: 10;

  &:hover {
    z-index: 50;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    scale: 1.05;
    /* animation: ${jumpUp} 1.5s ease-in-out infinite;  */
  }

  @media (max-width: 335px) {
    width: 90%;
  }
`;
export const CardProductName = styled.span`
  color: #000;
  font-family: "Avenir";
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.067px;
`;
export const ProductInfo = styled.div`
  padding: 15px 20px;
  z-index: 20;
  cursor: pointer;
  border-radius: 1rem 1rem 2rem 2rem;
  background: #fff;
  gap: 6px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 73px;
  box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.05);
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const Price = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-family: "Avenir";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.067px;
`;

export const EndMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 446px) {
    align-items: center;
  }
  @media (max-width: 390px) {
    align-items: flex-start;
  }
  @media (max-width: 366px) {
    align-items: center;
  }
`;
export const EndMessageText = styled.span`
  font-family: "Avenir-Heavy";
  font-size: 1.2rem;
  letter-spacing: -0.2px;
`;
export const StyledInfinityScroll = styled(InfiniteScroll)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: ${(props) => (props.$admin ? "15px 40px 40px 40px" : "40px 40px")};
  @media (max-width: 390px) {
    padding: ${(props) => (props.$admin ? "15px 0px 40px 0px" : "40px 0px")};
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-block: 50px;
`;
export const StyledText = styled(SortName)`
  margin-top: 50px;
  font-size: 22px;
`;
export const StyledBackContainer = styled.div`
  margin-top: -40px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const BackRow = styled(StyledBackContainer)`
  cursor: pointer;
  margin-top: 0;
`;
export const BackText = styled.span`
  color: #8a4b5e;
  user-select: none;
  font-family: "Avenir-Medium";
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.067px;
`;
export const Text = styled.span`
  color: #8a4b5e;
  user-select: none;
  font-family: "Avenir-Medium";
  font-size: 2rem;

  letter-spacing: 0.067px;
  text-align: center;
  margin-top: 8rem;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 110px;
`;

export const Subtitle = styled.span`
  color: #8a4b5e;
  font-family: "Avenir-Medium";
  font-size: 2.5rem;
  line-height: 3.2rem;
  text-align: center;
  margin-top: 80px;

  @media (max-width: 645px) {
    font-size: 2rem;
    text-align: center;
  }
`;

export const StyledMenuContainer = styled.div`
  background-color: #a12d50;
  transition: all 0.2s ease-out;
  height: ${(props) => (props.$open ? "46px" : "0px")};
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: -1;
  top: ${(props) => (props.$upgradePackage ? "calc(6.9rem + 5.4rem)" : "75px")};
  left: 0;
  right: 0;
  opacity: ${(props) => (props.$open ? 1 : 0)};
  padding-left: ${(props) =>
    props.$loggedIn && props.$ar
      ? "0px"
      : props.$loggedIn && !props.$ar
      ? "250px"
      : "0px"};
  padding-right: ${(props) =>
    props.$loggedIn && props.$ar
      ? "250px"
      : props.$loggedIn && !props.$ar
      ? "0px"
      : "0px"};

  @media (max-width: 1200px) {
    top: ${(props) => (props.$upgradePackage ? "115px" : "67px")};
  }

  @media (max-width: 870px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const AdminHeaderContainer = styled.div`
  width: 100%;
  margin-top: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GiftBoxImageContainer = styled.div`
  padding: 0px 40px;
`;
export const GiftBoxImage = styled(LazyLoadImage)`
  user-select: none;
  background-color: transparent;
  height: 550px;
  border-radius: 21px;
  width: 100%;
  max-width: 1440px;
  object-fit: cover;

  @media (max-width: 870px) {
    height: auto;
    min-height: 32rem;
  }

  @media (max-width: 525px) {
    display: none;
  }
`;
