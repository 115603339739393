import React, { useEffect, useState } from "react";
import {
  MainContainer,
  Content,
  MobileScreenContainer,
  Title,
  Success,
  SuccessDiv,
  BackgroundContainer,
  Background,
  BackgroundRotated,
  StoreContainer,
  Logo,
} from "./MobileAppPage.styles";
import AppStore from "../../assets/images/appstore.png";
import GooglePlay from "../../assets/images/googleplay.png";
import { useTranslation } from "react-i18next";
import SuccessImg from "../../assets/images/success.png";
import { useSearchParams } from "react-router-dom";
import orderService from "../../api/services/order";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";

const MobileAppCheckoutFinal = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const paymentChargeId = searchParams.get("tap_id");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (paymentChargeId) {
      setLoading(true);
      orderService
        .validatePayment({ paymentChargeId })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          toast.error(t("Payment validation failed"));
          setError(true);
          setLoading(false);
        });
    }
  }, []);

  return (
    <MainContainer>
      {loading && <Loader></Loader>}
      {!loading && (
        <MobileScreenContainer>
          <BackgroundContainer>
            <Background style={{ height: 100 }} />
            <BackgroundRotated />
          </BackgroundContainer>
          <div
            style={{
              display: "flex",
              position: "absolute",
              width: "100%",
              alignItems: "center",
              marginTop: 20,
              justifyContent: "center",
            }}
          >
            <Logo style={{ width: 75, height: 75 }} />
          </div>

          <Content style={{ marginTop: 80 }}>
            {!error ? (
              <SuccessDiv>
                <Title>{t("your-order-has-been-placed-successfully")}!</Title>
                <Success src={SuccessImg} alt="success" />
                <Title>{t("download-the-app-for-more-details")}!</Title>
                <StoreContainer>
                  <a
                    href="https://apps.apple.com/vn/app/ygii-gifts-wishlists/id1609129551"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={AppStore}
                      alt="App Store"
                      className="store"
                      style={{ userSelect: "none" }}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.ygii&hl=en_US&pli=1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={GooglePlay}
                      alt="img"
                      className="store"
                      style={{ userSelect: "none" }}
                    />
                  </a>
                </StoreContainer>
              </SuccessDiv>
            ) : (
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  width: "100%",
                  alignItems: "center",
                  marginTop: 20,
                  justifyContent: "center",
                  paddingTop: "100px",
                }}
              >
                <Title>{t("error-occurred-while-creating-payment")}</Title>
              </div>
            )}
          </Content>
        </MobileScreenContainer>
      )}
    </MainContainer>
  );
};

export default MobileAppCheckoutFinal;
