import { styled } from "styled-components";
import TableItem from "../../Table/TableItem";
import FirstTableItem from "../../Table/FirstTableItem";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 40px;
`;

export const HeadRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 575px) {
    margin-top: 20px;
  }
  @media (max-width: 590px) {
    flex-direction: column;
    margin-top: 0px;
    gap: 20px;
  }
`;

export const Text = styled.span`
  font-family: "Avenir-Heavy";
  cursor: pointer;
  font-size: 2rem;
  line-height: 27px;
  letter-spacing: -0.177777px;

  color: #8a4b5e;
  @media (max-width: 1030px) {
    font-size: 1.6rem;
  }
  @media (max-width: 500px) {
    font-size: 1.4rem;
  }
`;

export const TableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
  margin-top: 80px;
`;

export const StyledTh = styled.th`
  text-align: center;
  font-family: "Avenir";
`;

export const StyledTd = styled.td`
  text-align: center;
  min-width: 150px !important;
`;

export const StyledTdFirst = styled(StyledTd)`
  text-align: left;
  min-width: unset !important;
  padding-bottom: 10px;
  font-family: "Avenir-Medium";
`;

export const StyledTdAll = styled(StyledTd)`
  padding-bottom: 10px;
`;

export const PageCountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;
  gap: 5px;
`;

export const PageButton = styled.button`
  padding: 2px 7px;
  border: none;
  background: transparent;
  font-family: "Avenir-Medium";
  font-size: 14px;
  color: #8a4b5e;
  cursor: pointer;
  &:active {
    opacity: 0.6;
  }
`;

export const LastTableItem = styled(TableItem)`
  justify-content: flex-end;
  padding-right: 16px;
  cursor: ${(props) => (props.$offClick ? "default" : "pointer")};

  &:active {
    opacity: ${(props) => (props.$offClick ? 1 : 0)};
  }
`;

export const FirstItem = styled(FirstTableItem)`
  padding-left: 18px;
`;
