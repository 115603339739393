import { styled } from "styled-components";

import { ReactComponent as ChevronSvg } from "../../assets/icons/close.svg";

export const Chevron = styled(ChevronSvg)`
  height: 4rem;
  user-select: none;
  width: auto;
  background-color: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-radius: 2rem;
  padding: 1rem;
  fill: #fff;

  position: absolute;
  top: 3rem;
  left: 3rem;
  cursor: pointer;
  z-index: 20;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.4));
  transform: ${(props) => (props.$ar ? "rotate(180deg)" : "unset")};
  &:hover {
    fill: red;
  }
`;

export const HomeContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100dvh;
  justify-content: center;
  align-items: center;

  background-color: #8a4b5e;
`;

export const GameControl = styled.img`
  position: absolute;
  bottom: 3rem;
  user-select: none;

  background-color: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-radius: 2rem;
  padding: 1rem;
  right: 3rem;
  z-index: 20;
`;
