import React from "react";
import {
  Card,
  CardProductName,
  CardRow,
  ImageContainer,
  ProductInfo,
  Row,
  Price,
} from "./SkeletonMarketplaceLoader.styles";

const SkeletonMarketplaceLoader = ({ loggedIn }) => {
  const data = loggedIn
    ? [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]
    : [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];

  return (
    <Row>
      {data.map((x) => (
        <Card key={x.id}>
          <ImageContainer />
          <ProductInfo>
            <CardProductName />
            <CardRow>
              <Price />
              <Price />
            </CardRow>
          </ProductInfo>
        </Card>
      ))}
    </Row>
  );
};

export default SkeletonMarketplaceLoader;
