import { styled } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 40px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 42px;
  margin-bottom: 20px;
`;

export const TextLight = styled.span`
  font-family: "Avenir-Medium";
  font-size: 1.6rem;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
`;

export const Input = styled.input`
  margin-bottom: 10px;
  width: 266px;
  height: 45px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #8c8c8c;
  border-radius: 15px;

  font-family: "Avenir-Medium";
  font-size: 1.6rem;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;

  &::placeholder {
    font-family: "Avenir-Medium";
    font-size: 1.6rem;
    line-height: 22px;
    letter-spacing: -0.177777px;
    color: #b1b1d8;
  }
`;

export const ImageContainer = styled.div`
  cursor: pointer;
  margin-top: 20px;
  position: relative;
  width: fit-content;
  height: fit-content;
`;

export const ProfilePicEmpty = styled.div`
  width: 101px;
  height: 101px;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
`;

export const ButtonConfirm = styled.button`
  width: 10.5rem;
  color: white;
  font-family: "Avenir-Medium";
  font-size: 1.6rem;
  letter-spacing: 0.08px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 12px 18px;
  border: transparent;
  background: #8a4b5e;
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  cursor: pointer;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${(props) => (props.$cancel ? "#cc0000" : " #4ab639")};
  }

  &:active {
    ${({ saveloading }) =>
      saveloading
        ? null
        : `
      box-shadow: 0px 2px 8px -5px rgb(128, 128, 128);
    `};
    transition: all 0.1s ease-in;
    transform: scale(0.95);
  }
`;

export const ButtonPlain = styled.button`
  width: 10.5rem;
  color: white;
  font-family: "Avenir-Medium";
  font-size: 1.6rem;
  letter-spacing: 0.08px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 12px 18px;
  border: transparent;
  background: #8a4b5e;
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  cursor: pointer;

  &:active {
    ${({ saveloading }) =>
      saveloading
        ? null
        : `
      box-shadow: 0px 2px 8px -5px rgb(128, 128, 128);
    
    `};
    transition: all 0.2s ease-in;
    transform: scale(0.95);
  }
`;

export const ButtonVerify = styled.button`
  color: white;
  font-family: "Avenir-Medium";
  font-size: 1.6rem;
  letter-spacing: 0.08px;
  width: 15rem;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 10px 16px;
  border: transparent;
  background: ${(props) => (props.$color ? "#b3b3b3" : "#8A4B5E")};
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  cursor: ${(props) => (props.$color ? "default" : "pointer")};

  &:active {
    box-shadow: 0px 2px 8px -5px rgb(128, 128, 128);
    transition: all 0.2s ease-in;
    transform: scale(0.95);
  }
`;

export const VerifyEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;
