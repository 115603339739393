import location from "../assets/images/location.png";
import logoPurple from "../assets/images/logo_purple.png";
import lock from "../assets/images/lock.png";
import gift from "../assets/images/gift.png";
import success1 from "../assets/images/success1.png";
import success2 from "../assets/images/success2.png";
import copy from "../assets/images/copy.png";
import authBackground from "../assets/images/authBackground.png";
import giftColor from "../assets/images/gift-color.png";
import giftSmall from "../assets/images/gift-small-2.png";
import cake from "../assets/images/cake.png";
import flowers from "../assets/images/flowers.png";
import chocolate from "../assets/images/chocolate.png";
import dashboardImage1 from "../assets/images/dashboardImage1.png";

import Scalers from "../assets/images/scalers.png";
import Flat from "../assets/images/flat.png";
import Glub from "../assets/images/glub.png";
import Sign from "../assets/images/sign.png";
import Ovnum from "../assets/images/ovnum.png";

import WomenWithYgiiBag from "../assets/images/women-with-ygii-bag.png";
import WomenWithYgiiBagLow from "../assets/images/women-with-ygii-bag-low.png";
import AdminPortalDashboard from "../assets/images/admin-portal-dashboard.png";
import AdminPortalDashboardLow from "../assets/images/admin-portal-dashboard-low.png";
import GirlWorkingOnLaptop from "../assets/images/girl-working-on-laptop-and-drinking-coffee.png";
import GirlWorkingOnLaptopLow from "../assets/images/girl-working-on-laptop-and-drinking-coffee-low.png";
import TeamInOffice from "../assets/images/team-in-office-holding-hands-up.png";
import TeamInOfficeLow from "../assets/images/team-in-office-holding-hands-up-low.png";
import GiftAsAServicePopup from "../assets/images/gift-as-a-services-popup.png";
import GiftAsAServicePopupAr from "../assets/images/gift-as-a-services-popup-ar.png";
import GiftAsAServicePopupLow from "../assets/images/gift-as-a-services-popup-low.png";
import EmployeeEngagementPopupAr from "../assets/images/100%-employee-engagement-ar.png";
import EmployeeEngagementPopup from "../assets/images/100%-employee-engagement.png";
import EmployeeEngagementPopupLow from "../assets/images/100%-employee-engagement-low.png";
import GaSAndEmployeeEngagementPopup from "../assets/images/GaS-and-employee-engagement.png";
import GaSAndEmployeeEngagementPopupAr from "../assets/images/GaS-and-employee-engagementAr.png";
import GaSAndEmployeeEngagementPopupLow from "../assets/images/GaS-and-employee-engagement-low.png";

export default {
  logoPurple,
  location,
  GaSAndEmployeeEngagementPopupAr,
  lock,
  EmployeeEngagementPopupAr,
  GiftAsAServicePopupAr,
  gift,
  success1,
  success2,
  copy,
  authBackground,
  giftColor,
  cake,
  flowers,
  chocolate,
  giftSmall,
  dashboardImage1,
  GaSAndEmployeeEngagementPopupLow,
  GaSAndEmployeeEngagementPopup,
  EmployeeEngagementPopup,
  EmployeeEngagementPopupLow,
  GiftAsAServicePopup,
  GiftAsAServicePopupLow,
  TeamInOffice,
  TeamInOfficeLow,
  GirlWorkingOnLaptop,
  GirlWorkingOnLaptopLow,
  AdminPortalDashboard,
  AdminPortalDashboardLow,
  WomenWithYgiiBag,
  WomenWithYgiiBagLow,
  Scalers,
  Flat,
  Glub,
  Sign,
  Ovnum,
};
