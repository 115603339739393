import { axiosAuthClient } from "..";
import { handleError } from "../../utils/errors";

class AuthService {
  verifyEmail = async (email) => {
    try {
      const response = await axiosAuthClient.post("/verify/email", { email });
      if (response.status !== 201) {
        throw new Error();
      }

      return response.data.data;
    } catch (err) {
      throw handleError(err, "Error occurred while signing up new user");
    }
  };

  validateCode = async ({ code }) => {
    try {
      const body = {
        code: code,
      };
      const response = await axiosAuthClient.post("/verify/code", body, {
        withCredentials: true,
      });
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while verifying user");
    }
  };

  signUp = async ({ email, password, companyName, fullName, phoneNumber }) => {
    try {
      const body = {
        email,
        password,
        companyName,
        fullName,
        mobileNumber: phoneNumber,
      };
      const response = await axiosAuthClient.post("/sign-up", body, {
        withCredentials: true,
      });
      if (response.status !== 201) {
        throw new Error();
      }
      const { accessToken, refreshToken, businessUser } = response.data.data;
      return { accessToken, refreshToken, businessUser };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while creating new user, status:" + err.code
      );
    }
  };

  login = async ({ email, password }) => {
    try {
      const response = await axiosAuthClient.post(
        "/login",
        {
          email,
          password,
        },
        { withCredentials: true }
      );
      if (response.status !== 201) {
        throw new Error();
      }
      const { accessToken, refreshToken, businessUser } = response.data.data;

      return { accessToken, refreshToken, businessUser };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while logging in, status:" + err.code
      );
    }
  };

  logout = async () => {
    try {
      const response = await axiosAuthClient.post(
        "/logout",
        {},
        {
          withCredentials: true,
        }
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return;
    } catch (err) {
      throw handleError(err, "Error occurred while logging out");
    }
  };

  verifyEmployeeEmail = async ({ inviteToken, email }) => {
    try {
      const response = await axiosAuthClient.post(
        `/verify/employee?inviteToken=${inviteToken}`,
        { email }
      );
      if (response.status !== 201) {
        throw new Error();
      }

      return response.data.data;
    } catch (err) {
      throw handleError(err, "Error occurred while signing up new user");
    }
  };

  signUpEmployee = async ({
    email,
    password,
    fullName,
    phoneNumber,
    companyId,
    code,
  }) => {
    try {
      const body = {
        email,
        password,
        fullName,
        mobileNumber: phoneNumber,
        companyId,
        code,
      };
      const response = await axiosAuthClient.post("/sign-up/employee", body, {
        withCredentials: true,
      });
      if (response.status !== 201) {
        throw new Error();
      }
      const { accessToken, refreshToken, businessUser } = response.data.data;
      return { accessToken, refreshToken, businessUser };
    } catch (err) {
      throw handleError(err, "Error occurred while creating new user");
    }
  };

  sendCodeForNewPassword = async (email) => {
    try {
      const response = await axiosAuthClient.post(
        "/forgotten-password/send-code",
        {
          email,
        }
      );
      if (response.status !== 201) {
        throw new Error();
      }

      return response.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while sending an email to reset password"
      );
    }
  };

  resetPassword = async ({ email, newPassword, code }) => {
    try {
      const response = await axiosAuthClient.post("/reset-password", {
        email,
        newPassword,
        code,
      });
      if (response.status !== 201) {
        throw new Error();
      }

      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while resetting password");
    }
  };

  refresh = async () => {
    try {
      const response = await axiosAuthClient.get("/refresh-token", {
        withCredentials: true,
      });
      if (response.status !== 200) {
        throw new Error();
      }
      const { accessToken } = response.data.data;
      return { accessToken };
    } catch (err) {
      throw handleError(err, "Error occurred while refresh token");
    }
  };

  validateSignUpData = async ({ email, password, companyName, fullName }) => {
    try {
      const response = await axiosAuthClient.post("/validate-sign-up", {
        email,
        password,
        companyName,
        fullName,
      });
      if (response.status !== 201) {
        throw new Error();
      }

      return;
    } catch (err) {
      throw handleError(err, "Error occurred while validating sing up data");
    }
  };

  sendVerificationEmailAgain = async ({ email }) => {
    try {
      const response = await axiosAuthClient.post("/verification-email", {
        email,
      });
      if (response.status !== 200) {
        throw new Error();
      }

      return;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while sending verification email again"
      );
    }
  };
}
const authService = new AuthService();

export default authService;
