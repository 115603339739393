import React, { useEffect, useState } from "react";
import {
  TableBodyItem,
  ProfilePhotoContainer,
  ProfilePhoto,
  HoverText,
} from "./Table.styles";
import { ReactComponent as User } from "../../assets/icons/gift-empty.svg";

const FirstTableItem = ({
  data,
  className,
  image,
  showImage,
  hoverText,
  showHover,
  length,
  onClickInfo,
  big,
}) => {
  const [hover, setHover] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  let lengthValue = length ? length : 25;

  useEffect(() => {
    let timer;
    if (hover) {
      setIsVisible(true);
    } else {
      timer = setTimeout(() => setIsVisible(false), 50);
    }

    return () => clearTimeout(timer);
  }, [hover]);

  return showHover ? (
    <TableBodyItem
      className={className}
      onClick={onClickInfo}
      $big={big}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {showImage ? (
        image ? (
          <ProfilePhotoContainer>
            <ProfilePhoto src={image} alt="loginPhoto" />
          </ProfilePhotoContainer>
        ) : (
          <ProfilePhotoContainer>
            <User width={20} height={20} />
          </ProfilePhotoContainer>
        )
      ) : null}

      {isVisible && hoverText?.length > lengthValue && (
        <HoverText $hover={hover}>{hoverText}</HoverText>
      )}

      {data}
    </TableBodyItem>
  ) : (
    <TableBodyItem className={className} onClick={onClickInfo} $big={big}>
      {showImage ? (
        image ? (
          <ProfilePhotoContainer>
            <ProfilePhoto src={image} alt="loginPhoto" />
          </ProfilePhotoContainer>
        ) : (
          <ProfilePhotoContainer>
            <User width={20} height={20} />
          </ProfilePhotoContainer>
        )
      ) : null}

      {data}
    </TableBodyItem>
  );
};

export default FirstTableItem;
