import {
  HeaderContainer,
  Center,
  TabName,
  TabNameContainer,
  Row,
  TabNameText,
  CartContainer,
  Dot,
  CartCount,
  LogoContainer,
  LogoText,
  GiftCartContainer,
  GiftCart,
  ItemName,
  ImgContainer,
  Balance,
  BalanceContainer,
  BalanceValue,
  BalanceValueContainer,
  ButtonsContainer,
  StyledDiv,
  StyledButton,
} from "../AccountSettingsPage/AccountSettingsPage.styles";
import Button from "../../../components/Button/Button";
import { useRef, useState } from "react";
import { ReactComponent as BackIcon } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as Cart } from "../../../assets/icons/cart.svg";
import { ReactComponent as Logo } from "../../../assets/icons/logo_purple.svg";
import { ReactComponent as Gift } from "../../../assets/icons/drawer/gift-sent-active.svg";
import { ReactComponent as Delete } from "../../../assets/icons/delete.svg";
import UserInfoModal from "../../../components/UserInfoModal/UserInfoModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BackContainer,
  ButtonBack,
  IconRotate,
} from "../MarketplacePage/MarketplacePage.styles";
import { cartItemsData } from "../../../store/slices/marketplace/marketplaceSlice";
import {
  selectCompanyInfo,
  selectCompanyPackage,
} from "../../../store/slices/businessUser/slice";
import { useTranslation } from "react-i18next";
import { removeGift, selectGifts } from "../../../store/slices/sendGifts/slice";
import { ReactComponent as ChargeWallet } from "../../../assets/icons/charge.svg";
import LocaleSelection from "../../../components/LocaleSelection/LocaleSelection";
import { getCurrencySymbol } from "../../../utils/constants";
import { priceFormatter } from "../../../utils/utils";
import { selectedLocale } from "../../../store/slices/common/slice";
import { countClickAction } from "../../../store/slices/common/asyncThunks";
import { ReactComponent as SendGiftIcon } from "../../../assets/icons/send-gift-icon.svg";

const ContentHeader = ({
  title,
  registeredRecipient,
  number,
  showBack,
  showCount,
  hide,
  showGiftCart,
  showLogo,
  showButton,
  showWallet,
  showTitle,
  showCart,
  tabStyle,
  style,
  logoStyle,
  onBack,
  showLocale,
  onChargeWallet,
  showAddItem,
  showButtonCharge,
  showSendGifts,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const userInfoRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useSelector(cartItemsData);
  const data = useSelector(selectGifts);
  const selectCompany = useSelector(selectCompanyInfo);
  const planIdState = useSelector(selectCompanyPackage);
  const selectedLanguage = useSelector(selectedLocale);

  const handleItemClick = (id) => {
    navigate(`/admin-portal/item-detail/${id}`);
  };

  const Notification = () => {
    if (items?.length > 0) {
      return (
        <Dot>
          <CartCount>{items?.length}</CartCount>
        </Dot>
      );
    } else {
      return null;
    }
  };
  const Notification2 = () => {
    if (data?.length > 0) {
      return (
        <Dot>
          <CartCount>{data?.length}</CartCount>
        </Dot>
      );
    } else {
      return null;
    }
  };

  const goBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  const handleShowGiftsList = () => {
    setShow(!show);
  };

  const remove = (id) => {
    dispatch(removeGift(id));
  };

  return (
    <>
      {!hide && (
        <HeaderContainer style={style}>
          {showLogo && (
            <LogoContainer
              onClick={() => {
                navigate("/admin-portal/dashboard");
              }}
              style={logoStyle}
            >
              <Logo />
              <LogoText>{t("business")}</LogoText>
            </LogoContainer>
          )}
          <Center>
            {showButton && planIdState && (
              <Button
                onClick={() => {
                  dispatch(countClickAction("startGifting"));
                  navigate("/admin-portal/send-gifts/1");
                }}
                title={t("send-gifts")}
              />
            )}

            {showLocale && (
              <LocaleSelection textColor="#8A4B5E"></LocaleSelection>
            )}
            {showCart && (
              <CartContainer
                onClick={() => {
                  navigate("/admin-portal/cart");
                }}
              >
                <Cart />
                <Notification />
              </CartContainer>
            )}
            {showGiftCart && (
              <>
                <GiftCartContainer>
                  <div onClick={handleShowGiftsList}>
                    <Gift />
                    <div style={{ position: "absolute", top: 2, right: 4 }}>
                      <Notification2 />
                    </div>
                  </div>
                  {show && (
                    <GiftCart>
                      {data?.length === 0 ? (
                        <div
                          style={{
                            width: 115,
                          }}
                        >
                          <ItemName>No gifts in cart.</ItemName>
                        </div>
                      ) : (
                        data.map((product) => (
                          <ImgContainer>
                            <img
                              onClick={() => {
                                handleItemClick(product.id);
                              }}
                              src={product.image}
                              alt="item-img"
                              style={{
                                height: 70,
                                borderRadius: 10,
                                background: "white",
                              }}
                              className="item-detail-img"
                            />

                            <Delete
                              onClick={() => remove(product.id)}
                              height={22}
                              fill={"white"}
                              className="pointer"
                            />
                          </ImgContainer>
                        ))
                      )}
                    </GiftCart>
                  )}
                </GiftCartContainer>
              </>
            )}
          </Center>
          <UserInfoModal modalRef={userInfoRef} />
        </HeaderContainer>
      )}
      <TabNameContainer style={tabStyle}>
        {showTitle && <TabName>{title}</TabName>}
        {showWallet && (
          <StyledDiv>
            {selectedLanguage === "en" ? (
              <BalanceContainer>
                <BalanceValueContainer>
                  <Balance>{t("wallet-balance")}:</Balance>
                  <BalanceValue>
                    {selectCompany.walletBalance
                      ? `${priceFormatter(
                          selectCompany?.walletBalance
                        )} ${getCurrencySymbol(selectCompany?.walletCurrency)}`
                      : "0"}
                  </BalanceValue>
                </BalanceValueContainer>
              </BalanceContainer>
            ) : (
              <BalanceContainer>
                <BalanceValueContainer>
                  <BalanceValue>
                    {`${priceFormatter(
                      selectCompany?.walletBalance
                    )} ${getCurrencySymbol(selectCompany?.walletCurrency)}`}
                  </BalanceValue>
                  <Balance>:{t("wallet-balance")}</Balance>
                </BalanceValueContainer>
              </BalanceContainer>
            )}
            {showButtonCharge && (
              <ButtonsContainer>
                <StyledButton
                  onClick={() => {
                    onChargeWallet();
                  }}
                >
                  {t("charge-wallet")}
                  <ChargeWallet width={28} height={"auto"} />
                </StyledButton>
                <StyledButton
                  onClick={() => {
                    dispatch(countClickAction("startGifting"));
                    navigate("/admin-portal/send-gifts/1");
                  }}
                >
                  {t("send-gifts")}
                  <SendGiftIcon fill="#FFF" width={28} height={"auto"} />
                </StyledButton>
              </ButtonsContainer>
            )}
          </StyledDiv>
        )}
        {showBack && (
          <BackContainer onClick={goBack}>
            <IconRotate>
              <BackIcon />
            </IconRotate>
            <ButtonBack>{t("back")}</ButtonBack>
          </BackContainer>
        )}

        {showCount && (
          <>
            {selectedLanguage === "en" ? (
              <Row>
                <TabNameText>{registeredRecipient}:</TabNameText>
                <TabName>{number}</TabName>
              </Row>
            ) : (
              <Row>
                <TabName>{number}</TabName>
                <TabNameText>:{registeredRecipient}</TabNameText>
              </Row>
            )}
          </>
        )}
      </TabNameContainer>
    </>
  );
};

export default ContentHeader;
