import styled from "styled-components";

export const Search = styled.input`
  border-radius: 10px;

  user-select: none;
  width: 100%;
  border: none;
  height: 45px;
  font-family: "Avenir-Medium";
  font-size: 1.2rem;
  letter-spacing: 0.067px;

  &:active {
    outline: none;
  }
  &::placeholder {
    color: #8a4b5e;

    opacity: 0.75;
  }

  @media (max-width: 1530px) {
    width: 100%;
  }
`;
export const Search2 = styled.input`
  border-radius: 10px;

  user-select: none;
  height: 45px;
  border: none;
  width: 100%;
  font-family: "Avenir-Medium";
  font-size: 1.5rem;
  letter-spacing: 0.067px;
  width: 230px;
  background: transparent;
  color: #fff;
  border-radius: 0;
  &:active {
    outline: none;
  }
  &::placeholder {
    color: ${(props) => (props.$placeholderColor ? "#8A4B5E" : "#fff5")};
    opacity: 0.75;
  }
`;

export const StyledInput = styled.div`
  position: relative;

  border-radius: 10px;
  width: 300px;
  user-select: none;
  height: 45px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  border: none;
  padding-right: ${(props) => (props.$ar ? "46px" : "15px")};
  padding-left: ${(props) => (props.$ar ? "15px" : "46px")};

  @media (max-width: 1530px) {
    width: 200px;
  }
  @media (max-width: 1035px) {
    width: 150px;
  }
`;
export const StyledInput2 = styled.div`
  position: relative;
  border-radius: 10px;

  user-select: none;
  height: 45px;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  border: none;
  padding-right: ${(props) => (props.$ar ? "46px" : "15px")};
  padding-left: ${(props) => (props.$ar ? "15px" : "46px")};
  width: 230px;
  background: transparent;
  color: #fff;
  border-bottom: 1px solid #fff;
  border-radius: 0;
`;
export const IconContainer = styled.div`
  position: absolute;
  top: 50%;

  left: ${(props) => (props.$ar ? "unset" : "12px")};
  right: ${(props) => (props.$ar ? "12px" : "unset")};
  cursor: pointer;
  transform: translateY(-50%);
`;
export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1075px) {
    align-items: flex-start;
  }
  @media (max-width: 870px) {
    display: none;
  }
`;
