import styled from "styled-components";
import { ReactComponent as ChevronIcon } from "../../../assets/icons/chevron-left.svg";

export const PaginationContainer = styled.ul`
  display: flex;
`;

export const ArrowContainer = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;
  pointer-events: ${(props) => props.disabled && "none"};
`;

export const ArrowIcon = styled(ChevronIcon)`
  width: 1.2rem;
  height: 1.2rem;
`;

export const RightArrowIcon = styled(ArrowIcon)`
  transform: rotate(180deg);
`;

export const PaginationItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  font-family: ${(props) => (props.selected ? "Avenir-Heavy" : "Avenir")};
  font-size: 15px;
  font-style: normal;
  line-height: normal;
  user-select: none;
  letter-spacing: -0.408px;
  color: ${(props) => (props.selected ? "#8A4B5E" : "#0005")};

  &:hover {
    cursor: ${(props) => (props.dots ? "default" : "pointer")};
  }
`;
