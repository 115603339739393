import { createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../../../api/services/auth";

export const signUp = createAsyncThunk("auth/sign-up", async (requestData) => {
  return await authService.signUp(requestData);
});

export const validateCode = createAsyncThunk(
  "auth/verify/code",
  async (requestData) => {
    return await authService.validateCode(requestData);
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verify/email",
  async (requestData) => {
    return await authService.verifyEmail(requestData);
  }
);

export const login = createAsyncThunk("auth/login", async (requestData) => {
  return await authService.login(requestData);
});

export const verifyEmployeeEmail = createAsyncThunk(
  "auth/verify/employee",
  async (requestData) => {
    return await authService.verifyEmployeeEmail(requestData);
  }
);

export const signUpEmployee = createAsyncThunk(
  "auth/sign-up/employee",
  async (requestData) => {
    return await authService.signUpEmployee(requestData);
  }
);

export const sendCodeForNewPassword = createAsyncThunk(
  "auth/sendCodeForNewPassword",
  async (requestData) => {
    return await authService.sendCodeForNewPassword(requestData);
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (requestData) => {
    return await authService.resetPassword(requestData);
  }
);

export const refresh = createAsyncThunk("auth/refresh", async () => {
  return await authService.refresh();
});

export const sendVerificationEmailAgain = createAsyncThunk(
  "auth/sendVerificationEmailAgain",
  async (requestData) => {
    return await authService.sendVerificationEmailAgain(requestData);
  }
);
