import {} from "../../../utils/constants";
import {
  LOCAL_STORAGE_REFRESH_TOKEN,
  LOCAL_STORAGE_ACCESS_TOKEN,
} from "../../../utils/constants";
import {
  assignSubscriptionPlan,
  disableAutomaticSubscriptionRenewal,
  validatePayment,
} from "../subscriptions/asyncThunks";
import {
  editCompanyInfo,
  editCompanyProfile,
  fetchBusinessUserInfo,
  getInvitationLink,
  logout,
} from "./asyncThunks";

export const businessUserInfoBuilder = (builder) => {
  builder.addCase(fetchBusinessUserInfo.pending, (state) => {
    state.fetchBusinessUserInfoLoading = true;
  });
  builder.addCase(fetchBusinessUserInfo.fulfilled, (state, action) => {
    state.fetchBusinessUserInfoLoading = false;
    const {
      id,
      fullName,
      email,
      mobileNumber,
      role,
      company,
      tapPaymentCustomerId,
      verified,
    } = action.payload;
    state.id = id;
    state.fullName = fullName;
    state.email = email;
    state.mobileNumber = mobileNumber;
    state.role = role;
    state.company = company;
    state.tapPaymentCustomerId = tapPaymentCustomerId;
    state.verified = verified;
    state.loggedIn = true;
  });
  builder.addCase(fetchBusinessUserInfo.rejected, (state) => {
    state.fetchBusinessUserInfoLoading = false;
  });
};

export const invitationLinkBuilder = (builder) => {
  builder.addCase(getInvitationLink.pending, (state) => {
    state.gettingInvitationLink = true;
  });
  builder.addCase(getInvitationLink.fulfilled, (state, action) => {
    state.gettingInvitationLink = false;
    const invitationLink = action.payload;
    state.invitationLink = invitationLink;
  });
  builder.addCase(getInvitationLink.rejected, (state) => {
    state.gettingInvitationLink = false;
  });
};

export const editCompanyBuilder = (builder) => {
  builder.addCase(editCompanyInfo.pending, (state) => {
    state.editingCompanyInfo = true;
  });
  builder.addCase(editCompanyInfo.fulfilled, (state, action) => {
    state.editingCompanyInfo = false;
    const company = action.payload;
    state.company = company;
  });
  builder.addCase(editCompanyInfo.rejected, (state) => {
    state.editingCompanyInfo = false;
  });
};

export const editCompanyProfileBuilder = (builder) => {
  builder.addCase(editCompanyProfile.pending, (state) => {
    state.editingCompanyInfo = true;
  });
  builder.addCase(editCompanyProfile.fulfilled, (state, action) => {
    state.editingCompanyInfo = false;
    const businessUser = action.payload;
    state.company = businessUser.company;
    state.fullName = businessUser.fullName;
  });
  builder.addCase(editCompanyProfile.rejected, (state) => {
    state.editingCompanyInfo = false;
  });
};

export const logoutBuilder = (builder) => {
  builder.addCase(logout.pending, (state) => {
    state.loggedIn = false;
  });
  builder.addCase(logout.fulfilled, (state) => {
    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
    state.loggedIn = false;
  });
  builder.addCase(logout.rejected, (state) => {
    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
    state.loggedIn = false;
  });
};

export const disableAutomaticSubscriptionRenewalBuilder = (builder) => {
  builder.addCase(disableAutomaticSubscriptionRenewal.pending, (state) => {
    state.disableAutomaticSubscriptionRenewal.loading = true;
  });
  builder.addCase(disableAutomaticSubscriptionRenewal.fulfilled, (state) => {
    state.company.automaticSubscriptionRenewal = false;
    state.disableAutomaticSubscriptionRenewal.loading = false;
  });
  builder.addCase(
    disableAutomaticSubscriptionRenewal.rejected,
    (state, { payload }) => {
      state.disableAutomaticSubscriptionRenewal.loading = false;
    }
  );
};

export const assignSubscriptionPlanBuilder = (builder) => {
  builder.addCase(assignSubscriptionPlan.pending, (state) => {
    state.assignSubscriptionPlanLoading = true;
  });
  builder.addCase(assignSubscriptionPlan.fulfilled, (state, action) => {
    state.assignSubscriptionPlanLoading = false;
    const { packageType } = action.payload.data;
    if (packageType === "Free") {
      state.company = { ...state.company, packageType };
    }
  });
  builder.addCase(assignSubscriptionPlan.rejected, (state) => {
    state.assignSubscriptionPlanLoading = false;
  });
};

export const validatePaymentSubscriptionBuilder = (builder) => {
  builder.addCase(validatePayment.pending, (state) => {});
  builder.addCase(validatePayment.fulfilled, (state, action) => {
    const { data } = action.payload;

    if (data && data.packageType) {
      state.company = { ...state.company, packageType: data.packageType };
    }
  });
  builder.addCase(validatePayment.rejected, (state) => {});
};
