import { createSlice } from "@reduxjs/toolkit";
import {
  getItemUnauthorizedBuilder,
  getUsersWishlistItemsUnauthorizedBuilder,
} from "./builder";

const itemSlice = createSlice({
  name: "itemUnauthorized",
  initialState: {
    itemInfo: [],
    selectedItem: {},
    getUsersWishlistItemsUnauthorizedLoading: false,
    getItemUnauthorizedPending: true,
  },
  reducers: {},

  extraReducers: (builder) => {
    getUsersWishlistItemsUnauthorizedBuilder(builder);
    getItemUnauthorizedBuilder(builder);
  },
});

// export const {} = itemSlice.actions;

export const selectedItemInfo = (state) => state.itemUnauthorized.itemInfo;
export const selectedItemLoading = (state) =>
  state.itemUnauthorized.getUsersWishlistItemsUnauthorizedLoading;
export const selectedItem = (state) => state.itemUnauthorized.selectedItem;
export const selectedGetItemLoading = (state) =>
  state.itemUnauthorized.getItemUnauthorizedPending;

export default itemSlice.reducer;
