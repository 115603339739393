import { createSlice } from "@reduxjs/toolkit";
import {
  getOrderBuilder,
  getOrdersBuilder,
  updateOrderStateBuilder,
  getSubcategoriesBuilder,
  getCategoriesBuilder,
  createItemBuilder,
  getMarketplaceItemByIdBuilder,
  getSubcategoriesListBuilder,
  deleteVariableBuilder,
  deleteSubcategoryBuilder,
  deleteCategoryBuilder,
  createVariableBuilder,
  createSubcategoryBuilder,
  createCategoryBuilder,
  editCategoryBuilder,
  editSubcategoryBuilder,
  editVariableBuilder,
  getFavoriteMarketplaceItemsAllBuilder,
  updateCategoryPriorityBuilder,
  getAttributesByCategoryBuilder,
  toggleFavoriteMarketplaceItemBuilder,
  getCountFavoriteMarketplaceItemsBuilder,
  fetchAllMarketplaceItems,
  deleteItemBuilder,
  getSuperAdminOrdersBuilder,
  shareCartProductBuilder,
  generatePhotosWithLogoBuilder,
  getOrderPackagesBuilder,
} from "./builder";
import { LOCAL_STORAGE_CART_ITEMS } from "../../../utils/constants";
import { checkEnvironment } from "../../../utils/utils";
const environment = checkEnvironment();

const initialState = {
  getOrders: {
    data: [],
    total: "",
    maxReached: "",
    perPage: "",
    loading: false,
  },
  getFavoriteItems: {
    count: 0,
    selectedItem: {
      data: {},
      error: null,
      loading: false,
    },
    data: [],
    total: "",
    maxReached: false,
    perPage: "",
    loading: false,
    countError: null,
    countLoading: false,
    page: 1,
  },
  getOrder: {
    data: {},
    loading: true,
  },
  getAllMarketplaceItemsPending: true,
  ordersAdmin: {
    data: [],
    total: "",
    maxReached: "",
    perPage: "",
    loading: true,
  },
  subcategories: {
    data: {},
    loading: false,
  },
  categories: {
    data: {},
    loading: false,
  },
  item: { data: {}, loading: false, error: null },
  selectedItem: {
    data: { subcategories: {} },
    loading: false,
  },
  subcategoriesList: {
    data: [],
    loading: true,
  },
  deleteVariable: {
    loading: true,
  },
  deleteSubcategory: {
    loading: true,
  },
  deleteCategory: {
    loading: false,
  },
  addItem: {
    name: "",
    nameAr: "",
    price: "",
    descriptionAr: "",
    description: "",
    photos: [],
    displayPhoto: null,
    categoryId: [],
    attributes: [],
    editItemSubcategories: [],
    favorite: false,
    userCanUploadFile: false,
    isMinOrderQty: false,
    minOrderQty: 0,
    productionTime: 15,
    priceBrake: false,
    buySample: false,
    sampleQuantity: 0,
    blankPrice: 0,
    buyPrintedSample: false,
    printedQuantity: 0,
    printPrice: 0,
    additionalPrices: [{ minQuantity: "", discount: "" }],
    printAreas: {},
    designWithoutColor: false,
  },
  createVariable: {
    loading: false,
  },
  createSubcategory: {
    loading: false,
  },
  createCategory: {
    loading: false,
  },
  editCategory: {
    loading: false,
  },
  editSubcategory: {
    loading: false,
  },
  editVariable: {
    loading: false,
  },
  updateCategoryPriority: {
    loading: false,
  },
  getAttributesByCategory: {
    data: [],
    loading: false,
  },
  editItem: {
    data: [],
    loading: false,
  },
  marketplaceItems: {
    data: [],
    maxReached: false,
    page: 1,
    loading: false,
    total: null,
  },
  cartItems: [],
  deleteMarketplaceItemPending: false,
  sendCartProductEmail: {
    loading: false,
  },
  createOrder: {
    fullName: "",
    mobileNumber: "",
    addressDetails: "",
    deliveryAddress: {},
    notes: "",
    subTotal: null,
    discountCode: null,
    discountPercentage: null,
  },
  getSuperAdminOrders: {
    data: [],
    total: "",
    maxReached: "",
    perPage: "",
    loading: false,
  },
  editItemData: {
    index: null,
  },
  orderPackages: {
    data: [],
    loading: false,
  },
  generatePhotosWithLogoPending: false,
};

const marketplaceSlice = createSlice({
  name: "marketplace",
  initialState,
  reducers: {
    removeItem: (state, action) => {
      state.addItem.subcategories = state.addItem.subcategories.filter(
        (item, index) => index !== action.payload
      );
    },
    setCategories: (state, action) => {
      state.categories.data.categories = action.payload;
    },
    setArabicName: (state, { payload }) => {
      state.addItem.nameAr = payload;
    },
    setName: (state, { payload }) => {
      state.addItem.name = payload;
    },
    setPrice: (state, { payload }) => {
      state.addItem.price = payload;
    },
    setDescription: (state, { payload }) => {
      state.addItem.description = payload;
    },
    setArabicDescription: (state, { payload }) => {
      state.addItem.descriptionAr = payload;
    },
    setCategory: (state, { payload }) => {
      state.addItem.categoryId = payload;
      state.addItem.attributes = [];
      state.addItem.editItemSubcategories = [];
    },
    setAttributes: (state, { payload }) => {
      state.addItem.attributes = payload;
    },
    setVariablePhoto: (state, { payload }) => {
      const { attributeValue, variableId, photo, photoIndexes } = payload;

      attributeValue.forEach((attributeItem) => {
        const attribute = state.addItem.attributes.find(
          (attr) => attr.id === attributeItem.id
        );

        if (attribute) {
          const variable = attribute.variables.find(
            (variable) => variable.id === variableId
          );

          if (variable) {
            variable.value = { ...variable.value, photo, photoIndexes };
          }
        }
      });
    },
    setAttributePhoto: (state, { payload }) => {
      state.addItem.attributes = payload;
    },
    setPricing: (state, { payload }) => {
      state.addItem.pricing = payload;
    },
    setUrlPhotos: (state, { payload }) => {
      state.addItem.photos = payload;
    },
    setDisplayPhoto: (state, { payload }) => {
      state.addItem.displayPhoto = payload;
    },
    setPrintAreasToStore: (state, { payload }) => {
      state.addItem.printAreas = payload;
    },
    resetAddItem: (state) => {
      state.addItem = {
        name: "",
        nameAr: "",
        price: "",
        description: "",
        descriptionAr: "",
        photos: [],
        displayPhoto: null,
        categoryId: [],
        attributes: [],
        editItemSubcategories: [],
        userCanUploadFile: false,
        isMinOrderQty: false,
        minOrderQty: 0,
        productionTime: 15,
        priceBrake: false,
        buySample: false,
        blankPrice: 0,
        buyPrintedSample: false,
        printPrice: 0,
        additionalPrices: [{ minQuantity: "", discount: "" }],
      };
    },
    setAddItemData: (state, { payload }) => {
      state.addItem = { ...state.addItem, ...payload };
    },
    setSelectedItemCategory: (state, { payload }) => {
      const { id, name } = payload;
      const updatedSelectedItem = {
        ...state.selectedItem.data,
        category: [
          {
            ...state.selectedItem.data.category[0],
            marketplaceCategory: {
              ...state.selectedItem.data.category[0]?.marketplaceCategory,
              id: id,
              name: name,
            },
          },
        ],
      };
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          data: updatedSelectedItem,
        },
      };
    },
    refreshCart: (state, { payload }) => {
      state.cartItems = payload.cartItems;
    },
    addToCart: (state, action) => {
      const {
        id,
        name,
        photo,
        color,
        size,
        uploadLogoUrl,
        itemPrice,
        quantity,
        minQuantity,
        productionTime,
        buyBlank,
        buyPrinted,
        printedQuantity,
        sampleQuantity,
        currency,
        priceBreaks,
        giftCard,
        cartItemId,
        businessUserId,
      } = action.payload;
      const existingItemIndex = state.cartItems.findIndex(
        (item) =>
          item.id === id &&
          item.name === name &&
          item.photo.id === photo.id &&
          item.color.id === color.id &&
          item.size.id === size.id &&
          item.giftCard.id === giftCard.id &&
          item.uploadLogoUrl === uploadLogoUrl &&
          item.minQuantity === minQuantity &&
          item.productionTime === productionTime &&
          item.buyBlank === buyBlank &&
          item.buyPrinted === buyPrinted &&
          item.currency === currency &&
          !item.photosWithLogo
      );

      if (buyBlank) {
        if (existingItemIndex !== -1) {
          state.cartItems[existingItemIndex].sampleQuantity +=
            Number(sampleQuantity);
        } else {
          state.cartItems.push({
            ...action.payload,
            cartItemId,
            sampleQuantity: sampleQuantity,
            printedQuantity: 0,
          });
        }
      } else if (buyPrinted) {
        if (existingItemIndex !== -1) {
          state.cartItems[existingItemIndex].printedQuantity +=
            Number(printedQuantity);
        } else {
          state.cartItems.push({
            ...action.payload,
            cartItemId,
            sampleQuantity: 0,
            printedQuantity: Number(printedQuantity),
          });
        }
      } else if (existingItemIndex !== -1) {
        state.cartItems[existingItemIndex].quantity += Number(quantity);

        let quantityValue = state.cartItems[existingItemIndex].quantity;

        let minQuantityPriceBreak = 0;
        let discount = 0;
        if (priceBreaks) {
          for (const priceBreak of priceBreaks) {
            if (
              quantityValue >= priceBreak.minQuantity &&
              priceBreak.minQuantity > minQuantityPriceBreak
            ) {
              minQuantityPriceBreak = priceBreak.minQuantity;
              discount = priceBreak.discount;
            }
          }
        }
        let finalItemPrice = itemPrice - (itemPrice * discount) / 100;
        state.cartItems[existingItemIndex].itemPrice = finalItemPrice;
        state.cartItems[existingItemIndex].totalPrice =
          finalItemPrice * quantityValue;
      } else {
        let quantityValue = quantity;
        let minQuantityPriceBreak = 0;
        let discount = 0;
        if (priceBreaks) {
          for (const priceBreak of priceBreaks) {
            if (
              quantityValue >= priceBreak.minQuantity &&
              priceBreak.minQuantity > minQuantityPriceBreak
            ) {
              minQuantityPriceBreak = priceBreak.minQuantity;
              discount = priceBreak.discount;
            }
          }
        }
        let finalItemPrice = itemPrice - (itemPrice * discount) / 100;
        state.cartItems.push({
          ...action.payload,
          cartItemId,
          itemPrice: finalItemPrice,
          totalPrice: finalItemPrice * quantityValue,
        });
      }
      const data = {
        businessUserId: businessUserId,
        cartItems: JSON.stringify(state.cartItems),
        environment,
      };

      localStorage.setItem(LOCAL_STORAGE_CART_ITEMS, JSON.stringify(data));
    },
    minusQuantity: (state, action) => {
      let foundItem = false;
      state.cartItems = state.cartItems.map((item) => {
        const itemSubcategories = item.selectedSubcategory.map(
          (subcategory) => subcategory.valueId
        );
        const addedItemSubcategories = action.payload.selectedSubcategory.map(
          (subcategory) => subcategory.valueId
        );

        if (
          item.id === action.payload.id &&
          itemSubcategories.sort().join(",") ===
            addedItemSubcategories.sort().join(",")
        ) {
          item.quantity--;
          foundItem = true;
          if (item.quantity === 0) {
            return null;
          }
        }
        return item;
      });
      state.cartItems = state.cartItems.filter((item) => item !== null);
      if (!foundItem) {
        state.cartItems.push({ ...action.payload, quantity: 1 });
      }

      // localStorage.setItem(
      //   LOCAL_STORAGE_CART_ITEMS,
      //   JSON.stringify(state.cartItems)
      // );
    },
    plusQuantity: (state, action) => {
      let foundItem = false;
      state.cartItems = state.cartItems.map((item) => {
        const itemSubcategories = item.selectedSubcategory.map(
          (subcategory) => subcategory.valueId
        );
        const addedItemSubcategories = action.payload.selectedSubcategory.map(
          (subcategory) => subcategory.valueId
        );

        if (
          item.id === action.payload.id &&
          itemSubcategories.sort().join(",") ===
            addedItemSubcategories.sort().join(",")
        ) {
          item.quantity++;
          foundItem = true;
        }
        return item;
      });
      if (!foundItem) {
        state.cartItems.push({ ...action.payload, quantity: 1 });
      }
      // localStorage.setItem(
      //   LOCAL_STORAGE_CART_ITEMS,
      //   JSON.stringify(state.cartItems)
      // );
    },
    changePackagingQuantity: (state, action) => {
      state.cartItems = state.cartItems.map((item) => {
        if (item.isPackaging) {
          item.quantity = action.payload;
          item.totalPrice = item.itemPrice * action.payload;
        }

        return item;
      });

      const data = {
        // businessUserId: businessUserId,
        cartItems: JSON.stringify(state.cartItems),
        environment,
      };

      localStorage.setItem(LOCAL_STORAGE_CART_ITEMS, JSON.stringify(data));
    },
    removeCartItem: (state, action) => {
      const indexToRemove = action.payload.index;
      state.cartItems = state.cartItems.filter(
        (item, index) => index !== indexToRemove
      );
      const data = {
        businessUserId: action.payload.businessUserId,
        cartItems: JSON.stringify(state.cartItems),
        environment,
      };
      localStorage.setItem(LOCAL_STORAGE_CART_ITEMS, JSON.stringify(data));
    },
    editOrderQuantity: (state, { payload }) => {
      state.addItem = { ...state.addItem, minOrderQty: payload };
    },
    setCreateOrderDelivery: (state, { payload }) => {
      state.createOrder = {
        ...state.createOrder,
        fullName: payload.fullName,
        mobileNumber: payload.mobileNumber,
        addressDetails: payload.addressDetails,
        deliveryAddress: payload.deliveryAddress,
      };
    },
    setCreateOrderSubTotal: (state, { payload }) => {
      state.createOrder = {
        ...state.createOrder,
        subTotal: payload,
      };
    },
    setCreateOrderDiscountCode: (state, { payload }) => {
      state.createOrder = {
        ...state.createOrder,
        discountCode: payload.code,
        discountPercentage: payload.percentage,
      };
    },
    setCreateOrderNotes: (state, { payload }) => {
      state.createOrder = {
        ...state.createOrder,
        notes: payload,
      };
    },
    resetMarketplaceOrderData: (state, action) => {
      state.createOrder = {
        fullName: "",
        mobileNumber: "",
        addressDetails: "",
        deliveryAddress: {},
        subTotal: null,
        discountCode: null,
        discountPercentage: null,
      };
      state.cartItems = [];
      localStorage.removeItem(LOCAL_STORAGE_CART_ITEMS);
    },
    setEditItemData: (state, { payload }) => {
      state.editItemData = {
        ...payload.product,
        index: payload.index,
      };
    },
    resetEditItemData: (state) => {
      state.editItemData = {
        index: null,
      };
    },
    editCartItem: (state, action) => {
      const { indexToRemove, newItem, businessUserId } = action.payload;

      let quantityValue = newItem.quantity;
      let minQuantityPriceBreak = 0;
      let discount = 0;
      if (newItem.priceBreaks) {
        for (const priceBreak of newItem.priceBreaks) {
          if (
            quantityValue >= priceBreak.minQuantity &&
            priceBreak.minQuantity > minQuantityPriceBreak
          ) {
            minQuantityPriceBreak = priceBreak.minQuantity;
            discount = priceBreak.discount;
          }
        }
      }
      let finalItemPrice =
        newItem.itemPrice - (newItem.itemPrice * discount) / 100;

      state.cartItems = [
        ...state.cartItems.slice(0, indexToRemove),
        {
          ...newItem,
          itemPrice: finalItemPrice,
          totalPrice: finalItemPrice * quantityValue,
        },
        ...state.cartItems.slice(indexToRemove + 1),
      ];
      const data = {
        businessUserId,
        cartItems: JSON.stringify(state.cartItems),
        environment,
      };
      localStorage.setItem(LOCAL_STORAGE_CART_ITEMS, JSON.stringify(data));
    },
    setSampleQuantity: (state, { payload }) => {
      state.addItem = {
        ...state.createOrder,
        notes: payload,
      };
    },
    setPrintedQuantity: (state, { payload }) => {
      state.addItem = {
        ...state.addItem,
        notes: payload,
      };
    },
    resetSelectedItem: (state, { payload }) => {
      state.selectedItem = {
        data: { subcategories: {} },
        loading: false,
      };
    },
    toggleFavoriteItem: (state, { payload }) => {
      const { id, like, businessUserId } = payload;
      const updatedCartData = state.cartItems.map((item) => {
        if (item.id === id) {
          return { ...item, favorite: !item.favorite };
        }
        return item;
      });

      state.getFavoriteItems.count += like ? 1 : -1;
      state.cartItems = updatedCartData;
      const data = {
        businessUserId,
        cartItems: JSON.stringify(updatedCartData),
        environment,
      };
      localStorage.setItem(LOCAL_STORAGE_CART_ITEMS, JSON.stringify(data));
    },

    addPhotoToVariable: (state, action) => {
      const { attributeId, variableId, photo } = action.payload;
      const attributeIndex = state.addItem.attributes.findIndex(
        (attr) => attr.id === attributeId
      );
      if (attributeIndex !== -1) {
        const attribute = state.addItem.attributes[attributeIndex];
        const variableIndex = attribute.variables.findIndex(
          (variable) => variable.id === variableId
        );
        if (variableIndex !== -1) {
          const variable = attribute.variables[variableIndex];
          state.addItem.attributes[attributeIndex].variables[variableIndex] = {
            ...variable,
            value: { ...variable.value, photo },
          };
        }
      }
    },
  },

  extraReducers: (builder) => {
    getOrdersBuilder(builder);
    getSubcategoriesBuilder(builder);
    getOrderBuilder(builder);
    updateOrderStateBuilder(builder);
    getCategoriesBuilder(builder);
    createItemBuilder(builder);
    getMarketplaceItemByIdBuilder(builder);
    getFavoriteMarketplaceItemsAllBuilder(builder);
    getSubcategoriesListBuilder(builder);
    deleteVariableBuilder(builder);
    deleteSubcategoryBuilder(builder);
    deleteCategoryBuilder(builder);
    createVariableBuilder(builder);
    getCountFavoriteMarketplaceItemsBuilder(builder);
    toggleFavoriteMarketplaceItemBuilder(builder);
    createSubcategoryBuilder(builder);
    createCategoryBuilder(builder);
    editCategoryBuilder(builder);
    editSubcategoryBuilder(builder);
    editVariableBuilder(builder);
    updateCategoryPriorityBuilder(builder);
    getAttributesByCategoryBuilder(builder);
    fetchAllMarketplaceItems(builder);
    deleteItemBuilder(builder);
    getSuperAdminOrdersBuilder(builder);
    shareCartProductBuilder(builder);
    generatePhotosWithLogoBuilder(builder);
    getOrderPackagesBuilder(builder);
    builder.addCase("reset", (state) => ({
      ...initialState,
      cartItems: state.cartItems,
    }));
  },
});

export const {
  removeItem,
  setCategories,
  addPhotoToVariable,
  setAttributes,
  setVariablePhoto,
  setPricing,
  setCategory,
  setName,
  setArabicName,
  setArabicDescription,
  setDescription,
  setPrice,
  setUrlPhotos,
  setDisplayPhoto,
  resetAddItem,
  setAddItemData,
  setPrintedPrice,
  setSamplePrice,
  setPriceBreaks,
  setMinQuantity,
  addToCart,
  minusQuantity,
  removeCartItem,
  plusQuantity,
  editOrderQuantity,
  setCreateOrderDelivery,
  setCreateOrderSubTotal,
  resetMarketplaceOrderData,
  setEditItemData,
  resetEditItemData,
  editCartItem,
  setCreateOrderNotes,
  setCreateOrderDiscountCode,
  resetSelectedItem,
  toggleFavoriteItem,
  setPrintAreasToStore,
  refreshCart,
  changePackagingQuantity,
} = marketplaceSlice.actions;

export const orders = (state) => state.marketplace.getOrders;
export const ordersData = (state) => state.marketplace.getOrder.data;
export const orderDataState = (state) => state.marketplace.getOrder.data.state;
export const orderDataPaymentStatus = (state) =>
  state.marketplace.getOrder.data.paymentStatus;
export const marketplaceOrdersLoader = (state) =>
  state.marketplace.getOrders.loading;
export const selectFavoriteItemsData = (state) =>
  state.marketplace.getFavoriteItems.data;
export const selectFavoriteItems = (state) =>
  state.marketplace.getFavoriteItems;
export const selectFavoriteToggleLoading = (state) =>
  state.marketplace.getFavoriteItems.selectedItem.loading;
export const marketplaceOrderLoader = (state) =>
  state.marketplace.getOrder.loading;
export const marketplaceOrdersAdminLoader = (state) =>
  state.marketplace.ordersAdmin.loading;
export const subcategoriesData = (state) =>
  state.marketplace.subcategories.data;
export const categoriesData = (state) => state.marketplace.categories.data;
export const categoriesLoading = (state) =>
  state.marketplace.categories.loading;
export const selectedMarketplaceItem = (state) =>
  state.marketplace.selectedItem.data;
export const selectedMarketplaceItemLoader = (state) =>
  state.marketplace.selectedItem.loading;
export const createItemLoader = (state) => state.marketplace.item.loading;
export const subcategoriesListData = (state) =>
  state.marketplace.subcategoriesList.data;
export const addItemCategoryId = (state) =>
  state.marketplace.addItem.categoryId;
export const selectAddItem = (state) => state.marketplace.addItem;
export const addItemPrice = (state) => state.marketplace.addItem.price;
export const addItemDescription = (state) =>
  state.marketplace.addItem.description;
export const addItemArabicDescription = (state) =>
  state.marketplace.addItem.descriptionAr;
export const addItemName = (state) => state.marketplace.addItem.name;
export const addItemArabicName = (state) => state.marketplace.addItem.nameAr;
export const addItemPhotos = (state) => state.marketplace.addItem.photos;

export const addItemPrintAreas = (state) =>
  state.marketplace.addItem.printAreas;

export const addItemDisplayPhoto = (state) =>
  state.marketplace.addItem.displayPhoto;
export const addItemState = (state) => state.marketplace.addItem;
export const addItemAttributes = (state) =>
  state.marketplace.addItem.attributes;
export const attributesByCategory = (state) =>
  state.marketplace.getAttributesByCategory.data;
export const attributesByCategoryLoading = (state) =>
  state.marketplace.getAttributesByCategory.loading;

export const selectDeleteCategoryPending = (state) =>
  state.marketplace.deleteCategory.loading;
export const selectEditCategoryPending = (state) =>
  state.marketplace.editCategory.loading;
export const selectCreateCategoryPending = (state) =>
  state.marketplace.createCategory.loading;
export const selectSubcategoriesListPending = (state) =>
  state.marketplace.subcategoriesList.loading;

export const selectCreateSubcategoryPending = (state) =>
  state.marketplace.createSubcategory.loading;
export const selectEditSubcategoryPending = (state) =>
  state.marketplace.editSubcategory.loading;
export const selectDeleteSubcategoryPending = (state) =>
  state.marketplace.deleteSubcategory.loading;

export const selectCreateVariablePending = (state) =>
  state.marketplace.createVariable.loading;
export const selectEditVariablePending = (state) =>
  state.marketplace.editVariable.loading;
export const selectDeleteVariablePending = (state) =>
  state.marketplace.deleteVariable.loading;

export const cartItemsData = (state) => state.marketplace.cartItems;
export const selectMarketplaceItems = (state) =>
  state.marketplace.marketplaceItems;
export const marketplaceItemsLoader = (state) =>
  state.marketplace.marketplaceItems.loading;
export const deleteMarketplaceItemLoader = (state) =>
  state.marketplace.deleteMarketplaceItemPending;
export const selectCreateOrder = (state) => state.marketplace.createOrder;
export const selectCreateOrderNotes = (state) =>
  state.marketplace.createOrder.notes;
export const selectSuperAdminOrders = (state) =>
  state.marketplace.getSuperAdminOrders;
export const loadingSendCartProductEmail = (state) =>
  state.marketplace.sendCartProductEmail.loading;
export const selectEditItemData = (state) => state.marketplace.editItemData;
export const addItemSampleQuantity = (state) =>
  state.marketplace.addItem.sampleQuantity;
export const addItemPrintedQuantity = (state) =>
  state.marketplace.addItem.printedQuantity;
export const selectMarketplaceItemsLoading = (state) =>
  state.marketplace.marketplaceItems.loading;

export const selectGeneratePhotosWithLogoPending = (state) =>
  state.marketplace.generatePhotosWithLogoPending;

export const selectOrderPackages = (state) => state.marketplace.orderPackages;

export default marketplaceSlice.reducer;
