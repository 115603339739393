import React from "react";
import { Message, Button, MessageContainer } from "../Steps.styles";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AddItemStepComplete = ({ action }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <MessageContainer>
      <Message>
        {action === "create"
          ? t("product-has-been-added")
          : t("product-has-been-edited")}
      </Message>
      <Button onClick={() => navigate("/admin-portal/marketplace")}>
        {t("go-to-marketplace")}
      </Button>
    </MessageContainer>
  );
};

export default AddItemStepComplete;
