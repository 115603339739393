import React, { useEffect, useState } from "react";
import {
  MainContainer,
  Column,
  Content,
  Text,
  TextContainer,
  TitleContainer,
  Message,
  SectionTitle,
  StyledAHref,
  LoaderDiv,
  HeaderWrapper,
  TableBodyRow,
  FirstItem,
  LastTableItem,
} from "./OrderHistoryPage.styles";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ReactComponent as Open } from "../../../assets/icons/open.svg";

import { getSuperAdminOrders } from "../../../store/slices/marketplace/asyncThunks";
import { getAllCompanyGiftingAdmin } from "../../../store/slices/gift/asyncThunks";
import { selectSuperAdminOrders } from "../../../store/slices/marketplace/marketplaceSlice";
import {
  giftsOrdersAdmin,
  giftsOrdersAdminLoader,
} from "../../../store/slices/gift/slice";
import { formatDate, priceFormatter } from "../../../utils/utils";
import { getCurrencySymbol } from "../../../utils/constants";
import Sidebar from "../utils/Sidebar";
import MiniLoader from "../../../components/MiniLoader/MiniLoader";
import Header from "../../../components/Header/Header";
import TableItem from "../../../components/Table/TableItem";
import Table from "../../../components/Table/Table";
import { selectedLocale } from "../../../store/slices/common/slice";

const OrderHistoryPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const ordersMarketplace = useSelector(selectSuperAdminOrders);
  const ordersGifts = useSelector(giftsOrdersAdmin);
  const giftOrdersLoading = useSelector(giftsOrdersAdminLoader);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const marketplacePage = queryParams.get("marketplacePage");
  const giftsPage = queryParams.get("giftsPage");
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    dispatch(
      getSuperAdminOrders({
        page: marketplacePage,
        sortBy: "createdAt",
        order: "DESC",
      })
    )
      .unwrap()
      .catch((err) => toast.error(t(err.message)));
  }, [dispatch, marketplacePage]);

  useEffect(() => {
    dispatch(
      getAllCompanyGiftingAdmin({
        page: giftsPage,
        sortBy: "createdAt",
        order: "DESC",
      })
    )
      .unwrap()
      .catch((err) => toast.error(t(err.message)));
  }, [dispatch, giftsPage]);

  useEffect(() => {
    if (ordersMarketplace.loading && giftOrdersLoading) {
      setLoader(true);
    }
    if (!ordersMarketplace.loading && !giftOrdersLoading) {
      setLoader(false);
    }
    if (!ordersMarketplace.loading && giftOrdersLoading) {
      setLoader(false);
    }
    if (ordersMarketplace.loading && !giftOrdersLoading) {
      setLoader(false);
    }
  }, [giftOrdersLoading, ordersMarketplace.loading]);

  const handleGiftOrderDetailsClick = (id) => {
    navigation(`/admin-portal/order-history/gift-order-details/${id}`);
  };

  const handleMarketplaceOrderDetailsClick = (id) => {
    navigation(`/admin-portal/order-history/marketplace-order-details/${id}`);
  };

  const handlePageChange = (pageNumber, paramName) => {
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set(paramName, pageNumber);

    const url = `/admin-portal/order-history?${currentParams.toString()}`;
    navigation(url);
  };

  const tableHeaderMarketplace = [
    {
      label: `${t("id")}`,
      sortable: true,
      key: "id",
    },
    {
      label: `${t("company")}`,
      sortable: true,
      key: "company",
    },
    {
      label: `${t("state")}`,
      sortable: true,
      key: "state",
    },
    {
      label: `${t("date-of-delivery")}`,
      sortable: true,
      key: "dateOfDelivery",
    },
    {
      label: `${t("total-price")}`,
      sortable: true,
      key: "totalPrice",
    },
    {
      label: `${t("receipt-url")}`,
      sortable: true,
      key: "recipientUrl",
    },
    {
      label: `${t("payment-type")}`,
      sortable: true,
      key: "paymentType",
    },
    {
      label: `${t("payment-status")}`,
      sortable: true,
      key: "paymentStatus",
    },
    {
      label: `${t("address-details")}`,
      sortable: true,
      key: "addressDetails",
    },
    {
      label: `${t("notes")}`,
      sortable: true,
      key: "notes",
    },
  ];
  const tableHeaderGifts = [
    {
      label: `${t("id")}`,
      sortable: true,
      key: "id",
    },
    {
      label: `${t("company")}`,
      sortable: true,
      key: "company",
    },
    {
      label: `${t("status")}`,
      sortable: true,
      key: "status",
    },
    {
      label: `${t("created")}`,
      sortable: true,
      key: "created",
    },
    {
      label: `${t("order-budget")}`,
      sortable: true,
      key: "orderBudget",
    },
    {
      label: `${t("occasions")}`,
      sortable: true,
      key: "occasions",
    },
    {
      label: `${t("delivery-location")}`,
      sortable: true,
      key: "deliveryLocation",
    },
    {
      label: `${t("address-details")}`,
      sortable: true,
      key: "addressDetails",
    },
  ];

  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";

  return (
    <MainContainer>
      <HeaderWrapper>
        <Header
          showLogin
          showSignUp
          showNav
          color
          showUser
          showFavorite
          showCart
        />
      </HeaderWrapper>
      <Sidebar />

      <Column $ar={ar}>
        <SectionTitle>{t("order-history")}</SectionTitle>
        {!loader ? (
          <>
            {!ordersMarketplace.loading ? (
              <>
                {ordersMarketplace?.data?.length <= 0 ? (
                  <Content>
                    <TextContainer>
                      <Message>
                        {t(
                          "you-currently-have-no-marketplace-orders-in-history"
                        )}
                      </Message>
                    </TextContainer>
                  </Content>
                ) : (
                  <>
                    <TitleContainer>
                      <Text>
                        {t("marketplace-order-history")} (
                        {ordersMarketplace.total})
                      </Text>
                    </TitleContainer>

                    <Table
                      marginTop={40}
                      headerInformation={tableHeaderMarketplace}
                      totalCount={ordersMarketplace.total}
                      currentPage={+marketplacePage}
                      setPage={handlePageChange}
                      perPage={ordersMarketplace.perPage}
                      noDataContent={t("no-data")}
                      dataLoading={false}
                      param={"marketplacePage"}
                      lastColumnAligned
                      orderHistory
                      orderHistoryMarketplace
                      centerText
                    >
                      {ordersMarketplace.data.map((val, key) => {
                        return (
                          <TableBodyRow
                            key={key}
                            onClick={() =>
                              handleMarketplaceOrderDetailsClick(val.id)
                            }
                          >
                            <FirstItem data={val.id} />
                            <TableItem
                              hoverText={val.company?.name}
                              showHover
                              data={
                                val.company?.name.length > 30
                                  ? val.company?.name.slice(0, 30) + "..."
                                  : val.company?.name
                              }
                              center
                            />
                            <TableItem data={t(val.state)} center />
                            <TableItem
                              data={
                                val.dateOfDelivery
                                  ? formatDate(val.dateOfDelivery)
                                  : "/"
                              }
                              center
                            />
                            <TableItem
                              center
                              data={
                                priceFormatter(val.totalPrice, false, 0) +
                                " " +
                                getCurrencySymbol(val.currency)
                              }
                            />
                            <TableItem
                              center
                              data={
                                val.receiptUrl === null ? (
                                  <Open className="details-icon" />
                                ) : (
                                  <StyledAHref
                                    href={val.receiptUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Open className="details-icon" />
                                  </StyledAHref>
                                )
                              }
                            />
                            <TableItem center data={t(val.paymentType)} />
                            <TableItem center data={t(val.paymentStatus)} />
                            <TableItem
                              center
                              data={val.addressDetails || "/"}
                            />
                            <LastTableItem data={val.notes || "/"} $offClick />
                          </TableBodyRow>
                        );
                      })}
                    </Table>
                  </>
                )}
              </>
            ) : (
              <div
                style={{
                  height: 822,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <MiniLoader />
              </div>
            )}

            {!giftOrdersLoading ? (
              <>
                {ordersGifts?.data?.length <= 0 ? (
                  <Content>
                    <TextContainer>
                      <Message>
                        {t("you-currently-have-no-gift-orders-in-history")}
                      </Message>
                    </TextContainer>
                  </Content>
                ) : (
                  <>
                    <TitleContainer style={{ marginTop: 40 }}>
                      <Text>
                        {t("gifts-order-history")} ({ordersGifts.total})
                      </Text>
                    </TitleContainer>

                    <Table
                      marginTop={40}
                      headerInformation={tableHeaderGifts}
                      totalCount={ordersGifts.total}
                      currentPage={+giftsPage}
                      setPage={handlePageChange}
                      perPage={ordersGifts.perPage}
                      noDataContent={t("no-data")}
                      dataLoading={false}
                      param={"giftsPage"}
                      lastColumnAligned
                      centerText
                      orderHistory
                    >
                      {ordersGifts?.data.map((val, key) => {
                        return (
                          <TableBodyRow
                            key={key}
                            $offClick
                            onClick={() => handleGiftOrderDetailsClick(val.id)}
                          >
                            <FirstItem data={val.id} />
                            <TableItem
                              hoverText={val?.company?.name}
                              showHover
                              data={
                                val?.company?.name?.length > 30
                                  ? val.company?.name.slice(0, 30) + "..."
                                  : val.company?.name
                              }
                              center
                            />
                            <TableItem data={t(val.status)} center />
                            <TableItem
                              data={formatDate(val.createdAt)}
                              center
                            />
                            <TableItem
                              data={val.orderMaxBudget + " SR"}
                              center
                            />

                            <TableItem data={t(val.occasion)} center />
                            <TableItem data={t(val.deliveryLocation)} center />
                            <LastTableItem data={val.addressDetails || "/"} />
                          </TableBodyRow>
                        );
                      })}
                    </Table>
                  </>
                )}
              </>
            ) : (
              <div
                style={{
                  height: 822,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <MiniLoader />
              </div>
            )}
          </>
        ) : (
          <LoaderDiv>
            <MiniLoader style={{ padding: "100px 0px" }} />
          </LoaderDiv>
        )}
      </Column>
    </MainContainer>
  );
};

export default OrderHistoryPage;
