import { styled } from "styled-components";
import { ReactComponent as ChevronSvg } from "../../../../assets/icons/chevron-right.svg";

export const Chevron = styled(ChevronSvg)`
  fill: #55a1e8;

  transform: ${(props) => (props.$ar ? "rotate(180deg)" : "unset")};
`;
export const MainContainer = styled.div`
  transition: all 0.2s ease-in-out;
  position: relative;
  z-index: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${(props) => (props.$upgradePackage ? "40px" : "0px")};
  background: #f6f6f9;
`;

export const HeaderWrapper = styled.div`
  background: #8a4b5e;
  position: fixed;
  z-index: 1000;
  width: 100%;
`;
export const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  background: white;

  justify-content: flex-start;
  padding: 12rem 3rem;
`;

export const ScreenContent = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;
  height: 93.9rem;

  border-radius: 3rem;
  padding: 3rem 0px;

  padding: 3rem;

  align-items: center;
  gap: 10rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 1375px) {
    height: 100%;
  }
`;
export const Slider = styled.input`
  width: 1000px;
  @media (max-width: 1100px) {
    width: 90%;
  }
`;
export const PaymentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4rem;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
export const HeaderText = styled.span`
  color: #8a4b5e;

  text-align: center;
  font-family: "Avenir";
  font-size: 5rem;
  font-weight: 800;
  letter-spacing: -0.178px;
`;
export const LargeText = styled(HeaderText)`
  font-size: 4rem;
  font-weight: 400;
  @media (max-width: 410px) {
    font-size: 3rem;
  }
`;
export const Link = styled(LargeText)`
  color: #55a1e8;
`;
export const Linked = styled(LargeText)`
  color: #55a1e8;
  text-decoration: underline;
  cursor: pointer;
`;
export const Container = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 120px;
  flex-direction: column;
`;

export const Screen = styled.div`
  padding: 2rem 3rem 1rem 3rem;
  position: relative;
  flex-direction: column;
  height: fit-content;
  height: 100%;
  width: 100%;
`;

export const Title = styled.span`
  justify-content: center;
  display: flex;
  user-select: none;
  flex-wrap: wrap;
  font-family: "FrankfurterEF-Medium", sans-serif;
  font-size: 4.8rem;
  text-align: center;
  line-height: 5.5rem;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  @media (max-width: 700px) {
    font-size: 3.4rem;
    line-height: 3.5rem;
    width: 20rem;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const StyledTitle = styled.span`
  justify-content: center;
  display: flex;
  text-align: center;
  user-select: none;
  flex-wrap: wrap;
  font-family: "Avenir-Medium";
  font-size: 4rem;

  opacity: 1;

  letter-spacing: -0.177777px;

  @media (max-width: 400px) {
    font-size: 3rem;
  }
`;

export const Success = styled.img`
  background-color: transparent;
  width: 6.5rem;
  user-select: none;
  height: 6.5rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 75rem;
  flex-direction: row;
  gap: 5.8rem;
  align-items: center;
  width: 100%;

  @media (max-width: 760px) {
    width: 90%;
  }
  @media (max-width: 490px) {
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;

export const StepContainer = styled(TitleContainer)`
  flex-direction: row;
  gap: 15rem;
  @media (max-width: 760px) {
    gap: unset;
  }
  @media (max-width: 605px) {
    gap: 3rem;
    flex-direction: column;
  }
`;

export const Step = styled(TitleContainer)`
  gap: 1.2rem;
`;

export const Subtitle = styled.span`
  color: #8a4b5e;
  font-family: "Avenir-Medium";
  font-size: 1.8rem;
  text-align: center;
  letter-spacing: -0.178px;
  user-select: none;
`;
export const StepCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
  background-color: #8a4b5e;

  color: #fff;
  font-family: "Avenir-Medium";
  font-size: 2rem;
  letter-spacing: -0.178px;
`;
export const StyledStepCircle = styled(StepCircle)`
  background-color: #ececf4;
  color: #bababa;
`;

export const StyledSubtitle = styled(Subtitle)`
  color: rgba(69, 69, 132, 0.5);
`;

export const Skip = styled.span`
  color: #55a1e8;
  font-family: "Avenir-Medium";
  font-size: 2.4rem;
  user-select: none;
  letter-spacing: -0.178px;
  text-decoration-line: underline;
`;

export const SkipContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  align-items: center;
`;

export const Row = styled.div`
  display: flex;

  cursor: pointer;
  flex-direction: row;
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); */
  border-radius: 1rem;
  width: 10rem;
  padding-left: 1rem;
  align-items: center;
  display: flex;
  z-index: 100;
  justify-content: center;
  @media (max-width: 565px) {
    top: 8rem;
  }
`;
export const StyledRow = styled(Row)`
  right: 0px;
  left: 25px;
`;
export const Text = styled.span`
  color: #8a4b5e;
  text-align: center;
  font-family: "Avenir";
  font-size: 2.6rem;
  font-weight: 400;
  letter-spacing: -0.178px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const StyledText = styled(Text)`
  font-weight: 600;
`;
export const Invisible = styled.div`
  background: transparent;
  mix-blend-mode: normal;
  padding: 6px 13px;
`;
export const InvisibleText = styled.span`
  user-select: none;
  font-family: "Avenir-Black";
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.177777px;
  color: transparent;
`;
export const Discount = styled.span`
  font-family: "Avenir-Black";
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.177777px;
  color: #1ab900;
`;
export const DiscountContainer = styled.div`
  background: #fff;
  mix-blend-mode: normal;
  padding: 6px 13px;
`;
export const StyledDate = styled.span`
  font-family: "Avenir-Black";
  font-style: normal;

  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
`;
export const Row2 = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
export const Payments = styled.span`
  color: #8a4b5e;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.178px;
`;
export const Input = styled.input`
  padding: 0.5rem 0.7rem;
  border-radius: 1.5rem;
  border: 1px solid #8c8c8c;
  height: 100%;
  background: #fff;
  color: rgba(69, 69, 132, 1);
  font-family: "Avenir-Medium";
  font-size: 1.8rem;
  letter-spacing: -0.178px;
`;
export const Gap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  @media (max-width: 750px) {
    padding: 0px 20px;
  }
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  height: 4.4rem;
  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const Button = styled.button`
  border-radius: 1.5rem;
  cursor: pointer;
  height: 4.4rem;
  user-select: none;
  border: none;
  outline: none;
  background: #8a4b5e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  text-align: center;
  font-family: "FrankfurterEF-Medium";

  font-size: 1.8rem;
  letter-spacing: -0.178px;
  &:active {
    scale: 0.95;
    transition: all 0.2s ease-in-out;
  }
`;
export const StyledTextSmall = styled(Text)`
  font-size: 2rem;
  font-weight: 300;
`;
export const GreenText = styled(StyledText)`
  color: #1ab900;
`;
export const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const DiscountText = styled.div`
  font-size: 14px;
  font-family: Avenir-Black;
  color: #1ab900;
`;

export const ErrorContainer = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Form = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 40px 60px 40px;
  flex-direction: column;
  gap: 30px;
  @media (max-width: 450px) {
    gap: 15px;
  }
`;

export const Message = styled.span`
  color: #8a4b5e;
  text-align: center;
  font-family: "Avenir";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.178px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding: 0px 40px;
  width: 100%;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FeaturesContainer = styled.div`
  display: flex;
  margin-top: 10rem;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const FeaturesTitles = styled.div`
  gap: 3rem;
  display: flex;
  flex-direction: column;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    props.$ar ? "translateX(150%)" : "translateX(-150%)"};

  opacity: 0;
  @media (max-width: 400px) {
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
`;

export const Feature = styled.span`
  font-family: "Avenir-Medium";
  font-size: 3rem;

  padding: 2rem 0rem;
  border-bottom: 1px solid #8a4b5e;
`;

export const FeatureTitle = styled(Feature)`
  font-family: "FrankfurterEF-Medium";
  font-size: 2.5rem;
  max-height: 70px;
  align-items: center;
  display: flex;

  letter-spacing: -0.18px;
  color: #000;
`;

export const Card = styled.div`
  border: 1px solid rgb(1, 1, 1, 0.15);
  width: 291px;
  gap: 3rem;
  height: 690px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  position: relative;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(1, 0, 1, 0.1);
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    props.$ar ? "translateX(-150%)" : "translateX(150%)"};

  opacity: 0;
  @media (max-width: 400px) {
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
`;

export const Name = styled.span`
  font-family: "FrankfurterEF-Medium";
  font-size: 4rem;
  letter-spacing: -0.18px;
  text-align: center;
  padding: 2rem 0rem;

  color: #8a4b5e;
  border-bottom: 1px solid transparent;
`;

export const Value = styled.span`
  font-family: "Avenir-Medium";
  border-bottom: 1px solid transparent;
  font-size: 2rem;
  padding: 2rem 0rem;

  text-align: center;
  letter-spacing: -0.18px;
`;

export const Image = styled.img`
  width: 42px;
  height: 42px;
`;

export const Wrapper = styled.div`
  padding: 2rem 0rem;
  max-height: 69.75px;
  align-items: center;
  display: flex;
  border-bottom: 1px solid transparent;
  justify-content: center;
`;
