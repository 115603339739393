import React, { useRef, useState } from "react";
import {
  ContentContainer,
  Text,
  Row,
  ButtonBack,
  Buttons,
  Category,
  Value,
  RowCategory,
  Categories,
  AddCategoryContainer,
  Input,
  IconsContainer,
} from "./CategoriesPage.styles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Add } from "../../../../assets/icons/add-filled.svg";
import { ReactComponent as Drag } from "../../../../assets/icons/drag.svg";
import UseAnimations from "react-useanimations";
import trash2 from "react-useanimations/lib/trash2";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import {
  createCategory,
  deleteCategory,
  editCategory,
  updateCategoryPriority,
} from "../../../../store/slices/marketplace/asyncThunks";
import {
  selectCreateCategoryPending,
  selectDeleteCategoryPending,
  selectEditCategoryPending,
  setCategories,
} from "../../../../store/slices/marketplace/marketplaceSlice";
import { ReactComponent as EditPencil } from "../../../../assets/icons/edit-pencil.svg";

import ButtonComponent from "../../../../components/ButtonAdd/ButtonAdd";
import { useGetCategoriesQuery } from "../../../../store/slices/marketplace/marketplaceApi";
import { api } from "../../../../store/api";
import openToast from "../../../../utils/toast";
import AppLoader from "../../../../components/AppLoader";

const CategoryStep1 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [editFormShow, setEditFormShow] = useState(false);
  const [editingCategoryId, setEditingCategoryId] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const createCategoryLoading = useSelector(selectCreateCategoryPending);
  const editCategoryLoading = useSelector(selectEditCategoryPending);
  const deleteCategoryLoading = useSelector(selectDeleteCategoryPending);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const toastId = useRef(null);

  const { data: categories, isFetching: categoriesPending } =
    useGetCategoriesQuery({ search: "" });

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const updatedCategories = [...categories?.data?.categories];

    const [movedCategory] = updatedCategories.splice(source.index, 1);
    updatedCategories.splice(destination.index, 0, movedCategory);

    const newPriorities = updatedCategories.map((category, index) => ({
      priority: index,
      categoryId: category.id,
    }));
    dispatch(setCategories(updatedCategories));
    dispatch(updateCategoryPriority({ newPriorities: newPriorities }))
      .unwrap()
      .catch((err) =>
        openToast(toastId, "update-category-priority", "error", t(err.message))
      );
  };

  const handleCategoryDelete = (categoryId) => {
    setSelectedCategory(categoryId);
    dispatch(deleteCategory(categoryId))
      .unwrap()
      .then(() => {
        setSelectedCategory(categoryId);
        toast.success(t("category-deleted-successfully"));
        dispatch(api.util.invalidateTags(["categories"]));
      })
      .catch((error) => {
        console.log(error);
        openToast(toastId, "delete-category", "error", t(error.message));
      });
  };

  const handleAddCategory = () => {
    if (!newCategoryName) {
      openToast(toastId, "add-category", "error", t("fill-category-name"));
      return;
    }
    dispatch(createCategory(newCategoryName))
      .unwrap()
      .then(() => {
        toast.success(t("category-added-successfully"));
        setNewCategoryName("");
        dispatch(api.util.invalidateTags(["categories"]));
        setShow(false);
      })
      .catch((error) => {
        openToast(toastId, "add-category", "error", t(error.message));
      });
  };

  const handleCancel = () => {
    setShow(false);
    setNewCategoryName("");
  };

  const handleEditCancel = () => {
    setEditFormShow(false);
    setEditingCategoryId(null);
  };

  const handleEditCategory = (categoryId) => {
    if (categories) {
      setEditingCategoryId(null);
      const selectedCategory = categories?.data?.categories?.find(
        (category) => category.id === categoryId
      );
      if (selectedCategory) {
        setEditFormShow(true);
        setCategoryName(selectedCategory.name);
        setEditingCategoryId(selectedCategory.id);
      }
    }
  };

  const handleUpdateAction = () => {
    if (!categoryName) {
      openToast(toastId, "update-category", "error", t("fill-category-name"));
      return;
    }

    dispatch(
      editCategory({
        name: categoryName,
        categoryId: editingCategoryId,
      })
    )
      .unwrap()
      .then(() => {
        handleEditCancel();
        toast.success(t("category-updated-successfully"));
        dispatch(api.util.invalidateTags(["categories"]));
      })
      .catch((error) => {
        openToast(toastId, "update-category", "error", t(error.message));
      });
  };

  return (
    <>
      <ContentContainer>
        <Row>
          <Text>{t("categories.categories")}:</Text>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="categories">
              {(provided) => (
                <Categories
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {categoriesPending ? (
                    <AppLoader />
                  ) : (
                    categories?.data?.categories?.map((category, index) => (
                      <Draggable
                        key={category.id}
                        draggableId={category.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <RowCategory
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Drag />
                            <Category>
                              <Value>{category.name}</Value>
                            </Category>
                            <IconsContainer>
                              {deleteCategoryLoading &&
                              selectedCategory === category.id ? (
                                <AppLoader />
                              ) : (
                                <UseAnimations
                                  className="pointer"
                                  animation={trash2}
                                  size={32}
                                  strokeColor="#8A4B5E"
                                  onClick={() =>
                                    handleCategoryDelete(category.id)
                                  }
                                />
                              )}
                              <EditPencil
                                className="edit-icon"
                                fill="#8A4B5E"
                                onClick={() => {
                                  setEditFormShow(true);
                                  handleEditCategory(category.id);
                                  setShow(false);
                                }}
                                style={{
                                  marginTop: 6,
                                  width: 26,
                                  height: 26,
                                  cursor: "pointer",
                                }}
                              />
                            </IconsContainer>
                          </RowCategory>
                        )}
                      </Draggable>
                    ))
                  )}
                  {provided.placeholder}
                </Categories>
              )}
            </Droppable>
          </DragDropContext>
          <IconsContainer style={{ marginTop: 3 }}>
            <Add
              onClick={() => {
                setShow(true);
                setEditFormShow(false);
              }}
              className="pointer"
            />
          </IconsContainer>
          {show && (
            <AddCategoryContainer>
              <Text>Add Category</Text>
              <Input
                type="text"
                placeholder={t("category-name")}
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
              />
              {
                <ButtonComponent
                  title={t("add")}
                  loading={createCategoryLoading}
                  onClick={handleAddCategory}
                />
              }

              <ButtonComponent title={t("cancel")} onClick={handleCancel} />
            </AddCategoryContainer>
          )}
          {editFormShow && (
            <AddCategoryContainer>
              <Text>{t("edit-category")}</Text>
              <Input
                type="text"
                placeholder={t("edit-category-name")}
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />

              <ButtonComponent
                title={t("edit")}
                loading={editCategoryLoading}
                onClick={() => handleUpdateAction()}
              />
              <ButtonComponent
                title={t("cancel")}
                onClick={() => handleEditCancel()}
              />
            </AddCategoryContainer>
          )}
        </Row>
      </ContentContainer>
      <Buttons>
        <ButtonBack onClick={() => navigate("/admin-portal/marketplace")}>
          {t("back")}
        </ButtonBack>
      </Buttons>
    </>
  );
};

export default CategoryStep1;
