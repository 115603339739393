import { styled } from "styled-components";
import { ReactComponent as MailIcon } from "../../../assets/icons/drawer/mail.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/drawer/lock.svg";
import { ReactComponent as VisibilityOff } from "../../../assets/icons/visibility_off.svg";
import { ReactComponent as Visibility } from "../../../assets/icons/visibility.svg";

export const Error = styled.div`
  color: #dc143c;
  font-size: 13px;
  bottom: -50px;
  display: inline-block;
  position: absolute;
`;
export const MainContainer = styled.div`
  height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f6f9;
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
`;

export const Screen = styled.div`
  padding: 30px 0px;
  position: relative;
  height: fit-content;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 40px 0px 40px;
  gap: 30px;
  @media (max-width: 450px) {
    gap: 15px;
  }
`;

export const StyledLockIcon = styled(LockIcon)`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 17.5px;
  left: 10px;
  @media (max-width: 450px) {
    top: 4.5px;
  }
`;
export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

export const StyledVisibility = styled(Visibility)`
  width: 24px;
  height: 24px;
  top: 17.5px;
  position: absolute;
  right: 20px;

  @media (max-width: 450px) {
    top: 4.5px;
  }
`;
export const StyledVisibilityOff = styled(VisibilityOff)`
  width: 24px;
  height: 24px;

  top: 17.5px;
  position: absolute;
  right: 20px;
  @media (max-width: 450px) {
    top: 4.5px;
  }
`;
export const ScreenContent = styled.div`
  z-index: 1;
  position: relative;
  width: 548px;
  min-height: 520px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #b1b1b1;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(1, 0, 1, 0.1);
  border-radius: 30px;
  padding: 30px 0px 60px 0px;
  background: #fff;
  @media (max-width: 560px) {
    width: 400px;
  }
  @media (max-width: 450px) {
    min-height: 444px;
    width: 90%;
  }
`;

export const Title = styled.span`
  justify-content: center;
  display: flex;
  user-select: none;
  flex-wrap: wrap;
  font-family: "FrankfurterEF-Medium", sans-serif;
  font-size: 48px;
  line-height: 55px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  @media (max-width: 450px) {
    font-size: 3.8rem;
    line-height: 4.8rem;
  }
`;
export const Subtitle = styled.span`
  color: #8a4b5e;
  justify-content: center;
  user-select: none;
  opacity: 0.7;
  font-size: 16px;
  font-family: "Avenir";
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 450px) {
    font-size: 14px;
  }
`;
export const Input = styled.input`
  border: 1px solid #8c8c8c;
  border-radius: 15px;
  height: 60px;
  user-select: none;
  background: none;
  width: 100%;
  font-weight: 700;
  transition: 0.2s;
  &:active,
  &:hover,
  &:focus {
    border: 1px solid #8a4b5e;
    box-shadow: none;
  }
  padding: 15px 15px 15px 38px;

  color: rgba(69, 69, 132, 1);
  font-family: "Avenir";
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.178px;
  @media (max-width: 450px) {
    font-size: 14px;
    border-radius: 7.5px;
    height: 30px;
  }
`;

export const SubmitButton = styled.button`
  font-family: "FrankfurterEF-Medium", sans-serif;

  letter-spacing: -0.178px;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  user-select: none;
  justify-content: center;
  font-size: 22px;
  font-weight: 500;
  color: white;
  background: linear-gradient(180deg, #a87283 0%, #8a4b5e 100%), #ffffff;
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 18px 35px;
  border: none;
  width: 100%;
  height: 70px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  &:hover {
    background: ${({ disabled }) => (disabled ? null : "#8A4B5E")};
  }

  &:active {
    ${({ disabled }) =>
      disabled
        ? null
        : `
    box-shadow: 0px 2px 8px -5px rgb(128, 128, 128);
    transition: all 0.1s ease-in;
    transform: scale(0.95);
  `};
  }
  @media (max-width: 450px) {
    font-size: 16px;
    border-radius: 7.5px;
    height: 35px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  user-select: none;
  width: 100%;
  padding: 0px 40px;
`;
export const ForgotPwContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  margin-top: -10px;
  padding-right: 50px;
  justify-content: flex-end;
`;
export const ForgotPw = styled.span`
  font-size: 12px;
  cursor: pointer;
  font-family: "Avenir-Heavy";
  cursor: pointer;
  color: #8a4b5e;
`;

export const TextDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const StyledTextDiv = styled(TextDiv)`
  align-items: center;
`;
export const CreateText = styled.span`
  color: #8a4b5e;
  opacity: 0.7;
  font-family: "Avenir-Heavy";
  user-select: none;
  font-size: 16px;
  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

export const TabTitle = styled.span`
  font-family: "Avenir-Heavy";
  font-size: 14px;
  letter-spacing: -0.41px;
`;

export const TabContainer = styled.div`
  padding: 0px 20px 8px 20px;
  user-select: none;
  border-radius: 2px;
  cursor: pointer;
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 25px;
`;

export const StyledMailIcon = styled(MailIcon)`
  width: 24px;
  height: 24px;
  margin-left: 8px;
  margin-top: 5.5px;
`;
