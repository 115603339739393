import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Screen,
  ScreenContent,
  MainContainer,
  StyledTitle,
  Content,
  HeaderWrapper,
} from "./WalletCheckoutPage.styles";

import Loader from "../../../components/Loader/Loader";
import Sidebar from "../utils/Sidebar";
import PaymentsComponent from "../../../components/PaymentsComponent/PaymentsComponent";
import billingService from "../../../api/services/billing";
import { selectAmount } from "../../../store/slices/billing/slice";
import Header from "../../../components/Header/Header";
import openToast from "../../../utils/toast";
import {
  MiniText,
  OrderContentContainer,
  OrderSummaryText,
  Price,
  PriceCard,
  RightContainer,
  Separator,
  StyledColumnDiv,
  Total,
} from "../CartPage/CartPage.styles";
import {
  PaymentDiv,
  TitleTotal,
} from "../MarketplaceOrderDeliveryDetails/DeliveryDetailsPage.styles";

const WalletCheckoutPage = ({ items }) => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const amount = useSelector(selectAmount);
  const [loading, setLoading] = useState(false);
  const toastId = useRef(null);

  const submitPayment = async (data) => {
    setLoading(true);
    try {
      const result = await billingService.addToWallet({
        value: amount,
        cardTokenId: data.tokenId,
        paymentMethodId: data.paymentMethodId,
        receipt: data.receipt,
      });

      if (data.receipt) {
        navigation("/admin-portal/wallet/final?bank_transfer=true");
      } else if (data.createOrderWithoutPayment) {
        navigation("/admin-portal/wallet/final?tap_id=" + result.data.tapId);
      } else {
        if (result.data.url) {
          window.open(result.data.url, "_self");
        } else if (result.data.tapId) {
          navigation("/admin-portal/wallet/final?tap_id=" + result.data.tapId);
        } else {
          openToast(
            toastId,
            "submit-payment",
            "error",
            t("Error occurred while creating payment")
          );
          setLoading(false);
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      {loading && <Loader disableScroll></Loader>}
      <HeaderWrapper>
        <Header showLogin showNav color showUser showCart showFavorite />
      </HeaderWrapper>
      <MainContainer>
        <Sidebar />
        <Screen $loggedIn>
          <ScreenContent $center>
            <StyledTitle>{t("checkout")}</StyledTitle>
            <Content>
              <PaymentDiv>
                <PaymentsComponent
                  onBack={() => navigation(-1)}
                  priceToPay={amount}
                  setLoading={setLoading}
                  onComplete={(data) => submitPayment(data)}
                  createOrderButton
                  hideAdminCreateOrder
                />

                <StyledColumnDiv>
                  <OrderSummaryText>{t("order-summary")}</OrderSummaryText>
                  <RightContainer>
                    <PriceCard style={{ background: "#fff" }}>
                      <OrderContentContainer>
                        <TitleTotal>{t("number-of-items")}: </TitleTotal>
                        <TitleTotal>{items ? items?.length : 0}</TitleTotal>
                      </OrderContentContainer>
                      <Separator />
                      <div>
                        <OrderContentContainer>
                          <Total>{t("total")}: </Total>
                          <Price>{parseFloat(amount).toFixed(2)} SR</Price>
                        </OrderContentContainer>
                        <MiniText>{t("inclusive-of-vat")}</MiniText>
                      </div>
                    </PriceCard>
                  </RightContainer>
                </StyledColumnDiv>
              </PaymentDiv>
            </Content>
          </ScreenContent>
        </Screen>
      </MainContainer>
    </>
  );
};

export default WalletCheckoutPage;
