import React, { useRef } from "react";
import Modal from "../Modal/Modal";
import {
  ContentContainer,
  Title,
  InviteLink,
  InviteLinkContainer,
  TitleContainer,
  CloseContainer,
  Column,
  Button,
} from "./InviteUserModal.style";

import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal2 from "../Modal/Modal2";

const InviteUserModal = ({ open, close }) => {
  const { t } = useTranslation();
  const inviteLinkRef = useRef();
  const invitationLink = useSelector(
    (state) => state.businessUser.invitationLink
  );

  const copyInviteLink = () => {
    if (inviteLinkRef.current) {
      inviteLinkRef.current.select();
      document.execCommand("copy");
    }
  };

  return (
    <Modal2
      border={"2px solid #8A4B5E"}
      bg={"linear-gradient(360deg, #a87283 -100%, #8A4B5E 100%)"}
      onClose={close}
      open={open}
      height={"fit-content"}
      width={"fit-content"}
    >
      <ContentContainer>
        <CloseContainer onClick={close}>
          <Close className="close" fill="#fff" />
        </CloseContainer>

        <Column>
          <TitleContainer>
            <Title>{t("invite-link")}</Title>
          </TitleContainer>

          <InviteLinkContainer>
            <InviteLink ref={inviteLinkRef} readOnly value={invitationLink} />
          </InviteLinkContainer>

          <Button onClick={copyInviteLink}>
            <Copy />
            {t("copy")}
          </Button>
        </Column>
      </ContentContainer>
    </Modal2>
  );
};

export default InviteUserModal;
