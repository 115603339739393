import { createAsyncThunk } from "@reduxjs/toolkit";
import subscriptionService from "../../../api/services/subscription";

export const getAdminSubscriptions = createAsyncThunk(
  "subscriptions/get-admin-subscriptions",
  async (requestData) => {
    return await subscriptionService.getAdminSubscriptions(requestData);
  }
);
export const getAdminSubscriptionsBankTransfers = createAsyncThunk(
  "subscriptions/get-bank-transfers",
  async (requestData) => {
    return await subscriptionService.getAdminSubscriptionsBankTransfers(
      requestData
    );
  }
);
export const getAdminSubscriptionsAll = createAsyncThunk(
  "subscriptions/get-all-subscriptions",
  async (requestData) => {
    return await subscriptionService.getAdminSubscriptionsAll(requestData);
  }
);

export const reviewBankTransfer = createAsyncThunk(
  "subscriptions/review-bank-transfer",
  async (requestData) => {
    return await subscriptionService.reviewBankTransfer(requestData);
  }
);

export const editCompanySubscription = createAsyncThunk(
  "subscriptions/edit-company-subscription",
  async (requestData) => {
    return await subscriptionService.editCompanySubscription(requestData);
  }
);

export const getUserSubscriptions = createAsyncThunk(
  "subscriptions/get-user-subscriptions",
  async (requestData) => {
    return await subscriptionService.getUserSubscriptions(requestData);
  }
);

export const disableAutomaticSubscriptionRenewal = createAsyncThunk(
  "subscriptions/disable-automatic-subscription-renewal",
  async () => {
    return await subscriptionService.disableAutomaticSubscriptionRenewal();
  }
);

export const getCompanySubscriptionHistory = createAsyncThunk(
  "subscriptions/company-subscription-history",
  async (requestData) => {
    return await subscriptionService.getCompanySubscriptionHistory(requestData);
  }
);

export const assignSubscriptionPlan = createAsyncThunk(
  "subscriptions/assign-plan",
  async (requestData) => {
    return await subscriptionService.assignPlan(requestData);
  }
);

export const validatePayment = createAsyncThunk(
  "subscriptions/validatePayment",
  async (requestData) => {
    return await subscriptionService.validatePayment(requestData);
  }
);
