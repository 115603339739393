import axiosApiClient from "..";

import { handleError } from "../../utils/errors";

const BASE_URL = "items";

class ItemServices {
  getUsersWishlistItemsUnauthorized = async (userId) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/wishlist/unauthorized/${userId}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return data;
    } catch (err) {
      throw handleError(err, "Error occurred while fetching item by id.");
    }
  };

  getItemUnauthorized = async (id) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/single/unauthorized/${id}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return data;
    } catch (err) {
      throw handleError(err, "Error occurred while fetching item by id.");
    }
  };
}

const itemServices = new ItemServices();

export default itemServices;
