import React from "react";
import Modal from "../Modal/Modal";
import {
  ContentContainer,
  Title,
  TitleContainer,
  CloseContainer,
  Column,
  Button,
  Input,
} from "../InviteUserModal/InviteUserModal.style";

import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import Modal2 from "../Modal/Modal2";

const ChargeWalletModal = ({ onClick, onChange, value, open, close }) => {
  const { t } = useTranslation();

  return (
    <Modal2
      border={"2px solid #8A4B5E"}
      bg={"linear-gradient(360deg, #a87283 -100%, #8A4B5E 100%)"}
      onClose={close}
      open={open}
      height={"fit-content"}
      width={"fit-content"}
    >
      <ContentContainer>
        <CloseContainer onClick={close}>
          <Close className="close" fill="#fff" />
        </CloseContainer>

        <Column>
          <TitleContainer>
            <Title>{t("enter-the-amount")}</Title>
          </TitleContainer>
          <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
            <Input type="number" value={value} onChange={onChange} min={1} />

            <Button onClick={onClick}>{t("confirm")}</Button>
          </div>
        </Column>
      </ContentContainer>
    </Modal2>
  );
};

export default ChargeWalletModal;
