import {
  getRecipients,
  deleteRecipient,
  addRecipient,
  addRecipientsWithFile,
  editRecipient,
} from "./asyncThunks";

export const getRecipientsBuilder = (builder) => {
  builder
    .addCase(getRecipients.pending, (state, { meta }) => {
      const { page } = meta.arg;

      if (page === 1) {
        state.loading = true;
      } else {
        state.loading = false;
      }

      state.error = null;
    })
    .addCase(getRecipients.fulfilled, (state, { payload, meta }) => {
      const { total, perPage, maxReached, recipients } = payload;
      const { page } = meta.arg;

      if (page === 1) {
        state.recipients = recipients;
      } else {
        state.recipients = [...state.recipients, ...recipients];
      }
      state.perPage = perPage;
      state.total = total;
      state.loading = false;
      state.maxReached = maxReached;
    })
    .addCase(getRecipients.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
};

export const addRecipientBuilder = (builder) => {
  builder
    .addCase(addRecipient.pending, (state) => {
      state.addLoading = true;
    })
    .addCase(addRecipient.fulfilled, (state, action) => {
      state.recipients = [...state.recipients, action.payload];
      state.addLoading = false;
    })
    .addCase(addRecipient.rejected, (state) => {
      state.addLoading = false;
    });
};

export const deleteRecipientBuilder = (builder) => {
  builder
    .addCase(deleteRecipient.pending, (state) => {
      state.deleteLoading = true;
    })
    .addCase(deleteRecipient.fulfilled, (state, action) => {
      const recipientId = action.payload;
      state.recipients = state.recipients.filter(
        (recipient) => recipient.id !== recipientId
      );
      state.total = state.total - 1;
      state.deleteLoading = false;
    })
    .addCase(deleteRecipient.rejected, (state) => {
      state.deleteLoading = false;
    });
};

export const uploadRecipientsBuilder = (builder) => {
  builder
    .addCase(addRecipientsWithFile.pending, (state) => {
      state.uploadLoading = true;
    })
    .addCase(addRecipientsWithFile.fulfilled, (state, action) => {
      const newRecipients = action.payload.data.recipients;
      newRecipients.forEach((recipient) => {
        state.recipients = [...state.recipients, recipient];
      });
      state.uploadLoading = false;
    })
    .addCase(addRecipientsWithFile.rejected, (state) => {
      state.uploadLoading = false;
    });
};

export const editRecipientBuilder = (builder) => {
  builder
    .addCase(editRecipient.pending, (state) => {
      state.editLoading = true;
    })
    .addCase(editRecipient.fulfilled, (state, action) => {
      const updatedRecipient = action.payload;

      state.recipients = state.recipients.map((rec) =>
        rec.id === updatedRecipient.id ? { ...rec, ...updatedRecipient } : rec
      );
      state.editLoading = false;
    })
    .addCase(editRecipient.rejected, (state) => {
      state.editLoading = false;
    });
};
