import { toast } from "react-toastify";
import moment from "moment";
import { FRONT_URL } from "../api";

export const priceFormatter = (amount, buyable, itemPurchaseVAT) => {
  if (buyable) {
    const vat = (amount * itemPurchaseVAT) / 100;
    amount = amount + vat;
  }

  const formattedAmount = amount?.toFixed(2);
  return formattedAmount?.endsWith(".00")
    ? formattedAmount?.replace(".00", ".00")
    : formattedAmount;
};

export const subtractVAT = (amount, itemPurchaseVAT) => {
  const result = (+amount * 100) / (100 + itemPurchaseVAT);

  const formattedResult = result?.toFixed(2);
  return formattedResult?.endsWith(".00")
    ? formattedResult?.replace(".00", "")
    : formattedResult;
};

export const isSaudiNumber = (phone) => {
  return phone.includes("+966");
};

export const formatDateNumeric = (date) => {
  return new Date(date)
    .toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })
    .replace(/ /g, "-");
};

export function formatDate(date) {
  const formattedDate = moment(date).format("MMM DD, YYYY");

  return formattedDate;
}

export const isAppleDevice = () => {
  var isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
  var isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);
  return isMacLike || isIOS;
};

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailRegex.test(email);
};

export const isValidPassword = (password) => {
  const oneUpperLetter = /(?=.*?[A-Z])/;
  const oneLowerLetter = /(?=.*?[a-z])/;
  const oneNumber = /(?=.*?[0-9])/;
  const oneSpecialCharacter = /(?=.*?[#?!@$%^&*-])/;
  const minLengthEight = /.{8,}/;

  return (
    oneUpperLetter.test(password) &&
    oneLowerLetter.test(password) &&
    oneNumber.test(password) &&
    oneSpecialCharacter.test(password) &&
    minLengthEight.test(password)
  );
};

export function handleCopyClick(inputRef, t) {
  try {
    inputRef.select();
    document.execCommand("copy");
    navigator.clipboard.writeText(inputRef.value).then(() => {
      toast.success(t("Text copied to clipboard"));
    });
  } catch (err) {
    toast.error(t("Unable to copy text to clipboard"));
  } finally {
    window.getSelection().removeAllRanges();
  }
}

export const blobUrlToFile = (blobUrl, fileName) => {
  return new Promise(function (resolve, reject) {
    fetch(blobUrl)
      .then((response) => response.blob())
      .then((blobData) => {
        const mimeType = getMimeTypeFromFileName(fileName);
        resolve(new File([blobData], fileName, { type: mimeType }));
      })
      .catch((error) => {
        console.error("Error fetching blob data");
        reject();
      });
  });
};

const extractFileNameFromBlobUrl = (blobUrl) => {
  const matches = blobUrl.match(/\/([^/]+)$/);
  return matches ? matches[1] : "unknown-file";
};

const getMimeTypeFromFileName = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  switch (extension) {
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    default:
      return "image/jpeg";
  }
};

export const checkEnvironment = () => {
  let environment;

  if (FRONT_URL === "http://localhost:3000") {
    environment = "development";
  } else if (FRONT_URL === "https://portal.ygii.app") {
    environment = "production";
  } else if (
    FRONT_URL === "https://stagingbusiness-portal.ygii.app" ||
    FRONT_URL === "https://sp.ygii.app"
  ) {
    environment = "staging";
  }
  return environment;
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
