import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MainContainer,
  Column,
  SectionTitle,
  HeaderWrapper,
  Row,
  StyledColumn,
  Card,
  FooterCard,
  Img,
  Description,
  Title,
  Name,
  StyledCard,
  TitleContainer,
  Color,
  Colors,
  Button,
  Text,
  StyledTitleContainer,
  Titles,
  Input,
  StyledRow,
  FooterRow,
  FooterColumn,
  FooterText,
  FooterValues,
  TitleRow,
  StyledSectionTitle,
  Size,
  SizeValue,
  Green,
  Layout,
  ChooseFile,
  Red,
  ErrorDiv,
  Success,
  SubTitle,
  LoaderDiv,
  ImageContainer,
  HorizontalLine,
  VerticalLine,
  PrintLogo,
  RemoveLogo,
  LogoContainer,
  RotateLogo,
  ResizeLogo,
  Background,
  PrintAreTextContainer,
  PrintAreaText,
  PrintAreaBorder,
  PrintAreaContainer,
  PrintLogoInvisible,
  PhotoBorder,
  PopupTitle,
  PopupContainer,
  PopupText,
  Triangle,
  PopupTitleContainer,
  XIcon,
  ButtonsContainer,
  Relative,
  MinQuantityContainer,
  TextColumn,
} from "./MarketplaceItemDetailPage.styles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getMarketplaceItemById } from "../../../../store/slices/marketplace/asyncThunks";
import { selectBusinessUserInfo } from "../../../../store/slices/businessUser/slice";
import { convertBase64, priceFormatter } from "../../../../utils/utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../../../../components/Footer.js";
import Header from "../../../../components/Header/Header";
import {
  addToCart,
  editCartItem,
  resetEditItemData,
  selectEditItemData,
  selectedMarketplaceItem,
  selectedMarketplaceItemLoader,
  setEditItemData,
} from "../../../../store/slices/marketplace/marketplaceSlice";
import { toast } from "react-toastify";
import { getCurrencySymbol } from "../../../../utils/constants";
import MediaSlider from "../../../../components/MediaSlider/MediaSlider";
import Dots from "../../../../components/Dots/Dots";
import Sidebar from "../../utils/Sidebar";
import MiniLoader from "../../../../components/MiniLoader/MiniLoader";
import PriceBreakModal from "../../../../components/Modal/PriceBreakModal.js";
import InstantQuoteModal from "../../../../components/Modal/InstantQuoteModal.js";
import LoginModal from "../../../../components/Modal/LoginModal.js";
import BuySampleModal from "../../../../components/Modal/BuySampleModal.js";
import CartModal from "../../../../components/Modal/CartModal.js";
import FailedImg from "../../../../assets/images/failed.png";
import openToast from "../../../../utils/toast.js";
import { selectedLocale } from "../../../../store/slices/common/slice.js";
import { useSwipeable } from "react-swipeable";
import { ReactComponent as RemoveIcon } from "../../../../assets/icons/removeLogo.svg";
import { ReactComponent as RotateIcon } from "../../../../assets/icons/rotateLogo.svg";
import { ReactComponent as ResizeIcon } from "../../../../assets/icons/resizeLogo.svg";
import { ReactComponent as Trash } from "../../../../assets/icons/remove.svg";
import Draggable from "react-draggable";
import { generatePhotosWithLogo } from "../../../../store/slices/photoProcessing/asyncThunks.js";
import KeepInMindModal from "../../../../components/Modal/KeepInMindModal.js";

const MarketplaceItemDetailPage = () => {
  const { itemId } = useParams();
  const location = useLocation();
  const { state } = location;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const businessUser = useSelector(selectBusinessUserInfo);
  const item = useSelector(selectedMarketplaceItem);
  const loading = useSelector(selectedMarketplaceItemLoader);
  const [activeIndex, setActiveIndex] = useState(0);
  const fileInputRef = useRef(null);
  const editItemData = useSelector(selectEditItemData);

  const [priceBreaksModal, setPriceBreaksModal] = useState(false);
  const [instantQuoteModal, setInstantQuoteModal] = useState(false);
  const [buySampleModal, setBuySampleModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [cartModal, setCartModal] = useState(false);

  const [quantityValue, setQuantityValue] = useState(1);
  const [activeColor, setActiveColor] = useState({});
  const [activeSize, setActiveSize] = useState({});
  const [activeCard, setActiveCard] = useState({});
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [logoName, setLogoName] = useState(null);
  const [quantityBlank, setQuantityBlank] = useState(0);
  const [quantityBranded, setQuantityBranded] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [errorBlank, setErrorBlank] = useState(false);
  const [errorBranded, setErrorBranded] = useState(false);
  const toastId = useRef(null);
  const [loadingItemId, setLoadingItemId] = useState(null);

  useEffect(() => {
    if (editItemData?.id) {
      resetEditItemData();
    }
    if (state) {
      dispatch(setEditItemData({ product: state.product, index: state.index }));
    }
    dispatch(getMarketplaceItemById(itemId));
  }, [state, dispatch, itemId, editItemData]);

  useEffect(() => {
    if (item) {
      setIsLoading(false);

      if (item.subcategories && Object.values(item.subcategories).length > 0) {
        Object.values(item.subcategories).map((color) => {
          const colors = color.filter(
            (array) => array.subcategoryName === "color"
          );
          let newColor = colors[0];

          if (colors && colors.length > 0) {
            setActiveColor({ id: newColor.valueId, name: newColor.value });
          }
        });
      }
    }
  }, [item]);

  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    if (item.photos) {
      setPhotos(
        item.photos.filter(
          (el) =>
            el.marketplaceSubcategoryItemId == null ||
            el.marketplaceSubcategoryItemId === activeColor.id
        )
      );
    }
  }, [item.photos, activeColor]);

  useEffect(() => {
    if (businessUser.loggedIn) {
      navigate(`/admin-portal/marketplace/item-detail/${itemId}`);
    } else {
      navigate(`/marketplace/${itemId}`);
    }
  }, [state, businessUser, navigate, itemId]);

  useEffect(() => {
    if (editItemData && editItemData?.quantity) {
      setQuantityValue(editItemData?.quantity);
    } else if (item && item.minQuantity) {
      setQuantityValue(item.minQuantity);
    }
  }, [editItemData, item]);

  useEffect(() => {
    if (editItemData && editItemData?.color?.id) {
      setActiveColor(editItemData.color);
    }
    if (editItemData && editItemData?.size?.id) {
      setActiveSize(editItemData.size);
    }
    if (editItemData && editItemData?.giftCard?.id) {
      setActiveCard(editItemData.giftCard);
    }
  }, [editItemData]);

  const subcategoriesContainPrice = Object.values(item.subcategories).some(
    (obj) =>
      Object.values(obj).some(
        (innerObj) =>
          innerObj.subcategoryName === "price" ||
          innerObj.subcategoryName === "Gift Card Value"
      )
  );

  useEffect(() => {
    if (!editItemData?.giftCard) {
      if (item && item?.productId) {
        const subcategoriesValues = Object.values(item.subcategories);

        if (
          Array.isArray(subcategoriesValues) &&
          subcategoriesValues.length > 0 &&
          subcategoriesContainPrice
        ) {
          const firstSubcategoryArray = subcategoriesValues[0];

          if (
            Array.isArray(firstSubcategoryArray) &&
            firstSubcategoryArray.length > 0
          ) {
            const firstSubcategory = firstSubcategoryArray[0];

            if (
              firstSubcategory &&
              firstSubcategory.valueId &&
              firstSubcategory.value &&
              firstSubcategory.price
            ) {
              const newActiveCard = {
                id: firstSubcategory.valueId,
                name: firstSubcategory.value,
                price: firstSubcategory.price,
              };

              setActiveCard(newActiveCard);
            }
          }
        }
      }
    }
  }, [editItemData, item, subcategoriesContainPrice]);

  const subcategoriesContainColor = Object.values(item.subcategories).some(
    (obj) =>
      Object.values(obj).some(
        (innerObj) => innerObj.subcategoryName === "color"
      )
  );

  const subcategoriesContainSize = Object.values(item.subcategories).some(
    (obj) =>
      Object.values(obj).some((innerObj) => innerObj.subcategoryName === "size")
  );

  const truncateText = (text, maxLength) => {
    if (text && text.length <= maxLength) {
      return text;
    } else {
      return text?.substring(0, maxLength) + "...";
    }
  };

  const convertLogo = async (selectedFile) => {
    const base64 = await convertBase64(selectedFile);
    setLogoUrl(base64);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // dispatch(processLogoBackground({ logo: selectedFile }))
      //   .unwrap()
      //   .then((data) => {
      //     if (data) {
      //       setLogoUrl(data);
      //       toast.success(t("File uploaded successfully"));
      //     }
      //   });
      if (item.designWithoutColor) {
        setShowKeepInMindModal(true);
      }

      setLogo(selectedFile);
      convertLogo(selectedFile);
      // setLogoUrl(URL.createObjectURL(selectedFile));
      setLogoName(selectedFile.name);
    } else {
      setLogo(null);
      openToast(
        toastId,
        "marketplace-receipt-file",
        "error",
        t("Please select a logo file")
      );
    }
  };

  const handlePriceBreaksClick = () => {
    setPriceBreaksModal(true);
  };

  const handleChangePhotoIndex = (index) => {
    setLoadingImage(true);
    setLoadingLogo(true);
    setActiveIndex(index);
    setPhotosLogoPosition((prev) => {
      if (prev[index].hasLogo) {
        prev[index].isActive = true;
      }
      return prev;
    });
  };

  const handlePickSize = (c) => {
    setActiveSize({ id: c.valueId, name: c.value });
  };

  const validate = () => {
    let isValid = true;

    if (subcategoriesContainColor) {
      if (!activeColor?.id) {
        openToast(toastId, "add-to-cart", "error", t("please-select-a-color"));
        isValid = false;
        return;
      }
    }

    if (subcategoriesContainSize) {
      if (!activeSize?.id) {
        openToast(toastId, "add-to-cart", "error", t("please-select-a-size"));
        isValid = false;
        return;
      }
    }

    if (quantityValue < item.minQuantity) {
      openToast(
        toastId,
        "add-to-cart",
        "error",
        `${t("quantity-must-be-minimum")} ${item.minQuantity}`
      );
      isValid = false;
      return;
    }

    return isValid;
  };

  const totalItemPrice = useMemo(() => {
    if (item && item.priceBreaks) {
      if (quantityValue) {
        let minQuantityPriceBreak = 0;
        let discount = 0;
        for (const priceBreak of item.priceBreaks) {
          if (
            quantityValue >= priceBreak.minQuantity &&
            priceBreak.minQuantity > minQuantityPriceBreak
          ) {
            minQuantityPriceBreak = priceBreak.minQuantity;
            discount = priceBreak.discount;
          }
        }
        return (item.price - (item.price * discount) / 100) * +quantityValue;
      }
      return item.price;
    }
    return item.price;
  }, [item, quantityValue]);

  const createCartItemObject = (cartItemId) => {
    return {
      id: item.id,
      name: item.name,
      photo: photos[0],
      color: {
        id: activeColor?.id,
        name: activeColor?.name,
      },
      size: {
        id: activeSize?.id,
        name: activeSize?.name,
      },
      giftCard: {
        id: activeCard?.id,
        name: activeCard?.name,
        price: activeCard?.price,
      },
      uploadLogoUrl: logoUrl,
      uploadLogoName: logoName,
      priceBreaks: item.priceBreaks,
      samplePrice: item.samplePrice,
      printedPrice: item.printedPrice,
      itemPrice: item.price,
      minQuantity: item.minQuantity,
      quantity: +quantityValue,
      productionTime: item.productionTime,
      totalPrice: totalItemPrice,
      buyBlank: false,
      buyPrinted: false,
      currency: item.currency,
      favorite: item.favorite,
      productId: item.productId,
      cartItemId,
      businessUserId: businessUser.id,
    };
  };

  const showUpgradeBar = businessUser.company.packageType === "Free";
  let diff =
    new Date(businessUser.company?.packageExpiration).getTime() -
    new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  const locale = useSelector(selectedLocale);
  const upgradePackage = showUpgradeBar && diff > 0 && businessUser.state;
  const ar = locale === "ar";

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleChangePhotoIndex(
        activeIndex === photos.length - 1 ? 0 : activeIndex + 1
      );
    },
    onSwipedRight: () => {
      handleChangePhotoIndex(
        activeIndex === 0 ? photos.length - 1 : activeIndex - 1
      );
    },
  });

  const [hover, setHover] = useState(false);
  const [rotateAngle, setRotateAngle] = useState(0);
  const [drag, setDrag] = useState(false);
  const [isFirstDrag, setFirstDrag] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showKeepInMindModal, setShowKeepInMindModal] = useState(false);

  const [loadingLogo, setLoadingLogo] = useState(true);
  const [loadingImage, setLoadingImage] = useState(true);

  const [scaleImage, setScaleImage] = useState({ x: 1, y: 1 });
  const [invertedScaleImage, setInvertedScaleImage] = useState({ x: 1, y: 1 });

  const [printingAreaSize, setPrintingAreaSize] = useState({
    width: 170,
    height: 200,
    top: "50%",
    left: "50%",
  });

  const [printingAreaSizes, setPrintingAreaSizes] = useState([]);

  const imageRef = useRef(null);
  const divRef = useRef(null);
  const imgRef = useRef(null);
  const itemPhotoRef = useRef(null);

  const [sizesss, setSize] = useState({
    width: printingAreaSize.width,
    height: "auto",
  });

  const [photosLogoPosition, setPhotosLogoPosition] = useState([]);

  const [counter, setCounter] = useState(1);

  const handlePickColor = (c) => {
    setActiveColor({ id: c.valueId, name: c.value });
    setActiveIndex(0);
  };

  useEffect(() => {
    let areas = [];
    let photosData = [];
    if (item && item.uploadDesign) {
      for (const [index, photo] of photos.entries()) {
        let printArea = photo.itemLogoPrintAreas[0];

        if (printArea) {
          printArea = {
            top: printArea.top,
            left: printArea.left,
            width: printArea.width,
            height: printArea.height,
          };
        }

        areas.push(
          photo.itemLogoPrintAreas.length
            ? { ...printArea, hasArea: true }
            : { hasArea: false }
        );

        photosData.push(
          photo.itemLogoPrintAreas.length
            ? {
                top: 0,
                left: 0,
                width: 150,
                height: "auto",
                rotateAngle: 0,
                hasLogo: true,
                isActive: index === activeIndex ? true : false,
              }
            : { hasLogo: false }
        );
      }
    }

    setPrintingAreaSizes(areas);
    setPhotosLogoPosition(photosData);
    setCounter((prev) => prev + 1);
  }, [item, logo, photos]);

  useEffect(() => {
    if (printingAreaSizes) {
      let tmpPrintAreas = [...printingAreaSizes];

      let printArea = tmpPrintAreas[activeIndex];
      if (printArea && printArea.hasArea) {
        tmpPrintAreas[activeIndex] = {
          ...printArea,
          topScaled: printArea.top * invertedScaleImage.y,
          leftScaled: printArea.left * invertedScaleImage.x,
          widthScaled: printArea.width * invertedScaleImage.x,
          heightScaled: printArea.height * invertedScaleImage.y,
        };
        setPrintingAreaSizes(tmpPrintAreas);
      }
    }
  }, [
    activeIndex,
    invertedScaleImage.x,
    invertedScaleImage.y,
    logo,
    invertedScaleImage,
  ]);

  useEffect(() => {
    if (photosLogoPosition[activeIndex]?.rotateAngle) {
      setRotateAngle(photosLogoPosition[activeIndex].rotateAngle);
    }

    setDrag(false);
    setCounter((prev) => prev + 1);
  }, [activeIndex]);

  const onLoadPicture = () => {
    setLoadingImage(false);
    if (itemPhotoRef.current) {
      let scaleImage = { x: 1, y: 1 };
      let invertedScaleImage = { x: 1, y: 1 };
      scaleImage.x =
        itemPhotoRef.current?.naturalWidth / itemPhotoRef.current?.width;
      scaleImage.y =
        itemPhotoRef.current?.naturalHeight / itemPhotoRef.current?.height;

      invertedScaleImage.x =
        itemPhotoRef.current?.width / itemPhotoRef.current?.naturalWidth;
      invertedScaleImage.y =
        itemPhotoRef.current?.height / itemPhotoRef.current?.naturalHeight;

      setScaleImage(scaleImage);
      setInvertedScaleImage(invertedScaleImage);
    }
  };

  const onLoadLogo = (ev) => {
    if (photosLogoPosition[activeIndex].height === "auto") {
      setPhotosLogoPosition((prev) => {
        prev[activeIndex].height = (150 * ev.target.height) / ev.target.width;
        return prev;
      });
    }
    setTimeout(() => {
      setLoadingLogo(false);
    }, 100);
  };

  const handleRotate = (event) => {
    event.stopPropagation();
    if (!event.clientX && event.touches) {
      event = event.touches[0];
    }

    const target = divRef.current;
    const rect = target.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;

    const startAngle =
      Math.atan2(event.clientY - centerY, event.clientX - centerX) *
      (180 / Math.PI);

    const handleMove = (moveEvent) => {
      if (!moveEvent.clientX && moveEvent.touches) {
        moveEvent = moveEvent.touches[0];
      }
      const currentAngle =
        Math.atan2(moveEvent.clientY - centerY, moveEvent.clientX - centerX) *
        (180 / Math.PI);

      const rotate = currentAngle - startAngle + rotateAngle;
      setPhotosLogoPosition((prev) => {
        prev[activeIndex].rotateAngle = rotate;
        return [...prev];
      });
      setRotateAngle(rotate);
    };

    const handleEnd = () => {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseup", handleEnd);
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("touchend", handleEnd);
    };

    document.addEventListener("mousemove", handleMove);
    document.addEventListener("mouseup", handleEnd);
    document.addEventListener("touchmove", handleMove);
    document.addEventListener("touchend", handleEnd);
  };

  const handleResize = (event) => {
    event.stopPropagation();

    if (!event.clientX && event.touches) {
      event = event.touches[0];
    }

    let tempSize = {
      width: photosLogoPosition[activeIndex].width,
      height: photosLogoPosition[activeIndex].height,
    };
    if (photosLogoPosition[activeIndex].height === "auto") {
      tempSize.height = imgRef.current.height;
    }

    const startPoint = { x: event.clientX, y: event.clientY };
    const startDimensions = tempSize;

    const handleMove = (moveEvent) => {
      if (!moveEvent.clientX && moveEvent.touches) {
        moveEvent = moveEvent.touches[0];
      }

      let dx = moveEvent.clientX - startPoint.x;

      let moveDirection = 3;
      if (
        photosLogoPosition[activeIndex]?.rotateAngle < -90 &&
        photosLogoPosition[activeIndex]?.rotateAngle > -270
      ) {
        moveDirection = -3;
      }

      dx = dx * moveDirection;

      const scale = (startDimensions.width + dx) / startDimensions.width;

      setSize({
        width: startDimensions.width + dx,
        height: startDimensions.height * scale,
      });
      setPhotosLogoPosition((prev) => {
        prev[activeIndex].width = startDimensions.width + dx;
        prev[activeIndex].height = startDimensions.height * scale;

        return prev;
      });
    };

    const handleEnd = () => {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseup", handleEnd);
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("touchend", handleEnd);
    };

    document.addEventListener("touchmove", handleMove);
    document.addEventListener("touchend", handleEnd);

    document.addEventListener("mousemove", handleMove);
    document.addEventListener("mouseup", handleEnd);
  };
  const addToCartAndCreatePhotosWithLogo = () => {
    let photosData = [];
    const cartItemId =
      Date.now().toString(36) + Math.random().toString(36).substr(2);
    if (logo) {
      for (let i = 0; i < printingAreaSizes.length; i++) {
        if (
          printingAreaSizes[i].hasArea &&
          photosLogoPosition[i].hasLogo &&
          photosLogoPosition[i].isActive
        ) {
          photosData.push({
            photoUrl: photos[i]?.url,
            positionY:
              (photosLogoPosition[i].top + printingAreaSizes[i].topScaled) *
              scaleImage.y,
            positionX:
              (photosLogoPosition[i].left + printingAreaSizes[i].leftScaled) *
              scaleImage.x,
            width: photosLogoPosition[i].width * scaleImage.x,
            height: photosLogoPosition[i].height * scaleImage.y,
            rotateAngle: photosLogoPosition[i].rotateAngle,
            indexPhoto: i,
          });
        }
      }
    }

    dispatch(addToCart(createCartItemObject(cartItemId)));
    setCartModal(true);
    setLoadingItemId(cartItemId);
    toast.success(t("item-added-to-cart-successfully"));
    if (photosData.length !== 0 && logo) {
      dispatch(
        generatePhotosWithLogo({
          photosData,
          logo: logo,
          cartItemId: cartItemId,
        })
      )
        .unwrap()
        .then((data) => {})
        .catch((error) => {
          openToast(
            toastId,
            "create-company-gifting-order",
            "error",
            t(error.message)
          );
        });
    }
  };

  const handleFirstDrag = () => {
    if (!isFirstDrag) {
      setFirstDrag(true);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 5000);
    }
  };
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timer;
    if (showPopup) {
      setIsVisible(true);
    } else {
      timer = setTimeout(() => setIsVisible(false), 250);
    }

    return () => clearTimeout(timer);
  }, [showPopup]);

  const handleRemoveLogoFromPhoto = (event) => {
    event.stopPropagation();

    setPhotosLogoPosition((prev) => {
      prev[activeIndex].hasLogo = false;
      prev[activeIndex].isActive = false;
      return [...prev];
    });
  };

  return (
    <>
      <MainContainer>
        {businessUser.loggedIn && <Sidebar />}
        <HeaderWrapper>
          <Header
            display
            showLogin
            showSignUp
            showNav
            color
            showCart
            showUser
            showFavorite
          />
        </HeaderWrapper>
        <Layout>
          <Column
            $ar={ar}
            $upgradePackage={upgradePackage}
            $loggedIn={businessUser.loggedIn}
          >
            {!loading && !isLoading ? (
              <>
                {item.id ? (
                  <>
                    <TitleRow $loggedIn={businessUser.loggedIn}>
                      <StyledSectionTitle
                        style={{ textDecoration: "underline", fontWeight: 600 }}
                        onClick={() => navigate("/marketplace")}
                      >
                        {t("marketplace")}
                      </StyledSectionTitle>
                      <SectionTitle> {">"} </SectionTitle>
                      {item?.category &&
                        item?.category.map((c) => (
                          <StyledSectionTitle
                            key={c.id}
                            onClick={() => {
                              const query = {
                                category: c.marketplaceCategory.id,
                              };
                              const queryString = new URLSearchParams(
                                query
                              ).toString();
                              const url = !businessUser.loggedIn
                                ? `/marketplace?${queryString}`
                                : `/admin-portal/marketplace?${queryString}`;
                              navigate(url);
                            }}
                          >
                            {c.marketplaceCategory?.name}
                          </StyledSectionTitle>
                        ))}
                    </TitleRow>

                    <Row>
                      <StyledColumn>
                        <Card $bigGap>
                          {isVisible && (
                            <PopupContainer show={showPopup}>
                              <Triangle />
                              <PopupTitleContainer>
                                <PopupTitle>{t("dont-worry")}</PopupTitle>
                                <XIcon onClick={() => setShowPopup(false)} />
                              </PopupTitleContainer>
                              <PopupText>{t("reviewed-human")}</PopupText>
                            </PopupContainer>
                          )}
                          <ImageContainer ref={imageRef}>
                            {(logo || editItemData?.uploadLogoName) &&
                              printingAreaSizes[activeIndex].hasArea &&
                              photosLogoPosition[activeIndex].hasLogo &&
                              photosLogoPosition[activeIndex].isActive && (
                                <>
                                  {drag ? (
                                    <PrintAreaBorder
                                      $drag
                                      style={{
                                        width: `${printingAreaSizes[activeIndex].widthScaled}px`,
                                        height: `${printingAreaSizes[activeIndex].heightScaled}px`,
                                        top: `${printingAreaSizes[activeIndex].topScaled}px`,
                                        left: `${printingAreaSizes[activeIndex].leftScaled}px`,
                                      }}
                                    >
                                      <VerticalLine $drag />
                                      <HorizontalLine $drag />
                                    </PrintAreaBorder>
                                  ) : (
                                    <PrintAreaBorder
                                      $hover={hover}
                                      style={{
                                        width: `${printingAreaSizes[activeIndex].widthScaled}px`,
                                        height: `${printingAreaSizes[activeIndex].heightScaled}px`,
                                        top: `${printingAreaSizes[activeIndex].topScaled}px`,
                                        left: `${printingAreaSizes[activeIndex].leftScaled}px`,
                                      }}
                                    >
                                      <VerticalLine $hover={hover} />
                                      <HorizontalLine $hover={hover} />
                                    </PrintAreaBorder>
                                  )}

                                  <PrintAreaContainer
                                    onMouseEnter={() => setHover(true)}
                                    onMouseLeave={() => setHover(false)}
                                    style={{
                                      zIndex: 20,
                                      width: `${printingAreaSizes[activeIndex].widthScaled}px`,
                                      height: `${printingAreaSizes[activeIndex].heightScaled}px`,
                                      top: `${printingAreaSizes[activeIndex].topScaled}px`,
                                      left: `${printingAreaSizes[activeIndex].leftScaled}px`,
                                    }}
                                  >
                                    {drag && <Background />}
                                    {drag && (
                                      <PrintAreTextContainer>
                                        <PrintAreaText>
                                          {t("print-area")}
                                        </PrintAreaText>
                                      </PrintAreTextContainer>
                                    )}
                                    <Draggable
                                      key={counter}
                                      position={{
                                        x: photosLogoPosition[activeIndex].left,
                                        y: photosLogoPosition[activeIndex].top,
                                      }}
                                      onDrag={(e, data) => {
                                        setPhotosLogoPosition((prev) => {
                                          prev[activeIndex].left = data.x;
                                          prev[activeIndex].top = data.y;

                                          return [...prev];
                                        });
                                      }}
                                      onStart={(e) => {
                                        const isIcon =
                                          e.target.closest(".toolIcon");
                                        if (!isIcon) {
                                          setDrag(true);
                                          handleFirstDrag();
                                        } else {
                                          return false;
                                        }
                                      }}
                                      onStop={() => setDrag(false)}
                                      disabled={drag}
                                    >
                                      <LogoContainer ref={divRef}>
                                        <div
                                          style={{
                                            rotate: `${photosLogoPosition[activeIndex].rotateAngle}deg`,
                                            width:
                                              photosLogoPosition[activeIndex]
                                                ?.width || "100%",
                                            height:
                                              photosLogoPosition[activeIndex]
                                                ?.height || "100%",
                                          }}
                                        >
                                          <RemoveLogo
                                            className="toolIcon"
                                            $hover={hover}
                                            onClick={handleRemoveLogoFromPhoto}
                                          >
                                            <RemoveIcon />
                                          </RemoveLogo>
                                          <RotateLogo
                                            className="toolIcon"
                                            $hover={hover}
                                            onMouseDown={handleRotate}
                                            onTouchStart={handleRotate}
                                          >
                                            <RotateIcon />
                                          </RotateLogo>

                                          <ResizeLogo
                                            className="toolIcon"
                                            $hover={hover}
                                            onTouchStart={handleResize}
                                            onMouseDown={handleResize}
                                          >
                                            <ResizeIcon />
                                          </ResizeLogo>
                                          <PhotoBorder $hover={hover}>
                                            <PrintLogoInvisible
                                              src={logoUrl}
                                              onMouseEnter={() =>
                                                setHover(true)
                                              }
                                              onMouseLeave={() =>
                                                setHover(false)
                                              }
                                            />
                                          </PhotoBorder>
                                        </div>
                                      </LogoContainer>
                                    </Draggable>
                                  </PrintAreaContainer>
                                </>
                              )}

                            {(logo || editItemData?.uploadLogoName) &&
                              printingAreaSizes[activeIndex].hasArea &&
                              photosLogoPosition[activeIndex].hasLogo &&
                              photosLogoPosition[activeIndex].isActive && (
                                <PrintAreaContainer
                                  style={{
                                    width: `${printingAreaSizes[activeIndex].widthScaled}px`,
                                    height: `${printingAreaSizes[activeIndex].heightScaled}px`,
                                    top: `${printingAreaSizes[activeIndex].topScaled}px`,
                                    left: `${printingAreaSizes[activeIndex].leftScaled}px`,
                                    overflow: "hidden",
                                  }}
                                  $hover={hover}
                                  onMouseEnter={() => setHover(true)}
                                  onMouseLeave={() => setHover(false)}
                                >
                                  <LogoContainer>
                                    <div
                                      key={activeIndex + counter}
                                      style={{
                                        width: `${photosLogoPosition[activeIndex].width}px`,
                                        height: `${photosLogoPosition[activeIndex].height}px`,
                                        transform: `translate(${photosLogoPosition[activeIndex]?.left}px, ${photosLogoPosition[activeIndex]?.top}px)`,
                                      }}
                                    >
                                      <PrintLogo
                                        ref={imgRef}
                                        src={logoUrl}
                                        style={{
                                          rotate: `${photosLogoPosition[activeIndex].rotateAngle}deg`,
                                          display:
                                            loadingLogo || loadingImage
                                              ? "none"
                                              : "",
                                        }}
                                        onLoad={(ev) => {
                                          onLoadLogo(ev);
                                        }}
                                        onLoadStart={() => {
                                          setLoadingLogo(true);
                                        }}
                                      />
                                    </div>
                                  </LogoContainer>
                                </PrintAreaContainer>
                              )}

                            {photos?.length > 1 ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 10,
                                  width: "100%",
                                }}
                                {...handlers}
                              >
                                <MediaSlider
                                  key={counter}
                                  pictures={photos}
                                  imageRef={itemPhotoRef}
                                  activeIndex={activeIndex}
                                  onSlideChange={(index) => {
                                    handleChangePhotoIndex(index);
                                  }}
                                  onLoadPicture={onLoadPicture}
                                  style={{
                                    width: 500,
                                    borderRadius: 10,
                                    pointerEvents: "none",
                                    objectFit: "contain",
                                    userSelect: "none",
                                  }}
                                />
                              </div>
                            ) : (
                              photos && (
                                <Img
                                  $contain={!!item.productId}
                                  src={photos[0]?.url}
                                  imageRef={itemPhotoRef}
                                  onLoad={onLoadPicture}
                                />
                              )
                            )}
                          </ImageContainer>
                          {photos?.length > 1 && (
                            <Dots
                              total={photos?.length}
                              activeIndex={activeIndex}
                              onDotClick={handleChangePhotoIndex}
                              rotate
                            />
                          )}
                        </Card>
                        <FooterCard>
                          <Button
                            onClick={() => {
                              if (!businessUser.loggedIn) {
                                setLoginModal(true);
                              } else if (validate()) {
                                setInstantQuoteModal(true);
                              }
                            }}
                          >
                            {t("instant-quote")}
                          </Button>
                          {item.samplePrice && (
                            <Button
                              style={{ backgroundColor: "#7D6460" }}
                              onClick={() => {
                                setBuySampleModal(true);
                              }}
                            >
                              {t("buy-sample")}
                            </Button>
                          )}
                        </FooterCard>
                      </StyledColumn>
                      <StyledColumn>
                        <StyledCard>
                          <Name>
                            {ar && item?.nameAr ? item?.nameAr : item?.name}
                          </Name>
                          <Description>
                            {ar && item?.descriptionAr
                              ? item?.descriptionAr
                              : item?.description}
                          </Description>

                          <Titles>
                            {subcategoriesContainColor && (
                              <TitleContainer>
                                <Title>- {t("choose-your-colors")}</Title>
                                <Colors>
                                  {Object.values(item.subcategories).map(
                                    (color) =>
                                      color
                                        .filter(
                                          (array) =>
                                            array.subcategoryName === "color"
                                        )
                                        .map((c) => (
                                          <Color
                                            onClick={() => handlePickColor(c)}
                                            key={c.valueId}
                                            style={{
                                              backgroundColor: c.value,
                                              border:
                                                c.value === "white"
                                                  ? "3px solid #8A4B5E"
                                                  : activeColor?.id ===
                                                    c.valueId
                                                  ? "3px solid #fff"
                                                  : null,
                                              transform:
                                                activeColor?.id === c.valueId
                                                  ? "scale(1.2)"
                                                  : "scale(1)",
                                              opacity:
                                                activeColor === "" ||
                                                activeColor?.id === c.valueId
                                                  ? 1
                                                  : 0.2,
                                              outline:
                                                activeColor?.id === c.valueId
                                                  ? "2px solid #8A4B5E"
                                                  : null,
                                            }}
                                          />
                                        ))
                                  )}
                                </Colors>
                              </TitleContainer>
                            )}
                            {subcategoriesContainSize && (
                              <TitleContainer>
                                <Title>- {t("choose-your-size")}</Title>
                                <Colors>
                                  {Object.values(item.subcategories).map(
                                    (size) =>
                                      size
                                        .filter(
                                          (array) =>
                                            array.subcategoryName === "size"
                                        )
                                        .map((item) => (
                                          <Size
                                            onClick={() => handlePickSize(item)}
                                            key={item.valueId}
                                            style={{
                                              border:
                                                item.value === "white"
                                                  ? "1.5px solid #8A4B5E"
                                                  : activeSize?.id ===
                                                    item.valueId
                                                  ? "2px solid #fff"
                                                  : null,
                                              transform:
                                                activeSize?.id === item.valueId
                                                  ? "scale(1.2)"
                                                  : "scale(1)",
                                              opacity:
                                                activeSize === ""
                                                  ? 1
                                                  : activeSize?.id ===
                                                    item.valueId
                                                  ? 1
                                                  : 0.2,
                                              outline:
                                                activeSize?.id === item.valueId
                                                  ? "2px solid #8A4B5E"
                                                  : null,
                                            }}
                                          >
                                            <SizeValue>{item.value}</SizeValue>
                                          </Size>
                                        ))
                                  )}
                                </Colors>
                              </TitleContainer>
                            )}

                            {item.uploadDesign && (
                              <TitleContainer>
                                <Title>- {t("upload-your-designs")}</Title>
                                <TextColumn>
                                  <Text>{t("maximum-file-size")}</Text>
                                  <Text>
                                    {(logo || editItemData?.uploadLogoName) &&
                                      t("file-name")}{" "}
                                    <Green>
                                      {logo && logo.name
                                        ? truncateText(logo?.name, 32)
                                        : editItemData &&
                                          editItemData?.uploadLogoName
                                        ? truncateText(
                                            editItemData.uploadLogoName,
                                            32
                                          )
                                        : null}
                                    </Green>
                                  </Text>
                                </TextColumn>
                                {logo || editItemData.uploadLogoName ? (
                                  <ButtonsContainer>
                                    <Relative>
                                      <Button
                                        $upload
                                        style={{
                                          backgroundColor: "#8A4B5E",
                                          fontSize: "3rem",
                                          width: 464,
                                          boxShadow: "none",
                                        }}
                                        onClick={() => {}}
                                      >
                                        {t("upload-logo")}
                                      </Button>
                                      <ChooseFile
                                        type="file"
                                        ref={fileInputRef}
                                        accept="image/*"
                                        onChange={handleFileChange}
                                      />
                                    </Relative>
                                    <Button
                                      style={{
                                        backgroundColor: "#EA435D",
                                        width: 56,
                                        boxShadow: "none",
                                      }}
                                      onClick={() => {
                                        setLogo(null);
                                        setLogoUrl(null);
                                        setLogoName(null);
                                      }}
                                    >
                                      <div>
                                        <Trash fill="white" />
                                      </div>
                                    </Button>
                                  </ButtonsContainer>
                                ) : (
                                  <Relative>
                                    <Button
                                      style={{
                                        backgroundColor: "#8A4B5E",
                                        fontSize: "3rem",
                                        width: "100%",
                                        boxShadow: "none",
                                      }}
                                      onClick={() => {}}
                                    >
                                      {t("upload-logo")}
                                    </Button>
                                    <ChooseFile
                                      type="file"
                                      ref={fileInputRef}
                                      accept="image/*"
                                      onChange={handleFileChange}
                                    />
                                  </Relative>
                                )}
                              </TitleContainer>
                            )}
                            <StyledTitleContainer>
                              <StyledRow>
                                <Title>- {t("i-would-like")}</Title>
                                <MinQuantityContainer>
                                  <Input
                                    placeholder={
                                      item.minQuantity !== null
                                        ? item.minQuantity
                                        : "1"
                                    }
                                    min={1}
                                    autoComplete="off"
                                    type="number"
                                    value={quantityValue}
                                    onChange={(e) =>
                                      setQuantityValue(e.target.value)
                                    }
                                  />
                                  <Title>{t("items")}</Title>
                                  {item.minQuantity !== null && (
                                    <Red>(min {item.minQuantity})</Red>
                                  )}
                                </MinQuantityContainer>
                                {item?.priceBreaks?.length !== 0 && (
                                  <Button
                                    style={{
                                      backgroundColor: "#EA435D",
                                      fontSize: "3rem",
                                      width: "100%",
                                      boxShadow: "none",
                                    }}
                                    onClick={handlePriceBreaksClick}
                                  >
                                    {t("price-breaks")}
                                  </Button>
                                )}
                              </StyledRow>
                            </StyledTitleContainer>
                          </Titles>
                        </StyledCard>
                        <FooterCard>
                          <FooterColumn>
                            <FooterRow>
                              {!item.productId && (
                                <>
                                  <FooterText>
                                    {t("production-time")}:
                                  </FooterText>
                                  <FooterValues>
                                    {item.productionTime === 1 ? (
                                      <>
                                        {item.productionTime}{" "}
                                        {t("business-day-small")}
                                      </>
                                    ) : (
                                      <>
                                        {item.productionTime}{" "}
                                        {t("business-days-small")}
                                      </>
                                    )}
                                  </FooterValues>
                                </>
                              )}
                            </FooterRow>
                            <FooterRow>
                              <FooterText>{t("total-price")}:</FooterText>
                              <FooterValues>
                                {priceFormatter(
                                  quantityValue === ""
                                    ? item.price
                                    : totalItemPrice
                                )}{" "}
                                {getCurrencySymbol(item?.currency)}
                              </FooterValues>
                            </FooterRow>
                          </FooterColumn>
                          <Button
                            style={{
                              backgroundColor: "#85A195",
                            }}
                            onClick={() => {
                              if (validate()) {
                                if (editItemData?.id) {
                                  dispatch(
                                    editCartItem({
                                      indexToRemove: editItemData.index,
                                      newItem: createCartItemObject(),
                                      businessUserId: businessUser.id,
                                    })
                                  );
                                  toast.success(t("item-edited-successfully"));
                                  if (businessUser.loggedIn) {
                                    navigate("/admin-portal/cart");
                                  } else {
                                    navigate("/cart");
                                  }
                                } else {
                                  addToCartAndCreatePhotosWithLogo();
                                }
                              }
                            }}
                          >
                            {!editItemData?.id
                              ? t("add-to-cart")
                              : t("edit-item")}
                          </Button>
                        </FooterCard>
                      </StyledColumn>
                    </Row>
                  </>
                ) : (
                  <ErrorDiv>
                    <Title>{t("item-does-not-exist")}</Title>
                    <Success src={FailedImg} alt="success-img" />
                    <SubTitle
                      onClick={() =>
                        !businessUser.loggedIn
                          ? navigate("/marketplace")
                          : navigate("/admin-portal/marketplace")
                      }
                    >
                      {t("go-back-marketplace")}{" "}
                    </SubTitle>
                  </ErrorDiv>
                )}
              </>
            ) : (
              <LoaderDiv>
                <MiniLoader />
              </LoaderDiv>
            )}
          </Column>
        </Layout>
        <LoginModal
          open={loginModal}
          close={() => setLoginModal(false)}
          title
        />
        <PriceBreakModal
          close={() => setPriceBreaksModal(false)}
          open={priceBreaksModal}
          item={item}
          data={item.priceBreaks}
        />
        <CartModal
          close={() => setCartModal(false)}
          open={cartModal}
          loadingItemId={loadingItemId}
        />
        <InstantQuoteModal
          open={instantQuoteModal}
          close={() => setInstantQuoteModal(false)}
          minQuantity={item.minQuantity ?? 1}
          itemId={itemId}
          subcategoryIds={[activeColor, activeSize, activeCard].filter(
            (item) => Object.keys(item).length > 0
          )}
        />
        <BuySampleModal
          open={buySampleModal}
          quantityBlank={quantityBlank}
          setQuantityBlank={setQuantityBlank}
          quantityBranded={quantityBranded}
          setQuantityBranded={setQuantityBranded}
          item={item}
          errorBranded={errorBranded}
          errorBlank={errorBlank}
          onBuyPrinted={() => {
            if (validate()) {
              if (quantityBranded <= 0) {
                setErrorBranded(true);
                return;
              } else {
                dispatch(
                  addToCart({
                    id: item.id,
                    name: item.name,
                    photo: photos[0],
                    color: {
                      id: activeColor?.id,
                      name: activeColor?.name,
                    },
                    size: {
                      id: activeSize?.id,
                      name: activeSize?.name,
                    },
                    giftCard: {
                      id: activeCard?.id,
                      name: activeCard?.name,
                      price: activeCard?.price,
                    },
                    uploadLogoUrl: logoUrl,
                    priceBreaks: item.priceBreaks,
                    samplePrice: null,
                    printedPrice: item.printedPrice,
                    itemPrice: activeCard.price ? activeCard.price : item.price,
                    printedQuantity: quantityBranded,
                    sampleQuantity: null,
                    minQuantity: item.minQuantity,
                    quantity: +quantityValue,
                    productionTime: item.productionTime,
                    totalPrice: null,
                    buyBlank: false,
                    buyPrinted: true,
                    currency: item.currency,
                    favorite: item.favorite,
                    businessUserId: businessUser.id,
                  })
                );
                toast.success(t("item-added-to-cart-successfully"));
                setQuantityBlank(0);

                setQuantityBranded(0);
                setBuySampleModal(false);
                setErrorBlank(false);
                setErrorBranded(false);
                setCartModal(true);
              }
            }
          }}
          onBuyBlank={() => {
            if (validate()) {
              if (quantityBlank <= 0) {
                setErrorBlank(true);
              } else {
                dispatch(
                  addToCart({
                    id: item.id,
                    name: item.name,
                    photo: photos[0],
                    color: {
                      id: activeColor?.id,
                      name: activeColor?.name,
                    },
                    size: {
                      id: activeSize?.id,
                      name: activeSize?.name,
                    },
                    giftCard: {
                      id: activeCard?.id,
                      name: activeCard?.name,
                      price: activeCard?.price,
                    },
                    uploadLogoUrl: logoUrl,
                    priceBreaks: item.priceBreaks,
                    printedPrice: null,
                    sampleQuantity: quantityBlank,
                    samplePrice: item.samplePrice,
                    printedQuantity: null,
                    itemPrice: item.price,
                    minQuantity: item.minQuantity,
                    quantity: +quantityValue,
                    productionTime: item.productionTime,
                    totalPrice: null,
                    buyBlank: true,
                    buyPrinted: false,
                    currency: item.currency,
                    favorite: item.favorite,
                    businessUserId: businessUser.id,
                  })
                );
                toast.success(t("item-added-to-cart-successfully"));
                setBuySampleModal(false);
                setQuantityBlank(0);
                setQuantityBranded(0);
                setErrorBlank(false);
                setErrorBranded(false);
                setCartModal(true);
              }
            }
          }}
          close={() => setBuySampleModal(false)}
        />

        <KeepInMindModal
          open={showKeepInMindModal}
          close={() => setShowKeepInMindModal(false)}
        />
      </MainContainer>
      {!businessUser.loggedIn && <Footer />}
    </>
  );
};

export default MarketplaceItemDetailPage;
