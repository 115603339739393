import React, { useEffect, useRef, useState } from "react";
import {
  MainContainer,
  Column,
  Content,
  SectionTitle,
  HeaderWrapper,
} from "./AccountSettingsPage.styles";
import Sidebar from "../utils/Sidebar";

import { useDispatch, useSelector } from "react-redux";
import {
  editingLoader,
  setBusinessUserInfo,
  setInvitationLink,
} from "../../../store/slices/businessUser/slice";
import { toast } from "react-toastify";
import {
  editCompanyInfo,
  editCompanyProfile,
  getInvitationLink,
} from "../../../store/slices/businessUser/asyncThunks";
import { useTranslation } from "react-i18next";
import { setCompanyInfo } from "../../../store/slices/businessUser/slice";
import { getCompanyUsers } from "../../../store/slices/company/asyncThunks";
import {
  companyUsersData,
  companyUsersLoader,
} from "../../../store/slices/company/slice";
import {
  selectCompanyInfo,
  selectBusinessUserInfo,
} from "../../../store/slices/businessUser/slice";
import AccountSettingTabs from "../../../components/AccountSettingsComponents/AccountSettingTabs/AccountSettingTabs";
import TabGeneral from "../../../components/AccountSettingsComponents/TabGeneral/TabGeneral";
import TabBilling from "../../../components/AccountSettingsComponents/TabBilling/TabBilling";
import TabManage from "../../../components/AccountSettingsComponents/TabManage/TabManage";
import TabCreditCards from "../../../components/AccountSettingsComponents/TabCreditCards/TabCreditCards";
import Header from "../../../components/Header/Header";
import openToast from "../../../utils/toast";
import { useLocation, useNavigate } from "react-router-dom";
import { selectedLocale } from "../../../store/slices/common/slice";

const TAB_GENERAL = "general";
const TAB_BILLING = "billing";
const TAB_MANAGE = "manage";
const TAB_CREDIT_CARDS = "creditCards";

const AccountSettingsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState(null);
  const dispatch = useDispatch();
  const [tab, setTab] = useState(TAB_GENERAL);
  const [searchQuery, setSearchQuery] = useState("");
  const businessUser = useSelector(selectBusinessUserInfo);
  const company = useSelector(selectCompanyInfo);
  const companyUsers = useSelector(companyUsersData);
  const companyLoading = useSelector(companyUsersLoader);
  const [image, setImage] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [fullName, setFullName] = useState("");
  const [name, setName] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [address, setAddress] = useState("");
  const [addressOptional, setAddressOptional] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [VAT, setVAT] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;
  const saveLoading = useSelector(editingLoader);
  const [selectedUser, setSelectedUser] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");

  const indexOfLastUser = +page * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  const compUsers = companyUsers ?? [];
  const filteredUsers = Object.values(compUsers).filter((user) => {
    const nameMatch = user.fullName
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const emailMatch = user.email
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    return nameMatch || emailMatch;
  });

  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
  const toastId = useRef(null);

  useEffect(() => {
    if (company) {
      setFullName(businessUser.fullName);
      setCompanyLogo(company.companyLogo);
      setName(company.name);
      setAddress(company.address);
      setAddressOptional(company.addressOptional);
      setBillingEmail(company.billingEmail);
      setVAT(company.VAT);
      setCity(company.city);
      setCountry(company.country);
      setZipCode(company.zipCode);
    }
  }, [businessUser, company]);

  useEffect(() => {
    dispatch(getCompanyUsers())
      .unwrap()
      .then((response) => {})
      .catch((error) => {
        toast.error(t(error.message));
      });
  }, [dispatch]);

  useEffect(() => {
    if (!page && tab === TAB_MANAGE) {
      setTab(TAB_GENERAL);
    }
  }, [page, queryParams, tab]);

  const handlePageChange = (pageNumber, paramName) => {
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set(paramName, pageNumber);

    const url = `/admin-portal/account-settings?${currentParams.toString()}`;
    navigate(url);
  };

  const handleTab = (tab) => {
    if (tab === TAB_MANAGE) {
      const query = { page: 1 };
      const queryString = new URLSearchParams(query).toString();
      const url = `/admin-portal/account-settings?${queryString}`;
      navigate(url);
    } else {
      navigate("/admin-portal/account-settings");
    }
    setTab(tab);
  };

  const editProfile = () => {
    setIsEditable(false);
    let formData = new FormData();
    formData.append("file", image);
    formData.append("name", name);
    formData.append("fullName", fullName);

    dispatch(editCompanyProfile(formData))
      .unwrap()
      .then((response) => {
        setBusinessUserInfo(response);
        setCompanyLogo(previewImage);
      })
      .catch((error) => {
        openToast(toastId, "edit-profile", "error", t(error.message));
      });
  };

  const handleImagePreview = async (e) => {
    if (e.target.files.length !== 0) {
      let imageFile = e.target.files[0];
      if (imageFile.type.startsWith("image/")) {
        let imagePreviewUrl = URL.createObjectURL(imageFile);

        setImage(imageFile);
        setPreviewImage(imagePreviewUrl);
        setCompanyLogo(imagePreviewUrl);
      } else {
        openToast(
          toastId,
          "image-preview-account-settings",
          "warning",
          t("Please select an image file.")
        );
      }
    }
  };

  const handleEdit = () => {
    setIsEditable(true);
  };

  const onSaveClick = async () => {
    try {
      setIsEditable(false);
      validateForm();
      dispatch(
        editCompanyInfo({
          billingEmail,
          address,
          addressOptional,
          city,
          zipCode,
          country,
          ...(VAT && { VAT }),
        })
      )
        .unwrap()
        .then((response) => {
          const company = response;
          dispatch(setCompanyInfo(company));
        })
        .catch((error) => {
          openToast(toastId, "edit-company-info", "error", t(error.message));
        });
    } catch (error) {
      openToast(toastId, "edit-company-info", "error", t(error.message));
    }
  };

  const handleGetInvitationLink = () => {
    dispatch(getInvitationLink(businessUser.id))
      .then((response) => {
        const invitationLink = response.payload;
        dispatch(setInvitationLink(invitationLink));
      })
      .catch((error) => {
        toast.error(t(error.message));
      });
  };

  const validateForm = () => {
    let formIsValid = true;

    if (!billingEmail) {
      openToast(
        toastId,
        "edit-company-info",
        "error",
        t("billing-email-is-required")
      );
      formIsValid = false;
    }

    if (!address) {
      openToast(
        toastId,
        "edit-company-info",
        "error",
        t("address-is-required")
      );
      formIsValid = false;
    }

    if (!zipCode) {
      openToast(
        toastId,
        "edit-company-info",
        "error",
        t("zip-code-is-required")
      );
      formIsValid = false;
    }

    if (!city) {
      openToast(toastId, "edit-company-info", "error", t("city-is-required"));
      formIsValid = false;
    }
    if (!country) {
      openToast(
        toastId,
        "edit-company-info",
        "error",
        t("country-is-required")
      );
      formIsValid = false;
    }

    return formIsValid;
  };

  const showUpgradeBar = businessUser.company.packageType === "Free";
  let diff =
    new Date(businessUser.company?.packageExpiration).getTime() -
    new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  const locale = useSelector(selectedLocale);
  const upgradePackage = showUpgradeBar && diff > 0 && businessUser.state;
  const ar = locale === "ar";

  return (
    <MainContainer>
      <HeaderWrapper>
        <Header
          showLogin
          showSignUp
          showNav
          color
          showCart
          showFavorite
          showUser
        />
      </HeaderWrapper>
      <Sidebar />

      <Column $upgradePackage={upgradePackage} $ar={ar}>
        <SectionTitle>{t("account-settings")}</SectionTitle>
        <Content>
          <AccountSettingTabs
            tab={tab}
            handleTab={handleTab}
            setIsEditable={setIsEditable}
          />
          {tab === TAB_GENERAL && (
            <TabGeneral
              setFullName={setFullName}
              fullName={fullName}
              setIsEditable={setIsEditable}
              isEditable={isEditable}
              businessUser={businessUser}
              company={company}
              name={name}
              setName={setName}
              companyLogo={companyLogo}
              handleImagePreview={handleImagePreview}
              saveLoading={saveLoading}
              handleEdit={handleEdit}
              editProfile={editProfile}
              previewImage={previewImage}
            />
          )}
          {tab === TAB_BILLING && (
            <TabBilling
              isEditable={isEditable}
              billingEmail={billingEmail}
              setBillingEmail={setBillingEmail}
              company={company}
              address={address}
              setAddress={setAddress}
              addressOptional={addressOptional}
              setAddressOptional={setAddressOptional}
              city={city}
              setCity={setCity}
              VAT={VAT}
              setVAT={setVAT}
              zipCode={zipCode}
              setZipCode={setZipCode}
              country={country}
              setCountry={setCountry}
              businessUser={businessUser}
              setIsEditable={setIsEditable}
              handleEdit={handleEdit}
              onSaveClick={onSaveClick}
              saveLoading={saveLoading}
            />
          )}
          {tab === TAB_MANAGE && (
            <TabManage
              businessUser={businessUser}
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              handleGetInvitationLink={handleGetInvitationLink}
              companyLoading={companyLoading}
              currentUsers={currentUsers}
              currentPage={+page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              selectedUser={selectedUser}
              totalNumOfUsers={compUsers.length}
              perPage={usersPerPage}
            />
          )}
          {tab === TAB_CREDIT_CARDS && <TabCreditCards />}
        </Content>
      </Column>
    </MainContainer>
  );
};

export default AccountSettingsPage;
