import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MainContainer,
  Column,
  ContentContainer,
  ItemContainer,
  LoadingContainer,
  HeaderWrapper,
} from "./AllSectionItemsPage.styles";
import ContentHeader from "../utils/ContentHeader";
import { getItemsBySection } from "../../../store/slices/gift/asyncThunks";
import { useNavigate, useParams } from "react-router-dom";
import {
  selectRecipientsWithWishlists,
  selectedSectionData,
  selectedSectionLoading,
  selectedSectionTitle,
} from "../../../store/slices/gift/slice";
import {
  selectGifts,
  removeGift,
  addGifts,
} from "../../../store/slices/sendGifts/slice";
import { useTranslation } from "react-i18next";
import GiftItemCard from "../../../components/GiftItemCard/GiftItemCard";
import Header from "../../../components/Header/Header";
import openToast from "../../../utils/toast";
import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";
import CategoryBar from "../../../components/CategoryBar/CategoryBar";
import AppLoader from "../../../components/AppLoader";

const AllSectionItemsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(selectedSectionData);
  const sectionTitle = useSelector(selectedSectionTitle);
  const { sectionId } = useParams();
  const selectedItems = useSelector(selectGifts);
  const { t } = useTranslation();
  const loading = useSelector(selectedSectionLoading);
  const toastId = useRef(null);
  const { company, state } = useSelector(selectBusinessUserInfo);
  const recipientsWithWishlist = useSelector(selectRecipientsWithWishlists);
  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  useEffect(() => {
    dispatch(
      getItemsBySection({
        sectionId,
        page: 1,
        order: "DESC",
        sortBy: "CreatedAt",
        search: "",
      })
    );
  }, [dispatch, sectionId]);

  const handleItemClick = (id) => {
    navigate(`/admin-portal/item-detail/${id}`);
  };

  const addToItemCart = (item) => {
    let foundItem = false;

    selectedItems.forEach((data) => {
      if (data.id === item.id) {
        const itemSubcategories = data.subcategory?.map(
          (subcategory) => subcategory.valueId
        );
        const addedItemSubcategories = item.subcategoryItems?.map(
          (subcategory) => subcategory.valueId
        );

        if (
          itemSubcategories?.sort().join(",") ===
          addedItemSubcategories?.sort().join(",")
        ) {
          foundItem = true;
        }
      }
    });

    if (!foundItem) {
      if (recipientsWithWishlist[0]?.items?.length > 0) {
        if (selectedItems.length === 1) {
          openToast(
            toastId,
            "add-item-to-cart",
            "error",
            t("select-max-1-item")
          );
          return;
        }
      }

      if (
        recipientsWithWishlist[0]?.items?.length === 0 ||
        recipientsWithWishlist.length === 0
      ) {
        if (selectedItems.length === 3) {
          openToast(
            toastId,
            "add-item-to-cart",
            "error",
            t("select-max-3-items")
          );
          return;
        }
      }
      dispatch(
        addGifts({
          id: item.id,
          image: item.photoUrl,
          name: item.name,
          price: item.price,
          currency: item.currency,
          subcategory: item.subcategoryItems,
        })
      );
    } else {
      dispatch(removeGift(item.id));
    }
  };

  return (
    <>
      <HeaderWrapper>
        <Header showLogin showNav color showUser showCart showFavorite />
      </HeaderWrapper>

      <MainContainer>
        <CategoryBar />
        <Column $upgradePackage={showUpgradeBar && diff > 0 && state}>
          <ContentHeader
            showTitle={true}
            showBack={true}
            title={t(`${sectionTitle}`)}
          />
          <ContentContainer style={{ marginTop: 40 }}>
            {!loading ? (
              <ItemContainer>
                {data.map((item) => (
                  <GiftItemCard
                    item={item}
                    key={item.id}
                    itemDetails={() => {
                      handleItemClick(item.id);
                    }}
                    isSelected={
                      !!selectedItems.filter(
                        (selectedItem) => item.id === selectedItem.id
                      ).length
                    }
                    addToItemCart={addToItemCart}
                  />
                ))}
              </ItemContainer>
            ) : (
              <LoadingContainer>
                <AppLoader />
              </LoadingContainer>
            )}
          </ContentContainer>
        </Column>
      </MainContainer>
    </>
  );
};

export default AllSectionItemsPage;
