import React from "react";
import {
  ContentContainer,
  Title,
  TitleContainer,
  CloseContainer,
  Column,
  ButtonNo,
  ButtonYes,
} from "../InviteUserModal/InviteUserModal.style";

import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Loader } from "../../assets/icons/loader-white.svg";

import { useTranslation } from "react-i18next";
import Modal2 from "../Modal/Modal2";

const ModalConfirm = ({
  onClickYes,
  onClickNo,
  title,
  confirmLoading,
  open,
  close,
}) => {
  const { t } = useTranslation();

  return (
    <Modal2
      border={"2px solid #8A4B5E"}
      bg={"linear-gradient(360deg, #a87283 -100%, #8A4B5E 100%)"}
      onClose={close}
      open={open}
      height={"fit-content"}
      width={"fit-content"}
    >
      <ContentContainer>
        <CloseContainer onClick={close}>
          <Close className="close" fill="#fff" />
        </CloseContainer>

        <Column>
          <TitleContainer>
            {!title ? (
              <Title>{t("are-you-sure")}</Title>
            ) : (
              <Title>{t(`${title}`)}</Title>
            )}
          </TitleContainer>
          <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
            <ButtonYes
              loading={confirmLoading}
              style={{ padding: "10px 24px" }}
              onClick={() => {
                if (!confirmLoading) {
                  onClickYes();
                }
              }}
            >
              {confirmLoading ? <Loader height={24} /> : t(`${"yes"}`)}
            </ButtonYes>
            {!confirmLoading && (
              <ButtonNo style={{ padding: "10px 24px" }} onClick={onClickNo}>
                {t("no")}
              </ButtonNo>
            )}
          </div>
        </Column>
      </ContentContainer>
    </Modal2>
  );
};

export default ModalConfirm;
