import styled from "styled-components";

export const MainContainer = styled.div`
  flex-direction: column;
  background: rgb(255, 255, 255);
  min-height: 100vh;
  display: flex;
`;

export const HeaderWrapper = styled.div`
  background: #8a4b5e;
  position: fixed;
  z-index: 1000;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
`;
export const ItemContainer = styled.div`
  display: flex;

  flex-direction: row;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 40px;
`;
export const Title = styled.span`
  font-size: 32px;
  font-family: "Avenir-Black";

  letter-spacing: 0.5%;
  text-align: center;
  color: #8a4b5e;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  margin-top: ${(props) => (props.$upgradePackage ? "160px" : "100px")};
`;
