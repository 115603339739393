import React, { useEffect, useState } from "react";
import {
  Content,
  CloseIcon,
  IconContainer,
  ChevronContainer,
  ChevronLeft,
  ChevronRight,
  IconsContainer,
} from "./GalleryModal.styles";
import Modal from "./ModalTemplate";

const GalleryModal = ({ open, onClose, width, height, images, index }) => {
  const [currentIndex, setCurrentIndex] = useState("");
  const [imagesArray, setImagesArray] = useState([]);

  useEffect(() => {
    setCurrentIndex(index);
    setImagesArray(images);
  }, [index, images]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images?.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images?.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "a" || event.key === "ArrowLeft") {
        handlePrev();
      } else if (event.key === "d" || event.key === "ArrowRight") {
        handleNext();
      } else if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Modal
      hideScroll
      isOpen={open}
      width={width}
      onClose={onClose}
      height={height}
      fade
      padding={0}
    >
      <Content imageUrl={imagesArray[currentIndex]?.photoUrl}>
        <IconsContainer>
          <IconContainer onClick={onClose}>
            <CloseIcon />
          </IconContainer>
        </IconsContainer>

        <ChevronContainer>
          <IconContainer onClick={handlePrev}>
            <ChevronLeft />
          </IconContainer>

          <IconContainer onClick={handleNext}>
            <ChevronRight />
          </IconContainer>
        </ChevronContainer>
      </Content>
    </Modal>
  );
};

export default GalleryModal;
