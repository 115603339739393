import {
  ChangeQuantityContainer,
  Color,
  Colors,
  ColumnItem,
  Content,
  ContentCard,
  FooterContainer,
  FooterText,
  ImgContainer,
  Input,
  ItemPhoto,
  ItemPrice,
  ItemProduction,
  ItemQuantity,
  ItemSpecifications,
  ItemTitle,
  ItemsCard,
  Quantity,
  Row,
  Size,
  SizeValue,
  StyledColumnItem,
  TitleContainer,
} from "./CartPage.styles";
import UseAnimations from "react-useanimations";
import heart from "react-useanimations/lib/heart";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getCurrencySymbol } from "../../../utils/constants";
import {
  changePackagingQuantity,
  toggleFavoriteItem,
} from "../../../store/slices/marketplace/marketplaceSlice";
import { useFavoriteItemMutation } from "../../../store/slices/marketplace/marketplaceApi";
import { useState } from "react";

const ProductCard = ({
  product,
  reverse,
  index,
  isLoggedIn,
  setOpenShareModal,
  setProductId,
  removeCartItem,
  setLoginModal,
  businessUser,
}) => {
  const handleShare = () => {
    setOpenShareModal(true);
    setProductId(product.id);
  };
  const [favoriteItem, result] = useFavoriteItemMutation();

  const remove = () => {
    dispatch(removeCartItem({ index, businessUserId: businessUser.id }));
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [quantityValue, setQuantityValue] = useState(1);

  return (
    <ItemsCard>
      <ContentCard>
        {!product.isPackaging && (
          <UseAnimations
            animation={heart}
            fillColor="#8A4B5E"
            strokeColor="#8A4B5E"
            reverse={reverse}
            wrapperStyle={{
              marginBottom: -3,
              position: "absolute",
              right: 10,
              top: 8,
            }}
            size={32}
            onClick={
              isLoggedIn
                ? () => {
                    dispatch(
                      toggleFavoriteItem({
                        id: product.id,
                        like: !reverse,
                        businessUserId: businessUser.id,
                      })
                    );
                    favoriteItem({ id: product.id });
                  }
                : () => setLoginModal(true)
            }
          />
        )}
        <ImgContainer>
          <ItemPhoto src={product?.photo?.url} alt="Item-img" />
        </ImgContainer>
        <Colors>
          {!product.isPackaging && product.color.id && (
            <Color
              style={{
                backgroundColor: product.color.name,
                border: "1.2px solid #8A4B5E",
              }}
            />
          )}
          {!product.isPackaging && product.size.id && (
            <Size>
              <SizeValue>{product.size.name}</SizeValue>
            </Size>
          )}
        </Colors>
        <Content>
          <TitleContainer>
            <ItemTitle
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                if (!product.isPackaging) {
                  !businessUser.loggedIn
                    ? navigate(`/marketplace/${product.id}`)
                    : navigate(
                        `/admin-portal/marketplace/item-detail/${product.id}`
                      );
                }
              }}
            >
              {product.name}
            </ItemTitle>
          </TitleContainer>

          <Row>
            <ColumnItem>
              <ItemSpecifications>
                {product.buyBlank && !product.buyPrinted ? (
                  <>
                    <ItemSpecifications>{t("quantity")}: </ItemSpecifications>
                    <ItemQuantity>{product.sampleQuantity}</ItemQuantity>
                  </>
                ) : product.buyPrinted ? (
                  <>
                    <ItemSpecifications>{t("quantity")}: </ItemSpecifications>
                    <ItemQuantity>{product.printedQuantity}</ItemQuantity>
                  </>
                ) : (
                  <>
                    {!product.isPackaging ? (
                      <>
                        <ItemSpecifications>
                          {t("quantity")}:{" "}
                        </ItemSpecifications>
                        <ItemQuantity>{product.quantity}</ItemQuantity>
                      </>
                    ) : (
                      <>
                        <ChangeQuantityContainer>
                          <Quantity>{t("quantity")}:</Quantity>
                          <Input
                            placeholder={1}
                            min={1}
                            autoComplete="off"
                            type="number"
                            value={product.quantity}
                            onChange={(e) =>
                              dispatch(changePackagingQuantity(e.target.value))
                            }
                          />
                        </ChangeQuantityContainer>
                      </>
                    )}
                  </>
                )}
              </ItemSpecifications>
              {!product.buyBlank && !product.buyPrinted ? (
                <ItemPrice>
                  <>
                    {!product.productId && (
                      <>
                        <ItemSpecifications>
                          {t("production")}:{" "}
                        </ItemSpecifications>
                        <ItemProduction>
                          {product.productionTime === 1 ? (
                            <>
                              {product.productionTime} {t("business-day-small")}
                            </>
                          ) : (
                            <>
                              {product.productionTime}{" "}
                              {t("business-days-small")}
                            </>
                          )}
                        </ItemProduction>
                      </>
                    )}
                  </>
                </ItemPrice>
              ) : product.buyBlank && !product.buyPrinted ? (
                <ItemSpecifications>{t("blank-sample")}</ItemSpecifications>
              ) : !product.buyBlank && product.buyPrinted ? (
                <ItemSpecifications>{t("printed-sample")}</ItemSpecifications>
              ) : null}
            </ColumnItem>
            <StyledColumnItem>
              <>
                <ItemSpecifications>{t("item-price")}</ItemSpecifications>
                <ItemSpecifications>{t("total")}</ItemSpecifications>
              </>
            </StyledColumnItem>
            <StyledColumnItem>
              {!product.buyBlank && !product.buyPrinted ? (
                <>
                  <ItemPrice>
                    {product?.itemPrice?.toFixed(2)}{" "}
                    {getCurrencySymbol(product.currency)}
                  </ItemPrice>
                  <ItemPrice>
                    {product?.totalPrice.toFixed(2)}{" "}
                    {getCurrencySymbol(product.currency)}
                  </ItemPrice>
                </>
              ) : (
                <>
                  <ItemPrice>
                    {product.buyBlank && !product.buyPrinted
                      ? `${product?.samplePrice?.toFixed(
                          2
                        )} ${getCurrencySymbol(product?.currency)}`
                      : `${product?.printedPrice?.toFixed(
                          2
                        )} ${getCurrencySymbol(product?.currency)}`}
                  </ItemPrice>
                  <ItemPrice>
                    {product.buyBlank &&
                      !product.buyPrinted &&
                      `${(product.samplePrice * product.sampleQuantity).toFixed(
                        2
                      )} ${getCurrencySymbol(product?.currency)}`}

                    {!product.buyBlank &&
                      product.buyPrinted &&
                      `${(
                        product.printedPrice * product.printedQuantity
                      ).toFixed(2)} ${getCurrencySymbol(product?.currency)}`}
                  </ItemPrice>
                </>
              )}
            </StyledColumnItem>
          </Row>
        </Content>
      </ContentCard>
      <FooterContainer>
        {!product.isPackaging && (
          <FooterText onClick={handleShare} style={{ cursor: "pointer" }}>
            {t("share")} |
          </FooterText>
        )}
        {!product.buyBlank &&
          !product.buyPrinted &&
          !product.uploadLogoUrl &&
          !product.isPackaging && (
            <FooterText
              onClick={() => {
                isLoggedIn
                  ? navigate(
                      `/admin-portal/marketplace/item-detail/${product.id}`,
                      {
                        state: { product: product, index: index },
                      }
                    )
                  : navigate(`/marketplace/${product.id}`, {
                      state: { product: product, index: index },
                    });
              }}
              style={{ cursor: "pointer" }}
            >
              {" "}
              {t("edit")} |
            </FooterText>
          )}

        <FooterText onClick={remove} style={{ cursor: "pointer" }}>
          {" "}
          {t("remove")}{" "}
        </FooterText>
      </FooterContainer>
    </ItemsCard>
  );
};

export default ProductCard;
