import React from "react";
import { ReactComponent as Unchecked } from "../../assets/icons/radio-check.svg";
import { ReactComponent as Checked } from "../../assets/icons/radio-on.svg";
import { StyledDiv, StyledRow, StyledText } from "./RadioButtons.styles";

const RadioButtons = ({
  checked,
  onChange,
  label1,
  label2,
  checked2,
  column,
  onChange2,
}) => {
  return (
    <StyledDiv $column={column}>
      <StyledRow onClick={onChange}>
        {checked ? (
          <Checked width={24} height={24} className="pointer" />
        ) : (
          <Unchecked width={24} height={24} className="pointer" />
        )}
        <StyledText $column={column}>{label1}</StyledText>
      </StyledRow>
      <StyledRow onClick={onChange2}>
        {checked2 ? (
          <Checked width={24} height={24} className="pointer" />
        ) : (
          <Unchecked width={24} height={24} className="pointer" />
        )}
        <StyledText $column={column}>{label2}</StyledText>
      </StyledRow>
    </StyledDiv>
  );
};

export default RadioButtons;
