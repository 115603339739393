import React from "react";
import {
  Content,
  Text,
  TextContainer,
  TitleContainer,
  Message,
  ViewMoreText,
  ViewMoreContainer,
  StyledAHref,
  LastTableItem,
  FirstItem,
  Chevron,
  TableBodyRow,
} from "../../screens/AdminPortal/CouponsPage/CouponsPage.styles";

import { useTranslation } from "react-i18next";
import { ReactComponent as Open } from "../../assets/icons/open.svg";
import { formatDate, priceFormatter } from "../../utils/utils";
import MiniLoader from "../MiniLoader/MiniLoader";
import Table from "../Table/Table";
import TableItem from "../Table/TableItem";

const AllSubs = ({
  data,
  ar,
  onClickMoreSubscriptions,
  onClickMoreChurned,
  onClickMoreBankTransfers,
}) => {
  const { t } = useTranslation();

  const tableHeaderTransfers = [
    {
      label: `${t("type")}`,
      sortable: true,
      key: "type",
    },
    {
      label: `${t("price-to-pay")}`,
      sortable: true,
      key: "priceToPay",
    },
    {
      label: `${t("period")}`,
      sortable: true,
      key: "period",
    },
    {
      label: `${t("subscription-from")}`,
      sortable: true,
      key: "subFrom",
    },
    {
      label: `${t("subscription-to")}`,
      sortable: true,
      key: "subTo",
    },
    {
      label: `${t("recipient-url")}`,
      sortable: true,
      key: "recipientUrl",
    },
    {
      label: `${t("num-of-users")}`,
      sortable: true,
      key: "numOfUsers",
    },
    {
      label: `${t("company")}`,
      sortable: true,
      key: "company",
    },
    {
      label: `${t("business-user")}`,
      sortable: true,
      key: "businessUser",
    },
    {
      label: `${t("coupon-name")}`,
      sortable: true,
      key: "couponName",
    },
  ];

  const tableHeaderSubscriptions = [
    {
      label: `${t("company-name")}`,
      sortable: true,
      key: "companyName",
    },
    {
      label: `${t("employees")}`,
      sortable: true,
      key: "employees",
    },
    {
      label: `${t("wallet")}`,
      sortable: true,
      key: "wallet",
    },
    {
      label: `${t("expiry-date")}`,
      sortable: true,
      key: "expiryDate",
    },
    {
      label: `${t("type")}`,
      sortable: true,
      key: "type",
    },
    {
      label: `${t("orders")}`,
      sortable: true,
      key: "orders",
    },
    {
      label: `${t("num-of-users")}`,
      sortable: true,
      key: "numOfUsers",
    },
  ];

  return (
    <>
      {!data?.loading ? (
        <>
          {data?.data?.bankTransfersForReview?.length <= 0 ? (
            <Content>
              <TextContainer>
                <Message>{t("you-currently-have-no-bank-transfers")}</Message>
              </TextContainer>
            </Content>
          ) : (
            <>
              <TitleContainer>
                <Text>{t("bank-transfers")}</Text>
                <ViewMoreContainer onClick={onClickMoreBankTransfers}>
                  <ViewMoreText>{t("view-more")}</ViewMoreText>
                  <Chevron $ar={ar} />
                </ViewMoreContainer>
              </TitleContainer>

              <Table
                marginTop={40}
                headerInformation={tableHeaderTransfers}
                totalCount={data?.data?.bankTransfersForReview?.length}
                currentPage={1}
                setPage={() => {}}
                perPage={100}
                noDataContent={t("no-data")}
                dataLoading={false}
                lastColumnAligned
                centerText
              >
                {data?.data?.bankTransfersForReview &&
                  Object.values(data?.data?.bankTransfersForReview).map(
                    (val, key) => {
                      return (
                        <TableBodyRow key={key} $offClick>
                          <FirstItem data={t(val.type.toLowerCase())} />

                          <TableItem
                            center
                            data={
                              priceFormatter(val.priceToPay, false, 0) + " SR"
                            }
                          />
                          <TableItem center data={t(val.period)} />
                          <TableItem
                            center
                            data={formatDate(val.subscriptionFrom)}
                          />
                          <TableItem
                            center
                            data={formatDate(val.subscriptionTo)}
                          />
                          <TableItem
                            center
                            data={
                              <StyledAHref
                                href={val.receiptUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Open />
                              </StyledAHref>
                            }
                          />
                          <TableItem data={val.numOfUsers || "/"} center />
                          <TableItem
                            hoverText={val?.company}
                            showHover
                            data={
                              val?.company
                                ? val?.company.length > 25
                                  ? val?.company.slice(0, 25) + "..."
                                  : val?.company
                                : "/"
                            }
                            center
                          />
                          <TableItem
                            center
                            hoverText={val?.businessUser}
                            showHover
                            data={
                              val?.businessUser
                                ? val?.businessUser.length > 25
                                  ? val?.businessUser.slice(0, 25) + "..."
                                  : val?.businessUser
                                : "/"
                            }
                          />
                          <LastTableItem
                            hoverText={val?.couponName}
                            showHover
                            data={
                              val?.couponName
                                ? val?.couponName.length > 25
                                  ? val?.couponName.slice(0, 25) + "..."
                                  : val?.couponName
                                : "/"
                            }
                            $offClick
                          />
                        </TableBodyRow>
                      );
                    }
                  )}
              </Table>
            </>
          )}

          {data?.data?.companySubscriptionsActive?.length <= 0 ? (
            <Content>
              <TextContainer>
                <Message>{t("you-currently-have-no-subscribers")}</Message>
              </TextContainer>
            </Content>
          ) : (
            <>
              <TitleContainer>
                <Text>{t("subscriptions")}</Text>
                <ViewMoreContainer onClick={onClickMoreSubscriptions}>
                  <ViewMoreText>{t("view-more")}</ViewMoreText>
                  <Chevron $ar={ar} />
                </ViewMoreContainer>
              </TitleContainer>

              <Table
                marginTop={40}
                headerInformation={tableHeaderSubscriptions}
                totalCount={data?.data?.companySubscriptionsActive?.length}
                currentPage={1}
                setPage={() => {}}
                perPage={100}
                noDataContent={t("no-data")}
                dataLoading={false}
                lastColumnAligned
                centerText
              >
                {data.data.companySubscriptionsActive &&
                  Object.values(data?.data?.companySubscriptionsActive).map(
                    (val, key) => {
                      return (
                        <TableBodyRow key={key} $offClick>
                          <FirstItem
                            hoverText={val?.company}
                            showHover
                            data={
                              val?.company.length > 25
                                ? val?.company.slice(0, 25) + "..."
                                : val?.company
                            }
                            center
                          />

                          <TableItem center data={val.employees} />
                          <TableItem
                            center
                            data={priceFormatter(val.wallet, false, 0) + " SR"}
                          />
                          <TableItem
                            center
                            data={formatDate(val.packageExpiration)}
                          />
                          <TableItem center data={t(val.type.toLowerCase())} />
                          <TableItem center data={val.orders} />

                          <LastTableItem
                            data={val.numOfUsers || "/"}
                            $offClick
                          />
                        </TableBodyRow>
                      );
                    }
                  )}
              </Table>
            </>
          )}
          {data?.data?.companySubscriptionsExpired?.length <= 0 ? (
            <Content>
              <TextContainer>
                <Message>{t("you-currently-have-no-churned-users")}</Message>
              </TextContainer>
            </Content>
          ) : (
            <>
              <TitleContainer>
                <Text>{t("churned")}</Text>
                <ViewMoreContainer onClick={onClickMoreChurned}>
                  <ViewMoreText>{t("view-more")}</ViewMoreText>
                  <Chevron $ar={ar} />
                </ViewMoreContainer>
              </TitleContainer>

              <Table
                marginTop={40}
                headerInformation={tableHeaderSubscriptions}
                totalCount={data?.data?.companySubscriptionsExpired?.length}
                currentPage={1}
                setPage={() => {}}
                perPage={100}
                noDataContent={t("no-data")}
                dataLoading={false}
                lastColumnAligned
                centerText
              >
                {data.data.companySubscriptionsExpired &&
                  Object.values(data?.data?.companySubscriptionsExpired).map(
                    (val, key) => {
                      return (
                        <TableBodyRow key={key} $offClick>
                          <FirstItem
                            hoverText={val?.company}
                            showHover
                            data={
                              val?.company.length > 25
                                ? val?.company.slice(0, 25) + "..."
                                : val?.company
                            }
                            center
                          />
                          <TableItem center data={val.employees} />
                          <TableItem
                            center
                            data={priceFormatter(val.wallet, false, 0) + " SR"}
                          />
                          <TableItem
                            center
                            data={formatDate(val.packageExpiration)}
                          />
                          <TableItem center data={t(val.type.toLowerCase())} />
                          <TableItem center data={val.orders} />

                          <LastTableItem
                            data={val.numOfUsers || "/"}
                            $offClick
                          />
                        </TableBodyRow>
                      );
                    }
                  )}
              </Table>
            </>
          )}
        </>
      ) : (
        <MiniLoader />
      )}
    </>
  );
};

export default AllSubs;
