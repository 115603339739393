import React, { useEffect, useRef, useState } from "react";
import {
  StyledContentContainer,
  Text,
  ButtonNext,
  ButtonBack,
  Buttons,
  StyledInput,
  StyledRowInput,
  LoaderContainer,
} from "../Steps.styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  selectedGetCouponByIdLoading,
  selectedNewCoupon,
  setCouponName,
} from "../../../store/slices/coupon/couponSlice";
import { useSelector } from "react-redux";

import openToast from "../../../utils/toast";
import MiniLoader from "../../MiniLoader/MiniLoader";

const CreateEditStep1 = ({ onNext, onBack }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const loading = useSelector(selectedGetCouponByIdLoading);
  const [loading, setLoading] = useState(true);
  const data = useSelector(selectedNewCoupon) || { name: "" };
  const toastId = useRef(null);

  const validation = () => {
    if (!data.name) {
      openToast(
        toastId,
        "validate-coupon-name",
        "warning",
        t("fill-coupon-name")
      );
    } else {
      onNext();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <>
      <StyledContentContainer>
        {!loading ? (
          <StyledRowInput>
            <Text>{t("coupon-name")}:</Text>
            <StyledInput
              placeholder={t("coupon-name")}
              value={data.name || ""}
              onChange={(e) => {
                dispatch(setCouponName(e.target.value));
              }}
            />
          </StyledRowInput>
        ) : (
          <LoaderContainer>
            <MiniLoader />
          </LoaderContainer>
        )}
      </StyledContentContainer>
      <Buttons>
        <ButtonBack onClick={onBack}>{t("back")}</ButtonBack>
        <ButtonNext onClick={validation}>{t("next")}</ButtonNext>
      </Buttons>
    </>
  );
};

export default CreateEditStep1;
