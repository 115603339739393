import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import {
  isBusinessUserLoggedIn,
  selectBusinessUserInfo,
} from "../store/slices/businessUser/slice";
import UpgradePackage from "../components/UpgradePackage/UpgradePackage";

const BusinessUserLayout = () => {
  const location = useLocation();
  const loggedIn = useSelector(isBusinessUserLoggedIn);
  const [wasUserLoggedIn, setWasUserLoggedIn] = useState(false);

  const businessUser = useSelector(selectBusinessUserInfo);

  const showUpgradeBar = businessUser.company.packageType === "Free";
  let diff =
    new Date(businessUser?.company?.packageExpiration).getTime() -
    new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  //   useEffect(() => {
  //     if (PERMISSIONS_FOT_ROUTES[location.pathname]) {
  //       if (PERMISSIONS_FOT_ROUTES[location.pathname].includes(userRole)) {
  //         setUserHasRequiredRole(true);
  //       } else {
  //         setUserHasRequiredRole(false);
  //       }
  //     } else {
  //       setUserHasRequiredRole(true);
  //     }
  //   }, [location]);

  useEffect(() => {
    if (loggedIn) {
      setWasUserLoggedIn(true);
    }
  }, [loggedIn]);

  let publicPath = "/login";
  if (
    !wasUserLoggedIn &&
    location.pathname.includes("/marketplace/item-detail/")
  ) {
    publicPath = location.pathname
      .replace("/admin-portal", "")
      .replace("item-detail/", "");
  }

  return loggedIn ? (
    <>
      {/* <UpgradePackage show={showUpgradeBar} expirationDate={diff} /> */}

      <Outlet />
    </>
  ) : (
    loggedIn === false && <Navigate to={publicPath} state={{}} replace />
  );
};

export default BusinessUserLayout;
