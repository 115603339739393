import { orderStates } from "../../../utils/constants";
import { getDashboardData } from "./asyncThunks";

export const getDashboardDataBuilder = (builder) => {
  builder.addCase(getDashboardData.pending, (state) => {
    state.getDashboardDataPending = true;
  });
  builder.addCase(getDashboardData.fulfilled, (state, action) => {
    const {
      recipientCount,
      marketplaceOrders,
      sendGiftOrders,
      topRecipients,
      topDepartments,
    } = action.payload;

    state.getDashboardDataPending = false;
    state.recipientCount = recipientCount;
    state.marketplaceOrders = [
      findValueForState(orderStates.created, marketplaceOrders),
      findValueForState(orderStates.wrapping, marketplaceOrders),
      findValueForState(orderStates.sent, marketplaceOrders),
      findValueForState(orderStates.received, marketplaceOrders),
    ];

    state.sendGiftOrders = [
      findValueForState(orderStates.created, sendGiftOrders),
      findValueForState(orderStates.wrapping, sendGiftOrders),
      findValueForState(orderStates.sent, sendGiftOrders),
      findValueForState(orderStates.received, sendGiftOrders),
    ];
    state.topRecipients = topRecipients;
    state.topDepartments = topDepartments;
  });
  builder.addCase(getDashboardData.rejected, (state) => {
    state.getDashboardDataPending = false;
  });
};

function findValueForState(state, orders) {
  if (orders) {
    for (const orderState of orders) {
      if (orderState.state === state) {
        return orderState.count;
      }
    }
  }
  return 0;
}
