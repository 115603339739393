import styled from "styled-components";
import { ReactComponent as CloseSvg } from "../../assets/icons/close.svg";
export const CloseIcon = styled(CloseSvg)`
  cursor: pointer;
  position: absolute;
  right: ${(props) => (props.$ar ? "unset" : "50px")};
  left: ${(props) => (props.$ar ? "50px" : "unset")};

  &&:hover {
    fill: red;
  }
  @media (max-width: 1025px) {
    right: ${(props) => (props.$ar ? "unset" : "10px")};
    left: ${(props) => (props.$ar ? "10px" : "unset")};
  }
  @media (max-width: 940px) {
    fill: #000;
    border-radius: 25%;
    background-color: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
  }
`;
export const WarningContainer = styled.div`
  z-index: 1001;
  position: fixed;
  top: 0;
  padding: 1rem;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-bottom: 2px solid #eadadd88;
  border-top: 2px solid #eadadd88;
  background: rgba(234, 218, 221, 0.75);
  width: 100%;
  height: 6rem;
`;
export const WarningMessage = styled.span`
  color: #000;
  font-family: "FrankfurterEF-Medium";
  font-size: 1.6rem;
  letter-spacing: 0.067px;
  @media (max-width: 890px) {
    font-size: 1.3rem;
  }
  @media (max-width: 420px) {
    font-size: 1.1rem;
  }
  @media (max-width: 370px) {
    font-size: 0.9rem;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
`;

export const ButtonRequest = styled.button`
  color: #fff;
  text-align: center;
  font-family: "FrankfurterEF-Medium";

  user-select: none;
  font-size: 1.6rem;

  letter-spacing: 0.067px;
  padding: 0.4rem 1rem;
  height: 32px;
  border-radius: 10px;
  background: #85a1ac;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  border: none;
  outline: none;
  cursor: pointer;
  @media (max-width: 890px) {
    font-size: 1.3rem;
  }
  @media (max-width: 420px) {
    font-size: 1.1rem;
  }
  @media (max-width: 370px) {
    font-size: 0.9rem;
  }
`;

export const ButtonUpgrade = styled.button`
  color: #fff;
  user-select: none;
  padding: 0.4rem 1rem;
  border: none;
  height: 32px;
  outline: none;
  cursor: pointer;
  text-align: center;
  font-family: "FrankfurterEF-Medium";

  font-size: 1.6rem;

  letter-spacing: 0.067px;
  border-radius: 10px;
  background: #85a195;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  @media (max-width: 890px) {
    font-size: 1.3rem;
  }
  @media (max-width: 420px) {
    font-size: 1.1rem;
  }
  @media (max-width: 370px) {
    font-size: 0.9rem;
  }
`;
