import styled from "styled-components";
import { ReactComponent as LeftArrow } from "../../assets/icons/chevron-left.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/chevron-right.svg";

export const LeftIcon = styled(LeftArrow)`
  fill: #8a4b5e;
  text-shadow: 0px 5px 10px black;
`;
export const RightIcon = styled(RightArrow)`
  fill: #8a4b5e;
  text-shadow: 0px 5px 10px black;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${(props) => (props.$right ? "unset" : "-15px")};
  right: ${(props) => (!props.$right ? "unset" : "-15px")};

  background-color: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 3.2rem;
  height: 3.2rem;

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15), 0px 2.5px 5px rgba(0, 0, 0, 0.2);
  z-index: 501;

  outline: 1px solid #8a4b5e15;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  &:hover {
    scale: 1.05;
  }
  &:active {
    scale: 0.95;
  }
`;

export const MediaSliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 500;
`;
export const Image = styled.img`
  width: 100%;
  user-select: none;
  height: 320px;
  transform: rotate(-4deg);
`;
