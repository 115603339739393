import React, { useEffect, useRef, useState } from "react";

import {
  ContentContainer,
  PackageTypeContainer,
  PackageTypeButton,
  Text,
  Column,
  StyledInput,
  StyledButton,
  StyledDatePicker,
  ButtonDiv,
  Title,
  StyledTitleContainer,
} from "../InviteUserModal/InviteUserModal.style";

import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactComponent as Loader } from "../../assets/icons/loaderWhite.svg";
import { selectedEditCompanySubscriptionLoading } from "../../store/slices/subscriptions/slice";
import { useDispatch } from "react-redux";
import { editCompanySubscription } from "../../store/slices/subscriptions/asyncThunks";

import openToast from "../../utils/toast";
import Modal2 from "../Modal/Modal2";

const EditModal = ({ modalRef, data, resetData, open, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const [date, setDate] = useState(new Date());
  const [type, setType] = useState("");
  const [maxNumberOfUsers, setMaxNumberOfUsers] = useState("");

  const loading = useSelector(selectedEditCompanySubscriptionLoading);

  useEffect(() => {
    if (data) {
      setDate(new Date(data.packageExpiration));
      setMaxNumberOfUsers(data.numOfUsers);
      setType(data.type);
    }
  }, [data]);

  const onClickSave = () => {
    dispatch(
      editCompanySubscription({
        companyId: data.id,
        packageExpiration: date.getTime(),
        packageType: type,
        maxNumberOfUsers,
      })
    )
      .unwrap()
      .then(() => {
        modalRef.current.hide();
      })
      .catch((err) =>
        openToast(toastId, "edit-company-subscription", "error", t(err.message))
      );
  };

  return (
    <Modal2
      border={"2px solid #8A4B5E"}
      bg={"linear-gradient(360deg, #a87283 -100%, #8A4B5E 100%)"}
      onClose={close}
      open={open}
      height={"290px"}
      width={"fit-content"}
    >
      <ContentContainer>
        <StyledTitleContainer>
          <Title>Edit</Title>
          <Close
            className="close"
            fill="#fff"
            onClick={
              !loading
                ? () => {
                    close();
                    resetData();
                  }
                : () => {}
            }
          />
        </StyledTitleContainer>

        <Column style={{ marginTop: 10 }}>
          <PackageTypeContainer>
            <Text>{t("package-type")}:</Text>
            <PackageTypeButton
              $active={type === "Basic"}
              onClick={() => setType("Basic")}
            >
              {t("basic")}
            </PackageTypeButton>
            <PackageTypeButton
              $active={type === "Essentials"}
              onClick={() => setType("Essentials")}
            >
              {t("essentials")}
            </PackageTypeButton>
          </PackageTypeContainer>

          <StyledDatePicker
            placeholderText={t("expiry-date")}
            selected={date}
            onChange={(value) => {
              const selectedDate = new Date(value);
              setDate(selectedDate);
            }}
          />

          <StyledInput
            type="number"
            min={1}
            placeholder={t("maxNumberOfUsers")}
            value={maxNumberOfUsers}
            onChange={(e) => setMaxNumberOfUsers(e.target.value)}
          />
          <ButtonDiv>
            <StyledButton
              $disabled={!loading}
              onClick={!loading ? onClickSave : () => {}}
            >
              {!loading ? t("save-changes") : <Loader height={24} width={24} />}
            </StyledButton>
          </ButtonDiv>
        </Column>
      </ContentContainer>
    </Modal2>
  );
};

export default EditModal;
