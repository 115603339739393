import React, { useEffect, useRef, useState } from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";

import {
  Container,
  Description,
  HeaderWrapper,
  MainContainer,
  PageTitle,
  Screen,
  Title,
} from "./PrivacyPolicyPage.styles";
const PrivacyPolicyPage = () => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleIntersection = (setIntersecting, ref) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      { rootMargin: "-300px" }
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  };

  useEffect(() => handleIntersection(setIsIntersecting, ref, "slide-in"), []);

  useEffect(() => {
    const handleClassToggle = (isIntersecting, ref, className) => {
      ref.current.querySelectorAll("span").forEach((el) => {
        if (isIntersecting) {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      });
    };

    handleClassToggle(isIntersecting, ref, "slide-in");
  }, [isIntersecting]);

  const [headerVisible, setHeaderVisible] = useState(true);
  const [lastScrollUpY, setLastScrollUpY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(null);
  const lastScrollTopRef = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      const lastScrollTop = lastScrollTopRef.current;

      if (currentScrollTop === 0) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
        setScrollDirection(null);
        lastScrollTopRef.current = currentScrollTop;
        return;
      }

      if (currentScrollTop > lastScrollTop) {
        setScrollDirection("down");
        setLastScrollUpY(currentScrollTop);
      } else {
        setScrollDirection("up");
      }

      if (scrollDirection === "up" && currentScrollTop < lastScrollUpY - 350) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
      }

      if (
        scrollDirection === "down" &&
        currentScrollTop > lastScrollUpY + 350
      ) {
        setHeaderVisible(false);
      }

      lastScrollTopRef.current = currentScrollTop;
    };

    const handleScrollDebounced = () => {
      requestAnimationFrame(handleScroll);
    };

    window.addEventListener("scroll", handleScrollDebounced);

    return () => {
      window.removeEventListener("scroll", handleScrollDebounced);
    };
  }, [scrollDirection]);

  return (
    <>
      <HeaderWrapper
        style={{
          opacity: headerVisible ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
        }}
      >
        <Header
          showLogin
          showNav
          color
          showUser
          hideSearch
          showSignUp
          display={headerVisible}
        />
      </HeaderWrapper>
      <MainContainer>
        <Container>
          <Screen ref={ref}>
            <PageTitle>{"Privacy Policy"}</PageTitle>
            <Title>{"Who we are"}</Title>
            <Description>
              {"Our website address is:"}
              <Description
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open("https://ygii.app", "_blank");
                }}
              >
                https://ygii.app
              </Description>
            </Description>
            <Title>{"Comments"}</Title>
            <Description>
              {
                "When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection."
              }
            </Description>
            <Description>
              {
                "An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: https://automattic.com/privacy/. After approval of your comment, your profile picture is visible to the public in the context of your comment."
              }
            </Description>
            <Title>{"Media"}</Title>
            <Description>
              {
                "Uploading images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website."
              }
            </Description>
            <Title>{"Cookies"}</Title>
            <Description>
              {
                "If you leave a comment on our site you may opt-in to saving your name, email address, and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year."
              }
            </Description>
            <Description>
              {
                "Upon visiting our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser."
              }
            </Description>
            <Description>
              {
                "When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed."
              }
            </Description>
            <Description>
              {
                "When editing or publishing an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day."
              }
            </Description>
            <Title>{"Embedded content from other websites"}</Title>
            <Description>
              {
                "Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website."
              }
            </Description>
            <Description>
              {
                "These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website."
              }
            </Description>
            <Title>{"Who we share your data with"}</Title>
            <Description>
              {
                "Upon requesting a password reset, your IP address will be included in the reset email."
              }
            </Description>
            <Title>{"How long we retain your data"}</Title>
            <Description>
              {
                "By leaving a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue."
              }
            </Description>
            <Description>
              {
                "For users that register on our website (if any), we also store the personal information they provide in their user profiles. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information."
              }
            </Description>
            <Title>{"What rights you have over your data"}</Title>
            <Description>
              {"We are not storing any account-specific data on this website."}
            </Description>
            <Title>{"Where we send your data"}</Title>
            <Description>
              {
                "Visitor comments may be checked through an automated spam detection service."
              }
            </Description>
          </Screen>
        </Container>
      </MainContainer>
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
