import { styled } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 40px;
`;

export const Desc = styled.span`
  font-family: "Avenir-Medium";
  font-size: 1.6rem;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #c99ba9;
`;

export const TextRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextLight = styled.span`
  font-family: "Avenir-Medium";
  font-size: 1.6rem;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
`;

export const Input = styled.input`
  margin-bottom: 10px;
  width: 266px;
  height: 45px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #8c8c8c;
  border-radius: 15px;

  font-family: "Avenir-Medium";
  font-size: 1.6rem;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;

  &::placeholder {
    font-family: "Avenir-Medium";
    font-size: 1.6rem;
    line-height: 22px;
    letter-spacing: -0.177777px;
    color: #b1b1d8;
  }
`;

export const InputRow = styled.div`
  flex-direction: row;
  gap: 25px;
  display: flex;
  @media (max-width: 870px) {
    flex-direction: column;
  }
`;
export const InputColumn = styled.div`
  display: flex;
  gap: 7px;
  flex-direction: column;
`;

export const OptionalText = styled.span`
  font-family: "Avenir-Medium";
  font-size: 1.6rem;
  line-height: 22px;
  letter-spacing: -0.177777px;
  opacity: 0.6;
  margin-left: 5px;
  color: #8a4b5e;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
`;
