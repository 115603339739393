import { useSelector } from "react-redux";
import SearchInput from "../Search/Search";
import {
  LoaderContainer,
  CategoryBarContainer,
  Title,
  CategoryList,
  Category,
} from "./CategoryBar.styles";
import { categoriesData } from "../../store/slices/gift/slice";
import { useState } from "react";
import { selectBusinessUserInfo } from "../../store/slices/businessUser/slice";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import MiniLoader from "../MiniLoader/MiniLoader";

const CategoryBar = ({ ar }) => {
  const categories = useSelector(categoriesData);
  const [searchTerm, setSearchTerm] = useState("");
  const { state, company } = useSelector(selectBusinessUserInfo);
  const { t } = useTranslation();
  const { categoryId } = useParams();

  const navigate = useNavigate();
  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();
  diff = Math.round(diff / (1000 * 3600 * 24));

  const handleCategoryClick = (categoryId) => {
    navigate(`/admin-portal/category-items/${categoryId}`);
  };

  return (
    <CategoryBarContainer
      $upgradePackage={showUpgradeBar && diff > 0 && state}
      $ar={ar}
    >
      {categories.length > 0 ? (
        <>
          <Title>{t("categories.categories")}</Title>

          <SearchInput
            width={"100%"}
            whiteIcon
            searchStyle2
            placeholder={t("search-categories")}
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <CategoryList className="vertical-scroll">
            {categories
              .filter((category) =>
                category.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((category) => (
                <Category
                  $active={category.id === +categoryId}
                  key={category.id}
                  onClick={() => {
                    handleCategoryClick(category.id);
                  }}
                >
                  {t(
                    "categories." +
                      [
                        category.name
                          .toLowerCase()
                          .replace(" ", "-")
                          .replace("/", "-"),
                      ]
                  )}
                </Category>
              ))}
          </CategoryList>
        </>
      ) : (
        <LoaderContainer>
          <MiniLoader
            small
            style={{ marginLeft: ar ? 0 : 40, marginRight: ar ? 40 : 0 }}
          />
        </LoaderContainer>
      )}
    </CategoryBarContainer>
  );
};

export default CategoryBar;
