import { styled } from "styled-components";

export const MainContainer = styled.div`
  position: relative;
  z-index: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f6f6f9;
`;

export const HeaderWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
`;
export const ScreenContent = styled.div`
  z-index: 1;

  width: 100%;
  /* height: 93.9rem; */

  border-radius: 3rem;
  padding: 3rem 0px;
  padding: 3rem;
  align-items: center;
  justify-content: center;
  gap: 60px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1375px) {
    height: 100%;
  }
`;

export const Subtitle = styled.span`
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Avenir";
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.178px;
`;

export const FooterText = styled.span`
  padding-top: 30px;

  text-align: center;
  font-family: "Avenir-Medium";
  font-size: 20px;
  letter-spacing: -0.178px;
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
`;

export const Screen = styled.div`
  padding: 2rem 3rem 1rem 3rem;
  position: relative;
  flex-direction: column;
  height: fit-content;
  height: 100%;
  width: 100%;
  margin-top: 120px;
`;

export const Title = styled.span`
  justify-content: center;
  display: flex;

  user-select: none;
  flex-wrap: wrap;
  font-family: "FrankfurterEF-Medium", sans-serif;
  font-size: 4.8rem;
  text-align: center;
  opacity: 1 !important;

  letter-spacing: -0.177777px;

  @media (max-width: 400px) {
    font-size: 3.4rem;
  }
`;

export const PickColorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const Row = styled.div`
  display: flex;
  right: 25px;
  cursor: pointer;
  flex-direction: row;
  position: absolute;
  align-items: center;
  @media (max-width: 565px) {
    top: 8rem;
  }
`;

export const SelectedItemContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;

  @media (max-width: 990px) {
    flex-direction: column;
  }
`;

export const SelectedItemDescriptionContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: flex-start;

  gap: 20px;
  /* @media (max-width: 600px) {
    flex-direction: column;
  } */
`;

export const SelectedItemName = styled.span`
  font-family: "Avenir-Heavy";
  font-size: 20px;
  letter-spacing: -0.178px;
`;

export const SelectedItemPrice = styled.span`
  text-align: center;
  font-family: "Avenir-Light";
  font-size: 18px;

  letter-spacing: -0.178px;
`;

export const SelectedColor = styled.span`
  font-family: "Avenir-Heavy";
  font-size: 18px;

  letter-spacing: -0.178px;
  padding-top: 20px;
`;

export const Gap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 100%;
  @media (max-width: 1400px) {
    flex-direction: column;
  }
  @media (max-width: 750px) {
    padding: 0px 20px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const Button = styled.button`
  border-radius: 1.5rem;
  cursor: pointer;
  height: 4.4rem;
  user-select: none;
  border: none;
  outline: none;
  /* background: #8A4B5E; */
  background: ${(props) => (props.disabled ? "rgba(0,0,0,0.10)" : "#8A4B5E")};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-family: "FrankfurterEF-Medium";
  width: 215px;
  font-size: 1.8rem;
  letter-spacing: -0.178px;
  &:active {
    scale: 0.95;
    transition: all 0.2s ease-in-out;
  }
  @media (max-width: 320px) {
    width: 100%;
  }
`;
export const Cancel = styled(Button)`
  background: #f0f0f0;
  color: #8a4b5e;
`;

export const SelectColorButton = styled(Button)`
  background: ${(props) => (!props.$color ? "#fff" : props.$color)};
  color: #000;

  font-family: "Avenir-Light";
  font-size: 18px;
  width: 277px;
  @media (max-width: 320px) {
    width: 100%;
  }
`;

export const SelectFileButton = styled(Button)`
  position: relative;
`;

export const UploadLogoContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 374px;
  height: 189px;
  gap: 20px;
  border: 1px solid rgb(1, 1, 1, 0.15);
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(1, 0, 1, 0.1);
  transition: all ease-in 0.4s;
  /* transform: ${(props) =>
    !props.$ar ? "translateX(-150%)" : "translateX(150%)"}; */
  @media (max-width: 400px) {
    width: 100%;
    padding: 0px 20px;
  }
  @media (max-width: 1160px) {
    flex-wrap: wrap;
  }
`;

export const UploadLogoImage = styled.img`
  user-select: none;
  background-color: transparent;
  width: 80px;
  height: 80px;
`;

export const MinQuantityContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: flex-start;
`;

export const Input = styled.input`
  font-family: "Avenir-Light";
  user-select: none;
  font-size: 18px;
  text-align: center;
  color: #000;
  line-height: 12px;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  max-width: 100px;

  border-bottom: 2px solid;
  width: fit-content;
  &:focus {
    outline: none;
  }
  @media (max-width: 645px) {
    font-size: 2rem;
    line-height: 2.2rem;
  }
`;

export const Quantity = styled.span`
  letter-spacing: -0.178px;
  text-align: center;
  font-family: "Avenir-Light";
  font-size: 18px;
`;
