import { styled } from "styled-components";
export const MainContainer = styled.div`
  position: relative;
  z-index: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f5f6fa;
`;

export const HeaderWrapper = styled.div`
  background: #8a4b5e;
  position: fixed;
  z-index: 1000;
  width: 100%;
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  max-width: 1100px;
`;

export const Screen = styled.div`
  padding: 2rem 3rem 1rem 3rem;
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 160px;
  gap: 20px;
`;

export const PageTitle = styled.span`
  display: flex;
  opacity: 0;
  justify-content: center;
  color: #8a4b5e;
  text-align: center;
  font-family: "FrankfurterEF-Medium", sans-serif;
  font-size: 50px;
  padding-bottom: 20px;

  letter-spacing: -0.178px;

  @media (max-width: 400px) {
    font-size: 3.4rem;

    opacity: 1 !important;
  }
`;

export const Title = styled.span`
  display: flex;
  text-align: start;
  flex-wrap: wrap;
  font-family: "FrankfurterEF-Medium", sans-serif;
  color: #130f35;
  font-size: 3rem;
  letter-spacing: -0.177777px;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    !props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;

  @media (max-width: 400px) {
    font-size: 3.4rem;
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
  padding-bottom: 15px;
  padding-top: 10px;
`;

export const Description = styled.span`
  text-align: justify;
  display: flex;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  gap: 5px;
  flex-wrap: wrap;
  color: #130f35;
  font-family: "Avenir-Roman";
  font-size: 1.8rem;
  padding-bottom: 15px;
  /* padding-inline: 200px; */
  @media (max-width: 400px) {
    transform: translateX(0) !important;
    opacity: 1 !important;
  }
`;
