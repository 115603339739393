import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  ItemsColumn,
  ButtonContainer,
  ContentContainer,
  Text,
  ButtonDisabled,
  StyledText,
  StyledTitle,
  ColumnDiv,
  CloseContainer,
  ItemsCard,
  ContentCard,
  ImgContainer,
  ItemPhoto,
  Colors,
  Color,
  Size,
  SizeValue,
  Content,
  TitleContainer,
  ItemTitle,
  ItemSpecifications,
  ItemQuantity,
  ItemPrice,
  FooterContainer,
  FooterText,
  LoaderContainer,
} from "./CartModal.styles";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import Modal from "./Modal2";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  cartItemsData,
  removeCartItem,
  selectGeneratePhotosWithLogoPending,
  setCreateOrderSubTotal,
} from "../../store/slices/marketplace/marketplaceSlice";
import { selectBusinessUserInfo } from "../../store/slices/businessUser/slice";
import { useNavigate } from "react-router-dom";
import { getCurrencySymbol } from "../../utils/constants";
import AppLoader from "../AppLoader";

const CartModal = ({ open, close, loadingItemId }) => {
  const { t } = useTranslation();
  const items = useSelector(cartItemsData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const businessUser = useSelector(selectBusinessUserInfo);
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);

  const generatePhotosWithLogoPending = useSelector(
    selectGeneratePhotosWithLogoPending
  );

  const subTotal = useMemo(() => {
    return items.reduce((total, item) => {
      if (item.buyBlank && !item.buyPrinted) {
        return total + (item.samplePrice * item.sampleQuantity || 0);
      } else if (!item.buyBlank && item.buyPrinted) {
        return total + (item.printedPrice * item.printedQuantity || 0);
      } else {
        return total + (item.totalPrice || 0);
      }
    }, 0);
  }, [items]);

  const totalPrice = useMemo(() => {
    if (isCouponValid && discountPercentage) {
      return subTotal - (subTotal * discountPercentage) / 100;
    }

    return subTotal;
  }, [discountPercentage, isCouponValid, subTotal]);

  const remove = (index) => {
    dispatch(removeCartItem({ index, businessUserId: businessUser.id }));
  };

  const [isVisible, setIsVisible] = useState(generatePhotosWithLogoPending);

  useEffect(() => {
    let timer;
    if (generatePhotosWithLogoPending) {
      setIsVisible(true);
    } else {
      timer = setTimeout(() => setIsVisible(false), 850);
    }

    return () => clearTimeout(timer);
  }, [generatePhotosWithLogoPending]);

  return (
    <Modal
      onClose={close}
      open={open}
      width={"fit-content"}
      height={"100%"}
      padding={"50px 60px"}
      bg={"#f6f6f9"}
      right
      unset
      smallPadding
    >
      <CloseContainer onClick={close}>
        <CloseIcon fill="white" width={20} height={20} />
      </CloseContainer>

      <ContentContainer>
        {items?.length > 0 ? (
          <ColumnDiv>
            <div>
              <StyledTitle> {t("your-cart")} </StyledTitle>
              <StyledText>
                ({items?.length} {t("items")})
              </StyledText>
            </div>
            <ItemsColumn>
              {items?.map((product, index) => (
                <ItemsCard key={index}>
                  <ContentCard>
                    <ImgContainer>
                      {isVisible && product.cartItemId === loadingItemId && (
                        <LoaderContainer>
                          <AppLoader />
                        </LoaderContainer>
                      )}
                      <ItemPhoto src={product?.photo?.url} alt="Item-img" />
                    </ImgContainer>
                    <Colors>
                      {!product.isPackaging && product.color.id && (
                        <Color
                          style={{
                            backgroundColor: product.color.name,
                            border: "1.2px solid #8A4B5E",
                          }}
                        />
                      )}
                      {!product.isPackaging && product.size.id && (
                        <Size>
                          <SizeValue>{product.size.name}</SizeValue>
                        </Size>
                      )}
                    </Colors>
                    <Content>
                      <TitleContainer>
                        <ItemTitle
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (!product.isPackaging) {
                              !businessUser.loggedIn
                                ? navigate(`/marketplace/${product.id}`)
                                : navigate(
                                    `/admin-portal/marketplace/item-detail/${product.id}`
                                  );
                            }
                          }}
                        >
                          {product.name}
                        </ItemTitle>
                      </TitleContainer>

                      <ItemSpecifications>
                        {product.buyBlank && !product.buyPrinted ? (
                          <div>
                            {t("quantity")}:{" "}
                            <ItemQuantity>
                              {product.sampleQuantity}
                            </ItemQuantity>
                          </div>
                        ) : product.buyPrinted ? (
                          <div>
                            {t("quantity")}:{" "}
                            <ItemQuantity>
                              {product.printedQuantity}
                            </ItemQuantity>
                          </div>
                        ) : (
                          <div>
                            {t("quantity")}:{" "}
                            <ItemQuantity>{product.quantity}</ItemQuantity>
                          </div>
                        )}

                        <ItemSpecifications>
                          <div>
                            {t("total")}:{" "}
                            <ItemPrice>
                              {!product.buyBlank &&
                                !product.buyPrinted &&
                                product?.totalPrice?.toFixed(2)}
                              {product.buyBlank &&
                                !product.buyPrinted &&
                                product.samplePrice * product.sampleQuantity}
                              {!product.buyBlank &&
                                product.buyPrinted &&
                                product.printedPrice * product.printedQuantity}
                              {getCurrencySymbol(product.currency)}
                            </ItemPrice>
                          </div>
                        </ItemSpecifications>
                      </ItemSpecifications>
                    </Content>
                  </ContentCard>
                  <FooterContainer>
                    <FooterText
                      onClick={() => remove(index)}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      {t("remove")}{" "}
                    </FooterText>
                  </FooterContainer>
                </ItemsCard>
              ))}
            </ItemsColumn>
          </ColumnDiv>
        ) : (
          <ColumnDiv $fullwidth>
            <Text $textalign>{t("you-have-no-products-in-your")}</Text>
            <Text $textalign>{t("shopping-cart")}.</Text>
          </ColumnDiv>
        )}

        <ButtonContainer>
          {items?.length > 0 ? (
            <Button
              onClick={() => {
                if (items.length > 0 && businessUser.loggedIn) {
                  navigate("/admin-portal/cart");
                } else if (items.length > 0) {
                  navigate("/cart");
                }
                dispatch(setCreateOrderSubTotal(totalPrice));
              }}
              disabled={generatePhotosWithLogoPending}
            >
              {t("proceed-to-checkout")}
            </Button>
          ) : (
            <ButtonDisabled>{t("proceed-to-checkout")}</ButtonDisabled>
          )}
        </ButtonContainer>
      </ContentContainer>
    </Modal>
  );
};

export default CartModal;
