import styled from "styled-components";

export const MainContainer = styled.div`
  flex-direction: row;
  background: #f6f6f9;
  min-height: 100vh;
  display: flex;
  position: relative;
`;
export const Column = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  margin-top: 75px;
  transition: all 0.2s ease-in-out;
  padding: ${(props) =>
    props.$upgradePackage && props.$ar
      ? "100px 290px 80px 40px"
      : props.$upgradePackage && !props.$ar
      ? "100px 40px 80px 290px"
      : !props.$upgradePackage && props.$ar
      ? "40px 290px 80px 40px"
      : "40px 40px 80px 290px"};

  @media (max-width: 870px) {
    margin-top: 50px;
    padding: ${(props) =>
      props.$upgradePackage ? "60px 40px 40px 40px" : "0px 40px 40px 40px"};
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 38px;
  margin-left: 50px;
  @media (max-width: 870px) {
    margin-left: 0;
  }
  @media (max-width: 360px) {
    margin-top: -20px;
    transform: scale(0.9);
  }
`;

export const SectionTitle = styled.h1`
  color: #8a4b5e;
  font-family: "Avenir-Heavy";
  user-select: none;
  font-size: 3.5rem;
  letter-spacing: -0.178px;

  margin-bottom: 80px;
  @media (max-width: 870px) {
    display: none;
  }
`;

export const HeaderContainer = styled.div`
  margin-top: 30px;
  margin-right: 40px;
  display: flex;
  flex-direction: row;
  gap: 43px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  @media (max-width: 870px) {
    display: none;
  }
`;
export const ProfilePicEmpty = styled.div`
  width: 101px;
  height: 101px;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ProfilePic = styled.div`
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #454588;
  border-radius: 42px;
  cursor: pointer;
  box-shadow: 0px 3px 25px -8px rgba(0, 0, 0, 0.24);
`;
export const Button = styled.button`
  user-select: none;
  color: white;

  font-family: "Avenir-Heavy";
  font-style: normal;

  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 0.5em 1.7em;
  border: transparent;
  background: linear-gradient(180deg, #a87283 0%, #8a4b5e 100%);
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 30px;
  &:hover {
    background: #8a4b5e;
  }
  &:active {
    box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);

    transition: all 0.1s ease-in;
    transform: scale(0.95);
  }
  @media (max-width: 990px) {
    font-size: 16px;
  }
  @media (max-width: 952px) {
    font-size: 14px;
  }
  @media (max-width: 915px) {
    font-size: 12px;
  }
  @media (max-width: 795px) {
    font-size: 11px;
  }
`;
export const Group = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const Center = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  @media (max-width: 1030px) {
    gap: 30px;
  }
  @media (max-width: 875px) {
    gap: 20px;
  }
  @media (max-width: 835px) {
    gap: 10px;
  }
`;
export const Shape = styled.div`
  user-select: none;
  width: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 50px;
  font-family: "Avenir-Heavy";
  font-style: normal;

  font-size: 16px;
  line-height: 27px;
  letter-spacing: -0.177777px;

  color: #8a4b5e;

  background-color: #ececf4;
  border-radius: 50%;
`;

export const TabNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  @media (max-width: 870px) {
    margin-top: 50px;
  }
  @media (max-width: 575px) {
    flex-direction: column;
  }
`;

export const TabName = styled.span`
  font-family: "Avenir-Black", sans-serif;
  font-size: 35px;
  user-select: none;
  line-height: 48px;
  letter-spacing: -0.177777px;

  color: #8a4b5e;
  @media (max-width: 575px) {
    text-align: center;
  }
  @media (max-width: 365px) {
    font-size: 30px;
    line-height: 35px;
  }
  @media (max-width: 325px) {
    font-size: 25px;
    line-height: 25px;
  }
`;
export const TabContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  padding: 12px;
  gap: 160px;
  border-bottom: 2px solid #e6e6e6;
  @media (max-width: 1030px) {
    gap: 100px;
  }
  @media (max-width: 870px) {
    margin-top: 50px;
  }
  @media (max-width: 845px) {
    gap: 50px;
  }
  @media (max-width: 500px) {
    gap: 20px;
  }
`;

export const Text = styled.span`
  font-family: "Avenir-Heavy";
  cursor: pointer;
  font-style: normal;

  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.177777px;

  color: #8a4b5e;
  @media (max-width: 1030px) {
    font-size: 16px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const TextLight = styled.span`
  font-family: "Avenir-Medium";
  font-size: 1.6rem;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
`;
export const OptionalText = styled.span`
  font-family: "Avenir-Medium";
  font-size: 1.6rem;
  line-height: 22px;
  letter-spacing: -0.177777px;
  opacity: 0.6;
  margin-left: 5px;
  color: #8a4b5e;
`;
export const TableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
  margin-top: 80px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 40px;
`;

export const Input = styled.input`
  margin-bottom: 10px;
  width: 266px;
  height: 45px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #8c8c8c;
  border-radius: 15px;

  font-family: "Avenir-Medium";
  font-size: 1.6rem;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;

  &::placeholder {
    font-family: "Avenir-Medium";
    font-size: 1.6rem;
    line-height: 22px;
    letter-spacing: -0.177777px;
    color: #b1b1d8;
  }
`;

export const Desc = styled.span`
  font-family: "Avenir-Medium";
  font-size: 1.6rem;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #c99ba9;
`;

export const InputRow = styled.div`
  flex-direction: row;
  gap: 25px;
  display: flex;
  @media (max-width: 870px) {
    flex-direction: column;
  }
`;
export const InputColumn = styled.div`
  display: flex;
  gap: 7px;
  flex-direction: column;
`;
export const TextRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Search = styled.input`
  padding: 8px;
  border: unset;
  border-bottom: 2px solid #e6e6e6;
  width: 240px;
  font-family: "Avenir-Medium";
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  background: transparent;
  &:focus {
    outline: unset;
  }
  &::placeholder {
    font-family: "Avenir-Medium";
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.177777px;
    color: #c99ba9;
  }
`;

export const HeadRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 575px) {
    margin-top: 20px;
  }
  @media (max-width: 590px) {
    flex-direction: column;
    margin-top: 0px;
    gap: 20px;
  }
`;
export const HeadColum = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 575px) {
    margin-top: 20px;
  }
`;

export const TabNameText = styled.span`
  font-family: "Avenir-Light";
  font-style: normal;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.177777px;

  color: #8a4b5e;
  @media (max-width: 325px) {
    font-size: 18px;
    line-height: 25px;
  }
  @media (max-width: 285px) {
    font-size: 16px;
    line-height: 25px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;
export const CartContainer = styled.div`
  cursor: pointer;
  position: relative;
`;

export const Dot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #8a4b5e;
  top: -5px;
  right: -5px;
  padding: 5px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
`;

export const CartCount = styled.span`
  color: white;
  font-size: 12px;
  font-family: "Avenir-Medium", sans-serif;
`;

export const LogoText = styled.span`
  color: #8a4b5e;
  font-family: "Avenir-Medium", sans-serif;
  font-weight: 500;
  font-size: 18px;
`;

export const LogoContainer = styled.div`
  position: absolute;
  left: 0px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const ImageContainer = styled.div`
  cursor: pointer;
  margin-top: 20px;
  position: relative;
  width: fit-content;
  height: fit-content;
`;
export const SearchIconContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 3px;
  background: #fff;
`;
export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
`;

export const ButtonCancel = styled.button`
  color: white;

  font-family: "Avenir-Black";
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 0.5em 2em;
  margin-left: 2em;
  border: transparent;
  background: linear-gradient(180deg, #e5e4e2 0%, #a8a8a8 100%);
  box-shadow: 0px 12px 24px -6px rgb(128, 128, 128);
  cursor: pointer;
  border-radius: 30px;
  &:hover {
    background: #a9a9a9;
  }
  &:active {
    box-shadow: 0px 12px 24px -6px rgb(128, 128, 128);

    transition: all 0.1s ease-in;
    transform: scale(0.95);
  }
`;
export const PageButton = styled.button`
  padding: 2px 7px;
  border: none;
  background: transparent;
  font-family: "Avenir-Medium";
  font-size: 14px;
  color: #8a4b5e;
  cursor: pointer;
  &:active {
    opacity: 0.6;
  }
`;

export const PageCountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;
  gap: 5px;
`;

export const GiftCartContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

export const GiftCart = styled.div`
  position: absolute;
  padding: 20px;
  background: #8a4b5e;
  width: fit-content;
  height: fit-content;
  right: 0;
  border: 2px solid #fff;
  top: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
`;
export const Row1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-left: 20px;
`;
export const ItemName = styled.span`
  font-size: 16px;
  color: white;
  text-align: center;
  font-family: "Avenir-Medium";
`;

export const ImgContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const ButtonContinue = styled.button`
  padding: 12px 15px;
  background: #8a4b5e;
  border-radius: 15px;
  border: 0;
  color: #fff;
  font-family: "Avenir-Medium";

  font-size: 18px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: block;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &::after {
    width: 25px;
    height: 1px;
    background: white;
    transform: translateX(-3px);
    margin-top: 0px;
  }

  &::before {
    content: "";
    transform: rotate(-135deg) translateX(50%);
    width: 11px;
    height: 11px;
    background: transparent;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    margin-top: -1px;
  }

  &:hover {
    background: #4ab639;
    padding: 12px 60px 12px 15px;
  }

  &:hover::after,
  &:hover::before {
    opacity: 1;
    right: 15px;
  }
`;
export const LanguageText = styled.span`
  color: #8a4b5e;
  font-size: 12px;

  font-family: "Avenir-Medium";
`;
export const FlagContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;

export const Buttons = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 18px;
  align-items: center;
  font-family: "Avenir-Heavy";
  font-style: normal;

  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.177777px;
  color: #ffffff;
  gap: 10px;
  justify-content: center;
  border-radius: 30px;
  background: linear-gradient(180deg, #a87283 0%, #8a4b5e 100%), #ffffff;
  &:hover {
    background: #8a4b5e;
  }
  &:active {
    transition: all 0.2s ease;
    transform: scale(0.95);
  }
  @media (max-width: 920px) {
    padding: 10px 16px;

    font-size: 16px;
  }
`;
export const BalanceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;
export const BalanceValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
export const Balance = styled.span`
  font-family: "Avenir-Medium";

  font-size: 35px;
  letter-spacing: -0.18px;
  color: #8a4b5e;
  @media (max-width: 920px) {
    font-size: 25px;
  }
  @media (max-width: 365px) {
    font-size: 20px;
  }
`;
export const BalanceValue = styled.span`
  font-family: "Avenir-Heavy";

  font-size: 35px;
  letter-spacing: -0.18px;
  color: #8a4b5e;
  @media (max-width: 920px) {
    font-size: 25px;
  }
  @media (max-width: 365px) {
    font-size: 20px;
  }
`;
export const StyledTd = styled.td`
  text-align: center;
  min-width: 150px !important;
`;
export const StyledTdAll = styled(StyledTd)`
  padding-bottom: 10px;
`;

export const StyledTdFirst = styled(StyledTd)`
  text-align: left;
  min-width: unset !important;
  padding-bottom: 10px;
`;

export const StyledTh = styled.th`
  text-align: center;
`;

export const HeaderWrapper = styled.div`
  background: #8a4b5e;
  position: fixed;
  z-index: 1000;
  width: 100%;
`;

export const StyledButton = styled.div`
  border-radius: 30px;
  user-select: none;
  background: #8a4b5e;
  height: 45px;
  padding: 5px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: none;
  color: #fff;
  text-align: center;
  gap: 1rem;
  font-family: "Avenir";
  font-size: 16px;
  font-weight: 500;

  letter-spacing: 0.08px;
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
  }
  @media (max-width: 1075px) {
    height: 35px;
    padding: 5px 20px;
    font-size: 12px;
  }
`;
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  @media (max-width: 1205px) {
    gap: 10px;
  }
  @media (max-width: 1075px) {
    margin-top: 6px;
    flex-direction: column;
  }
`;
export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: ${(props) => (props.margin ? "-20px" : "0px")};
  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
  }
`;
