import styled, { keyframes } from "styled-components";
import { ReactComponent as FavoriteSvg } from "../../assets/icons/heart.svg";
import { ReactComponent as CartSvg } from "../../assets/icons/cart.svg";
import { ReactComponent as Chevron } from "../../assets/icons/chevronDown.svg";
import { ReactComponent as SearchSvg } from "../../assets/icons/whiteSearch.svg";
import { ReactComponent as DashboardSvg } from "../../assets/icons/dashboard.svg";
import { ReactComponent as MarketplaceSvg } from "../../assets/icons/market.svg";
import { ReactComponent as RecipientsSvg } from "../../assets/icons/usergroupwhite.svg";
import { ReactComponent as CouponSvg } from "../../assets/icons/coupon.svg";
import { ReactComponent as AccountSettingsSvg } from "../../assets/icons/settings.svg";
import { ReactComponent as WalletSvg } from "../../assets/icons/wallet.svg";
import { ReactComponent as OrderHistorySvg } from "../../assets/icons/clock.svg";
import { ReactComponent as ArrowSvg } from "../../assets/icons/arrow.svg";
import { ReactComponent as SubscriptionSvg } from "../../assets/icons/sub.svg";

import { ReactComponent as DashboardUnSelectedSvg } from "../../assets/icons/dashboardUnSelected.svg";
import { ReactComponent as MarketplaceUnSelectedSvg } from "../../assets/icons/marketUnSelected.svg";
import { ReactComponent as RecipientsUnSelectedSvg } from "../../assets/icons/usergroupUnSelected.svg";
import { ReactComponent as CouponUnSelectedSvg } from "../../assets/icons/couponUnSelected.svg";
import { ReactComponent as AccountSettingsUnSelectedSvg } from "../../assets/icons/settingsUnSelected.svg";
import { ReactComponent as WalletUnSelectedSvg } from "../../assets/icons/walletUnSelected.svg";
import { ReactComponent as OrderHistoryUnSelectedSvg } from "../../assets/icons/clockUnSelected.svg";
import { ReactComponent as FavoriteUnSelectedSvg } from "../../assets/icons/heartUnSelected.svg";
import { ReactComponent as SubscriptionUnSelectedSvg } from "../../assets/icons/subUnSelected.svg";

import { NavLink } from "react-router-dom";

export const Arrow = styled(ArrowSvg)`
  transform: ${(props) => (props.$ar ? "rotate(180deg)" : "unset")};
  path {
    stroke: #8a4b5e;
  }
`;
export const FavoriteUnSelected = styled(FavoriteUnSelectedSvg)`
  path {
    fill: #fff;
  }
`;

export const FavoriteIcon = styled(FavoriteSvg)`
  path {
    fill: #fff;
  }
`;

export const DashboardUnSelected = styled(DashboardUnSelectedSvg)`
  path {
    stroke: #fff;
  }
`;

export const SubscriptionUnSelected = styled(SubscriptionUnSelectedSvg)`
  path {
    fill: #fff;
  }
`;
export const MarketplaceUnSelected = styled(MarketplaceUnSelectedSvg)`
  path {
    fill: #fff;
  }
`;
export const RecipientsUnSelected = styled(RecipientsUnSelectedSvg)`
  path {
    fill: #fff;
  }
`;
export const CouponUnSelected = styled(CouponUnSelectedSvg)`
  path {
    fill: #fff;
  }
`;
export const AccountSettingsUnSelected = styled(AccountSettingsUnSelectedSvg)`
  path {
    fill: #fff;
  }
`;
export const WalletUnSelected = styled(WalletUnSelectedSvg)`
  path {
    fill: #fff;
  }
`;
export const OrderHistoryUnSelected = styled(OrderHistoryUnSelectedSvg)`
  path {
    fill: #fff;
  }
`;
export const Dashboard = styled(DashboardSvg)`
  path {
    fill: #fff;
  }
`;
export const Subscription = styled(SubscriptionSvg)`
  path {
    fill: #fff;
  }
`;
export const Marketplace = styled(MarketplaceSvg)`
  path {
    fill: #fff;
  }
`;
export const Recipients = styled(RecipientsSvg)`
  path {
    fill: #fff;
  }
`;
export const Coupon = styled(CouponSvg)`
  path {
    fill: #fff;
  }
`;
export const AccountSettings = styled(AccountSettingsSvg)`
  path {
    fill: #fff;
  }
`;
export const Wallet = styled(WalletSvg)`
  path {
    fill: #fff;
  }
`;
export const OrderHistory = styled(OrderHistorySvg)`
  path {
    fill: #fff;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const MenuContainer = styled.div`
  width: 42px;
  height: 42px;
  align-items: center;
  position: relative;
  justify-content: center;
  border-radius: 10px;
  background-color: #fff;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  flex-wrap: wrap;
  width: 100%;
`;

export const CartContainer = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
`;

export const ChevronDown = styled(Chevron)`
  width: 20px;
  height: auto;
  transition: all 0.2s ease-in-out;

  rotate: ${(props) => (props.$open ? "180deg" : "0deg")};
  path {
    stroke-width: 5px;
    stroke: #fff;
  }
`;

export const CartIcon = styled(CartSvg)`
  fill: #fff;
`;
export const SearchIcon = styled(SearchSvg)`
  cursor: pointer;

  g {
    stroke: #fff;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  border-bottom: ${(props) =>
    props.$open ? "1px solid white" : "1px solid transparent"};
  width: ${(props) => (props.$open ? "25rem" : "4.5rem")};
  margin-right: ${(props) => (props.$open ? "0" : "-1.5rem")};
  padding: 0.5rem;
  transition: all 0.2s ease-in-out;
`;
export const SearchInput = styled.input`
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  padding-left: 1rem;
  user-select: none;
  color: #fff;
  font-family: "Avenir";
  font-weight: 500;
  font-size: 16px;
  &::placeholder {
    font-weight: 400;
  }
`;

export const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;

  margin-left: ${(props) => (props.$ar ? "unset" : "auto")};
  margin-right: ${(props) => (props.$ar ? "auto" : "unset")};
  justify-content: center;
  flex-wrap: wrap;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  flex-wrap: wrap;
  gap: ${(props) => (props.$gap ? "3rem" : "2rem")};
`;

export const StyledText = styled.span`
  user-select: none;
  color: ${(props) => (props.$color ? "#fff" : "#8A4B5E")};
  font-family: "Avenir";
  font-weight: 400;
  font-size: 22px;
  letter-spacing: -0.18px;
  min-width: fit-content;

  text-align: center;

  &:active {
    opacity: 0.5;
  }
  @media (max-width: 450px) {
    min-width: 10rem;
  }
`;
export const Text = styled.span`
  user-select: none;
  color: ${(props) => (props.$color ? "#fff" : "#8A4B5E")};
  font-family: "Avenir";
  font-weight: 400;
  font-size: 22px;
  letter-spacing: -0.18px;
  min-width: 12.8rem;
  text-align: center;

  &:active {
    opacity: 0.5;
  }
  @media (max-width: 450px) {
    min-width: 10rem;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  border: unset;
  outline: unset;
  user-select: none;
  background-color: #fff;
  transition: transform 0.3s ease;
  box-shadow: 0px 1.2rem 24rem -0.6rem rgba(0, 0, 0, 0.25);
  border-radius: 3rem;
  padding: 1rem 3rem;
  letter-spacing: 0.5%;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 12.8rem;

  &:hover {
    box-shadow: 0px 1.2rem 2.4rem -0.6rem rgba(0, 0, 0, 0.25);
    transform: scale(1.05);
  }
  &:active {
    transform: translate(0em, 0.2em);
  }
  @media (max-width: 450px) {
    min-width: 10rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  overflow: auto;

  white-space: nowrap;

  &&::-webkit-scrollbar {
    width: 5px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 10px;
  }

  &&::-webkit-scrollbar-track {
    background-color: transparent;
  }

  @media (max-height: 1090px) {
    gap: 2rem;
    padding-right: 0.5rem;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid white;
  padding-bottom: 1rem;
  padding-right: 10px;
  transition: all 0.3s ease-in-out;
  height: ${(props) => (props.$open ? "100%" : "33px")};
`;
export const Link = styled.span`
  color: #fff;
  font-family: "Avenir";
  user-select: none;
  font-size: 20px;
  font-weight: 500;

  letter-spacing: 0.067px;
`;
export const StyledLink = styled(Link)`
  transition: all 0.2s ease-in-out;
  padding: 1rem;
  cursor: pointer;
  border-radius: 1rem;
  &:hover {
    padding-left: 2rem;
    background-color: rgba(255, 255, 255, 0.15);
  }
`;
export const StyledRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StyledColumn = styled(Column)`
  gap: 0rem;
  padding: 2rem;
  max-height: 55rem;
  overflow: auto;
  animation: ${(props) => (props.$open ? fadeIn : fadeOut)} 0.3s ease-in-out;
`;
export const LogoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 1rem;
`;

export const StyledNavLink = styled(NavLink)`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 0.6;
  text-decoration: none;
  color: #fff;
  font-family: "Avenir-Medium";
  gap: 15px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.0666667px;
  transition: all 0.2s ease-in-out;
  padding: 5px 10px;
  border-radius: 30px;
  width: 100%;
  &.active {
    background-color: #fff4;
    opacity: 1;
  }

  &:hover {
    opacity: 1;
  }
`;
