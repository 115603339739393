import styled from "styled-components";

export const MainContainer = styled.div`
  flex-direction: row;
  background: #f6f6f9;

  min-height: 100vh;
  display: flex;
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  margin-top: 80px;
  padding: ${(props) =>
    props.$ar ? "0px 290px 0px 40px" : "0px 40px 0px 290px"};
  @media (max-width: 870px) {
    margin-top: 50px;
    padding: 0px 40px 0px 40px;
  }
`;

export const HeaderWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  margin-top: 18px;
`;

export const Text = styled.span`
  font-family: "Avenir-Black";
  font-style: normal;

  font-size: 24px;
  line-height: 33px;

  letter-spacing: -0.177777px;

  color: #8a4b5e;
`;

export const Desc = styled.span`
  font-family: "Avenir-Light";
  font-style: normal;

  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.177777px;

  color: rgba(69, 69, 132, 0.6);
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  margin-right: 30px;
  align-items: right;
  justify-content: right;
  @media (max-width: 990px) {
    align-items: center;
    justify-content: center;
    margin-right: 0px;
  }
`;

export const Button = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 30px;
  align-items: center;
  font-family: "Avenir-Medium";
  font-style: normal;

  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.177777px;
  color: #ffffff;
  gap: 10px;
  justify-content: center;
  background: linear-gradient(180deg, #a87283 0%, #8a4b5e 100%), #ffffff;
  border-radius: 15px;
  &:hover {
    background: #8a4b5e;
  }
  &:active {
    transition: all 0.2s ease;
    transform: scale(0.95);
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 40px;
  justify-content: space-between;
  align-items: center;
`;

export const DetailsDataValue = styled.span`
  font-family: "Avenir-Heavy";
  font-style: normal;
  text-align: right;
  font-size: 18px;
  line-height: 33px;
  letter-spacing: -0.177777px;
  color: #a87283;

  @media (max-width: 990px) {
    text-align: center;
  }

  @media (max-width: 485px) {
    font-size: 14px;
  }
`;

export const DetailsContainer = styled.div`
  border: 1px solid #ccc;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  background-color: white;
  @media (max-width: 1065px) {
    flex-direction: column;
  }
`;

export const DetailsContainerColumn = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
`;

export const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

export const DetailsData = styled.div`
  margin-right: 5px;
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 18px;
  line-height: 33px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  @media (max-width: 485px) {
    font-size: 14px;
  }
`;

export const InvoiceDiv = styled.div`
  margin-top: 10px;
`;

export const ButtonBackContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: left;
  justify-content: left;
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const ButtonDisabled = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 30px;
  align-items: center;
  font-family: "Avenir-Medium";
  font-style: normal;

  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.177777px;
  color: #ffffff;
  gap: 10px;
  justify-content: center;
  background: #8a4b5e 100%;
  border-radius: 15px;
`;

export const Select = styled.select`
  border: none;
  padding: 10px 5px;
  background: transparent;
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #a87283;
  display: flex;
  flex-direction: flex-end;
  text-align: right;

  &:focus {
    outline: none;
  }
`;

export const Option = styled.option`
  border: none;
  padding: 8px;
  background: transparent;
  width: 100%;
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
`;

export const DetailsDataWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const StyledDetailsDataValue = styled(DetailsDataValue)`
  text-align: left;
  width: 100px;
  @media (max-width: 485px) {
    font-size: 14px;
  }
`;

export const DetailsDataValueColumn = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: right;

  @media (max-width: 990px) {
    justify-content: center;
  }
`;

export const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 18px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 4px 8px 4px 10px;
  margin-bottom: 10px;
  border: 1px solid #d1d1d4;
  justify-content: space-between;
  border-radius: 4px;
  background-color: white;
  outline: none;
`;

export const LoaderDiv = styled.div`
  margin-top: 150px;
  @media (max-height: 870px) {
    margin-top: 100px;
  }

  @media (max-height: 550px) {
    margin-top: 40px;
  }

  @media (max-height: 450px) {
    margin-top: 20px;
  }
`;

export const ImgGiftCard = styled.img`
  border-radius: 20;
  height: 150px;
  max-width: 250px;
  margin-bottom: 10px;
  object-fit: contain;
`;

export const RightSideRecipientDetails = styled.div`
  padding: 5px;
`;
