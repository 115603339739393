import React, { useEffect, useRef, useState } from "react";
import { MainContainer, Column, SectionTitle } from "./CreateEditCoupon.styles";
import Sidebar from "../../utils/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Steps from "../../../../components/Steps/Steps";

import {
  createCoupon,
  editCoupon,
  getCouponById,
} from "../../../../store/slices/coupon/asyncThunks";
import {
  resetData,
  selectedNewCoupon,
} from "../../../../store/slices/coupon/couponSlice";
import CreateEditStep1 from "../../../../components/Steps/Coupons/CreateEditStep1";
import CreateEditStep2 from "../../../../components/Steps/Coupons/CreateEditStep2";
import CreateEditStep3 from "../../../../components/Steps/Coupons/CreateEditStep3";
import Header from "../../../../components/Header/Header";
import { HeaderWrapper } from "../CouponsPage.styles";
import openToast from "../../../../utils/toast";
import { selectedLocale } from "../../../../store/slices/common/slice";
import { selectBusinessUserInfo } from "../../../../store/slices/businessUser/slice";

const CreateEditCoupon = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { couponId } = useParams();
  const isEditCoupon = location.pathname.includes("edit-coupon");
  const [activeStep, setActiveStep] = useState(1);
  const data = useSelector(selectedNewCoupon);
  const stepName = [t("coupon-name"), t("coupon-details"), t("confirm")];
  const toastId = useRef(null);

  useEffect(() => {
    if (isEditCoupon) {
      dispatch(getCouponById(couponId));
    } else {
      dispatch(resetData());
    }
  }, [dispatch, couponId, isEditCoupon]);

  const { company, state } = useSelector(selectBusinessUserInfo);

  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  const locale = useSelector(selectedLocale);
  const upgradePackage = showUpgradeBar && diff > 0 && state;
  const ar = locale === "ar";

  return (
    <>
      <MainContainer>
        <HeaderWrapper>
          <Header
            showLogin
            showSignUp
            showNav
            color
            showCart
            showFavorite
            showUser
          />
        </HeaderWrapper>
        <Sidebar />

        <Column $ar={ar} $upgradePackage={upgradePackage}>
          <SectionTitle>
            {isEditCoupon ? t("edit-coupon") : t("new-coupons")}
          </SectionTitle>
          {activeStep !== 4 && (
            <Steps
              steps={stepName}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          )}

          <>
            {activeStep === 1 && (
              <CreateEditStep1
                onNext={() => {
                  setActiveStep(2);
                }}
                onBack={() => navigate(-1)}
              />
            )}
            {activeStep === 2 && (
              <CreateEditStep2
                onBack={() => {
                  setActiveStep(1);
                }}
                onNext={() => {
                  setActiveStep(3);
                }}
              />
            )}
            {activeStep === 3 && (
              <CreateEditStep3
                onClickNo={() => setActiveStep(2)}
                onClickYes={() => {
                  const selectedDate = new Date(data?.expirationDate);
                  const newDate = selectedDate?.getTime();

                  const products = data?.products?.map((p) => p.name);
                  const newCoupon = {
                    name: data.name,
                    ...(data.value !== "" && { value: data.value }),
                    expirationDate: newDate,
                    products: products,
                    type: data.type,
                    status: data.status,
                    maxUsage: data.onetime ? 1 : data.maxUsage,
                    frequency: data.frequency,
                  };
                  if (isEditCoupon) {
                    dispatch(editCoupon({ newCoupon, couponId }))
                      .unwrap()
                      .then(() => {
                        const query = { page: 1, type: data.status };
                        const queryString = new URLSearchParams(
                          query
                        ).toString();
                        const url = `/admin-portal/coupons?${queryString}`;

                        navigate(url);
                        dispatch(resetData());
                      })
                      .catch((err) =>
                        openToast(
                          toastId,
                          "edit-coupon",
                          "error",
                          t(err.message)
                        )
                      );
                  } else {
                    dispatch(createCoupon(newCoupon))
                      .unwrap()
                      .then(() => {
                        navigate("/admin-portal/coupons");
                        dispatch(resetData());
                      })
                      .catch((err) =>
                        openToast(
                          toastId,
                          "add-coupon",
                          "error",
                          t(err.message)
                        )
                      );
                  }
                }}
              />
            )}
          </>
        </Column>
      </MainContainer>
    </>
  );
};

export default CreateEditCoupon;
