import {
  LOCAL_STORAGE_REFRESH_TOKEN,
  LOCAL_STORAGE_ACCESS_TOKEN,
} from "../../../utils/constants";
import {
  login,
  validateCode,
  signUp,
  verifyEmail,
  verifyEmployeeEmail,
  signUpEmployee,
  sendCodeForNewPassword,
  resetPassword,
  refresh,
  sendVerificationEmailAgain,
} from "./asyncThunks";

export const signUpBuilder = (builder) => {
  builder.addCase(signUp.pending, (state) => {
    state.createAccountPending = true;
  });
  builder.addCase(signUp.fulfilled, (state, action) => {
    const { accessToken, businessUser } = action.payload;
    state.createAccountPending = false;
    state.loggedIn = true;
    state.token = accessToken;
  });
  builder.addCase(signUp.rejected, (state) => {
    state.createAccountPending = false;
  });
};

export const validateCodeBuilder = (builder) => {
  builder.addCase(validateCode.pending, (state) => {
    state.validateCodePending = true;
  });
  builder.addCase(validateCode.fulfilled, (state) => {
    state.validateCodePending = false;
  });
  builder.addCase(validateCode.rejected, (state) => {
    state.validateCodePending = false;
  });
};

export const verifyEmailBuilder = (builder) => {
  builder.addCase(verifyEmail.pending, (state) => {
    state.verifyEmailPending = true;
  });
  builder.addCase(verifyEmail.fulfilled, (state, action) => {
    const { email } = action.payload;

    state.verifyEmailPending = false;
    state.email = email;
  });
  builder.addCase(verifyEmail.rejected, (state) => {
    state.verifyEmailPending = false;
  });
};

export const loginBuilder = (builder) => {
  builder.addCase(login.pending, (state) => {
    state.loginPending = true;
  });
  builder.addCase(login.fulfilled, (state, action) => {
    const { accessToken } = action.payload;
    state.loginPending = false;
    state.loggedIn = true;
    state.token = accessToken;
  });
  builder.addCase(login.rejected, (state) => {
    state.loginPending = false;
  });
};

export const verifyEmployeeEmailBuilder = (builder) => {
  builder.addCase(verifyEmployeeEmail.pending, (state) => {
    state.verifyEmailPending = true;
  });
  builder.addCase(verifyEmployeeEmail.fulfilled, (state, action) => {
    const { email, companyId } = action.payload;
    state.verifyEmailPending = false;
    state.email = email;
    state.companyId = companyId;
  });
  builder.addCase(verifyEmployeeEmail.rejected, (state) => {
    state.verifyEmailPending = false;
  });
};

export const signUpEmployeeBuilder = (builder) => {
  builder.addCase(signUpEmployee.pending, (state) => {
    state.createAccountPending = true;
  });
  builder.addCase(signUpEmployee.fulfilled, (state, action) => {
    const { accessToken } = action.payload;
    state.createAccountPending = false;
    state.loggedIn = true;
    state.token = accessToken;
  });
  builder.addCase(signUpEmployee.rejected, (state) => {
    state.createAccountPending = false;
  });
};

export const sendCodeForNewPasswordBuilder = (builder) => {
  builder.addCase(sendCodeForNewPassword.pending, (state) => {
    state.sendingResetEmailPending = true;
  });
  builder.addCase(sendCodeForNewPassword.fulfilled, (state) => {
    state.sendingResetEmailPending = false;
  });
  builder.addCase(sendCodeForNewPassword.rejected, (state, action) => {
    state.sendingResetEmailPending = false;
    state.error = action.error.message;
  });
};

export const resetPasswordBuilder = (builder) => {
  builder.addCase(resetPassword.pending, (state) => {
    state.resettingPasswordPending = true;
  });
  builder.addCase(resetPassword.fulfilled, (state) => {
    state.resettingPasswordPending = false;
  });
  builder.addCase(resetPassword.rejected, (state, action) => {
    state.resettingPasswordPending = false;
    state.error = action.error.message;
  });
};

export const refreshBuilder = (builder) => {
  builder.addCase(refresh.fulfilled, (state, action) => {
    const { accessToken } = action.payload;
    state.token = accessToken;
  });
};

export const sendVerificationEmailAgainBuilder = (builder) => {
  builder.addCase(sendVerificationEmailAgain.pending, (state) => {
    state.sendVerificationEmailAgainPending = true;
  });
  builder.addCase(sendVerificationEmailAgain.fulfilled, (state) => {
    state.sendVerificationEmailAgainPending = false;
  });
  builder.addCase(sendVerificationEmailAgain.rejected, (state) => {
    state.sendVerificationEmailAgainPending = false;
  });
};
