import axiosApiClient from "..";

import { handleError } from "../../utils/errors";

const BASE_URL = "payment";

class PaymentServices {
  getPaymentMethod = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/method`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return {
        data,
      };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching payment method.");
    }
  };

  setDefaultPaymentMethod = async (paymentMethodId) => {
    try {
      const response = await axiosApiClient.put(
        `${BASE_URL}/method/${paymentMethodId}`
      );
      if (response.status !== 200) {
        throw new Error();
      }

      return paymentMethodId;
    } catch (err) {
      throw handleError(err, "Error occurred while editing coupon.");
    }
  };

  removePaymentMethod = async (paymentMethodId) => {
    try {
      const response = await axiosApiClient.delete(
        `${BASE_URL}/method/${paymentMethodId}`
      );
      if (response.status !== 200) {
        throw new Error();
      }

      return paymentMethodId;
    } catch (err) {
      throw handleError(err, "Error occurred while deleting coupon.");
    }
  };
}

const paymentServices = new PaymentServices();

export default paymentServices;
