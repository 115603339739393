import axiosApiClient from "..";

import { handleError } from "../../utils/errors";

const BASE_URL = "common";

class CommonServices {
  countClickAction = async (key) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/click`, { key });
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while count Click Action.");
    }
  };

  getBusinessRulesVariables = async () => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/business-rules-variables-portal`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching business rules variables"
      );
    }
  };
}

const commonServices = new CommonServices();

export default commonServices;
