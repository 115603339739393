import React from "react";
import {
  MainContainer,
  Column,
  SectionTitle,
  HeaderWrapper,
} from "./SubscriptionPage.styles";
import Sidebar from "../utils/Sidebar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ViewMoreBankTransfers from "../../../components/Subscriptions/ViewMoreBankTransfers";
import Header from "../../../components/Header/Header";
import { selectedLocale } from "../../../store/slices/common/slice";
import { useSelector } from "react-redux";

const BankTransfersPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";

  return (
    <MainContainer>
      <HeaderWrapper>
        <Header
          showNav
          color
          showLogin
          showSignUp
          showCart
          showUser
          showFavorite
        />
      </HeaderWrapper>

      <Sidebar />

      <Column $ar={ar}>
        <SectionTitle>{t("subscriptions")}</SectionTitle>

        <ViewMoreBankTransfers
          ar={ar}
          onBack={() => navigate("/admin-portal/subscriptions")}
        />
      </Column>
    </MainContainer>
  );
};

export default BankTransfersPage;
