import React from "react";
import { useState } from "react";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import ReactModal from "react-modal";
import { ModalStyle, OverlayStyle } from "./Modal.styles";

const Modal = forwardRef(
  ({ children, shouldCloseOnOverlayClick, onClose }, ref) => {
    const [show, setShow] = useState(false);

    useImperativeHandle(ref, () => ({
      show() {
        setShow(true);
      },
      hide() {
        setShow(false);
      },
      toggle() {
        setShow((prev) => !prev);
      },
    }));

    return (
      <ReactModal
        isOpen={show}
        onRequestClose={onClose}
        className="_"
        overlayClassName="_"
        contentElement={(props, children) => (
          <ModalStyle {...props}>{children}</ModalStyle>
        )}
        overlayElement={(props, contentElement) => (
          <OverlayStyle {...props}>{contentElement}</OverlayStyle>
        )}
        shouldCloseOnEsc={shouldCloseOnOverlayClick}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        {children}
      </ReactModal>
    );
  }
);

export default Modal;
