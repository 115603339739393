import {
  Button,
  ButtonContainer,
  HomePageContainer,
  H1,
  LeftSide,
  H2,
  Text,
  Rotate,
  ButtonStroke,
  SectionTwo,
  Sponsors,
  SectionThree,
  Title,
  Span,
  HeaderWrapper,
  SectionFour,
  SectionFive,
  TitleFive,
  Column,
  H5,
  TextFive,
  SectionSix,
  TitleSix,
  ButtonRed,
  TextSix,
  ColumnSix,
  RowSix,
  RightSide,
  Wrapper,
  TextWrapper,
  ImageContainer1,
  ImageContainer2,
  TextWrapperSectionThree,
  TitleWrapper,
  SplineContainer,
  IconContainer,
  ContainerBox2,
  ContainerBox3,
  BellContainer,
  ContainerBox1,
  GiftIcon,
  ImageContainer3,
  Background,
  Bg2,
  StoryImage,
  StyledRow,
  HomeContainer,
  GoToTop,
  StyledImage,
  StyledImageDashboard,
  SectionTwoContainer,
  SponsorImgFirst,
  SponsorImg,
  DashboardImage,
  SectionThreeContainer,
  SectionFiveContainer,
  StyledRowSix,
  RowStory,
  CommentImg,
  Click,
  ImageDiv,
  StyledColumn,
  StyledColumnSix,
  FadeColumn,
  TextTrust,
} from "./HomePage.styles.js";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { countClickAction } from "../../store/slices/common/asyncThunks.js";

import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer.js";

import { ReactComponent as Gift } from "../../assets/icons/gift.svg";
import { ReactComponent as Bell } from "../../assets/icons/zvono.svg";
import { ReactComponent as ChevronTop } from "../../assets/icons/chevron-top.svg";
import { ReactComponent as Arrow } from "../../assets/icons/onboardingArrow.svg";
import { ReactComponent as Img1 } from "../../assets/icons/img1.svg";
import { ReactComponent as Img2 } from "../../assets/icons/img2.svg";
import { ReactComponent as Img3 } from "../../assets/icons/img3.svg";

import images from "../../themes/images.js";

import { selectedLocale } from "../../store/slices/common/slice.js";
import { useSelector } from "react-redux";
import Icon from "../../components/Icon.js";
import Spline from "@splinetool/react-spline";

import { selectBusinessUserInfo } from "../../store/slices/businessUser/slice.js";

const HomePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showGoToTop, setShowGoToTop] = useState(false);
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";
  const { loggedIn } = useSelector(selectBusinessUserInfo);

  /* SCROLL TO TOP  */

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setShowGoToTop(true);
    } else {
      setShowGoToTop(false);
    }
  };

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  /* 3D GIFT ANIMATION */

  const spline = useRef();

  function onLoad(splineApp) {
    spline.current = splineApp;
  }

  /* SCROLL ANIMATION */

  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isIntersecting2, setIsIntersecting2] = useState(false);
  const [isIntersecting3, setIsIntersecting3] = useState(false);
  const [isIntersecting4, setIsIntersecting4] = useState(false);
  const [isIntersecting5, setIsIntersecting5] = useState(false);
  const [isIntersecting6, setIsIntersecting6] = useState(false);
  const [isIntersecting7, setIsIntersecting7] = useState(false);
  const [isIntersecting8, setIsIntersecting8] = useState(false);
  const [isIntersecting9, setIsIntersecting9] = useState(false);

  const ref = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);

  const handleIntersection = (setIntersecting, ref) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      { rootMargin: "-300px" }
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  };

  useEffect(() => handleIntersection(setIsIntersecting, ref, "slide-in"), []);
  useEffect(() => handleIntersection(setIsIntersecting2, ref2, "slide-in"), []);
  useEffect(() => handleIntersection(setIsIntersecting3, ref3, "slide-in"), []);
  useEffect(() => handleIntersection(setIsIntersecting4, ref4, "slide-in"), []);
  useEffect(() => handleIntersection(setIsIntersecting5, ref5, "slide-in"), []);
  useEffect(() => handleIntersection(setIsIntersecting6, ref6, "slide-in"), []);
  useEffect(() => handleIntersection(setIsIntersecting7, ref7, "slide-in"), []);
  useEffect(() => handleIntersection(setIsIntersecting8, ref8, "slide-in"), []);
  useEffect(() => handleIntersection(setIsIntersecting9, ref9, "slide-in"), []);

  useEffect(() => {
    const handleClassToggle = (isIntersecting, ref, className) => {
      ref.current.querySelectorAll("div").forEach((el) => {
        if (isIntersecting) {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      });
    };
    const handleClassToggleH2 = (isIntersecting, ref, className) => {
      ref.current.querySelectorAll("h2").forEach((el) => {
        if (isIntersecting) {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      });
    };

    handleClassToggle(isIntersecting, ref, "slide-in");
    handleClassToggle(isIntersecting2, ref2, "slide-in");
    handleClassToggle(isIntersecting3, ref3, "slide-in");
    handleClassToggle(isIntersecting4, ref4, "slide-in");
    handleClassToggle(isIntersecting5, ref5, "slide-in");
    handleClassToggle(isIntersecting6, ref6, "slide-in");
    handleClassToggle(isIntersecting7, ref7, "slide-in");
    handleClassToggleH2(isIntersecting8, ref8, "slide-in");
    handleClassToggleH2(isIntersecting9, ref9, "slide-in");
  }, [
    isIntersecting,
    isIntersecting2,
    isIntersecting3,
    isIntersecting4,
    isIntersecting5,
    isIntersecting6,
    isIntersecting7,
    isIntersecting8,
    isIntersecting9,
  ]);

  const [headerVisible, setHeaderVisible] = useState(true);
  const [lastScrollUpY, setLastScrollUpY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(null);
  const lastScrollTopRef = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      const lastScrollTop = lastScrollTopRef.current;

      if (currentScrollTop === 0) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
        setScrollDirection(null);
        lastScrollTopRef.current = currentScrollTop;
        return;
      }

      if (currentScrollTop > lastScrollTop) {
        setScrollDirection("down");
        setLastScrollUpY(currentScrollTop);
      } else {
        setScrollDirection("up");
      }

      if (scrollDirection === "up" && currentScrollTop < lastScrollUpY - 350) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
      }

      if (
        scrollDirection === "down" &&
        currentScrollTop > lastScrollUpY + 350
      ) {
        setHeaderVisible(false);
      }

      lastScrollTopRef.current = currentScrollTop;
    };

    const handleScrollDebounced = () => {
      requestAnimationFrame(handleScroll);
    };

    window.addEventListener("scroll", handleScrollDebounced);

    return () => {
      window.removeEventListener("scroll", handleScrollDebounced);
    };
  }, [scrollDirection]);

  return (
    <>
      <HeaderWrapper
        style={{
          opacity: headerVisible ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
        }}
      >
        <Header
          showLogin
          showNav
          color
          showUser
          showSignUp
          hideSearch
          display={headerVisible}
        />
      </HeaderWrapper>
      <HomeContainer>
        <HomePageContainer $ar={ar} ref={ref6}>
          <Wrapper $ar={ar}>
            <LeftSide $ar={ar}>
              <GiftIcon $ar={ar}>
                <Icon
                  icon={<Gift width={98} height={98} />}
                  selectedLocale={ar}
                />
              </GiftIcon>

              <TitleWrapper $ar={ar}>
                <H1 $ar={ar}>{t("one-platform")}</H1>
                <H2 $ar={ar}>{t("gift")}</H2>

                <SplineContainer $ar={ar}>
                  <Spline
                    scene="https://prod.spline.design/yLnlDchW7zL0EoYo/scene.splinecode"
                    onLoad={onLoad}
                  />
                  <Click onClick={() => navigate("/game")} $ar={ar} />
                </SplineContainer>
              </TitleWrapper>

              <Text $ar={ar}>{t("send-personalized-employee")}</Text>
              <ButtonContainer $ar={ar}>
                <Button
                  onClick={() => {
                    if (loggedIn) {
                      navigate("sign-up/steps/upgrade");
                    } else {
                      navigate("pricing");
                    }
                  }}
                >
                  {t("start-gifting")}
                  <Rotate $ar={ar}>
                    <Icon
                      icon={<Gift width={25} height={25} />}
                      selectedLocale={ar}
                    />
                  </Rotate>
                </Button>
                <ButtonStroke
                  onClick={() => {
                    dispatch(countClickAction("demoRequest"));

                    window.open(
                      "https://calendly.com/abdulkareem-2/demo",
                      "_blank"
                    );
                  }}
                >
                  {t("schedule-a-demo")}
                </ButtonStroke>
              </ButtonContainer>
            </LeftSide>

            <RightSide $ar={ar}>
              <div style={{ position: "relative" }}>
                <ContainerBox2 $ar={ar}>
                  <StyledImage
                    src={
                      ar
                        ? images.EmployeeEngagementPopupAr
                        : images.EmployeeEngagementPopup
                    }
                    placeholderSrc={images.EmployeeEngagementPopupLow}
                    height={73}
                    width={275}
                    alt="100% Employee Engagement Popup - Ygii"
                  />
                </ContainerBox2>
                <ContainerBox3 $ar={ar}>
                  <CommentImg
                    src={
                      ar
                        ? images.GaSAndEmployeeEngagementPopupAr
                        : images.GaSAndEmployeeEngagementPopup
                    }
                    placeholderSrc={images.GaSAndEmployeeEngagementPopupLow}
                    alt="Gift as a Service and Employee Engagement Popup - Ygii"
                  />
                </ContainerBox3>
                <ContainerBox1 $ar={ar}>
                  <StyledImage
                    src={
                      ar
                        ? images.GiftAsAServicePopupAr
                        : images.GiftAsAServicePopup
                    }
                    placeholderSrc={images.GiftAsAServicePopupLow}
                    height={73}
                    width={217}
                    alt="Gift as a Service Popup - Ygii"
                  />
                </ContainerBox1>
                <BellContainer $ar={ar}>
                  <Bell className="bell" />
                </BellContainer>
                <StyledImageDashboard
                  $ar={ar}
                  src={images.WomenWithYgiiBag}
                  placeholderSrc={images.WomenWithYgiiBagLow}
                  alt="Women with ygii bag - Ygii"
                  height={595}
                  width={415}
                />

                <IconContainer $ar={ar}>
                  <Arrow className="arrow" />
                </IconContainer>
              </div>
            </RightSide>
          </Wrapper>
        </HomePageContainer>

        <SectionTwoContainer ref={ref7}>
          <ImageDiv>
            <DashboardImage
              src={images.AdminPortalDashboard}
              placeholderSrc={images.AdminPortalDashboardLow}
              alt="Admin Portal Dashboard - Ygii"
              width={1149}
              height={712}
            />
          </ImageDiv>

          <SectionTwo $ar={ar}>
            <TextTrust>{t("trusted-by")}</TextTrust>
            <Sponsors>
              <SponsorImgFirst
                src={images.Glub}
                onClick={() => {
                  window.open("https://qlub.io/", "_blank");
                }}
                alt="Sponsor - Glub Logo - Ygii"
                width={250}
                height={176}
              />
              <SponsorImg
                src={images.Scalers}
                onClick={() => {
                  window.open("https://www.withscalers.com/", "_blank");
                }}
                alt="Sponsor - Scalers Logo - Ygii"
                width={250}
                height={114}
              />
              <SponsorImg
                src={images.Flat}
                onClick={() => {
                  window.open("https://www.flat6labs.com/", "_blank");
                }}
                alt="Sponsor - Flat Logo - Ygii"
                width={300}
                height={80}
              />
              <SponsorImg
                src={images.Sign}
                onClick={() => {
                  window.open("https://signsa.com/", "_blank");
                }}
                alt="Sponsor - Sign Logo - Ygii"
                width={100}
                height={100}
              />
              <SponsorImg
                src={images.Ovnum}
                onClick={() => {
                  window.open("https://www.omniumint.com/", "_blank");
                }}
                alt="Sponsor - Ovnum Logo - Ygii"
                width={300}
                height={84}
              />
            </Sponsors>
          </SectionTwo>
        </SectionTwoContainer>

        <SectionThreeContainer ref={ref}>
          <SectionThree>
            <RowStory>
              <TextWrapper $ar={ar}>
                <Title>{t("cultivate")}</Title>
                <Span>{t("discover")}</Span>
              </TextWrapper>

              <ImageContainer1 $ar={ar}>
                <StoryImage
                  src={images.TeamInOffice}
                  alt="Teamwork in Office: People Holding Hands Up - Ygii"
                  placeholderSrc={images.TeamInOfficeLow}
                />
              </ImageContainer1>
            </RowStory>
          </SectionThree>
        </SectionThreeContainer>

        <Background ref={ref2}>
          <SectionFour>
            <RowStory>
              <ImageContainer2 $ar={ar}>
                <StoryImage
                  src={images.GirlWorkingOnLaptop}
                  placeholderSrc={images.GirlWorkingOnLaptopLow}
                  alt={"Girl Working on Laptop and Drinking Coffee - Ygii"}
                />
              </ImageContainer2>

              <TextWrapperSectionThree $ar={ar}>
                <Title>{t("one-stop")}</Title>
                <Span>{t("step-into")}</Span>
              </TextWrapperSectionThree>

              <ImageContainer3 $ar={ar}>
                <StoryImage
                  src={images.GirlWorkingOnLaptop}
                  placeholderSrc={images.GirlWorkingOnLaptopLow}
                  alt={"Girl Working on Laptop and Drinking Coffee - Ygii"}
                />
              </ImageContainer3>
            </RowStory>
          </SectionFour>
        </Background>

        <SectionFiveContainer ref={ref3}>
          <SectionFive ref={ref9}>
            <TitleFive>{t("the-emotional-gap")}</TitleFive>

            <StyledRow>
              <StyledColumn $ar={ar}>
                <Img1 />
                <H5>{t("lack-of-interaction")}</H5>
                <TextFive>{t("the-digital")}</TextFive>
              </StyledColumn>

              <FadeColumn $ar={ar}>
                <Img2 />
                <H5>{t("missing-experience")}</H5>
                <TextFive>{t("givers-value")}</TextFive>
              </FadeColumn>

              <Column $ar={ar}>
                <Img3 />
                <H5>{t("loss-of")}</H5>
                <TextFive>{t("the-physical")}</TextFive>
              </Column>
            </StyledRow>
          </SectionFive>
        </SectionFiveContainer>

        <Bg2 ref={ref4}>
          <SectionSix className="sectionSix" ref={ref8}>
            <TitleFive>{t("workplace")}</TitleFive>

            <RowSix>
              <ColumnSix $ar={ar}>
                <TitleSix>{t("11")}</TitleSix>
                <ButtonRed>{t("interaction")}</ButtonRed>
                <TextSix>{t("social")}</TextSix>
              </ColumnSix>

              <StyledColumnSix $ar={ar}>
                <TitleSix>{t("22")}</TitleSix>
                <ButtonRed>{t("moments")}</ButtonRed>
                <TextSix>{t("create-and-recall")}</TextSix>
              </StyledColumnSix>
            </RowSix>

            <StyledRowSix ref={ref5}>
              <ColumnSix $ar={ar}>
                <TitleSix>{t("33")}</TitleSix>
                <ButtonRed>{t("culture")}</ButtonRed>
                <TextSix>{t("reduce")}</TextSix>
              </ColumnSix>

              <StyledColumnSix $ar={ar}>
                <TitleSix>{t("44")}</TitleSix>
                <ButtonRed>{t("relationships")}</ButtonRed>
                <TextSix>{t("nature-workplace")}</TextSix>
              </StyledColumnSix>
            </StyledRowSix>
          </SectionSix>
          {showGoToTop && (
            <GoToTop onClick={goToTop}>
              <ChevronTop />
            </GoToTop>
          )}
        </Bg2>

        <Footer ar={ar} />
      </HomeContainer>
    </>
  );
};

export default HomePage;
