import React, { useEffect, useRef, useState } from "react";
import {
  Content,
  Text,
  TextContainer,
  TitleContainer,
  Message,
  ViewMoreText,
  ViewMoreContainer,
  LastTableItem,
  FirstItem,
  TableBodyRow,
  Chevron,
} from "../../screens/AdminPortal/CouponsPage/CouponsPage.styles";
import { useTranslation } from "react-i18next";
import trash2 from "react-useanimations/lib/trash2";
import AreYouSureModal from "../AreYouSureModal/AreYouSureModal";
import { selectedCoupons } from "../../store/slices/coupon/couponSlice";
import { useSelector } from "react-redux";
import {
  getCoupons,
  removeCoupon,
} from "../../store/slices/coupon/asyncThunks";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import UseAnimations from "react-useanimations";
import { priceFormatter } from "../../utils/utils";
import MiniLoader from "../MiniLoader/MiniLoader";
import Table from "../Table/Table";
import TableItem from "../Table/TableItem";

const StatusCoupons = ({ onBack, ar }) => {
  const { t } = useTranslation();
  const [couponId, setCouponId] = useState("");
  const selectedCouponsData = useSelector(selectedCoupons);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const type = queryParams.get("type");
  const dispatch = useDispatch();
  const openModal = useRef();
  const navigate = useNavigate();

  const handleActiveCouponPageChange = (pageNumber) => {
    dispatch(getCoupons({ page: pageNumber, type: type }));
    const query = { page: pageNumber, type: type };
    const queryString = new URLSearchParams(query).toString();
    const url = `/admin-portal/coupons?${queryString}`;

    navigate(url);
  };

  useEffect(() => {
    dispatch(getCoupons({ page, type }));
  }, [dispatch, page, type]);

  const tableHeader = [
    {
      label: `${t("coupon")}`,
      sortable: true,
      key: "coupon",
    },
    {
      label: `${t("usage")}`,
      sortable: true,
      key: "usage",
    },
    {
      label: `${t("frequency")}`,
      sortable: true,
      key: "frequency",
    },
    {
      label: `${t("gmv")}`,
      sortable: true,
      key: "gmv",
    },

    {
      label: `${t("createdAt")}`,
      sortable: true,
      key: "createdAt",
    },
    {
      label: `${t("expiry-date")}`,
      sortable: true,
      key: "expiryDate",
    },
    {
      label: `${t("type")}`,
      sortable: true,
      key: "type",
    },
    {
      label: `${t("value")}`,
      sortable: true,
      key: "value",
    },
    {
      label: `${t("maxUsage")}`,
      sortable: true,
      key: "maxUsage",
    },
    {
      label: `${t("product")}`,
      sortable: true,
      key: "product",
    },
    {
      label: "",
      sortable: false,
      key: "delete",
    },
  ];

  return (
    <>
      {!selectedCouponsData.loading ? (
        <>
          {selectedCouponsData.data.length <= 0 ? (
            <Content>
              <TextContainer>
                {type === "ACTIVE" ? (
                  <Message>{t("you-currently-have-no-active-coupons")}</Message>
                ) : (
                  <Message>
                    {t("you-currently-have-no-inactive-coupons")}
                  </Message>
                )}
              </TextContainer>
            </Content>
          ) : (
            <>
              <TitleContainer>
                {type === "ACTIVE" ? (
                  <Text>
                    {t("active-coupons")} ({selectedCouponsData.total})
                  </Text>
                ) : (
                  <Text>
                    {t("inactive-coupons")} ({selectedCouponsData.total})
                  </Text>
                )}
                <ViewMoreContainer onClick={onBack}>
                  <Chevron $ar={!ar} />
                  <ViewMoreText>{t("back")}</ViewMoreText>
                </ViewMoreContainer>
              </TitleContainer>

              <Table
                marginTop={40}
                headerInformation={tableHeader}
                totalCount={selectedCouponsData?.total}
                currentPage={+page}
                setPage={handleActiveCouponPageChange}
                perPage={selectedCouponsData?.perPage}
                param={"page"}
                noDataContent={t("no-data")}
                dataLoading={false}
                lastColumnAligned
                centerText
              >
                {selectedCouponsData?.data?.map((val, key) => {
                  const originalDate = val.createdAt;
                  const newDate = new Date(originalDate)
                    .toLocaleDateString()
                    .replaceAll("/", "-");
                  const parts = newDate.split("-");
                  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

                  const productNames = val.products.map(
                    (product) => product.name
                  );

                  return (
                    <TableBodyRow
                      key={key}
                      onClick={() =>
                        navigate(`/admin-portal/coupons/edit-coupon/${val.id}`)
                      }
                    >
                      <FirstItem data={val.name} />
                      <TableItem center data={val.usage} />
                      <TableItem center data={t(val.frequency)} />
                      <TableItem
                        center
                        data={priceFormatter(val.gmv, false, 0)}
                      />
                      <TableItem center data={formattedDate} />
                      <TableItem center data={val.expirationDate} />
                      <TableItem center data={val.type} />
                      <TableItem
                        center
                        data={!val.value ? "/" : `${val.value}%`}
                      />

                      <TableItem center data={val.maxUsage} />
                      <TableItem center data={productNames.join(", ")} />
                      <LastTableItem
                        $offClick
                        data={
                          <UseAnimations
                            className="delete"
                            animation={trash2}
                            size={32}
                            strokeColor="#8A4B5E"
                            onClick={() => {
                              openModal.current.show();
                              setCouponId(val.id);
                            }}
                          />
                        }
                      />
                    </TableBodyRow>
                  );
                })}
              </Table>

              <AreYouSureModal
                title={t("are-you-sure-coupon")}
                modalRef={openModal}
                onClick={() => {
                  dispatch(removeCoupon(couponId))
                    .unwrap()
                    .then(() => {
                      openModal.current.hide();
                    });
                }}
              />
            </>
          )}
        </>
      ) : (
        <MiniLoader />
      )}
    </>
  );
};

export default StatusCoupons;
