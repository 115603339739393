import { createAsyncThunk } from "@reduxjs/toolkit";

import instantQuotationService from "../../../api/services/instantQuotation";

export const postInstantQuotation = createAsyncThunk(
  "instant-quotation/post",
  async (requestData) => {
    return await instantQuotationService.postInstantQuotation(requestData);
  }
);

export const postQuotationWithMultipleItems = createAsyncThunk(
  "instant-quotation/postQuotationWithMultipleItems",
  async (requestData) => {
    return await instantQuotationService.postQuotationWithMultipleItems(
      requestData
    );
  }
);
