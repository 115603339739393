import { countClickAction, getBusinessRulesVariables } from "./asyncThunks";

export const countClickActionBuilder = (builder) => {
  builder
    .addCase(countClickAction.pending, (state) => {
      state.countClickAction.loading = true;
    })
    .addCase(countClickAction.fulfilled, (state) => {
      state.countClickAction.loading = false;
    })
    .addCase(countClickAction.rejected, (state, action) => {
      state.countClickAction.loading = false;
      state.countClickAction.error = action.error.message;
    });
};

export const getBusinessRulesVariablesBuilder = (builder) => {
  builder
    .addCase(getBusinessRulesVariables.pending, (state) => {
      state.getBusinessRulesVariables.loading = true;
    })
    .addCase(getBusinessRulesVariables.fulfilled, (state, action) => {
      state.getBusinessRulesVariables.loading = false;
      state.getBusinessRulesVariables.data =
        action.payload.businessRulesVariables;
    })
    .addCase(getBusinessRulesVariables.rejected, (state) => {
      state.getBusinessRulesVariables.loading = false;
    });
};
