import styled, { keyframes } from "styled-components";

const slideIn = keyframes`
  from {
    opacity: 0;
    /* transform: translateX(-100%); */
  }
  to {
    opacity: 1;
    /* transform: translateX(0); */
  }
`;

const slideOut = keyframes`
  from {
    opacity: 1;
    /* transform: translateX(0); */
  }
  to {
    opacity: 0;
    /* transform: translateX(-100%); */
  }
`;

export const HoverText = styled.span`
  position: absolute;
  background: #8a4b5e;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
  font-size: 1.4rem;
  animation: ${(props) => (props.$hover ? slideIn : slideOut)} 0.1s ease-in-out;
`;
export const TableContainer = styled.div`
  border-radius: 3rem;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;
export const TableWrapper = styled.div`
  background: transparent;
  position: relative;
  box-shadow: none;

  overflow: ${(props) => (props.$empty ? "hidden" : "auto")};
  min-height: fit-content;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  padding: 3rem 0rem 0rem 0rem;
  border-radius: 3rem;

  min-height: 40rem;
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &&::-webkit-scrollbar {
    height: 4px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: #c8c8db;
    border-radius: 10rem;
    cursor: ew-resize;
  }
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
`;

export const TableHeader = styled.thead`
  height: 5rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
`;

export const TableHeaderRow = styled.tr`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const TableHeaderItem = styled.th`
  display: flex;
  flex: 1;

  max-width: ${(props) => (props.$big ? "250px" : "200px")};
  width: ${(props) => (props.$big ? "250px" : "200px")};

  font-family: "Avenir";
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;

  align-items: center;

  justify-content: ${(props) => (props.$centerText ? "center" : "flex-start")};
  &:first-of-type {
    justify-content: flex-start;
    padding-left: 4rem;
  }
  &:last-of-type {
    padding-right: 4rem;

    justify-content: flex-end;
  }
  min-width: 10rem;
  opacity: 1;
  @media (max-height: 1200px) {
    max-width: 200px;
    width: 200px;
  }
`;

export const ExpandableTableHeaderItem = styled.th`
  width: 5.2rem;
`;

export const TableBody = styled.tbody``;

export const TableBodyRow = styled.tr`
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  justify-content: space-between;
  display: flex;
  width: 100%;
  background-color: ${(props) => props.$active && "#8A4B5E15"};

  cursor: ${(props) => (props.$offClick ? "default" : "pointer")};

  min-height: 6.4rem;
  gap: 4rem;

  &:last-of-type {
    border-bottom: none !important;
  }
  &:hover {
    background-color: #8a4b5e15;
    transition: all 0.2s ease-in-out;
  }
  &:nth-child(even) {
    background-color: #8a4b5e15;
  }
`;

export const TableBodyRowEmpty = styled.tr`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;

  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const TableBodyItemEmpty = styled.td`
  color: #000;
  flex: 1;
  user-select: none;
  font-size: 15px;
  font-family: "Avenir";
  font-weight: 500;
  letter-spacing: -0.408px;
  display: flex;
  justify-content: center;

  align-items: center;
  gap: 12px;
`;
export const TableBodyItem = styled.td`
  flex: 1;
  max-width: ${(props) => (props.$big ? "250px" : "200px")};
  width: ${(props) => (props.$big ? "250px" : "200px")};

  text-align: center;
  display: flex;
  align-items: center;
  gap: 12px;

  font-family: "Avenir-Medium";

  font-size: 18px;
  line-height: 25px;
  max-height: 64px;
  justify-content: ${(props) => (props.$centerText ? "center" : "flex-start")};
  &:first-of-type {
    text-align: left;
    padding-left: 4rem;
    justify-content: flex-start;
  }
  &:last-of-type {
    justify-content: flex-end;
    text-align: right;
    padding-right: 4rem;
  }
  letter-spacing: -0.177777px;
  color: #8a4b5e;

  @media (max-height: 1200px) {
    max-width: 200px;
    width: 200px;
  }
`;
export const Td = styled.td`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const PaginationContainer = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  justify-content: flex-end;
  padding: 1.6rem 2.4rem;

  bottom: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);

  right: 0;
  left: 0;
`;

export const Spinner = styled.img`
  height: 4rem;
  object-fit: contain;
`;

export const TableItemContainer = styled.div``;

export const ProfilePhotoContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: linear-gradient(0deg, #f1e4df 0%, #f1e4df 100%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProfilePhoto = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 16px;
`;
