import styled from "styled-components";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;
`;
export const Title = styled.span`
  color: #000;
  text-align: center;
  font-family: "Avenir-Black";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07px;
`;
export const StyledButton = styled.button`
  width: 50%;
  height: 40px;
  border-top: 1px solid #d1d2d4;
  border-left: none;
  border-right: 0.5px solid #d1d2d4;
  background-color: white;
  cursor: pointer;
  border-bottom-left-radius: 5px;
  border-bottom: 1px solid #d1d2d4;
  color: #017afe;
  text-align: center;
  font-family: "Avenir-Black";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07px;
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 20%;
  width: 100%;
  align-items: center;
`;

export const StyledAHref = styled.a`
  width: 50%;
  height: 40px;
  border-top: 1px solid #d1d2d4;
  background-color: white;
  cursor: pointer;
  border-bottom: 1px solid #d1d2d4;
  color: #017afe;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-decoration: unset;

  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 5px;
  border-left: 0.5px solid #d1d2d4;
  border-right: 1px solid #d1d2d4;
  font-family: "Avenir-Heavy";
`;
