import { createAsyncThunk } from "@reduxjs/toolkit";
import giftService from "../../../api/services/gift";

export const getRecipients = createAsyncThunk(
  "gifts/getRecipients",
  async (requestData) => {
    return await giftService.getRecipients(requestData);
  }
);

export const addRecipient = createAsyncThunk(
  "gifts/addRecipient",
  async (requestData) => {
    return await giftService.addRecipient(requestData);
  }
);

export const deleteRecipient = createAsyncThunk(
  "gifts/deleteRecipient",
  async (requestData) => {
    return await giftService.deleteRecipient(requestData);
  }
);

export const addRecipientsWithFile = createAsyncThunk(
  "gifts/uploadRecipients",
  async (requestData) => {
    return await giftService.addRecipientsWithFile(requestData);
  }
);

export const editRecipient = createAsyncThunk(
  "gifts/editRecipient",
  async (requestData) => {
    return await giftService.editRecipient(requestData);
  }
);
