import React, { useEffect, useRef, useState } from "react";
import {
  CartContainer,
  CartIcon,
  FavoriteIcon,
  Row,
  MenuContainer,
  ModalHeader,
  ModalFooter,
  Text,
  Button,
  Column,
  Content,
  LinkContainer,
  Link,
  SearchIcon,
  ChevronDown,
  StyledRow,
  StyledColumn,
  StyledLink,
  SearchInput,
  SearchContainer,
  StyledIcon,
  LogoutContainer,
  StyledText,
} from "./SmallScreensSideBarModal.styles";
import Modal from "./Modal2";
import { useTranslation } from "react-i18next";
import UseAnimations from "react-useanimations";
import menu4 from "react-useanimations/lib/menu4";
import { useNavigate } from "react-router-dom";
import LocaleSelection from "../LocaleSelection/LocaleSelection";
import { useDispatch } from "react-redux";
import { getCategories } from "../../store/slices/marketplace/asyncThunks";
import { categoriesData } from "../../store/slices/marketplace/marketplaceSlice";
import { useSelector } from "react-redux";
import { countClickAction } from "../../store/slices/common/asyncThunks";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout2.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { selectBusinessUserInfo } from "../../store/slices/businessUser/slice";
import { selectUserInfo } from "../../store/slices/authRecipient/slice";
import { logoutRecipient } from "../../store/slices/authRecipient/asyncThunks";
import { logout } from "../../store/slices/businessUser/asyncThunks";
import openToast from "../../utils/toast";
import Icon from "../Icon";

const SmallScreensSideBarModal = ({
  open,
  close,
  setOpenSidebarModal,
  openSidebarModal,
  loggedIn,
  Notification1,
  Notification3,
  hideSearch,
  showCart,
  ar,
  showFavorite,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const businessUserInfo = useSelector(selectBusinessUserInfo);
  const userInfo = useSelector(selectUserInfo);
  const [showCategory, setShowCategory] = useState(false);
  const categories = useSelector(categoriesData);
  const [isVisible, setIsVisible] = useState(showCategory);
  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const toastId = useRef(null);

  useEffect(() => {
    let timer;
    if (showCategory) {
      setIsVisible(true);
    } else {
      timer = setTimeout(() => setIsVisible(false), 250);
    }

    return () => clearTimeout(timer);
  }, [showCategory]);

  const navigateTo = (route) => {
    navigate(route);
    setOpenSidebarModal(false);
  };

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && searchValue !== "") {
      if (loggedIn) {
        navigate(`/admin-portal/search?searchTerm=${searchValue}`);
        close();
      } else {
        navigate(`/search?searchTerm=${searchValue}`);
        close();
      }
    }
  };

  useEffect(() => {
    if (showSearch) {
      document.addEventListener("keypress", handleKeyPress);
    }
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleLogout = () => {
    if (businessUserInfo.id > 0) {
      dispatch(logout())
        .unwrap()
        .then(() => {
          close();
          navigate("/login");
        })
        .catch((error) => {
          openToast(toastId, "logout-sidebar", "error", t(error.message));
        });
    } else if (userInfo.id > 0) {
      dispatch(logoutRecipient())
        .unwrap()
        .then(() => {
          close();
          navigate("/login/user");
        })
        .catch((error) => {
          openToast(toastId, "logout-sidebar", "error", t(error.message));
        });
    }
  };

  return (
    <Modal
      onClose={close}
      open={open}
      right
      width={"80%"}
      height={"100%"}
      borderRadius={ar ? "0px 30px 0px 0px" : "30px 0px 0px 0px"}
      bg={"#8A4B5E"}
      blur={"3.5px"}
      paddingOuter={0}
      className={"responsive"}
      hideScrollToTop={false}
    >
      <Column>
        <ModalHeader>
          <MenuContainer>
            <UseAnimations
              onClick={() => {
                setOpenSidebarModal(!openSidebarModal);
              }}
              className="pointer"
              animation={menu4}
              reverse
              size={42}
              strokeColor="#8A4B5E"
            />
          </MenuContainer>
          <Row $ar={ar}>
            {!hideSearch && (
              <SearchContainer $open={showSearch}>
                <StyledIcon>
                  <Icon
                    icon={
                      <SearchIcon onClick={() => setShowSearch(!showSearch)} />
                    }
                    selectedLocale={ar}
                  />
                </StyledIcon>
                <SearchInput
                  placeholder={"Search anything.."}
                  disabled={!showSearch}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </SearchContainer>
            )}
            {loggedIn && showFavorite && (
              <CartContainer
                onClick={() => {
                  navigate("/admin-portal/favorite");
                }}
              >
                <Icon icon={<FavoriteIcon />} selectedLocale={ar} />

                <Notification3 />
              </CartContainer>
            )}
            {showCart && (
              <CartContainer
                onClick={() => {
                  if (loggedIn) {
                    navigate("/admin-portal/cart");
                  } else {
                    navigate("/cart");
                  }
                }}
              >
                <Icon icon={<CartIcon />} selectedLocale={ar} />
                <Notification1 />
              </CartContainer>
            )}
            <LocaleSelection color marginLeft={0} />
          </Row>
        </ModalHeader>

        <Content>
          <LinkContainer
            $open={showCategory}
            onClick={() => {
              setShowCategory(!showCategory);
            }}
          >
            <StyledRow>
              <Link> {t("marketplace")}</Link>
              <ChevronDown $open={showCategory} />
            </StyledRow>
            {isVisible && (
              <StyledColumn $open={showCategory}>
                <StyledLink
                  onClick={() => {
                    dispatch(countClickAction("marketplace"));
                    navigateTo("/marketplace");
                  }}
                >
                  {t("all")}
                </StyledLink>
                {categories.categories.map((category) => (
                  <StyledLink
                    key={category.id}
                    onClick={() => {
                      dispatch(countClickAction("marketplace"));
                      navigate(`/marketplace?category=${category.id}`);
                      close();
                    }}
                  >
                    {t(`${category.name}`)}
                  </StyledLink>
                ))}
              </StyledColumn>
            )}
          </LinkContainer>
          <LinkContainer
            onClick={() => {
              if (loggedIn) {
                navigateTo("/sign-up/steps/upgrade");
              } else {
                navigateTo("/pricing");
              }
              dispatch(countClickAction("giftingAsAService"));
            }}
          >
            <Link> {t("gifting-as-a-service")}</Link>
          </LinkContainer>
        </Content>
      </Column>

      <ModalFooter $gap={loggedIn}>
        {loggedIn ? (
          <>
            <LogoutContainer>
              <StyledText className="login-btn" onClick={handleLogout} $color>
                {t("logout")}
              </StyledText>
              <div>
                <Icon icon={<LogoutIcon />} selectedLocale={ar} />
              </div>
            </LogoutContainer>
            <Button onClick={() => navigateTo("/admin-portal/dashboard")}>
              <Icon icon={<DashboardIcon />} selectedLocale={ar} />
            </Button>
          </>
        ) : (
          <>
            <Text
              className="login-btn"
              onClick={() => navigateTo("/login")}
              $color
            >
              {t("login")}
            </Text>
            <Button onClick={() => navigateTo("/sign-up")}>
              <Text className="login-btn">{t("sign-up")}</Text>
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default SmallScreensSideBarModal;
