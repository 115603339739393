import styled from "styled-components";

export const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  background-color: ${(props) =>
    props.$bg ? "rgba(255,255,255,.5)" : "unset"};
  backdrop-filter: ${(props) => (props.$bg ? "blur(2px)" : "unset")};
  align-items: ${(props) => (props.$bg ? "center" : "flex-start")};
  border-radius: ${(props) => (props.$bg ? "10px" : "0px")};
  padding: ${(props) => (props.$bg ? "2px" : "0px")};
  cursor: pointer;
  box-shadow: ${(props) =>
    props.$bg ? "0px 0px 5px 2.5px rgba(0, 0, 0,0.15)" : "unset"};
  transform: ${(props) => (!props.$rotate ? "rotate(-4deg)" : "unset")};
  bottom: 20px;
  position: ${(props) => (!props.$bg ? "absolute" : "unset")};
`;

export const Dot = styled.span`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${(props) => (props.$active ? "#8A4B5E" : "white")};
  outline: 1px solid #8a4b5e15;
  box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.25);
  margin: 0px 5px;
  cursor: pointer;

  transition: all 0.3s ease-in-out;
  &:hover {
    scale: 1.1;
  }

  &:active {
    scale: 0.95;
  }
`;
