import { createSlice } from "@reduxjs/toolkit";
import {
  deleteCompanyBusinessUserBuilder,
  fetchCompanyUsersBuilder,
} from "./builder";

const initialState = {
  users: {},
  loading: true,
  error: null,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanyUsers: (state, action) => {
      state.users = action.payload;
    },
  },
  extraReducers: (builder) => {
    fetchCompanyUsersBuilder(builder);
    deleteCompanyBusinessUserBuilder(builder);
    builder.addCase("logout", () => ({ ...initialState, loggedIn: false }));
  },
});

export const { setCompanyUsers } = companySlice.actions;

export const companyUsersLoader = (state) => state.company.loading;
export const companyUsersData = (state) => state.company.users;

export default companySlice.reducer;
