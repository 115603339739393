import React, { useEffect, useRef, useState } from "react";
import {
  Image,
  MainContainer,
  MobileScreenContainer,
  ItemName,
  ItemContent,
  ItemPrice,
  Row,
  InputContainer,
  Placeholder,
  Value,
  Column,
  ButtonContainer,
  ButtonFavorite,
  Button,
  PlaceHolderRow,
  Icon,
  ImageContainer,
  Absolute,
  LoaderWrapper,
  ImageWrapper,
} from "./MobileAppPage.styles";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { priceFormatter } from "../../utils/utils";
import { ReactComponent as Heart } from "../../assets/icons/heart-empty.svg";
import { ReactComponent as Chevron } from "../../assets/icons/chevron-left.svg";
import { getCurrencySymbol } from "../../utils/constants";
import MediaSlider from "../../components/MediaSlider/MediaSlider";
import Dots from "../../components/Dots/Dots";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectedGetItemLoading,
  selectedItem,
} from "../../store/slices/item/itemSlice";
import MiniLoader from "../../components/MiniLoader/MiniLoader";
import { getItemUnauthorized } from "../../store/slices/item/asyncThunks";
import DownloadAppModal from "../../components/DownloadAppModal/DownloadAppModal";
import { useSwipeable } from "react-swipeable";
import AppLoader from "../../components/AppLoader";

const MobileAppItemDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openModal = useRef();
  const { t } = useTranslation();
  const { id } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const item = useSelector(selectedItem);
  const loading = useSelector(selectedGetItemLoading);

  useEffect(() => {
    dispatch(getItemUnauthorized(id));
  }, [dispatch, id]);

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setActiveIndex((prevIndex) =>
        prevIndex === item?.photos.length - 1 ? 0 : prevIndex + 1
      );
    },
    onSwipedRight: () => {
      setActiveIndex((prevIndex) =>
        prevIndex === 0 ? item?.photos.length - 1 : prevIndex - 1
      );
    },
  });

  return (
    <MainContainer>
      <DownloadAppModal modalRef={openModal} />

      <MobileScreenContainer>
        {!loading ? (
          <>
            <ImageContainer>
              <Icon onClick={() => navigate(-1)}>
                <Chevron />
              </Icon>
              {item?.photos?.length > 0 ? (
                item?.photos?.length > 1 ? (
                  <ImageWrapper
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                    }}
                    {...handlers}
                  >
                    <MediaSlider
                      style={{
                        objectFit: item.productId ? "contain" : "cover",
                      }}
                      mobileApp
                      hideArrows
                      pictures={item?.photos}
                      activeIndex={activeIndex}
                      onSlideChange={setActiveIndex}
                      timer={3000}
                    />
                    <Absolute>
                      <Dots
                        total={item?.photos.length}
                        activeIndex={activeIndex}
                        onDotClick={handleDotClick}
                      />
                    </Absolute>
                  </ImageWrapper>
                ) : (
                  <ImageWrapper>
                    <Image
                      src={item?.photos[0].url}
                      style={{
                        objectFit: item.productId ? "contain" : "cover",
                      }}
                    />
                  </ImageWrapper>
                )
              ) : (
                <AppLoader />
              )}
            </ImageContainer>
            <ItemContent>
              <Column>
                <Row>
                  <ItemName>{item?.name}</ItemName>
                  {item.isBought ? (
                    <ItemPrice $isBought>
                      {priceFormatter(item?.price, true, 15)}{" "}
                      {getCurrencySymbol(item?.currency)}
                    </ItemPrice>
                  ) : (
                    <ItemPrice>
                      {priceFormatter(item?.price, true, 15)}{" "}
                      {getCurrencySymbol(item?.currency)}
                    </ItemPrice>
                  )}
                </Row>
                {item?.description && (
                  <InputContainer>
                    <PlaceHolderRow>
                      <Placeholder>📃</Placeholder>
                      <Placeholder>{t("description")}</Placeholder>
                    </PlaceHolderRow>
                    <Value>{item.description}</Value>
                  </InputContainer>
                )}
                {item?.categories?.length > 0 && (
                  <InputContainer>
                    <PlaceHolderRow>
                      <Placeholder>🛍</Placeholder>
                      <Placeholder>{t("category")}</Placeholder>
                    </PlaceHolderRow>
                    {item?.categories.map((category) => (
                      <Value>{category.name}</Value>
                    ))}
                  </InputContainer>
                )}
                {item?.url && (
                  <InputContainer>
                    <PlaceHolderRow>
                      <Placeholder>💻</Placeholder>
                      <Placeholder>{t("url")}</Placeholder>
                    </PlaceHolderRow>
                    <Value>{item?.url}</Value>
                  </InputContainer>
                )}
                {item?.location && (
                  <InputContainer>
                    <PlaceHolderRow>
                      <Placeholder>📍</Placeholder>
                      <Placeholder>{t("gift-location")}</Placeholder>
                    </PlaceHolderRow>
                    <Value>{item?.location.name}</Value>
                  </InputContainer>
                )}
              </Column>
              {item?.buyable && !item?.isBought && (
                <ButtonContainer>
                  <ButtonFavorite onClick={() => openModal.current.show()}>
                    <Heart />
                  </ButtonFavorite>
                  <Button
                    onClick={() => {
                      navigate(`/mobile-app/checkout/${id}`);
                    }}
                  >
                    {t("buy-now")}
                  </Button>
                </ButtonContainer>
              )}
            </ItemContent>
          </>
        ) : (
          <LoaderWrapper>
            <MiniLoader />
          </LoaderWrapper>
        )}
      </MobileScreenContainer>
    </MainContainer>
  );
};

export default MobileAppItemDetailPage;
