import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MainContainer,
  Column,
  ContentContainer,
  ItemContainer,
  LoadingContainer,
  Text,
  HeaderWrapper,
} from "./AllCategoryItemsPage.styles";
import ContentHeader from "../utils/ContentHeader";
import { getItemsByCategory } from "../../../store/slices/gift/asyncThunks";
import { useNavigate, useParams } from "react-router-dom";
import {
  selectRecipientsWithWishlists,
  selectedCategoryData,
  selectedCategoryLoading,
  selectedCategoryTitle,
} from "../../../store/slices/gift/slice";
import {
  selectGifts,
  removeGift,
  addGifts,
} from "../../../store/slices/sendGifts/slice";

import { useTranslation } from "react-i18next";
import GiftItemCard from "../../../components/GiftItemCard/GiftItemCard";
import Header from "../../../components/Header/Header";
import openToast from "../../../utils/toast";
import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";
import CategoryBar from "../../../components/CategoryBar/CategoryBar";
import AppLoader from "../../../components/AppLoader";

const AllCategoryItemsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const data = useSelector(selectedCategoryData);
  const sectionTitle = useSelector(selectedCategoryTitle);
  const selectedItems = useSelector(selectGifts);
  const { categoryId } = useParams();
  const loading = useSelector(selectedCategoryLoading);
  const toastId = useRef(null);
  const { company, state } = useSelector(selectBusinessUserInfo);
  const showUpgradeBar = company.packageType === "Free";
  const recipientsWithWishlist = useSelector(selectRecipientsWithWishlists);

  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  useEffect(() => {
    dispatch(
      getItemsByCategory({
        categoryId,
        page: 1,
        order: "DESC",
        sortBy: "CreatedAt",
        search: "",
      })
    );
  }, [categoryId, dispatch]);

  const handleItemClick = (id) => {
    navigate(`/admin-portal/item-detail/${id}`);
  };
  const addToItemCart = (item) => {
    let foundItem = false;

    selectedItems.forEach((data) => {
      if (data.id === item.id) {
        const itemSubcategories = data.subcategory?.map(
          (subcategory) => subcategory.valueId
        );
        const addedItemSubcategories = item.subcategoryItems?.map(
          (subcategory) => subcategory.valueId
        );

        if (
          itemSubcategories?.sort().join(",") ===
          addedItemSubcategories?.sort().join(",")
        ) {
          foundItem = true;
        }
      }
    });

    if (!foundItem) {
      if (recipientsWithWishlist[0]?.items?.length > 0) {
        if (selectedItems.length === 1) {
          openToast(
            toastId,
            "add-item-to-cart",
            "error",
            t("select-max-1-item")
          );
          return;
        }
      }

      if (
        recipientsWithWishlist[0]?.items?.length === 0 ||
        recipientsWithWishlist.length === 0
      ) {
        if (selectedItems.length === 3) {
          openToast(
            toastId,
            "add-item-to-cart",
            "error",
            t("select-max-3-items")
          );
          return;
        }
      }
      dispatch(
        addGifts({
          id: item.id,
          image: item.photoUrl,
          name: item.name,
          price: item.price,
          currency: item.currency,
          subcategory: item.subcategoryItems,
        })
      );
    } else {
      dispatch(removeGift(item.id));
    }
  };

  return (
    <>
      <HeaderWrapper>
        <Header showLogin showNav color showUser showCart showFavorite />
      </HeaderWrapper>
      <MainContainer>
        <CategoryBar />
        <Column $upgradePackage={showUpgradeBar && diff > 0 && state}>
          <ContentHeader
            showTitle={true}
            showBack={true}
            title={t(
              "categories." +
                [sectionTitle.toLowerCase().replace(" ", "-").replace("/", "-")]
            )}
          />
          <ContentContainer style={{ marginTop: 40 }}>
            <ItemContainer>
              {!loading ? (
                data.length > 0 ? (
                  data.map((item) => (
                    <GiftItemCard
                      item={item}
                      key={item.id}
                      itemDetails={() => {
                        handleItemClick(item.id);
                      }}
                      isSelected={
                        !!selectedItems.filter(
                          (selectedItem) => item.id === selectedItem.id
                        ).length
                      }
                      addToItemCart={addToItemCart}
                    />
                  ))
                ) : (
                  <Text>{t("category-is-empty")}</Text>
                )
              ) : (
                <LoadingContainer>
                  <AppLoader width={100} height={100} />
                </LoadingContainer>
              )}
            </ItemContainer>
          </ContentContainer>
        </Column>
      </MainContainer>
    </>
  );
};

export default AllCategoryItemsPage;
