import { createAsyncThunk } from "@reduxjs/toolkit";
import planService from "../../../api/services/plan";

export const bookCall = createAsyncThunk(
  "plan/book-call",
  async (requestData) => {
    return await planService.bookCall(requestData);
  }
);

export const getPlans = createAsyncThunk("plan/get-plans", async () => {
  return await planService.getPlans();
});

export const choosePlan = createAsyncThunk(
  "plan/choose-plan",
  async (requestData) => {
    return await planService.choosePlan(requestData);
  }
);

export const assignPlan = createAsyncThunk(
  "plan/assign-plan",
  async (requestData) => {
    return await planService.assignPlan(requestData);
  }
);

export const cancelPlan = createAsyncThunk(
  "plan/cancel-plan",
  async (requestData) => {
    return await planService.cancelPlan(requestData);
  }
);
