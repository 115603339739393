import React, { useEffect, useRef, useState } from "react";
import {
  MainContainer,
  Column,
  CardProduct,
  CardProductName,
  ProductInfo,
  Row,
  Price,
  Icons,
  ImageContainer,
  StyledInfinityScroll,
  HeaderWrapper,
  Layout,
  Subtitle,
  IconContainer,
  SortContainer,
  SortName,
  SectionTitle,
} from "./SearchPage.styles.js";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UseAnimations from "react-useanimations";
import trash2 from "react-useanimations/lib/trash2";
import heart from "react-useanimations/lib/heart";
import {
  resetEditItemData,
  resetSelectedItem,
  toggleFavoriteItem,
} from "../../../store/slices/marketplace/marketplaceSlice";
import { deleteMarketplaceItemLoader } from "../../../store/slices/marketplace/marketplaceSlice.js";
import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";
import { priceFormatter } from "../../../utils/utils";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import { toast } from "react-toastify";
import { ReactComponent as Edit } from "../../../assets/icons/edit-3.svg";
import { ReactComponent as Share } from "../../../assets/icons/share-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer.js";
import Header from "../../../components/Header/Header";
import { getCurrencySymbol } from "../../../utils/constants";
import Sidebar from "../utils/Sidebar";
import LoginModal from "../../../components/Modal/LoginModal.js";

import {
  useFavoriteItemMutation,
  useGetCategoriesQuery,
  useGetMarketplaceItemsQuery,
} from "../../../store/slices/marketplace/marketplaceApi.js";
import { api } from "../../../store/api/index.js";
import store from "../../../store/index.js";
import SkeletonMarketplaceLoader from "../../../components/SkeletonLoader/SkeletonMarketplaceLoader/SkeletonMarketplaceLoader.js";
import { removeItem } from "../../../store/slices/marketplace/asyncThunks.js";
import SkeletonMarketplaceSortLoader from "../../../components/SkeletonLoader/SkeletonMarketplaceSortLoader/SkeletonMarketplaceSortLoader.js";
import openToast from "../../../utils/toast.js";
import AppLoader from "../../../components/AppLoader.js";

function ProductCard({ product, itemDetails, removeItem, modal, reverse }) {
  const businessUser = useSelector(selectBusinessUserInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productId = product.id;
  const [favoriteItem] = useFavoriteItemMutation();
  const { t } = useTranslation();
  const toastId = useRef(null);

  const handleOnClickEdit = () => {
    const dataToSend = {
      productId: productId,
      action: "edit",
    };
    navigate(`/admin-portal/marketplace/add-an-item/${dataToSend.action}`, {
      state: dataToSend,
    });
  };

  const onShare = (id) => {
    const link = businessUser.loggedIn
      ? `${window.location.origin}/admin-portal/marketplace/item-detail/${id}`
      : `${window.location.origin}/marketplace/${id}`;

    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success(t("link-copied-to-clipboard"));
      })
      .catch((error) => {
        openToast(
          toastId,
          "share-marketplace-item",
          "error",
          t("Failed to copy link")
        );
        console.error("Error copying link to clipboard:", error);
      });
  };

  return (
    <CardProduct>
      <Icons>
        {businessUser.role === "superAdmin" && (
          <>
            <IconContainer>
              <UseAnimations
                animation={trash2}
                size={32}
                wrapperStyle={{ marginTop: -4 }}
                strokeColor="#8A4B5E"
                onClick={() => removeItem(product.id)}
              />
            </IconContainer>
            <IconContainer>
              <Edit width={"auto"} height={27} onClick={handleOnClickEdit} />
            </IconContainer>
          </>
        )}
        <IconContainer>
          <Share
            height={24}
            width={"auto"}
            onClick={() => onShare(productId)}
          />
        </IconContainer>
        <IconContainer>
          <UseAnimations
            animation={heart}
            fillColor={businessUser?.loggedIn ? "#8A4B5E" : "transparent"}
            strokeColor="#8A4B5E"
            reverse={reverse}
            size={32}
            onClick={
              !businessUser?.loggedIn
                ? modal
                : () => {
                    dispatch(
                      toggleFavoriteItem({
                        id: productId,
                        like: !reverse,
                        businessUserId: businessUser.id,
                      })
                    );
                    favoriteItem({ id: productId })
                      .unwrap()
                      .then(() => {})
                      .catch((err) => {
                        openToast(
                          toastId,
                          "add-item-to-fav",
                          "error",
                          t(err.data.message)
                        );
                      });
                  }
            }
          />
        </IconContainer>
      </Icons>

      <ImageContainer onClick={itemDetails}>
        <img
          style={{
            width: 250,
            height: 241,
            objectFit: product?.productId ? "contain" : "cover",
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            userSelect: "none",
            pointerEvents: "none",
          }}
          src={product?.photos[0]?.url}
          alt="PhotoProduct"
        />
      </ImageContainer>

      <ProductInfo>
        <CardProductName
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {product?.name}
        </CardProductName>
        <Row>
          <Price>
            {t("from")} {priceFormatter(product?.price ?? product?.samplePrice)}{" "}
            {getCurrencySymbol(product?.currency)}
          </Price>
          {product.minQuantity && (
            <Price>
              Min Qty:{" "}
              <Price style={{ fontWeight: 800 }}>{product.minQuantity}</Price>
            </Price>
          )}
        </Row>
      </ProductInfo>
    </CardProduct>
  );
}

const SearchPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loggedIn, role, company, state } = useSelector(
    selectBusinessUserInfo
  );
  const deletePending = useSelector(deleteMarketplaceItemLoader);
  const [selectedItem, setSelectedItem] = useState(null);
  const [areYouSureModal, setAreYouSureModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchTerm = queryParams.get("searchTerm");
  const toastId = useRef(null);

  const [queryData, setQueryData] = useState({
    page: 1,
    category: "",
    search: searchTerm || "",
  });

  useEffect(() => {
    if (loggedIn) {
      if (searchTerm && searchTerm !== null) {
        navigate(`/admin-portal/search?searchTerm=${searchTerm}`);
      }
    } else {
      if (searchTerm && searchTerm !== null) {
        navigate(`/search?searchTerm=${searchTerm}`);
      }
    }
    if (searchTerm !== queryData.search) {
      setQueryData({
        page: 1,
        category: "",
        search: searchTerm !== "" ? searchTerm : "",
      });
    }
  }, [loggedIn, navigate, queryData.search, searchTerm]);

  const { data: categories, isFetching: categoriesPending } =
    useGetCategoriesQuery({ search: searchTerm });
  const { data, isFetching, originalArgs } =
    useGetMarketplaceItemsQuery(queryData);

  const loadMoreItems = () => {
    if (!data?.maxReached && !isFetching) {
      setQueryData((prev) => {
        const page = originalArgs?.page + 1 ?? queryData.page + 1;
        return { ...prev, page };
      });
    }
  };

  const removeItemOpenModal = (id) => {
    setAreYouSureModal(true);
    setSelectedItem(id);
  };

  const removeItemAction = () => {
    dispatch(removeItem(selectedItem))
      .unwrap()
      .then(() => {
        setAreYouSureModal(false);

        toast.success(t("item-deleted"));

        const entries = api.util.selectInvalidatedBy(store.getState(), [
          "marketplaceItems",
        ]);
        for (const { originalArgs } of entries) {
          dispatch(
            api.endpoints.getMarketplaceItems.initiate(originalArgs, {
              subscribe: false,
              forceRefetch: true,
            })
          );
        }
      })
      .catch((err) => {
        openToast(toastId, "remove-item", "error", t(err.message));
      });
  };

  const handleItemClick = (itemId) => {
    dispatch(resetSelectedItem());
    dispatch(resetEditItemData());
    if (loggedIn) {
      navigate(`/admin-portal/marketplace/item-detail/${itemId}`);
    } else {
      navigate(`/marketplace/${itemId}`);
    }
  };

  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  return (
    <>
      {loggedIn && <Sidebar categoriesBar />}
      <HeaderWrapper>
        <Header
          showLogin
          showSignUp
          showNav
          color
          display
          showFavorite
          showCart
          setModal={setLoginModal}
          modal={loginModal}
          showUser
          onSearchPage
        />
      </HeaderWrapper>
      <MainContainer>
        <Layout>
          <Column
            $loggedIn={!loggedIn}
            $upgradePackage={showUpgradeBar && diff > 0 && state}
          >
            {categories?.data?.categories?.length > 0 && (
              <SectionTitle $loggedIn={loggedIn}>
                {t("categories.categories")}
              </SectionTitle>
            )}

            {categoriesPending ? (
              <SortContainer>
                <SkeletonMarketplaceSortLoader />
              </SortContainer>
            ) : (
              categories?.data?.categories?.length > 0 && (
                <SortContainer>
                  {categories?.data?.categories.map((s) =>
                    categories.loading ? (
                      <SkeletonMarketplaceSortLoader key={s.id} />
                    ) : (
                      <SortName
                        key={s.id}
                        onClick={() => {
                          if (loggedIn) {
                            navigate(
                              `/admin-portal/marketplace?category=${s.id}`
                            );
                          } else {
                            navigate(`/marketplace?category=${s.id}`);
                          }
                        }}
                      >
                        {s.name}
                      </SortName>
                    )
                  )}
                </SortContainer>
              )
            )}
            <SectionTitle $loggedIn={loggedIn}>{t("items")}</SectionTitle>
            {isFetching ? (
              <SkeletonMarketplaceLoader />
            ) : data?.data?.length > 0 ? (
              <StyledInfinityScroll
                $admin={role === "superAdmin"}
                dataLength={data?.data?.length ?? 0}
                hasMore={!data?.maxReached}
                next={loadMoreItems}
                loader={<AppLoader />}
              >
                {data?.data?.map((product) => (
                  <ProductCard
                    key={product.id}
                    reverse={product.favorite}
                    itemDetails={() => {
                      handleItemClick(product.id);
                    }}
                    product={product}
                    modal={() => {
                      setLoginModal(true);
                    }}
                    removeItem={removeItemOpenModal}
                  />
                ))}
              </StyledInfinityScroll>
            ) : (
              <Subtitle>{t("nothing-to-show-here")}</Subtitle>
            )}
          </Column>
        </Layout>
        <LoginModal open={loginModal} close={() => setLoginModal(false)} />
        <ModalConfirm
          open={areYouSureModal}
          close={() => {
            setAreYouSureModal(false);
          }}
          onClickNo={() => {
            setAreYouSureModal(false);
          }}
          onClickYes={() => {
            removeItemAction();
          }}
          confirmLoading={deletePending}
        />
      </MainContainer>
      {!loggedIn && <Footer />}
    </>
  );
};

export default SearchPage;
