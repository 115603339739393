import styled from "styled-components";

export const MainContainer = styled.div`
  flex-direction: row;
  background: #f6f6f9;

  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
`;

export const HeaderWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  justify-content: center;
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  margin-top: 75px;
  padding: ${(props) =>
    props.$upgradePackage && props.$ar
      ? "100px 290px 80px 40px"
      : props.$upgradePackage && !props.$ar
      ? "100px 40px 80px 290px"
      : !props.$upgradePackage && props.$ar
      ? "40px 290px 80px 40px"
      : "40px 40px 80px 290px"};

  @media (max-width: 870px) {
    margin-top: 50px;
    padding: ${(props) =>
      props.$upgradePackage ? "60px 40px 40px 40px" : "0px 40px 40px 40px"};
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-block: 50px;
`;

export const CardProductMockup = styled.div`
  gap: 10px;
  cursor: pointer;
  border: 1px solid rgb(1, 1, 1, 0.15);
  width: 215px;
  height: 350px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  &:hover {
    transition: transform 0.2s ease-out;
    transform: scale(1.1);
    -webkit-box-shadow: 0px 0px 30px 5px rgba(1, 1, 1, 0.1);
    -moz-box-shadow: 0px 0px 30px 5px rgba(1, 1, 1, 0.1);
    box-shadow: 0px 0px 30px 5px rgba(1, 1, 1, 0.1);
  }
`;

export const ProductNameContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #e8dbdf;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 15px 15px 20px 15px;
  margin-top: auto;
  gap: 10px;
`;
export const ProductPrice = styled.span`
  font-family: "Avenir-Heavy";
  font-size: 24px;
  cursor: default;
  letter-spacing: -0.41px;
  color: #343456;
`;
export const ColorRow = styled.div`
  padding: 0px 15px;
  display: flex;
  gap: 6px;
`;

export const Button = styled.button`
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  border: transparent;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  background: #8a4b5e;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  font-family: "Avenir-Medium";
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 60px;
  padding: 80px 0px 80px 0px;
`;

export const BackContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

export const IconRotate = styled.div`
  transform: rotate(90deg);
  margin-right: -10px;
`;

export const ImagePadding = styled.div`
  height: 100%;
  padding-top: 30px;
`;

export const Sizes = styled.span`
  font-family: "Avenir-Heavy";
  font-size: 14px;
`;
export const Circle = styled.div`
  border-radius: 50%;
  cursor: pointer;
  border: 1.5px solid #fff;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Icons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
`;
export const SectionTitle = styled.h1`
  margin-bottom: 60px;
  color: #8a4b5e;
  font-family: "Avenir";
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.178px;
`;
export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
`;
export const SortContainer = styled.div`
  border-radius: 10px;
  margin-top: 40px;
  height: 46px;
  background: #fff;
  box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
`;

export const SortName = styled.span`
  color: #8a4b5e;
  user-select: none;
  text-align: center;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.067px;
  cursor: pointer;
  &:active {
    text-decoration: underline;
  }
`;
export const CardProduct = styled.div`
  border-radius: 10px;
  position: relative;
  background: #fff;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  height: 304px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
`;
export const CardProductName = styled.span`
  color: #000;
  font-family: "Avenir";
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.067px;
`;
export const ProductInfo = styled.div`
  padding: 15px 20px;
  border-radius: 10px;
  background: #fff;
  gap: 6px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 73px;
  box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.05);
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
`;
export const Price = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-family: "Avenir";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.067px;
`;
export const Text = styled.span`
  color: #8a4b5e;
  font-family: "Avenir";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.178px;
`;

export const Categories = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 800px;
  flex-wrap: wrap;
`;

export const Category = styled.div`
  width: 164px;
  height: 48px;
  border-radius: 35px;
  background: ${(props) => (props.$active ? "#8A4B5E" : "#f3edef")};
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
`;
export const CategoryText = styled.span`
  color: ${(props) => (props.$active ? "#fff" : "#222239")};
  text-align: center;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.178px;
`;

export const ButtonNext = styled.button`
  padding: 12px 15px;
  background: #8a4b5e;
  border-radius: 15px;
  border: 0;
  color: #fff;
  font-family: "Avenir-Medium";

  font-size: 18px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: block;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &::after {
    width: 25px;
    height: 1px;
    background: white;
    transform: translateX(-3px);
    margin-top: 0px;
  }

  &::before {
    content: "";
    transform: rotate(-135deg) translateX(50%);
    width: 11px;
    height: 11px;
    background: transparent;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    margin-top: -1px;
  }

  &:hover {
    background: #4ab639;
    padding: 12px 60px 12px 15px;
  }

  &:hover::after,
  &:hover::before {
    opacity: 1;
    right: 15px;
  }
`;
export const ButtonBack = styled.button`
  padding: 12px 15px;
  background: #8a4b5e;
  border-radius: 15px;
  border: 0;
  color: #fff;
  font-family: "Avenir-Medium";

  font-size: 18px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: block;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &::after {
    width: 25px;
    height: 1px;
    background: white;
    transform: translateX(-4.5px);
    margin-top: 0px;
  }

  &::before {
    content: "";
    transform: rotate(-135deg) translateX(50%);
    width: 11px;
    height: 11px;
    background: transparent;
    border-right: 1px solid white;
    border-top: 1px solid white;
    margin-top: -1px;
  }

  &:hover {
    background: #d92525;
    padding: 12px 15px 12px 60px;
  }

  &:hover::after,
  &:hover::before {
    opacity: 1;
    right: 15px;
  }
`;
export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: auto;
  margin-bottom: 40px;
`;
