import { createSlice } from "@reduxjs/toolkit";
import { assignPlanBuilder, bookCallBuilder, getPlansBuilder } from "./builder";

const planSlice = createSlice({
  name: "planInfo",
  initialState: {
    bookCall: {
      email: "",
      firstName: "",
      lastName: "",
      companyName: "",
      hrSystem: "",
    },
    planData: {
      plans: [],
      error: null,
      loading: false,
    },
    choosePlan: {},
    maxReached: false,
    loading: false,
    error: null,
    assignPlan: {
      companyId: "",
      planId: "",
      isActive: false,
      error: null,
      loading: false,
    },
    cancelPlan: {
      error: null,
      loading: false,
    },
  },

  reducers: {
    setPlans: (state, action) => {
      state.plans = action.payload;
    },
  },

  extraReducers: (builder) => {
    bookCallBuilder(builder);
    getPlansBuilder(builder);
    assignPlanBuilder(builder);
  },
});
export const { setPlans } = planSlice.actions;

export const selectedPlans = (state) => state.planInfo.planData.plans;

export default planSlice.reducer;
