import styled from "styled-components";
import { ReactComponent as CancelIcon } from "../../assets/icons/cancel.svg";
import { ReactComponent as CheckCircleIcon } from "../../assets/icons/check-circle.svg";

export const MainContainer = styled.div`
  flex-direction: column;
  background: #f6f6f9;
  min-height: 100vh;
  display: flex;
  align-items: center;
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  width: ${(props) => (props.$loggedIn ? "100%" : "1440px")};

  padding: ${(props) => (props.$loggedIn ? "40px 40px 40px 290px" : "40px")};
  @media (max-width: 1440px) {
    width: 100%;
  }
  @media (max-width: 870px) {
    margin-top: 50px;
    padding: 40px 40px 40px 40px;
  }
`;
export const CloseContainer = styled.div`
  border-radius: 1rem;
  background: #8a4b5e;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  width: 5.5rem;
  height: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1rem;
  left: -1rem;
  z-index: 500;
  @media (max-width: 870px) {
    top: 8rem;
    right: 2rem;
    left: unset;
  }
  @media (max-width: 600px) {
    top: 1rem;
  }
`;

export const ItemsColumn = styled.div`
  width: 100%;
  max-height: 84rem;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0rem 3rem 1.2rem 3rem;
  margin-top: 5rem;

  &&::-webkit-scrollbar {
    width: 5px;
  }

  &&::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #8a4b5e55;
  }

  &&::-webkit-scrollbar-track {
    background-color: transparent;
  }

  @media (max-width: 1250px) {
    align-items: center;
  }
`;
export const ProfilePicEmpty = styled.div`
  width: 10.1rem;
  height: 10.1rem;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ItemsCard = styled.div`
  -webkit-box-shadow: 0px 0px 4px 0px rgba(1, 1, 1, 0.2);
  -moz-box-shadow: 0px 0px 4px 0px rgba(1, 1, 1, 0.2);
  box-shadow: 0px 3px 3px -1px rgba(1, 1, 1, 0.2);
  position: relative;
  padding: 30px 20px 6px 20px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 640px) {
    width: 100%;
  }
`;
export const PriceCard = styled.div`
  background: #f6f6f9;
  width: 100%;
  padding: 5rem;
  flex-direction: column;
  display: flex;
  gap: 1rem;
`;

export const ButtonDisabled = styled.button`
  color: white;
  text-transform: capitalize;
  font-family: "FrankfurterEF-Medium";
  font-size: 2rem;
  line-height: 3rem;
  display: flex;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.005em;
  width: 50%;

  padding: 0.5em 1.5em;
  user-select: none;
  border: 0px solid #fff4;
  background: #a87283;
  cursor: not-allowed;
  border-radius: 8px;
  opacity: 0.6;

  @media (max-width: 420px) {
    font-size: 1.45rem;
  }
`;
export const ButtonLoading = styled.button`
  color: white;
  text-transform: uppercase;
  font-family: "Avenir-Black";

  font-size: 1.4rem;
  line-height: 3rem;
  display: flex;
  align-items: center;
  user-select: none;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 10px 48px;
  border: 1px solid #fff4;
  background: #a87283;

  cursor: default;
  border-radius: 30px;
`;
export const Button = styled.button`
  color: white;
  text-transform: capitalize;
  font-family: "FrankfurterEF-Medium";
  font-size: 2rem;
  line-height: 3rem;
  display: flex;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 0.5em 1.5em;
  border: 0px solid #fff4;
  user-select: none;
  background: #8a4b5e;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;

  &:active {
    transition: all 0.1s ease-in;
    transform: scale(0.95);
  }

  @media (max-width: 420px) {
    font-size: 1.45rem;
  }
`;

export const ContentCard = styled.div`
  align-items: center;
  justify-content: space-between;

  display: flex;
  @media (max-width: 635px) {
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }
`;
export const ImgContainer = styled.div`
  height: 10rem;
  position: relative;
  width: 10rem;
  display: flex;
  margin: 0px 2rem;
  justify-content: center;
  align-items: center;
  @media (max-width: 635px) {
    margin: 0px;
  }
`;
export const ItemTitle = styled.span`
  align-self: center;
  font-family: "FrankfurterEF-Medium";
  user-select: none;
  font-size: 1.8rem;

  @media (max-width: 635px) {
    text-align: center;
  }
`;
export const Title = styled.span`
  color: #7f7f7f;
  text-align: center;
  user-select: none;

  font-family: "Avenir-Medium";
  font-size: 2rem;
`;
export const PriceContainer = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;
export const ItemPrice = styled.span`
  // text-align: center;
  font-family: "FrankfurterEF-Medium";
  user-select: none;
  font-size: 1.5rem;
`;
export const ItemProduction = styled(ItemPrice)`
  font-size: 1.4rem;
`;
export const ItemQuantity = styled(ItemProduction)`
  color: #85a195;
`;

export const ButtonContainer = styled.div`
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PriceBox = styled.div`
  width: 10rem;
  margin-left: -1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const QuantityBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const Row = styled.div`
  padding-top: 2.2rem;
  display: flex;
  gap: 6.6rem;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 955px) {
    gap: 3rem;
  }
  @media (max-width: 635px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
`;

export const TitleContainer = styled.div`
  display: flex;

  @media (max-width: 635px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    self-align: center;
    align-self: center;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 0px 20px;
`;

export const RightContainer = styled.div`
  min-width: 42rem;
  margin-top: 3rem;
  height: fit-content;
  background-color: #f6f6f9;
  display: flex;
  flex-direction: column;
  @media (max-width: 420px) {
    min-width: 100%;
  }
`;
export const LoaderContainer = styled.div`
  position: absolute;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  background: rgba(255, 255, 255, 0.35);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #f6f6f9;
  justify-content: space-between;

  height: 100%;
  padding-right: 0.5rem;

  width: 100%;
  overflow: auto;
  &&::-webkit-scrollbar {
    width: 5px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: #8a4b5e;
    border-radius: 10px;
  }

  &&::-webkit-scrollbar-track {
    background-color: transparent;
  }

  @media (max-width: 1680px) {
    flex-direction: column;
    align-items: center;
    gap: 8rem;
  }
`;
export const ItemPhoto = styled.img`
  object-fit: cover;
  user-select: none;
  width: 10rem;
  height: 10rem;
  border-radius: 2rem;
`;

export const Text = styled.span`
  color: #8a4b5e;
  font-family: "Avenir-Heavy";
  text-align: ${(props) => (props.$textalign ? "center" : "unset")};
  font-size: 3.6rem;
  user-select: none;
  @media (max-width: 610px) {
    font-size: 2.6rem;
  }
  @media (max-width: 390px) {
    font-size: 2rem;
  }
`;
export const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.$fullwidth ? "95.6rem" : "unset")};
  height: ${(props) => (props.$fullwidth ? "31.39rem" : "unset")};
  align-items: ${(props) => (props.$fullwidth ? "center" : "unset")};
  justify-content: ${(props) => (props.$fullwidth ? "center" : "unset")};
  @media (max-width: 875px) {
    width: 100%;
  }
`;
export const StyledColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledButton = styled(Button)`
  width: 25rem;
  background-color: #85a195;
`;

export const StyledText = styled(Text)`
  font-family: "Avenir-Black";
  font-size: 2.2rem;
  color: #7b7faa;
  text-shadow: 2px 2px 3px rgba(128, 128, 128, 0.5);
`;
export const StyledTitle = styled(Text)`
  font-family: "Avenir-Black";

  text-shadow: 2px 2px 3px rgba(128, 128, 128, 0.5);
`;
export const TextContainer = styled.div`
  margin-top: 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
  @media (max-width: 610px) {
    margin-bottom: 2rem;
  }
  @media (max-width: 500px) {
    margin-bottom: 3rem;
  }
  @media (max-width: 390px) {
    margin-bottom: 4rem;
  }
  @media (max-width: 370px) {
    margin-bottom: 7rem;
  }
`;

export const ImageContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemSpecifications = styled.span`
  font-family: "Avenir-Medium";
  flex-direction: column;
  display: flex;
  user-select: none;
  font-size: 1.4rem;
  margin-top: 10px;
`;
export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;
export const StyledItemSpecifications = styled.span`
  font-family: "Avenir-Medium";
  font-size: 1.4rem;
  flex-direction: column;
  gap: 1rem;
  user-select: none;
  display: flex;
  align-items: center;
`;

export const FooterText = styled(ItemSpecifications)`
  color: #7f7f7f;
`;

export const FooterContainer = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 3rem;
`;

export const Color = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
`;

export const Size = styled(Color)`
  background-color: #8a4b5e;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Colors = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  align-items: center;
  @media (max-width: 635px) {
    flex-direction: row;
  }
`;
export const SizeValue = styled.span`
  user-select: none;
  color: #fff;
  font-family: "Avenir-Medium";
  font-size: 1rem;

  margin-bottom: -2px;
`;
export const ColumnItem = styled.div`
  flex-direction: column;
  display: flex;
  // justify-content: ;
  flex-wrap: wrap;
  gap: 1.2rem;
  @media (max-width: 635px) {
    justify-content: center;
    align-items: center;
  }
`;

export const StyledColumnItem = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.2rem;
  @media (max-width: 635px) {
    display: none;
  }
  @media (max-width: 300px) {
    margin-top: 2rem;
  }
`;
export const Show = styled.div`
  display: none;
  @media (max-width: 635px) {
    display: flex;
  }
`;

export const OrderContentContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.2rem;
  @media (max-width: 420px) {
    justify-content: center;
    align-items: center;
  }
`;

export const Separator = styled.div`
  height: 2px;
  display: flex;
  background-color: #e8e8e8;
`;

export const EditInput = styled.input`
  padding: 0em 0.5em;
  width: 7.6rem;
  height: 3rem;
  color: #85a195;
  font-family: "FrankfurterEF-Medium";
  font-size: 1.4rem;
  border: 1px solid #ccc;
  user-select: none;
  border-radius: 8px;
`;
export const MiniText = styled.span`
  color: rgba(0, 0, 0, 0.5);
  user-select: none;
  font-family: "Avenir-Light";
  font-size: 1.4rem;
  line-height: 3.2rem; /* 228.571% */
`;
export const StyledCancelIcon = styled(CancelIcon)`
  align-self: center;
  justify-content: center;
  cursor: pointer;

  &:active {
    transition: all 0.2s ease-in;
    transform: scale(0.95);
  }
`;

export const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  align-self: center;
  justify-content: center;
  cursor: pointer;
  &:active {
    transition: all 0.1s ease-in;
    transform: scale(0.95);
  }
`;

export const StyledButtonContainer = styled(ButtonContainer)`
  justify-content: center;
  margin-top: 3rem;
`;

export const OrderSummaryText = styled(Text)`
  align-self: center;
  padding-top: 1rem;

  font-family: "Avenir-Black";
`;

export const Price = styled(Title)`
  color: black;
  font-family: "FrankfurterEF-Medium";

  font-size: 2.4rem;
`;

export const Total = styled(Title)`
  color: black;
  font-size: 2.2rem;
`;

export const DiscountContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;
  gap: 0.4rem;
  margin-top: 3rem;
`;

export const DiscountText = styled.span`
  color: #8a4b5e;
  font-family: "Avenir-Book";
  font-size: 1.6rem;
  letter-spacing: -0.178px;
  user-select: none;
`;

export const DiscountRow = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DiscountButton = styled.button`
  border-radius: 3.95rem;
  border: 1px solid #8a4b5e;
  background: #8a4b5e;
  box-shadow: 0rem 0.4rem 1rem 0rem rgba(45, 45, 67, 0.11);
  width: 8rem;
  height: 4rem;
  color: #fff;
  font-family: "Avenir-Book";
  font-size: 1.6rem;
  letter-spacing: -0.178px;
  user-select: none;
`;

export const DiscountInput = styled.input`
  width: 25.5rem;
  height: 4.6rem;

  border-radius: 3.95rem;
  padding: 0rem 1.5rem;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0rem 0.4rem 1rem 0rem rgba(45, 45, 67, 0.11);

  color: rgba(72, 72, 136, 1);
  font-family: "Avenir-Book";
  font-size: 1.6rem;
  letter-spacing: -0.178px;
`;

export const DiscountAmountText = styled.div`
  font-size: 14px;
  font-family: "Avenir-Black";
  color: #1ab900;
  padding-top: 10px;
`;
