import styled from "styled-components";

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  gap: 2.5rem;
  align-items: flex-start;
  justify-content: center;
`;
export const Button = styled.button`
  color: white;

  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 0.5em 2em;
  border: transparent;
  background: rgba(255, 255, 255, 0.15);
  width: fit-content;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.15);
  cursor: pointer;
  border-radius: 30px;
  &:hover {
    outline: 1px solid rgba(255, 255, 255, 0.15);
    background: transparent;
  }
  &:active {
    -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    outline: 1px solid rgba(255, 255, 255, 0.15);
    transition: all 0.1s ease-in;
    transform: scale(0.97);
  }
  @media (max-width: 990px) {
    font-size: 16px;
  }
  // @media (max-width: 952px) {
  //   font-size: 14px;
  // }
`;

export const Title = styled.span`
  user-select: none;
  font-family: "Avenir-Medium", sans-serif;
  font-size: 2.2rem;
  letter-spacing: -0.177777px;
  color: #fff;
  @media (max-width: 990px) {
    font-size: 16px;
  }
  // @media (max-width: 952px) {
  //   font-size: 14px;
  // }
`;
export const Text = styled.span`
  font-family: "Avenir-Medium", sans-serif;
  user-select: auto;
  font-size: 2rem;
  letter-spacing: -0.177777px;
  color: #fff;
  @media (max-width: 990px) {
    font-size: 16px;
  }
  // @media (max-width: 952px) {
  //   font-size: 14px;
  // }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  opacity: 0.7;
  gap: 10px;
`;

export const CloseContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  border-radius: 20px;
  gap: 10px;
  align-items: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.15);
  -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  // justify-content: center;
  @media (max-width: 441px) {
    flex-direction: column;
  }
`;

export const ButtonsContainer = styled.div`
  display: none;
  justify-content: space-around;
  width: 100%;
  gap: 20px;
  @media (max-width: 570px) {
    display: flex;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const LogoLogoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  margin-bottom: -30px;
  gap: 20px;
`;
