import styled from "styled-components";

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  align-items: center;
  justify-content: space-between;
  @media (max-width: 1075px) {
    align-items: flex-start;
  }
  @media (max-width: 870px) {
    justify-content: center;
    margin-bottom: 20px;
  }
`;
export const Dot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #8a4b5e;
  top: -5px;
  right: -5px;
  padding: 5px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
`;

export const CartCount = styled.span`
  color: white;
  font-size: 12px;
  font-family: "Avenir-Medium", sans-serif;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;
export const ProfilePic = styled.div`
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #8a4b5e;
  border-radius: 42px;
  cursor: pointer;
  box-shadow: 0px 3px 25px -8px rgba(0, 0, 0, 0.24);
`;
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  @media (max-width: 1205px) {
    gap: 10px;
  }
  @media (max-width: 390px) {
    flex-direction: column;
  }
`;
export const CartContainer = styled.div`
  cursor: pointer;
  position: relative;
`;
export const GiftCartContainer = styled.div`
  position: relative;
  cursor: pointer;
`;
export const GiftCart = styled.div`
  position: absolute;
  padding: 20px;
  background: #8a4b5e;
  width: fit-content;
  height: fit-content;
  right: 0;
  border: 2px solid #fff;
  top: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
`;

export const ItemName = styled.span`
  font-size: 16px;
  color: white;
  text-align: center;
  font-family: "Avenir-Medium";
`;
export const ImgContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
export const Button = styled.div`
  border-radius: 30px;
  user-select: none;
  background: #8a4b5e;
  height: 45px;
  padding: 5px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  text-align: center;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
  }
  @media (max-width: 1075px) {
    height: 35px;
    padding: 5px 20px;
    font-size: 12px;
  }
`;
export const SectionTitle = styled.h1`
  color: #8a4b5e;
  margin-top: -40px;
  font-family: "Avenir";
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.178px;
`;
export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;

  @media (max-width: 1075px) {
    align-items: flex-start;
  }
`;
