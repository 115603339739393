import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CardHeaderContainer,
  DescBox,
  Row,
  ItemPhoto,
  ItemName,
  ItemPrice,
  SelectedImageContainer,
  SelectedText,
  SelectContainer,
} from "./PackagingComponent.styles";
import { useSelector } from "react-redux";
import { selectedLocale } from "../../store/slices/common/slice";
import SuccessImg from "../../assets/images/success.png";

const PackagingComponent = ({ items, selectedPackage, setSelectedPackage }) => {
  const { t } = useTranslation();

  const locale = useSelector(selectedLocale);

  return (
    <>
      {items.length &&
        items.map((item) => {
          return (
            <Row
              key={item.id}
              $selected={selectedPackage?.id === item.id}
              onClick={() => {
                setSelectedPackage(item);
              }}
            >
              <CardHeaderContainer>
                <ItemPhoto src={item?.photoUrl} alt="Item-img" />
                <DescBox>
                  <ItemName>{t(`${item?.name}`)}</ItemName>
                  <ItemPrice>
                    {item.price === 0
                      ? `${t("free")} `
                      : `${t("price-per-item")} ${item?.price} SR`}
                  </ItemPrice>
                </DescBox>
              </CardHeaderContainer>
              {selectedPackage?.id === item.id && (
                <SelectContainer>
                  <SelectedImageContainer src={SuccessImg} alt="success-img" />
                  <SelectedText>{t("selected")}</SelectedText>
                </SelectContainer>
              )}
            </Row>
          );
        })}
    </>
  );
};

export default PackagingComponent;
