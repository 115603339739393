import { styled } from "styled-components";

export const TabContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  padding: 12px;
  gap: 160px;
  border-bottom: 2px solid #e6e6e6;
  @media (max-width: 1170px) {
    gap: 100px;
  }
  @media (max-width: 870px) {
    margin-top: 50px;
  }
  @media (max-width: 700px) {
    gap: 50px;
  }
  @media (max-width: 500px) {
    gap: 20px;
  }
`;

export const Text = styled.span`
  font-family: "Avenir-Heavy";
  cursor: pointer;
  font-style: normal;

  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.177777px;

  color: #8a4b5e;
  @media (max-width: 1030px) {
    font-size: 16px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;
