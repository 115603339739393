import { createCompanyGiftingDetails } from "./asyncThunks";

export const createCompanyGiftingDetailsBuilder = (builder) => {
  builder
    .addCase(createCompanyGiftingDetails.pending, (state) => {
      state.companyGiftingDetailsData.loading = true;
      state.companyGiftingDetailsData.error = null;
    })
    .addCase(createCompanyGiftingDetails.fulfilled, (state, action) => {
      const items = state.giftCart;
      const recipients = state.recipients;
      const budget = state.budget;
      const occasion = state.giftCard.occasion;
      const message = state.giftCard.description;
      const deliveryLocation = state.packageLocation;
      const deliveryDate = state.deliveryDate;
      state.companyGiftingDetailsData.data = {
        items,
        recipients,
        budget,
        occasion,
        message,
        deliveryLocation,
        deliveryDate,
      };
      state.detailsId = action.payload.data.id;
      state.companyGiftingDetailsData.loading = false;
      state.companyGiftingDetailsData.error = null;
    })
    .addCase(createCompanyGiftingDetails.rejected, (state, action) => {
      state.companyGiftingDetailsData.loading = false;
      state.companyGiftingDetailsData.error = action.error.message;
    });
};
