import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  MainContainer,
  Title,
  Form,
  InputWrapper,
  Input,
  ButtonContainer,
  SubmitButton,
  StyledMailIcon,
  OuterContainer,
  StyledUserIcon,
} from "./DeleteAccountPage.style";
import Header from "../../components/Header/Header";
import { Footer } from "../../components/Footer.styles";
import AreYouSureModal from "../../components/AreYouSureModal/AreYouSureModal";
import deleteService from "../../api/services/deleteAccount";
import PhoneNumberInput from "../../components/PhoneNumberInput/PhoneNumberInput";
import { validatePhoneNumberLength } from "libphonenumber-js";
import openToast from "../../utils/toast";

const DeleteAccountPage = () => {
  const { t } = useTranslation();
  const [mobilePhone, setMobilePhone] = useState("");
  const [username, setUsername] = useState("");
  const navigation = useNavigate();
  const modalRef = useRef();
  const toastId = useRef(null);

  const handleDeleteAccount = async () => {
    try {
      await deleteService.deleteAccount(mobilePhone, username);
      toast.success(t("account-will-be-deleted"));
      navigation("/");
    } catch (err) {
      openToast(toastId, "delete-account", "error", t(err.message));
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleDeleteAccount();
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <Header
        showNav={true}
        showLogin={false}
        showSignUp={false}
        color={true}
        display
      />
      <MainContainer>
        <OuterContainer>
          <div>
            <Title>{t("delete-your-account")}</Title>
            <Form>
              <InputWrapper>
                <StyledMailIcon className="login__icon" />
                <PhoneNumberInput
                  phone={mobilePhone}
                  placeholder={t("mobile")}
                  setPhone={setMobilePhone}
                  containerStyle={{
                    height: 60,
                    color: "rgba(69, 69, 132, 1)",
                    fontFamily: "Avenir",
                    fontSize: 18,
                    fontWeight: 400,
                    letterSpacing: -0.178,
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <StyledUserIcon className="login__icon" />
                <Input
                  type={"text"}
                  placeholder={t("username")}
                  name="username"
                  autoComplete="off"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </InputWrapper>
            </Form>
          </div>
          <ButtonContainer>
            <SubmitButton
              onClick={() => {
                if (!mobilePhone || !username) {
                  openToast(
                    toastId,
                    "delete-account",
                    "error",
                    t("mobile-phone-and-username-are-required")
                  );
                  return;
                }
                if (validatePhoneNumberLength(mobilePhone)) {
                  openToast(
                    toastId,
                    "delete-account",
                    "error",
                    t("invalid-mobile-phone")
                  );
                  return;
                }
                modalRef.current.show();
              }}
            >
              {t("delete-account")}
            </SubmitButton>
          </ButtonContainer>
        </OuterContainer>
      </MainContainer>
      <Footer />
      <AreYouSureModal
        modalRef={modalRef}
        onClick={handleDeleteAccount}
        title={t("are-you-sure-delete-your-account")}
      />
    </>
  );
};

export default DeleteAccountPage;
