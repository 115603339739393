import React, { useEffect } from "react";
import {
  StyledCloseContainer,
  Button,
  Gap,
  StyledText,
  StyledTitle,
} from "./PriceBreakModal.styles";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import Modal from "./Modal2";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const KeepInMindModal = ({ open, close }) => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const handleContinue = () => {
    close();
  };

  const handleKeyPress = (e) => {
    if (open) {
      if (e.key === "Enter") {
        handleContinue();
      }
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("keypress", handleKeyPress);
      return () => {
        document.removeEventListener("keypress", handleKeyPress);
      };
    }
  }, [handleKeyPress, open]);

  return (
    <Modal
      onClose={close}
      open={open}
      width={"877px"}
      height={"fit-content"}
      padding={"50px 60px"}
      unset
      className="modal-login"
    >
      <StyledCloseContainer onClick={close}>
        <CloseIcon fill="white" width={20} height={20} />
      </StyledCloseContainer>
      <Gap>
        <StyledTitle>{t("keep-in-mind")}</StyledTitle>
        <StyledText style={{ maxWidth: 700, alignSelf: "center" }}>
          {t("this-means-that")}
        </StyledText>

        <Button
          onClick={handleContinue}
          style={{
            width: 300,
            marginTop: 20,
            alignSelf: "center",
            height: 60,
            marginBottom: 0,
          }}
        >
          {t("continue")}
        </Button>
      </Gap>
    </Modal>
  );
};

export default KeepInMindModal;
