import { deleteCompanyBusinessUser, getCompanyUsers } from "./asyncThunks";

export const fetchCompanyUsersBuilder = (builder) => {
  builder.addCase(getCompanyUsers.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getCompanyUsers.fulfilled, (state, action) => {
    state.loading = false;
    const companyUsers = action.payload;
    state.users = companyUsers;
  });
  builder.addCase(getCompanyUsers.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  });
};

export const deleteCompanyBusinessUserBuilder = (builder) => {
  builder.addCase(deleteCompanyBusinessUser.pending, (state) => {
    state.loading = true;
    state.error = null;
  });
  builder.addCase(deleteCompanyBusinessUser.fulfilled, (state, action) => {
    state.loading = false;
    const companyUserId = action.payload;
    state.users = state.users.filter((user) => user.id !== companyUserId);
  });
  builder.addCase(deleteCompanyBusinessUser.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  });
};
