import styled from "styled-components";

export const CardProduct = styled.div`
  position: relative;
  gap: 10px;
  outline: 1px solid rgb(1, 1, 1, 0.15);
  width: 215px;
  height: 335px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  cursor: pointer;

  &:active {
    transition: all 0.2s ease-in;
    opacity: 0.8;
    transform: scale(0.98);
  }
`;

export const CardProductName = styled.span`
  font-family: "Avenir-Black";

  font-size: 18px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const ProductNameContainer = styled.div`
  display: flex;
  height: 120px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 15px 15px 20px 15px;
  margin-top: auto;
`;
export const ProductPrice = styled.span`
  font-family: "Avenir-Heavy";
  font-size: 26px;
  letter-spacing: -0.41px;
`;

export const Category = styled.span`
  // background-color: #545484;
  font-family: "Avenir-Medium";
  font-size: 18px;
  letter-spacing: -0.41px;
  color: #fff;
  padding: 5px 15px;
  width: fit-content;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    background-color: #e8dbdf33;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  &:hover {
    opacity: 0.6;
  }
`;

export const GiftCardImgContainer = styled.div`
  height: 192px;
  width: 200px;
  overflow: hidden;
  justify-content: center;
  display: flex;
  align-self: center;
`;
