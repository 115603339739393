import React, { useEffect, useRef, useState } from "react";
import {
  MainContainer,
  ContentContainer,
  ItemContainer,
  TitleContainer,
  Title,
  HeaderWrapper,
} from "./ChooseGiftPage.styles";

import {
  CardProduct,
  CardProductName,
  ProductNameContainerColumn,
} from "../../AdminPortal/MarketplacePage/MarketplacePage.styles";
import { useSelector } from "react-redux";
import { companyGiftingItemsDataPending } from "../../../store/slices/gift/slice";
import { ButtonContinue } from "../../AdminPortal/AccountSettingsPage/AccountSettingsPage.styles";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import ModalSuccess from "../../../components/SuccessModal/SuccessModal";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../../components/Header/Header";
import giftService from "../../../api/services/gift";
import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";
import openToast from "../../../utils/toast";
import giftMobileService from "../../../api/services/giftMobile";
import AppLoader from "../../../components/AppLoader";

const ChooseGiftPage = ({ companyGiftingRecipientId }) => {
  const pending = useSelector(companyGiftingItemsDataPending);
  const [activeIndex, setActiveIndex] = useState(null);
  const [data, setData] = useState([]);
  const { token } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [areYouSureModal, setAreYouSureModal] = useState(false);
  const toastId = useRef(null);
  const [source, setSource] = useState("");
  const { state, company } = useSelector(selectBusinessUserInfo);
  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  useEffect(() => {
    async function getData() {
      try {
        const result = await giftService.getCompanyGiftingItemsWithToken(token);
        setData(result.data);
        setSource(result.source);
      } catch (err) {
        setError(true);
      }
    }
    getData();
  }, [token]);

  const successRef = useRef();
  const navigate = useNavigate();

  const handlePickGift = (item) => {
    setSelectedItem(item);
    setAreYouSureModal(true);
  };

  const handleYes = async () => {
    try {
      setLoading(true);
      if (source !== "mobile") {
        await giftService.createCompanyGiftingRecipientOrderWithToken({
          itemId: selectedItem.item.id,
          token,
          totalPrice: selectedItem.item.price,
        });
      } else {
        await giftMobileService.createGiftingRecipientOrderWithToken({
          itemId: selectedItem.item.id,
          token,
        });
      }
      setLoading(false);

      successRef.current.show();
    } catch (error) {
      setLoading(false);
      openToast(
        toastId,
        "pick-a-gift",
        "error",
        t("error-while-picking-the-gift")
      );
    }
  };

  const ItemCard = ({ item, onClick, isActive }) => {
    return (
      <CardProduct
        onClick={onClick}
        style={{
          cursor: "pointer",
          transition: "all 0.2s ease-out",
          opacity: isActive === null ? 1 : isActive ? 1 : 0.5,
          transform:
            isActive === null ? "none" : isActive ? "scale(1.1)" : "none",
        }}
      >
        <img
          style={{
            borderRadius: 20,
            width: "80%",
            height: 172,
            marginTop: 20,
            alignSelf: "center",
          }}
          src={item?.item.photos[0].url}
          alt="item-img"
        />

        <ProductNameContainerColumn>
          {!isActive ? (
            <>
              <CardProductName>{item?.item.name}</CardProductName>
            </>
          ) : (
            <ButtonContinue onClick={() => handlePickGift(item)}>
              {t("pick-a-gift")}
            </ButtonContinue>
          )}
        </ProductNameContainerColumn>
      </CardProduct>
    );
  };

  return (
    <MainContainer>
      <HeaderWrapper>
        <Header
          showLogin
          showSignUp
          display
          showNav
          color
          showFavorite
          showUser
          hideSearch
          showCart={false}
        />
      </HeaderWrapper>
      <TitleContainer $upgradePackage={showUpgradeBar && diff > 0 && state}>
        {error ? (
          <Title>{t("gift-already-chosen")}</Title>
        ) : (
          <Title>{t("choose-your-gift")}</Title>
        )}
      </TitleContainer>
      <ContentContainer>
        {!pending && !loading ? (
          <ItemContainer>
            {data?.map((item, index) => (
              <ItemCard
                item={item}
                key={index}
                isActive={activeIndex === index}
                onClick={() => setActiveIndex(index)}
                handlePickGift={handlePickGift}
              />
            ))}
          </ItemContainer>
        ) : (
          <AppLoader />
        )}
      </ContentContainer>
      <ModalConfirm
        open={areYouSureModal}
        close={() => setAreYouSureModal(false)}
        onClickNo={() => setAreYouSureModal(false)}
        onClickYes={() => {
          setAreYouSureModal(false);
          handleYes();
        }}
      />
      <ModalSuccess
        modalRef={successRef}
        handleButtonPress={() => {
          successRef.current.hide();
          navigate("/");
        }}
      />
    </MainContainer>
  );
};

export default ChooseGiftPage;
