import React, { useState } from "react";
import {
  ArcStyle,
  HeaderContainer,
  HeaderWrapper,
  LocaleWrapper,
  MainContainer,
  MobileScreenContainer,
  PickGiftHeader,
} from "./PGMPages.styles";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PickYourGiftComponent from "./PickYourGiftComponent";
import ConfirmLocationComponent from "./ConfirmLocationComponent";
import RejectComponent from "./RejectComponent";
import BackToComponent from "./BackToComponent";
import NewLocationComponent from "./NewLocationComponent";
import ThankYouComponent from "./ThankYouComponent";
import LocaleSelection from "../../components/LocaleSelection/LocaleSelection";
import { ReactComponent as LogoOriginal } from "../../assets/icons/logo-original.svg";

const PGMPages = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token } = useParams();

  const [location, setLocation] = useState(null);
  const [tab, setTab] = useState("pick-your-gift");

  return (
    <MainContainer>
      <MobileScreenContainer>
        <PickGiftHeader>
          <HeaderContainer>
            <HeaderWrapper>
              <LogoOriginal />
              <LocaleWrapper>
                <LocaleSelection color textOnly />
              </LocaleWrapper>
            </HeaderWrapper>
          </HeaderContainer>
        </PickGiftHeader>
        <ArcStyle />
        {tab === "pick-your-gift" && (
          <PickYourGiftComponent setTab={setTab} t={t} token={token} />
        )}
        {tab === "confirm-location" && (
          <ConfirmLocationComponent
            setTab={setTab}
            t={t}
            location={location}
            dispatch={dispatch}
            token={token}
          />
        )}
        {tab === "new-location" && (
          <NewLocationComponent
            setTab={setTab}
            t={t}
            location={location}
            setLocation={setLocation}
          />
        )}
        {tab === "reject" && (
          <RejectComponent
            setTab={setTab}
            t={t}
            dispatch={dispatch}
            token={token}
          />
        )}
        {tab === "back-to" && <BackToComponent t={t} />}
        {tab === "thank-you-to-setting" && <ThankYouComponent t={t} />}
      </MobileScreenContainer>
    </MainContainer>
  );
};

export default PGMPages;
