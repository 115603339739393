import React, { useEffect, useRef, useState } from "react";
import {
  MainContainer,
  Column,
  Content,
  Text,
  TitleContainer,
  DetailsData,
  DetailsDataValue,
  DetailsContainer,
  InvoiceDiv,
  Select,
  Option,
  DropDownContainer,
  GalleryRow,
  StyledAttachment,
  Button,
  SelectForbidden,
  ItemDetailsContainer,
  VoucherCodeContainer,
  StyledDetailsDataValue,
  LoaderDiv,
  HeaderWrapper,
  GalleryIcon,
  SmallRow,
  GalleryColumn,
  GalleryText,
  Row,
  ItemImage,
} from "./MarketplaceOrderDetailsPage.styles";
import Sidebar from "../utils/Sidebar";
import ContentHeader from "../utils/ContentHeader";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getOrder,
  updateOrderState,
} from "../../../store/slices/marketplace/asyncThunks";
import { ReactComponent as InvoiceIcon } from "../../../assets/icons/invoice.svg";
import {
  ButtonBackContainer,
  DetailsDataWrapper,
} from "../GiftOrderDetailsPage/GiftOrderDetailsPage.styles";
import { ButtonBack } from "../SendGiftsPage/SendGiftsPage.styles";
import {
  marketplaceOrderLoader,
  ordersData,
  orderDataState,
} from "../../../store/slices/marketplace/marketplaceSlice";
import {
  MAP_LIBRARIES,
  getCurrencySymbol,
  googlePlacesKey,
  marketplaceOrderStates,
  marketplacePaymentStatus,
} from "../../../utils/constants";
import { toast } from "react-toastify";
import { priceFormatter } from "../../../utils/utils";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import MiniLoader from "../../../components/MiniLoader/MiniLoader";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy.svg";
import Header from "../../../components/Header/Header";
import { selectedLocale } from "../../../store/slices/common/slice";
import GalleryModal from "../../../components/Modal/GalleryModal";

const MarketplaceOrderDetailsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const params = useParams();
  const id = params.orderId;
  const order = useSelector(ordersData);
  const orderStateRedux = useSelector(orderDataState);
  const orderLoading = useSelector(marketplaceOrderLoader);
  const businessUser = useSelector((state) => state.businessUser);
  const mapRef = useRef(null);
  const [openGallery, setOpenGallery] = useState(false);
  const [clickedImage, setClickedImage] = useState("");
  const [images, setImages] = useState([]);

  const handleOpenGallery = ({ images, index }) => {
    setClickedImage(index);
    setImages(images);
    setOpenGallery(true);
  };

  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";

  useEffect(() => {
    dispatch(getOrder(id))
      .unwrap()
      .catch((error) => {
        toast.error(t(error.message));
      });
  }, [dispatch, id]);

  if (!order) {
    return <TitleContainer>Loading...</TitleContainer>;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googlePlacesKey,
    libraries: MAP_LIBRARIES,
  });

  const center = {
    lat: order?.location?.lat,
    lng: order?.location?.lng,
  };
  const onLoad = function callback(map) {
    mapRef.current = map;
    map.setCenter(center);
    map.setZoom(14);
  };

  const handleInvoiceMarketplaceClick = () => {
    if (order?.invoiceUrl) {
      window.open(order?.invoiceUrl, "_blank");
    }
  };

  const getItemPhoto = (subItems, marketplaceItem, item) => {
    if (
      item.marketplaceOrderItemPhotos &&
      item.marketplaceOrderItemPhotos.length
    ) {
      return item.marketplaceOrderItemPhotos[0]?.photoUrl;
    }

    if (subItems) {
      for (const i of subItems) {
        if (i.marketplacePhotos.length) {
          return i.marketplacePhotos[0]?.url;
        }
      }
    }

    if (marketplaceItem && marketplaceItem.marketplacePhotos?.length) {
      return marketplaceItem.marketplacePhotos[0].url;
    }
  };

  const handleLogoUrlClick = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  console.log(order);

  return (
    <MainContainer>
      <HeaderWrapper>
        <Header
          showLogin
          showSignUp
          showNav
          color
          showFavorite
          showCart
          showUser
        />
      </HeaderWrapper>
      <Sidebar />

      <Column $ar={ar}>
        <ContentHeader title={t("order-details")} showTitle={true} />
        {!orderLoading ? (
          <>
            <TitleContainer>
              <Text>
                {t("order-#")} {order?.id}
              </Text>
            </TitleContainer>
            {businessUser.role === "superAdmin" ? (
              <>
                <DropDownContainer style={{ marginTop: 18 }}>
                  <DetailsData> {t("order-state")}:</DetailsData>
                  <Select
                    id="userDropdown"
                    onChange={(event) => {
                      const selectedStateId = parseInt(event.target.value);
                      const selectedState = marketplaceOrderStates.find(
                        (state) => state.id === selectedStateId
                      );
                      const selectedStateValue = selectedState?.value;
                      dispatch(
                        updateOrderState({ id, state: selectedStateValue })
                      );
                    }}
                  >
                    {marketplaceOrderStates.map((state) => (
                      <Option
                        key={state.id}
                        value={state.id}
                        selected={orderStateRedux === state.value}
                      >
                        {t(state.value)}
                      </Option>
                    ))}
                  </Select>
                </DropDownContainer>
                <DropDownContainer style={{ marginBottom: 10 }}>
                  <DetailsData>{t("payment-status")}:</DetailsData>
                  {order.paymentStatus === "pending" ? (
                    <Select
                      id="userDropdown"
                      onChange={(event) => {
                        const selectedStatusId = parseInt(event.target.value);
                        const selectedPaymentStatus =
                          marketplacePaymentStatus.find(
                            (status) => status.id === selectedStatusId
                          );
                        const selectedStatusValue =
                          selectedPaymentStatus?.value;
                        dispatch(
                          updateOrderState({
                            id,
                            paymentStatus: selectedStatusValue,
                          })
                        );
                      }}
                    >
                      {marketplacePaymentStatus.map((status) => (
                        <Option
                          key={status.id}
                          value={status.id}
                          selected={order.paymentStatus === status.value}
                        >
                          {t(status.value)}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <SelectForbidden>{t(order.paymentStatus)}</SelectForbidden>
                  )}
                </DropDownContainer>
              </>
            ) : (
              <>
                <DetailsContainer style={{ marginTop: 18 }}>
                  <DetailsData>{t("order-state")}: </DetailsData>
                  <DetailsDataValue>{t(order.state)}</DetailsDataValue>
                </DetailsContainer>
                <DetailsContainer>
                  <DetailsData>{t("payment-status")}: </DetailsData>
                  <DetailsDataValue>{t(order.paymentStatus)}</DetailsDataValue>
                </DetailsContainer>
              </>
            )}
            <Content>
              {order?.deliveryAddress?.floorNumber ? (
                <DetailsContainer>
                  <DetailsData>{t("floor")}: </DetailsData>
                  <DetailsDataValue>
                    {order.deliveryAddress?.floorNumber}
                  </DetailsDataValue>
                </DetailsContainer>
              ) : null}
              {order?.deliveryAddress?.nearestLandmark ? (
                <DetailsContainer>
                  <DetailsData>{t("nearest-landmark")}: </DetailsData>
                  <DetailsDataValue>
                    {order?.deliveryAddress?.nearestLandmark}
                  </DetailsDataValue>
                </DetailsContainer>
              ) : null}
              <DetailsContainer>
                <DetailsData>{t("total-price")}: </DetailsData>
                <DetailsDataValue>
                  {`${priceFormatter(order?.totalPrice)} ${getCurrencySymbol(
                    order?.currency
                  )}`}
                </DetailsDataValue>
              </DetailsContainer>

              {order.notes !== null && (
                <DetailsContainer>
                  <DetailsData>{t("notes")}: </DetailsData>
                  <DetailsDataValue>{order.notes}</DetailsDataValue>
                </DetailsContainer>
              )}
              {order?.location?.name && (
                <>
                  <DetailsContainer>
                    <DetailsData>{t("date-of-delivery")}:</DetailsData>
                    <DetailsDataValue>{order?.dateOfDelivery}</DetailsDataValue>
                  </DetailsContainer>
                  <DetailsContainer>
                    <DetailsData>{t("delivery-location")}: </DetailsData>
                    <DetailsDataWrapper
                      style={{
                        width: "100%",
                        maxWidth: "500px",
                        maxHeight: "380px",
                        flexDirection: "column",
                        display: "flex",
                      }}
                    >
                      <DetailsDataValue
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {t("full-name")}: {order.fullName}
                      </DetailsDataValue>
                      <DetailsDataValue
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {t("phone-number")}: {order.mobilePhone}
                      </DetailsDataValue>
                      <DetailsDataValue
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {t("address")}: {order?.location?.name}
                      </DetailsDataValue>
                      <DetailsDataValue
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {order.addressDetails && (
                          <>
                            {t("address-details")}: {order.addressDetails}
                          </>
                        )}
                      </DetailsDataValue>
                      {isLoaded ? (
                        <GoogleMap
                          mapContainerStyle={{
                            border: "1px solid #d1d1d4",
                            width: "100%",
                            maxWidth: "500px",
                            height: "330px",
                            marginTop: "10px",
                          }}
                          onLoad={(map) => {
                            mapRef.current = map;
                            onLoad(map);
                          }}
                          zoom={14}
                          center={center}
                        >
                          <Marker position={center} />
                        </GoogleMap>
                      ) : (
                        <MiniLoader />
                      )}
                    </DetailsDataWrapper>
                  </DetailsContainer>
                </>
              )}
              <DetailsContainer>
                <DetailsData>{t("invoice")}: </DetailsData>
                <InvoiceDiv>
                  {order.paymentStatus === "paid" && (
                    <Button
                      onClick={() =>
                        navigation("/admin-portal/checkout/final", {
                          state: { order: order },
                        })
                      }
                    >
                      {t("generate-invoice")}
                    </Button>
                  )}
                  {order.invoiceUrl && (
                    <InvoiceIcon
                      className="invoice-icon"
                      onClick={handleInvoiceMarketplaceClick}
                    />
                  )}
                </InvoiceDiv>
              </DetailsContainer>
              {order.marketplaceOrderPackageId &&
                order.marketplaceOrderPackage && (
                  <DetailsContainer
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <DetailsData>{t("package-details")}:</DetailsData>
                    <DetailsContainer
                      style={{
                        borderTop: "5px solid #8A4B5E88",
                      }}
                    >
                      <ItemDetailsContainer>
                        <Row>
                          <DetailsData>{t("name")}: </DetailsData>
                          <DetailsDataValue>
                            {t(`${order.marketplaceOrderPackage?.name}`)}
                          </DetailsDataValue>
                        </Row>
                        <Row>
                          <DetailsData>{t("price")}: </DetailsData>
                          <DetailsDataValue>
                            {order.packagePrice + " SR"}
                          </DetailsDataValue>
                        </Row>
                        {order.packageColor && (
                          <Row>
                            <DetailsData>{t(`color`)}:</DetailsData>
                            <DetailsDataValue>
                              {order.packageColor}
                            </DetailsDataValue>
                          </Row>
                        )}

                        {order.packageLogoUrl && (
                          <Row>
                            <DetailsData>{t("logo")}: </DetailsData>
                            <StyledAttachment
                              className="attachment-icon"
                              onClick={() =>
                                handleLogoUrlClick(order.packageLogoUrl)
                              }
                            />
                          </Row>
                        )}
                      </ItemDetailsContainer>
                      {order.marketplaceOrderPackage &&
                        order.marketplaceOrderPackage.photoUrl && (
                          <ItemImage
                            src={order.marketplaceOrderPackage.photoUrl}
                            alt="img"
                          />
                        )}
                    </DetailsContainer>
                  </DetailsContainer>
                )}
              <DetailsContainer
                style={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <DetailsData>{t("item-details")}:</DetailsData>
                {order?.marketplaceOrderItems.map((item, index) => (
                  <DetailsContainer
                    key={index}
                    style={{
                      borderTop: "5px solid #8A4B5E88",
                    }}
                  >
                    <ItemDetailsContainer>
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <DetailsData>{t("quantity")}: </DetailsData>
                        <DetailsDataValue>{item.quantity}</DetailsDataValue>
                      </div>

                      {!!item.marketplaceSubcategoryItems.length && (
                        <div style={{ flexDirection: "row", display: "flex" }}>
                          <DetailsData>
                            {t(
                              `${item.marketplaceSubcategoryItems[0].marketplaceSubcategory.value}`
                            )}
                            :
                          </DetailsData>
                          <DetailsDataValue>
                            {item.marketplaceSubcategoryItems[0].value}
                          </DetailsDataValue>
                        </div>
                      )}

                      {item.marketplaceItem?.price && (
                        <div style={{ flexDirection: "row", display: "flex" }}>
                          <DetailsData>{t("price")}: </DetailsData>
                          {!item.marketplaceSubcategoryItems[0]?.price ? (
                            <DetailsDataValue>
                              {`${priceFormatter(
                                item.marketplaceItem?.price
                              )} ${getCurrencySymbol(
                                item.marketplaceItem?.currency
                              )}`}
                            </DetailsDataValue>
                          ) : (
                            <DetailsDataValue>
                              {`${priceFormatter(
                                item.marketplaceSubcategoryItems[0]?.price
                              )} ${getCurrencySymbol(
                                item.marketplaceItem?.currency
                              )}`}
                            </DetailsDataValue>
                          )}
                        </div>
                      )}
                      {item.giftCardStatus && (
                        <div style={{ flexDirection: "row", display: "flex" }}>
                          <DetailsData>{t("gift-card-status")}: </DetailsData>
                          <DetailsDataValue>
                            {item.giftCardStatus}
                          </DetailsDataValue>
                        </div>
                      )}

                      {item.pinCode && (
                        <>
                          <DetailsData>{t("pin-code")}: </DetailsData>
                          <DetailsDataValue>{item.pinCode} </DetailsDataValue>
                          <CopyIcon
                            fill="#8A4B5E"
                            width={20}
                            height={20}
                            style={{
                              cursor: "pointer",
                              marginTop: 3,
                              marginLeft: 6,
                            }}
                            onClick={() =>
                              navigator.clipboard.writeText(item.pinCode)
                            }
                          />
                        </>
                      )}
                      {item.validTo && (
                        <div style={{ flexDirection: "row", display: "flex" }}>
                          <DetailsData>{t("valid-to")}: </DetailsData>
                          <DetailsDataValue>{item.validTo}</DetailsDataValue>
                        </div>
                      )}
                      {item.voucherCode && (
                        <>
                          <VoucherCodeContainer>
                            <DetailsData>{t("voucher-code")}: </DetailsData>
                            {item.voucherCode.split(",").map((code, index) => (
                              <div
                                key={index}
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                }}
                              >
                                <StyledDetailsDataValue>
                                  {code.trim()}
                                </StyledDetailsDataValue>
                                <CopyIcon
                                  fill="#8A4B5E"
                                  width={20}
                                  height={20}
                                  style={{
                                    cursor: "pointer",
                                    marginTop: 3,
                                    marginLeft: 6,
                                  }}
                                  onClick={() =>
                                    navigator.clipboard.writeText(code.trim())
                                  }
                                />
                              </div>
                            ))}
                          </VoucherCodeContainer>
                        </>
                      )}
                      {item.logoUrl && (
                        <div style={{ flexDirection: "row", display: "flex" }}>
                          <DetailsData>{t("logo")}: </DetailsData>
                          <StyledAttachment
                            className="attachment-icon"
                            onClick={() => handleLogoUrlClick(item.logoUrl)}
                          />
                        </div>
                      )}
                    </ItemDetailsContainer>
                    <GalleryRow>
                      {item.marketplaceOrderItemPhotos &&
                        item.marketplaceOrderItemPhotos.length > 0 && (
                          <GalleryColumn>
                            <GalleryIcon
                              onClick={() => {
                                if (
                                  item.marketplaceOrderItemPhotos &&
                                  item.marketplaceOrderItemPhotos.length
                                ) {
                                  handleOpenGallery({
                                    images: item.marketplaceOrderItemPhotos,
                                    index: 0,
                                  });
                                }
                              }}
                            />
                            <GalleryText>{t("view-designs")}</GalleryText>
                          </GalleryColumn>
                        )}
                      {!!item.marketplaceSubcategoryItems.length ? (
                        <img
                          src={getItemPhoto(
                            item.marketplaceSubcategoryItems,
                            item.marketplaceItem,
                            item
                          )}
                          className="img"
                          style={{
                            display: "flex",
                            height: 250,
                            maxWidth: 750,
                          }}
                          alt="img"
                        />
                      ) : (
                        <img
                          src={
                            item.marketplaceOrderItemPhotos &&
                            item.marketplaceOrderItemPhotos.length
                              ? item.marketplaceOrderItemPhotos[0].photoUrl
                              : item.marketplaceItem?.marketplacePhotos[0]?.url
                          }
                          className="img"
                          style={{
                            display: "flex",
                            height: 250,
                            maxWidth: 750,
                          }}
                          alt="img"
                        />
                      )}
                    </GalleryRow>
                  </DetailsContainer>
                ))}
              </DetailsContainer>

              <ButtonBackContainer>
                <ButtonBack onClick={() => navigation(-1)}>
                  {t("back")}
                </ButtonBack>
              </ButtonBackContainer>
            </Content>
          </>
        ) : (
          <LoaderDiv>
            <MiniLoader />
          </LoaderDiv>
        )}
      </Column>

      <GalleryModal
        open={openGallery}
        onClose={() => {
          setOpenGallery(false);
        }}
        width={"50%"}
        height={"50%"}
        images={images}
        index={clickedImage}
      />
    </MainContainer>
  );
};
export default MarketplaceOrderDetailsPage;
