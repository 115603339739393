import { createSlice } from "@reduxjs/toolkit";
import {
  editCompanySubscriptionBuilder,
  getAdminSubscriptionsAllBuilder,
  getAdminSubscriptionsBankTransfersBuilder,
  getAdminSubscriptionsBuilder,
  getCompanySubscriptionHistoryBuilder,
  getUserSubscriptionsBuilder,
  reviewBankTransferBuilder,
} from "./builder";

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState: {
    subscriptions: {
      data: {},
      loading: false,
      error: null,
    },
    allSubscriptions: {
      data: {
        companySubscriptions: [],
        maxReached: false,
        total: 0,
        perPage: 10,
      },
      loading: false,
      error: null,
    },
    bankTransfers: {
      data: {
        bankTransfersForReview: [],
        maxReached: false,
        total: 0,
        perPage: 10,
      },
      loading: false,
      error: null,
    },
    reviewBankTransfer: {
      loading: false,
      error: null,
    },

    editCompanySubscription: {
      loading: false,
      error: null,
    },
    getUserSubscriptions: {
      subscriptions: [],
      maxReached: false,
      total: null,
      perPage: null,
      loading: false,
    },
    getCompanySubscriptionHistory: {
      company: null,
      data: [],
      maxReached: false,
      total: null,
      perPage: null,
      loading: false,
    },
  },
  reducers: {},

  extraReducers: (builder) => {
    getAdminSubscriptionsBuilder(builder);
    getAdminSubscriptionsAllBuilder(builder);
    reviewBankTransferBuilder(builder);
    editCompanySubscriptionBuilder(builder);
    getAdminSubscriptionsBankTransfersBuilder(builder);
    getUserSubscriptionsBuilder(builder);
    getCompanySubscriptionHistoryBuilder(builder);
  },
});

// export const {} = subscriptionsSlice.actions;

export const selectedSubscriptions = (state) =>
  state.subscriptions.subscriptions;

export const selectedAllSubscriptionsData = (state) =>
  state.subscriptions.allSubscriptions.data;

export const selectedAllSubscriptionsLoading = (state) =>
  state.subscriptions.allSubscriptions.loading;

export const selectedBankTransfersData = (state) =>
  state.subscriptions.bankTransfers.data;

export const selectedBankTransfersLoading = (state) =>
  state.subscriptions.bankTransfers.loading;

export const selectedBankTransfersReviewData = (state) =>
  state.subscriptions.reviewBankTransfer.data;

export const selectedBankTransfersReviewLoading = (state) =>
  state.subscriptions.reviewBankTransfer.loading;

export const selectedEditCompanySubscriptionData = (state) =>
  state.subscriptions.editCompanySubscription.data;

export const selectedEditCompanySubscriptionLoading = (state) =>
  state.subscriptions.editCompanySubscription.loading;
export const selectedGetUserSubscriptions = (state) =>
  state.subscriptions.getUserSubscriptions;

export const selectGetCompanySubscriptionHistory = (state) =>
  state.subscriptions.getCompanySubscriptionHistory;

export default subscriptionsSlice.reducer;
