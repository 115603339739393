import React, { useRef } from "react";
import {
  MenuContainer,
  ModalHeader,
  Column,
  StyledNavLink,
  Dashboard,
  Marketplace,
  Recipients,
  OrderHistory,
  Wallet,
  FavoriteIcon,
  Coupon,
  Arrow,
  AccountSettings,
  Subscription,
  FavoriteUnSelected,
  CouponUnSelected,
  WalletUnSelected,
  DashboardUnSelected,
  MarketplaceUnSelected,
  SubscriptionUnSelected,
  OrderHistoryUnSelected,
  AccountSettingsUnSelected,
  RecipientsUnSelected,
} from "./SmallScreensSideBarModal.styles";

import Modal from "./Modal3";
import { useTranslation } from "react-i18next";
import UseAnimations from "react-useanimations";
import menu4 from "react-useanimations/lib/menu4";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { countClickAction } from "../../store/slices/common/asyncThunks";
import { ReactComponent as Logout } from "../../assets/icons/logout2.svg";
import {
  selectBusinessUserInfo,
  selectCompanyInfo,
} from "../../store/slices/businessUser/slice";
import { selectUserInfo } from "../../store/slices/authRecipient/slice";
import { logoutRecipient } from "../../store/slices/authRecipient/asyncThunks";
import { logout } from "../../store/slices/businessUser/asyncThunks";
import {
  AdminInfoContainer,
  AdminName,
  AdminAccount,
  NavContainer,
  NavDiv,
  IconContainer,
  ButtonsContainer,
  UpgradeButton,
  LogoutButton,
  StyledBar,
  ProfilePicEmpty,
  LogoGiftIcon,
  ProfilePhoto,
  StyledLogoutButton,
} from "../../screens/AdminPortal/utils/HamburgerSidebar.styles";
import openToast from "../../utils/toast";
import Icon from "../Icon";

const SmallScreensLeftSideBarModal = ({ open, close, ar }) => {
  const userInfo = useSelector(selectUserInfo);
  const { name, companyLogo } = useSelector(selectCompanyInfo);
  const { role, fullName, id } = useSelector(selectBusinessUserInfo);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const toastId = useRef(null);

  const navLinks = [
    {
      to: "/admin-portal/dashboard",
      icon: <Dashboard />,
      iconUnSelected: <DashboardUnSelected />,
      label: "dashboard",
    },
    {
      to: "/admin-portal/marketplace",
      icon: <Marketplace />,
      iconUnSelected: <MarketplaceUnSelected />,
      label: "marketplace",
    },
    {
      to: "/admin-portal/recipients?page=1",
      icon: <Recipients />,
      iconUnSelected: <RecipientsUnSelected />,
      label: "recipients",
    },
    {
      to: "/admin-portal/order-history?marketplacePage=1&giftsPage=1",
      icon: <OrderHistory />,
      iconUnSelected: <OrderHistoryUnSelected />,
      label: "order-history",
    },
    {
      to:
        role === "superAdmin"
          ? "/admin-portal/billing?billingsPage=1&bankTransfersPage=1"
          : "/admin-portal/billing?billingsPage=1",
      icon: <Wallet />,
      iconUnSelected: <WalletUnSelected />,
      label: "wallet",
    },
    {
      to: "/admin-portal/favorite",
      icon: <FavoriteIcon />,
      iconUnSelected: <FavoriteUnSelected />,
      label: "favorites",
    },
    {
      to:
        role === "superAdmin"
          ? "/admin-portal/subscriptions"
          : "/admin-portal/business-subscriptions?page=1",
      icon: <Subscription />,
      iconUnSelected: <SubscriptionUnSelected />,
      label: "subscriptions",
    },
  ];

  const superAdminNavLinks = [
    {
      to: "/admin-portal/coupons",
      icon: <Coupon />,
      iconUnSelected: <CouponUnSelected />,
      label: "coupons",
    },
  ];

  const handleLogout = () => {
    if (id > 0) {
      dispatch(logout())
        .unwrap()
        .then(() => {
          navigation("/login");
        })
        .catch((error) => {
          openToast(toastId, "logout-sidebar-modal", "error", t(error.message));
        });
    } else if (userInfo.id > 0) {
      dispatch(logoutRecipient())
        .unwrap()
        .then(() => {
          navigation("/login/user");
        })
        .catch((error) => {
          openToast(toastId, "logout-sidebar-modal", "error", t(error.message));
        });
    }
  };

  const UserInfo = () => {
    return (
      <AdminInfoContainer>
        {companyLogo ? (
          <ProfilePhoto src={companyLogo} alt="profilepicture" />
        ) : (
          <ProfilePicEmpty>
            <div>
              <LogoGiftIcon />
            </div>
          </ProfilePicEmpty>
        )}
        <AdminName>
          {!userInfo.fullName
            ? name
              ? `${name.length > 30 ? name?.slice(0, 30) + "..." : name}`
              : `${fullName}`
            : name
            ? `${name}`
            : `${userInfo.fullName}`}
        </AdminName>
        {id > 0 && <AdminAccount>{t("admin-account")}</AdminAccount>}
        {userInfo?.id > 0 && <AdminAccount>{t("user-account")}</AdminAccount>}
      </AdminInfoContainer>
    );
  };

  const Buttons = () => {
    return (
      <ButtonsContainer>
        <UpgradeButton onClick={() => navigation("/sign-up/steps/upgrade")}>
          {t("upgrade-your-account")}
          <Arrow $ar={ar} />
        </UpgradeButton>
        <LogoutButton onClick={handleLogout}>
          <div>
            <Icon
              icon={<Logout width={27} height={29} />}
              selectedLocale={ar}
            />
          </div>
          {t("logout")}
        </LogoutButton>
      </ButtonsContainer>
    );
  };

  return (
    <Modal
      padding={20}
      onClose={close}
      open={open}
      left
      borderRadius={ar ? "30px 0px 0px 0px" : "0px 30px 0px 0px"}
      bg={"#8A4B5E"}
      blur={"3.5px"}
      paddingOuter={0}
    >
      <Column>
        <ModalHeader>
          <MenuContainer>
            <UseAnimations
              onClick={() => {
                close();
              }}
              className="pointer"
              animation={menu4}
              reverse
              size={42}
              strokeColor="#8A4B5E"
            />
          </MenuContainer>
        </ModalHeader>

        <StyledBar>
          <UserInfo />
          {id > 0 && (
            <NavDiv>
              <NavContainer>
                {navLinks.map(({ to, icon, iconUnSelected, label }) => (
                  <StyledNavLink
                    key={to}
                    to={to}
                    onClick={() => {
                      if (label === "marketplace") {
                        dispatch(countClickAction("marketplace"));
                      }
                    }}
                    children={({ isActive }) => {
                      return (
                        <>
                          <IconContainer>
                            {isActive ? (
                              <Icon icon={icon} selectedLocale={ar} />
                            ) : (
                              <Icon icon={iconUnSelected} selectedLocale={ar} />
                            )}
                          </IconContainer>
                          {t(`${label}`)}
                        </>
                      );
                    }}
                  />
                ))}
                {role === "superAdmin" &&
                  superAdminNavLinks.map(
                    ({ to, icon, iconUnSelected, label }) => (
                      <StyledNavLink
                        key={to}
                        to={to}
                        children={({ isActive }) => {
                          return (
                            <>
                              <IconContainer>
                                {isActive ? (
                                  <Icon icon={icon} selectedLocale={ar} />
                                ) : (
                                  <Icon
                                    icon={iconUnSelected}
                                    selectedLocale={ar}
                                  />
                                )}
                              </IconContainer>
                              {t(`${label}`)}
                            </>
                          );
                        }}
                      />
                    )
                  )}
                <StyledNavLink
                  to={"/admin-portal/account-settings"}
                  children={({ isActive }) => {
                    return (
                      <>
                        <IconContainer>
                          {isActive ? (
                            <Icon
                              icon={<AccountSettings />}
                              selectedLocale={ar}
                            />
                          ) : (
                            <Icon
                              icon={<AccountSettingsUnSelected />}
                              selectedLocale={ar}
                            />
                          )}
                        </IconContainer>
                        {t("account-settings")}
                      </>
                    );
                  }}
                />

                <Buttons />
              </NavContainer>
            </NavDiv>
          )}
          {!!userInfo?.id && (
            <NavContainer>
              <NavDiv>
                <StyledNavLink
                  to="/user-portal/recipient/pick-a-gift"
                  children={({ isActive }) => {
                    return (
                      <>
                        <IconContainer>
                          {isActive ? (
                            <Icon icon={<Dashboard />} selectedLocale={ar} />
                          ) : (
                            <Icon
                              icon={<DashboardUnSelected />}
                              selectedLocale={ar}
                            />
                          )}
                        </IconContainer>
                        {t("received-gifts")}
                      </>
                    );
                  }}
                />
              </NavDiv>

              <StyledLogoutButton onClick={handleLogout}>
                <div>
                  <Icon
                    icon={<Logout width={27} height={29} />}
                    selectedLocale={ar}
                  />
                </div>
                {t("logout")}
              </StyledLogoutButton>
            </NavContainer>
          )}
        </StyledBar>
      </Column>
    </Modal>
  );
};

export default SmallScreensLeftSideBarModal;
