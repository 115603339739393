import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import {
  Screen,
  ScreenContent,
  MainContainer,
  Title,
  Input,
  Button,
  InputContainer,
  ButtonContainer,
  StyledButton,
  SubTitle,
  Separator,
  ColumnDiv,
  PhoneInputWrapper,
  Inputs,
  RowDiv,
  HeaderWrapper,
  TextArea,
  LoginText,
  LoginTextDiv,
  TitleTotal,
  StyledSeparator,
  PaymentDiv,
} from "./DeliveryDetailsPage.styles";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  MAP_LIBRARIES,
  MAP_ZOOM_LEVEL,
  getCurrencySymbol,
  googlePlacesKey,
} from "../../../utils/constants";
import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import MiniLoader from "../../../components/MiniLoader/MiniLoader";
import PhoneNumberInput from "../../../components/PhoneNumberInput/PhoneNumberInput";
import PaymentsComponent from "../../../components/PaymentsComponent/PaymentsComponent";
import Sidebar from "../utils/Sidebar";
import { useSelector } from "react-redux";
import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer";
import {
  cartItemsData,
  selectCreateOrder,
  setCreateOrderDelivery,
} from "../../../store/slices/marketplace/marketplaceSlice";
import { toast } from "react-toastify";
import { isValidPhoneNumber } from "libphonenumber-js";
import marketplaceService from "../../../api/services/marketplace";
import Loader from "../../../components/Loader/Loader";
import {
  blobUrlToFile,
  isValidEmail,
  isValidPassword,
  priceFormatter,
} from "../../../utils/utils";
import SignUpFormComponent from "../../OnboardingPages/SignupPage/SignUpFormComponent";
import { signUp } from "../../../store/slices/auth/asyncThunks";
import { selectedLocale } from "../../../store/slices/common/slice";
import authService from "../../../api/services/auth";
import { ReactComponent as Loading } from "../../../assets/icons/loader-white.svg";
import openToast from "../../../utils/toast";
import {
  MiniText,
  OrderContentContainer,
  OrderSummaryText,
  PriceCard,
  StyledColumnDiv,
  Total,
  Price,
  RightContainer,
} from "../CartPage/CartPage.styles";

const DeliveryDetailsPage = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const createOrderData = useSelector(selectCreateOrder);
  const [fullName, setFullName] = useState(createOrderData.fullName);
  const [addressDetails, setAddressDetails] = useState(
    createOrderData.addressDetails
  );
  const [mobileNumber, setMobileNumber] = useState(
    createOrderData.mobileNumber
  );
  const [location, setLocation] = useState(createOrderData.deliveryAddress);
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [visibility2, setVisibility2] = useState(false);
  const fullNameSignUp = `${firstName}  ${lastName}`;
  const [tab, setTab] = useState(0);
  const items = useSelector(cartItemsData);
  const searchBoxRef = useRef();
  const [mapZoom, setMapZoom] = useState(MAP_ZOOM_LEVEL);
  const mapRef = useRef();
  const [showPayment, setShowPayment] = useState(false);
  const businessUser = useSelector(selectBusinessUserInfo);
  const [loading, setLoading] = useState(false);
  const selectedLanguage = useSelector(selectedLocale);
  const [validateFormLoading, setValidateFormLoading] = useState(false);
  const allItemsHaveProductIdNotNull = items.every(
    (item) => item.productId !== null
  );
  const toastId = useRef(null);

  useEffect(() => {
    if (items.length === 0) {
      if (businessUser.loggedIn) {
        navigation("/admin-portal/marketplace");
      } else {
        navigation("/marketplace");
      }
    }
  }, [businessUser, items, navigation]);

  useEffect(() => {
    if (!allItemsHaveProductIdNotNull) {
      setTab(0);
    } else if (allItemsHaveProductIdNotNull && businessUser.loggedIn) {
      setTab(2);
    } else if (allItemsHaveProductIdNotNull && !businessUser.loggedIn) {
      setTab(1);
    }
  }, [allItemsHaveProductIdNotNull, businessUser]);

  const handleSignup = (data) => {
    if (validateForm(true)) {
      dispatch(
        signUp({
          email,
          password,
          fullName: fullNameSignUp,
          companyName,
        })
      )
        .unwrap()
        .then((response) => {
          submitMarketplaceOrder(data, response.businessUser.id);
        })
        .catch((error) => {
          openToast(toastId, "sign-up", "error", t(error.message));
        });
    }
  };

  const validateForm = async (disableServerValidation) => {
    try {
      setValidateFormLoading(true);
      if (!companyName) {
        openToast(
          toastId,
          "sign-up-form",
          "error",
          t("company-name-is-required")
        );
        return;
      }

      if (!firstName) {
        openToast(
          toastId,
          "sign-up-form",
          "error",
          t("first-name-is-required")
        );
        return;
      }

      if (!lastName) {
        openToast(toastId, "sign-up-form", "error", t("last-name-is-required"));
        return;
      }
      if (!email) {
        openToast(toastId, "sign-up-form", "error", t("email-is-required"));
        return;
      } else if (!isValidEmail(email)) {
        openToast(toastId, "sign-up-form", "error", t("invalid-email-format"));
        return;
      }

      if (!password) {
        openToast(toastId, "sign-up-form", "error", t("password-is-required"));
        return;
      } else if (!isValidPassword(password)) {
        openToast(toastId, "sign-up-form", "info", t("password-contain"));
        return;
      }

      if (!confirmPassword) {
        openToast(
          toastId,
          "sign-up-form",
          "error",
          t("confirm-password-is-required")
        );
        return;
      } else if (confirmPassword !== password) {
        openToast(toastId, "sign-up-form", "error", t("password-dont-match"));
        return;
      }

      if (!disableServerValidation) {
        await authService.validateSignUpData({
          email: email,
          password: password,
          companyName: companyName,
          fullName: fullNameSignUp,
        });
      }
      setValidateFormLoading(false);
      return true;
    } catch (error) {
      openToast(toastId, "sign-up-form", "error", t(error.message));
      setValidateFormLoading(false);
      return false;
    }
  };

  const subTotal = items.reduce((total, item) => {
    if (item.buyBlank && !item.buyPrinted) {
      return total + (item.samplePrice * item.sampleQuantity || 0);
    } else if (!item.buyBlank && item.buyPrinted) {
      return total + (item.printedPrice * item.printedQuantity || 0);
    } else {
      return total + (item.totalPrice || 0);
    }
  }, 0);

  const totalPrice = useMemo(() => {
    if (createOrderData.discountPercentage) {
      return subTotal - (subTotal * createOrderData.discountPercentage) / 100;
    }

    return subTotal;
  }, [createOrderData.discountPercentage, subTotal]);

  const submitMarketplaceOrder = async (data, newBusinessUserId) => {
    try {
      const formData = new FormData();

      formData.append("totalPrice", totalPrice);
      formData.append("fullPrice", subTotal);
      if (location.lat) {
        formData.append("locationLat", location.lat);
      }
      if (location.lng) {
        formData.append("locationLng", location.lng);
      }
      if (location.address) {
        formData.append("locationName", location.address);
      }
      if (mobileNumber) {
        formData.append("mobilePhone", mobileNumber);
      }
      if (fullName) {
        formData.append("fullName", fullName);
      }
      if (createOrderData.notes) {
        formData.append("notes", createOrderData.notes);
      }
      if (addressDetails) {
        formData.append("addressDetails", addressDetails);
      }
      formData.append("dateOfDelivery", new Date().getTime());
      formData.append("isLoggedIn", businessUser.loggedIn);
      if (newBusinessUserId) {
        formData.append("newUserId", newBusinessUserId);
      }

      if (data.tokenId) {
        formData.append("cardTokenId", data.tokenId);
      }

      if (data.paymentMethodId) {
        formData.append("paymentMethodId", data.paymentMethodId);
      }

      if (createOrderData.discountCode) {
        formData.append("couponCode", createOrderData.discountCode);
      }

      let imageIndex = 0;

      if (data.tokenId || data.paymentMethodId) {
        formData.append("paymentType", "CARD_PAYMENT");
      } else {
        if (data.receipt) {
          formData.append("paymentType", "BANK_TRANSFER");
          formData.append("files", data.receipt);
          imageIndex += 1;
        } else {
          formData.append("paymentType", "BANK_TRANSFER");
          formData.append("createWithoutPayment", "true");
        }
      }

      for (const item of items) {
        if (item.isPackaging) {
          formData.append("packageId", item.id);

          if (item.packageLogo) {
            formData.append(
              "packageLogo",
              await blobUrlToFile(item.packageLogo, item.packageLogoName)
            );
          }
          if (item.packageColor) {
            formData.append("packageColor", item.packageColor);
          }
          if (item.quantity) {
            formData.append("packageQuantity", item.quantity);
          }
        } else {
          let subcategoryItems = [];
          if (item.color.id) {
            subcategoryItems.push(item.color.id);
          }
          if (item.size.id) {
            subcategoryItems.push(item.size.id);
          }
          if (item.giftCard.id) {
            subcategoryItems.push(item.giftCard.id);
            formData.append("giftCardItemPrice", item.giftCard.price);
          }

          let itemObj = {
            id: item.id,
            printedItem: item.buyPrinted,
            sampleItem: item.buyBlank,
            quantity: item.quantity,
            printedQuantity: item.printedQuantity,
            sampleQuantity: item.sampleQuantity,
            subcategoryItems,
          };

          if (item.uploadLogoUrl) {
            formData.append(
              "files",
              await blobUrlToFile(item.uploadLogoUrl, item.uploadLogoName)
            );
            itemObj.logoUrl = imageIndex;
            imageIndex += 1;
          }
          if (item.photosWithLogo && item.photosWithLogo.length) {
            itemObj.photosWithLogo = item.photosWithLogo;
          }

          formData.append("items[]", JSON.stringify(itemObj));
        }
      }

      const result = await marketplaceService.createOrder(formData);
      if (data.receipt) {
        navigation("/checkout/final?bank_transfer=true");
      } else if (data.createOrderWithoutPayment) {
        navigation("/checkout/final?tap_id=" + result.data.tapId);
      } else {
        if (result.data.url) {
          window.open(result.data.url, "_self");
        } else if (result.data.tapId) {
          navigation("/checkout/final?tap_id=" + result.data.tapId);
        } else {
          openToast(
            toastId,
            "delivery-details",
            "error",
            t("error-occurred-while-creating-payment")
          );
          setLoading(false);
        }
      }
    } catch (err) {
      openToast(toastId, "delivery-details", "error", t(err?.message));
      setLoading(false);
      console.log(err);
    }
  };

  const onCompletePayment = async (data) => {
    if (!businessUser.loggedIn) {
      handleSignup(data);
    } else {
      submitMarketplaceOrder(data, null);
    }
  };

  const validation = () => {
    if (!fullName) {
      openToast(
        toastId,
        "delivery-details",
        "error",
        t("full-name-is-required")
      );
      return;
    }

    if (!mobileNumber) {
      openToast(
        toastId,
        "delivery-details",
        "error",
        t("phone-number-is-required")
      );
      return;
    } else if (!isValidPhoneNumber(mobileNumber)) {
      openToast(
        toastId,
        "delivery-details",
        "error",
        t("invalid-phone-number")
      );
      return;
    }

    if (!location.address) {
      openToast(toastId, "delivery-details", "error", t("address-is-required"));
      return;
    }
    setShowPayment(true);
    dispatch(
      setCreateOrderDelivery({
        fullName: fullName,
        mobileNumber: mobileNumber,
        deliveryAddress: location,
        addressDetails: addressDetails,
      })
    );
    if (businessUser.loggedIn) {
      setTab(2);
    } else {
      setTab(1);
    }
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googlePlacesKey,
    libraries: MAP_LIBRARIES,
  });

  const center =
    location?.lat && location?.lng
      ? {
          lat: location.lat,
          lng: location.lng,
        }
      : {
          lat: DEFAULT_LATITUDE,
          lng: DEFAULT_LONGITUDE,
        };

  const handlePlacesChanged = (map) => {
    const places = searchBoxRef.current?.getPlaces();

    if (places && places.length > 0) {
      const place = places[0];
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const address = place.formatted_address;
      setLocation({ lat, lng, address });

      if (mapRef.current) {
        mapRef.current.setCenter({ lat, lng });
        setMapZoom(14);
      }
    }
  };

  const handleMarkerDragEnd = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results?.length > 0) {
        const address = results[0].formatted_address;
        setLocation({ lat, lng, address });
      }
    });
  };

  const handleMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results?.length > 0) {
        const address = results[0].formatted_address;
        setLocation({ lat, lng, address });
      }
    });
  };

  const onLoad = useCallback(
    function callback(map) {
      mapRef.current = map;
      map.setCenter(center);
      map.setZoom(MAP_ZOOM_LEVEL);
    },
    [center]
  );

  const showUpgradeBar = businessUser.company.packageType === "Free";
  let diff =
    new Date(businessUser.company?.packageExpiration).getTime() -
    new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  const upgradePackage = showUpgradeBar && diff > 0 && businessUser.state;
  const ar = selectedLanguage === "ar";

  return (
    <>
      {loading && <Loader disableScroll></Loader>}
      <HeaderWrapper>
        <Header
          showLogin
          showSignUp
          showNav
          color
          showFavorite
          showCart
          showUser
          display
        />
      </HeaderWrapper>
      <MainContainer $height={!showPayment}>
        {businessUser.loggedIn && <Sidebar />}
        <Screen
          $loggedIn={businessUser.loggedIn}
          $ar={ar}
          $upgradePackage={upgradePackage}
        >
          <ScreenContent>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                width: "100%",
              }}
            >
              <Title>
                {tab === 0 && t("delivery")}
                {tab === 1 && t("sign-up")}
                {tab === 2 && t("checkout")}
              </Title>
            </div>

            {tab === 0 && (
              <RowDiv>
                <ColumnDiv>
                  <SubTitle>{t("contact-details")}</SubTitle>
                  <Inputs>
                    <InputContainer>
                      <Input
                        type="text"
                        placeholder={t("full-name")}
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </InputContainer>
                    <InputContainer>
                      <PhoneInputWrapper>
                        <PhoneNumberInput
                          phone={mobileNumber}
                          placeholder={t("mobile")}
                          setPhone={setMobileNumber}
                        />
                      </PhoneInputWrapper>
                    </InputContainer>
                  </Inputs>
                  <div
                    style={{
                      paddingTop: 50,
                      gap: 20,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <SubTitle>{t("address-details")}</SubTitle>
                    <Inputs>
                      <InputContainer>
                        <TextArea
                          placeholder={t("building-number-nearby-landmark")}
                          value={addressDetails}
                          onChange={(e) => setAddressDetails(e.target.value)}
                        />
                      </InputContainer>
                    </Inputs>
                  </div>
                </ColumnDiv>
                <Separator />
                <ColumnDiv>
                  <SubTitle>{t("delivery-location")}</SubTitle>

                  {isLoaded ? (
                    <>
                      <StandaloneSearchBox
                        onLoad={(ref) => {
                          searchBoxRef.current = ref;
                        }}
                        onPlacesChanged={handlePlacesChanged}
                      >
                        <Input
                          style={{ marginBottom: 8 }}
                          type="text"
                          placeholder={t("search-for-location")}
                          value={location?.address}
                          onChange={(e) => {
                            setLocation((prevLocation) => ({
                              ...prevLocation,
                              address: e.target.value,
                            }));
                          }}
                        />
                      </StandaloneSearchBox>
                      <GoogleMap
                        mapContainerClassName="map"
                        mapContainerStyle={{
                          border: "1px solid white",
                          width: "100%",
                          maxWidth: 800,
                          height: 400,
                          borderRadius: 15,
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                        }}
                        onLoad={(map) => {
                          mapRef.current = map;
                          onLoad(map);
                        }}
                        zoom={mapZoom}
                        onClick={handleMapClick}
                      >
                        <Marker
                          position={
                            location?.lat && location?.lng
                              ? {
                                  lat: location.lat,
                                  lng: location.lng,
                                }
                              : {
                                  lat: DEFAULT_LATITUDE,
                                  lng: DEFAULT_LONGITUDE,
                                }
                          }
                          draggable
                          onDragEnd={handleMarkerDragEnd}
                        />
                      </GoogleMap>
                    </>
                  ) : (
                    <div style={{ marginTop: 50 }}>
                      <MiniLoader />
                    </div>
                  )}
                </ColumnDiv>
              </RowDiv>
            )}

            {tab === 2 && (
              <PaymentDiv>
                <PaymentsComponent
                  showTotal
                  onBack={() => {
                    if (
                      businessUser.loggedIn &&
                      !allItemsHaveProductIdNotNull
                    ) {
                      setTab(0);
                    } else if (
                      !businessUser.loggedIn &&
                      !allItemsHaveProductIdNotNull
                    ) {
                      setTab(1);
                    } else if (
                      !businessUser.loggedIn &&
                      allItemsHaveProductIdNotNull
                    ) {
                      setTab(1);
                    } else if (
                      businessUser.loggedIn &&
                      allItemsHaveProductIdNotNull
                    ) {
                      navigation(-1);
                    }
                  }}
                  priceToPay={totalPrice}
                  setLoading={setLoading}
                  onComplete={onCompletePayment}
                  createOrderButton
                  size={"80%"}
                />

                <StyledColumnDiv>
                  <OrderSummaryText>{t("order-summary")}</OrderSummaryText>
                  <RightContainer>
                    <PriceCard style={{ background: "#fff" }}>
                      <OrderContentContainer>
                        <TitleTotal>{t("number-of-items")}: </TitleTotal>
                        <TitleTotal>{items.length} </TitleTotal>
                      </OrderContentContainer>
                      <StyledSeparator />
                      <div>
                        <OrderContentContainer>
                          <Total>{t("total")}: </Total>
                          <Price>{totalPrice?.toFixed(2)} SR</Price>
                        </OrderContentContainer>
                        <MiniText>{t("inclusive-of-vat")}</MiniText>
                      </div>
                    </PriceCard>
                  </RightContainer>
                </StyledColumnDiv>
              </PaymentDiv>
            )}

            {tab === 1 && (
              <div
                style={{
                  marginTop: -60,
                }}
              >
                {selectedLanguage === "en" ? (
                  <LoginTextDiv
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <LoginText style={{}}>
                      {t("already-have-an-account")}
                    </LoginText>
                    <Link to="/login" className="signup">
                      {t("login")}
                    </Link>
                  </LoginTextDiv>
                ) : (
                  <LoginTextDiv>
                    <Link to="/login" className="signup">
                      {t("login")}
                    </Link>
                    <LoginText>{t("already-have-an-account")}</LoginText>
                  </LoginTextDiv>
                )}

                <SignUpFormComponent
                  companyName={companyName}
                  setCompanyName={setCompanyName}
                  setFirstName={setFirstName}
                  firstName={firstName}
                  lastName={lastName}
                  setLastName={setLastName}
                  email={email}
                  setEmail={setEmail}
                  visibility={visibility}
                  visibility2={visibility2}
                  confirmPassword={confirmPassword}
                  password={password}
                  setPassword={setPassword}
                  setConfirmPassword={setConfirmPassword}
                  setVisibility={setVisibility}
                  setVisibility2={setVisibility2}
                />
              </div>
            )}
            {tab === 0 && (
              <ButtonContainer>
                <StyledButton
                  onClick={() => {
                    if (tab === 0) {
                      navigation(-1);
                    } else if (tab === 1) {
                      setTab(0);
                    } else if (tab === 2) {
                      setTab(0);
                    }
                  }}
                >
                  {t("back")}
                </StyledButton>
                <Button
                  onClick={() => {
                    if (tab === 0) {
                      validation();
                    }
                  }}
                >
                  {t("complete-payment")}
                </Button>
              </ButtonContainer>
            )}
            {tab === 1 && (
              <ButtonContainer>
                <StyledButton
                  onClick={() => {
                    if (
                      !businessUser.loggedIn &&
                      allItemsHaveProductIdNotNull
                    ) {
                      navigation(-1);
                    } else if (
                      !businessUser.loggedIn &&
                      !allItemsHaveProductIdNotNull
                    ) {
                      setTab(0);
                    }
                  }}
                >
                  {t("back")}
                </StyledButton>
                <Button
                  onClick={() => {
                    validateForm().then((isValid) => {
                      if (isValid === true) {
                        setTab(2);
                      }
                    });
                  }}
                >
                  {!validateFormLoading ? (
                    t("submit")
                  ) : (
                    <Loading height={24} width={54} />
                  )}
                </Button>
              </ButtonContainer>
            )}
          </ScreenContent>
        </Screen>
      </MainContainer>
      {!businessUser.loggedIn && <Footer />}
    </>
  );
};

export default DeliveryDetailsPage;
