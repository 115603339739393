import styled from "styled-components";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
`;
export const Text = styled.span`
  font-size: 14px;
  font-family: "Avenir-Medium";
  color: rgba(0, 0, 0, 0.3);
`;
export const Resend = styled.span`
  font-size: 14px;
  font-family: "Avenir-Heavy";
  text-decoration: underline;
  color: #8a4b5e;
  cursor: pointer;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const Button = styled.button`
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 25px;
  width: 90%;
  outline: none;
  border: 1px solid #8a4b5e;
  background-color: ${(props) => (props.$disabled ? "white" : "#8A4B5E")};
  font-family: "Avenir-Medium";
  color: ${(props) => (props.$disabled ? "#8A4B5E" : "white")};
  font-size: 18px;
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;
`;
export const Title = styled.span`
  color: #000;
  text-align: center;
  font-family: "Avenir-Black";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07px;
`;
export const StyledButton = styled.button`
  width: 50%;
  height: 40px;
  border-top: 1px solid #d1d2d4;
  border-left: none;
  border-right: 0.5px solid #d1d2d4;
  background-color: white;
  cursor: pointer;
  border-bottom-left-radius: 5px;
  border-bottom: 1px solid #d1d2d4;
  color: #017afe;
  text-align: center;
  font-family: "Avenir-Black";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07px;
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 20%;
  width: 100%;
  align-items: center;
`;

export const StyledAHref = styled.a`
  width: 50%;
  height: 40px;
  border-top: 1px solid #d1d2d4;
  background-color: white;
  cursor: pointer;
  border-bottom: 1px solid #d1d2d4;
  color: #017afe;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-decoration: unset;

  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 5px;
  border-left: 0.5px solid #d1d2d4;
  border-right: 1px solid #d1d2d4;
  font-family: "Avenir-Heavy";
`;
