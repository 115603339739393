import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Screen,
  ScreenContent,
  MainContainer,
  Title,
  Success,
  Button,
  StyledTitle,
  Content,
  Text,
  ButtonContainer,
  Link,
  ErrorDiv,
  HeaderWrapper,
} from "./WalletCheckoutPage.styles";
import Loader from "../../../components/Loader/Loader";
import SuccessImg from "../../../assets/images/success.png";
import FailedImg from "../../../assets/images/failed.png";

import Sidebar from "../utils/Sidebar";
import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";
import { toast } from "react-toastify";
import billingService from "../../../api/services/billing";
import Header from "../../../components/Header/Header";
import { selectSendGifts } from "../../../store/slices/sendGifts/slice";
import { fetchBusinessUserInfo } from "../../../store/slices/businessUser/asyncThunks";
import { useDispatch } from "react-redux";

const WalletCheckoutFinalPage = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const businessUser = useSelector(selectBusinessUserInfo);
  const sendGiftsData = useSelector(selectSendGifts);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const paymentChargeId = searchParams.get("tap_id");
  const bankTransfer = searchParams.get("bank_transfer");

  const sendGiftsSessionData = JSON.parse(sessionStorage.getItem("sendGifts"));

  useEffect(() => {
    if (paymentChargeId) {
      setLoading(true);
      billingService
        .validatePayment({ paymentChargeId })
        .then(() => {
          setLoading(false);
          setError(false);
        })
        .catch((err) => {
          toast.error(t("Payment validation failed"));
          setError(true);
          setLoading(false);
        });
    }
  }, []);
  return (
    <>
      {loading && <Loader disableScroll></Loader>}
      <HeaderWrapper>
        <Header showLogin showNav color showUser showCart showFavorite />
      </HeaderWrapper>
      <MainContainer>
        <Sidebar />

        <Screen $loggedIn>
          <ScreenContent>
            <StyledTitle>{t("checkout")}</StyledTitle>
            <Content>
              <>
                {error ? (
                  <Success src={FailedImg} alt="success-img" />
                ) : (
                  <Success src={SuccessImg} alt="success-img" />
                )}
                {error ? (
                  <ErrorDiv>
                    <Title>{t("payment-failed")}</Title>
                    <Text>
                      {t("contact-us-at")}{" "}
                      <Link href={"mailto:hello@ygii.app"}>hello@ygii.app</Link>
                    </Text>
                  </ErrorDiv>
                ) : (
                  <>
                    {bankTransfer ? (
                      <Title>{t("our-admin-will-check-your-invoice")}</Title>
                    ) : (
                      <>
                        {!order ? (
                          <Title>{t("payment-completed")}</Title>
                        ) : null}
                      </>
                    )}
                  </>
                )}
              </>
            </Content>

            <ButtonContainer>
              {sendGiftsData?.gift?.length || sendGiftsSessionData ? (
                <Button
                  onClick={() => {
                    navigation("/admin-portal/send-gifts/5");
                    dispatch(fetchBusinessUserInfo());
                  }}
                >
                  {t("confirm")}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    if (businessUser.role === "superAdmin") {
                      navigation(
                        "/admin-portal/billing?billingsPage=1&bankTransfersPage=1"
                      );
                    } else {
                      navigation("/admin-portal/billing?billingsPage=1");
                    }
                  }}
                >
                  {t("go-to-wallet")}
                </Button>
              )}
            </ButtonContainer>
          </ScreenContent>
        </Screen>
      </MainContainer>
    </>
  );
};

export default WalletCheckoutFinalPage;
