import axiosApiClient from "..";
import { handleError } from "../../utils/errors";

const BASE_URL = "business/subscription";

class SubscriptionService {
  assignPlan = async ({
    planId,
    maxNumberOfUsers,
    cardTokenId,
    planPeriod,
    couponCode,
    paymentMethodId,
  }) => {
    try {
      const body = {
        planId,
        maxNumberOfUsers,
        cardTokenId,
        planPeriod,
      };
      if (cardTokenId) {
        body.cardTokenId = cardTokenId;
      }
      if (couponCode) {
        body.couponCode = couponCode;
      }
      if (paymentMethodId) {
        body.paymentMethodId = paymentMethodId;
      }
      if (maxNumberOfUsers) {
        body.maxNumberOfUsers = maxNumberOfUsers;
      }
      const response = await axiosApiClient.post(BASE_URL + "/assign", body);
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while choosing a plan");
    }
  };

  assignPlanWithoutPayment = async ({
    planId,
    maxNumberOfUsers,
    planPeriod,
    receipt,
    couponCode,
  }) => {
    try {
      let formData = new FormData();

      formData.append("files", receipt);
      formData.append("planId", planId);
      if (maxNumberOfUsers) {
        formData.append("maxNumberOfUsers", maxNumberOfUsers);
      }
      formData.append("planPeriod", planPeriod);

      if (couponCode) {
        formData.append("couponCode", couponCode);
      }

      const response = await axiosApiClient.post(
        BASE_URL + "/assign-receipt",
        formData
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while choosing a plan");
    }
  };

  generateInvoice = async ({
    paymentChargeId,
    companyName,
    companyVat,
    companyCountry,
    companyAddress,
    companyPostalCode,
  }) => {
    try {
      const body = {
        paymentChargeId,
        companyName,
        companyVat,
        companyCountry,
        companyAddress,
        companyPostalCode,
      };
      const response = await axiosApiClient.post(
        BASE_URL + "/generate-invoice",
        body
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while choosing a plan");
    }
  };

  validatePayment = async ({ paymentChargeId }) => {
    try {
      const body = {
        paymentChargeId,
      };
      const response = await axiosApiClient.post(
        BASE_URL + "/validate-payment",
        body
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while choosing a plan");
    }
  };

  getAdminSubscriptions = async () => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/admin-subscriptions`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return {
        data,
      };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching admin subscriptions."
      );
    }
  };

  getAdminSubscriptionsAll = async ({ page, search, expired }) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/admin-subscriptions-all?page=${page}&search=${search}&expired=${expired}`
      );
      if (response.status !== 200) {
        throw new Error();
      }

      const { companySubscriptions, maxReached, total, perPage } =
        response.data.data;
      return {
        companySubscriptions,
        maxReached,
        total,
        perPage,
      };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while fetching all subscriptions."
      );
    }
  };

  getAdminSubscriptionsBankTransfers = async ({ page }) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/admin-subscriptions-bank-transfer?page=${page}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { bankTransfersForReview, maxReached, total, perPage } =
        response.data.data;
      return {
        bankTransfersForReview,
        maxReached,
        total,
        perPage,
      };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching bank transfers.");
    }
  };

  reviewBankTransfer = async ({ subscriptionsId, action }) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/review-bank-transfer/${subscriptionsId}`,
        { action }
      );
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data.data;
    } catch (err) {
      throw handleError(err, "Error occurred while review bank transfer.");
    }
  };

  editCompanySubscription = async ({
    companyId,
    packageExpiration,
    packageType,
    maxNumberOfUsers,
  }) => {
    const newData = {
      packageExpiration,
      packageType,
      maxNumberOfUsers,
    };
    try {
      const response = await axiosApiClient.put(
        `${BASE_URL}/company/${companyId}`,
        newData
      );
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while edit company subscription.");
    }
  };

  getUserSubscriptions = async (page) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/?page=${page}`);
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while getting user subscriptions."
      );
    }
  };

  disableAutomaticSubscriptionRenewal = async () => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/disable-subscription`
      );
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data.message;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while disabling automatic subscription renewal."
      );
    }
  };

  generateInvoiceBySubscriptionId = async ({
    subscriptionId,
    companyName,
    companyAddress,
    companyCountry,
    companyVat,
    companyPostalCode,
  }) => {
    const body = {
      companyName,
      companyAddress,
      companyCountry,
      companyVat,
      companyPostalCode,
    };
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/generate-invoice/${subscriptionId}`,
        body
      );
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while generating subscription invoice ."
      );
    }
  };

  getCompanySubscriptionHistory = async ({ companyId, page }) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/admin/${companyId}?page=${page}`
      );
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while getting company subscription history."
      );
    }
  };
}

const subscriptionService = new SubscriptionService();

export default subscriptionService;
