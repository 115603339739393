import { createSlice } from "@reduxjs/toolkit";
import {
  getCouponsBuilder,
  getCouponsDashboardBuilder,
  createCouponBuilder,
  removeCouponBuilder,
  editCouponBuilder,
  getCouponByIdBuilder,
} from "./builder";

const couponSlice = createSlice({
  name: "coupon",
  initialState: {
    coupons: {
      data: [],
      total: "",
      maxReached: "",
      perPage: "",
      loading: true,
      error: null,
    },
    couponsDashboard: {
      data: {},
      loading: true,
      error: null,
    },
    couponData: {
      name: "",
      expirationDate: 0,
      value: 5,
      products: [],
      type: null,
      status: null,
      maxUsage: 2,
      onetime: true,
      frequency: "SINGLE",
    },
    getCouponByIdLoading: true,
    createCouponLoading: false,
    editCouponLoading: false,
    removeCouponLoading: false,
    removeCouponError: null,
  },
  reducers: {
    setCouponName: (state, action) => {
      state.couponData.name = action.payload;
    },

    setValue: (state, action) => {
      state.couponData.value = action.payload;
    },

    setType: (state, action) => {
      state.couponData.type = action.payload;
    },

    setProduct: (state, action) => {
      state.couponData.products = action.payload;
    },

    setExpirationDate: (state, action) => {
      state.couponData.expirationDate = action.payload;
    },

    setStatus: (state, action) => {
      state.couponData.status = action.payload;
    },

    setMaxUsage: (state, action) => {
      state.couponData.maxUsage = action.payload;
    },
    setOnetime: (state, action) => {
      state.couponData.onetime = action.payload;
      if (action.payload) {
        state.couponData.maxUsage = 1;
      }
    },
    resetData: (state) => {
      state.couponData.id = null;
      state.couponData.maxUsage = 2;
      state.couponData.value = 5;
      state.couponData.name = "";
      state.couponData.type = null;
      state.couponData.products = [];
      state.couponData.expirationDate = null;
      state.couponData.status = null;
      state.couponData.onetime = true;
      state.couponData.frequency = "SINGLE";
    },
    setFrequency: (state, action) => {
      state.couponData.frequency = action.payload;
    },
  },

  extraReducers: (builder) => {
    getCouponsDashboardBuilder(builder);
    getCouponsBuilder(builder);
    removeCouponBuilder(builder);
    createCouponBuilder(builder);
    editCouponBuilder(builder);
    getCouponByIdBuilder(builder);
  },
});

export const {
  setCouponName,
  setValue,
  setType,
  resetEditData,
  setMaxUsage,
  setStatus,
  setOnetime,
  resetData,
  setProduct,
  editCouponName,
  setExpirationDate,
  setFrequency,
} = couponSlice.actions;

export const selectedCouponsDashboard = (state) =>
  state.coupon.couponsDashboard;
export const selectedCoupons = (state) => state.coupon.coupons;
export const selectedRemoveCouponLoading = (state) =>
  state.coupon.removeCouponLoading;
export const selectedEditCouponLoading = (state) =>
  state.coupon.editCouponLoading;
export const selectedCreateCouponLoading = (state) =>
  state.coupon.createCouponLoading;
export const selectedGetCouponByIdLoading = (state) =>
  state.coupon.getCouponByIdLoading;
export const selectedNewCoupon = (state) => state.coupon.couponData;

export default couponSlice.reducer;
