import { createSlice } from "@reduxjs/toolkit";
import {
  signUpBuilder,
  loginBuilder,
  verifyEmailBuilder,
  validateCodeBuilder,
  verifyEmployeeEmailBuilder,
  signUpEmployeeBuilder,
  sendCodeForNewPasswordBuilder,
  resetPasswordBuilder,
  refreshBuilder,
  sendVerificationEmailAgainBuilder,
} from "./builder";

const initialState = {
  loggedIn: null,
  loginPending: false,
  email: "",
  code: "",
  companyId: null,
  error: null,
  verifyEmailPending: false,
  createAccountPending: false,
  validateCodePending: false,
  sendingCodePending: false,
  sendingResetEmailPending: false,
  resettingPasswordPending: false,
  token: null,
  sendVerificationEmailAgainPending: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedIn: (state, { payload }) => {
      const { loggedIn } = payload;
      state.loggedIn = loggedIn;
    },
    setEmailInput: (state, { payload }) => {
      state.email = payload;
    },
    setCodeInput: (state, { payload }) => {
      state.code = payload;
    },
    setCompanyId: (state, { payload }) => {
      state.companyId = payload;
    },
    setAccessToken: (state, { payload }) => {
      const { accessToken } = payload;
      state.token = accessToken;
    },
  },
  extraReducers: (builder) => {
    signUpBuilder(builder);
    verifyEmailBuilder(builder);
    loginBuilder(builder);
    validateCodeBuilder(builder);
    verifyEmployeeEmailBuilder(builder);
    signUpEmployeeBuilder(builder);
    sendCodeForNewPasswordBuilder(builder);
    resetPasswordBuilder(builder);
    refreshBuilder(builder);
    sendVerificationEmailAgainBuilder(builder);
    builder.addCase("logout", () => ({ ...initialState, loggedIn: false }));
  },
});

export const {
  setLoggedIn,
  setEmailInput,
  setCodeInput,
  setNewPassword,
  setAccessToken,
} = authSlice.actions;

export const selectLoggedIn = (state) => state.register.loggedIn;
export const selectEmailInput = (state) => state.register.email;
export const selectCodeInput = (state) => state.register.code;
export const selectCreateAccountPending = (state) =>
  state.register.createAccountPending;
export const selectVerifyEmailPending = (state) =>
  state.register.verifyEmailPending;
export const selectLoginPending = (state) => state.register.loginPending;
export const selectValidatePending = (state) =>
  state.register.validateCodePending;
export const selectResetPasswordPending = (state) =>
  state.register.resettingPasswordPending;
export const selectSendingResetPending = (state) =>
  state.register.sendingResetEmailPending;
export const selectBusinessUserIdAuth = (state) =>
  state.register.businessUserId;
export const selectSendVerificationEmailAgainPending = (state) =>
  state.register.sendVerificationEmailAgainPending;

export default authSlice.reducer;
