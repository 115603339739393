import { createSlice } from "@reduxjs/toolkit";
import {
  giftItemsBuilder,
  getCategoriesBuilder,
  getSectionsBuilder,
  getCategoriesWithSixteenOrMoreItemsBuilder,
  getExploreItemsBySectionBuilder,
  getItemsByCategoryBuilder,
  getItemByIdBuilder,
  getItemsBySectionBuilder,
  getAllCompanyGiftingBuilder,
  getCompanyGiftingByIdBuilder,
  rejectCompanyGiftingRecipientOrderBuilder,
  acceptCompanyGiftingRecipientOrderBuilder,
  fetchCompanyGettingItemsBuilder,
  fetchCompanyGettingItemsForMeBuilder,
  getUserByPhoneBuilder,
  createCompanyGiftingRecipientOrderBuilder,
  getAllCompanyGiftingAdminBuilder,
  updateRecipientOrderStateBuilder,
  getAdminDashboardDataBuilder,
  findRecipientsWishlistsBuilder,
  updateCompanyGiftingDetailsStatusBuilder,
  rejectCompanyGiftingTokenBuilder,
  setDeliveryAddressCompanyGiftingTokenBuilder,
  getDeliveryAddressCompanyGiftingTokenBuilder,
} from "./builder";

const giftItemsSlice = createSlice({
  name: "giftItems",
  initialState: {
    companyGiftingSetDeliveryAddressPending: false,
    rejectCompanyGiftingTokenPending: false,
    getDeliveryAddressCompanyGiftingTokenPending: false,
    deliveryAddresses: [],
    exploreItemsInfo: {
      sectionData: {
        1: {
          id: 1,
          name: "Explore the most popular gifts",
          data: [],
          loading: false,
          maxReached: false,
        },
        2: {
          id: 2,
          name: "Hot right now🔥",
          data: [],
          loading: false,
          maxReached: false,
        },
        3: {
          id: 3,
          name: "New on Ygii💡",
          data: [],
          loading: false,
          maxReached: false,
        },
        4: {
          id: 4,
          name: "Browse more...",
          data: [],
          loading: false,
          maxReached: false,
        },
      },
      maxReached: false,
      error: null,
      loading: true,
    },
    adminDashboardData: {
      data: {
        clickCounts: [
          { key: "giftingAsAService", value: 0 },
          { key: "marketplace", value: 0 },
          { key: "demoRequest", value: 0 },
          { key: "androidDownload", value: 0 },
          { key: "iosDownload", value: 0 },
          { key: "startGifting", value: 0 },
        ],
      },
      loading: false,
      error: null,
    },
    categoryItemsData: {
      name: "",
      data: [],
      maxReached: false,
      loading: true,
      error: null,
    },
    sectionItemsData: {
      name: "",
      data: [],
      maxReached: false,
      loading: true,
      error: null,
    },
    selectedItem: {
      data: {},
      loading: true,
      error: null,
      approveOrderPending: false,
      approveOrderError: null,
      rejectOrderPending: false,
      rejectOrderError: null,
    },
    companyGiftById: {
      data: {},
      loading: true,
      error: null,
    },
    items: [],
    sections: [],
    categories: { data: {}, loading: true, error: null },
    getExploreCategoryItems: [],
    companyGiftingData: {
      data: [],
      maxReached: "",
      perPage: "",
      total: "",
      loading: true,
      error: null,
    },
    companyGiftingItems: {
      data: [],
      companyGiftingItemsPending: false,
      companyGiftingItemsError: null,
      giftingItemsForMeData: {
        data: [],
        maxReached: "",
        perPage: "",
        total: "",
        createCompanyGiftingRecipientOrderPending: false,
        createCompanyGiftingRecipientOrderError: null,
        giftingItemsForMeDataPending: false,
        giftingItemsForMeDataError: null,
      },
    },
    updateRecipientOrderState: {
      loading: true,
      error: null,
    },
    fetchedUserByPhone: {
      fetchedUsers: [],
      error: null,
      loading: false,
    },
    companyGiftingAdmin: {
      data: [],
      maxReached: "",
      perPage: "",
      total: "",
      loading: false,
      error: null,
    },
    findRecipientsWishlists: {
      loading: false,
      wishlists: [
        {
          id: null,
          fullName: "",
          items: [],
        },
      ],
    },
    updateGiftingDetailsStatus: {
      loading: false,
    },
    maxReached: false,
    loading: true,
    error: null,
  },

  extraReducers: (builder) => {
    giftItemsBuilder(builder);
    getCategoriesBuilder(builder);
    getExploreItemsBySectionBuilder(builder);
    getSectionsBuilder(builder);
    getCategoriesWithSixteenOrMoreItemsBuilder(builder);
    getItemsByCategoryBuilder(builder);
    getItemByIdBuilder(builder);
    getAdminDashboardDataBuilder(builder);
    getItemsBySectionBuilder(builder);
    getAllCompanyGiftingBuilder(builder);
    getCompanyGiftingByIdBuilder(builder);
    rejectCompanyGiftingRecipientOrderBuilder(builder);
    acceptCompanyGiftingRecipientOrderBuilder(builder);
    fetchCompanyGettingItemsBuilder(builder);
    fetchCompanyGettingItemsForMeBuilder(builder);
    getUserByPhoneBuilder(builder);
    createCompanyGiftingRecipientOrderBuilder(builder);
    getAllCompanyGiftingAdminBuilder(builder);
    updateRecipientOrderStateBuilder(builder);
    findRecipientsWishlistsBuilder(builder);
    updateCompanyGiftingDetailsStatusBuilder(builder);
    rejectCompanyGiftingTokenBuilder(builder);
    setDeliveryAddressCompanyGiftingTokenBuilder(builder);
    getDeliveryAddressCompanyGiftingTokenBuilder(builder);
  },
});

export const selectedSectionTitle = (state) =>
  state.giftItems.sectionItemsData.name;

export const selectAdminDashboard = (state) =>
  state.giftItems.adminDashboardData.data;
export const selectAdminDashboardLoading = (state) =>
  state.giftItems.adminDashboardData.loading;
export const selectedSectionData = (state) =>
  state.giftItems.sectionItemsData.data;
export const selectedSectionLoading = (state) =>
  state.giftItems.sectionItemsData.loading;
export const selectedCategoryTitle = (state) =>
  state.giftItems.categoryItemsData.name;
export const selectedCategoryData = (state) =>
  state.giftItems.categoryItemsData.data;
export const selectedCategoryLoading = (state) =>
  state.giftItems.categoryItemsData.loading;
export const selectedItem = (state) => state.giftItems.selectedItem.data;
export const selectedItemLoading = (state) =>
  state.giftItems.selectedItem.loading;
export const approveLoader = (state) =>
  state.giftItems.companyGiftById.approveOrderPending;
export const rejectLoader = (state) =>
  state.giftItems.companyGiftById.rejectOrderPending;
export const exploreItemsData = (state) =>
  state.giftItems.exploreItemsInfo.sectionData;
export const categoriesData = (state) => state.giftItems.categories.data;
export const companyGiftingItemsData = (state) =>
  state.giftItems.companyGiftingItems.data.data;
export const companyGiftingItemsDataPending = (state) =>
  state.giftItems.companyGiftingItems.companyGiftingItemsPending;
export const companyGiftingItemsDataForMe = (state) =>
  state.giftItems.companyGiftingItems.giftingItemsForMeData.data;
export const getSectionItemsLoader = (state) =>
  state.giftItems.exploreItemsInfo.loading;
export const categoriesLoader = (state) => state.giftItems.categories.loading;
export const selectGiftsOrders = (state) => state.giftItems.companyGiftingData;
export const ordersLoader = (state) =>
  state.giftItems.companyGiftingData.loading;
export const orderDetailLoader = (state) =>
  state.giftItems.companyGiftById.loading;
export const ordersData = (state) => state.giftItems.companyGiftById.data;
export const giftsOrdersAdmin = (state) => state.giftItems.companyGiftingAdmin;
export const giftsOrdersAdminLoader = (state) =>
  state.giftItems.companyGiftingAdmin.loading;

export const getCreateCompanyGiftingRecipientOrderPending = (state) =>
  state.giftItems.companyGiftingItems.giftingItemsForMeData
    .createCompanyGiftingRecipientOrderPending;

export const selectRecipientsWithWishlists = (state) =>
  state.giftItems.findRecipientsWishlists.wishlists;
export const selectRecipientsWithWishlistsLoading = (state) =>
  state.giftItems.findRecipientsWishlists.loading;
export const selectOrderStatus = (state) =>
  state.giftItems.companyGiftById.data.status;
export const selectUpdateGiftingDetailsStatusLoading = (state) =>
  state.giftItems.updateGiftingDetailsStatus.loading;

export const selectRejectCompanyGiftingTokenPending = (state) =>
  state.giftItems.rejectCompanyGiftingTokenPending;

export const selectCompanyGiftingSetDeliveryAddressPending = (state) =>
  state.giftItems.companyGiftingSetDeliveryAddressPending;

export const selectGetDeliveryAddressCompanyGiftingTokenPending = (state) =>
  state.giftItems.getDeliveryAddressCompanyGiftingTokenPending;

export const selectDeliveryAddress = (state) =>
  state.giftItems.deliveryAddresses;

export default giftItemsSlice.reducer;
