import axiosApiClient from "..";
import { handleError } from "../../utils/errors";

const BASE_URL = "/business/businessUser";

class BusinessUserService {
  fetchBusinessUserInfo = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/me`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { businessUser } = response.data.data;
      return businessUser;
    } catch (err) {
      throw handleError(err, "Error occurred while fetching user's info.");
    }
  };

  getInvitationLink = async (id) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/invitation-link/${id}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { invitationLink } = response.data.data;
      return invitationLink;
    } catch (err) {
      throw handleError(err, "Error occurred while fetching user's info.");
    }
  };
}

const businessUserService = new BusinessUserService();

export default businessUserService;
