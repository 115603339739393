import React, { useRef } from "react";
import {
  ContentContainer,
  Text,
  ButtonNext,
  ButtonBack,
  Input,
  Buttons,
  UploadButton,
  RowInputLast,
  UploadedPhotosContainer,
  SmallText,
  UploadCard,
  HeaderCard,
  LeftIcons,
  TextArea,
  Image,
  ColumnInput,
  StyledRow,
} from "../Steps.styles";

import { useTranslation } from "react-i18next";
import { ReactComponent as Checked } from "../../../assets/icons/checkbox-fill.svg";
import { ReactComponent as Unchecked } from "../../../assets/icons/checkbox-blank-line.svg";
import { ReactComponent as Upload } from "../../../assets/icons/upload2.svg";
import UseAnimations from "react-useanimations";
import trash2 from "react-useanimations/lib/trash2";
import { useDispatch } from "react-redux";
import {
  addItemName,
  addItemPrice,
  setName,
  setPrice,
  addItemDisplayPhoto,
  setDisplayPhoto,
  addItemPhotos,
  setUrlPhotos,
  addItemDescription,
  setDescription,
  addItemArabicName,
  setArabicName,
  addItemArabicDescription,
  setArabicDescription,
  addItemPrintAreas,
  setPrintAreasToStore,
} from "../../../store/slices/marketplace/marketplaceSlice";
import { useSelector } from "react-redux";
import openToast from "../../../utils/toast";

const AddItemStepItemsInfo = ({ onNext, onBack, setPhotos, photos }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const name = useSelector(addItemName);
  const arabicName = useSelector(addItemArabicName);
  const price = useSelector(addItemPrice);
  const description = useSelector(addItemDescription);
  const arabicDescription = useSelector(addItemArabicDescription);
  const addItemPhotosArr = useSelector(addItemPhotos);
  const displayPhoto = useSelector(addItemDisplayPhoto);
  const toastId = useRef(null);

  const printAreasStore = useSelector(addItemPrintAreas);

  const handleFileUpload = (files) => {
    const updatedFiles = [...photos];
    for (let i = 0; i < files.length; i++) {
      updatedFiles.push(files[i]);
    }
    setPhotos(updatedFiles);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...photos];
    updatedFiles.splice(index, 1);
    setPhotos(updatedFiles);
    if (index + addItemPhotosArr.length <= displayPhoto) {
      dispatch(
        setDisplayPhoto(displayPhoto - 1 >= 0 ? displayPhoto - 1 : null)
      );
    }

    const tmpPrintAreas = { ...printAreasStore };
    delete tmpPrintAreas[index];

    for (const key in tmpPrintAreas) {
      if (key > index) {
        delete Object.assign(tmpPrintAreas, { [key - 1]: tmpPrintAreas[key] })[
          key
        ];
      }
    }

    dispatch(setPrintAreasToStore(tmpPrintAreas));
  };

  const handleRemovePhotUrl = (index) => {
    const tmpPhotos = [...addItemPhotosArr];
    tmpPhotos.splice(index, 1);
    dispatch(setUrlPhotos(tmpPhotos));
    if (index <= displayPhoto) {
      dispatch(
        setDisplayPhoto(displayPhoto - 1 >= 0 ? displayPhoto - 1 : null)
      );
    }

    let tmpPrintAreas = { ...printAreasStore };
    delete tmpPrintAreas[index];

    for (const key in tmpPrintAreas) {
      if (key > index) {
        delete Object.assign(tmpPrintAreas, { [key - 1]: tmpPrintAreas[key] })[
          key
        ];
      }
    }

    dispatch(setPrintAreasToStore(tmpPrintAreas));
  };

  const handleClick = (itemId, file) => {
    if (itemId === displayPhoto) {
      dispatch(setDisplayPhoto(null));
    } else {
      dispatch(
        setDisplayPhoto(file ? itemId + addItemPhotosArr.length : itemId)
      );
    }
  };

  const validation = () => {
    if (!name || !price || !arabicName) {
      openToast(toastId, "add-item", "warning", t("please-fill-all-inputs"));
    } else if (photos.length === 0 && addItemPhotosArr.length === 0) {
      openToast(toastId, "add-item", "warning", t("please-upload-photos"));
    } else if (displayPhoto === null) {
      openToast(
        toastId,
        "add-item",
        "warning",
        t("please-select-display-photo")
      );
    } else {
      onNext();
    }
  };

  return (
    <>
      <ContentContainer>
        <>
          <ColumnInput>
            <Text>{t("item-name")}:</Text>
            <StyledRow>
              <Input
                placeholder={t("name")}
                value={name}
                onChange={(e) => {
                  dispatch(setName(e.target.value));
                }}
              />
              <Input
                placeholder={t("arabic-name")}
                value={arabicName}
                onChange={(e) => {
                  dispatch(setArabicName(e.target.value));
                }}
              />
            </StyledRow>
          </ColumnInput>
          <ColumnInput>
            <Text>{t("item-description")}:</Text>
            <StyledRow>
              <TextArea
                placeholder={t("product-description")}
                value={description}
                rows={4}
                onChange={(e) => {
                  dispatch(setDescription(e.target.value));
                }}
              />
              <TextArea
                placeholder={t("arabic-product-description")}
                value={arabicDescription}
                rows={4}
                onChange={(e) => {
                  dispatch(setArabicDescription(e.target.value));
                }}
              />
            </StyledRow>
          </ColumnInput>
          <ColumnInput>
            <Text>{t("item-price")}:</Text>

            <Input
              type="number"
              min="1"
              placeholder={t("price")}
              value={price}
              onChange={(e) => {
                dispatch(setPrice(e.target.value));
              }}
            />
          </ColumnInput>
          <RowInputLast>
            <Text>{t("attach-photos")}:</Text>
            <UploadButton htmlFor="fileInput">
              <Upload />
              {t("upload-file")}

              <input
                type="file"
                id="fileInput"
                multiple
                accept="image/*"
                onChange={(e) => {
                  handleFileUpload(e.target.files);
                }}
                style={{ display: "none" }}
              />
            </UploadButton>
          </RowInputLast>
          <UploadedPhotosContainer>
            {addItemPhotosArr?.map((file, i) => (
              <UploadCard key={i}>
                <HeaderCard>
                  <LeftIcons>
                    {i === displayPhoto ? (
                      <>
                        <Checked
                          onClick={() => handleClick(i)}
                          className="pointer"
                        />
                        <SmallText>{t("display-photo")}</SmallText>
                      </>
                    ) : (
                      <Unchecked
                        onClick={() => handleClick(i)}
                        className="pointer"
                      />
                    )}
                  </LeftIcons>
                  <UseAnimations
                    onClick={() => handleRemovePhotUrl(i)}
                    className="pointer"
                    animation={trash2}
                    size={32}
                    strokeColor="#8A4B5E"
                  />
                </HeaderCard>
                <Image src={file.url} alt="ItemPhoto" />
              </UploadCard>
            ))}

            {photos?.map((file, i) => (
              <UploadCard key={i}>
                <HeaderCard>
                  <LeftIcons>
                    {addItemPhotosArr.length + i === displayPhoto ? (
                      <>
                        <Checked
                          onClick={() => handleClick(i, true)}
                          className="pointer"
                        />
                        <SmallText>{t("display-photo")}</SmallText>
                      </>
                    ) : (
                      <Unchecked
                        onClick={() => handleClick(i, true)}
                        className="pointer"
                      />
                    )}
                  </LeftIcons>
                  <UseAnimations
                    onClick={() => handleRemoveFile(i)}
                    className="pointer"
                    animation={trash2}
                    size={32}
                    strokeColor="#8A4B5E"
                  />
                </HeaderCard>
                <Image src={URL.createObjectURL(file)} alt="ItemPhoto" />
              </UploadCard>
            ))}
          </UploadedPhotosContainer>
        </>
      </ContentContainer>
      <Buttons>
        <ButtonBack onClick={onBack}>{t("back")}</ButtonBack>
        <ButtonNext onClick={validation}>{t("next")}</ButtonNext>
      </Buttons>
    </>
  );
};

export default AddItemStepItemsInfo;
