import { styled } from "styled-components";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu-icon.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/share-icon.svg";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo-small.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/add-icon.svg";
import DatePicker from "react-datepicker";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100dvh;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
`;

export const Input = styled.input`
  border: 1px solid #8c8c8c;
  border-radius: 15px;
  height: 45px;
  min-height: 45px;
  user-select: none;
  background: none;
  width: 100%;
  font-weight: 700;
  transition: 0.2s;

  &:active,
  &:hover,
  &:focus {
    border: 1px solid #8a4b5e;
    box-shadow: none;
  }
  padding: ${(props) =>
    props.$ar ? "15px 15px 15px 15px" : "15px 15px 15px 15px"};

  color: rgba(69, 69, 132, 1);
  font-family: "Avenir";
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.178px;
  @media (max-width: 450px) {
    font-size: 14px;
    border-radius: 7.5px;
    height: 30px;
  }
`;
export const MobileScreenContainer = styled.div`
  cursor: pointer;
  width: 600px;
  background-color: white;
  position: relative;
  height: 100%;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);

  @media (max-width: 575px) {
    width: 100%;
  }
`;

export const Background = styled.div`
  background-color: #8a4b5e;
  width: 100%;
  height: 235px;
`;
export const BackgroundRotated = styled(Background)`
  height: 90px;
  border-radius: 25px;
  width: 101%;
  margin-left: -2px;
  margin-top: -60px;
  transform: rotate(3deg);
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

export const HeaderContainer = styled.div`
  margin-top: 50px;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
  position: relative;
`;

export const Menu = styled(MenuIcon)`
  cursor: pointer;
`;
export const Share = styled(ShareIcon)`
  cursor: pointer;
`;
export const Logo = styled(LogoIcon)`
  cursor: default;
`;

export const ProfilePhoto = styled.img`
  width: 67px;
  user-select: none;
  height: 67px;
  border-radius: 50%;
  box-shadow: 0px 3px 21px -7px rgba(0, 0, 0, 0.24);
  flex-shrink: 0;
`;
export const ProfilePicEmpty = styled.div`
  width: 67px;
  height: 67px;
  border-radius: 50%;
  background: transparent;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 21px -7px rgba(0, 0, 0, 0.24);
  justify-content: center;
  border: 1.5px solid #fff;
`;
export const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 0px 20px;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Name = styled.span`
  color: #fff;
  font-family: "Avenir";
  user-select: none;
  font-size: 28px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.311px;
`;

export const Username = styled(Name)`
  font-size: 18px;
  letter-spacing: -0.2px;
`;

export const NameContainer = styled(ProfileInfoContainer)`
  flex-direction: column;
  padding: 0px;
  align-items: unset;
  gap: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const NameRow = styled(ProfileInfoContainer)`
  padding: 0px;
  gap: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const InfoBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 12px;
  padding: 0px 20px;
`;

export const Count = styled.span`
  color: #fff;
  font-family: "Avenir";
  font-size: 18px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  user-select: none;
  letter-spacing: -0.2px;
`;

export const BarName = styled(Count)`
  letter-spacing: -0.167px;
  font-size: 15px;
`;

export const BarRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 6px;
`;

export const Add = styled(AddIcon)`
  cursor: pointer;
`;

export const ButtonAdd = styled.button`
  border-radius: 39.5px;
  height: 40px;
  width: 100%;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(45, 45, 67, 0.11);
  display: flex;
  align-items: center;
  gap: 12px;
  flex-direction: row;
  justify-content: center;

  color: #8a4b5e;
  font-family: "Avenir-Heavy";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  user-select: none;
  line-height: normal;
  letter-spacing: -0.178px;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: calc(100% - 290px);
  margin-top: 80px;
  flex-direction: column;
  overflow-y: auto;
  padding: 0px 20px 20px 30px;
  gap: 20px;
`;

export const Card = styled.div`
  border-radius: 20px;
  position: relative;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(45, 45, 67, 0.11);
  min-height: 121px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 12px;
  flex-wrap: nowrap;
`;

export const CardName = styled.span`
  color: #222239;
  font-family: "Avenir-Medium";
  font-size: 18px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.27px;
  user-select: none;
  text-transform: lowercase;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media (max-width: 420px) {
    font-size: 14px;
  }
`;

export const CardDesc = styled(CardName)`
  color: #7c7c9d;
  font-size: 13px;
  line-height: 16px;
`;

export const CardPrice = styled(CardName)`
  color: #58589c;
  text-decoration: ${(props) => (props.$isBought ? "line-through" : "none")};
  font-size: 17px;
  margin-top: 8px;
  text-transform: ${(props) => (props.$lower ? "lowercase" : "uppercase")};

  @media (max-width: 420px) {
    font-size: 14px;
  }
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const CardImg = styled.img`
  width: 130px;
  height: 100px;
  border-radius: 25px;
  flex-shrink: 0;
  user-select: none;

  @media (max-width: 420px) {
    width: 100px;
    height: auto;
  }
`;
export const Scroll = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 30px;
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: -30px;
`;

export const CountContainer = styled.div`
  width: 36px;
  height: 31px;
  border-radius: 9px 0px 0px 9px;
  background: #8a4b5e;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const Number = styled.span`
  color: #fff;
  font-family: "Avenir";
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  user-select: none;
  line-height: normal;
  letter-spacing: -0.21px;
`;
export const TabName = styled.span`
  color: ${(props) => (props.$active ? "white" : "#8A4B5E")};
  font-family: "Avenir";
  font-size: 13px;
  user-select: none;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0.054px;
`;

export const Tab = styled.div`
  border-radius: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 36px;
  background: ${(props) => (props.$active ? "#8A4B5E" : "white")};
`;

export const TabContainer = styled.div`
  width: 100%;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 5px 32px 0px rgba(37, 37, 70, 0.12);

  /* height: 56px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const LoaderContainer = styled.div`
  position: relative;
`;

export const StyledLoaderContainer = styled(LoaderContainer)`
  margin-left: -20px;
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;
export const Image = styled.img`
  width: 100%;
  user-select: none;
  height: 320px;
  transform: rotate(-4deg);
`;

export const ImageWrapper = styled.div`
  border-radius: 40px;
  height: 280px;
  min-width: 104%;
  overflow: hidden;
  transform: rotate(4deg);
  margin-left: -6px;
  margin-top: -44px;
`;

export const ItemContent = styled.div`
  padding: 20px 20px 40px 20px;
  margin-top: 20px;
  gap: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  height: calc(100% - 236px);
  overflow: auto;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ItemName = styled.span`
  color: #222239;
  font-family: "Avenir-Medium";
  font-size: 18px;
  letter-spacing: -0.2px;
`;

export const ItemPrice = styled.span`
  color: #4d4d8e;
  font-family: "Avenir-Medium";
  font-size: 24px;
  letter-spacing: -0.267px;
  text-decoration: ${(props) => (props.$isBought ? "line-through" : "none")};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Value = styled.span`
  color: #222239;
  font-family: "Avenir-Medium";
  font-size: 16px;
  letter-spacing: -0.178px;
`;
export const PlaceHolderRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;
export const Placeholder = styled.span`
  color: #7c7c9d;
  gap: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Avenir-Medium";
  font-size: 13px;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.195px;
`;

export const InputContainer = styled.div`
  border-radius: 20px;
  width: 100%;
  background: #f4f4f8;
  padding: 14px 22px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ImageContainer = styled.div``;
export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  z-index: 50;
  top: 20px;
  left: 20px;
  cursor: pointer;
`;
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;
export const ButtonFavorite = styled.button`
  min-width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  background-color: white;
  outline: none;
  border: 1px solid #8a4b5e;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
`;
export const Button = styled.button`
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 25px;
  width: 90%;
  outline: none;
  border: 1px solid #8a4b5e;
  background-color: ${(props) => (props.$disabled ? "white" : "#8A4B5E")};
  font-family: "Avenir-Medium";
  color: ${(props) => (props.$disabled ? "#8A4B5E" : "white")};
  font-size: 18px;
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
`;

export const Absolute = styled.div`
  display: flex;
  position: absolute;
  right: 20px;
  bottom: 10px;
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const Title = styled.span`
  color: #8a4b5e;
  text-align: ${(props) => (props.$center ? "center" : "left")};
  font-family: "Avenir-Heavy";
  font-size: 18px;
  letter-spacing: -0.28px;
`;

export const ButtonSelected = styled.button`
  color: #8a4b5e;
  font-family: "Avenir-Medium";
  font-size: 18px;
  letter-spacing: 0.09px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 60px;
  width: 169px;
  outline: none;
  border: none;
  text-transform: uppercase;
  cursor: ${(props) => (props.$active ? "default" : "pointer")};
  border-radius: 30px;
  background: ${(props) => (props.$active ? "#fff" : "#f3edef")};
  box-shadow: ${(props) =>
    props.$active ? "0px 12px 24px -6px rgba(0, 0, 0, 0.2)" : "unset"};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 10px 20px;
`;

export const PageName = styled.span`
  text-transform: uppercase;
  color: #8a4b5e;
  font-family: "Avenir-Medium";
  font-size: 20px;
  letter-spacing: 0.09px;
`;

export const ChevronContainer = styled.div`
  position: absolute;
  left: 20px;
`;
export const Success = styled.img`
  background-color: transparent;
  width: 120px;
  user-select: none;
  height: 120px;
`;
export const Gap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const StoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  gap: 10px;
`;
export const Content = styled.div`
  padding: 20px 20px 75px 20px;
  height: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  justify-content: space-between;
`;

export const TitleColumn = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;
export const TitleRow = styled.div`
  gap: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
`;
export const SmallRow = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.span`
  color: #8a4b5e;
  font-family: "Avenir-Medium";
  font-size: 18px;
  letter-spacing: 0.09px;
`;

export const CustomizeOrder = styled.div`
  border-radius: 14px;
  position: relative;
  height: 171px;
  background-position: center;
  min-width: 90%;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  box-shadow: 0px 5px 12px 0px rgba(45, 45, 67, 0.11);
  margin-right: 20px;
`;

export const OrderColumn = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 10px;
  left: 10px;
  z-index: 2;
`;

export const PriceOrder = styled.span`
  color: #fff;
  font-family: "Avenir-Medium";
  font-size: 16px;
  line-height: 24px; /* 150% */
`;

export const TitleOrder = styled.span`
  color: #fff;
  font-family: "Avenir-Medium";
  font-size: 16px;
  line-height: 24px; /* 150% */
`;

export const Gradient = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 57px;
  border-radius: 0px 0px 14px 14px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
`;

export const CustomizeOrdersContainer = styled.div`
  padding: 10px;
  overflow-x: auto; /* Enable horizontal scrolling */
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SwitchContainer = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 2;
`;

export const GreenText = styled.span`
  color: #65bf73;
  font-family: "Avenir-Medium";
  font-size: 14px;
  letter-spacing: 0.07px;
`;

export const GiftCardContainer = styled.div`
  border-radius: 20px;

  background: #fff;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.13);
  display: flex;
  padding: 25px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const OccasionsContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

export const TextArea = styled.textarea`
  border-radius: 18.343px;
  border: 0.583px solid #8a4b5e;
  background: #fff;
  display: flex;
  outline: none;
  padding: 15px 9px 60px 9px;
  align-items: flex-start;
  gap: 10px;
  font-family: "Avenir-Medium";
  font-size: 14px;
  letter-spacing: 0.07px;
  width: 100%;
`;

export const TextLeft = styled.span`
  color: #979797;
  margin-left: auto;
  font-family: "Avenir-Medium";
  font-size: 12px;
  letter-spacing: 0.06px;
  text-decoration-line: underline;
`;

export const StyledTitle = styled(Title)`
  color: gray;
  font-family: "Avenir-Medium";
`;

export const StyledValue = styled(StyledTitle)`
  color: black;
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: lightgray;
`;
export const StyledDatePicker = styled(DatePicker)`
  color: #000;
  font-family: "Avenir-Heavy";
  font-size: 18px;
  letter-spacing: 0.09px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  height: 75px;
  width: 100%;
  outline: none;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 40px;
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.15) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.2) 0px 3px 7px -3px;
`;

export const SuccessDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
`;

export const LocaleWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 0px;
`;

export const LocaleWrapperAr = styled.div`
  position: absolute;
  left: 20px;
  top: 0px;
`;
