import React, { useEffect } from "react";
import {
  MainContainer,
  Column,
  SectionTitle,
  HeaderWrapper,
  ButtonsContainer,
  Button,
  StyledTitleContainer,
} from "./CouponsPage.styles";
import Sidebar from "../utils/Sidebar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { selectedCouponsDashboard } from "../../../store/slices/coupon/couponSlice";
import { getCouponsDashboard } from "../../../store/slices/coupon/asyncThunks";
import AllCoupons from "../../../components/Coupons/AllCoupons";
import StatusCoupons from "../../../components/Coupons/StatusCoupons";
import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";
import Header from "../../../components/Header/Header";
import { selectedLocale } from "../../../store/slices/common/slice";

const CouponsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const businessUser = useSelector(selectBusinessUserInfo);
  const coupons = useSelector(selectedCouponsDashboard);

  useEffect(() => {
    dispatch(getCouponsDashboard())
      .unwrap()
      .catch((err) => toast.error(t(err.message)));
  }, [dispatch]);

  const onClickMoreActive = () => {
    const query = { page: 1, type: "ACTIVE" };
    const queryString = new URLSearchParams(query).toString();
    const url = `/admin-portal/coupons?${queryString}`;

    navigate(url);
  };

  const onClickMoreDeactivated = () => {
    const query = { page: 1, type: "DEACTIVATED" };
    const queryString = new URLSearchParams(query).toString();
    const url = `/admin-portal/coupons?${queryString}`;

    navigate(url);
  };

  const { company, state } = useSelector(selectBusinessUserInfo);

  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  const locale = useSelector(selectedLocale);
  const upgradePackage = showUpgradeBar && diff > 0 && state;
  const ar = locale === "ar";

  return (
    <MainContainer>
      <HeaderWrapper>
        <Header
          showLogin
          showSignUp
          showNav
          color
          showFavorite
          showCart
          showUser
        />
      </HeaderWrapper>
      <Sidebar />

      <Column $ar={ar} $upgradePackage={upgradePackage}>
        <StyledTitleContainer>
          <SectionTitle>{t("coupons")}</SectionTitle>
          {businessUser.role === "superAdmin" && (
            <ButtonsContainer>
              <Button
                onClick={() => {
                  navigate("/admin-portal/coupons/new-coupon");
                }}
              >
                {t("new-coupon")}
              </Button>
            </ButtonsContainer>
          )}
        </StyledTitleContainer>
        {!type && (
          <AllCoupons
            data={coupons}
            onClickMoreActive={onClickMoreActive}
            onClickMoreDeactivated={onClickMoreDeactivated}
            ar={ar}
          />
        )}
        {type === "ACTIVE" && (
          <StatusCoupons
            onBack={() => navigate("/admin-portal/coupons")}
            ar={ar}
          />
        )}
        {type === "DEACTIVATED" && (
          <StatusCoupons
            onBack={() => navigate("/admin-portal/coupons")}
            ar={ar}
          />
        )}
      </Column>
    </MainContainer>
  );
};

export default CouponsPage;
