import styled, { css } from "styled-components";

export const LocaleContainer = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;

  /* padding: 5px 10px 5px 0px; */
  gap: 2.5px;
  transition: all 0.2s ease-in-out;
  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }
`;

export const LocaleButton = styled.span`
  user-select: none;
  font-size: 16px;
  font-family: "Avenir-Medium";
  font-style: normal;
  color: ${(props) => (props.$color ? "#fff" : "#8A4B5E")};
  line-height: 20px;
  letter-spacing: -0.177777px;
  min-width: 20px;

  transition: all 0.2s ease-in-out;
  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }

  ${(props) =>
    props.textColor &&
    css`
      color: ${props.textColor};
    `}
  @media (max-width: 1200px) {
    font-size: 1.4rem;
  }
`;

export const FlagIcon = styled.img`
  user-select: none;
  width: 25px;
  height: 25px;
`;
