import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Loading } from "../../../assets/icons/loader-white.svg";
import Button from "../../../components/Button/Button";

import {
  ButtonContainer,
  Container,
  Desc,
  Input,
  InputColumn,
  InputRow,
  OptionalText,
  TextLight,
  TextRow
} from "./TabBilling.styles";
import { ButtonConfirm, ButtonPlain } from "../TabGeneral/TabGeneral.styles";

const TabBilling = ({
  isEditable,
  billingEmail,
  setBillingEmail,
  company,
  address,
  setAddress,
  addressOptional,
  setAddressOptional,
  city,
  setCity,
  country,
  setCountry,
  zipCode,
  setZipCode,
  VAT,
  setVAT,
  businessUser,
  setIsEditable,
  handleEdit,
  onSaveClick,
  saveLoading
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Desc>{t("input-billing-address-and-email")}</Desc>
      <Desc>{t("invoices-will-be-sent")}</Desc>

      <TextLight className="marginT">{t("billing-email")}</TextLight>
      {isEditable ? (
        <Input
          id="billingEmail"
          name="billingEmail"
          placeholder={t("billing-email")}
          value={billingEmail}
          onChange={(e) => {
            const { value } = e.target;
            setBillingEmail(value);
          }}
          autoCapitalize="none"
        />
      ) : (
        <Input
          value={company.billingEmail}
          placeholder={t("billing-email")}
          readOnly
          onClick={(e) => e.preventDefault()}
          style={{ cursor: "default" }}
        />
      )}
      <InputRow>
        <InputColumn>
          <TextLight> {t("address")}</TextLight>
          {isEditable ? (
            <Input
              id="address"
              name="address"
              placeholder={t("address")}
              onChange={(e) => setAddress(e.target.value)}
              autoCapitalize="none"
              value={address}
            />
          ) : (
            <Input
              value={company.address}
              placeholder={t("address")}
              onClick={(e) => e.preventDefault()}
              style={{ cursor: "default" }}
              readOnly
            />
          )}
        </InputColumn>
        <InputColumn>
          <TextRow>
            <TextLight> {t("address-optional")}</TextLight>
            <OptionalText>{t("optional")}</OptionalText>
          </TextRow>
          {isEditable ? (
            <Input
              id="addressOptional"
              name="addressOptional"
              placeholder={t("address-optional")}
              value={addressOptional}
              onChange={(e) => setAddressOptional(e.target.value)}
              autoCapitalize="none"
            />
          ) : (
            <Input
              value={company.addressOptional}
              placeholder={t("address-optional")}
              onClick={(e) => e.preventDefault()}
              style={{ cursor: "default" }}
              readOnly
            />
          )}
        </InputColumn>
      </InputRow>
      <InputRow>
        <InputColumn>
          <TextLight> {t("city")}</TextLight>
          {isEditable ? (
            <Input
              id="city"
              name="city"
              placeholder={t("city")}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              autoCapitalize="none"
            />
          ) : (
            <Input
              value={company.city}
              placeholder={t("city")}
              onClick={(e) => e.preventDefault()}
              style={{ cursor: "default" }}
              readOnly
            />
          )}
        </InputColumn>
        <InputColumn>
          <TextRow>
            <TextLight>{t("zip-code")}</TextLight>
          </TextRow>
          {isEditable ? (
            <Input
              id="zipCode"
              name="zipCode"
              placeholder={t("zip-code")}
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              autoCapitalize="none"
            />
          ) : (
            <Input
              value={company.zipCode}
              onClick={(e) => e.preventDefault()}
              placeholder={t("zip-code")}
              style={{ cursor: "default" }}
              readOnly
            />
          )}
        </InputColumn>
      </InputRow>
      <InputRow>
        <InputColumn>
          <TextLight>{t("country")}</TextLight>
          {isEditable ? (
            <Input
              id="country"
              name="country"
              placeholder={t("country")}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              autoCapitalize="none"
            />
          ) : (
            <Input
              value={company.country}
              placeholder={t("country")}
              onClick={(e) => e.preventDefault()}
              style={{ cursor: "default" }}
              readOnly
            />
          )}
        </InputColumn>
        <InputColumn>
          <TextRow>
            <TextLight>{t("VAT")}</TextLight>
            <OptionalText>{t("optional")}</OptionalText>
          </TextRow>
          {isEditable ? (
            <Input
              id="VAT"
              name="VAT"
              placeholder={t("VAT")}
              value={VAT}
              onChange={(e) => setVAT(e.target.value)}
              autoCapitalize="none"
            />
          ) : (
            <Input
              value={company.VAT}
              onClick={(e) => e.preventDefault()}
              placeholder={t("VAT")}
              style={{ cursor: "default" }}
              readOnly
            />
          )}
        </InputColumn>
        {businessUser.role !== "employee"}
        <ButtonContainer>
          {isEditable && businessUser.role === "owner" ? (
            <div
              style={{
                display: "flex",
                gap: 20
              }}
            >
              <ButtonConfirm onClick={onSaveClick}>{t("save")}</ButtonConfirm>

              <ButtonConfirm
                onClick={() => {
                  setIsEditable(false);
                }}
                $cancel
              >
                {t("cancel")}
              </ButtonConfirm>
            </div>
          ) : (
            businessUser.role === "owner" && (
              <div>
                <ButtonPlain
                  saveloading={saveLoading + ""}
                  disabled={saveLoading}
                  onClick={handleEdit}
                >
                  {!saveLoading ? <>{t("edit")}</> : <Loading height={30} />}
                </ButtonPlain>
              </div>
            )
          )}
        </ButtonContainer>
      </InputRow>
    </Container>
  );
};

export default TabBilling;
