import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../store/slices/auth/slice";
import commonSlice from "../store/slices/common/slice";
import businessUserSlice from "../store/slices/businessUser/slice";
import companySlice from "../store/slices/company/slice";
import recipientSlice from "./slices/recipients/slice";
import authRecipientSlice from "../store/slices/authRecipient/slice";
import giftItemsSlice from "../store/slices/gift/slice";
import planSlice from "../store/slices/plan/slice";
import sendGiftsSlice from "../store/slices/sendGifts/slice";
import billingSlice from "../store/slices/billing/slice";
import dashboardSlice from "../store/slices/dashboard/slice";
import couponSlice from "../store/slices/coupon/couponSlice";
import itemSlice from "./slices/item/itemSlice";
import userSlice from "./slices/user/userSlice";
import subscriptionsSlice from "./slices/subscriptions/slice";
import marketplaceSlice from "./slices/marketplace/marketplaceSlice";
import paymentSlice from "./slices/payment/paymentSlice";
import instantQuotationSlice from "./slices/instantQuotation/instantQuotationSlice";
import photoProcessingSlice from "./slices/photoProcessing/slice";

import { api } from "./api";

const store = configureStore({
  reducer: {
    register: authSlice,
    commonInfo: commonSlice,
    businessUser: businessUserSlice,
    company: companySlice,
    giftItems: giftItemsSlice,
    recipient: recipientSlice,
    recipientUser: authRecipientSlice,
    recipientInfo: recipientSlice,
    authRecipient: authRecipientSlice,
    planInfo: planSlice,
    marketplace: marketplaceSlice,
    sendGifts: sendGiftsSlice,
    billingInfo: billingSlice,
    dashboard: dashboardSlice,
    coupon: couponSlice,
    itemUnauthorized: itemSlice,
    userUnauthorized: userSlice,
    subscriptions: subscriptionsSlice,
    paymentInfo: paymentSlice,
    instantQuotationInfo: instantQuotationSlice,
    photoProcessingInfo: photoProcessingSlice,

    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
  devTools: false,
});

export default store;
