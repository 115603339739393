import styled from "styled-components";

export const StyledButtonAdd = styled.button`
  border-radius: 35px;
  border: none;
  padding: 5px 15px;
  width: 80px;
  color: #fff;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
  }
  height: 30px;
  line-height: normal;
  letter-spacing: -0.178px;
  background: #8a4b5e;
`;
