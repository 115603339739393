import { createAsyncThunk } from "@reduxjs/toolkit";
import couponServices from "../../../api/services/coupon";

export const getCouponsDashboard = createAsyncThunk(
  "coupon/get-coupons-dashboard",
  async (requestData) => {
    return await couponServices.getCouponsDashboard(requestData);
  }
);

export const getCoupons = createAsyncThunk(
  "coupon/get-coupons",
  async (requestData) => {
    return await couponServices.getCoupons(requestData);
  }
);

export const createCoupon = createAsyncThunk(
  "coupon/create-coupon",
  async (requestData) => {
    return await couponServices.createCoupon(requestData);
  }
);

export const removeCoupon = createAsyncThunk(
  "coupon/remove-coupon",
  async (requestData) => {
    return await couponServices.removeCoupon(requestData);
  }
);

export const editCoupon = createAsyncThunk(
  "coupon/edit-coupon",
  async (requestData) => {
    return await couponServices.editCoupon(requestData);
  }
);
export const getCouponById = createAsyncThunk(
  "coupon/get-coupon",
  async (requestData) => {
    return await couponServices.getCouponById(requestData);
  }
);
