import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  MainContainer,
  Column,
  Content,
  Text,
  Buttons,
  Container,
  TextContainerNone,
  SearchAndButtonContainer,
  FormDiv,
  Message,
  ButtonUpload,
  SectionTitle,
  SecondLevelText,
  Row,
  MaxRecipientsText,
  NumberOfRecipientsContainer,
  LoadingIcon,
  MessageContainer,
  ButtonContainer,
  LoaderDiv,
  SendGiftsButton,
  HeaderWrapper,
  TableBodyRow,
  FirstItem,
  LastTableItem,
} from "./RecipientsPage.styles";
import Sidebar from "../utils/Sidebar";
import { ReactComponent as Add } from "../../../assets/icons/addrecipeint.svg";
import { ReactComponent as Upload } from "../../../assets/icons/upload.svg";
import { ReactComponent as Download } from "../../../assets/icons/file-download.svg";
import { ReactComponent as SendGiftIcon } from "../../../assets/icons/send-gift-icon.svg";
import { ReactComponent as Edit } from "../../../assets/icons/edit-3.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addRecipient,
  addRecipientsWithFile,
  deleteRecipient,
  editRecipient,
  getRecipients,
} from "../../../store/slices/recipients/asyncThunks";
import RecipientForm from "../../../components/RecipientForm/RecipientForm";
import { isValidNumber, isValidPhoneNumber } from "libphonenumber-js";
import SearchInput from "../../../components/Search/Search";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import { getUserByPhone } from "../../../store/slices/gift/asyncThunks";
import {
  deleteRecipientLoading,
  recipientsData,
  recipientsInfo,
  uploadRecipientLoading,
} from "../../../store/slices/recipients/slice";
import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";
import MiniLoader from "../../../components/MiniLoader/MiniLoader";
import { useLocation, useNavigate } from "react-router";
import Header from "../../../components/Header/Header";
import openToast from "../../../utils/toast";
import Table from "../../../components/Table/Table";
import TableItem from "../../../components/Table/TableItem";
import UseAnimations from "react-useanimations";
import trash2 from "react-useanimations/lib/trash2";
import debounce from "lodash.debounce";
import { ReactComponent as Loading } from "../../../assets/icons/loader-white.svg";
import { selectedLocale } from "../../../store/slices/common/slice";

const RecipientsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const recipients = useSelector(recipientsData);
  const { total, perPage, maxReached, loading } = useSelector(recipientsInfo);
  const [showAddRecipientForm, setShowAddRecipientForm] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [department, setDepartment] = useState("");
  const departments = [
    { id: 1, name: t("sales") },
    { id: 2, name: t("marketing") },
    { id: 3, name: t("engineering") },
  ];
  const [fetchedUsers, setFetchedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const formattedPhone = mobileNumber?.replaceAll(" ", "");
  const [showInput, setShowInput] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const businessUser = useSelector(selectBusinessUserInfo);
  const loadingDelete = useSelector(deleteRecipientLoading);
  const documentUpload = useRef();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const [isEdit, setIsEdit] = useState(false);
  const toastId = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const uploadRecipientWithFileLoading = useSelector(uploadRecipientLoading);

  const showUpgradeBar = businessUser.company.packageType === "Free";
  let diff =
    new Date(businessUser.company?.packageExpiration).getTime() -
    new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  const templateUrl =
    "https://ygii-s3-bucket.s3.me-south-1.amazonaws.com/MassRecipientsTemplate.xlsx";

  const cannotAddRecipients =
    businessUser.company.maxNumberOfUsers === null ||
    +businessUser.company.maxNumberOfUsers <= +total;

  const [queryData, setQueryData] = useState({
    page: 1,
  });

  useEffect(() => {
    dispatch(getRecipients({ page: 1, search: "" }))
      .unwrap()
      .then(() => {
        setLoadingTable(false);
        setQueryData((prev) => {
          const page = 2;
          return { ...prev, page };
        });
      })
      .catch((error) => {
        toast.error(t(error.message));
      });
  }, [dispatch]);

  const changeHandler = useCallback(
    (text) => {
      setLoadingTable(false);
      dispatch(getRecipients({ page: 1, search: text }))
        .unwrap()
        .then(() => {
          setLoadingTable(true);
          inputRef.current.focus();
          setQueryData((prev) => {
            const page = 2;
            return { ...prev, page };
          });
        })
        .catch((error) => {
          toast.error(t(error.message));
        });
    },
    [dispatch]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    [changeHandler]
  );
  const handleChange = (event) => {
    debouncedChangeHandler(event.target.value);
  };

  const loadMoreItems = () => {
    if (!maxReached && !loading) {
      dispatch(getRecipients({ page: queryData.page, search: searchTerm }))
        .unwrap()
        .then(() => {
          setQueryData((prev) => {
            const page = queryData.page + 1;
            return { ...prev, page };
          });
        })
        .catch((error) => {
          toast.error(t(error.message));
        });
    }
  };

  useEffect(() => {
    if (formattedPhone && isValidNumber(formattedPhone)) {
      dispatch(getUserByPhone(formattedPhone))
        .then((response) => {
          const fetchedUsers = response.payload.fetchedUsers;
          setFetchedUsers(fetchedUsers);
        })
        .catch((error) => {
          toast.error(t(error.message));
        });
    }
  }, [dispatch, formattedPhone]);

  useEffect(() => {
    if (fetchedUsers?.length === 1) {
      setSelectedUser(fetchedUsers[0]);
    } else {
      if (isEdit && selectedRecipient?.user?.id) {
        setSelectedUser(selectedRecipient?.user?.id);
      } else if (fetchedUsers?.length > 1) {
        setSelectedUser(fetchedUsers[0]);
      }
    }
  }, [fetchedUsers, formattedPhone, isEdit, selectedRecipient]);

  const resetFormData = () => {
    setShowAddRecipientForm(false);
    setShowInput(false);
    setFetchedUsers([]);
    setEmail("");
    setFirstName("");
    setLastName("");
    setMobileNumber("");
    setDepartment("");
    setSelectedUser("");
    setIsEdit(false);
    setSelectedRecipient(null);
  };

  const handleAddRecipientClick = () => {
    resetFormData();
    setShowAddRecipientForm(true);
  };

  const handleConfirmClick = () => {
    if (validateForm()) {
      if (isEdit) {
        dispatch(
          editRecipient({
            recipientId: selectedRecipient.id,
            email,
            firstName,
            lastName,
            mobileNumber: mobileNumber?.replaceAll(" ", ""),
            department,
            ...(selectedUser?.id && { userId: selectedUser.id }),
          })
        )
          .unwrap()
          .then(() => {
            toast.success(t("you-successfully-edited-recipient-details"));
            resetFormData();
            setSelectedRecipient(null);
            dispatch(getRecipients({ page: 1, search: searchTerm }))
              .unwrap()
              .then(() => {
                setQueryData((prev) => {
                  const page = queryData.page + 1;
                  return { ...prev, page };
                });
              })
              .catch((error) => {
                toast.error(t(error.message));
              });
            navigate(`/admin-portal/recipients?page=${+page}`);
          })
          .catch((error) => {
            openToast(toastId, "edit-recipient", "error", t(error.message));
          });
      } else {
        dispatch(
          addRecipient({
            email,
            firstName,
            lastName,
            mobileNumber: mobileNumber?.replaceAll(" ", ""),
            department,
            ...(selectedUser?.id && { userId: selectedUser.id }),
          })
        )
          .unwrap()
          .then(() => {
            toast.success(t("you-successfully-added-a-recipient"));
            resetFormData();
            dispatch(getRecipients({ page: 1, search: searchTerm }))
              .unwrap()
              .then(() => {
                setQueryData((prev) => {
                  const page = queryData.page + 1;
                  return { ...prev, page };
                });
              })
              .catch((error) => {
                toast.error(t(error.message));
              });
            navigate(`/admin-portal/recipients?page=1`);
          })
          .catch((error) => {
            openToast(toastId, "add-recipient", "error", t(error.message));
          });
      }
    }
  };

  // const handlePageChange = (pageNumber) => {
  //   dispatch(getRecipients(pageNumber))
  //     .unwrap()
  //     .catch((error) => {
  //       toast.error(error.message);
  //     });
  //   const query = { page: pageNumber };
  //   const queryString = new URLSearchParams(query).toString();
  //   navigate(`/admin-portal/recipients?${queryString}`);
  // };

  const handleAddIconClick = () => {
    setShowInput(true);
  };

  const validateForm = () => {
    let formIsValid = true;

    if (!firstName) {
      openToast(
        toastId,
        "add-recipient-form",
        "error",
        t("first-name-is-required")
      );
      formIsValid = false;
    }

    if (!lastName) {
      openToast(
        toastId,
        "add-recipient-form",
        "error",
        t("last-name-is-required")
      );
      formIsValid = false;
    }

    if (!email) {
      openToast(toastId, "add-recipient-form", "error", t("email-is-required"));
      formIsValid = false;
    }

    if (!department) {
      openToast(
        toastId,
        "add-recipient-form",
        "error",
        t("department-is-required")
      );
      formIsValid = false;
    }

    if (!mobileNumber) {
      openToast(
        toastId,
        "add-recipient-form",
        "error",
        t("phone-number-is-required")
      );
      formIsValid = false;
    } else if (!isValidPhoneNumber(mobileNumber)) {
      formIsValid = false;
    }

    if (mobileNumber && fetchedUsers?.length > 0) {
      if (!selectedUser) {
        openToast(
          toastId,
          "add-recipient-form",
          "error",
          t("please-select-user")
        );
        formIsValid = false;
      }
    }

    return formIsValid;
  };

  const onUploadFileClick = () => {
    documentUpload?.current.click();
  };

  const handleUploadRecipients = (event) => {
    const formData = new FormData();

    formData.append("file", event.target.files[0]);
    dispatch(addRecipientsWithFile(formData))
      .unwrap()
      .then((res) => {
        if (!res.data.error) {
          toast.success(t("you-successfully-added-recipients"));
        } else {
          toast.warning(t("you-successfully-added-recipients-however"));
        }
        dispatch(getRecipients({ page: 1, search: "" }))
          .unwrap()
          .then(() => {
            setQueryData((prev) => {
              const page = queryData.page + 1;
              return { ...prev, page };
            });
          })
          .catch((error) => {
            toast.error(t(error.message));
          });
        navigate(`/admin-portal/recipients?page=1`);
      })
      .catch((error) => {
        toast.error(t(error.message));
      });
  };

  const handleDownloadTemplate = () => {
    window.open(templateUrl, "_blank");
  };

  const tableHeader = [
    {
      label: `${t("email")}`,
      sortable: true,
      key: "email",
    },
    {
      label: `${t("first-name")}`,
      sortable: true,
      key: "firstName",
    },
    {
      label: `${t("last-name")}`,
      sortable: true,
      key: "lastName",
    },
    {
      label: `${t("mobile-number")}`,
      sortable: true,
      key: "mobilePhone",
    },
    {
      label: `${t("department")}`,
      sortable: true,
      key: "department",
    },

    {
      label: "",
      sortable: false,
      key: "icons",
    },
  ];

  const locale = useSelector(selectedLocale);
  const upgradePackage = showUpgradeBar && diff > 0 && businessUser.state;
  const ar = locale === "ar";

  return (
    <MainContainer>
      <HeaderWrapper>
        <Header
          showLogin
          showSignUp
          showNav
          color
          showUser
          showFavorite
          showCart
        />
      </HeaderWrapper>
      <Sidebar />

      <Column $upgradePackage={upgradePackage} $ar={ar}>
        <SectionTitle>
          {!showAddRecipientForm
            ? t("recipients")
            : !isEdit
            ? t("add-recipients")
            : t("edit-recipients")}
        </SectionTitle>
        {businessUser?.company?.packageType && diff >= 0 ? (
          <>
            {!showAddRecipientForm && (
              <SearchAndButtonContainer>
                <SearchInput
                  inputRef={inputRef}
                  placeholderColor={true}
                  placeholder={t("search-by-name-or-email")}
                  value={searchTerm}
                  onChange={(e) => {
                    handleChange(e);
                    setSearchTerm(e.target.value);
                  }}
                  disabled={recipients?.length === 0 && searchTerm === ""}
                />
                {((recipients?.length === 0 && searchTerm !== "") ||
                  recipients.length !== 0) && (
                  <>
                    <ButtonContainer>
                      <Buttons
                        $disabled={cannotAddRecipients}
                        onClick={() =>
                          !cannotAddRecipients && handleAddRecipientClick()
                        }
                      >
                        <Add style={{ marginRight: 10 }} />
                        {t("add-recipient")}
                      </Buttons>
                      <ButtonUpload
                        onClick={() =>
                          !cannotAddRecipients && onUploadFileClick()
                        }
                        $disabled={cannotAddRecipients}
                      >
                        <Upload
                          style={{
                            marginRight: 10,
                            width: 32,
                            height: 32,
                          }}
                        />
                        {uploadRecipientWithFileLoading ? (
                          <Loading height={20} width={50} />
                        ) : (
                          <>{t("upload-file")}</>
                        )}
                      </ButtonUpload>
                      <input
                        style={{ display: "none" }}
                        ref={documentUpload}
                        type="file"
                        onChange={handleUploadRecipients}
                        accept=".csv, .xlsx"
                      />
                      <Buttons onClick={handleDownloadTemplate}>
                        <Download style={{ marginRight: 10 }} />
                        {t("download-template")}
                      </Buttons>

                      <SendGiftsButton
                        onClick={() => {
                          navigate("/admin-portal/send-gifts/1");
                        }}
                      >
                        <SendGiftIcon
                          fill="#FFF"
                          style={{
                            marginRight: 8,
                          }}
                        />
                        {t("send-gifts")}
                      </SendGiftsButton>
                    </ButtonContainer>
                  </>
                )}
              </SearchAndButtonContainer>
            )}

            {!loading ? (
              <>
                <Content
                  $center={
                    recipients?.length === 0 &&
                    !searchTerm &&
                    !showAddRecipientForm
                  }
                >
                  {recipients?.length === 0 &&
                  searchTerm === "" &&
                  !loading &&
                  !loadingTable ? (
                    !showAddRecipientForm ? (
                      <TextContainerNone>
                        <Text>{t("no-recipients")}</Text>
                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 5,
                          }}
                        >
                          <SecondLevelText>
                            {t("upload-a-file-please-download-follow")}
                          </SecondLevelText>
                          <SecondLevelText
                            style={{ color: "#55A1E8", cursor: "pointer" }}
                            onClick={handleDownloadTemplate}
                          >
                            {" "}
                            {t("template-file")}
                          </SecondLevelText>
                        </Row>
                        <div
                          style={{
                            display: "flex",
                            gap: 12,
                            marginTop: 20,
                          }}
                        >
                          <Buttons onClick={handleAddRecipientClick}>
                            <Add style={{ marginRight: 10 }} />
                            {t("add-recipient")}
                          </Buttons>
                          <ButtonUpload onClick={onUploadFileClick}>
                            <Upload
                              style={{
                                marginRight: 10,
                                width: 32,
                                height: 32,
                              }}
                            />
                            {t("upload-file")}
                          </ButtonUpload>
                          <input
                            style={{ display: "none" }}
                            ref={documentUpload}
                            type="file"
                            onChange={handleUploadRecipients}
                            accept=".csv, .xlsx"
                          />
                        </div>
                      </TextContainerNone>
                    ) : (
                      <Container>
                        <RecipientForm
                          t={t}
                          email={email}
                          setEmail={setEmail}
                          firstName={firstName}
                          setFirstName={setFirstName}
                          lastName={lastName}
                          setLastName={setLastName}
                          mobileNumber={mobileNumber}
                          setMobileNumber={setMobileNumber}
                          setDepartment={setDepartment}
                          department={department}
                          departments={departments}
                          handleConfirmClick={handleConfirmClick}
                          fetchedUsers={fetchedUsers}
                          setSelectedUser={setSelectedUser}
                          showInput={showInput}
                          setShowInput={setShowInput}
                          handleAddIconClick={handleAddIconClick}
                          resetFormData={resetFormData}
                          isEdit={isEdit}
                          recipient={selectedRecipient}
                          setIsEdit={setIsEdit}
                        />
                      </Container>
                    )
                  ) : (
                    <Container>
                      {showAddRecipientForm && (
                        <FormDiv>
                          <RecipientForm
                            t={t}
                            email={email}
                            setEmail={setEmail}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            lastName={lastName}
                            setLastName={setLastName}
                            mobileNumber={mobileNumber}
                            setMobileNumber={setMobileNumber}
                            setDepartment={setDepartment}
                            departments={departments}
                            department={department}
                            handleConfirmClick={handleConfirmClick}
                            fetchedUsers={fetchedUsers}
                            setSelectedUser={setSelectedUser}
                            showInput={showInput}
                            setShowInput={setShowInput}
                            handleAddIconClick={handleAddIconClick}
                            resetFormData={resetFormData}
                            validateForm={validateForm}
                            isEdit={isEdit}
                            recipient={selectedRecipient}
                            setIsEdit={setIsEdit}
                          />
                        </FormDiv>
                      )}

                      {!showAddRecipientForm && (
                        <>
                          {cannotAddRecipients && searchTerm === "" && (
                            <NumberOfRecipientsContainer>
                              <MaxRecipientsText>
                                *{t("reached-max-number-of-users")}
                              </MaxRecipientsText>
                            </NumberOfRecipientsContainer>
                          )}
                          <Table
                            key={searchTerm}
                            infinityScroll
                            marginTop={40}
                            headerInformation={tableHeader}
                            totalCount={total}
                            currentPage={+page}
                            setPage={() => {}}
                            perPage={perPage}
                            noDataContent={t("no-data")}
                            dataLoading={false}
                            lastColumnAligned
                            maxReached={maxReached}
                            dataLength={recipients.length}
                            loadMoreItems={loadMoreItems}
                          >
                            {recipients.map((recipient, key) => {
                              const isSelected =
                                selectedRecipient?.id === recipient.id;

                              return (
                                <TableBodyRow
                                  key={key}
                                  $active={isSelected}
                                  $offClick
                                >
                                  <FirstItem
                                    hoverText={recipient?.email}
                                    length={18}
                                    showHover
                                    data={
                                      recipient.email &&
                                      recipient.email.length > 18
                                        ? recipient.email.slice(0, 18) + "..."
                                        : recipient.email || "/"
                                    }
                                  />
                                  <TableItem
                                    data={recipient.firstName || "/"}
                                  />
                                  <TableItem data={recipient.lastName || "/"} />
                                  <TableItem
                                    data={recipient.mobileNumber || "/"}
                                  />
                                  <TableItem
                                    data={recipient.department || "/"}
                                  />

                                  <LastTableItem
                                    $offClick
                                    data={
                                      <>
                                        <Edit
                                          className="delete-icon"
                                          width={28}
                                          height={28}
                                          style={{ marginBottom: -4 }}
                                          onClick={() => {
                                            setSelectedRecipient(recipient);
                                            setShowAddRecipientForm(true);
                                            setIsEdit(true);
                                          }}
                                        />
                                        {recipient.id === selectedRecipient &&
                                        loadingDelete ? (
                                          <div style={{ marginBottom: -2 }}>
                                            <LoadingIcon
                                              height={28}
                                              width={28}
                                            />
                                          </div>
                                        ) : (
                                          <UseAnimations
                                            animation={trash2}
                                            size={32}
                                            className="delete-icon"
                                            strokeColor="#8A4B5E"
                                            onClick={() => {
                                              setSelectedRecipient(
                                                recipient.id
                                              );
                                              setModalOpen(true);
                                            }}
                                          />
                                        )}
                                      </>
                                    }
                                  />
                                </TableBodyRow>
                              );
                            })}
                          </Table>
                        </>
                      )}
                    </Container>
                  )}
                </Content>
              </>
            ) : (
              <LoaderDiv>
                <MiniLoader />
              </LoaderDiv>
            )}
          </>
        ) : (
          <MessageContainer>
            <Message>{t("you-must-upgrade-subscription-plan")}</Message>
          </MessageContainer>
        )}
      </Column>

      <ModalConfirm
        title={"Are you sure you want to delete this recipient?"}
        open={modalOpen}
        onClickNo={() => {
          setModalOpen(false);
        }}
        close={() => {
          setModalOpen(false);
        }}
        onClickYes={() => {
          dispatch(deleteRecipient(selectedRecipient))
            .unwrap()
            .then(() => {
              toast.success(t("recipient-deleted-successfully"));
              setModalOpen(false);
            })
            .catch((error) => {
              openToast(toastId, "delete-recipient", "error", t(error.message));
            });
        }}
      />
    </MainContainer>
  );
};

export default RecipientsPage;
