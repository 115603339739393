import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BenefitsBox,
  BenefitsBoxFirst,
  BenefitsFirst,
  BenefitsText,
  ButtonBoxLast,
  ButtonPrice,
  CardHeader,
  CardHeaderContainer,
  Column,
  Desc,
  DescBox,
  Row,
  Title,
  LeftCard,
  MiddleCard,
  RightCard,
  StyledPriceContainer,
  PriceLabel,
  PriceAmount,
} from "./PricePlan.styles";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getPlans } from "../../store/slices/plan/asyncThunks";
import { ReactComponent as Green } from "../../assets/icons/green.svg";
import { useSelector } from "react-redux";
import { selectedLocale } from "../../store/slices/common/slice";
import { selectBusinessUserInfo } from "../../store/slices/businessUser/slice";

const PricePlan = ({ startPremium, startFree, startEnterprise }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { company } = useSelector(selectBusinessUserInfo);

  useEffect(() => {
    dispatch(getPlans())
      .unwrap()
      .catch((err) => toast.error(t(err.message)));
  }, [dispatch]);

  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";

  /* SCROLL ANIMATION */

  const [isIntersecting, setIsIntersecting] = useState(false);

  const ref = useRef(null);

  const handleIntersection = (setIntersecting, ref) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      { rootMargin: "-300px" }
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  };

  useEffect(() => handleIntersection(setIsIntersecting, ref, "slide-in"), []);

  useEffect(() => {
    const handleClassToggle = (isIntersecting, ref, className) => {
      ref.current.querySelectorAll("div").forEach((el) => {
        if (isIntersecting) {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      });
    };

    handleClassToggle(isIntersecting, ref, "slide-in");
  }, [isIntersecting]);

  return (
    <Row ref={ref}>
      <LeftCard $ar={ar}>
        <CardHeaderContainer>
          <CardHeader>
            <Title>{t("free")}</Title>
          </CardHeader>
          <DescBox>
            <Desc>{t("give-the-gift-of-choice")}</Desc>
          </DescBox>
        </CardHeaderContainer>

        <StyledPriceContainer>
          <PriceLabel>{t("forever")}</PriceLabel>
          <PriceAmount>0 SAR</PriceAmount>
        </StyledPriceContainer>

        <BenefitsFirst>
          <Column>
            <BenefitsBoxFirst>
              <div>
                <Green />
              </div>
              <BenefitsText>{t("100-recipients")}</BenefitsText>
            </BenefitsBoxFirst>
            <BenefitsBox>
              <div>
                <Green />
              </div>
              <BenefitsText>{t("send-gifts-across-sa")}</BenefitsText>
            </BenefitsBox>
            <BenefitsBox>
              <div>
                <Green />
              </div>
              <BenefitsText>{t("branded-items-and-giveaways")}</BenefitsText>
            </BenefitsBox>
            <BenefitsBox>
              <div>
                <Green />
              </div>
              <BenefitsText>{t("unlimited-gifts")}</BenefitsText>
            </BenefitsBox>
          </Column>
          <ButtonBoxLast>
            <ButtonPrice
              disabled={company.packageType === "Free"}
              onClick={startFree}
            >
              {company.packageType === "Free"
                ? t("active")
                : t("start-for-free")}
            </ButtonPrice>
          </ButtonBoxLast>
        </BenefitsFirst>
      </LeftCard>

      <MiddleCard>
        <CardHeaderContainer>
          <CardHeader>
            <Title>{t("premium")}</Title>
          </CardHeader>
          <DescBox>
            <Desc>{t("scale-up-your-gifting")}</Desc>
          </DescBox>
        </CardHeaderContainer>

        <StyledPriceContainer>
          <PriceLabel>{t("yearly")}</PriceLabel>
          <PriceAmount>1,199 SAR</PriceAmount>
        </StyledPriceContainer>

        <BenefitsFirst>
          <Column>
            <BenefitsBoxFirst>
              <div>
                <Green />
              </div>
              <BenefitsText>{t("everything-in-free-package")}</BenefitsText>
            </BenefitsBoxFirst>
            <BenefitsBox>
              <div>
                <Green />
              </div>
              <BenefitsText>{t("unlimited-recipients")}</BenefitsText>
            </BenefitsBox>
            <BenefitsBox>
              <div>
                <Green />
              </div>
              <BenefitsText>{t("no-delivery-fee-in-sa")}</BenefitsText>
            </BenefitsBox>
            <BenefitsBox>
              <div>
                <Green />
              </div>
              <BenefitsText>{t("premium-procurement-discounts")}</BenefitsText>
            </BenefitsBox>
          </Column>
        </BenefitsFirst>
        <ButtonBoxLast>
          <ButtonPrice
            disabled={company.packageType === "Premium"}
            onClick={startPremium}
          >
            {company.packageType === "Premium" ? t("active") : t("subscribe")}
          </ButtonPrice>
        </ButtonBoxLast>
      </MiddleCard>

      <RightCard $ar={ar}>
        <CardHeaderContainer>
          <CardHeader>
            <Title>{t("platinum")}</Title>
          </CardHeader>
          <DescBox>
            <Desc>{t("build-an-impactful-gifting-program")}</Desc>
          </DescBox>
        </CardHeaderContainer>

        <StyledPriceContainer>
          <PriceLabel>{t("yearly")}</PriceLabel>
          <PriceAmount>9,900 SAR</PriceAmount>
        </StyledPriceContainer>

        <BenefitsFirst>
          <Column>
            <BenefitsBoxFirst>
              <div>
                <Green />
              </div>
              <BenefitsText>{t("everything-in-premium")}</BenefitsText>
            </BenefitsBoxFirst>
            <BenefitsBox>
              <div>
                <Green />
              </div>
              <BenefitsText>{t("dedicated-account")}</BenefitsText>
            </BenefitsBox>
            <BenefitsBox>
              <div>
                <Green />
              </div>
              <BenefitsText>{t("gifting-programs")}</BenefitsText>
            </BenefitsBox>
            <BenefitsBox>
              <div>
                <Green />
              </div>
              <BenefitsText>
                {t("enterprise-procurement-discounts")}
              </BenefitsText>
            </BenefitsBox>
          </Column>
          <ButtonBoxLast>
            <ButtonPrice
              disabled={company.packageType === "Enterprise"}
              onClick={startEnterprise}
            >
              {company.packageType === "Enterprise"
                ? t("active")
                : t("subscribe")}
            </ButtonPrice>
          </ButtonBoxLast>
        </BenefitsFirst>
      </RightCard>
    </Row>
  );
};

export default PricePlan;
