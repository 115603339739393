import { styled } from "styled-components";
import ProgressiveImg from "../../components/ProgressiveImg/ProgressiveImg";

export const HomePageContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #8a4b5e;
  border-radius: 0px 0px 30px 30px;
  padding-top: 90px;

  padding-bottom: 90px;
  height: 1015px;

  @media (max-width: 1860px) {
    height: ${(props) => (props.$ar ? "1075px" : "1015px")};
    align-items: ${(props) => (props.$ar ? "flex-start" : "center")};
  }
  @media (max-width: 1235px) {
    height: 1075px;
    align-items: flex-start;
  }
`;
export const HomeContainer = styled.div`
  width: 100%;
  background-color: white;
  overflow-x: hidden;

  position: relative;
`;
export const Wrapper = styled.main`
  width: 1440px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1860px) {
    justify-content: ${(props) => (props.$ar ? "center" : "space-between")};
    padding-top: ${(props) => (props.$ar ? "40px" : "0px")};
    flex-direction: ${(props) => (props.$ar ? "row" : "row")};
    align-items: ${(props) => (props.$ar ? "flex-start" : "flex-start")};
    gap: ${(props) => (props.$ar ? "100px" : "10px")};
  }
  /* @media (max-width: 1417px) {
    padding-top: ${(props) => (props.$ar ? "40px" : "0px")};
    flex-direction: ${(props) => (props.$ar ? "column" : "row")};
    align-items: ${(props) => (props.$ar ? "center" : "flex-start")};
    gap: ${(props) => (props.$ar ? "48px" : "10px")};
  } */
  @media (max-width: 1235px) {
    padding-top: 40px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
  }
`;
export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 54px;
  background-color: transparent;
  justify-content: center;
  padding: 0px 40px;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    props.$ar ? "translateX(150%)" : "translateX(-150%)"};
  opacity: 0;
  @media (max-width: 1860px) {
    padding: ${(props) => (props.$ar ? "0px" : "0px 40px")};
    gap: ${(props) => (props.$ar ? "0px" : "54px")};
  }
  @media (max-width: 1235px) {
    padding-top: 0px;
    gap: 0px;
  }
  @media (max-width: 635px) {
    align-items: center;
  }
  @media (max-width: 400px) {
    opacity: 1 !important;
    transform: translateX(0) !important;
  }
`;
export const GiftIcon = styled.div`
  align-self: center;
  transform: ${(props) =>
    props.$ar ? "rotate(-18.884deg)" : "rotate(18.884deg)"};

  display: none;
  @media (max-width: 1860px) {
    /* display: ${(props) => (props.$ar ? "flex" : "none")}; */
    display: ${(props) => (props.$ar ? "flex" : "none")};
    opacity: ${(props) => (props.$ar ? "0 !important" : "0 !important")};
  }
  @media (max-width: 1235px) {
    opacity: ${(props) => (props.$ar ? "1 !important" : "1 !important")};
    display: flex;
  }
`;
export const H1 = styled.h1`
  color: #fff;
  user-select: none;
  font-family: "Avenir-Book";
  font-size: 64px;
  width: ${(props) => (props.$ar ? "unset" : "450px")};
  @media (max-width: 515px) {
    font-size: 50px;
    width: ${(props) => (props.$ar ? "unset" : "368px")};
  }
`;
export const H2 = styled.h1`
  color: #fff;
  user-select: none;
  font-family: "Avenir-Black";
  font-size: 64px;
  align-self: flex-end;
  margin-left: ${(props) => (props.$ar ? "unset" : "-200px")};
  @media (max-width: 515px) {
    font-size: 50px;
    margin-left: ${(props) => (props.$ar ? "unset" : "-180px")};
  }
`;

export const Text = styled.h2`
  user-select: none;
  width: 610px;
  color: #fff;
  font-family: "Avenir-Medium";
  font-size: 24px;
  line-height: 32px; /* 133.333% */
  @media (max-width: 1860px) {
    /* text-align: ${(props) => (props.$ar ? "center" : "left")}; */
    text-align: ${(props) => (props.$ar ? "right" : "left")};
  }
  @media (max-width: 1235px) {
    text-align: center;
  }
  @media (max-width: 635px) {
    width: 407px;
  }
  @media (max-width: 515px) {
    font-size: 20px;
  }
  @media (max-width: 420px) {
    width: 303px;

    font-size: 18px;
  }
  @media (max-width: 380px) {
    scale: 0.9;
  }
`;
export const HeaderWrapper = styled.header`
  background: #8a4b5e;
  z-index: 500;
  position: fixed;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  z-index: 10;
  flex-direction: row;
  align-items: center;
  gap: 21px;
  @media (max-width: 1860px) {
    /* justify-content: ${(props) => (props.$ar ? "center" : "flex-start")}; */
    justify-content: ${(props) => (props.$ar ? "flex-start" : "flex-start")};
    margin-top: ${(props) => (props.$ar ? "24px" : "0px")};
  }
  @media (max-width: 1235px) {
    justify-content: center;
    margin-top: 24px;
    padding: 0px 1rem;
    width: 100%;
  }
  @media (max-width: 360px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;
export const Button = styled.button`
  display: flex;
  flex-direction: row;
  user-select: none;
  align-items: center;
  border-radius: 30px;
  background: #f0f0f0;
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  min-width: 234px;
  min-height: 61px;
  justify-content: center;
  border: none;

  cursor: pointer;
  color: #8a4b5e;
  text-align: center;
  font-family: "Avenir-Medium";
  font-size: 22px;
  letter-spacing: 0.11px;
  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
  }
  @media (max-width: 515px) {
    min-width: 164.282px;
    font-size: 18px;
  }
  @media (max-width: 360px) {
    min-width: 144px;
    height: 51px;
    font-size: 16px;
  }
  @media (max-width: 360px) {
    min-width: 144px;
    height: 51px;
    font-size: 16px;
  }
`;
export const ButtonStroke = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 30px;
  border-radius: 30px;
  user-select: none;
  border: 1px solid #fff;
  background: rgba(240, 240, 240, 0);
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);

  min-width: 234px;
  min-height: 61px;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: "Avenir-Medium";
  font-size: 22px;

  letter-spacing: 0.11px;
  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
  }
  @media (max-width: 515px) {
    min-width: 164.282px;
    font-size: 18px;
  }
  @media (max-width: 360px) {
    min-width: 144px;
    height: 51px;
    font-size: 16px;
  }
`;
export const Rotate = styled.div`
  transform: ${(props) =>
    props.$ar ? "rotate(-18.884deg)" : "rotate(18.884deg)"};
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: -5px;
`;

export const SectionTwo = styled.div`
  width: 1440px;
  height: 100%;
  display: flex;

  align-items: center;
  align-self: center;
  justify-content: flex-end;
  gap: 19px;
  flex-direction: column;
  padding: 0px 28px 50px 28px;

  transition: all ease-in 0.4s;
  transform: ${(props) =>
    props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  mix-blend-mode: luminosity;

  @media (max-width: 1400px) {
    width: 100%;
    flex-wrap: wrap;
  }

  @media (max-width: 400px) {
    scale: 0.85;
    opacity: 1 !important;
    transform: translateX(0) !important;
  }
`;
export const Background = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgba(236, 195, 113, 0.05);
  @media (max-width: 1860px) {
    background: ${(props) => (props.$ar ? "#fff" : "rgba(236,195,113,0.05)")};
  }
  @media (max-width: 1235px) {
    background: #fff;
  }
`;
export const ImageDiv = styled.div`
  margin-top: -5rem;

  transition: all ease-in 0.4s;
  transform: translateY(150%);
  opacity: 0;

  @media (max-width: 400px) {
    opacity: 1 !important;
    transform: translateX(0) !important;
  }
`;
export const SectionThree = styled.div`
  width: 1440px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 57px 64px;
  gap: 48px;
  justify-content: center;
  align-items: center;
  @media (max-width: 1235px) {
    width: 100%;
    padding: 0;
  }
  @media (max-width: 700px) {
    height: 100%;
    justify-content: unset;
    padding: 50px 0px;
  }
`;
export const Bg2 = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgba(234, 67, 93, 0.05);
  @media (max-width: 1235px) {
    background: rgba(72, 72, 136, 0.05);
  }
`;
export const SectionSix = styled.div`
  width: 1440px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 131px 77px;
  gap: 33px;
  justify-content: center;
  align-items: center;
  @media (max-width: 1235px) {
    height: 100%;
    width: 100%;
    padding: 60px;
    justify-content: unset;
  }
`;
export const SectionFour = styled.div`
  width: 1440px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 57px 64px;
  gap: 48px;
  justify-content: center;
  align-items: center;
  @media (max-width: 700px) {
    height: 100%;
    justify-content: unset;
    padding: 50px 0px;
  }
`;
export const SectionFive = styled.div`
  width: 1440px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 57px 64px;
  background: #fff;
  gap: 45px;
  justify-content: center;
  align-items: center;
  @media (max-width: 1235px) {
    height: 100%;
    width: 100%;
    gap: 0px;
  }
`;
export const Sponsors = styled.div`
  display: flex;

  flex-direction: row;
  gap: 30px;
  width: 100%;
  justify-content: space-evenly;
  align-items: flex-end;
  flex-wrap: wrap;

  @media (max-width: 450px) {
    justify-content: center;
    align-items: center;
  }
`;

export const TextTrust = styled.h3`
  user-select: none;
  color: #0005;
  font-family: "Avenir-Black";
  text-transform: uppercase;
  font-size: 30px;
  letter-spacing: 0.067px;
  text-align: center;
  margin-bottom: 40px;

  @media (max-width: 650px) {
    font-size: 20px;
  }
`;
export const Text2 = styled.h3`
  user-select: none;
  color: #0005;
  font-family: "Avenir-Black";
  text-transform: uppercase;
  font-size: 30px;
  letter-spacing: 0.067px;
  text-align: center;
  margin-bottom: 40px;

  @media (max-width: 1235px) {
    margin-bottom: 50px;
  }
  @media (max-width: 860px) {
    margin-top: 76px;
  }
  @media (max-width: 650px) {
    font-size: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
`;
export const Line2 = styled.div`
  width: 44px;
  position: absolute;
  left: 50.2%;
  height: 100%;
  background: #ea435d;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
`;
export const Line = styled.div`
  width: 44px;
  left: 48.48%;
  position: absolute;
  height: 100%;
  background: #ecc371;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
`;
export const Title = styled.h3`
  color: #000;
  font-family: "Avenir-Heavy";
  font-size: 64px;
  width: 622px;
  letter-spacing: 0.067px;
  @media (max-width: 1235px) {
    text-align: center;
  }
  @media (max-width: 650px) {
    width: 100%;
    font-size: 40px;
  }
  @media (max-width: 340px) {
    font-size: 32px;
  }
  @media (max-height: 1090px) {
    font-size: 48px;
  }
  @media (max-height: 750px) {
    font-size: 40px;
  }
  @media (max-height: 650px) {
    width: unset;
  }
`;

export const Span = styled.span`
  width: 622px;
  color: #000;
  font-family: "Avenir-Book";
  font-size: 28px;
  line-height: 32px; /* 114.286% */
  @media (max-width: 1235px) {
    text-align: center;
  }
  @media (max-width: 650px) {
    width: 362px;
    font-size: 20px;
  }
  @media (max-width: 370px) {
    width: 283px;

    font-size: 18px;
  }
  @media (max-height: 1090px) {
    font-size: 20px;
    line-height: normal;
  }
  @media (max-height: 1090px) {
    font-size: 20px;
  }
  @media (max-height: 650px) {
    width: unset;
  }
`;
export const TitleFive = styled.h2`
  transition: all ease-in 0.4s;

  opacity: 0;
  color: #000;
  text-align: center;
  font-family: "Avenir-Heavy";
  font-size: 64px;
  margin-bottom: 63px;

  letter-spacing: 0.067px;
  @media (max-width: 650px) {
    width: 100%;
    font-size: 32px;
  }
  @media (max-width: 400px) {
    opacity: 1 !important;
  }
  @media (max-width: 340px) {
    font-size: 28px;
  }
`;
export const RowStory = styled.main`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 70px;
  width: 100%;
  @media (max-width: 1235px) {
    flex-direction: column;
  }
  @media (max-height: 650px) {
    flex-direction: row;
    padding: 0 40px;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: 70px;
  width: 100%;
  @media (max-width: 1235px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-height: 650px) {
    flex-direction: row;
    padding: 0 40px;
  }
`;
export const StyledRow = styled.main`
  display: flex;

  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: 70px;
  width: 100%;

  @media (max-width: 1235px) {
    flex-direction: column;
    align-items: center;
    width: unset;
  }
`;

export const Column = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  @media (max-width: 400px) {
    opacity: 1 !important;
    transform: translateX(0) !important;
  }
`;

export const StyledColumn = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    !props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  @media (max-width: 400px) {
    opacity: 1 !important;
    transform: translateX(0) !important;
  }
`;

export const FadeColumn = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  transition: all ease-in 0.4s;

  opacity: 0;
  @media (max-width: 1200px) {
    transform: ${(props) =>
      props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  }
  @media (max-width: 400px) {
    opacity: 1 !important;
    transform: translateX(0) !important;
  }
`;

export const H5 = styled.span`
  color: #000;
  height: 82px;
  width: 323px;
  text-align: center;
  font-family: "Avenir-Heavy";
  font-size: 30px;
  letter-spacing: 0.067px;
  margin-top: 46px;
  @media (max-width: 1235px) {
    height: unset;
    margin-top: 0px;
  }
  @media (max-width: 420px) {
    width: 100%;
  }
  @media (max-width: 340px) {
    font-size: 28px;
  }
`;

export const TextFive = styled.span`
  width: 390px;
  color: #000;
  text-align: center;
  font-family: "Avenir-Book";
  font-size: 22px;
  line-height: 32px; /* 145.455% */
  @media (max-width: 650px) {
    width: 362px;
    font-size: 20px;
  }
  @media (max-width: 340px) {
    width: 283px;

    font-size: 18px;
  }
`;
export const TitleSix = styled.span`
  color: #000;
  user-select: none;
  text-align: center;
  font-family: "Avenir-Black";
  font-size: 75px;

  letter-spacing: 0.067px;
`;
export const ButtonRed = styled.button`
  width: 311px;
  height: 70px;
  flex-shrink: 0;
  user-select: none;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  font-family: "Avenir-Heavy";
  font-size: 30px;

  letter-spacing: 0.067px;
  background: #ea435d;
  border: none;
`;

export const TextSix = styled.span`
  color: #000;
  text-align: center;
  font-family: "Avenir-Book";
  font-size: 22px;
  line-height: 32px; /* 145.455% */
`;
export const ColumnSix = styled.div`
  flex-direction: column;
  display: flex;
  gap: 22px;
  align-items: center;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    !props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  @media (max-width: 400px) {
    opacity: 1 !important;
    transform: translateX(0) !important;
  }
`;
export const StyledColumnSix = styled.div`
  flex-direction: column;
  display: flex;
  gap: 22px;
  align-items: center;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  @media (max-width: 400px) {
    opacity: 1 !important;
    transform: translateX(0) !important;
  }
`;

export const RowSix = styled.main`
  flex-direction: row;
  display: flex;
  gap: 200px;
  align-items: center;
  @media (max-width: 865px) {
    flex-direction: column;
    gap: 50px;
  }
`;

export const RightSide = styled.div`
  display: flex;
  position: relative;
  padding-right: 40px;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  @media (max-width: 1860px) {
    scale: ${(props) => (props.$ar ? "0.85" : "1")};
    padding-right: ${(props) => (props.$ar ? "0px" : "40px")};
  }
  @media (max-width: 1235px) {
    scale: 0.85;
    padding-right: 0px;
  }
  @media (max-width: 400px) {
    opacity: 1 !important;
    transform: translateX(0) !important;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
  align-self: center;
  justify-content: flex-end;

  transition: all ease-in 0.4s;
  transform: ${(props) =>
    !props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;

  @media (max-width: 1235px) {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  @media (max-height: 1090px) {
    gap: 20px;
  }
  @media (max-width: 400px) {
    opacity: 1 !important;
    transform: translateX(0) !important;
  }
`;

export const TextWrapperSectionThree = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: flex-end;
  gap: 48px;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  @media (max-width: 1235px) {
    text-align: center;

    justify-content: center;
  }
  @media (max-height: 1090px) {
    gap: 20px;
  }
  @media (max-width: 400px) {
    opacity: 1 !important;
    transform: translateX(0) !important;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;

  @media (max-width: 1860px) {
    align-items: ${(props) => (props.$ar ? "center" : "flex-start")};
    /* justify-content: ${(props) => (props.$ar ? "center" : "flex-start")}; */
    justify-content: ${(props) => (props.$ar ? "flex-start" : "flex-start")};
    margin-bottom: ${(props) => (props.$ar ? "32px" : "0px")};
  }
  @media (max-width: 1235px) {
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
  }
  @media (max-width: 380px) {
    scale: 0.9;
    margin-bottom: 24px;
  }
`;

export const RotateGiftIcon = styled.div`
  transform: ${(props) =>
    props.$ar ? "rotate(-18.884deg)" : "rotate(18.884deg)"};
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  align-self: flex-end;
  margin-left: -5px;
  position: relative;
  @media (max-width: 1235px) {
    display: none;
  }
`;
export const IconContainer = styled.div`
  position: absolute;
  right: -10px;
  z-index: 402;
  top: 30%;
  transform: translateY(-50%);
  @media (max-width: 1860px) {
    right: ${(props) => (props.$ar ? "-70px" : "-10px")};
    transform: ${(props) =>
      props.$ar ? "rotate(-10deg)" : "translateY(-50%)"};
  }
  @media (max-width: 1235px) {
    right: -70px;
    transform: rotate(-10deg);
  }
`;
export const BellContainer = styled.div`
  position: absolute;
  left: -160px;
  top: 52%;
  transform: translateY(-50%);
  z-index: 402;
  @media (max-width: 1860px) {
    top: 45%;
  }
  @media (max-width: 1418px) {
    left: -90px;
  }

  @media (max-width: 340px) {
    left: -65px;
  }
`;

export const ContainerBox2 = styled.div`
  position: absolute;
  left: -95px;
  top: 8.5%;
  transform: translateY(-50%);
  z-index: 402;
  @media (max-width: 1860px) {
    display: ${(props) => (props.$ar ? "flex" : "flex")};
  }
  @media (max-width: 1235px) {
    display: none;
  }
`;
export const CommentImg = styled(ProgressiveImg)`
  @media (max-width: 430px) {
    width: 264.367px;
    height: 378.576px;
  }
  @media (max-width: 400px) {
    scale: 0.9;
  }
`;
export const ContainerBox3 = styled.div`
  position: absolute;
  left: -95px;
  top: 53%;
  transform: translateY(-50%);
  z-index: 401;
  display: none;
  @media (max-width: 1860px) {
    display: ${(props) => (props.$ar ? "none" : "none")};
    top: ${(props) => (props.$ar ? "5%" : "3%")};
  }
  @media (max-width: 1235px) {
    display: flex;
  }
  @media (max-width: 430px) {
    transform: translateY(0);
    left: -65px;
  }
  @media (max-width: 340px) {
    left: -35px;
  }
`;
export const ContainerBox1 = styled.div`
  position: absolute;
  height: 73px;
  left: -170px;
  bottom: -2%;
  transform: translateY(-50%);
  z-index: 402;
  @media (max-width: 1860px) {
    display: ${(props) => (props.$ar ? "flex" : "flex")};
    left: ${(props) => (props.$ar ? "-130px" : "-170px")};
    bottom: ${(props) => (props.$ar ? "2%" : "-2%")};
  }
  @media (max-width: 1235px) {
    display: none;
  }
`;
export const ImageContainer1 = styled.div`
  padding-right: 100px;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  @media (max-width: 1235px) {
    padding-right: 0px;
  }
  @media (max-width: 400px) {
    opacity: 1 !important;
    transform: translateX(0) !important;
  }
`;
export const ImageContainer2 = styled.div`
  padding-left: 100px;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    !props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  @media (max-width: 1235px) {
    display: none;
  }
  @media (max-width: 400px) {
    opacity: 1 !important;
    transform: translateX(0) !important;
  }
`;
export const ImageContainer3 = styled.div`
  display: none;
  transition: all ease-in 0.4s;
  transform: ${(props) =>
    !props.$ar ? "translateX(-150%)" : "translateX(150%)"};
  opacity: 0;
  @media (max-width: 1235px) {
    display: flex;
  }
  @media (max-width: 400px) {
    opacity: 1 !important;
    transform: translateX(0) !important;
  }
`;

export const StoryImage = styled(ProgressiveImg)`
  width: 415px;
  height: 622px;
  border-radius: 78px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.3);
  align-self: center;
  user-select: none;
  @media (max-height: 1090px) {
    width: 257px;
    height: 361px;
  }
  @media (max-height: 750px) {
    width: 207px;
    height: 311px;
    border-radius: 58px;
  }
  @media (max-height: 650px) {
    width: 157px;

    height: 261px;
    aspect-ratio: auto;
    border-radius: 38px;
  }
  @media (max-width: 430px) {
    width: 264.37px;
    height: 396.216px;
    flex-shrink: 0;
  }
`;

export const StyledImage = styled(ProgressiveImg)`
  user-select: none;
  z-index: 400;
  border-radius: 12px;
  box-shadow: 0px 4px 80px 20px rgba(0, 0, 0, 0.24);
`;
export const StyledImageDashboard = styled(ProgressiveImg)`
  user-select: none;
  z-index: 400;
  border-radius: 78px;
  box-shadow: 0px 4px 80px 20px rgba(0, 0, 0, 0.24);
  @media (max-width: 1860px) {
    /* height: ${(props) => (props.$ar ? "426px" : "595px")};
    width: ${(props) => (props.$ar ? "298px" : "415px")}; */
  }
  @media (max-width: 1417px) {
    height: 426px;
    width: 298px;
  }
  @media (max-width: 430px) {
    width: 264.367px;
    height: 378.576px;
  }
  @media (max-width: 400px) {
    scale: 0.9;
  }
`;

export const SectionTwoContainer = styled.section`
  width: 100%;

  display: flex;
  justify-content: center;

  flex-direction: column;
  align-items: center;

  height: 100%;

  /* @media (max-width: 1400px) {
    height: 100%;
  } */
`;

export const SponsorImgFirst = styled.img`
  mix-blend-mode: luminosity;

  margin-bottom: -43px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease-in-out;
  &:hover {
    scale: 1.1;
  }
`;
export const SponsorImg = styled(SponsorImgFirst)`
  margin-bottom: 0px;
`;
export const DashboardImage = styled(ProgressiveImg)`
  z-index: 10;
  user-select: none;
  order: -1;
  align-self: center;
  justify-content: center;
  @media (max-width: 1400px) {
    width: 100%;
    height: auto;
  }
`;
export const SectionThreeContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgba(72, 72, 136, 0.05);
`;
export const SectionFiveContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const StyledRowSix = styled(RowSix)`
  margin-top: 33px;
`;
export const GoToTop = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #8a4b5e88;
  position: fixed;
  bottom: 40px;
  cursor: pointer;
  right: 40px;
  border: 1.5px solid #fff;
  &:hover {
    background-color: #8a4b5e;
  }
`;

export const SplineContainer = styled.div`
  transform: ${(props) => (props.$ar ? "rotateY(180deg) !important" : "none")};
  position: absolute;
  right: ${(props) => (props.$ar ? "unset" : "0px")};
  left: ${(props) => (!props.$ar ? "unset" : "0px")};
  height: 100rem;

  width: 144rem;
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  margin-top: ${(props) => (props.$ar ? "-33.2rem" : "-26.5rem")};
  margin-left: ${(props) => (props.$ar ? "-63.2rem" : "unset")};
  margin-right: ${(props) => (!props.$ar ? "-53rem" : "unset")};

  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));

  @media (max-width: 1235px) {
    display: none;
  }
`;

export const Click = styled.div`
  position: absolute;

  z-index: 20;
  width: 4.2rem;
  height: 4.2rem;

  cursor: pointer;

  top: 35.5rem;
  right: 69rem;
  @media (max-width: 1235px) {
    display: none;
  }
`;
