import axiosApiClient from "..";
import { handleError } from "../../utils/errors";
const BASE_URL = "business/instant-quotation";

class InstantQuotationService {
  postInstantQuotation = async ({
    itemId,
    quantity,
    branding,
    companyName,
    marketplaceSubcategoryIds,
  }) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/`, {
        itemId,
        quantity,
        branding,
        companyName,
        marketplaceSubcategoryIds,
      });
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while sending instant quotation.");
    }
  };

  postQuotationWithMultipleItems = async (body) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/generate-multiple`,
        body
      );
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while sending quotation from cart page."
      );
    }
  };
}

const instantQuotationService = new InstantQuotationService();
export default instantQuotationService;
