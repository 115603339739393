import React from "react";
import { Text } from "./SkeletonMarketplaceSortLoader.styles";

const SkeletonMarketplaceSortLoader = () => {
  const data = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
  ];

  return (
    <>
      {data.map((x) => (
        <Text key={x.id} />
      ))}
    </>
  );
};

export default SkeletonMarketplaceSortLoader;
