import React, { useEffect } from "react";
import {
  ContentContainer,
  TableColumn,
  TableHeader,
  TableRow,
  TableValue,
  TableSmallRow,
  TableColumnFirst,
  ProceedContainer,
  ButtonsContainer,
  StyledButton,
  PopupTitle,
  Content,
} from "../Steps.styles";

import { useTranslation } from "react-i18next";
import {
  selectedCreateCouponLoading,
  selectedEditCouponLoading,
  selectedNewCoupon,
  setStatus,
} from "../../../store/slices/coupon/couponSlice";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import AppLoader from "../../AppLoader";

const CreateEditStep3 = ({ onClickNo, onClickYes }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const isEditCoupon = location.pathname.includes("edit-coupon");
  const data = useSelector(selectedNewCoupon);
  const edit = useSelector(selectedEditCouponLoading);
  const create = useSelector(selectedCreateCouponLoading);
  const date = new Date(data.expirationDate);

  const getLoading = () => {
    if (isEditCoupon) {
      return edit;
    } else {
      return create;
    }
  };

  useEffect(() => {
    if (!isEditCoupon) {
      dispatch(setStatus("ACTIVE"));
    }
  }, [dispatch, isEditCoupon]);

  return (
    <Content>
      <ContentContainer>
        <TableRow>
          <TableColumnFirst>
            <TableHeader>{t("coupon")}</TableHeader>
            <TableValue>{data.name}</TableValue>
          </TableColumnFirst>
          <TableColumn>
            <TableHeader>{t("expiry-date")}</TableHeader>
            <TableValue>{date.toLocaleDateString()}</TableValue>
          </TableColumn>

          <TableColumn>
            <TableHeader>{t("usage")}</TableHeader>
            <TableValue>{data.maxUsage}</TableValue>
          </TableColumn>
          <TableColumn>
            <TableHeader>{t("type")}</TableHeader>
            <TableValue>{data.type}</TableValue>
          </TableColumn>
          {data.value > 0 && (
            <TableColumn>
              <TableHeader>{t("value")}</TableHeader>
              <TableValue>{data.value}%</TableValue>
            </TableColumn>
          )}
          <TableColumn>
            <TableHeader>{t("frequency")}</TableHeader>
            <TableValue>{data.frequency}</TableValue>
          </TableColumn>
          <TableColumn>
            <TableHeader>{t("status")}</TableHeader>
            <TableValue>{data.status}</TableValue>
          </TableColumn>
          <TableColumn>
            <TableHeader>{t("product")}</TableHeader>
            <TableSmallRow>
              {data.products.map((product, index) => (
                <TableValue key={index}>{product.name}</TableValue>
              ))}
            </TableSmallRow>
          </TableColumn>
        </TableRow>
      </ContentContainer>
      {!getLoading() ? (
        <ProceedContainer>
          <PopupTitle>{t("do-you-want-to-proceed")}</PopupTitle>
          <ButtonsContainer>
            <StyledButton onClick={onClickNo}>{t("no")}</StyledButton>
            <StyledButton onClick={onClickYes}>{t("yes")}</StyledButton>
          </ButtonsContainer>
        </ProceedContainer>
      ) : (
        <ProceedContainer>
          <PopupTitle>
            {isEditCoupon ? t("editing-coupon") : t("creating-coupon")}
          </PopupTitle>
          <AppLoader />
        </ProceedContainer>
      )}
    </Content>
  );
};

export default CreateEditStep3;
