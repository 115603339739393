import { styled } from "styled-components";

export const FooterContainer = styled.footer`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;
export const Image = styled.img`
  user-select: none;
`;
export const Download = styled.div`
  height: 122px;
  width: 1440px;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0px 46px;
  @media (max-width: 1235px) {
    flex-direction: column;
    width: unset;
    height: 100%;
    padding: 20px 0px;
    gap: 20px;
  }
`;
export const StyledDiv = styled.div`
  background-color: #8a4b5e;
  width: 100%;
  justify-content: center;
  display: flex;
`;
export const Bg = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  background-color: #f6f6f9;
`;
export const Footer = styled.div`
  width: 1440px;

  // height: 276px;
  padding: ${(props) =>
    props.$ar ? "34px 34px 34px 0px" : "34px 0px 34px 34px"};

  gap: 30px;
  display: flex;
  flex-direction: column;
  @media (max-width: 720px) {
    padding: 34px 20px;
  }
  @media (max-width: 375px) {
    padding: ${(props) =>
      props.$ar ? "34px 5px 34px 0px" : "34px 0px 34px 5px"};
  }
`;

export const TitleDownload = styled.span`
  user-select: none;
  color: #8a4b5e;
  font-family: "Avenir-Black";
  font-size: 40px;
  @media (max-width: 430px) {
    font-size: 30px;
  }
`;
export const TextDownload = styled.span`
  color: #8a4b5e;
  font-family: "Avenir-Book";
  font-size: 16px;
  letter-spacing: 0.067px;
  user-select: none;
  font-style: italic;
  @media (max-width: 430px) {
    width: 262px;
    text-align: center;
  }
`;

export const StoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  gap: 10px;
  @media (max-width: 1235px) {
    margin-left: 0;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  @media (max-width: 1235px) {
    flex-direction: column;
  }
`;
export const TextFooter = styled.h3`
  color: #fff;
  user-select: none;
  font-family: "Avenir-Medium";
  width: 628px;
  color: #fff;
  font-size: 25px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.067px;
  @media (max-width: 680px) {
    width: unset;
    font-size: 16px;
  }
  @media (max-width: 430px) {
    width: 333px;
  }
  @media (max-width: 340px) {
    width: unset;
  }
`;
export const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 150px;
  @media (max-width: 1235px) {
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;
  }
`;
export const FooterColumn = styled.div`
  display: flex;
  gap: 19px;
  flex-direction: column;
`;
export const ColumTitle = styled.span`
  color: #fff;
  margin-bottom: 13px;
  user-select: none;
  font-family: "Avenir-Black";
  font-size: 16px;
  letter-spacing: 0.067px;
`;
export const ColumnLink = styled.span`
  color: #fff;
  font-family: "Avenir-Roman";
  font-size: 14px;
  user-select: none;

  cursor: pointer;
  letter-spacing: 0.067px;
  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }
`;
export const ColumnLinkHref = styled.a`
  color: #fff;
  font-family: "Avenir-Roman";
  font-size: 14px;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: 0.067px;
  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }
`;
export const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: flex-end;

  gap: 10rem;
  @media (max-width: 1380px) {
    gap: 5rem;
  }
  @media (max-width: 635px) {
    gap: 40px;

    justify-content: flex-start;

    flex-wrap: wrap;
  }
`;
export const MediaContainer = styled.div`
  padding-bottom: 9px;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

export const Media = styled.div`
  display: flex;
  flex-direction: row;
  gap: 9px;
  align-items: center;
`;

export const CopyText = styled.span`
  color: #fff;
  font-family: "Avenir-Book";
  font-size: 10px;
  letter-spacing: 0.067px;
  user-select: none;
`;

export const CopyContainer = styled.div`
  display: flex;
  margin-top: 9px;
  flex-direction: row;
  align-items: center;
`;
