import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { signUp } from "../../../store/slices/auth/asyncThunks";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer";
import {
  ButtonContainer,
  Form,
  SignupButton,
  ScreenContent,
  MainContainer,
  Title,
  TextDiv,
  CreateText,
  Message,
  SuccessImg,
  HeaderWrapper,
} from "./SignupPage.style";
import { selectCreateAccountPending } from "../../../store/slices/auth/slice";
import { selectedLocale } from "../../../store/slices/common/slice";
import Loader from "../../../components/Loader/Loader";
import { isValidEmail, isValidPassword } from "../../../utils/utils";
import Success from "../../../assets/images/success.png";
import { ReactComponent as Loading } from "../../../assets/icons/loader-white.svg";
import SignUpFormComponent from "./SignUpFormComponent";
import { signUpRecipient } from "../../../store/slices/authRecipient/asyncThunks";
import { getBusinessRulesVariables } from "../../../store/slices/common/asyncThunks";
import logService from "../../../api/services/log";
import openToast from "../../../utils/toast";
import {
  selectBusinessUserInfo,
  setBusinessUserInfo,
} from "../../../store/slices/businessUser/slice";

const SignupPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [visibility2, setVisibility2] = useState(false);
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(selectedLocale);
  const continueLoading = useSelector(selectCreateAccountPending);
  const fullName = `${firstName}  ${lastName}`;
  const [tab, setTab] = useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const inviteToken = queryParams.get("inviteToken");
  const { loggedIn } = useSelector(selectBusinessUserInfo);
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";

  const toastId = useRef(null);

  const [checkValidation, setCheckValidation] = useState(false);

  const handleSignup = () => {
    setCheckValidation(true);
    if (inviteToken) {
      if (validateForm) {
        dispatch(signUpRecipient({ email, fullName, password, inviteToken }))
          .unwrap()
          .then((response) => {
            toast.success(t("account-created-successfully"));
            navigate("/admin-portal/dashboard");
            const { businessUser, accessToken } = response;
            // dispatch(setAccessToken({ accessToken: accessToken }));
            dispatch(setBusinessUserInfo(businessUser));
            dispatch(getBusinessRulesVariables());
          })
          .catch((error) => {
            openToast(toastId, "sign-up", "error", t(error.message));
          });
      }
    } else {
      if (validateForm()) {
        dispatch(
          signUp({
            email,
            password,
            fullName,
            companyName,
          })
        )
          .unwrap()
          .then((response) => {
            toast.success(t("account-created-successfully"));
            navigate("/admin-portal/dashboard");

            const { businessUser, accessToken } = response;
            // dispatch(setAccessToken({ accessToken: accessToken }));
            dispatch(setBusinessUserInfo(businessUser));
            dispatch(getBusinessRulesVariables());
          })
          .catch(async (error) => {
            openToast(toastId, "sign-up", "error", t(error.message));
            await logService.createErrorLog({
              type: "bp_sign_in_error",
              data: JSON.stringify({
                error: error.message,
                email,
                fullName,
                companyName,
              }),
            });
          });
      }
    }
  };

  const validateForm = () => {
    let formIsValid = true;

    if (!inviteToken) {
      if (!companyName) {
        openToast(toastId, "sign-up", "error", t("company-name-is-required"));
        formIsValid = false;
        return;
      }
    }

    if (!firstName) {
      openToast(toastId, "sign-up", "error", t("first-name-is-required"));
      formIsValid = false;
      return;
    }

    if (!lastName) {
      openToast(toastId, "sign-up", "error", t("last-name-is-required"));
      formIsValid = false;
      return;
    }
    if (!email) {
      openToast(toastId, "sign-up", "error", t("email-is-required"));
      formIsValid = false;
      return;
    } else if (!isValidEmail(email)) {
      openToast(toastId, "sign-up", "error", t("invalid-email-format"));

      formIsValid = false;
      return;
    }

    if (!password) {
      openToast(toastId, "sign-up", "error", t("password-is-required"));
      formIsValid = false;
      return;
    } else if (!isValidPassword(password)) {
      openToast(toastId, "sign-up", "info", t("password-contain"));
      formIsValid = false;
      return;
    }

    if (!confirmPassword) {
      openToast(toastId, "sign-up", "error", t("confirm-password-is-required"));
      formIsValid = false;
      return;
    } else if (confirmPassword !== password) {
      openToast(toastId, "sign-up", "error", t("password-dont-match"));
      formIsValid = false;
      return;
    }

    return formIsValid;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSignup();
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [handleKeyPress]);

  const [headerVisible, setHeaderVisible] = useState(true);
  const [lastScrollUpY, setLastScrollUpY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(null);
  const lastScrollTopRef = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      const lastScrollTop = lastScrollTopRef.current;

      if (currentScrollTop === 0) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
        setScrollDirection(null);
        lastScrollTopRef.current = currentScrollTop;
        return;
      }

      if (currentScrollTop > lastScrollTop) {
        setScrollDirection("down");
        setLastScrollUpY(currentScrollTop);
      } else {
        setScrollDirection("up");
      }

      if (scrollDirection === "up" && currentScrollTop < lastScrollUpY - 350) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
      }

      if (
        scrollDirection === "down" &&
        currentScrollTop > lastScrollUpY + 350
      ) {
        setHeaderVisible(false);
      }

      lastScrollTopRef.current = currentScrollTop;
    };

    const handleScrollDebounced = () => {
      requestAnimationFrame(handleScroll);
    };

    window.addEventListener("scroll", handleScrollDebounced);

    return () => {
      window.removeEventListener("scroll", handleScrollDebounced);
    };
  }, [scrollDirection]);

  return (
    <>
      <MainContainer>
        {!loggedIn ? (
          <HeaderWrapper
            style={{
              opacity: headerVisible ? 1 : 0,
              transition: "opacity 0.5s",
            }}
          >
            <Header
              showNav
              color
              hideSearch
              showLogin
              showSignUp
              display={headerVisible}
            />
          </HeaderWrapper>
        ) : (
          <HeaderWrapper>
            <Header showNav color hideSearch showLogin showSignUp />
          </HeaderWrapper>
        )}
        <ScreenContent>
          <Title>{t("welcome-to-ygii")}</Title>
          {selectedLanguage === "en" ? (
            <TextDiv>
              <CreateText>{t("already-have-an-account")}</CreateText>
              <Link to="/login" className="signup">
                {t("login")}
              </Link>
            </TextDiv>
          ) : (
            <TextDiv>
              <Link to="/login" className="signup">
                {t("login")}
              </Link>
              <CreateText>{t("already-have-an-account")}</CreateText>
            </TextDiv>
          )}
          {tab === 0 && (
            <SignUpFormComponent
              ar={ar}
              checkValidation={checkValidation}
              companyName={companyName}
              setCompanyName={setCompanyName}
              setFirstName={setFirstName}
              firstName={firstName}
              lastName={lastName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
              visibility={visibility}
              visibility2={visibility2}
              confirmPassword={confirmPassword}
              password={password}
              setPassword={setPassword}
              setConfirmPassword={setConfirmPassword}
              setVisibility={setVisibility}
              setVisibility2={setVisibility2}
              inviteToken={inviteToken}
            />
          )}

          {tab === 1 && (
            <Form>
              <Message>{t("a-verification-mail")}</Message>
              <SuccessImg src={Success} alt="success-img" />
            </Form>
          )}
          {tab === 0 && (
            <>
              {!continueLoading ? (
                <ButtonContainer>
                  <SignupButton onClick={handleSignup}>
                    {t("create-your-free-account")}
                  </SignupButton>
                </ButtonContainer>
              ) : (
                <ButtonContainer>
                  <SignupButton disabled>
                    <Loading height={30} />
                  </SignupButton>
                </ButtonContainer>
              )}
            </>
          )}
        </ScreenContent>
      </MainContainer>
      {continueLoading && <Loader />}
      <Footer />
    </>
  );
};

export default SignupPage;
