import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MainContainer,
  Column,
  ContentContainer,
  SectionTitle,
  SeeAllText,
  SectionDiv,
  TitleContainer,
  ItemContainer,
  ButtonBack,
  IconRotate,
  BackContainer,
  ButtonContinue,
  Container,
  StepsContainer,
  BudgetContainer,
  LoadingText,
  BudgetTitle,
  BudgetInput,
  EmptyDataText,
  EmptyDataContainer,
  HeaderWrapper,
  StyledButton,
  ButtonsContainer,
  FooterRow,
} from "./GiftsPage.styles";

import {
  getAllGiftItems,
  getExploreItemsBySection,
  getCategories,
  findRecipientsWishlists,
} from "../../../store/slices/gift/asyncThunks";
import ContentHeader from "../utils/ContentHeader";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../../assets/icons/arrow-down.svg";
import { priceFormatter, subtractVAT } from "../../../utils/utils";
import {
  exploreItemsData,
  getSectionItemsLoader,
  selectRecipientsWithWishlists,
  selectRecipientsWithWishlistsLoading,
} from "../../../store/slices/gift/slice";
import { useTranslation } from "react-i18next";
import {
  removeGift,
  resetGifts,
  addGifts,
  selectGifts,
  giftCart,
  selectRecipients,
  addBudget,
  setBiggestPrice,
  resetGiftsAfterBudget,
} from "../../../store/slices/sendGifts/slice";
import Steps from "../../../components/Steps/Steps";
import debounce from "lodash.debounce";
import {
  selectBusinessUserInfo,
  selectCompanyInfo,
} from "../../../store/slices/businessUser/slice";
import {
  selectBusinessRulesVariables,
  selectedLocale,
} from "../../../store/slices/common/slice";
import GiftItemCard from "../../../components/GiftItemCard/GiftItemCard";
import SkeletonGASLoader from "../../../components/SkeletonLoader/SkeletonGASLoader/SkeletonGASLoader";
import Header from "../../../components/Header/Header";
import openToast from "../../../utils/toast";
import CategoryBar from "../../../components/CategoryBar/CategoryBar";
import { ReactComponent as ChargeWallet } from "../../../assets/icons/charge.svg";
import ChargeWalletModal from "../../../components/ChargeWalletModal/ChargeWalletModal";
import { toast } from "react-toastify";
import { setChargeWalletAmount } from "../../../store/slices/billing/slice";

const GiftsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(exploreItemsData);
  const selectedItem = useSelector(selectGifts);
  const sectionLoading = useSelector(getSectionItemsLoader);
  const [activeStep, setActiveStep] = useState(2);
  const [budget, setBudget] = useState(0);
  const selectCompany = useSelector(selectCompanyInfo);
  const selectRecipientsData = useSelector(selectRecipients);
  const recipientsWithWishlist = useSelector(selectRecipientsWithWishlists);
  const [budgetValueInput, setBudgetValueInput] = useState(0);

  const businessRulesVariables = useSelector(selectBusinessRulesVariables);
  const findRecipientsWishlistsLoading = useSelector(
    selectRecipientsWithWishlistsLoading
  );
  const toastId = useRef(null);
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";

  useEffect(() => {
    dispatch(
      getAllGiftItems({
        page: 1,
        order: "DESC",
        sortBy: "CreatedAt",
        search: "",
      })
    );
    dispatch(
      getExploreItemsBySection({
        page: 1,
        sectionId: 1,
        order: "DESC",
        sortBy: "CreatedAt",
        search: "",
      })
    );
    dispatch(
      getExploreItemsBySection({
        page: 1,
        sectionId: 2,
        order: "DESC",
        sortBy: "CreatedAt",
        search: "",
      })
    );
    dispatch(
      getExploreItemsBySection({
        page: 1,
        sectionId: 3,
        order: "DESC",
        sortBy: "CreatedAt",
        search: "",
      })
    );
    dispatch(
      getExploreItemsBySection({
        page: 1,
        sectionId: 4,
        order: "DESC",
        sortBy: "CreatedAt",
        search: "",
      })
    );
    dispatch(getCategories());
    if (selectRecipientsData.length > 0) {
      dispatch(
        findRecipientsWishlists({
          recipients: selectRecipientsData,
          searchTerm: "",
        })
      );
    }
  }, [dispatch, selectRecipientsData]);

  const changeHandler = useCallback(
    (text) => {
      const searchTerm =
        text === ""
          ? ""
          : subtractVAT(
              text,
              businessRulesVariables.itemPurchaseVAT
            ).toString();
      if (searchTerm !== "") {
        setBudgetValueInput(searchTerm);
      } else {
        setBudgetValueInput(0);
      }

      dispatch(
        getExploreItemsBySection({
          page: 1,
          sectionId: 1,
          order: "DESC",
          sortBy: "CreatedAt",
          search: searchTerm,
        })
      );
      dispatch(
        getExploreItemsBySection({
          page: 1,
          sectionId: 2,
          order: "DESC",
          sortBy: "CreatedAt",
          search: searchTerm,
        })
      );
      dispatch(
        getExploreItemsBySection({
          page: 1,
          sectionId: 3,
          order: "DESC",
          sortBy: "CreatedAt",
          search: searchTerm,
        })
      );
      dispatch(
        getExploreItemsBySection({
          page: 1,
          sectionId: 4,
          order: "DESC",
          sortBy: "CreatedAt",
          search: searchTerm,
        })
      );
      if (selectRecipientsData.length > 0) {
        dispatch(
          findRecipientsWishlists({
            recipients: selectRecipientsData,
            searchTerm: searchTerm,
          })
        );
      }
    },
    [businessRulesVariables.itemPurchaseVAT, dispatch, selectRecipientsData]
  );

  const debouncedChangeHandler = debounce(changeHandler, 500);

  const handleChange = (event) => {
    const sanitizedValue = event.target.value.replace(/[^0-9]/g, "");
    debouncedChangeHandler(sanitizedValue);
  };

  const prices = Array.isArray(selectedItem)
    ? selectedItem?.map((item) => item.price)
    : [];
  const biggestPrice = Math.max(...prices);

  useEffect(() => {
    if (
      budgetValueInput === 0 &&
      selectRecipientsData[0]?.user &&
      recipientsWithWishlist[0]?.items?.length > 0 &&
      selectedItem.length > 1
    ) {
      dispatch(resetGiftsAfterBudget());
      setBudgetValueInput(0);
    }
  }, [
    budgetValueInput,
    dispatch,
    recipientsWithWishlist,
    selectRecipientsData,
    selectedItem,
  ]);

  useEffect(() => {
    dispatch(setBiggestPrice(biggestPrice));
    setBudget(biggestPrice);
  }, [biggestPrice, dispatch]);

  const handleSeeAllClick = (sectionId) => {
    navigate(`/admin-portal/section-items/${sectionId}`);
  };

  const handleItemClick = (id) => {
    navigate(`/admin-portal/item-detail/${id}`);
  };

  const addToItemCartWithWishList = (item) => {
    let foundItem = false;

    selectedItem.forEach((data) => {
      if (data.id === item.id) {
        const itemSubcategories = data.subcategory?.map(
          (subcategory) => subcategory.valueId
        );
        const addedItemSubcategories = item.subcategoryItems?.map(
          (subcategory) => subcategory.valueId
        );

        if (
          itemSubcategories?.sort().join(",") ===
          addedItemSubcategories?.sort().join(",")
        ) {
          foundItem = true;
        }
      }
    });

    if (!foundItem) {
      dispatch(
        addGifts({
          id: item.id,
          image: item.photoUrl ? item.photoUrl : item.photos[0]?.url,
          name: item.name,
          price: priceFormatter(
            item.price,
            true,
            businessRulesVariables.itemPurchaseVAT
          ),
          currency: item.currency,
          subcategory: item.subcategoryItems,
          user: selectRecipientsData[0]?.user,
        })
      );
    } else {
      dispatch(removeGift(item.id));
    }
  };
  const addToItemCart = (item) => {
    let foundItem = false;

    selectedItem.forEach((data) => {
      if (data.id === item.id) {
        const itemSubcategories = data.subcategory?.map(
          (subcategory) => subcategory.valueId
        );
        const addedItemSubcategories = item.subcategoryItems?.map(
          (subcategory) => subcategory.valueId
        );

        if (
          itemSubcategories?.sort().join(",") ===
          addedItemSubcategories?.sort().join(",")
        ) {
          foundItem = true;
        }
      }
    });

    if (!foundItem) {
      if (selectedItem.length >= 3) {
        openToast(
          toastId,
          "send-gifts-select",
          "error",
          t("select-max-3-items")
        );
        return;
      }
      dispatch(
        addGifts({
          id: item.id,
          image: item.photoUrl ? item.photoUrl : item.photos[0]?.url,
          name: item.name,
          price: priceFormatter(
            item.price,
            true,
            businessRulesVariables.itemPurchaseVAT
          ),
          currency: item.currency,
          subcategory: item.subcategoryItems,
        })
      );
    } else {
      dispatch(removeGift(item.id));
    }
  };

  const goBack = () => {
    navigate(`/admin-portal/send-gifts/1`);
  };
  const handleNextStep = (step) => {
    navigate(`/admin-portal/send-gifts/${step}`);
  };

  const [amount, setAmount] = useState("");

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };
  const [modal, setModal] = useState(false);

  const ButtonContinue3Gifts = () => {
    return (
      <ButtonContinue
        $ar={ar}
        onClick={() => {
          if (selectRecipientsData.length <= 0) {
            openToast(
              toastId,
              "select-gift-warning",
              "warning",
              t("please-select-at-least-one-recipient")
            );
            navigate("/admin-portal/send-gifts/1");
            return;
          }
          if (selectedItem.length === 3) {
            dispatch(giftCart());
            dispatch(
              addBudget({
                budget,
                currency: selectCompany.walletCurrency,
              })
            );
            navigate("/admin-portal/send-gifts/3");
            handleNextStep(3);
          } else {
            openToast(
              toastId,
              "select-gift-warning",
              "warning",
              t("you-must-select-a-3-gifts")
            );
          }
        }}
      >
        {t("add-ons")}
      </ButtonContinue>
    );
  };
  const ButtonContinue1Gift = () => {
    return (
      <ButtonContinue
        $ar={ar}
        onClick={() => {
          if (selectRecipientsData.length <= 0) {
            openToast(
              toastId,
              "select-1-gifts",
              "warning",
              t("please-select-at-least-one-recipient")
            );
            navigate("/admin-portal/send-gifts/1");
            return;
          }
          if (selectedItem.length === 1) {
            dispatch(giftCart());
            dispatch(
              addBudget({
                budget,
                currency: selectCompany.walletCurrency,
              })
            );
            navigate("/admin-portal/send-gifts/3");
            handleNextStep(3);
          } else {
            openToast(
              toastId,
              "select-1-gift",
              "warning",
              t("you-must-select-a-1-gift")
            );
          }
        }}
      >
        {t("add-ons")}
      </ButtonContinue>
    );
  };

  const { state, company } = useSelector(selectBusinessUserInfo);
  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  return (
    <>
      <HeaderWrapper>
        <Header showLogin showSignUp color showUser hideSearch />
      </HeaderWrapper>
      <MainContainer>
        <CategoryBar ar={ar} />

        <Column $upgradePackage={showUpgradeBar && diff > 0 && state}>
          <ContentHeader
            style={{ padding: "0px 30px" }}
            tabStyle={{ padding: "0px 40px 0px 290px" }}
            logoStyle={{ left: 290 }}
            onBack={() => {
              dispatch(resetGifts());
              navigate(-1);
            }}
          />
          <StepsContainer $ar={ar}>
            <Steps
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              steps={[
                "recipients",
                "select-gifts",
                "add-ons",
                "delivery",
                "confirmation",
              ]}
            />
          </StepsContainer>

          <BudgetContainer>
            <BudgetTitle>{t("budget")}: </BudgetTitle>
            <BudgetInput
              placeholder="SR"
              onChange={handleChange}
              type="number"
              min="0"
            />
          </BudgetContainer>

          <ContentContainer style={{ marginTop: 40, paddingBottom: 40 }}>
            {!findRecipientsWishlistsLoading &&
            !sectionLoading &&
            Object.values(data).every((item) => item.data.length <= 0) &&
            recipientsWithWishlist?.every(
              (recipient) => recipient.items.length <= 0
            ) ? (
              <SectionDiv
                $ar={ar}
                style={{
                  alignItems: "center",
                  paddingTop: 100,
                }}
              >
                <SectionTitle>{t("wishes-above-budget")}</SectionTitle>
              </SectionDiv>
            ) : (
              <>
                {findRecipientsWishlistsLoading ? (
                  <SectionDiv $ar={ar}>
                    <TitleContainer style={{ padding: "0px 40px 0px 10px" }}>
                      <LoadingText />
                    </TitleContainer>
                    <ItemContainer className="horizontal-scroll">
                      <SkeletonGASLoader />
                    </ItemContainer>
                  </SectionDiv>
                ) : (
                  recipientsWithWishlist.map((recipient, index) => (
                    <SectionDiv key={`index-${index}`} $ar={ar}>
                      <TitleContainer style={{ padding: "0px 40px 0px 10px" }}>
                        <SectionTitle>
                          {recipient.fullName} {t("wishlist")}
                        </SectionTitle>
                      </TitleContainer>
                      {recipient.items.length > 0 ? (
                        <ItemContainer className="horizontal-scroll">
                          {recipient.items.map((item, index) => (
                            <GiftItemCard
                              item={item}
                              key={index}
                              itemDetails={() => {
                                handleItemClick(item.id);
                              }}
                              isSelected={
                                !!selectedItem.filter((i) => item.id === i.id)
                                  .length
                              }
                              addToItemCart={
                                recipientsWithWishlist[0]?.items.length > 0
                                  ? addToItemCartWithWishList
                                  : addToItemCart
                              }
                            />
                          ))}
                        </ItemContainer>
                      ) : (
                        <EmptyDataContainer key={index}>
                          <EmptyDataText>
                            {t("nothing-to-show-here")}
                          </EmptyDataText>
                        </EmptyDataContainer>
                      )}
                    </SectionDiv>
                  ))
                )}
                {Object.values(data).map((section, index) => (
                  <Fragment key={index}>
                    {sectionLoading ? (
                      <SectionDiv $ar={ar}>
                        <TitleContainer
                          style={{ padding: "0px 40px 0px 10px" }}
                        >
                          <LoadingText />
                        </TitleContainer>
                        <ItemContainer className="horizontal-scroll">
                          <SkeletonGASLoader />
                        </ItemContainer>
                      </SectionDiv>
                    ) : (
                      <SectionDiv key={index} $ar={ar}>
                        {section.data.length > 0 && (
                          <>
                            <TitleContainer
                              style={{ padding: "0px 40px 0px 10px" }}
                            >
                              <SectionTitle>{t(section.name)}</SectionTitle>
                              <SeeAllText
                                onClick={() => handleSeeAllClick(section.id)}
                              >
                                {t("see-all")}
                              </SeeAllText>
                            </TitleContainer>
                            <ItemContainer className="horizontal-scroll">
                              {section.data.map((item, index) => (
                                <GiftItemCard
                                  item={item}
                                  key={index}
                                  itemDetails={() => {
                                    handleItemClick(item.id);
                                  }}
                                  isSelected={
                                    !!selectedItem.filter(
                                      (i) => item.id === i.id
                                    ).length
                                  }
                                  addToItemCart={
                                    recipientsWithWishlist[0]?.items.length > 0
                                      ? addToItemCartWithWishList
                                      : addToItemCart
                                  }
                                />
                              ))}
                            </ItemContainer>
                          </>
                        )}
                      </SectionDiv>
                    )}
                  </Fragment>
                ))}
              </>
            )}
          </ContentContainer>
          <FooterRow $ar={ar}>
            <BackContainer onClick={goBack} $ar={ar}>
              {t("back")}
            </BackContainer>
            <ButtonsContainer>
              {selectRecipientsData[0]?.user ? (
                <>
                  {budgetValueInput > 0 &&
                    recipientsWithWishlist[0]?.items.length > 0 && (
                      <ButtonContinue1Gift />
                    )}

                  {budgetValueInput > 0 &&
                    recipientsWithWishlist[0]?.items.length === 0 && (
                      <ButtonContinue3Gifts />
                    )}

                  {budgetValueInput === 0 &&
                    recipientsWithWishlist[0]?.items.length > 0 && (
                      <ButtonContinue1Gift />
                    )}

                  {budgetValueInput === 0 &&
                    recipientsWithWishlist[0]?.items.length === 0 && (
                      <ButtonContinue3Gifts />
                    )}
                </>
              ) : (
                <ButtonContinue3Gifts />
              )}
            </ButtonsContainer>
          </FooterRow>
        </Column>
        <ChargeWalletModal
          onChange={handleAmountChange}
          onClick={() => {
            dispatch(setChargeWalletAmount(amount));
            if (amount) {
              navigate("/admin-portal/wallet/checkout");
            } else {
              toast.error(t("please-add-amount"));
            }
          }}
          value={amount}
          open={modal}
          close={() => setModal(false)}
        />
      </MainContainer>
    </>
  );
};

export default GiftsPage;
