import axios from "axios";
import { LOGOUT } from "../store/constants";
import {
  APP_VERSION,
  LOCAL_STORAGE_RECIPIENT_ACCESS_TOKEN,
  LOCAL_STORAGE_RECIPIENT_REFRESH_TOKEN,
} from "../utils/constants";

import { setAccessToken } from "../store/slices/auth/slice.js";

// export const BASE_URL = "http://localhost:5000";
export const BASE_URL = "https://api.ygii.app";
// export const BASE_URL = "https://staging-api.ygii.app";

// export const FRONT_URL = "http://localhost:3000";
export const FRONT_URL = "https://portal.ygii.app";
// export const FRONT_URL = "https://stagingbusiness-portal.ygii.app";

let store;

export const injectStore = (_store) => {
  store = _store;
};

const API_BASE_URL = `${BASE_URL}/api/`;
const AUTH_BASE_URL = `${BASE_URL}/business/auth/`;
const RECIPIENT_AUTH_BASE_URL = `${BASE_URL}`;
const RECIPIENT_API_BASE_URL = `${BASE_URL}/api/`;

const axiosRecipientAuthClient = axios.create({
  baseURL: RECIPIENT_AUTH_BASE_URL,
});

const axiosRecipientUserClient = axios.create({
  baseURL: RECIPIENT_API_BASE_URL,
});

const axiosAuthClient = axios.create({
  baseURL: AUTH_BASE_URL,
});

const axiosApiClient = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

axiosRecipientAuthClient.interceptors.request.use(
  (config) => {
    config.headers = {
      version: APP_VERSION,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosRecipientUserClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(LOCAL_STORAGE_RECIPIENT_ACCESS_TOKEN);
    config.headers = {
      Authorization: `Bearer ${token}`,
      version: APP_VERSION,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosAuthClient.interceptors.request.use(
  (config) => {
    config.headers = {
      version: APP_VERSION,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosApiClient.interceptors.request.use(
  (config) => {
    const token = store.getState().register.token;
    config.headers = {
      Authorization: `Bearer ${token}`,
      version: APP_VERSION,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosRecipientUserClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    try {
      const originalRequest = error.config;
      let currentRefreshToken = localStorage.getItem(
        LOCAL_STORAGE_RECIPIENT_REFRESH_TOKEN
      );
      if (
        currentRefreshToken &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const refreshResponse = await axiosRecipientAuthClient.post(
          "/refresh-token",
          {
            refreshToken: currentRefreshToken,
          }
        );
        if (refreshResponse.status !== 200) {
          return Promise.reject(error);
        }
        const { accessToken, refreshToken } = refreshResponse.data.data;
        localStorage.setItem(LOCAL_STORAGE_RECIPIENT_ACCESS_TOKEN, accessToken);
        localStorage.setItem(
          LOCAL_STORAGE_RECIPIENT_REFRESH_TOKEN,
          refreshToken
        );
        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
        return axios(originalRequest);
      } else if (error.response.status === 401) {
        localStorage.removeItem(LOCAL_STORAGE_RECIPIENT_ACCESS_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_RECIPIENT_REFRESH_TOKEN);

        store.dispatch({ type: LOGOUT });
      }
      return Promise.reject(error);
    } catch (err) {
      if (err.response.status === 400) {
        localStorage.removeItem(LOCAL_STORAGE_RECIPIENT_ACCESS_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_RECIPIENT_REFRESH_TOKEN);

        store.dispatch({ type: LOGOUT });
      }
      return Promise.reject(err);
    }
  }
);

axiosApiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      // CHECK: this isn't necessary
      originalRequest._retry = true;

      try {
        const refreshResponse = await axiosAuthClient.get("/refresh-token", {
          withCredentials: true,
        });
        const { accessToken } = refreshResponse.data.data;
        store.dispatch(setAccessToken({ accessToken }));
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return axios(originalRequest);
      } catch (error) {
        store.dispatch({ type: LOGOUT });
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosApiClient;
export { axiosAuthClient, axiosRecipientAuthClient, axiosRecipientUserClient };
