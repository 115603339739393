import { createSlice } from "@reduxjs/toolkit";
import {
  getBillingsInfoBuilder,
  getBillingsWithBankTransferPaymentBuilder,
  updateBillingPaymentStatusBuilder,
  validatePaymentBuilder,
} from "./builder";

const initialState = {
  getBillingsInfo: {
    data: [],
    total: "",
    maxReached: "",
    perPage: "",
    error: null,
    loading: false,
  },
  getBillingsWithBankTransferPayment: {
    data: [],
    total: "",
    maxReached: "",
    perPage: "",
    loading: false,
  },
  amount: "",
  updateBillingPaymentStatusPending: false,
  validatePaymentPending: false,
};

const billingSlice = createSlice({
  name: "billingInfo",
  initialState,
  reducers: {
    setChargeWalletAmount: (state, { payload }) => {
      state.amount = payload;
    },
  },
  extraReducers: (builder) => {
    validatePaymentBuilder(builder);
    getBillingsWithBankTransferPaymentBuilder(builder);
    getBillingsInfoBuilder(builder);
    updateBillingPaymentStatusBuilder(builder);
  },
});

export const { setChargeWalletAmount } = billingSlice.actions;

export const selectAmount = (state) => state.billingInfo.amount;
export const selectBankTransfers = (state) =>
  state.billingInfo.getBillingsWithBankTransferPayment;
export const selectUpdateBillingPaymentStatusLoading = (state) =>
  state.billingInfo.updateBillingPaymentStatusPending;
export const selectBillingInfoLoading = (state) =>
  state.billingInfo.getBillingsInfo.loading;
export const selectBankTransfersLoading = (state) =>
  state.billingInfo.getBillingsWithBankTransferPayment.loading;
export const selectBillingInfo = (state) => state.billingInfo.getBillingsInfo;

export default billingSlice.reducer;
