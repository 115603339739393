import React from "react";
import {
  Title,
  CloseContainer,
  Description,
  TopHeader,
  Text,
  PriceBreaksContainer,
  RightText,
  StyledRightText,
  LeftText,
  StyledSubtitle,
} from "./PriceBreakModal.styles";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import Modal from "./Modal2";
import { useTranslation } from "react-i18next";
import { getCurrencySymbol } from "../../utils/constants";
import { priceFormatter } from "../../utils/utils";

const PriceBreakModal = ({ open, close, item, data }) => {
  const { t } = useTranslation();

  return (
    <Modal
      onClose={close}
      open={open}
      right
      width={"567px"}
      height={"100%"}
      padding={"50px 60px"}
      unset
    >
      <CloseContainer onClick={close}>
        <CloseIcon fill="white" width={20} height={20} />
      </CloseContainer>
      <Title>{t("price-breaks")}</Title>

      <StyledSubtitle>{t("save-even-bigger")}</StyledSubtitle>
      <Description>{t("price-breaks-desc")}</Description>
      <PriceBreaksContainer>
        <TopHeader>
          <LeftText>Qty</LeftText>
          <Text>Coast (Each)</Text>
          <RightText>Saving</RightText>
        </TopHeader>
        {data?.map((x) => (
          <TopHeader key={x.id}>
            <LeftText>{x.minQuantity}</LeftText>
            <Text>
              {`${priceFormatter(
                item.price - (item.price * x.discount) / 100
              )} ${getCurrencySymbol(item?.currency)}`}
            </Text>
            <StyledRightText>{x.discount}%</StyledRightText>
          </TopHeader>
        ))}
      </PriceBreaksContainer>
    </Modal>
  );
};

export default PriceBreakModal;
