import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import {
  Container,
  Screen,
  ScreenContent,
  MainContainer,
  Title,
  Gap,
  HeaderWrapper,
  Subtitle,
  Button,
  ButtonContainer,
  Cancel,
  SelectedItemContainer,
  SelectedItemDescriptionContainer,
  SelectedItemName,
  SelectedItemPrice,
  SelectedColor,
  SelectColorButton,
  PickColorContainer,
  UploadLogoContainer,
  UploadLogoImage,
  SelectFileButton,
  FooterText,
  MinQuantityContainer,
  Input,
  Quantity,
} from "./PackagingPage.styles";

import Loader from "../../components/Loader/Loader";
import { selectedLocale } from "../../store/slices/common/slice";
import PackagingComponent from "../../components/PackagingComponent/PackagingComponent";
import { ItemPhoto } from "../../components/PackagingComponent/PackagingComponent.styles";
import UploadLogo from "../../assets/images/uploadLogo.png";
import { toast } from "react-toastify";

import {
  addToCart,
  cartItemsData,
  selectOrderPackages,
} from "../../store/slices/marketplace/marketplaceSlice";
import { useDispatch } from "react-redux";
import { getOrderPackages } from "../../store/slices/marketplace/asyncThunks";
import { selectBusinessUserInfo } from "../../store/slices/businessUser/slice";
import { convertBase64 } from "../../utils/utils";

const PackagingPage = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const locale = useSelector(selectedLocale);
  const dispatch = useDispatch();
  const ar = locale === "ar";

  const ref = useRef(null);

  const [headerVisible, setHeaderVisible] = useState(true);
  const [lastScrollUpY, setLastScrollUpY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(null);
  const lastScrollTopRef = useRef(0);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [step, setStep] = useState(0);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [quantityValue, setQuantityValue] = useState(1);
  const orderPackages = useSelector(selectOrderPackages);
  const businessUser = useSelector(selectBusinessUserInfo);
  const items = useSelector(cartItemsData);

  useEffect(() => {
    dispatch(getOrderPackages());
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      const lastScrollTop = lastScrollTopRef.current;

      if (currentScrollTop === 0) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
        setScrollDirection(null);
        lastScrollTopRef.current = currentScrollTop;
        return;
      }

      if (currentScrollTop > lastScrollTop) {
        setScrollDirection("down");
        setLastScrollUpY(currentScrollTop);
      } else {
        setScrollDirection("up");
      }

      if (scrollDirection === "up" && currentScrollTop < lastScrollUpY - 350) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
      }

      if (
        scrollDirection === "down" &&
        currentScrollTop > lastScrollUpY + 350
      ) {
        setHeaderVisible(false);
      }

      lastScrollTopRef.current = currentScrollTop;
    };

    const handleScrollDebounced = () => {
      requestAnimationFrame(handleScroll);
    };

    window.addEventListener("scroll", handleScrollDebounced);

    return () => {
      window.removeEventListener("scroll", handleScrollDebounced);
    };
  }, [scrollDirection]);

  const handleLogoChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSelectedLogo(selectedFile);
      toast.success(t("File uploaded successfully"));
    } else {
      //     setSelectedLogo(null);
      //   openToast(
      //     toastId,
      //     "select-receipt-file",
      //     "error",
      //     t("Please select a receipt file")
      //   );
    }
  };

  const handleDisabledButton = useCallback(() => {
    let disabled = false;
    if (step === 0 && !selectedPackage) {
      disabled = true;
    }
    if (step === 1) {
      if (selectedPackage.canPickColor && !selectedColor) {
        disabled = true;
      } else if (selectedPackage.canUploadLogo && !selectedLogo) {
        disabled = true;
      }
    }
    return disabled;
  }, [selectedColor, selectedLogo, selectedPackage, step]);

  const btnContinueDisabled = useMemo(
    () => handleDisabledButton(),

    [handleDisabledButton]
  );

  const convertLogo = async () => {
    const base64 = await convertBase64(selectedLogo);
    return base64;
  };
  const getMinimumProductionTime = () => {
    let minTime = 0;

    for (const item of items) {
      if (minTime < item.productionTime) {
        minTime = item.productionTime;
      }
    }
    return minTime;
  };

  const getQuantityItems = () => {
    let quantity = 0;

    for (const item of items) {
      quantity = quantity + item.quantity;
    }
    return quantity;
  };

  const handleAddToCart = async () => {
    const packageData = {
      id: selectedPackage.id,
      isPackaging: true,
      name: selectedPackage.name,
      photo: {
        url: selectedPackage.photoUrl,
      },
      itemPrice: selectedPackage.price,
      totalPrice: selectedPackage.price * quantityValue,
      ...(selectedColor && { packageColor: selectedColor }),
      ...(selectedLogo && {
        packageLogo: await convertLogo(),
      }),
      ...(selectedLogo && {
        packageLogoName: selectedLogo.name,
      }),
      quantity: quantityValue,
      productionTime: getMinimumProductionTime(),
      currency: "sar",
    };
    dispatch(addToCart(packageData));
    if (businessUser.loggedIn) {
      navigation("/admin-portal/cart");
    } else {
      navigation("/cart");
    }
  };

  return (
    <>
      {orderPackages.loading && <Loader disableScroll />}

      <HeaderWrapper
        style={{
          opacity: headerVisible ? 1 : 0,
          transition: "opacity 0.5s",
        }}
      >
        <Header
          showLogin
          showNav
          color
          showUser
          hideSearch
          showSignUp
          display={headerVisible}
        />
      </HeaderWrapper>

      <MainContainer>
        <Container>
          <Screen>
            <ScreenContent ref={ref}>
              <Title>{t("add-packaging")}</Title>
              <Subtitle>
                {step === 0 ? t("select-packaging") : t("upload-your-logo")}
              </Subtitle>

              {step === 0 && (
                <Gap>
                  <PackagingComponent
                    animated
                    selectedPackage={selectedPackage}
                    items={orderPackages.data}
                    setSelectedPackage={(item) => {
                      if (selectedPackage && item.id === selectedPackage.id) {
                        setSelectedPackage(null);
                      } else {
                        setSelectedPackage(item);
                      }
                    }}
                  />
                </Gap>
              )}
              {step === 1 && (
                <SelectedItemContainer>
                  <ItemPhoto src={selectedPackage?.photoUrl} alt="Item-img" />
                  <SelectedItemDescriptionContainer>
                    <SelectedItemName>
                      {t(`${selectedPackage.name}`)}
                    </SelectedItemName>
                    <SelectedItemPrice>
                      {selectedPackage.price === 0
                        ? `${t("free")} `
                        : `${t("price-per-item")} ${selectedPackage.price} SR`}
                    </SelectedItemPrice>
                    <MinQuantityContainer>
                      <Quantity>{t("quantity")}:</Quantity>
                      <Input
                        placeholder={1}
                        min={1}
                        autoComplete="off"
                        type="number"
                        value={quantityValue}
                        onChange={(e) => setQuantityValue(e.target.value)}
                      />
                    </MinQuantityContainer>

                    {selectedPackage?.canPickColor && (
                      <>
                        <SelectedColor>{t("select-color")}</SelectedColor>
                        <PickColorContainer>
                          <SelectColorButton $color={selectedColor}>
                            {selectedColor ? selectedColor : t("select-color")}
                            <input
                              type="color"
                              value={selectedColor ? selectedColor : "#ff0000"}
                              style={{
                                opacity: 0,
                                width: "100%",
                                height: "100%",
                                borderRadius: 15,
                                top: 0,
                                position: "absolute",
                              }}
                              onChange={(e) => {
                                setSelectedColor(e.target.value);
                              }}
                            />
                          </SelectColorButton>
                        </PickColorContainer>
                      </>
                    )}
                  </SelectedItemDescriptionContainer>
                  {selectedPackage?.canUploadLogo && (
                    <UploadLogoContainer>
                      <UploadLogoImage
                        src={
                          selectedLogo
                            ? URL.createObjectURL(selectedLogo)
                            : UploadLogo
                        }
                        alt="success-img"
                      />
                      <SelectFileButton disabled={!selectedPackage}>
                        <input
                          type="file"
                          accept=".pdf,image/*"
                          style={{
                            opacity: 0,
                            width: "100%",
                            height: "100%",
                            top: 0,
                            position: "absolute",
                            border: "1px solid rgb(1, 1, 1, 0.15)",
                            borderRadius: "20px",
                          }}
                          onChange={handleLogoChange}
                        />
                        {t("upload-your-design")}
                      </SelectFileButton>
                    </UploadLogoContainer>
                  )}
                </SelectedItemContainer>
              )}
              <FooterText>{t("after-placing-order")}</FooterText>
              <ButtonContainer>
                <Cancel
                  onClick={() => {
                    if (step === 1) {
                      setStep(0);
                      window.scrollTo(0, 0);
                    } else {
                      navigation(-1);
                    }
                  }}
                >
                  {t("back")}
                </Cancel>
                <Button
                  disabled={btnContinueDisabled}
                  onClick={() => {
                    if (step === 0) {
                      setStep(1);
                      setQuantityValue(getQuantityItems());
                      window.scrollTo(0, 0);
                    } else {
                      handleAddToCart();
                    }
                  }}
                >
                  {t("continue")}
                </Button>
              </ButtonContainer>
            </ScreenContent>
          </Screen>
        </Container>
      </MainContainer>
      <Footer />
    </>
  );
};

export default PackagingPage;
