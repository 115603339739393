import {
  getBillingsInfo,
  getBillingsWithBankTransferPayment,
  updateBillingPaymentStatus,
  validatePayment,
} from "./asyncThunks";
import { businessUserSlice } from "../businessUser/slice";
import { updateWalletBalance } from "../businessUser/asyncThunks";

export const getBillingsInfoBuilder = (builder) => {
  builder.addCase(getBillingsInfo.pending, (state) => {
    state.getBillingsInfo.loading = true;
    state.getBillingsInfo.error = null;
  });
  builder.addCase(getBillingsInfo.fulfilled, (state, action) => {
    state.getBillingsInfo.loading = false;
    state.getBillingsInfo.data = action.payload.data;
    state.getBillingsInfo.total = action.payload.total;
    state.getBillingsInfo.maxReached = action.payload.maxReached;
    state.getBillingsInfo.perPage = action.payload.perPage;
  });
  builder.addCase(getBillingsInfo.rejected, (state, action) => {
    state.getBillingsInfo.loading = false;
    state.getBillingsInfo.error = action.error.message;
  });
};

export const validatePaymentBuilder = (builder) => {
  builder.addCase(validatePayment.pending, (state) => {
    state.validatePaymentPending = true;
  });
  builder.addCase(validatePayment.fulfilled, (state) => {
    state.validatePaymentPending = false;
  });
  builder.addCase(validatePayment.rejected, (state) => {
    state.validatePaymentPending = false;
  });
};

export const getBillingsWithBankTransferPaymentBuilder = (builder) => {
  builder.addCase(getBillingsWithBankTransferPayment.pending, (state) => {
    state.getBillingsWithBankTransferPayment.loading = true;
  });
  builder.addCase(
    getBillingsWithBankTransferPayment.fulfilled,
    (state, { payload }) => {
      const { data, total, perPage, maxReached } = payload;

      state.getBillingsWithBankTransferPayment.total = total;
      state.getBillingsWithBankTransferPayment.maxReached = maxReached;
      state.getBillingsWithBankTransferPayment.perPage = perPage;
      state.getBillingsWithBankTransferPayment.data = data;
      state.getBillingsWithBankTransferPayment.loading = false;
    }
  );
  builder.addCase(getBillingsWithBankTransferPayment.rejected, (state) => {
    state.getBillingsWithBankTransferPayment.loading = false;
  });
};

export const updateBillingPaymentStatusBuilder = (builder) => {
  builder.addCase(updateBillingPaymentStatus.pending, (state) => {
    state.updateBillingPaymentStatusPending = true;
  });
  builder.addCase(
    updateBillingPaymentStatus.fulfilled,
    (state, { payload }) => {
      const { data } = payload;
      state.updateBillingPaymentStatusPending = false;
      state.getBillingsWithBankTransferPayment.data =
        state.getBillingsWithBankTransferPayment.data.map((billing) =>
          billing.id === data.id
            ? { ...billing, paymentStatus: data.paymentStatus }
            : billing
        );
    }
  );
  builder.addCase(updateBillingPaymentStatus.rejected, (state) => {
    state.updateBillingPaymentStatusPending = false;
  });
};
