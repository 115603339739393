import React, { useEffect, useRef, useState } from "react";
import {
  Gift,
  GiftName,
  Gifts,
  InfoContainer,
  Title,
  Subtitle,
  Message,
  MessageContainer,
  SmallText,
  ButtonConfirm,
  ButtonReject,
  Buttons,
  ButtonsContainer,
  CheckIcon,
  Overlay,
  UncheckedIcon,
  Scroll,
  Gap,
  StoreContainer,
  StoreContainerWrapper,
  SuccessContainer,
  GiftIconStyled,
} from "./PGMPages.styles";

import giftService from "../../api/services/gift";
import MiniLoader from "../../components/MiniLoader/MiniLoader";
import giftMobileService from "../../api/services/giftMobile";
import openToast from "../../utils/toast";
import { ReactComponent as Loading } from "../../assets/icons/loader-white.svg";
import AppStore from "../../assets/images/appstore.png";
import GooglePlay from "../../assets/images/googleplay.png";

const PickYourGiftComponent = ({ setTab, t, token }) => {
  const [select, setSelect] = useState(null);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [pending, setPending] = useState(true);
  const toastId = useRef(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (data.length > 0) {
      setPending(false);
    }
  }, [data]);

  const onPick = (id) => {
    if (!confirmLoading) {
      setSelect(id);
    }
  };

  const pickAnotherGift = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.ygii&hl=en_US&pli=1",
        "_blank"
      );
    } else if (/iPad|iPhone|mac|iPod/i.test(userAgent)) {
      window.open(
        "https://apps.apple.com/vn/app/ygii-gifts-wishlists/id1609129551",
        "_blank"
      );
    } else {
      window.open("https://www.ygii.app/", "_blank");
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        const result = await giftService.getCompanyGiftingItemsWithToken(token);
        setData(result.data);
        setMessage(result.message);
      } catch (err) {
        console.log(err.message);
        setError(true);
      }
    }
    getData();
  }, [token]);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleYes = async () => {
    setConfirmLoading(true);
    try {
      await giftMobileService.createGiftingRecipientOrderWithToken({
        itemId: select.item.id,
        token,
      });
      setTab("confirm-location");
    } catch (error) {
      setConfirmLoading(false);
      openToast(
        toastId,
        "pick-a-gift",
        "error",
        t("error-while-picking-the-gift")
      );
    }
  };

  return (
    <Scroll>
      <Gap>
        {!error && <Title>{t("pick-your-gift")}</Title>}
        {error ? (
          <Subtitle style={{ textAlign: "center" }}>
            {t("gift-already-chosen")}.
          </Subtitle>
        ) : !pending ? (
          <>
            <>
              {message && (
                <MessageContainer>
                  <Subtitle>{t("sender-message")}</Subtitle>
                  <Message>{message}</Message>
                </MessageContainer>
              )}
              <Gifts>
                {data?.map((item, index) => (
                  <Gift
                    $selected={select?.item.id === item?.item.id}
                    $active={!select || select?.item.id === item?.item.id}
                    key={index}
                    $imageUrl={item?.item.photos[0].url}
                    onClick={() => onPick(item)}
                    disabled={confirmLoading}
                  >
                    <Overlay />
                    <InfoContainer>
                      <GiftName>{item?.item.name}</GiftName>
                    </InfoContainer>
                    {select?.item.id === item?.item.id ? (
                      <CheckIcon />
                    ) : (
                      <UncheckedIcon />
                    )}
                  </Gift>
                ))}
              </Gifts>
            </>
            <ButtonsContainer>
              <Buttons>
                <ButtonReject
                  disabled={error}
                  onClick={
                    error || confirmLoading ? () => {} : () => setTab("reject")
                  }
                >
                  {t("reject")}
                </ButtonReject>
                {!confirmLoading ? (
                  <ButtonConfirm
                    onClick={!select ? () => {} : () => handleYes()}
                    disabled={!select}
                  >
                    {t("confirm-selection")}
                  </ButtonConfirm>
                ) : (
                  <ButtonConfirm>
                    <Loading height={25} />
                  </ButtonConfirm>
                )}
              </Buttons>
              <SmallText onClick={confirmLoading ? () => {} : pickAnotherGift}>
                {t("pick-another-gift-from-the-app")}
              </SmallText>
            </ButtonsContainer>
          </>
        ) : (
          <MiniLoader />
        )}
        {error && (
          <>
            <SuccessContainer>
              <GiftIconStyled />
            </SuccessContainer>
            <StoreContainerWrapper>
              <Subtitle style={{ textAlign: "center" }}>
                {t("download-the-app-for-more-details")}!
              </Subtitle>
              <StoreContainer>
                <a
                  href="https://apps.apple.com/vn/app/ygii-gifts-wishlists/id1609129551"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={AppStore}
                    alt="App Store"
                    className="store"
                    style={{ userSelect: "none" }}
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.ygii&hl=en_US&pli=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={GooglePlay}
                    alt="img"
                    className="store"
                    style={{ userSelect: "none" }}
                  />
                </a>
              </StoreContainer>
            </StoreContainerWrapper>
          </>
        )}
      </Gap>
    </Scroll>
  );
};

export default PickYourGiftComponent;
