import React, { useEffect, useRef, useState } from "react";
import { MainContainer, Column, SectionTitle } from "../AddItem/AddItem.styles";
import Sidebar from "../../utils/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Steps from "../../../../components/Steps/Steps";
import {
  selectCreateSubcategoryPending,
  selectDeleteSubcategoryPending,
  selectEditSubcategoryPending,
  selectSubcategoriesListPending,
  subcategoriesListData,
} from "../../../../store/slices/marketplace/marketplaceSlice";
import {
  createSubcategory,
  deleteSubcategory,
  editSubcategory,
  getSubcategoriesList,
} from "../../../../store/slices/marketplace/asyncThunks";
import { toast } from "react-toastify";
import {
  CategoryContent,
  CategoryName,
  Subtitle,
  Row,
  AddCategoryContainer,
  Input,
  Gap,
  CategoryContainer,
  IconsContainer,
  Row3,
} from "./AttributesPage.styles";
import UseAnimations from "react-useanimations";
import trash2 from "react-useanimations/lib/trash2";
import { ReactComponent as EditPencil } from "../../../../assets/icons/edit-pencil.svg";
import { ReactComponent as Add } from "../../../../assets/icons/add-filled.svg";
import AddSubcategoryItemValue from "./AddSubcategoryItemValue";
import {
  ButtonBack,
  ButtonNext,
  Buttons,
} from "../Categories/CategoriesPage.styles";

import ButtonComponent from "../../../../components/ButtonAdd/ButtonAdd";
import Header from "../../../../components/Header/Header";
import { HeaderWrapper } from "../MarketplacePage.styles";
import openToast from "../../../../utils/toast";
import { selectedLocale } from "../../../../store/slices/common/slice";
import AppLoader from "../../../../components/AppLoader";

const AttributesPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(1);
  const navigate = useNavigate();
  const attributes = useSelector(subcategoriesListData);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [showAddInput, setShowAddInput] = useState(false);
  const [newAttributeName, setNewAttributeName] = useState("");
  const [editFormShow, setEditFormShow] = useState(false);
  const [editingAttributeId, setEditingAttributeId] = useState(null);
  const [attributeValue, setAttributeValue] = useState("");
  const fetchDataPending = useSelector(selectSubcategoriesListPending);
  const createSubcategoryPending = useSelector(selectCreateSubcategoryPending);
  const editSubcategoryPending = useSelector(selectEditSubcategoryPending);
  const deleteSubcategoryPending = useSelector(selectDeleteSubcategoryPending);
  const [deleteSubcategoryId, setDeleteSubcategoryId] = useState(null);
  const toastId = useRef(null);

  useEffect(() => {
    dispatch(getSubcategoriesList())
      .unwrap()
      .catch((err) => toast.error(t(err.message)));
  }, [dispatch]);

  const filteredAttributes = attributes?.find(
    (attribute) => attribute.id === selectedCategory
  )?.marketplaceSubcategories;

  const stepName = [t("attribute"), t("variables")];

  const handleAddAttribute = () => {
    if (selectedCategory) {
      if (!newAttributeName) {
        openToast(toastId, "add-attribute", "error", t("fill-attribute-name"));
        return;
      }

      dispatch(
        createSubcategory({
          value: newAttributeName,
          marketplaceCategoryId: selectedCategory,
        })
      )
        .unwrap()
        .then(() => {
          toast.success(t("attribute-added-successfully"));
          setNewAttributeName("");
        })
        .catch((error) => {
          openToast(toastId, "add-attribute", "error", t(error.message));
        });
    }
  };

  const handleDeleteAttribute = (id) => {
    setDeleteSubcategoryId(id);
    dispatch(deleteSubcategory(id))
      .unwrap()
      .then(() => {
        toast.success(t("attribute-deleted-successfully"));
        setDeleteSubcategoryId(null);
      })
      .catch((error) => {
        openToast(toastId, "delete-attribute", "error", t(error.message));
      });
  };

  const handleEditCancel = () => {
    setEditFormShow(false);
    setEditingAttributeId(null);
  };

  const handleEditSubcategory = (subcategoryId) => {
    setEditingAttributeId(null);
    const selectedAttribute = filteredAttributes?.find(
      (subcategory) => subcategory.id === subcategoryId
    );
    if (selectedAttribute) {
      setEditFormShow(true);
      setAttributeValue(selectedAttribute.value);
      setEditingAttributeId(selectedAttribute.id);
    }
  };

  const handleUpdateAction = () => {
    if (!attributeValue) {
      openToast(toastId, "update-attribute", "error", t("fill-attribute-name"));
      return;
    }
    dispatch(
      editSubcategory({
        value: attributeValue,
        subcategoryId: editingAttributeId,
      })
    )
      .unwrap()
      .then(() => {
        toast.success(t("attribute-updated-successfully"));
      })
      .catch((error) => {
        openToast(toastId, "update-attribute", "error", t(error.message));
      });
  };

  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";

  return (
    <>
      <MainContainer>
        <HeaderWrapper>
          <Header showLogin showNav color showUser showCart showFavorite />
        </HeaderWrapper>
        <Sidebar />

        <Column $ar={ar}>
          <SectionTitle>{t("attributes")}</SectionTitle>
          <Steps
            steps={stepName}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
          {activeStep === 1 && (
            <>
              <Row>
                <Subtitle>{t("select-category-lower")}</Subtitle>
                <CategoryContent>
                  {fetchDataPending && <AppLoader size={20} />}
                  {!fetchDataPending &&
                    attributes.map((attribute) => (
                      <CategoryName
                        key={attribute.id}
                        onClick={() => {
                          setSelectedCategory(attribute.id);
                          setShowAddInput(false);
                        }}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          backgroundColor:
                            selectedCategory === attribute.id
                              ? "#8A4B5E"
                              : "#f3edef",
                          color:
                            selectedCategory === attribute.id
                              ? "#FFF"
                              : "#222239",
                          flexDirection: "column",
                        }}
                      >
                        {attribute.name}
                      </CategoryName>
                    ))}
                </CategoryContent>

                {selectedCategory && (
                  <>
                    <Gap></Gap>
                    {filteredAttributes?.length > 0 && (
                      <Subtitle>{t("select-attribute-lower")}</Subtitle>
                    )}

                    <CategoryContent>
                      {filteredAttributes?.length > 0 &&
                        filteredAttributes?.map((subcategory) => (
                          <CategoryContainer key={subcategory.id}>
                            <CategoryName
                              onClick={() =>
                                setSelectedSubcategory(subcategory.id)
                              }
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  selectedSubcategory === subcategory.id
                                    ? "#8A4B5E"
                                    : "#f3edef",
                                color:
                                  selectedSubcategory === subcategory.id
                                    ? "#FFF"
                                    : "#222239",
                              }}
                            >
                              {subcategory.value}
                            </CategoryName>
                            <IconsContainer>
                              {deleteSubcategoryPending &&
                              deleteSubcategoryId === subcategory.id ? (
                                <AppLoader />
                              ) : (
                                <UseAnimations
                                  className="pointer"
                                  animation={trash2}
                                  size={32}
                                  strokeColor="#8A4B5E"
                                  onClick={() =>
                                    handleDeleteAttribute(subcategory.id)
                                  }
                                />
                              )}

                              <EditPencil
                                className="edit-icon"
                                fill="#8A4B5E"
                                style={{
                                  marginTop: 6,
                                  width: 26,
                                  height: 26,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setEditFormShow(true);
                                  setShowAddInput(false);
                                  handleEditSubcategory(subcategory.id);
                                }}
                              />
                            </IconsContainer>
                          </CategoryContainer>
                        ))}
                      {filteredAttributes?.length <= 0 && (
                        <Subtitle>{t("no-attributes")}</Subtitle>
                      )}
                    </CategoryContent>
                    <Add
                      onClick={() => {
                        setShowAddInput(true);
                        setEditFormShow(false);
                      }}
                      className="pointer"
                      style={{ width: 26, height: 26, minWidth: 26 }}
                    />
                  </>
                )}
                {showAddInput && (
                  <AddCategoryContainer>
                    <Row3>
                      <Subtitle>{t("add-attribute")}</Subtitle>
                      <Input
                        type="text"
                        placeholder={t("attribute-name")}
                        value={newAttributeName}
                        onChange={(e) => setNewAttributeName(e.target.value)}
                      />

                      <ButtonComponent
                        title={t("add")}
                        loading={createSubcategoryPending}
                        onClick={() => handleAddAttribute()}
                      />
                      <ButtonComponent
                        title={t("cancel")}
                        onClick={() => setShowAddInput(false)}
                      />
                    </Row3>
                  </AddCategoryContainer>
                )}
                {editFormShow && (
                  <AddCategoryContainer>
                    <Row3>
                      <Subtitle>{t("edit-attribute")}</Subtitle>
                      <Input
                        type="text"
                        placeholder={t("edit-attribute-name")}
                        value={attributeValue}
                        onChange={(e) => setAttributeValue(e.target.value)}
                      />

                      <ButtonComponent
                        title={t("edit")}
                        loading={editSubcategoryPending}
                        onClick={() => handleUpdateAction()}
                      />
                      <ButtonComponent
                        title={t("cancel")}
                        onClick={() => handleEditCancel()}
                      />
                    </Row3>
                  </AddCategoryContainer>
                )}
              </Row>
              <Buttons>
                <ButtonBack
                  onClick={() => navigate("/admin-portal/marketplace")}
                >
                  {t("back")}
                </ButtonBack>
                <ButtonNext
                  onClick={() => {
                    if (selectedSubcategory) {
                      setActiveStep(2);
                      setShowAddInput(false);
                      setEditFormShow(false);
                    } else {
                      toast.error(t("you-have-to-select-an-attribute"));
                    }
                  }}
                >
                  {t("next")}
                </ButtonNext>
              </Buttons>
            </>
          )}

          {activeStep === 2 && (
            <AddSubcategoryItemValue
              setActiveStep={setActiveStep}
              onNext={() => {
                setActiveStep(3);
              }}
              selectedSubcategory={selectedSubcategory}
              selectedCategory={selectedCategory}
              attributes={attributes}
            />
          )}
        </Column>
      </MainContainer>
    </>
  );
};

export default AttributesPage;
