import { useTranslation } from "react-i18next";
import React from "react";
import { Button, ButtonsContainer, LeftSide } from "./AdminHeader.styles";

import { useNavigate } from "react-router-dom";
import SearchInput from "../Search/Search";

const LeftSideComponent = ({ showButtons, hideSearch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOnClickAdd = () => {
    const dataToSend = {
      action: "create",
    };
    navigate(`/admin-portal/marketplace/add-an-item/${dataToSend.action}`, {
      state: dataToSend,
    });
  };
  return (
    <LeftSide>
      {!hideSearch && <SearchInput />}

      {showButtons && (
        <ButtonsContainer>
          <Button
            onClick={() => {
              handleOnClickAdd();
            }}
          >
            {t("add-item")}
          </Button>
          <Button
            onClick={() => {
              navigate("/admin-portal/marketplace/categories");
            }}
          >
            {t("categories.categories")}
          </Button>
          <Button
            onClick={() => {
              navigate("/admin-portal/marketplace/attributes");
            }}
          >
            {t("attributes")}
          </Button>
        </ButtonsContainer>
      )}
    </LeftSide>
  );
};

export default LeftSideComponent;
