import styled from "styled-components";

export const ModalStyle = styled.div`
  margin: 3rem;
  padding: 3.5rem;
  display: flex;
  outline: none;
  flex-direction: column;
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  background: linear-gradient(360deg, #a87283 -100%, #8a4b5e 100%);
  border-radius: 20px;
  border: 2px solid #8a4b5e;
  @media (max-width: 580px) {
    transform: scale(0.8);
  }
  @media (max-width: 430px) {
    transform: scale(0.65);
  }
  @media (max-width: 320px) {
    transform: scale(0.55);
  }
`;

export const OverlayStyle = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3500;
  background: rgba(0, 0, 0, 0.6);
  @media (max-width: 430px) {
    justify-content: center;
    align-items: flex-start;
  }
`;
