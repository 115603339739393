import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { selectCompanyInfo } from "../../store/slices/businessUser/slice";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ adminDashboard }) => {
  const options = {
    animation: false,
  };
  const companyInfo = useSelector(selectCompanyInfo);

  if (adminDashboard) {
    const totalCount = adminDashboard?.subscriptions?.reduce(
      (sum, sub) => sum + sub.count,
      0
    );

    const subscriptionsWithPercentage = adminDashboard?.subscriptions?.map(
      (sub) => {
        const percentage = (sub.count / totalCount) * 100;
        return {
          type: sub.type,
          count: sub.count,
          totalNumOfUsers: sub.totalNumOfUsers,
          percentage,
        };
      }
    );

    const data = {
      labels: [],
      datasets: [
        {
          label: "Percentage %",
          data: [
            subscriptionsWithPercentage[0]?.percentage,
            subscriptionsWithPercentage[1]?.percentage,
            subscriptionsWithPercentage[2]?.percentage,
          ],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };
    return <Doughnut data={data} options={options} />;
  } else {
    const data = {
      labels: [],
      datasets: [
        {
          data: [companyInfo?.walletBalance, companyInfo?.walletReservedFunds],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
          ],
          borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
          borderWidth: 1,
        },
      ],
    };
    return <Doughnut data={data} options={options} />;
  }
};

export default PieChart;
