import React, { useEffect, useRef, useState } from "react";
import {
  ButtonSelected,
  Header,
  ChevronContainer,
  MainContainer,
  Content,
  MobileScreenContainer,
  Text,
  PageName,
  Title,
  TitleColumn,
  CustomizeOrder,
  Gradient,
  PriceOrder,
  TitleOrder,
  OrderColumn,
  CustomizeOrdersContainer,
  SwitchContainer,
  TitleRow,
  GreenText,
  SmallRow,
  GiftCardContainer,
  OccasionsContainer,
  TextArea,
  Gap,
  TextLeft,
  Input,
  Button,
  LoaderWrapper,
  StyledTitle,
  StyledValue,
  Line,
  StyledDatePicker,
  InputWrapper,
} from "./MobileAppPage.styles";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactComponent as Chevron } from "../../assets/icons/chevron-left.svg";
import Roses from "../../assets/images/roses.jpeg";
import Cake from "../../assets/images/cake.jpeg";
import Choco from "../../assets/images/choco.png";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectedGetItemLoading,
  selectedItem,
} from "../../store/slices/item/itemSlice";
import Switch from "react-switch";
import { formatDateNumeric, priceFormatter } from "../../utils/utils";
import { getCurrencySymbol } from "../../utils/constants";
import { getItemUnauthorized } from "../../store/slices/item/asyncThunks";
import MiniLoader from "../../components/MiniLoader/MiniLoader";
import PaymentModal from "../../components/PaymentModal/PaymentModal";
import orderService from "../../api/services/order";
import PhoneNumberInput from "../../components/PhoneNumberInput/PhoneNumberInput";
import OTPModal from "../../components/OTPModal/OTPModal";
import { isValidPhoneNumber } from "libphonenumber-js";
import { toast } from "react-toastify";
import AppLoader from "../../components/AppLoader";

const occasions = [
  { name: "Birthday" },
  { name: "Anniversary" },
  { name: "Promotion" },
  { name: "Onboarding" },
  { name: "Farewell" },
];

const MobileAppCheckout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const item = useSelector(selectedItem);
  const openModalVerificationCode = useRef();
  const openModalPayment = useRef();
  const loading = useSelector(selectedGetItemLoading);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [fullName, setFullName] = useState("");

  const [createOrderLoading, setCreateOrderLoading] = useState(false);

  const [sendOtpLoading, setSendOtpLoading] = useState(false);
  const [validateOtpLoading, setValidateOtpLoading] = useState(false);

  const [giftCard, setGiftCard] = useState(false);
  const [selectedOccasion, setSelectedOccasion] = useState({
    name: "Birthday",
  });
  const [message, setMessage] = useState("");

  const [tab, setTab] = useState(1);

  useEffect(() => {
    dispatch(getItemUnauthorized(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (item.productId) {
      setTab(2);
    }
  }, [item]);

  const disable = () => {
    if (giftCard && message === "") {
      return true;
    } else if (!giftCard && message !== "") {
      return true;
    } else {
      return false;
    }
  };

  const [wrapping, setWrapping] = useState(!item.productId);
  const currentDate = new Date();
  const fiveDaysFromNow = new Date(
    currentDate.getTime() + 5 * 24 * 60 * 60 * 1000
  );
  const [date, setDate] = useState(fiveDaysFromNow);

  const [flowers, setFlowers] = useState(false);
  const [cake, setCake] = useState(false);
  const [chocolate, setChocolate] = useState(false);

  const itemPrice = priceFormatter(item?.price, true, 15);

  const wrappingTotalPrice = wrapping ? +itemPrice + 75 : +itemPrice;

  const deliveryPrice = item.productId ? 0 : 35;

  const subTotalPrice =
    (flowers ? 99 : 0) +
    (cake ? 149 : 0) +
    (chocolate ? 70 : 0) +
    wrappingTotalPrice +
    deliveryPrice;

  const totalPrice = giftCard ? 10 + subTotalPrice : subTotalPrice;

  let disable2 = formatDateNumeric(date) === formatDateNumeric(currentDate);

  const createOrder = async (data) => {
    try {
      const result = await orderService.createOrder({
        itemId: item?.id,
        paymentChargeId: data.tokenId,
        orderVAT: 15,
        orderDeliveryPrice: item.productId ? 0 : 35,
        itemBoxWrappingPrice: item.productId ? 0 : 75,
        boxWrapping: wrapping,
        totalPrice: totalPrice,
        receiverId: item.user?.id,
        flowers,
        cake,
        chocolate,
        giftCard,
        ...(giftCard && {
          giftCardCategory: selectedOccasion?.name ?? "Birthday",
        }),
        ...(giftCard && { giftCardText: message }),
        ...(date && date.getTime()),
        code: otp,
        fullName,
        phone: mobileNumber.replaceAll(" ", ""),
      });

      window.open(result.data.data.url, "_self");
    } catch (err) {
      setCreateOrderLoading(false);
      toast.error(err.message);
    }
  };

  const validateOtp = async () => {
    try {
      setValidateOtpLoading(true);
      const result = await orderService.validateOtpForPayment({
        code: otp,
        phone: mobileNumber.replaceAll(" ", ""),
      });
      openModalVerificationCode.current.hide();
      openModalPayment.current.show();
      setValidateOtpLoading(false);
    } catch (err) {
      setValidateOtpLoading(false);
      setOtp("");
      toast.error(err.message);
    }
  };

  return (
    <MainContainer>
      <PaymentModal
        modalRef={openModalPayment}
        amount={totalPrice}
        setLoading={setCreateOrderLoading}
        loading={createOrderLoading}
        onComplete={async (data) => {
          createOrder(data);
        }}
      />
      <OTPModal
        openModalPayment={openModalPayment}
        setOtp={setOtp}
        otp={otp}
        modalRef={openModalVerificationCode}
        t={t}
        validateOtp={validateOtp}
        loading={validateOtpLoading}
      />
      <MobileScreenContainer>
        {tab !== 3 && (
          <Header>
            <ChevronContainer
              onClick={() => {
                if (tab === 1) {
                  navigate(-1);
                } else {
                  if (item.productId) {
                    navigate(-1);
                  } else {
                    setTab(1);
                  }
                }
              }}
            >
              <Chevron />
            </ChevronContainer>

            <PageName>{t("check-out")}</PageName>
          </Header>
        )}

        {!loading ? (
          <>
            {tab === 1 && (
              <Content>
                <Gap>
                  <TitleColumn>
                    <Title>{t("delivery")}</Title>
                    <ButtonSelected $active>{t("to-friend")}</ButtonSelected>
                  </TitleColumn>
                  <TitleColumn>
                    <Title>{t("username")}</Title>
                    <Text>@{item.user.username}</Text>
                  </TitleColumn>
                  <TitleColumn>
                    <Title>{t("customize-your-order")}</Title>
                    <CustomizeOrdersContainer>
                      <CustomizeOrder backgroundImage={Roses}>
                        <Gradient />
                        <OrderColumn>
                          <TitleOrder>{t("flowers")}</TitleOrder>
                          <PriceOrder>
                            {priceFormatter(99)} {getCurrencySymbol("sar")}
                          </PriceOrder>
                        </OrderColumn>
                        <SwitchContainer>
                          <Switch
                            activeBoxShadow={"0px 0px 2px 3px #8A4B5E"}
                            onColor="#8A4B5E"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onChange={() => {
                              setFlowers(!flowers);
                            }}
                            checked={flowers}
                          />
                        </SwitchContainer>
                      </CustomizeOrder>

                      <CustomizeOrder backgroundImage={Cake}>
                        <Gradient />
                        <OrderColumn>
                          <TitleOrder>{t("cake")}</TitleOrder>
                          <PriceOrder>
                            {priceFormatter(149)} {getCurrencySymbol("sar")}
                          </PriceOrder>
                        </OrderColumn>
                        <SwitchContainer>
                          <Switch
                            activeBoxShadow={"0px 0px 2px 3px #8A4B5E"}
                            onColor="#8A4B5E"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onChange={() => {
                              setCake(!cake);
                            }}
                            checked={cake}
                          />
                        </SwitchContainer>
                      </CustomizeOrder>

                      <CustomizeOrder backgroundImage={Choco}>
                        <Gradient />
                        <OrderColumn>
                          <TitleOrder>{t("chocolate")}</TitleOrder>
                          <PriceOrder>
                            {priceFormatter(70)} {getCurrencySymbol("sar")}
                          </PriceOrder>
                        </OrderColumn>
                        <SwitchContainer>
                          <Switch
                            activeBoxShadow={"0px 0px 2px 3px #8A4B5E"}
                            onColor="#8A4B5E"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onChange={() => {
                              setChocolate(!chocolate);
                            }}
                            checked={chocolate}
                          />
                        </SwitchContainer>
                      </CustomizeOrder>
                    </CustomizeOrdersContainer>
                  </TitleColumn>
                  <GiftCardContainer>
                    <TitleRow>
                      <SmallRow>
                        <Title>{t("add-gift-card")}</Title>
                        <GreenText>+10 SR</GreenText>
                      </SmallRow>

                      <Switch
                        activeBoxShadow={"0px 0px 2px 3px #8A4B5E"}
                        onColor="#8A4B5E"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onChange={() => setGiftCard(!giftCard)}
                        checked={giftCard}
                      />
                    </TitleRow>
                    <OccasionsContainer>
                      {occasions.map((item) => (
                        <ButtonSelected
                          $active={selectedOccasion.name !== item.name}
                          onClick={() =>
                            setSelectedOccasion({ name: item.name })
                          }
                          style={{
                            height: 35,
                            width: "fit-content",
                            padding: "5px 12px",
                            fontSize: 14,
                            textTransform: "capitalize",
                          }}
                        >
                          {t(`${item.name}`)}
                        </ButtonSelected>
                      ))}
                    </OccasionsContainer>
                    <TextArea
                      placeholder={t("congratulations-on-your")}
                      maxLength={100}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <TextLeft>
                      {100 - message.length} {t("characters-left")}
                    </TextLeft>
                  </GiftCardContainer>
                </Gap>

                <Button
                  style={{ width: "100%" }}
                  onClick={() => {
                    if (!disable()) {
                      setTab(2);
                    }
                  }}
                  $disabled={disable()}
                >
                  {t("next")}
                </Button>
              </Content>
            )}
            {tab === 2 && (
              <Content>
                <Gap>
                  <GiftCardContainer>
                    <TitleRow>
                      <Title>{t("add-ygii-box-wrapping")}</Title>

                      <Switch
                        activeBoxShadow={"0px 0px 2px 3px #8A4B5E"}
                        onColor="#8A4B5E"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onChange={() => setWrapping(!wrapping)}
                        checked={wrapping}
                        disabled={!!item.productId}
                      />
                    </TitleRow>
                  </GiftCardContainer>

                  <GiftCardContainer style={{ gap: 20 }}>
                    <TitleRow>
                      <StyledTitle>{t(`${item.name}`)}</StyledTitle>
                      <StyledValue>
                        {itemPrice} {getCurrencySymbol("sar")}
                      </StyledValue>
                    </TitleRow>
                    {flowers && (
                      <TitleRow>
                        <StyledTitle>{t("flowers")}</StyledTitle>
                        <StyledValue>
                          {priceFormatter(99)} {getCurrencySymbol("sar")}
                        </StyledValue>
                      </TitleRow>
                    )}
                    {cake && (
                      <TitleRow>
                        <StyledTitle>{t("cake")}</StyledTitle>
                        <StyledValue>
                          {priceFormatter(149)} {getCurrencySymbol("sar")}
                        </StyledValue>
                      </TitleRow>
                    )}
                    {chocolate && (
                      <TitleRow>
                        <StyledTitle>{t("chocolate")}</StyledTitle>
                        <StyledValue>
                          {priceFormatter(70)} {getCurrencySymbol("sar")}
                        </StyledValue>
                      </TitleRow>
                    )}
                    {giftCard && (
                      <TitleRow>
                        <StyledTitle>{t("gift-card")}</StyledTitle>
                        <StyledValue>
                          {priceFormatter(10)} {getCurrencySymbol("sar")}
                        </StyledValue>
                      </TitleRow>
                    )}
                    {wrapping && (
                      <TitleRow>
                        <StyledTitle>{t("ygii-wrapping")}</StyledTitle>
                        <StyledValue>
                          {priceFormatter(75)} {getCurrencySymbol("sar")}
                        </StyledValue>
                      </TitleRow>
                    )}
                    {!item.productId && (
                      <TitleRow>
                        <StyledTitle>{t("delivery")}</StyledTitle>
                        <StyledValue>
                          {priceFormatter(35)} {getCurrencySymbol("sar")}
                        </StyledValue>
                      </TitleRow>
                    )}
                    <Line />
                    <TitleRow>
                      <StyledTitle>{t("total-included-vat")}</StyledTitle>
                      <StyledValue style={{ fontFamily: "Avenir-Heavy" }}>
                        {priceFormatter(totalPrice)} {getCurrencySymbol("sar")}
                      </StyledValue>
                    </TitleRow>
                  </GiftCardContainer>

                  <StyledTitle style={{ color: "black" }}>
                    {t("choose-delivery-date")}
                  </StyledTitle>
                  <div style={{ position: "relative" }}>
                    <StyledDatePicker
                      placeholderText={t("delivery-date")}
                      selected={date}
                      onChange={(value) => {
                        const selectedDate = new Date(value);
                        setDate(selectedDate);
                      }}
                      minDate={fiveDaysFromNow}
                    />
                  </div>
                  <InputWrapper>
                    <Input
                      placeholder={t("full-name")}
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    <PhoneNumberInput
                      phone={mobileNumber}
                      placeholder={t("mobile")}
                      setPhone={setMobileNumber}
                    />
                  </InputWrapper>
                </Gap>

                <Button
                  style={{ width: "100%" }}
                  onClick={async () => {
                    if (
                      !disable2 &&
                      fullName &&
                      isValidPhoneNumber(mobileNumber)
                    ) {
                      try {
                        setSendOtpLoading(true);
                        const result = await orderService.sendOtpForPayment({
                          phone: mobileNumber.replaceAll(" ", ""),
                        });
                        openModalVerificationCode.current.show();
                        setSendOtpLoading(false);
                      } catch (e) {
                        toast.error(e.message);
                      }
                    }
                  }}
                  $disabled={
                    disable2 ||
                    !fullName ||
                    !isValidPhoneNumber(mobileNumber) ||
                    sendOtpLoading
                  }
                >
                  {sendOtpLoading ? <AppLoader /> : t("buy-now")}
                </Button>
              </Content>
            )}
          </>
        ) : (
          <LoaderWrapper>
            <MiniLoader />
          </LoaderWrapper>
        )}
      </MobileScreenContainer>
    </MainContainer>
  );
};

export default MobileAppCheckout;
