import { createSlice } from "@reduxjs/toolkit";
import {
  postInstantQuotationBuilder,
  postQuotationWithMultipleItemsBuilder,
} from "./builder";

const instantQuotationSlice = createSlice({
  name: "instantQuotationInfo",
  initialState: {
    postInstantQuotation: {
      url: "",
      loading: false,
    },
    postQuotationWithMultipleItemsUrl: "",
    postQuotationWithMultipleItemsPending: false,
  },
  reducers: {},

  extraReducers: (builder) => {
    postInstantQuotationBuilder(builder);
    postQuotationWithMultipleItemsBuilder(builder);
  },
});

// export const {} = instantQuotationSlice.actions;

export const selectedQuotationData = (state) =>
  state.instantQuotationInfo.postInstantQuotation;

export const selectedQuotationWithMultipleItemsData = (state) =>
  state.instantQuotationInfo.postQuotationWithMultipleItemsUrl;

export const selectedPostQuotationWithMultipleItemsPending = (state) =>
  state.instantQuotationInfo.postQuotationWithMultipleItemsPending;

export default instantQuotationSlice.reducer;
