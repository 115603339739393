import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MainContainer,
  Column,
  Button,
  ItemsColumn,
  ButtonContainer,
  PriceCard,
  Title,
  RightContainer,
  ContentContainer,
  Text,
  ButtonDisabled,
  OrderContentContainer,
  Separator,
  StyledText,
  StyledTitle,
  ColumnDiv,
  StyledButton,
  StyledButtonContainer,
  OrderSummaryText,
  Price,
  Total,
  StyledColumnDiv,
  MiniText,
  DiscountContainer,
  DiscountText,
  DiscountRow,
  DiscountInput,
  DiscountButton,
  DiscountAmountText,
} from "./CartPage.styles";
import {
  cartItemsData,
  removeCartItem,
  selectedMarketplaceItem,
  setCreateOrderDiscountCode,
  setCreateOrderSubTotal,
} from "../../../store/slices/marketplace/marketplaceSlice";
import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../../components/Header/Header";
import FooterComponent from "../../../components/Footer";
import Sidebar from "../utils/Sidebar";
import ShareCartProductModal from "../../../components/Modal/ShareCartProductModal";
import LoginModal from "../../../components/Modal/LoginModal";
import couponServices from "../../../api/services/coupon";
import { toast } from "react-toastify";
import { ReactComponent as LoaderWhite } from "../../../assets/icons/loader-white.svg";
import ProductCard from "./ProductCard";
import { HeaderWrapper } from "../MarketplacePage/MarketplacePage.styles";
import openToast from "../../../utils/toast";
import { selectedLocale } from "../../../store/slices/common/slice";
import GenerateQuotationModal from "../../../components/Modal/GenerateQuotationModal";

const CartPage = () => {
  const { t } = useTranslation();
  const items = useSelector(cartItemsData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const businessUser = useSelector(selectBusinessUserInfo);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [productId, setProductId] = useState(null);
  const [loginModal, setLoginModal] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const toastId = useRef(null);

  const [generateQuotationModal, setGenerateQuotationModal] = useState(false);
  const item = useSelector(selectedMarketplaceItem);
  const [activeColor, setActiveColor] = useState({});
  const [activeSize, setActiveSize] = useState({});
  const [activeCard, setActiveCard] = useState({});

  const subTotal = useMemo(() => {
    return items.reduce((total, item) => {
      if (item.buyBlank && !item.buyPrinted) {
        return total + (item.samplePrice * item.sampleQuantity || 0);
      } else if (!item.buyBlank && item.buyPrinted) {
        return total + (item.printedPrice * item.printedQuantity || 0);
      } else {
        return total + (item.totalPrice || 0);
      }
    }, 0);
  }, [items]);

  const totalPrice = useMemo(() => {
    if (isCouponValid && discountPercentage) {
      return subTotal - (subTotal * discountPercentage) / 100;
    }

    return subTotal;
  }, [discountPercentage, isCouponValid, subTotal]);

  const discount = useMemo(() => {
    if (isCouponValid && discountPercentage) {
      return ((subTotal * discountPercentage) / 100) * -1;
    }

    return 0;
  }, [discountPercentage, isCouponValid, subTotal]);

  const validateCoupon = async () => {
    setLoading(true);
    try {
      const result = await couponServices.isCouponValid({
        coupon: discountCode,
        product: "WEB_APP",
      });
      if (result.isValid) {
        toast.success(t(result.message));
        setIsCouponValid(true);
        setDiscountPercentage(result.data.discount);
        dispatch(
          setCreateOrderDiscountCode({
            code: discountCode,
            percentage: result.data.discount,
          })
        );
      } else {
        toast.info(t(result.message));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      openToast(toastId, "validate-coupon", "error", t(err.message));
    }
  };

  const checkIfPackaging = () => {
    let packagingExist = false;
    let existAnotherItems = false;

    for (const item of items) {
      if (item.isPackaging) {
        packagingExist = true;
      } else if (item.id) {
        existAnotherItems = true;
      }
    }
    return { packagingExist, existAnotherItems };
  };

  const locale = useSelector(selectedLocale);

  const ar = locale === "ar";
  return (
    <>
      <MainContainer>
        {businessUser.loggedIn && <Sidebar />}

        <HeaderWrapper>
          <Header
            showNav
            color
            showLogin
            showSignUp
            showCart
            showUser
            showFavorite
            display
          />
        </HeaderWrapper>

        <Column $loggedIn={businessUser.loggedIn} $ar={ar}>
          <ContentContainer>
            {items?.length > 0 ? (
              <ColumnDiv>
                <div>
                  <StyledTitle> {t("your-cart")} </StyledTitle>
                  <StyledText>
                    ({items?.length} {t("items")})
                  </StyledText>
                </div>
                <ItemsColumn>
                  {items?.map((product, index) => (
                    <ProductCard
                      key={index}
                      product={product}
                      index={index}
                      reverse={product.favorite}
                      isLoggedIn={businessUser.loggedIn}
                      setOpenShareModal={setOpenShareModal}
                      setProductId={setProductId}
                      removeCartItem={removeCartItem}
                      setLoginModal={setLoginModal}
                      businessUser={businessUser}
                    />
                  ))}
                </ItemsColumn>
                <StyledButtonContainer>
                  <StyledButton
                    onClick={() => {
                      businessUser.loggedIn
                        ? navigate("/admin-portal/marketplace")
                        : navigate("/marketplace");
                    }}
                  >
                    {t("continue-shopping")}
                  </StyledButton>
                </StyledButtonContainer>
              </ColumnDiv>
            ) : (
              <ColumnDiv $fullwidth>
                <Text $textalign>{t("you-have-no-products-in-your")}</Text>
                <Text $textalign>{t("shopping-cart")}.</Text>
              </ColumnDiv>
            )}
            <StyledColumnDiv>
              <OrderSummaryText>{t("order-summary")}</OrderSummaryText>
              <RightContainer>
                <PriceCard>
                  <OrderContentContainer>
                    <Title>{t("number-of-items")}: </Title>
                    <Title>{items.length} </Title>
                  </OrderContentContainer>
                  <Separator />
                  <DiscountContainer>
                    <DiscountText>{t("discount-code")}?</DiscountText>
                    <DiscountRow>
                      <form autoComplete="off">
                        <DiscountInput
                          placeholder={t("discount-code")}
                          value={discountCode}
                          onChange={(e) => setDiscountCode(e.target.value)}
                        />
                      </form>
                      <DiscountButton
                        onClick={() => {
                          validateCoupon();
                        }}
                      >
                        {loading ? (
                          <LoaderWhite style={{ width: 20, height: 20 }} />
                        ) : (
                          <> {t("apply")}</>
                        )}
                      </DiscountButton>
                    </DiscountRow>
                    {isCouponValid && (
                      <DiscountAmountText>
                        {`${t("discount2")}: ${discount?.toFixed(
                          2
                        )}SR (${discountPercentage}
                        %)`}
                      </DiscountAmountText>
                    )}
                  </DiscountContainer>
                  <div>
                    <OrderContentContainer>
                      <Total>{t("total")}: </Total>
                      <Price>{totalPrice?.toFixed(2)} SR</Price>
                    </OrderContentContainer>
                    <MiniText>{t("inclusive-of-vat")}</MiniText>
                  </div>
                  <ButtonContainer>
                    {items?.length > 0 &&
                    checkIfPackaging().existAnotherItems ? (
                      <Button
                        onClick={() => {
                          const { packagingExist, existAnotherItems } =
                            checkIfPackaging();
                          if (
                            items.length > 0 &&
                            existAnotherItems &&
                            !packagingExist
                          ) {
                            businessUser.loggedIn
                              ? navigate("/admin-portal/packaging")
                              : navigate("/packaging");
                          } else {
                            if (items.length > 0 && businessUser.loggedIn) {
                              navigate("/admin-portal/delivery-details");
                            } else if (items.length > 0) {
                              navigate("/delivery-details");
                            }
                            dispatch(setCreateOrderSubTotal(totalPrice));
                          }
                        }}
                      >
                        {t("proceed-to-checkout")}
                      </Button>
                    ) : (
                      <ButtonDisabled>
                        {t("proceed-to-checkout")}
                      </ButtonDisabled>
                    )}
                  </ButtonContainer>
                  {businessUser.role === "superAdmin" && (
                    <ButtonContainer>
                      {items?.length > 0 ? (
                        <Button
                          onClick={() => {
                            setGenerateQuotationModal(true);
                          }}
                        >
                          {t("generate-a-quotation")}
                        </Button>
                      ) : (
                        <ButtonDisabled>
                          {t("generate-a-quotation")}
                        </ButtonDisabled>
                      )}
                    </ButtonContainer>
                  )}
                </PriceCard>
              </RightContainer>
            </StyledColumnDiv>
          </ContentContainer>
        </Column>
      </MainContainer>
      {!businessUser.loggedIn && <FooterComponent />}
      <LoginModal open={loginModal} close={() => setLoginModal(false)} />

      <ShareCartProductModal
        isOpen={openShareModal}
        close={() => {
          setOpenShareModal(false);
          setProductId(null);
        }}
        productId={productId}
        setProductId={setProductId}
      />
      <GenerateQuotationModal
        open={generateQuotationModal}
        close={() => setGenerateQuotationModal(false)}
        minQuantity={item.minQuantity ?? 1}
        items={items}
      />
    </>
  );
};

export default CartPage;
