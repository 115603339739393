import { keyframes, styled } from "styled-components";

const skeletonLoading = keyframes`
  from {
    background-color: #dcc9cf;
  }
  to {

    background-color: #f3edef;

  }
`;

export const Card = styled.div`
  display: flex;

  border-radius: 20px;
  position: relative;

  background: #fff;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  height: 354px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  @media (max-width: 380px) {
    width: 90%;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 15px 0px 40px 0px;
`;

export const ImageContainer = styled.div`
  display: flex;
  cursor: pointer;
  animation: ${skeletonLoading} 0.6s linear infinite alternate;
  width: 300px;
  height: 291px;
  margin-bottom: -10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  @media (max-width: 380px) {
    width: 100%;
  }
`;

export const CardProductName = styled.span`
  width: 100px;
  height: 12px;
  border-radius: 10px;
  animation: ${skeletonLoading} 0.6s linear infinite alternate;
`;
export const ProductInfo = styled.div`
  padding: 15px 20px;
  border-radius: 10px;
  background: #fff;
  gap: 15px;
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 73px;
  box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.05);
`;
export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Price = styled.span`
  width: 65px;
  height: 10px;
  border-radius: 10px;
  animation: ${skeletonLoading} 0.6s linear infinite alternate;
`;
