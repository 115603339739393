import { createAsyncThunk } from "@reduxjs/toolkit";

import paymentServices from "../../../api/services/payment";

export const getPaymentMethod = createAsyncThunk(
  "payment/get-payment-method",
  async (requestData) => {
    return await paymentServices.getPaymentMethod(requestData);
  }
);

export const setDefaultPaymentMethod = createAsyncThunk(
  "payment/set-default-payment-method",
  async (requestData) => {
    return await paymentServices.setDefaultPaymentMethod(requestData);
  }
);

export const removePaymentMethod = createAsyncThunk(
  "payment/remove-payment-method",
  async (requestData) => {
    return await paymentServices.removePaymentMethod(requestData);
  }
);
