import React from "react";
import {
  CardProduct,
  CardProductName,
  Image,
  ProductNameContainer,
  ProductPrice,
} from "./SkeletonGASLoader.styles";

const SkeletonGASLoader = () => {
  const data = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }];
  return (
    <>
      {data.map((x) => (
        <CardProduct key={x.id}>
          <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
            <Image />

            <ProductNameContainer>
              <CardProductName />
              <ProductPrice />
            </ProductNameContainer>
          </div>
        </CardProduct>
      ))}
    </>
  );
};

export default SkeletonGASLoader;
