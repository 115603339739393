import { createSlice } from "@reduxjs/toolkit";
import {
  loginRecipientBuilder,
  logoutRecipientBuilder,
  signUpRecipientBuilder,
  userInfoUpdateBuilder,
  userInfoBuilder,
  validateCodeBuilder,
} from "./builder";

const initialState = {
  loggedIn: false,
  loginPending: false,
  userInfo: { id: null },
  data: {},
  code: "",
  error: null,
  verifyPhonePending: false,
  createAccountPending: false,
  userInfoUpdatePending: false,
  gettingUserInfo: false,
  token: "",
};

export const authSlice = createSlice({
  name: "recipientUser",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      const {
        id,
        fullName,
        email,
        phone,
        dateOfBirth,
        gender,
        profilePhoto,
        verified,
      } = action.payload;
      state.userInfo.id = id;
      state.userInfo.fullName = fullName;
      state.userInfo.email = email;
      state.userInfo.phone = phone;
      state.userInfo.dateOfBirth = dateOfBirth;
      state.userInfo.gender = gender;
      state.userInfo.profilePhoto = profilePhoto;
      state.userInfo.verified = verified;
    },
    setLoggedIn: (state, { payload }) => {
      const { loggedIn } = payload;
      state.loggedIn = loggedIn;
    },
    setPhone: (state, { payload }) => {
      const phone = payload.phone;
      state.data = { ...state.data, phone };
    },
    setRecipient: (state, { payload }) => {
      state.data = payload;
    },
    setCodeInput: (state, { payload }) => {
      state.code = payload;
    },
  },
  extraReducers: (builder) => {
    userInfoUpdateBuilder(builder);
    validateCodeBuilder(builder);
    userInfoBuilder(builder);
    signUpRecipientBuilder(builder);
    loginRecipientBuilder(builder);
    logoutRecipientBuilder(builder);
    builder.addCase("logout", () => ({ ...initialState, loggedIn: false }));
  },
});

export const {
  setLoggedIn,
  setPhone,
  setRecipient,
  setCodeInput,
  setUserInfo,
} = authSlice.actions;

export const selectLoggedIn = (state) => state.recipientUser.loggedIn;
export const selectRecipient = (state) => state.recipientUser.userInfo;
export const selectCodeInput = (state) => state.recipientUser.code;
export const selectCreateAccountPending = (state) =>
  state.recipientUser.createAccountPending;
export const selectVerifyRecipientPending = (state) =>
  state.recipientUser.verifyEmailPending;
export const selectLoginPending = (state) => state.recipientUser.loginPending;
export const selectUserInfo = (state) => state.recipientUser.userInfo;

export default authSlice.reducer;
