import { styled } from "styled-components";

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;

export const LoadingSize = styled.div`
  width: ${(props) => (props.$small ? "150px" : "200px")};
  margin-left: ${(props) => (props.$leftshift ? 250 : 0)};
`;
