import styled from "styled-components";
import DatePicker from "react-datepicker";

export const ContentContainer = styled.div`
  position: relative;
  width: 450px;

  display: flex;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
export const Title = styled.span`
  font-family: "Avenir-Heavy-Medium", sans-serif;
  font-size: 22px;
  text-align: center;
  max-width: 30rem;

  letter-spacing: -0.177777px;

  color: #fff;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  opacity: 0.5;
  margin-bottom: 10px;
`;

export const CloseContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  opacity: 0.5;
  position: absolute;
  top: -10px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

export const InviteLink = styled.input`
  width: 100%;
  background: none;
  border: none;
  font-family: "Avenir-Heavy", sans-serif;
  font-size: 20px;
  letter-spacing: -0.177777px;
  color: #fff;
  &:focus {
    outline: none;
  }
`;
export const InviteLinkContainer = styled.div`
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.15);
  -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  padding: 15px;
  border-radius: 20px;
  width: 350px;
`;

export const Button = styled.button`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 5px;
  background-color: #8a4b5e;
  border: 1.5px solid rgba(255, 255, 255, 0.3);
  padding: 12px;
  border-radius: 20px;

  font-family: "Avenir-Heavy", sans-serif;
  font-size: 16px;
  letter-spacing: -0.177777px;
  color: #fff;
  text-transform: uppercase;

  &:hover {
    background-color: ${(props) => (props.$disabled ? "#a16f7e" : "#8a4b5e")};
    transition: all 0.2s ease-out;
  }
  &:active {
    transition: scale 0.3s ease-in;
    transform: ${(props) => (props.$disabled ? "scale(0.95)" : "unset")};
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
  }
`;
export const StyledButton = styled(Button)`
  padding: 10px 24px;
`;
export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;
export const ButtonYes = styled.button`
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 5px;
  background-color: #8a4b5e;
  border: 1.5px solid rgba(255, 255, 255, 0.3);
  padding: 12px;
  border-radius: 20px;

  font-family: "Avenir-Heavy", sans-serif;
  font-size: 16px;
  letter-spacing: -0.177777px;
  color: #fff;
  text-transform: uppercase;
  cursor: ${(props) => (props.loading ? "default" : "pointer")};

  &:hover {
    background-color: ${(props) => (props.loading ? "#8A4B5E" : "#4ab639")};
    transition: all 0.3s ease-out;
  }
  &:active {
    transition: scale 0.3s ease-in;
    transform: ${(props) => (props.loading ? "unset" : "scale(0.95)")};
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
  }
`;

export const ButtonNo = styled.button`
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 5px;
  background-color: #8a4b5e;
  border: 1.5px solid rgba(255, 255, 255, 0.3);
  padding: 12px;
  border-radius: 20px;

  font-family: "Avenir-Heavy", sans-serif;
  font-size: 16px;
  letter-spacing: -0.177777px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: #cc0000;
    transition: all 0.3s ease-out;
  }
  &:active {
    transition: scale 0.3s ease-in;
    transform: scale(0.95);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
  }
`;

export const Input = styled.input`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 5px;
  width: 250px;
  font-family: "Avenir-Heavy", sans-serif;
  font-size: 18px;
  letter-spacing: -0.177777px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  height: 4.5rem;

  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.15);
  -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  padding: 15px;
  border-radius: 20px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
export const StyledInput = styled(Input)`
  font-family: "Avenir-Medium", sans-serif;
  text-align: left;
  font-size: 14px;
  text-transform: unset;
`;

export const StyledDatePicker = styled(DatePicker)`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 5px;
  /* width: 250px; */
  letter-spacing: -0.177777px;
  color: #fff;
  text-align: center;

  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.15);
  -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  padding: 15px;
  border-radius: 20px;

  font-family: "Avenir-Medium", sans-serif;
  text-align: left;
  font-size: 14px;
  text-transform: unset;
`;

export const PackageTypeButton = styled.button`
  background: ${(props) =>
    props.$active ? "#8A4B5E" : "rgba(255, 255, 255, 0.15)"};
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: #fff;
  outline: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;

  height: 3.2rem;
  border-radius: 2rem;
  width: 10rem;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #8a4b5e;
  }
`;
export const PackageTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
`;

export const Text = styled.span`
  font-family: "Avenir-Medium", sans-serif;
  font-size: 14px;

  color: #fff;
`;
