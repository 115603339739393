import { Dot, DotsContainer } from "./Dots.styles";

const Dots = ({ total, activeIndex, onDotClick, bg, rotate }) => {
  return (
    <DotsContainer $bg={bg} $rotate={rotate}>
      {Array.from({ length: total }).map((_, index) => (
        <Dot
          $active={index === activeIndex}
          key={index}
          onClick={(event) => {
            event.stopPropagation();
            onDotClick(index);
          }}
        />
      ))}
    </DotsContainer>
  );
};

export default Dots;
