import { createAsyncThunk } from "@reduxjs/toolkit";
import commonServices from "../../../api/services/common";

export const countClickAction = createAsyncThunk(
  "coupon/count-click-action",
  async (requestData) => {
    return await commonServices.countClickAction(requestData);
  }
);

export const getBusinessRulesVariables = createAsyncThunk(
  "common/business-rules-variables",
  async () => {
    return await commonServices.getBusinessRulesVariables();
  }
);
