import styled from "styled-components";

export const MainContainer = styled.div`
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);

  min-height: 100vh;
  display: flex;
  color: white;
`;

export const BigText = styled.h1`
  font-family: "Avenir-Black";
  font-style: normal;

  font-size: 50px;
  line-height: 68px;
  text-align: center;
  letter-spacing: -0.177777px;
  color: #8a4b5e;

  @media (max-width: 600px) {
    font-size: 40px;
    line-height: 40px;
  }
  @media (max-width: 410px) {
    margin-top: 40px;
  }
`;
export const MediumText = styled.span`
  font-family: "Avenir-Medium";
  font-style: normal;

  font-size: 25px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.177777px;

  color: #8a4b5e;
  @media (max-width: 410px) {
    margin-top: 1px;
  }
`;
export const Container = styled.div`
  padding-left: 80px;
  margin-top: -40px;
  margin-left: 250px;
  padding-right: 80px;
  width: 100%;
  flex-direction: row;
  gap: 14px;
  transform: scale(0.85);
  flex-grow: 1;
  flex-direction: column;
  display: flex;

  @media (max-width: 1360px) {
    margin-top: -100px;
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (max-width: 870px) {
    margin-left: 0px;
  }
`;
export const DescBox = styled.div`
  margin-top: 25px;
  padding: 0px 30px 30px 30px;
`;

export const Title = styled.span`
  padding: 20px 30px 0px 30px;
  font-family: "Avenir-Heavy";
  font-size: 40px;
  color: #343456;

  @media (max-width: 1400px) {
    font-size: 35px;
  }
  @media (max-width: 500px) {
    font-size: 30px;
  }
`;

export const Subtitle = styled.span`
  padding: 0px 30px;
  font-family: "Avenir-Medium";
  font-size: 20px;
  color: rgba(52, 52, 86, 0.8);

  @media (max-width: 500px) {
    font-size: 15px;
  }
`;
export const Desc = styled.span`
  font-family: "Avenir-Medium";
  font-size: 14px;
  color: rgba(52, 52, 86, 0.8);

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const HeaderTitle = styled.h1`
  font-family: "Avenir-Black";
  font-style: normal;

  font-size: 50px;
  line-height: 68px;
  text-align: center;
  letter-spacing: -0.177777px;

  color: #8a4b5e;
`;

export const HeaderSubtitle = styled.h2`
  font-family: "Avenir-Medium";
  font-style: normal;

  font-size: 25px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
`;

export const Row = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (max-width: 1360px) {
    flex-direction: column;
    gap: 10px;
  }
  @media (max-width: 1200px) {
    margin-top: 5px;
  }
`;

export const CardsRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 40px;
    margin-top: 30px;
  }
`;

export const Card = styled.div`
  border: 1px solid rgb(1, 1, 1, 0.15);
  width: 405px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  // position: relative;
  // padding-top: 50px;

  @media (max-width: 1360px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  @media (max-width: 410px) {
    transform: scale(0.9);
    margin-bottom: -20px;
  }
  @media (max-width: 360px) {
    transform: scale(0.8);
    margin-bottom: -80px;
  }
`;

export const CardHeaderContainer = styled.div`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;

  flex-direction: column;
  width: 100%;
`;
export const Date = styled.span`
  font-family: "Avenir-Black";
  font-style: normal;

  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
`;
export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
  gap: 20px;
  margin-bottom: -20px;
`;
export const Cards = styled.div`
  flex-direction: row;
  display: flex;
  gap: 20px;
`;
export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
`;
export const RowPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -5px;
  gap: 10px;
  align-items: center;
`;
export const PriceContainer = styled.div`
  background-color: #e8dbdf;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  position: relative;
  flex-direction: column;
`;
export const Price = styled.span`
  font-family: "Avenir-Black";
  font-style: normal;
  font-size: 40px;
  line-height: 55px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;

  @media (max-width: 1282px) {
    font-size: 36px;
  }
  @media (max-width: 500px) {
    font-size: 30px;
  }
`;

export const PriceInfo = styled.span`
  font-family: "Avenir-Medium";
  font-size: 14px;

  color: #343456;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

export const Benefits = styled.div`
  transition: all 0.2 ease-out;
`;

export const BenefitsFirst = styled.div`
  margin-top: 10px;
`;

export const ButtonPrice = styled.button`
  color: white;
  font-family: "Avenir-Black";
  font-style: normal;
  font-size: 22px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 0.5em 2em;
  border: transparent;
  background: linear-gradient(180deg, #a87283 0%, #8a4b5e 100%);
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 30px;
  &:hover {
    transform: scale(1.05);
    background: rgb(2, 0, 36);
    background: #8a4b5e 100%;
  }
  &:active {
    transform: translate(0em, 0.2em);
  }
  transition: transform 0.3s ease;

  @media (max-width: 500px) {
    font-size: 18px;
  }
`;
export const ButtonSkip = styled.button`
  color: white;
  font-family: "Avenir-Black";
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 0.5em 1.5em;
  border: transparent;
  background: linear-gradient(180deg, #a87283 0%, #8a4b5e 100%);
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 30px;
  &:hover {
    transform: scale(1.05);
    background: rgb(2, 0, 36);
    background: #8a4b5e 100%;
  }
  &:active {
    transform: translate(0em, 0.2em);
  }
  transition: transform 0.3s ease;
`;

export const ButtonBox = styled.div`
  padding: 15px 0px;
  display: flex;
  width: 100%;
  margin-top: 15px;
  justify-content: center;
`;

export const ButtonBoxLast = styled.div`
  padding: 15px 0px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 25px;
  flex-direction: column;
`;

export const BenefitsBox = styled.div`
  display: flex;
  padding: 0px 30px;
  gap: 10px;
  flex-direction: row;
`;

export const BenefitsBoxFirst = styled.div`
  display: flex;
  padding: 0px 30px;
  margin-top: 10px;
  gap: 10px;
  flex-direction: row;
`;

export const BenefitsBoxLast = styled.div`
  margin-bottom: 25px;
  display: flex;
  padding: 0px 30px;
  gap: 10px;
  flex-direction: row;
`;

export const BenefitsText = styled.span`
  font-family: "Avenir-Light";
  font-style: normal;

  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;

  @media (max-width: 1400px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

export const Discount = styled.span`
  font-family: "Avenir-Black";
  font-style: normal;

  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.177777px;

  color: #ffffff;
`;
export const InvisibleText = styled.span`
  font-family: "Avenir-Black";
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.177777px;

  color: transparent;
`;

export const DiscountContainer = styled.div`
  background: #8a4b5e;
  mix-blend-mode: normal;
  padding: 6px 13px;
`;
export const Invisible = styled.div`
  background: transparent;
  mix-blend-mode: normal;
  padding: 6px 13px;
`;

export const SkipContainer = styled.div`
  display: flex;
  padding-right: 90px;
  position: absolute;
  right: 0;
`;

export const ButtonPriceChosen = styled.button`
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: default;
  font-family: "Avenir-Black";
  font-style: normal;
  font-size: 22px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 0.5em 2em;
  border: transparent;
  background: linear-gradient(180deg, #ade5ce 0%, #66d3a4 100%);
`;

export const ButtonCancel = styled.button`
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: default;
  font-family: "Avenir-Black";
  font-style: normal;

  font-size: 22px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 0.5em 2em;
  border: transparent;
  background: linear-gradient(180deg, #e5e4e2 0%, #a8a8a8 100%);
  box-shadow: 0px 8px 15px -5px rgb(128, 128, 128);
  cursor: pointer;
  border-radius: 30px;
  &:hover {
    transform: scale(1.05);
    background: rgb(2, 0, 36);
    background: #a9a9a9;
  }
  &:active {
    transform: translate(0em, 0.2em);
  }
  transition: transform 0.3s ease;
`;

export const CancelSubscriptionText = styled.span`
  font-family: "Avenir-Medium";
  font-size: 16px;
  cursor: pointer;
  color: rgba(52, 52, 86, 0.8);
  text-decoration: underline;
  pointer: default;
  @media (max-width: 500px) {
    font-size: 15px;
  }
`;

export const PreviousPrice = styled.span`
  text-decoration: line-through;
  font-family: "Avenir-Black";
  font-style: normal;
  font-size: 20px;
  margin-bottom: -12px;
  line-height: 55px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  @media (max-width: 1282px) {
    font-size: 15px;
  }
`;
