import React, { useEffect, useState } from "react";
import {
  Title,
  Subtitle,
  ButtonConfirm,
  ButtonsContainer,
  TitleContainer,
  ChevronBack,
  TextArea,
  FeedBackContainer,
  RadioButtonsContainer,
  Scroll,
  Gap,
} from "./PGMPages.styles";
import RadioButtons from "../../components/RadioButtons/RadioButtons";
import { rejectCompanyGiftingToken } from "../../store/slices/gift/asyncThunks";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { ReactComponent as Loading } from "../../assets/icons/loader-white.svg";
import { selectRejectCompanyGiftingTokenPending } from "../../store/slices/gift/slice";

const RejectComponent = ({ setTab, t, dispatch, token }) => {
  const [select, setSelect] = useState(null);
  const [text, setText] = useState("");
  const [feedback, setFeedback] = useState("");
  const loading = useSelector(selectRejectCompanyGiftingTokenPending);

  const onPick = (id) => {
    if (!loading) {
      setSelect(id);
    }
  };

  useEffect(() => {
    if (select === 1) {
      setFeedback(`I dont' know the sender.`);
    } else if (select === 2) {
      setFeedback(text);
    }
  }, [select, text]);

  const disable = () => {
    if (select === 1) {
      return true;
    } else if (select === 2 && text !== "") {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = () => {
    dispatch(rejectCompanyGiftingToken({ token, feedback }))
      .unwrap()
      .then(() => {
        setTab("back-to");
      })
      .catch((err) => {
        toast.error(t(err.message));
      });
  };

  return (
    <Scroll>
      <Gap>
        <TitleContainer>
          <ChevronBack onClick={() => setTab("pick-your-gift")} />
          <Title>{t("pick-your-gift-small")}</Title>
        </TitleContainer>

        <FeedBackContainer>
          <Subtitle>{t("give-us-feedback")}</Subtitle>
          <RadioButtonsContainer>
            <RadioButtons
              column
              label1={t("i-dont-know-the-sender")}
              label2={t("other-reason")}
              checked={select === 1}
              checked2={select === 2}
              onChange={() => {
                onPick(1);
              }}
              onChange2={() => {
                onPick(2);
              }}
            />
            {select === 2 && (
              <TextArea
                disabled={select === 1 || !select}
                rows={4}
                placeholder={t("type-here")}
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            )}
          </RadioButtonsContainer>
        </FeedBackContainer>
      </Gap>

      <ButtonsContainer>
        {!loading ? (
          <ButtonConfirm
            onClick={!disable() ? () => {} : () => onSubmit()}
            $center
            disabled={!disable()}
          >
            {t("submit")}
          </ButtonConfirm>
        ) : (
          <ButtonConfirm $center>
            <Loading height={25} />
          </ButtonConfirm>
        )}
      </ButtonsContainer>
    </Scroll>
  );
};

export default RejectComponent;
