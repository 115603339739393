import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";

export const MainContainer = styled.div`
  flex-direction: column;
  background: #f6f6f9;
  min-height: 100vh;
  display: flex;
`;
export const HeaderWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  justify-content: center;
`;
export const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 75px;

  justify-content: center;
  @media (max-width: 870px) {
    margin-top: 30px;
  }
`;
export const Column = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  width: ${(props) => (props.$loggedIn ? "1440px" : "100%")};

  transition: all 0.2s ease-in-out;
  padding: ${(props) =>
    props.$loggedIn ? "80px 40px 80px 40px" : "80px 40px 80px 250px"};

  margin-top: ${(props) => (props.$upgradePackage ? "60px" : "0px")};

  @media (max-width: 1440px) {
    width: 100%;
  }
  @media (max-width: 870px) {
    margin-top: ${(props) => (props.$upgradePackage ? "40px" : "0px")};

    padding: ${(props) =>
      props.$loggedIn ? "80px 40px 40px 40px" : "80px 40px 40px 40px"};
  }
`;

export const BackContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
  border-radius: 25%;
`;
export const Icons = styled.div`
  display: flex;
  position: absolute;
  z-index: 50;
  cursor: pointer;
  justify-content: center;
  padding: 5px;
  flex-direction: row;
  align-items: center;
  right: 2px;
  top: 2px;
  gap: 6px;
`;
export const SectionTitle = styled.h1`
  color: #8a4b5e;
  font-family: "Avenir";
  display: flex;
  align-self: flex-start;
  margin-top: 40px;
  margin-left: ${(props) => (props.$loggedIn ? "45px" : "0px")};
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.178px;
  text-transform: capitalize;
`;
export const ImageContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: -10px;
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  margin-top: 28px;
  gap: 10px;
  height: 80px;
  max-width: 90%;
  padding-inline: 10px;

  &&::-webkit-scrollbar {
    height: 3.8px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: #8a4b5e58;
    border-radius: 10px;
  }

  &&::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

export const SortName = styled.span`
  color: ${(props) => (props.selected ? "#fff" : "rgba(255, 250, 250, 0.85)")};
  background-color: #8a4b5e;
  user-select: none;
  text-align: center;
  font-family: "Avenir-Medium";
  font-size: 15px;
  letter-spacing: 0.067px;
  display: flex;
  cursor: pointer;
  text-decoration: ${(props) => (props.selected ? "underline" : "none")};
  padding: 10px 1.8rem;
  border-radius: 10px;

  &:hover {
    transform: scale(1.1);
    transition: all 0.1s ease-in-out;
    font-weight: 600;
    color: #fff;
  }
`;
export const CardProduct = styled.div`
  border-radius: 20px;
  position: relative;

  background: #fff;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  height: 304px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
`;
export const CardProductName = styled.span`
  color: #000;
  font-family: "Avenir";
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.067px;
`;
export const ProductInfo = styled.div`
  padding: 15px 20px;
  border-radius: 10px;
  background: #fff;
  gap: 6px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 73px;
  box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.05);
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const Price = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-family: "Avenir";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.067px;
`;

export const StyledInfinityScroll = styled(InfiniteScroll)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: ${(props) => (props.$admin ? "15px 0px 40px 0px" : "40px 0px")};
`;

export const Text = styled.span`
  color: #8a4b5e;
  user-select: none;
  font-family: "Avenir-Medium";
  font-size: 2rem;

  letter-spacing: 0.067px;
  text-align: center;
  margin-top: 8rem;
`;

export const Subtitle = styled.span`
  color: #8a4b5e;
  font-family: "Avenir-Medium";
  font-size: 2.5rem;
  line-height: 3.2rem;
  text-align: center;
  margin-top: 80px;

  @media (max-width: 645px) {
    font-size: 2rem;
    text-align: center;
  }
`;

export const StyledMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1200px) {
    top: ${(props) => (props.$upgradePackage ? "120px" : "68px")};
  }
`;

export const GiftBoxImage = styled.img`
  user-select: none;
  background-color: transparent;
  height: 550px;
  border-radius: 21px;
  width: 100%;
  max-width: 1440px;
  object-fit: cover;

  @media (max-width: 870px) {
    height: auto;
    min-height: 32rem;
  }
`;
