import { keyframes, styled } from "styled-components";

const skeletonLoading = keyframes`
  from {
    background-color: #dcc9cf;
  }
  to {

    background-color: #f3edef;

  }
`;
export const Image = styled.div`
  border-radius: 20px;
  width: 80%;
  height: 172px;
  margin-top: 20px;
  align-self: center;
  animation: ${skeletonLoading} 0.6s linear infinite alternate;
`;

export const CardProduct = styled.div`
  position: relative;
  gap: 10px;
  outline: 1px solid rgb(1, 1, 1, 0.15);
  width: 215px;
  height: 335px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  cursor: pointer;

  &:active {
    transition: all 0.2s ease-in;
    opacity: 0.8;
    transform: scale(0.98);
  }
`;

export const CardProductName = styled.div`
  width: 150px;
  height: 10px;
  border-radius: 10px;
  animation: ${skeletonLoading} 0.6s linear infinite alternate;
`;

export const ProductNameContainer = styled.div`
  display: flex;
  height: 120px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 15px 15px 20px 15px;
  background: #e8dbdf;
  gap: 10px;
  margin-top: auto;
`;

export const ProductPrice = styled.div`
  width: 100px;
  height: 15px;
  border-radius: 10px;
  animation: ${skeletonLoading} 0.6s linear infinite alternate;
`;
