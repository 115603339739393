import React, { useEffect, useRef, useState } from "react";
import {
  ContentContainer,
  ButtonNext,
  ButtonBack,
  Buttons,
  QuestRow,
  QuestText,
  QuestSmallGap,
  QuestInput,
  QuestSmallInput,
  Row2,
  QuestPriceBreak,
} from "../Steps.styles";

import { useTranslation } from "react-i18next";
import Checkbox from "../../Checkbox/Checkbox";
import { ReactComponent as Add } from "../../../assets/icons/add-filled.svg";
import { toast } from "react-toastify";
import UseAnimations from "react-useanimations";
import trash2 from "react-useanimations/lib/trash2";
import {
  addItemPhotos,
  resetAddItem,
  selectAddItem,
  setAddItemData,
} from "../../../store/slices/marketplace/marketplaceSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  createItem,
  editItem,
} from "../../../store/slices/marketplace/asyncThunks";
import { api } from "../../../store/api";
import store from "../../../store";
import openToast from "../../../utils/toast";

const AddItemStepPricing = ({
  onBack,
  onNext,
  photos,
  setLoading,
  action,
  itemId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [uploadDesign, setUploadDesign] = useState(false);
  const [minOrderQty, setMinOrderQty] = useState(false);
  const [priceBreak, setPriceBreak] = useState(false);
  const [buySample, setBuySample] = useState(false);
  const [buyPrinted, setBuyPrinted] = useState(false);
  const [designWithoutColor, setDesignWithoutColor] = useState(false);

  const [minOrder, setMinOrder] = useState("");
  const [bussDays, setBussDays] = useState(15);
  const [blankPrice, setBlankPrice] = useState("");
  const [printPrice, setPrintPrice] = useState("");
  const [additionalPrices, setAdditionalPrices] = useState([
    { minQuantity: "", discount: "" },
  ]);

  const photoUrls = useSelector(addItemPhotos);
  const storedAddItemData = useSelector(selectAddItem);
  const toastId = useRef(null);

  useEffect(() => {
    if (storedAddItemData) {
      const {
        userCanUploadFile,
        isMinOrderQty,
        minOrderQty,
        productionTime,
        priceBrake,
        buySample,
        blankPrice,
        buyPrintedSample,
        printPrice,
        additionalPrices,
        designWithoutColor,
      } = storedAddItemData;

      setUploadDesign(userCanUploadFile ?? false);
      setMinOrderQty(isMinOrderQty ?? false);
      setPriceBreak(priceBrake ?? false);
      setBuySample(buySample ?? false);
      setBuyPrinted(buyPrintedSample ?? false);
      setMinOrder(minOrderQty ?? "");
      setBussDays(productionTime ?? 15);
      setBlankPrice(blankPrice ?? "");
      setPrintPrice(printPrice ?? "");
      setAdditionalPrices(
        additionalPrices ?? [{ minQuantity: "", discount: "" }]
      );
      setDesignWithoutColor(designWithoutColor ?? false);
    }
  }, [storedAddItemData]);

  const handleInputChange = (e, setter) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setter(value);
    }
  };

  const isEmptyInputPriceDiscounts = () => {
    for (const row of additionalPrices) {
      if (row.minQuantity === "" || row.discount === "") {
        return true;
      }
    }

    return false;
  };

  const checkMinimumQuantityOrder = () => {
    for (const priceBreak of additionalPrices) {
      if (priceBreak.minQuantity < minOrder) {
        return true;
      }
    }

    return false;
  };

  const updateItemStep4Obj = async () => {
    if (minOrderQty && minOrder === "") {
      openToast(
        toastId,
        "edit-item",
        "error",
        t("you-must-fill-min-order-qty")
      );
    } else if (priceBreak && isEmptyInputPriceDiscounts()) {
      openToast(
        toastId,
        "edit-item",
        "error",
        t("you-must-fill-min-qty-discount")
      );
    } else if (buySample && blankPrice === "") {
      openToast(toastId, "edit-item", "error", t("you-must-fill-blank-price"));
    } else if (buyPrinted && printPrice === "") {
      openToast(toastId, "edit-item", "error", t("you-must-fill-print-price"));
    } else if (bussDays === "") {
      openToast(
        toastId,
        "edit-item",
        "error",
        t("you-must-fill-business-days")
      );
    } else if (minOrderQty && priceBreak && checkMinimumQuantityOrder()) {
      openToast(
        toastId,
        "edit-item",
        "error",
        t("minimum-order-quantity-error")
      );
    } else {
      await createItemAction();
    }
  };

  const saveDataToStore = () => {
    const obj = {
      userCanUploadFile: uploadDesign,
      designWithoutColor: designWithoutColor,
      isMinOrderQty: minOrderQty,
      minOrderQty: minOrder,
      productionTime: bussDays,
      priceBrake: priceBreak,
      buySample: buySample,
      blankPrice: blankPrice,
      buyPrintedSample: buyPrinted,
      printPrice: printPrice,
      additionalPrices,
    };
    dispatch(setAddItemData(obj));
  };

  const handleAddMore = () => {
    setAdditionalPrices([
      ...additionalPrices,
      { minQuantity: "", discount: "" },
    ]);
  };

  const handlePriceChange = (e, index) => {
    const value = e.target.value;
    const updatedPrices = [...additionalPrices];

    updatedPrices[index] = { ...updatedPrices[index], minQuantity: value };
    setAdditionalPrices(updatedPrices);
  };

  const handleDiscountChange = (e, index) => {
    const value = e.target.value;
    const updatedPrices = [...additionalPrices];
    updatedPrices[index] = { ...updatedPrices[index], discount: value };
    setAdditionalPrices(updatedPrices);
  };

  const handleRemove = (index) => {
    const updatedPrices = [...additionalPrices];
    updatedPrices.splice(index, 1);
    setAdditionalPrices(updatedPrices);
  };

  const handleMinOrder = (minOrder) => {
    const tmpAdditionalPrices = [];
    for (const priceBreak of additionalPrices) {
      tmpAdditionalPrices.push({
        ...priceBreak,
        ...(priceBreak.minQuantity < minOrder && { minQuantity: minOrder }),
      });
    }

    setAdditionalPrices(tmpAdditionalPrices);
  };

  const createItemAction = async () => {
    let formData = new FormData();
    formData.append("name", storedAddItemData.name);
    formData.append("nameAr", storedAddItemData.nameAr);
    formData.append("price", storedAddItemData.price);

    if (storedAddItemData.description) {
      formData.append("description", storedAddItemData.description);
    }
    if (storedAddItemData.descriptionAr) {
      formData.append("descriptionAr", storedAddItemData.descriptionAr);
    }
    formData.append("currency", "sar");

    for (const categoryId of storedAddItemData.categoryId) {
      formData.append("categories[]", categoryId);
    }

    if (action === "edit") {
      if (photoUrls.length > storedAddItemData.displayPhoto) {
        formData.append(
          "photoUrls[]",
          photoUrls[storedAddItemData.displayPhoto].url
        );

        const printAreasObj =
          storedAddItemData.printAreas[storedAddItemData.displayPhoto];

        if (printAreasObj) {
          formData.append(
            "photoAreas[]",
            JSON.stringify({
              top: printAreasObj.topScaled,
              left: printAreasObj.leftScaled,
              width: printAreasObj.widthScaled,
              height: printAreasObj.heightScaled,
            })
          );
        } else {
          formData.append("photoAreas[]", "");
        }

        formData.append("isFirstPhotoFromUrl", true);
      } else {
        formData.append("isFirstPhotoFromUrl", false);
        formData.append(
          "files",
          photos[storedAddItemData.displayPhoto - photoUrls.length]
        );

        const printAreasObj =
          storedAddItemData.printAreas[storedAddItemData.displayPhoto];

        if (printAreasObj) {
          formData.append(
            "photoAreas[]",
            JSON.stringify({
              top: printAreasObj.topScaled,
              left: printAreasObj.leftScaled,
              width: printAreasObj.widthScaled,
              height: printAreasObj.heightScaled,
            })
          );
        } else {
          formData.append("photoAreas[]", "");
        }
      }

      for (var j = 0; j < photoUrls.length; j++) {
        if (storedAddItemData.displayPhoto !== j) {
          formData.append("photoUrls[]", photoUrls[j].url);

          const printAreasObj = storedAddItemData.printAreas[j];

          if (printAreasObj) {
            formData.append(
              "photoAreas[]",
              JSON.stringify({
                top: printAreasObj.topScaled,
                left: printAreasObj.leftScaled,
                width: printAreasObj.widthScaled,
                height: printAreasObj.heightScaled,
              })
            );
          } else {
            formData.append("photoAreas[]", "");
          }
        }
      }

      for (var k = 0; k < photos.length; k++) {
        if (storedAddItemData.displayPhoto - photoUrls.length !== k) {
          formData.append("files", photos[k]);

          const printAreasObj =
            storedAddItemData.printAreas[photoUrls.length + k];

          if (printAreasObj) {
            formData.append(
              "photoAreas[]",
              JSON.stringify({
                top: printAreasObj.topScaled,
                left: printAreasObj.leftScaled,
                width: printAreasObj.widthScaled,
                height: printAreasObj.heightScaled,
              })
            );
          } else {
            formData.append("photoAreas[]", "");
          }
        }
      }
    } else {
      formData.append("files", photos[storedAddItemData.displayPhoto]);
      let photosLength = 1;

      const printAreasObj =
        storedAddItemData.printAreas[storedAddItemData.displayPhoto];
      if (printAreasObj) {
        formData.append(
          "photoAreas[]",
          JSON.stringify({
            top: printAreasObj.topScaled,
            left: printAreasObj.leftScaled,
            width: printAreasObj.widthScaled,
            height: printAreasObj.heightScaled,
          })
        );
      } else {
        formData.append("photoAreas[]", "");
      }

      for (var i = 0; i < photos.length; i++) {
        if (storedAddItemData.displayPhoto !== i) {
          formData.append("files", photos[i]);
          const printAreasObj = storedAddItemData.printAreas[i];
          photosLength++;
          if (printAreasObj) {
            formData.append(
              "photoAreas[]",
              JSON.stringify({
                top: printAreasObj.topScaled,
                left: printAreasObj.leftScaled,
                width: printAreasObj.widthScaled,
                height: printAreasObj.heightScaled,
              })
            );
          } else {
            formData.append("photoAreas[]", "");
          }
        }
      }
      formData.append("itemPhotosLength", photosLength);
    }

    for (var i = 0; i < storedAddItemData.attributes.length; i++) {
      for (const item of storedAddItemData.attributes[i].variables) {
        formData.append(
          "subcategories[]",
          JSON.stringify({ id: item.id, photoIndexes: item.value.photoIndexes })
        );
      }
    }

    formData.append("uploadDesign", uploadDesign);
    formData.append("designWithoutColor", designWithoutColor);
    if (minOrderQty) {
      formData.append("minQuantity", minOrder);
    }

    formData.append("productionTime", bussDays);

    if (priceBreak) {
      for (const row of additionalPrices) {
        formData.append("priceBreaks[]", JSON.stringify(row));
      }
    }

    if (buySample) {
      formData.append("samplePrice", blankPrice);
    }

    if (buyPrinted) {
      formData.append("printedPrice", printPrice);
    }

    setLoading(true);

    if (action === "create") {
      dispatch(createItem(formData))
        .unwrap()
        .then(() => {
          dispatch(resetAddItem());
          setLoading(false);
          const entries = api.util.selectInvalidatedBy(store.getState(), [
            "marketplaceItems",
          ]);
          for (const { originalArgs } of entries) {
            dispatch(
              api.endpoints.getMarketplaceItems.initiate(originalArgs, {
                subscribe: false,
                forceRefetch: true,
              })
            );
          }

          onNext();
        })
        .catch((err) => {
          setLoading(false);
          toast.warning(t(err.message));
        });
    } else {
      dispatch(editItem({ itemId, item: formData }))
        .unwrap()
        .then(() => {
          dispatch(resetAddItem());
          setLoading(false);

          const entries = api.util.selectInvalidatedBy(store.getState(), [
            "marketplaceItems",
          ]);
          for (const { originalArgs } of entries) {
            dispatch(
              api.endpoints.getMarketplaceItems.initiate(originalArgs, {
                subscribe: false,
                forceRefetch: true,
              })
            );
          }

          onNext();
        })
        .catch((err) => {
          setLoading(false);
          toast.warning(t(err.message));
        });
    }
  };

  return (
    <>
      <ContentContainer>
        <QuestRow>
          <QuestText>{t("question-1")}</QuestText>
          <QuestSmallGap>
            <Checkbox
              checked={uploadDesign}
              onChange={() => setUploadDesign(!uploadDesign)}
            />
          </QuestSmallGap>
        </QuestRow>
        {uploadDesign && (
          <QuestRow>
            <QuestText>{t("design-without-color")}</QuestText>
            <QuestSmallGap>
              <Checkbox
                checked={designWithoutColor}
                onChange={() => setDesignWithoutColor(!designWithoutColor)}
              />
            </QuestSmallGap>
          </QuestRow>
        )}
        <QuestRow>
          <QuestText>{t("question-2")}</QuestText>
          <QuestSmallGap>
            <Checkbox
              checked={minOrderQty}
              onChange={() => setMinOrderQty(!minOrderQty)}
            />
            {minOrderQty && (
              <QuestInput
                type="number"
                min="1"
                placeholder={t("minimum-order")}
                value={minOrder}
                onChange={(e) => {
                  handleInputChange(e, setMinOrder);
                  handleMinOrder(e.target.value);
                }}
              />
            )}
          </QuestSmallGap>
        </QuestRow>
        <QuestRow>
          <QuestText>{t("question-3")}</QuestText>
          <QuestSmallGap>
            <QuestInput
              type="number"
              min="1"
              placeholder={t("business-days")}
              value={bussDays}
              onChange={(e) => handleInputChange(e, setBussDays)}
            />
          </QuestSmallGap>
        </QuestRow>
        <QuestRow $add={additionalPrices.length > 1}>
          <QuestText>{t("question-4")}</QuestText>
          <QuestSmallGap $add={additionalPrices.length > 1}>
            <Checkbox
              checked={priceBreak}
              onChange={() => setPriceBreak(!priceBreak)}
            />
            {priceBreak && (
              <QuestPriceBreak>
                {additionalPrices.map((priceInfo, index) => (
                  <Row2 key={index}>
                    <QuestSmallInput
                      type="number"
                      min={minOrder}
                      placeholder={t("quantity")}
                      value={priceInfo.minQuantity}
                      onChange={(e) => handlePriceChange(e, index)}
                    />
                    <QuestSmallInput
                      type="number"
                      min="1"
                      placeholder={t("discount")}
                      value={priceInfo.discount}
                      onChange={(e) => handleDiscountChange(e, index)}
                    />
                    <Add onClick={handleAddMore} />
                    {index > 0 && (
                      <div style={{ marginTop: -4.5 }}>
                        <UseAnimations
                          onClick={() => handleRemove(index)}
                          className="pointer"
                          animation={trash2}
                          size={32}
                          strokeColor="#8A4B5E"
                        />
                      </div>
                    )}
                  </Row2>
                ))}
              </QuestPriceBreak>
            )}
          </QuestSmallGap>
        </QuestRow>
        <QuestRow>
          <QuestText>{t("question-5")}</QuestText>
          <QuestSmallGap>
            <Checkbox
              checked={buySample}
              onChange={() => setBuySample(!buySample)}
            />
            {buySample && (
              <QuestSmallInput
                type="number"
                min="1"
                placeholder={t("blank-price")}
                value={blankPrice}
                onChange={(e) => handleInputChange(e, setBlankPrice)}
              />
            )}
          </QuestSmallGap>
        </QuestRow>
        <QuestRow>
          <QuestText>{t("question-6")}</QuestText>
          <QuestSmallGap>
            <Checkbox
              checked={buyPrinted}
              onChange={() => setBuyPrinted(!buyPrinted)}
            />
            {buyPrinted && (
              <QuestSmallInput
                type="number"
                min="1"
                placeholder={t("printed-price")}
                value={printPrice}
                onChange={(e) => handleInputChange(e, setPrintPrice)}
              />
            )}
          </QuestSmallGap>
        </QuestRow>
      </ContentContainer>
      <Buttons>
        <ButtonBack
          onClick={() => {
            saveDataToStore();
            onBack();
          }}
        >
          {t("back")}
        </ButtonBack>
        <ButtonNext
          onClick={() => {
            updateItemStep4Obj();
          }}
        >
          {t("next")}
        </ButtonNext>
      </Buttons>
    </>
  );
};

export default AddItemStepPricing;
