import React, { useEffect, useRef, useState } from "react";
import {
  MainContainer,
  Column,
  SectionTitle,
  Content,
  DescriptionContainer,
  DescriptionValueContainer,
  Description,
  DescriptionValue,
  Button,
  SuccessImg,
  FailedImg,
  StyledAHref,
  ButtonGenerateInvoice,
  InvoiceDiv,
  LoaderDiv,
  Text,
  TextContainer,
  HeaderWrapper,
  LastTableItem,
  FirstItem,
  TableBodyRow,
} from "./BusinessSubscriptionsPage.styles";
import Sidebar from "../utils/Sidebar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getUserSubscriptions,
  disableAutomaticSubscriptionRenewal,
} from "../../../store/slices/subscriptions/asyncThunks";
import { selectedGetUserSubscriptions } from "../../../store/slices/subscriptions/slice";
import { formatDate, priceFormatter } from "../../../utils/utils";
import {
  selectBusinessUserInfo,
  selectBusinessUserInfoLoading,
  selectCompanyInfo,
  selectDisableAutomaticSubscriptionRenewalLoading,
} from "../../../store/slices/businessUser/slice";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import Success from "../../../assets/images/success.png";
import Failed from "../../../assets/images/failed.png";
import { ReactComponent as Open } from "../../../assets/icons/open.svg";
import MiniLoader from "../../../components/MiniLoader/MiniLoader";
import openToast from "../../../utils/toast";
import Header from "../../../components/Header/Header";
import TableItem from "../../../components/Table/TableItem";
import Table from "../../../components/Table/Table";
import { selectedLocale } from "../../../store/slices/common/slice";

const BusinessSubscriptionsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscriptionsData = useSelector(selectedGetUserSubscriptions);
  const businessUserLoading = useSelector(selectBusinessUserInfoLoading);
  const disablePending = useSelector(
    selectDisableAutomaticSubscriptionRenewalLoading
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const company = useSelector(selectCompanyInfo);
  const { state } = useSelector(selectBusinessUserInfo);

  const [modalRef, setModalRef] = useState(false);
  const toastId = useRef(null);

  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  useEffect(() => {
    dispatch(getUserSubscriptions(+page))
      .unwrap()
      .then(() => {})
      .catch((err) => {
        toast.error(t(err.message));
      });
  }, [dispatch, page]);

  const handleDisableAutomaticSubscriptionRenewal = () => {
    dispatch(disableAutomaticSubscriptionRenewal())
      .unwrap()
      .then(() => {
        setModalRef(false);
      })
      .catch((err) => {
        openToast(
          toastId,
          "handle-automatic-sub-renewal",
          "error",
          t(err.message)
        );
      });
  };

  const handlePageChange = (pageNumber, paramName) => {
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set(paramName, pageNumber);

    const url = `/admin-portal/business-subscriptions?${currentParams.toString()}`;
    navigate(url);
  };

  const tableHeader = [
    {
      label: `${t("id")}`,
      sortable: true,
      key: "id",
    },
    {
      label: `${t("type")}`,
      sortable: true,
      key: "type",
    },
    {
      label: `${t("price")}`,
      sortable: true,
      key: "price",
    },
    {
      label: `${t("period")}`,
      sortable: true,
      key: "period",
    },
    {
      label: `${t("subscription-valid")}`,
      sortable: true,
      key: "subValid",
    },
    {
      label: `${t("num-of-users")}`,
      sortable: true,
      key: "numOfUsers",
    },
    {
      label: `${t("invoice-url")}`,
      sortable: true,
      key: "invoiceUrl",
    },
    {
      label: `${t("recipient-url")}`,
      sortable: true,
      key: "recipientUrl",
    },
    {
      label: `${t("payment-status")}`,
      sortable: true,
      key: "paymentStatus",
    },
    {
      label: `${t("payment-type")}`,
      sortable: true,
      key: "paymentType",
    },
    {
      label: `${t("coupon-discount")}`,
      sortable: true,
      key: "couponDiscount",
    },
  ];

  const locale = useSelector(selectedLocale);
  const upgradePackage = showUpgradeBar && diff > 0 && state;
  const ar = locale === "ar";

  return (
    <>
      <HeaderWrapper>
        <Header color showUser showCart showFavorite />
      </HeaderWrapper>
      <MainContainer>
        <Sidebar />

        <Column $upgradePackage={upgradePackage} $ar={ar}>
          <SectionTitle>{t("subscriptions")}</SectionTitle>
          {businessUserLoading && subscriptionsData.loading ? (
            <LoaderDiv>
              <MiniLoader />
            </LoaderDiv>
          ) : (
            <>
              <DescriptionContainer>
                <DescriptionValueContainer>
                  <Description>{t("package-type")}:</Description>
                  <DescriptionValue>
                    {company?.packageType
                      ? t(company?.packageType.toLowerCase())
                      : "/"}
                  </DescriptionValue>
                </DescriptionValueContainer>
                <DescriptionValueContainer>
                  <Description>{t("package-expiration")}:</Description>
                  <DescriptionValue>
                    {company.packageExpiration
                      ? formatDate(company.packageExpiration)
                      : "/"}
                  </DescriptionValue>
                </DescriptionValueContainer>
                <DescriptionValueContainer>
                  <Description>{t("max-number-of-users")}:</Description>
                  <DescriptionValue>
                    {company.maxNumberOfUsers ? company.maxNumberOfUsers : "/"}
                  </DescriptionValue>
                </DescriptionValueContainer>
                <DescriptionValueContainer>
                  <Description>
                    {t("automatic-subscription-renewal")}:
                  </Description>
                  {company.automaticSubscriptionRenewal ? (
                    <SuccessImg src={Success} alt="success-img" />
                  ) : (
                    <FailedImg src={Failed} alt="success-img" />
                  )}
                  {company.automaticSubscriptionRenewal && (
                    <Button onClick={() => setModalRef(true)}>
                      {t("disable")}
                    </Button>
                  )}
                </DescriptionValueContainer>
              </DescriptionContainer>
              <SectionTitle style={{ marginTop: 40 }}>
                {t("subscriptions-history")}
              </SectionTitle>
              {!subscriptionsData.loading ? (
                <>
                  {subscriptionsData?.subscriptions.length !== 0 ? (
                    <>
                      <Table
                        marginTop={40}
                        headerInformation={tableHeader}
                        totalCount={subscriptionsData.total}
                        currentPage={+page}
                        setPage={handlePageChange}
                        perPage={subscriptionsData.perPage}
                        noDataContent={t("no-data")}
                        param={"page"}
                        dataLoading={false}
                        lastColumnAligned
                        centerText
                        bigHeaderWidth
                      >
                        {subscriptionsData?.subscriptions?.map((val, key) => {
                          return (
                            <TableBodyRow
                              key={key}
                              $offClick
                              onClick={() => {}}
                            >
                              <FirstItem data={val.id} />
                              <TableItem
                                data={t(val.type.toLowerCase())}
                                center
                                big
                              />
                              <TableItem
                                big
                                center
                                data={
                                  priceFormatter(val.price, false, 0) + " SR"
                                }
                              />
                              <TableItem center data={t(val.period)} big />
                              <TableItem
                                center
                                big
                                data={
                                  t(formatDate(val.subscriptionFrom)) +
                                  " - " +
                                  t(formatDate(val.subscriptionTo))
                                }
                              />
                              <TableItem center data={t(val.numOfUsers)} big />
                              <TableItem
                                data={
                                  <>
                                    {val.status === "PAID" && (
                                      <InvoiceDiv>
                                        <ButtonGenerateInvoice
                                          onClick={() =>
                                            navigate(
                                              `/admin-portal/checkout/final?subscriptionId=${val.id}`
                                            )
                                          }
                                        >
                                          {t("generate-invoice")}
                                        </ButtonGenerateInvoice>
                                      </InvoiceDiv>
                                    )}
                                    {val.invoiceUrl &&
                                      val.status === "PAID" && (
                                        <StyledAHref
                                          href={val.invoiceUrl}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Open className="details-icon" />
                                        </StyledAHref>
                                      )}
                                    {!val.invoiceUrl &&
                                      val.status !== "PAID" &&
                                      "/"}
                                  </>
                                }
                                big
                                center
                              />
                              <TableItem
                                big
                                data={
                                  !val.receiptUrl ? (
                                    "/"
                                  ) : (
                                    <StyledAHref
                                      href={val.receiptUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <Open className="details-icon" />
                                    </StyledAHref>
                                  )
                                }
                                center
                              />
                              <TableItem data={t(val.status)} center big />
                              <TableItem data={t(val.paymentType)} center big />
                              <LastTableItem
                                $offClick
                                big
                                data={t(val.couponDiscount) || "/"}
                              />
                            </TableBodyRow>
                          );
                        })}
                      </Table>
                    </>
                  ) : (
                    <Content>
                      <TextContainer>
                        <Text>{t("no-subscription-history")}.</Text>
                      </TextContainer>
                    </Content>
                  )}
                </>
              ) : (
                <LoaderDiv>
                  <MiniLoader />
                </LoaderDiv>
              )}
            </>
          )}
        </Column>
      </MainContainer>
      <ModalConfirm
        open={modalRef}
        close={() => setModalRef(false)}
        onClickNo={() => {
          setModalRef(false);
        }}
        title={t("are-you-sure-disable-subscription-renewal")}
        onClickYes={() => {
          handleDisableAutomaticSubscriptionRenewal();
        }}
        confirmLoading={disablePending}
      />
    </>
  );
};

export default BusinessSubscriptionsPage;
