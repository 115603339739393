import React, { useRef } from "react";
import {
  SideBar,
  LogoContainer,
  AdminInfoContainer,
  AdminName,
  AdminAccount,
  NavContainer,
  ProfilePicEmpty,
  IconContainer,
  NavDiv,
  StyledBar,
  ButtonsContainer,
  UpgradeButton,
  LogoutButton,
  StyledNavLink,
  LogoGiftIcon,
  ArrowIcon,
  LogoutIcon,
  TestEnvContainer,
  Relative,
  ProfilePhoto,
} from "./Sidebar.style";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo2 } from "../../../assets/icons/logo_purple.svg";
import { ReactComponent as Dashboard } from "../../../assets/icons/dashboard.svg";
import { ReactComponent as Marketplace } from "../../../assets/icons/market.svg";
import { ReactComponent as Recipients } from "../../../assets/icons/usergroup.svg";
import { ReactComponent as Coupon } from "../../../assets/icons/coupon.svg";
import { ReactComponent as AccountSettings } from "../../../assets/icons/settings.svg";
import { ReactComponent as Wallet } from "../../../assets/icons/wallet.svg";
import { ReactComponent as OrderHistory } from "../../../assets/icons/clock.svg";
import { ReactComponent as Favorite } from "../../../assets/icons/heart.svg";
import { ReactComponent as Subscription } from "../../../assets/icons/sub.svg";
import { ReactComponent as DashboardUnSelected } from "../../../assets/icons/dashboardUnSelected.svg";
import { ReactComponent as MarketplaceUnSelected } from "../../../assets/icons/marketUnSelected.svg";
import { ReactComponent as RecipientsUnSelected } from "../../../assets/icons/usergroupUnSelected.svg";
import { ReactComponent as CouponUnSelected } from "../../../assets/icons/couponUnSelected.svg";
import { ReactComponent as AccountSettingsUnSelected } from "../../../assets/icons/settingsUnSelected.svg";
import { ReactComponent as WalletUnSelected } from "../../../assets/icons/walletUnSelected.svg";
import { ReactComponent as OrderHistoryUnSelected } from "../../../assets/icons/clockUnSelected.svg";
import { ReactComponent as FavoriteUnSelected } from "../../../assets/icons/heartUnSelected.svg";
import { ReactComponent as SubscriptionUnSelected } from "../../../assets/icons/subUnSelected.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBusinessUserInfo,
  selectCompanyInfo,
} from "../../../store/slices/businessUser/slice";
import { useTranslation } from "react-i18next";
import { selectUserInfo } from "../../../store/slices/authRecipient/slice";
import { logoutRecipient } from "../../../store/slices/authRecipient/asyncThunks";
import { logout } from "../../../store/slices/businessUser/asyncThunks";
import { countClickAction } from "../../../store/slices/common/asyncThunks";
import { FRONT_URL } from "../../../api";
import openToast from "../../../utils/toast";
import { selectedLocale } from "../../../store/slices/common/slice";
import Icon from "../../../components/Icon";

const Sidebar = ({ categoriesBar }) => {
  const userInfo = useSelector(selectUserInfo);
  const { name, companyLogo } = useSelector(selectCompanyInfo);
  const { role, company, state, id, fullName } = useSelector(
    selectBusinessUserInfo
  );
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const toastId = useRef(null);

  const locale = useSelector(selectedLocale);

  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  const upgradePackage = showUpgradeBar && diff > 0 && state;

  const navLinks = [
    {
      to: "/admin-portal/dashboard",
      icon: <Dashboard />,
      iconUnSelected: <DashboardUnSelected />,
      label: "dashboard",
    },
    {
      to: "/admin-portal/marketplace",
      icon: <Marketplace />,
      iconUnSelected: <MarketplaceUnSelected />,
      label: "marketplace",
    },
    {
      to: "/admin-portal/recipients?page=1",
      icon: <Recipients />,
      iconUnSelected: <RecipientsUnSelected />,
      label: "recipients2",
    },
    {
      to: "/admin-portal/order-history?marketplacePage=1&giftsPage=1",
      icon: <OrderHistory />,
      iconUnSelected: <OrderHistoryUnSelected />,
      label: "order-history",
    },
    {
      to:
        role === "superAdmin"
          ? "/admin-portal/billing?billingsPage=1&bankTransfersPage=1"
          : "/admin-portal/billing?billingsPage=1",
      icon: <Wallet />,
      iconUnSelected: <WalletUnSelected />,
      label: "wallet",
    },
    {
      to: "/admin-portal/favorite",
      icon: <Favorite />,
      iconUnSelected: <FavoriteUnSelected />,
      label: "favorites",
    },
    {
      to:
        role === "superAdmin"
          ? "/admin-portal/subscriptions"
          : "/admin-portal/business-subscriptions?page=1",
      icon: <Subscription />,
      iconUnSelected: <SubscriptionUnSelected />,
      label: "subscriptions",
    },
  ];

  const superAdminNavLinks = [
    {
      to: "/admin-portal/coupons",
      icon: <Coupon />,
      iconUnSelected: <CouponUnSelected />,
      label: "coupons",
    },
  ];

  const handleLogout = () => {
    if (id > 0) {
      dispatch(logout())
        .unwrap()
        .then(() => {
          navigation("/login");
        })
        .catch((error) => {
          openToast(toastId, "new-password", "error", t(error.message));
        });
    } else if (userInfo.id > 0) {
      dispatch(logoutRecipient())
        .unwrap()
        .then(() => {
          navigation("/login/user");
        })
        .catch((error) => {
          openToast(toastId, "new-password", "error", t(error.message));
        });
    }
  };

  const UserInfo = () => {
    return (
      <AdminInfoContainer $superAdmin={role === "superAdmin"}>
        {companyLogo ? (
          <ProfilePhoto src={companyLogo} alt="profilepicture" />
        ) : (
          <ProfilePicEmpty>
            <div>
              <LogoGiftIcon />
            </div>
          </ProfilePicEmpty>
        )}
        <AdminName>
          {!userInfo.fullName
            ? name
              ? `${name.length > 30 ? name?.slice(0, 30) + "..." : t(name)}`
              : `${t(fullName)}`
            : name
            ? `${name}`
            : `${userInfo.fullName}`}
        </AdminName>
        {id > 0 && <AdminAccount>{t("admin-account")}</AdminAccount>}
        {userInfo?.id > 0 && <AdminAccount>{t("user-account")}</AdminAccount>}
      </AdminInfoContainer>
    );
  };

  const Logo = () => {
    return FRONT_URL !== "https://portal.ygii.app" ? (
      <Relative>
        <TestEnvContainer
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <span
            style={{
              color: "#8A4B5E",
              fontSize: 11,

              textAlign: "center",
              position: "absolute",
              bottom: -5,
              right: locale === "ar" ? "unset" : -5,
              left: locale === "ar" ? -5 : "unset",
            }}
          >
            TEST
          </span>
          <LogoContainer>
            <Logo2 height={33} />
          </LogoContainer>
        </TestEnvContainer>
      </Relative>
    ) : (
      <LogoContainer>
        <Logo2 height={33} />
      </LogoContainer>
    );
  };

  const Buttons = () => {
    return (
      <ButtonsContainer $superAdmin={role === "superAdmin"}>
        <UpgradeButton
          onClick={() => {
            window.scrollTo(0, 0);
            navigation("/sign-up/steps/upgrade");
          }}
        >
          {t("upgrade-your-account")}
          <ArrowIcon $ar={locale === "ar"} />
        </UpgradeButton>
        <LogoutButton onClick={handleLogout}>
          <Icon icon={<LogoutIcon />} selectedLocale={locale === "ar"} />
          {t("logout")}
        </LogoutButton>
      </ButtonsContainer>
    );
  };

  return (
    <SideBar
      $ar={locale === "ar"}
      $upgradePackage={upgradePackage}
      $height={
        location.pathname === "/admin-portal/marketplace" ||
        location.pathname === "/admin-portal/favorite"
      }
    >
      <StyledBar>
        <UserInfo />
        {id > 0 && (
          <NavDiv $superAdmin={role === "superAdmin"}>
            <NavContainer
              $superAdmin={role === "superAdmin"}
              $categoriesBar={categoriesBar}
            >
              {navLinks.map(({ to, icon, iconUnSelected, label }) => (
                <StyledNavLink
                  key={to}
                  to={to}
                  onClick={() => {
                    if (label === "marketplace") {
                      dispatch(countClickAction("marketplace"));
                    }
                  }}
                  children={({ isActive }) => {
                    return (
                      <>
                        <IconContainer>
                          {isActive ? (
                            <Icon
                              icon={icon}
                              selectedLocale={locale === "ar"}
                            />
                          ) : (
                            <Icon
                              icon={iconUnSelected}
                              selectedLocale={locale === "ar"}
                            />
                          )}
                        </IconContainer>
                        {t(`${label}`)}
                      </>
                    );
                  }}
                />
              ))}
              {role === "superAdmin" &&
                superAdminNavLinks.map(
                  ({ to, icon, iconUnSelected, label }) => (
                    <StyledNavLink
                      key={to}
                      to={to}
                      children={({ isActive }) => {
                        return (
                          <>
                            <IconContainer>
                              {isActive ? (
                                <Icon
                                  icon={icon}
                                  selectedLocale={locale === "ar"}
                                />
                              ) : (
                                <Icon
                                  icon={iconUnSelected}
                                  selectedLocale={locale === "ar"}
                                />
                              )}
                            </IconContainer>
                            {t(`${label}`)}
                          </>
                        );
                      }}
                    />
                  )
                )}
              <StyledNavLink
                to={"/admin-portal/account-settings"}
                children={({ isActive }) => {
                  return (
                    <>
                      <IconContainer>
                        {isActive ? (
                          <Icon
                            icon={<AccountSettings />}
                            selectedLocale={locale === "ar"}
                          />
                        ) : (
                          <Icon
                            icon={<AccountSettingsUnSelected />}
                            selectedLocale={locale === "ar"}
                          />
                        )}
                      </IconContainer>
                      {t("account-settings")}
                    </>
                  );
                }}
              />

              <Buttons />
            </NavContainer>
          </NavDiv>
        )}

        {!!userInfo?.id && (
          <NavContainer>
            <NavDiv>
              <StyledNavLink
                to="/user-portal/recipient/pick-a-gift"
                children={({ isActive }) => {
                  return (
                    <>
                      <IconContainer>
                        {isActive ? (
                          <Icon
                            icon={<Dashboard />}
                            selectedLocale={locale === "ar"}
                          />
                        ) : (
                          <Icon
                            icon={<DashboardUnSelected />}
                            selectedLocale={locale === "ar"}
                          />
                        )}
                      </IconContainer>
                      {t("received-gifts")}
                    </>
                  );
                }}
              />
            </NavDiv>

            <LogoutButton style={{ marginLeft: 15 }} onClick={handleLogout}>
              <Icon icon={<LogoutIcon />} selectedLocale={locale === "ar"} />
              {t("logout")}
            </LogoutButton>
          </NavContainer>
        )}
      </StyledBar>
      <Logo />
    </SideBar>
  );
};

export default Sidebar;
