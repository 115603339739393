import styled, { keyframes } from "styled-components";
import TableItem from "../../../components/Table/TableItem";
import FirstTableItem from "../../../components/Table/FirstTableItem";
import { ReactComponent as LoadingSvg } from "../../../assets/icons/loader-white.svg";

export const LoadingIcon = styled(LoadingSvg)`
  circle {
    fill: #8a4b5e;
  }
`;
export const MainContainer = styled.div`
  flex-direction: row;
  background: #f6f6f9;

  min-height: 100vh;
  display: flex;
`;
export const SectionTitle = styled.h1`
  color: #8a4b5e;

  font-family: "Avenir";
  user-select: none;
  font-size: 35px;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.178px;
  margin-bottom: 80px;
  @media (max-width: 870px) {
    display: none;
  }
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  margin-top: 75px;

  transition: all 0.2s ease-in-out;
  padding: ${(props) =>
    props.$upgradePackage && props.$ar
      ? "100px 290px 80px 40px"
      : props.$upgradePackage && !props.$ar
      ? "100px 40px 80px 290px"
      : !props.$upgradePackage && props.$ar
      ? "40px 290px 80px 40px"
      : "40px 40px 80px 290px"};

  @media (max-width: 870px) {
    margin-top: 50px;
    padding: ${(props) =>
      props.$upgradePackage ? "60px 40px 40px 40px" : "0px 40px 40px 40px"};
  }
`;
export const Message = styled.span`
  font-family: "Avenir-Medium";
  font-size: 24px;
  color: #8a4b5e;
  @media (max-width: 490px) {
    font-size: 18px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: ${(props) => (props.$center ? 1 : "unset")};
`;

export const Text = styled.span`
  font-family: "Avenir";
  font-style: normal;
  font-size: 24px;
  line-height: 33px;
  margin-top: -5rem;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
`;

export const Desc = styled.span`
  font-family: "Avenir-Light";
  font-style: normal;

  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.177777px;
  padding-bottom: 5px;

  color: rgba(69, 69, 132, 0.6);
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const TextContainerNone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 655px) {
    flex-direction: column;
  }
`;
export const Image = styled.img``;
export const Buttons = styled.div`
  border-radius: 30px;
  user-select: none;
  background: #8a4b5e;
  height: 45px;
  padding: 5px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  text-align: center;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};

  &:active {
    transition: ${(props) =>
      props.$disabled ? "unset" : "all 0.2s ease-in-out"};
    scale: ${(props) => (props.$disabled ? "unset" : "0.95")};
  }

  @media (max-width: 1075px) {
    height: 35px;
    padding: 5px 20px;
    font-size: 12px;
  }
`;

export const SendGiftsButton = styled(Buttons)`
  display: flex;
`;

export const TableContainer = styled.div`
  overflow-x: auto;
  margin-top: 8rem;
  margin-bottom: 80px;
  width: 1800px;
  @media (max-width: 2125px) {
    width: 100%;
  }
`;

export const EditTd = styled.td`
  text-align: center;
`;
export const DeleteTd = styled.td`
  text-align: center;
`;
export const Search = styled.input`
  padding: 8px;
  border: unset;
  border-bottom: 2px solid #e6e6e6;
  width: 240px;
  font-family: "Avenir-Medium";
  font-style: normal;

  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  flex-start: "start";
  background: transparent;
  &:focus {
    outline: unset;
  }
  &::placeholder {
    font-family: "Avenir-Medium";
    font-style: normal;

    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.177777px;
    color: #c99ba9;
  }
  @media (max-width: 650px) {
    width: 220px;
    font-size: 14px;
    &::placeholder {
      font-size: 14px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export const Input = styled.input`
  padding: 12px;
  background: #fff;
  width: 100%;
  font-family: "Avenir-Medium";
  font-style: normal;
  border: 0.8px solid #8c8c8c;
  border-radius: 15px;
  font-size: 1.6rem;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  height: 5rem;
  transition: 0.2s;

  &::placeholder {
    color: #8c8c8c;
  }
  &:active,
  &:hover,
  &:focus {
    outline: none;
    border-bottom-color: #8a4b5e;
  }

  @media (max-width: 450px) {
    font-size: 14px;
    &::placeholder {
      font-size: 14px;
    }
  }
`;

export const ButtonConfirm = styled.button`
  width: 10.5rem;
  color: white;
  font-family: "Avenir-Medium";

  font-size: 1.6rem;

  letter-spacing: 0.08px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  padding: 12px 18px;

  border: transparent;
  background: #8a4b5e;
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  cursor: pointer;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${(props) => (props.$cancel ? "#cc0000" : " #4ab639")};
  }

  &:active {
    ${({ saveloading }) =>
      saveloading
        ? null
        : `
      box-shadow: 0px 2px 8px -5px rgb(128, 128, 128);
      transition: all 0.1s ease-in;
      transform: scale(0.95);
    `};
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

export const DropdownItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const DropdownButton = styled.button`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
`;

export const ButtonNext = styled.button`
  padding: 12px 15px;
  background: #8a4b5e;
  border-radius: 15px;
  border: 0;
  color: #fff;
  font-family: "Avenir-Medium";

  font-size: 18px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: block;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &::after {
    width: 25px;
    height: 1px;
    background: white;
    transform: translateX(-3px);
    margin-top: 0px;
  }

  &::before {
    content: "";
    transform: rotate(-135deg) translateX(50%);
    width: 11px;
    height: 11px;
    background: transparent;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    margin-top: -1px;
  }

  &:hover {
    background: #4ab639;
    padding: 12px 60px 12px 15px;
  }

  &:hover::after,
  &:hover::before {
    opacity: 1;
    right: 15px;
  }
`;
export const ButtonBack = styled.button`
  padding: 12px 15px;
  background: #8a4b5e;
  border-radius: 15px;
  border: 0;
  color: #fff;
  font-family: "Avenir-Medium";

  font-size: 18px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: block;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &::after {
    width: 25px;
    height: 1px;
    background: white;
    transform: translateX(-4.5px);
    margin-top: 0px;
  }

  &::before {
    content: "";
    transform: rotate(-135deg) translateX(50%);
    width: 11px;
    height: 11px;
    background: transparent;
    border-right: 1px solid white;
    border-top: 1px solid white;
    margin-top: -1px;
  }

  &:hover {
    background: #d92525;
    padding: 12px 15px 12px 60px;
  }

  &:hover::after,
  &:hover::before {
    opacity: 1;
    right: 15px;
  }
`;

export const InputContainer = styled.div`
  padding-bottom: 15px;
  width: 100%;
  height: 5rem;
`;

export const PageButton = styled.button`
  padding: 2px 7px;
  border: none;
  background: transparent;
  font-family: "Avenir-Medium";
  font-size: 14px;
  user-select: none;
  color: #8a4b5e;
  cursor: pointer;
  &:active {
    opacity: 0.6;
  }
`;

export const PageCountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;
  gap: 5px;
  width: 1800px;
  margin-top: 10px;

  @media (max-width: 2125px) {
    width: 100%;
  }
`;
export const UserPhoneLabel = styled.label`
  border: none;
  border-bottom: 2px solid #d1d1d4;
  padding: 10px;
  background: transparent;
  width: 100%;
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
`;

export const Select = styled.select`
  border: 1px solid #8c8c8c;
  border-radius: 15px;
  padding: 12px;
  background: #fff;
  outline: none;
  height: 5rem;
  width: 100%;
  font-family: "Avenir-Medium";
  font-style: normal;
  font-size: 1.6rem;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;
  appearance: none;

  &::placeholder {
    color: #8c8c8c;
  }

  &:focus-within {
    color: #8a4b5e;
  }

  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 19" stroke-width="1px" width="40px" fill="%235D5C98"><g id="App" stroke="none" stroke-width="1" fill="%235D5C98" fill-rule="evenodd"></g><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M 15.5 10 L 12 13.5 L 8.5 10 L 7 10 l 5 5 l 5 -5 Z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 8px top 40%;
  background-size: 32px;

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

export const OptionContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #8c8c8c;
  border-top: none;
  border-radius: 0 0 15px 15px;
  max-height: 200px;
  overflow-y: auto;
`;

export const Option = styled.option`
  border: 1px solid #8c8c8c;
  border-radius: 15px;
  padding: 12px;
  background: transparent;
  width: 100%;
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #8a4b5e;

  &::placeholder {
    color: #8c8c8c;
  }

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

export const PhoneOptionPlaceholder = styled.option`
  border: none;
  border-bottom: 2px solid #d1d1d4;
  padding: 10px;
  background: transparent;
  width: 100%;
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.177777px;
  color: #b1b1d8;
`;

export const SearchAndButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  @media (max-width: 1690px) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    justify-content: center;
  }

  @media (max-width: 870px) {
    margin-top: 60px;
    align-items: center;
  }
`;
export const ContentContainer = styled.div`
  padding: 15px 36px;
  margin-top: 20px;
  width: 500px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 540px) {
    width: 400px;
  }
  @media (max-width: 440px) {
    width: 300px;
  }
`;

export const FormDiv = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 870px) {
    justify-content: center;
  }
`;

export const ButtonUpload = styled.div`
  border-radius: 30px;
  user-select: none;
  background: #8a4b5e;
  height: 45px;
  padding: 5px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  text-align: center;
  font-family: "Avenir";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.25);
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};

  &:active {
    transition: ${(props) =>
      props.$disabled ? "unset" : "all 0.2s ease-in-out"};
    scale: ${(props) => (props.$disabled ? "unset" : "0.95")};
  }

  @media (max-width: 1075px) {
    height: 35px;
    padding: 5px 20px;
    font-size: 12px;
  }
`;

export const SecondLevelText = styled.span`
  color: rgba(0, 0, 0, 0.6);
  font-family: "Avenir";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.178px;
`;

export const Row = styled.div`
  flex-direction: row;
  display: flex;
  gap: 5px;
`;

export const MaxRecipientsText = styled.span`
  color: red;
  font-family: "Avenir-Medium";
  font-size: 1.8rem;
`;

export const NumberOfRecipientsContainer = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: 8rem;
  // margin-bottom: 3rem;
  gap: 8px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Loading = styled.div`
  border: 4px solid #b3b3b3;
  border-left: 4px solid #fff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  animation: ${spin} 1s linear infinite;
  align-self: center;
  justify-content: center;
`;

export const DeleteIconContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const TableBodyRow = styled.tr`
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  justify-content: space-between;
  display: flex;
  width: 100%;
  background-color: ${(props) => props.$active && "#8A4B5E15"};

  cursor: ${(props) => (props.$offClick ? "default" : "pointer")};

  min-height: 6.4rem;
  gap: 4rem;

  &:last-of-type {
    border-bottom: none;
  }
  &:hover {
    background-color: #8a4b5e15;
    transition: all 0.2s ease-in-out;
  }
  &:nth-child(even) {
    background-color: #8a4b5e10;
  }
`;

export const FirstItem = styled(FirstTableItem)`
  padding-left: 18px;
`;

export const LastTableItem = styled(TableItem)`
  padding-right: 16px;
  justify-content: flex-end;
  /* background-color: red; */
  z-index: 10;
  cursor: pointer;
  &:active {
    opacity: ${(props) => (props.$offClick ? 1 : 0)};
  }
`;
export const StyledTable = styled.table`
  width: 100%;
`;

export const StyledTd = styled.td`
  min-width: 150px !important;
`;

export const StyledTdAll = styled(StyledTd)`
  padding-bottom: 10px;
  text-align: center;
`;

export const StyledTh = styled.th`
  text-align: center;
`;

export const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

export const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 42px;
`;

export const LoaderDiv = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 150px;
  @media (max-width: 680px) {
    margin-top: 80px;
  }
`;

export const HeaderWrapper = styled.div`
  background: #8a4b5e;
  position: fixed;
  z-index: 1000;
  width: 100%;
`;
