import { createAsyncThunk } from "@reduxjs/toolkit";
import photoProcessingService from "../../../api/services/photoProcessing";

export const processLogoBackground = createAsyncThunk(
  "photoProcessing/processLogo",
  async (requestData) => {
    let formData = new FormData();

    if (requestData.logo !== null) {
      formData.append("image", requestData.logo);
    }

    return await photoProcessingService.removeBackgroundFromLogo(formData);
  }
);

export const generatePhotosWithLogo = createAsyncThunk(
  "photoProcessing/generatePhotosWithLogo",
  async (requestData) => {
    let formData = new FormData();

    if (requestData.logo !== null) {
      formData.append("logo", requestData.logo);
    }

    if (requestData.photosData && requestData.photosData.length) {
      for (const row of requestData.photosData) {
        formData.append("photosData[]", JSON.stringify(row));
      }
    }

    return await photoProcessingService.generatePhotosWithLogo(
      formData,
      requestData.cartItemId
    );
  }
);
