import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Gift } from "../../../assets/icons/gift.svg";
import { ReactComponent as Green } from "../../../assets/icons/green.svg";
import { useNavigate } from "react-router-dom";
import {
  MainContainer,
  Container,
  Title,
  Subtitle,
  Desc,
  Row,
  Column,
  DescBox,
  Card,
  CardHeaderContainer,
  CardHeader,
  PriceContainer,
  Price,
  PriceInfo,
  BenefitsFirst,
  BenefitsBoxFirst,
  BenefitsText,
  BenefitsBox,
  ButtonPrice,
  MediumText,
  BigText,
  ButtonBoxLast,
  Date,
  Discount,
  Invisible,
  InvisibleText,
  DiscountContainer,
  ButtonPriceChosen,
  CancelSubscriptionText,
  PreviousPrice,
  CardsRow,
  RowPriceContainer,
} from "./AdminPricingPage.styles";
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import {
  assignPlan,
  cancelPlan,
  getPlans,
} from "../../../store/slices/plan/asyncThunks";
import { toast } from "react-toastify";
import ContentHeader from "../utils/ContentHeader";
import Sidebar from "../utils/Sidebar";
import { fetchBusinessUserInfo } from "../../../store/slices/businessUser/asyncThunks";
import {
  selectCompanyPackage,
  selectCompanyPackageActiveState,
} from "../../../store/slices/businessUser/slice";
import MiniLoader from "../../../components/MiniLoader/MiniLoader";
import openToast from "../../../utils/toast";

const AdminPricingPage = () => {
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const plans = useSelector((state) => state.planInfo.planData.plans);
  const companyId = useSelector((state) => state.businessUser.company.id);
  const planIdState = useSelector(selectCompanyPackage);
  const planActiveState = useSelector(selectCompanyPackageActiveState);
  const [chosenPlanId, setChosenPlanId] = useState("");
  const [loading, setLoading] = useState(true);
  const modalRef = useRef();
  const toastId = useRef();

  useEffect(() => {
    dispatch(getPlans())
      .unwrap()
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
        toast.error(t(err.message));
      });
  }, [dispatch]);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (planIdState !== null && planActiveState !== false) {
      setChosenPlanId(planIdState);
    }
  }, [planIdState, planActiveState]);

  const basicPlan = Object.values(plans)[0];
  const essentialsPlan = Object.values(plans)[1];
  const enterprisePlan = Object.values(plans)[2];

  const basicWithDiscountPrice = isChecked
    ? (basicPlan?.price * 0.9).toFixed(2)
    : basicPlan?.price;

  const essentialsWithDiscountPrice = isChecked
    ? (essentialsPlan?.price * 0.9).toFixed(2)
    : essentialsPlan?.price;

  const yearlyPrice = (essentialsWithDiscountPrice * 12).toFixed(2);

  const yearlyPriceBasic = (basicWithDiscountPrice * 12).toFixed(2);

  const goToTalk = () => {
    navigation("/contact");
  };
  const assignPlanHandler = (planId) => {
    dispatch(assignPlan({ planId, companyId }))
      .unwrap()
      .then(() => {
        toast.success(t("plan-chosen-successfully"));
        dispatch(fetchBusinessUserInfo());
        setChosenPlanId(planId);
      })
      .catch((err) =>
        openToast(toastId, "assign-plan", "error", t(err.message))
      );
  };

  const handleCancelSubscriptionClick = () => {
    modalRef.current.show();
  };

  const handleYes = (companyId) => {
    dispatch(cancelPlan(companyId))
      .unwrap()
      .then(() => {
        toast.success(t("plan-cancelled-successfully"));
        dispatch(fetchBusinessUserInfo());
        setChosenPlanId("");
      })
      .catch((err) => toast.error(t(err.message)));
  };

  return (
    <MainContainer>
      <Sidebar />

      <ContentHeader
        showButton
        showCart
        showLocale
        style={{ marginRight: 90 }}
      />
      {loading ? (
        <div style={{ marginTop: 100 }}>
          <MiniLoader />
        </div>
      ) : (
        <Container>
          <BigText>{t("find-your-perfect-plan")}</BigText>
          <MediumText>{t("get-started-today")}</MediumText>

          <Row>
            <Date>{t("monthly")}</Date>
            <Switch
              activeBoxShadow={"0px 0px 2px 3px #8A4B5E"}
              onColor="#8A4B5E"
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={handleChange}
              checked={isChecked}
            />
            <Date>{t("yearly")}</Date>
            {!isChecked && (
              <Invisible>
                <InvisibleText>{t("10%-discount")}</InvisibleText>
              </Invisible>
            )}
            {isChecked && (
              <DiscountContainer>
                <Discount>{t("10%-discount")}</Discount>
              </DiscountContainer>
            )}
          </Row>
          <CardsRow>
            <Card>
              <CardHeaderContainer>
                <CardHeader>
                  <Title>{t("basic")}</Title>
                  <Subtitle>{t("teams-and-small-businesses")}</Subtitle>
                </CardHeader>
                <DescBox>
                  <Desc>{t("give-the-gift-of-choice")}</Desc>
                </DescBox>
              </CardHeaderContainer>
              <PriceContainer
                style={{
                  height: isChecked ? 80 : 60,
                  transition: "all .2s ease-out",
                }}
              >
                <RowPriceContainer>
                  {isChecked && (
                    <PreviousPrice>
                      {basicPlan?.price}
                      {t(`${basicPlan?.currency}`)}
                    </PreviousPrice>
                  )}
                  <Price>
                    {basicWithDiscountPrice}
                    {t(`${basicPlan?.currency}`)}
                  </Price>
                  <PriceInfo>/ {t("recipient")}</PriceInfo>
                </RowPriceContainer>
                {isChecked && (
                  <PriceInfo style={{ marginTop: -5 }}>
                    ({t("billed-yearly-as")} {yearlyPriceBasic}
                    {t(`${basicPlan?.currency}`)} / {t("recipient")})
                  </PriceInfo>
                )}
              </PriceContainer>
              <BenefitsFirst>
                <Column>
                  <BenefitsBoxFirst>
                    <div>
                      <Green />
                    </div>
                    <BenefitsText>
                      {t("recipient-pick-their-gift")}
                    </BenefitsText>
                  </BenefitsBoxFirst>
                  <BenefitsBox>
                    <div>
                      <Green />
                    </div>
                    <BenefitsText>{t("send-gifts-across-sa")}</BenefitsText>
                  </BenefitsBox>
                  <BenefitsBox>
                    <div>
                      <Green />
                    </div>
                    <BenefitsText>{t("personalized-experience")}</BenefitsText>
                  </BenefitsBox>
                  <BenefitsBox>
                    <div>
                      <Green />
                    </div>
                    <BenefitsText>{t("unlimited-gifts")}</BenefitsText>
                  </BenefitsBox>
                </Column>
                <ButtonBoxLast>
                  {chosenPlanId === basicPlan?.id ? (
                    <>
                      <ButtonPriceChosen>
                        {t("you-have-this-plan")}
                      </ButtonPriceChosen>
                      <DescBox style={{ marginBottom: -30 }}>
                        <CancelSubscriptionText
                          onClick={() => handleCancelSubscriptionClick()}
                        >
                          {t("cancel-subscription")}
                        </CancelSubscriptionText>
                      </DescBox>
                    </>
                  ) : (
                    <ButtonPrice
                      onClick={() => assignPlanHandler(basicPlan?.id)}
                    >
                      {t("choose-plan")}
                    </ButtonPrice>
                  )}
                </ButtonBoxLast>
              </BenefitsFirst>
            </Card>
            <Card>
              <CardHeaderContainer>
                <CardHeader>
                  <Title>{t("essentials")}</Title>
                  <Subtitle>{t("larger-organizations")}</Subtitle>
                </CardHeader>
                <DescBox>
                  <Desc>{t("scale-up-your-gifting")}</Desc>
                </DescBox>
              </CardHeaderContainer>
              <PriceContainer
                style={{
                  height: isChecked ? 80 : 60,
                  transition: "all .2s ease-out",
                }}
              >
                <RowPriceContainer>
                  {isChecked && (
                    <PreviousPrice>
                      {essentialsPlan?.price}
                      {t(`${essentialsPlan?.currency}`)}
                    </PreviousPrice>
                  )}
                  <Price>
                    {essentialsWithDiscountPrice}
                    {t(`${essentialsPlan?.currency}`)}
                  </Price>
                  <PriceInfo>/ {t("recipient")}</PriceInfo>
                </RowPriceContainer>
                {isChecked && (
                  <PriceInfo style={{ marginTop: -5 }}>
                    ({t("billed-yearly-as")} {yearlyPrice}
                    {t(`${essentialsPlan?.currency}`)} / {t("recipient")})
                  </PriceInfo>
                )}
              </PriceContainer>
              <BenefitsFirst></BenefitsFirst>
              <Column>
                <BenefitsBoxFirst>
                  <div>
                    <Green />
                  </div>
                  <BenefitsText>{t("basic-plus")}</BenefitsText>
                </BenefitsBoxFirst>
                <BenefitsBox>
                  <div>
                    <Green />
                  </div>
                  <BenefitsText>{t("integrate-hr")}</BenefitsText>
                </BenefitsBox>
                <BenefitsBox>
                  <div>
                    <Green />
                  </div>
                  <BenefitsText>{t("automate-gifting")}</BenefitsText>
                </BenefitsBox>
                <BenefitsBox>
                  <div>
                    <Green />
                  </div>
                  <BenefitsText>{t("unlimited-users")}</BenefitsText>
                </BenefitsBox>
              </Column>
              <ButtonBoxLast>
                {chosenPlanId === essentialsPlan?.id ? (
                  <>
                    <ButtonPriceChosen>
                      {t("you-have-this-plan")}
                    </ButtonPriceChosen>
                    <DescBox style={{ marginBottom: -30 }}>
                      <CancelSubscriptionText
                        onClick={() => handleCancelSubscriptionClick()}
                      >
                        {t("cancel-subscription")}
                      </CancelSubscriptionText>
                    </DescBox>
                  </>
                ) : (
                  <ButtonPrice
                    onClick={() => assignPlanHandler(essentialsPlan?.id)}
                  >
                    {t("choose-plan")}
                  </ButtonPrice>
                )}
              </ButtonBoxLast>
            </Card>
            <Card>
              <CardHeaderContainer>
                <CardHeader>
                  <Title>{t("enterprise")}</Title>
                  <Subtitle>{t("enterprise-gifting-programs")}</Subtitle>
                </CardHeader>
                <DescBox>
                  <Desc>{t("build-an-impactful-gifting-program")}</Desc>
                </DescBox>
              </CardHeaderContainer>
              <PriceContainer
                style={{
                  height: isChecked ? 80 : 60,
                  transition: "all .2s ease-out",
                }}
              >
                <Gift
                  style={{ left: isChecked ? "-2%" : "8%" }}
                  className="gift"
                  height={50}
                  width={100}
                />
                <PriceInfo style={{ fontSize: isChecked ? 20 : 14 }}>
                  {t("advanced-controls")}
                </PriceInfo>
              </PriceContainer>
              <BenefitsFirst>
                <Column>
                  <BenefitsBoxFirst>
                    <div>
                      <Green />
                    </div>
                    <BenefitsText>{t("essentials-plus")}</BenefitsText>
                  </BenefitsBoxFirst>
                  <BenefitsBox>
                    <div>
                      <Green />
                    </div>
                    <BenefitsText>{t("dedicated-account")}</BenefitsText>
                  </BenefitsBox>
                  <BenefitsBox>
                    <div>
                      <Green />
                    </div>
                    <BenefitsText>{t("gifting-programs")}</BenefitsText>
                  </BenefitsBox>
                  <BenefitsBox>
                    <div>
                      <Green />
                    </div>
                    <BenefitsText>{t("special-gifts")}</BenefitsText>
                  </BenefitsBox>
                </Column>
                <ButtonBoxLast>
                  {chosenPlanId === enterprisePlan?.id ? (
                    <>
                      <ButtonPriceChosen>
                        {t("you-have-this-plan")}
                      </ButtonPriceChosen>
                      <DescBox style={{ marginBottom: -30 }}>
                        <CancelSubscriptionText
                          onClick={() => handleCancelSubscriptionClick()}
                        >
                          {t("cancel-subscription")}
                        </CancelSubscriptionText>
                      </DescBox>
                    </>
                  ) : (
                    <ButtonPrice onClick={goToTalk}>
                      {t("contact-us")}
                    </ButtonPrice>
                  )}
                </ButtonBoxLast>
              </BenefitsFirst>
            </Card>
          </CardsRow>
        </Container>
      )}
      {/* <ModalConfirm
        modalRef={modalRef}
        onClickNo={() => {
          modalRef.current.hide();
        }}
        onClickYes={() => {
          modalRef.current.hide();
          handleYes(companyId);
        }}
      /> */}
    </MainContainer>
  );
};

export default AdminPricingPage;
