import axiosApiClient, { axiosRecipientUserClient } from "..";
import { handleError } from "../../utils/errors";

const BASE_URL = "/gift";

class GiftMobileService {
  createGiftingRecipientOrderWithToken = async ({ itemId, token }) => {
    try {
      const response = await axiosRecipientUserClient.post(
        `${BASE_URL}/company-gifting-recipient/${token}/accept-token`,
        { itemId }
      );
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while posting gifting order.");
    }
  };
}
const giftMobileService = new GiftMobileService();

export default giftMobileService;
