import axiosApiClient from "..";
import { handleError } from "../../utils/errors";
const BASE_URL = "orders";

class OrderService {
  createOrder = async (data) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/unauthorized`,
        data
      );
      if (response.status !== 201) {
        throw new Error();
      }

      return response;
    } catch (err) {
      console.log(err, "Error occurred while creating order.");
      throw handleError(err, "Error occurred while creating order.");
    }
  };

  sendOtpForPayment = async (data) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/send-otp-payment`,
        data
      );
      if (response.status !== 201) {
        throw new Error();
      }

      return response;
    } catch (err) {
      throw handleError(err, "Error occurred while sending otp for payment.");
    }
  };

  validateOtpForPayment = async (data) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/validate-otp-payment`,
        data
      );
      if (response.status !== 201) {
        throw new Error();
      }

      return response;
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while validationg otp for payment."
      );
    }
  };

  validatePayment = async ({ paymentChargeId }) => {
    try {
      const body = {
        paymentChargeId,
      };
      const response = await axiosApiClient.post(
        BASE_URL + "/validate-payment",
        body
      );
      if (response.status !== 201) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while validating payment");
    }
  };
}

const orderService = new OrderService();

export default orderService;
