import { bookCall, getPlans, assignPlan, cancelPlan } from "./asyncThunks";

export const bookCallBuilder = (builder) => {
  builder
    .addCase(bookCall.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(bookCall.fulfilled, (state, action) => {
      state.bookCall.email = action.payload.email;
      state.bookCall.firstName = action.payload.firstName;
      state.bookCall.lastName = action.payload.lastName;
      state.bookCall.companyName = action.payload.companyName;
      state.bookCall.hrSystem = action.payload.hrSystem;
    })
    .addCase(bookCall.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
};

export const getPlansBuilder = (builder) => {
  builder
    .addCase(getPlans.pending, (state) => {
      state.planData.loading = true;
      state.planData.error = null;
    })
    .addCase(getPlans.fulfilled, (state, action) => {
      state.planData.plans = action.payload;
    })
    .addCase(getPlans.rejected, (state, action) => {
      state.planData.loading = false;
      state.planData.error = action.error.message;
    });
};

export const assignPlanBuilder = (builder) => {
  builder.addCase(assignPlan.pending, (state) => {
    state.assignPlan.loading = true;
    state.assignPlan.error = null;
  });
  builder.addCase(assignPlan.fulfilled, (state, action) => {
    state.assignPlan.loading = false;
    state.assignPlan.companyId = action.payload.company.id;
    state.assignPlan.planId = action.payload.plan.id;
    state.assignPlan.isActive = action.payload.isActive;
  });
  builder.addCase(assignPlan.rejected, (state, action) => {
    state.assignPlan.loading = false;
    state.assignPlan.error = action.error.message;
  });
};

export const cancelPlanBuilder = (builder) => {
  builder
    .addCase(cancelPlan.pending, (state) => {
      state.cancelPlan.loading = true;
      state.cancelPlan.error = null;
    })
    .addCase(cancelPlan.fulfilled, (state, action) => {
      state.cancelPlan.loading = false;
      state.assignPlan.isActive = action.payload.isActive;
    })
    .addCase(cancelPlan.rejected, (state, action) => {
      state.cancelPlan.loading = false;
      state.cancelPlan.error = action.error.message;
    });
};
