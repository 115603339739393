import { createSlice } from "@reduxjs/toolkit";
import {
  getPaymentMethodBuilder,
  removePaymentMethodBuilder,
  setDefaultPaymentMethodBuilder,
} from "./builder";

const initialState = {
  paymentMethods: {
    data: [],
    loading: true,
    error: null,
  },
  removePaymentMethodError: null,
  removePaymentMethodLoading: false,
  setDefaultPaymentMethodLoading: false,
  setDefaultPaymentMethodError: null,
};

const paymentSlice = createSlice({
  name: "paymentInfo",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    getPaymentMethodBuilder(builder);
    setDefaultPaymentMethodBuilder(builder);
    removePaymentMethodBuilder(builder);
    builder.addCase("logout", () => ({ ...initialState }));
  },
});

// export const {} = paymentSlice.actions;

export const selectedPaymentMethodData = (state) =>
  state.paymentInfo.paymentMethods.data;

export const removePaymentMethodLoading = (state) =>
  state.paymentInfo.removePaymentMethodLoading;

export const setDefaultPaymentMethodLoading = (state) =>
  state.paymentInfo.setDefaultPaymentMethodLoading;
export const selectedPaymentMethodLoading = (state) =>
  state.paymentInfo.paymentMethods.loading;

export default paymentSlice.reducer;
