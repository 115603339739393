import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ adminDashboard }) => {
  const options = {
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        type: "category",
        labels: adminDashboard?.subscriptions?.map((sub) => sub.type),
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const data = {
    datasets: [
      {
        label: "Total Num of Users of Basic Sub",
        data: [
          {
            x: "Basic",
            y: adminDashboard?.subscriptions[0]?.totalNumOfUsers,
          },
        ],
        fill: false,
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Total Num of Users of Essentials Sub",
        data: [
          {
            x: "Essentials",
            y: adminDashboard?.subscriptions[1]?.totalNumOfUsers,
          },
        ],
        fill: false,
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return <Bar options={options} data={data} />;
};

export default BarChart;
