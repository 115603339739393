import axiosApiClient, { axiosAuthClient } from "..";
import { handleError } from "../../utils/errors";

class PlanService {
  bookCall = async ({ email, firstName, lastName, companyName, hrSystem }) => {
    try {
      const body = {
        email,
        firstName,
        lastName,
        companyName,
        hrSystem,
      };
      const response = await axiosAuthClient.post("/plan/book-call", body);
      if (response.status !== 200) {
        throw new Error();
      }

      const { contact } = response.data.data;
      return contact;
    } catch (err) {
      throw handleError(err, "Error occurred while creating new call booking");
    }
  };

  getPlans = async () => {
    try {
      const response = await axiosAuthClient.get("/plan/");
      if (response.status !== 200) {
        throw new Error();
      }
      const { plans } = response.data.data;
      return plans;
    } catch (err) {
      throw handleError(err, "Error occurred while fetching plans");
    }
  };
}
const planService = new PlanService();

export default planService;
