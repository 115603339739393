import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  MainContainer,
  Column,
  ContentContainer,
  InfoTitle,
  InfoText,
  RightSideContainer,
  DescriptionContainer,
  PriceContainer,
  Button,
  ButtonDisabled,
  LoaderDiv,
  PriceWrapper,
} from "./ItemDetailPage.styles";
import ContentHeader from "../utils/ContentHeader";
import { getItemById } from "../../../store/slices/gift/asyncThunks";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  selectRecipientsWithWishlists,
  selectedItem,
  selectedItemLoading,
} from "../../../store/slices/gift/slice";
import { getCurrencySymbol } from "../../../utils/constants";
import { priceFormatter } from "../../../utils/utils";
import { ReactComponent as BuyableIcon } from "../../../assets/icons/gift.svg";
import MediaSlider from "../../../components/MediaSlider/MediaSlider";
import Dots from "../../../components/Dots/Dots";
import { useTranslation } from "react-i18next";
import { addGifts, selectGifts } from "../../../store/slices/sendGifts/slice";
import {
  selectBusinessRulesVariables,
  selectedLocale,
} from "../../../store/slices/common/slice";
import MiniLoader from "../../../components/MiniLoader/MiniLoader";
import { HeaderWrapper } from "../MarketplacePage/MarketplacePage.styles";
import Header from "../../../components/Header/Header";
import Sidebar from "../utils/Sidebar";
import openToast from "../../../utils/toast";
import AppLoader from "../../../components/AppLoader";

const ItemDetailPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(selectedItem);
  const loading = useSelector(selectedItemLoading);
  const selectedItems = useSelector(selectGifts);
  const { itemId } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const businessRulesVariables = useSelector(selectBusinessRulesVariables);
  const toastId = useRef(null);
  const recipientsWithWishlist = useSelector(selectRecipientsWithWishlists);

  const addToItemCart = () => {
    let foundItem = false;

    if (recipientsWithWishlist[0]?.items?.length > 0) {
      if (selectedItems.length === 1) {
        openToast(toastId, "add-item-to-cart", "error", t("select-max-1-item"));
        return;
      }
    }

    if (
      recipientsWithWishlist[0]?.items?.length === 0 ||
      recipientsWithWishlist.length === 0
    ) {
      if (selectedItems.length === 3) {
        openToast(
          toastId,
          "add-item-to-cart",
          "error",
          t("select-max-3-items")
        );
        return;
      }
    }

    selectedItems.forEach((item) => {
      if (item.id === data.id) {
        const itemSubcategories = item.subcategory.map(
          (subcategory) => subcategory.valueId
        );
        const addedItemSubcategories = data.subcategoryItems.map(
          (subcategory) => subcategory.valueId
        );

        if (
          itemSubcategories.sort().join(",") ===
          addedItemSubcategories.sort().join(",")
        ) {
          foundItem = true;
        }
      }
    });

    if (!foundItem) {
      dispatch(
        addGifts({
          id: data.id,
          image: data.photos[0].url,
          name: data.name,
          price: data.price,
          currency: data.currency,
          subcategory: data.subcategoryItems,
        })
      );
    } else {
      openToast(
        toastId,
        "add-item-to-cart",
        "error",
        t("item-already-selected")
      );
    }
  };

  const isSelected = useMemo(() => {
    let isSelected = false;
    for (const item of selectedItems) {
      if (item.id === data.id) {
        isSelected = true;
      }
    }

    return isSelected;
  }, [data.id, selectedItems]);

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    dispatch(getItemById(itemId))
      .unwrap()
      .then(() => {})
      .catch((err) => toast.error(t(err.message)));
  }, [dispatch, itemId]);

  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";
  return (
    <MainContainer>
      <HeaderWrapper>
        <Header
          showLogin
          showSignUp
          showNav
          color
          showCart
          showUser
          showFavorite
        />
      </HeaderWrapper>
      <Sidebar />
      <Column $ar={ar}>
        <ContentHeader
          showBack={true}
          showTitle={true}
          title={ar && data?.nameAr ? data?.nameAr : data?.name}
        />
        <ContentContainer>
          {!loading ? (
            <>
              {data?.photos?.length > 0 ? (
                data?.photos?.length > 1 ? (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <MediaSlider
                      style={{
                        borderRadius: 20,
                        height: 450,
                        width: 450,
                        objectFit: data.productId ? "contain" : "cover",
                      }}
                      pictures={data?.photos}
                      activeIndex={activeIndex}
                      onSlideChange={setActiveIndex}
                      timer={3000}
                    />
                    <Dots
                      total={data?.photos.length}
                      activeIndex={activeIndex}
                      onDotClick={handleDotClick}
                    />
                  </div>
                ) : (
                  <img
                    src={data.photos[0]?.url}
                    alt={"itemPhoto"}
                    className="itemPhoto"
                    style={{
                      borderRadius: 20,
                      height: 450,
                      width: 450,
                      objectFit: data.productId ? "contain" : "cover",
                    }}
                  />
                )
              ) : (
                <AppLoader />
              )}

              <RightSideContainer>
                <DescriptionContainer>
                  <InfoTitle>{t("description")}:</InfoTitle>
                  <InfoText style={{ maxWidth: 500, lineHeight: 1.25 }}>
                    {ar && data?.descriptionAr
                      ? data?.descriptionAr
                      : data?.description}
                  </InfoText>
                </DescriptionContainer>
                <PriceContainer>
                  <PriceWrapper>
                    <InfoTitle>{t("price")}:</InfoTitle>

                    <InfoText style={{ fontSize: 26 }}>
                      {data?.currency &&
                        `${priceFormatter(
                          data.price,
                          true,
                          businessRulesVariables.itemPurchaseVAT
                        )} ${getCurrencySymbol(data?.currency)}`}
                    </InfoText>
                  </PriceWrapper>

                  {data.buyable && (
                    <div style={{ position: "relative" }}>
                      <div className="buyable">
                        <BuyableIcon />
                      </div>
                    </div>
                  )}
                  {!isSelected ? (
                    <Button onClick={addToItemCart}>{t("add-as-gift")}</Button>
                  ) : (
                    <ButtonDisabled>{t("add-as-gift")}</ButtonDisabled>
                  )}
                </PriceContainer>
              </RightSideContainer>
            </>
          ) : (
            <LoaderDiv>
              <MiniLoader />
            </LoaderDiv>
          )}
        </ContentContainer>
      </Column>
    </MainContainer>
  );
};

export default ItemDetailPage;
