import React from "react";
import { usePagination, DOTS } from "./usePagination";
import {
  ArrowContainer,
  ArrowIcon,
  PaginationContainer,
  PaginationItem,
  RightArrowIcon,
} from "./Pagination.styles";

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  ar,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || !paginationRange || paginationRange.length < 2) {
    return null;
  }

  const lastPage = paginationRange[paginationRange.length - 1];

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  return (
    <PaginationContainer>
      <ArrowContainer disabled={currentPage === 1} onClick={onPrevious}>
        {ar ? <RightArrowIcon /> : <ArrowIcon />}
      </ArrowContainer>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <PaginationItem key={pageNumber + index} dots>
              &#8230;
            </PaginationItem>
          );
        }

        return (
          <PaginationItem
            key={pageNumber}
            selected={pageNumber === currentPage}
            onClick={() => {
              onPageChange(pageNumber);
            }}
          >
            {pageNumber}
          </PaginationItem>
        );
      })}
      <ArrowContainer disabled={currentPage === lastPage} onClick={onNext}>
        {ar ? <ArrowIcon /> : <RightArrowIcon />}
      </ArrowContainer>
    </PaginationContainer>
  );
};

export default Pagination;
