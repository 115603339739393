import { createSlice } from "@reduxjs/toolkit";
import {
  businessUserInfoBuilder,
  editCompanyBuilder,
  invitationLinkBuilder,
  editCompanyProfileBuilder,
  logoutBuilder,
  disableAutomaticSubscriptionRenewalBuilder,
  assignSubscriptionPlanBuilder,
  validatePaymentSubscriptionBuilder,
} from "./builder";

const initialState = {
  id: null,
  email: "",
  fullName: "",
  mobileNumber: "",
  role: "",
  verified: false,
  company: {
    id: null,
    name: "",
    companyLogo: "",
    billingEmail: "",
    address: "",
    addressOptional: "",
    city: "",
    zipCode: "",
    country: "",
    VAT: "",
    walletBalance: "",
    walletCurrency: "",
    walletReservedFunds: "",
    disableAutomaticSubscriptionRenewal: null,
  },
  fetchBusinessUserInfoLoading: false,
  editingCompanyInfo: false,
  loggedIn: null,
  invitationLink: "",
  tapPaymentCustomerId: null,
  disableAutomaticSubscriptionRenewal: {
    loading: false,
  },
  state: false,
  assignSubscriptionPlanLoading: false,
};

export const businessUserSlice = createSlice({
  name: "businessUser",
  initialState,
  reducers: {
    setBusinessUserInfo: (state, action) => {
      const { id, fullName, email, mobileNumber, role, company, verified } =
        action.payload;
      state.id = id;
      state.fullName = fullName;
      state.email = email;
      state.mobileNumber = mobileNumber;
      state.role = role;
      state.verified = verified;
      state.company = company;
      state.loggedIn = true;
    },
    uploadLogo: (state, action) => {
      state.company.companyLogo = action.payload;
    },
    setState: (state, { payload }) => {
      state.state = payload;
    },
    setCompanyInfo: (state, action) => {
      const company = action.payload;
      state.company = company;
    },
    setInvitationLink: (state, action) => {
      const invitationLink = action.payload;
      state.invitationLink = invitationLink;
    },
    setWallet: (state, action) => {
      const { newBalanceValue, newReservedFundsValue } = action.payload;
      state.company.walletBalance = newBalanceValue;
      state.company.walletReservedFunds = newReservedFundsValue;
    },
    setWalletBalance: (state, action) => {
      const { newBalanceValue } = action.payload;
      state.company.walletBalance = newBalanceValue;
    },
    setLoggedIn: (state, { payload }) => {
      const { loggedIn } = payload;
      state.loggedIn = loggedIn;
    },
  },
  extraReducers: (builder) => {
    businessUserInfoBuilder(builder);
    invitationLinkBuilder(builder);
    editCompanyBuilder(builder);
    editCompanyProfileBuilder(builder);
    logoutBuilder(builder);
    disableAutomaticSubscriptionRenewalBuilder(builder);
    assignSubscriptionPlanBuilder(builder);
    validatePaymentSubscriptionBuilder(builder);
    builder.addCase("logout", () => ({ ...initialState, loggedIn: false }));
  },
});

export const {
  setBusinessUserInfo,
  uploadLogo,
  setCompanyInfo,
  setInvitationLink,
  setWallet,
  setState,
  setWalletBalance,
  setLoggedIn,
} = businessUserSlice.actions;

export const selectBusinessUserInfo = (state) => state.businessUser;
export const selectBusinessUserInfoLoading = (state) =>
  state.businessUser.fetchBusinessUserInfoLoading;
export const editingLoader = (state) => state.businessUser.editingCompanyInfo;
export const isBusinessUserLoggedIn = (state) => state.businessUser.loggedIn;
export const selectCompanyInfo = (state) => state.businessUser.company;
export const selectCompanyLogo = (state) =>
  state.businessUser.company.companyLogo;
export const selectCompanyPackage = (state) =>
  state.businessUser.company?.packageExpiration;

export const selectCompanyPackageActiveState = (state) =>
  state.businessUser.company?.companyPlans
    ? state.businessUser.company?.companyPlans[0]?.isActive
    : null;
export const selectDisableAutomaticSubscriptionRenewalLoading = (state) =>
  state.businessUser.disableAutomaticSubscriptionRenewal.loading;

export default businessUserSlice.reducer;
