import styled from "styled-components";

export const ModalStyle = styled.div`
  padding: 0 2.5rem 2.5rem 2.5rem;
  display: flex;
  outline: none;
  flex-direction: column;
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  background: white;

  border-radius: 20px;
  // min-height: 550px;
`;

export const OverlayStyle = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3500;
  background: rgba(0, 0, 0, 0.6);
`;
