import { ReactComponent as LoaderWhite } from "../../assets/icons/loader-white.svg";
import { StyledButtonAdd } from "./ButtonAdd.styles";

const ButtonComponent = ({ onClick, loading, title }) => {
  return (
    <StyledButtonAdd onClick={onClick}>
      {loading ? (
        <LoaderWhite style={{ width: 20, height: 20 }} />
      ) : (
        <>{title}</>
      )}
    </StyledButtonAdd>
  );
};

export default ButtonComponent;
