import { Chevron, GameControl, HomeContainer } from "./GamePage.styles.js";
import React, { useEffect, useRef } from "react";
import Spline from "@splinetool/react-spline";
import Controls from "../../assets/images/gameControl.png";
import { useNavigate } from "react-router-dom";
import MiniLoader from "../../components/MiniLoader/MiniLoader.js";

const GamePage = () => {
  const navigate = useNavigate();
  const spline = useRef();

  function onLoad(splineApp) {
    spline.current = splineApp;
  }

  useEffect(() => {
    if (window.innerWidth < 1024) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <HomeContainer>
      <Chevron onClick={() => navigate("/")} />
      <GameControl src={Controls} />

      <div style={{ position: "absolute", zIndex: 1 }}>
        <MiniLoader />
      </div>

      <Spline
        style={{ zIndex: 2 }}
        scene="https://draft.spline.design/JbocNdPpVaHnTCmQ/scene.splinecode"
        onLoad={onLoad}
      />
    </HomeContainer>
  );
};

export default GamePage;
