import { styled } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 40px;
`;

export const StyledCardRow = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;
export const CardRow = styled.div`
  margin-left: 2rem;
  min-height: 4rem;
  /* cursor: pointer; */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
`;

export const Number = styled.span`
  color: #8a4b5e;
  font-family: "Avenir-Light";
  font-size: 1.8rem;
  user-select: "none";
  letter-spacing: -0.178px;
`;
export const Text = styled.button`
  color: #8a4b5e;
  font-family: "Avenir-Heavy";
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  border-radius: 1rem;
  border: 1px solid #8a4b5e;
  outline: none;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: #f6f6f9;
  &:hover {
    background-color: #8a4b5e;
    color: #fff;
    transition: all 0.5s ease-in-out;
  }
  &:active {
    scale: 0.97;
    transition: all 0.2s ease-in-out;
  }
`;

export const Title = styled.span`
  color: #8a4b5e;
  font-family: "Avenir-Heavy";

  font-size: 2rem;

  margin-bottom: 2rem;
`;
