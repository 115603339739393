import axiosApiClient from "..";

import { handleError } from "../../utils/errors";

const BASE_URL = "/business/photoProcessing";

class PhotoProcessingServices {
  removeBackgroundFromLogo = async (data) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/background`,
        data
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data.data;
    } catch (err) {
      throw handleError(err, "Error occurred while processing logo");
    }
  };

  generatePhotosWithLogo = async (data, cartItemId) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/add-logo`, data);
      if (response.status !== 200) {
        throw new Error();
      }
      return { data: response.data.data, cartItemId };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while generating photos with logo"
      );
    }
  };
}

const photoProcessingService = new PhotoProcessingServices();

export default photoProcessingService;
