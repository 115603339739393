import React from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { selectLoggedIn } from "../store/slices/authRecipient/slice";
import { selectBusinessUserInfo } from "../store/slices/businessUser/slice";
import UpgradePackage from "../components/UpgradePackage/UpgradePackage";

const UserLayout = () => {
  const location = useLocation();
  const loggedIn = useSelector(selectLoggedIn);

  const { company } = useSelector(selectBusinessUserInfo);

  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  return loggedIn ? (
    <>
      {/* <UpgradePackage show={showUpgradeBar} expirationDate={diff} /> */}
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default UserLayout;
