import axiosApiClient from "..";

import { handleError } from "../../utils/errors";

const BASE_URL = "users";

class UserServices {
  getUserInfoByIdUnauthorized = async (id) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/unauthorized/${id}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return data;
    } catch (err) {
      throw handleError(err, "Error occurred while fetching user by id.");
    }
  };
}

const userServices = new UserServices();

export default userServices;
