import React, { useEffect, useRef, useState } from "react";
import {
  MainContainer,
  Column,
  CardProduct,
  CardProductName,
  ProductInfo,
  Row,
  Price,
  Icons,
  ImageContainer,
  StyledInfinityScroll,
  HeaderWrapper,
  Layout,
  Subtitle,
  IconContainer,
  AdminHeaderContainer,
  GiftBoxImage,
  DotsContainer,
  Img,
  ImageDiv,
  EndMessageText,
  EndMessageContainer,
  GiftBoxImageContainer,
} from "./MarketplacePage.styles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AdminHeader from "../../../components/AdminHeader/AdminHeader";
import UseAnimations from "react-useanimations";
import trash2 from "react-useanimations/lib/trash2";
import heart from "react-useanimations/lib/heart";
import {
  resetAddItem,
  resetEditItemData,
  resetSelectedItem,
  toggleFavoriteItem,
} from "../../../store/slices/marketplace/marketplaceSlice";
import { deleteMarketplaceItemLoader } from "../../../store/slices/marketplace/marketplaceSlice.js";
import { selectBusinessUserInfo } from "../../../store/slices/businessUser/slice";
import { priceFormatter } from "../../../utils/utils";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import { toast } from "react-toastify";
import { ReactComponent as Edit } from "../../../assets/icons/edit-3.svg";
import { ReactComponent as Share } from "../../../assets/icons/share-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer.js";
import Header from "../../../components/Header/Header";
import { getCurrencySymbol } from "../../../utils/constants";
import Sidebar from "../utils/Sidebar";
import LoginModal from "../../../components/Modal/LoginModal.js";

import {
  useFavoriteItemMutation,
  useGetMarketplaceItemsQuery,
} from "../../../store/slices/marketplace/marketplaceApi.js";
import { api } from "../../../store/api/index.js";
import store from "../../../store/index.js";
import SkeletonMarketplaceLoader from "../../../components/SkeletonLoader/SkeletonMarketplaceLoader/SkeletonMarketplaceLoader.js";
import { removeItem } from "../../../store/slices/marketplace/asyncThunks.js";
import GiftBox from "../../../assets/images/marketplace-image.png";
import openToast from "../../../utils/toast.js";
import { selectedLocale } from "../../../store/slices/common/slice.js";
import Dots from "../../../components/Dots/Dots.js";
import { useSwipeable } from "react-swipeable";
import AppLoader from "../../../components/AppLoader.js";

function ProductCard({ product, itemDetails, removeItem, modal, reverse }) {
  const businessUser = useSelector(selectBusinessUserInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productId = product.id;
  const [favoriteItem] = useFavoriteItemMutation();
  const { t } = useTranslation();
  const toastId = useRef(null);
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";
  const [activeIndex, setActiveIndex] = useState(0);

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  const handleOnClickEdit = () => {
    const dataToSend = {
      productId: productId,
      action: "edit",
    };
    navigate(`/admin-portal/marketplace/add-an-item/${dataToSend.action}`, {
      state: dataToSend,
    });
  };

  const onShare = (id) => {
    const link = businessUser.loggedIn
      ? `${window.location.origin}/admin-portal/marketplace/item-detail/${id}`
      : `${window.location.origin}/marketplace/${id}`;

    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success(t("link-copied-to-clipboard"));
      })
      .catch((error) => {
        openToast(
          toastId,
          "share-marketplace-item",
          "error",
          t("Failed to copy link")
        );
        console.error("Error copying link to clipboard:", error);
      });
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setActiveIndex((prevIndex) =>
        prevIndex === product?.photos.length - 1 ? 0 : prevIndex + 1
      );
    },
    onSwipedRight: () => {
      setActiveIndex((prevIndex) =>
        prevIndex === 0 ? product?.photos.length - 1 : prevIndex - 1
      );
    },
  });

  return (
    <CardProduct>
      <Icons>
        {businessUser?.role === "superAdmin" && (
          <>
            <IconContainer>
              <UseAnimations
                animation={trash2}
                size={32}
                wrapperStyle={{ marginTop: -4 }}
                strokeColor="#8A4B5E"
                onClick={() => removeItem(product.id)}
              />
            </IconContainer>
            <IconContainer>
              <Edit width={25} height={27} onClick={handleOnClickEdit} />
            </IconContainer>
          </>
        )}
        <IconContainer>
          <Share
            height={24}
            width={24}
            style={{ marginTop: -2 }}
            onClick={() => onShare(productId)}
          />
        </IconContainer>
        <IconContainer>
          <UseAnimations
            animation={heart}
            fillColor={businessUser?.loggedIn ? "#8A4B5E" : "transparent"}
            strokeColor="#8A4B5E"
            reverse={reverse}
            size={32}
            onClick={
              !businessUser?.loggedIn
                ? modal
                : () => {
                    dispatch(
                      toggleFavoriteItem({
                        id: productId,
                        like: !reverse,
                        businessUserId: businessUser.id,
                      })
                    );
                    favoriteItem({ id: productId })
                      .unwrap()
                      .then(() => {})
                      .catch((err) => {
                        openToast(
                          toastId,
                          "marketplace-fav-item",
                          t(err?.data?.message)
                        );
                      });
                  }
            }
          />
        </IconContainer>
      </Icons>

      <ImageContainer onClick={itemDetails} {...handlers}>
        <>
          {product?.photos?.length > 1 ? (
            <ImageDiv>
              <Img
                $contain={!!product.productId}
                alt="PhotoProduct"
                effect="blur"
                wrapperProps={{
                  style: { transitionDelay: ".3s" },
                }}
                src={product?.photos[activeIndex]?.url}
              />

              <DotsContainer>
                <Dots
                  rotate
                  bg
                  total={product?.photos?.length}
                  activeIndex={activeIndex}
                  onDotClick={handleDotClick}
                />
              </DotsContainer>
            </ImageDiv>
          ) : (
            product?.photos && (
              <Img
                $contain={!!product.productId}
                alt="PhotoProduct"
                src={product?.photos[0]?.url}
                effect="blur"
                wrapperProps={{
                  style: { transitionDelay: ".3s" },
                }}
              />
            )
          )}
        </>
      </ImageContainer>

      <ProductInfo>
        <CardProductName
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {ar && product?.nameAr ? product?.nameAr : product?.name}
        </CardProductName>
        <Row>
          <Price>
            {t("from")} {priceFormatter(product?.price ?? product?.samplePrice)}{" "}
            {getCurrencySymbol(product?.currency)}
          </Price>
          {product?.minQuantity && (
            <Price>
              Min Qty:{" "}
              <Price style={{ fontWeight: 800 }}>{product?.minQuantity}</Price>
            </Price>
          )}
        </Row>
      </ProductInfo>
    </CardProduct>
  );
}

const MarketplacePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loggedIn, role, company, state } = useSelector(
    selectBusinessUserInfo
  );
  const deletePending = useSelector(deleteMarketplaceItemLoader);
  const [selectedItem, setSelectedItem] = useState(null);
  const [areYouSureModal, setAreYouSureModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("category");

  const [queryData, setQueryData] = useState({
    page: 1,
    category: category || "",
  });

  useEffect(() => {
    if (loggedIn) {
      if (category && category !== null) {
        navigate(`/admin-portal/marketplace?category=${category}`);
      } else {
        navigate(`/admin-portal/marketplace`);
      }
    } else {
      if (category && category !== null) {
        navigate(`/marketplace?category=${category}`);
      } else {
        navigate(`/marketplace`);
      }
    }
    if (category !== queryData.category) {
      setQueryData({ page: 1, category: category || "" });
    }
  }, [loggedIn, navigate, category]);

  useEffect(() => {
    dispatch(resetAddItem());
  }, [dispatch]);

  const { data, isFetching, originalArgs } =
    useGetMarketplaceItemsQuery(queryData);

  const loadMoreItems = () => {
    if (!data?.maxReached && !isFetching) {
      setQueryData((prev) => {
        const page = originalArgs?.page + 1 ?? queryData.page + 1;
        return { ...prev, page };
      });
    }
  };

  const removeItemOpenModal = (id) => {
    setAreYouSureModal(true);
    setSelectedItem(id);
  };
  const removeItemAction = () => {
    dispatch(removeItem(selectedItem))
      .unwrap()
      .then(() => {
        setAreYouSureModal(false);

        toast.success(t("item-deleted"));

        const entries = api.util.selectInvalidatedBy(store.getState(), [
          "marketplaceItems",
        ]);
        for (const { originalArgs } of entries) {
          dispatch(
            api.endpoints.getMarketplaceItems.initiate(originalArgs, {
              subscribe: false,
              forceRefetch: true,
            })
          );
        }
      })
      .catch((err) => {
        toast.error(t(err.message));
      });
  };

  const handleItemClick = (itemId) => {
    dispatch(resetSelectedItem());
    dispatch(resetEditItemData());
    window.scrollTo(0, 0);
    if (loggedIn) {
      navigate(`/admin-portal/marketplace/item-detail/${itemId}`);
    } else {
      navigate(`/marketplace/${itemId}`);
    }
  };

  const showUpgradeBar = company.packageType === "Free";
  let diff =
    new Date(company?.packageExpiration).getTime() - new Date().getTime();

  diff = Math.round(diff / (1000 * 3600 * 24));

  const locale = useSelector(selectedLocale);
  const upgradePackage = showUpgradeBar && diff > 0 && state;
  const ar = locale === "ar";

  const [headerVisible, setHeaderVisible] = useState(true);
  const [lastScrollUpY, setLastScrollUpY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(null);
  const lastScrollTopRef = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      const lastScrollTop = lastScrollTopRef.current;

      if (currentScrollTop === 0) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
        setScrollDirection(null);
        lastScrollTopRef.current = currentScrollTop;
        return;
      }

      if (currentScrollTop > lastScrollTop) {
        setScrollDirection("down");
        setLastScrollUpY(currentScrollTop);
      } else {
        setScrollDirection("up");
      }

      if (scrollDirection === "up" && currentScrollTop < lastScrollUpY - 350) {
        setHeaderVisible(true);
        setLastScrollUpY(currentScrollTop);
      }

      if (
        scrollDirection === "down" &&
        currentScrollTop > lastScrollUpY + 350
      ) {
        setHeaderVisible(false);
      }

      lastScrollTopRef.current = currentScrollTop;
    };

    const handleScrollDebounced = () => {
      requestAnimationFrame(handleScroll);
    };

    window.addEventListener("scroll", handleScrollDebounced);

    return () => {
      window.removeEventListener("scroll", handleScrollDebounced);
    };
  }, [scrollDirection]);

  return (
    <MainContainer>
      {loggedIn && <Sidebar categoriesBar />}
      {!loggedIn ? (
        <HeaderWrapper
          style={{
            opacity: headerVisible ? 1 : 0,
            transition: "opacity 0.5s",
          }}
        >
          <Header
            display={headerVisible}
            showLogin
            showSignUp
            showNav
            color
            showFavorite
            showCart
            setModal={setLoginModal}
            modal={loginModal}
            showUser
          />
        </HeaderWrapper>
      ) : (
        <HeaderWrapper>
          <Header
            showLogin
            showSignUp
            showNav
            color
            showFavorite
            showCart
            setModal={setLoginModal}
            modal={loginModal}
            showUser
          />
        </HeaderWrapper>
      )}
      <Layout $ar={ar} $loggedIn={loggedIn}>
        <Column $upgradePackage={upgradePackage} $ar={ar} $loggedIn={loggedIn}>
          <GiftBoxImageContainer>
            <GiftBoxImage
              src={GiftBox}
              effect="blur"
              wrapperProps={{
                style: { transitionDelay: ".3s" },
              }}
              alt={"Gift - Ygii"}
            />
          </GiftBoxImageContainer>

          {role === "superAdmin" && (
            <AdminHeaderContainer>
              <AdminHeader showButtons hideSearch hideSendGifts />
            </AdminHeaderContainer>
          )}

          {isFetching && queryData.page === 1 ? (
            <SkeletonMarketplaceLoader loggedIn={loggedIn} />
          ) : data?.data?.length > 0 ? (
            <StyledInfinityScroll
              $admin={role === "superAdmin"}
              dataLength={data?.data?.length ?? 0}
              hasMore={!data?.maxReached}
              next={loadMoreItems}
              // endMessage={
              //   <EndMessageContainer>
              //     <EndMessageText>{t("no2")}</EndMessageText>
              //     <EndMessageText>{t("more")}</EndMessageText>
              //     <EndMessageText>{t("products2")}</EndMessageText>
              //   </EndMessageContainer>
              // }
              loader={<AppLoader />}
            >
              {data?.data?.map((product) => (
                <ProductCard
                  key={product.id}
                  reverse={product.favorite}
                  itemDetails={() => {
                    handleItemClick(product.id);
                  }}
                  product={product}
                  modal={() => {
                    setLoginModal(true);
                  }}
                  removeItem={removeItemOpenModal}
                />
              ))}
            </StyledInfinityScroll>
          ) : (
            <Subtitle>{t("category-is-empty")}</Subtitle>
          )}
        </Column>
      </Layout>
      <LoginModal open={loginModal} close={() => setLoginModal(false)} />
      <ModalConfirm
        open={areYouSureModal}
        close={() => {
          setAreYouSureModal(false);
        }}
        onClickNo={() => {
          setAreYouSureModal(false);
        }}
        onClickYes={() => {
          removeItemAction();
        }}
        confirmLoading={deletePending}
      />
      {!loggedIn && <Footer />}
    </MainContainer>
  );
};

export default MarketplacePage;
